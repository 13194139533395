import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import IndicatorCard from '../components/IndicatorCard';
import { getTranslation } from '../dictionary';
import { indicatorsQueryOptions } from '../services/IndicatorService';
import { useLocaleStore } from '../store';

function IndicatorsSubset() {
  const language = useLocaleStore((state) => state.language);
  const { subsetKey, subsetValue } = useLoaderData();

  const { data: indicatorsSubset } = useQuery({
    ...indicatorsQueryOptions,
    select: (data) => {
      return data.filter((d) => d[subsetKey] === subsetValue);
    },
  });

  return (
    <div className="Home">
      <section className="cards-wrapper pad pad-b">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h2 className="">
                {getTranslation({
                  key: subsetValue,
                  categoryKey: 'indicatorCategories',
                  language,
                }) || subsetValue}
              </h2>
            </div>
          </div>
        </div>

        <div className="container-fluid mt6">
          <div className="row">
            {indicatorsSubset?.map((d, j) => {
              return <IndicatorCard d={d} key={j} />;
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default IndicatorsSubset;
