import React, { useState } from 'react';
import { getTranslation } from '../dictionary';
import { apiUrl } from '../settings';
import { useLocaleStore } from '../store';
import { getPhotoIfPresent } from '../utils';
import LinkScrollTop from './LinkScrollTop';
import UpdateExpert from './UpdateExpert';

const ExpertCard = ({ d }) => {
  const language = useLocaleStore((state) => state.language);
  const location = window.location.pathname;
  const interests = d.interest || [];
  const maxWords = 22;

  const translatedDescription = language === 'en' ? d.description_en : d.description;
  const words = translatedDescription ? translatedDescription.split(' ') : [];
  const description =
    words.length === 0
      ? 'No description.'
      : words.length <= maxWords
      ? translatedDescription
      : words.slice(0, maxWords).join(' ') + '…';

  const photo = getPhotoIfPresent(d);
  const imgStyle = photo ? {
    backgroundImage: `url(${apiUrl?.slice(0, -4)}${photo})`,
    //backgroundSize: 'contain', // Scales the image to fit within the container
    backgroundSize: 'cover', // Scales the image to cover the container
    backgroundPosition: 'center center', // Centers the image within the container
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  } : {};

  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapsible = () => setIsOpen(!isOpen);

  const renderInterests = () => {
    const max = 3;
    const displayedInterests = interests.slice(0, max);

    return displayedInterests
      ?.map((interest, index) => {
        let interestName = getTranslation({
          key: typeof interest === 'string' ? interest : interest.name,
          categoryKey: 'interestslist',
          language,
        });

        const separator = index === 0 ? '' : ', ';
        return (
          <span key={index}>
            {separator}
            {interestName}
          </span>
        );
      })
      ?.concat(
        interests.length > max ? (
          <span key="more">
            , {interests.length - max} {getTranslation({ key: 'more', language })}
          </span>
        ) : (
          []
        ),
      );
  };

  const renderCardContent = () => (
    <>
      <header>
        <div className="left">
          <div className="font-sans-s font-700 color-c1">
            {getTranslation({ key: 'expert', language }).toUpperCase()}
          </div>
          <div className="card-title">{d.name}</div>
          <div className="font-sans-s color-blackish-80">{renderInterests()}</div>
        </div>
        {photo && (
          <div className="right">
            <div className="round-img thumb" style={imgStyle}></div>
          </div>
        )}
      </header>
      <div className="card-body">
        <div className="font-serif-m font-400">{description}</div>
      </div>
    </>
  );

  const renderUpdateButton = () => (
    <>
      <button className="font-serif-m font-400" onClick={toggleCollapsible}>
        {isOpen ? getTranslation({ key: 'close', language }) : getTranslation({ key: 'update', language })} &#9660;
      </button>
      {isOpen && (
        <div className="collapsible-content">
          <UpdateExpert props={d} setIsOpen={setIsOpen} />
        </div>
      )}
    </>
  );

  const isAuthenticated = sessionStorage.getItem('stytch-user');
  const isOwner = isAuthenticated && JSON.parse(isAuthenticated)?.user_id === d?.user_id;
  const showUpdateButton = isOwner && location === '/data';

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} className="col-md-7 col-xl-4 card-stakeholder-col">
      <LinkScrollTop className="card-stakeholder" style={{ flex: 1 }} to={`/experts/${d.id}`}>
        {renderCardContent()}
      </LinkScrollTop>
      {showUpdateButton && renderUpdateButton()}
    </div>
  );
};

export default ExpertCard;
