import React from 'react';
import { formatNumberWithCommas } from '../utils';

const customTooltipStyle = {
  background: 'rgba(255,255,255,0.85)',
  color: '#333',
  boxShadow: '0 3px 4px rgba(0, 0, 0, 0.4)',
  padding: '1px',
  textAlign: 'left',
  borderRadius: '3px',
};

const tooltipDetailsStyle = {
  margin: '13px 19px',
};

const TrendComparedTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { year, ...data } = payload[0].payload;
    const sortedPayload = Object.entries(data).sort((a, b) => b[1] - a[1]);

    return (
      <div style={customTooltipStyle}>
        <div style={tooltipDetailsStyle}>
          <p>
            <span className="stat-text fs-2">{year}</span>
          </p>
          {sortedPayload.map(([value, number], key) => (
            <p key={key}>
              <span className="stat-unit">{value}: </span>
              <span className="stat-unit fw-bold">{formatNumberWithCommas(number)}</span>
            </p>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export default TrendComparedTooltip;
