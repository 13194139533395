import { useQuery } from '@tanstack/react-query';
import React, { Fragment } from 'react';
import { getTranslation } from '../dictionary';
import { indicatorsForHomePageQueryOptions } from '../services/IndicatorService';
import { useLocaleStore } from '../store';

const Statistics = () => {
  const language = useLocaleStore((state) => state.language);
  const { isLoading, error, data } = useQuery(indicatorsForHomePageQueryOptions);

  if (isLoading) return 'Loading...';

  if (error) return `An error has occurred: ${error.message}`;

  return (
    <div className="statistics d-flex flex-wrap">
      {data?.map((d, i) => {
        if (!d.statistic) return null;

        const renderStatistic = (start, end) => (
          <div className="row" key={`${i}-${start}`}>
            {d.statistic.slice(start, end).map((s, j) => (
              <div className="statistic lg d-inline-flex mb-0" key={`${i}-${start}-${j}`}>
                <div className="col-sm">
                  <p className="value">{s.value}</p>
                  <p className="name">{getTranslation({ key: s.name, language })}</p>
                </div>
              </div>
            ))}
          </div>
        );

        return (
          <Fragment key={i}>
            {renderStatistic(0, 3)}
            {renderStatistic(3, 5)}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Statistics;
