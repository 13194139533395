import { Checkbox, FormControlLabel, Switch, TextField } from '@mui/material';
import React, { forwardRef, useId, useImperativeHandle, useState } from 'react';
import { getTranslation } from '../dictionary';
import { updateOpeningHours } from '../utils';

const OpeningHoursForm = forwardRef(({ language, propsHours }, ref) => {
  const [showOpeningHours, setShowOpeningHours] = useState(propsHours ? true : false);
  const id = useId();

  const initialState = {
    monday: { open: '', close: '' },
    tuesday: { open: '', close: '' },
    wednesday: { open: '', close: '' },
    thursday: { open: '', close: '' },
    friday: { open: '', close: '' },
    saturday: { open: 'Gesloten', close: 'Gesloten' },
    sunday: { open: 'Gesloten', close: 'Gesloten' },
  };

  const [openingHours, setOpeningHours] = useState(propsHours || initialState);

  useImperativeHandle(ref, () => ({
    getOpeningHours: () => {
      const hasChanges = Object.keys(initialState).some(
        (day) =>
          initialState[day].open !== openingHours[day].open || initialState[day].close !== openingHours[day].close,
      );
      return hasChanges && showOpeningHours ? updateOpeningHours(openingHours) : null;
    }
  }));

  const handleInputChange = (day, type) => (e) => {
    setOpeningHours((prevState) => ({
      ...prevState,
      [day]: { ...prevState[day], [type]: e.target.value },
    }));
  };

  const handleClosedCheckboxChange = (day) => (e) => {
    setOpeningHours((prevState) => ({
      ...prevState,
      [day]: e.target.checked ? { open: 'Gesloten', close: 'Gesloten' } : { open: '', close: '' },
    }));
  };

  const isDayClosed = (day) => openingHours[day].open === 'Gesloten' && openingHours[day].close === 'Gesloten';

  return (
    <div className="col-12 py-2" key={id}>
      <FormControlLabel
        control={<Switch checked={showOpeningHours} onChange={() => setShowOpeningHours(!showOpeningHours)} />}
        label={getTranslation({ key: 'add_opening_hours', language })}
      />
      {showOpeningHours && (
        <div>
          <span className="text-danger fw-bold">
            {getTranslation({ key: 'message_add_forms', language })}
          </span>
          {Object.keys(openingHours).map((day) => (
            <div key={`${day}-${id}`}>
              <div className="col-12 col-md-3">
                <p>{`${getTranslation({ key: day, language })}`}</p>
              </div>
              <div className="row py-2">
                <div className="col-12 col-md-3 flex-grow-1">
                  <TextField
                    label={`Open`}
                    variant="standard"
                    name={`${day}-open`}
                    type="time"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    disabled={isDayClosed(day)}
                    value={isDayClosed(day) ? '' : openingHours[day].open}
                    onChange={handleInputChange(day, 'open')}
                  />
                </div>
                <div className="col-12 col-md-3 flex-grow-1">
                  <TextField
                    label={`${getTranslation({ key: 'close', language })}`}
                    variant="standard"
                    name={`${day}-close`}
                    type="time"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    disabled={isDayClosed(day)}
                    value={isDayClosed(day) ? '' : openingHours[day].close}
                    onChange={handleInputChange(day, 'close')}
                  />
                </div>
                <div className="col-12 col-md-3 d-flex align-items-center justify-content-center">
                  <FormControlLabel
                    control={<Checkbox checked={isDayClosed(day)} onChange={handleClosedCheckboxChange(day)} />}
                    label={getTranslation({ key: 'closed', language })}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export default OpeningHoursForm;
