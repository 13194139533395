import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import ExpertCard from '../components/ExpertCard';
import Loading from '../components/Loading';
import StakeholderCard from '../components/StakeholderCard';
import { getTranslation } from '../dictionary';
import { claimInitiative, fetchInitiative } from '../services/InitiativeService';
import { apiUrl } from '../settings';
import { useLocaleStore } from '../store';
import { addhttp, cleanUrlText, getPhotoIfPresent } from '../utils';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import GroupsIcon from '@mui/icons-material/Groups';
import SavingsIcon from '@mui/icons-material/Savings';
import LanguageIcon from '@mui/icons-material/Language';

function Initiative() {
  const { id } = useLoaderData();
  const language = useLocaleStore((state) => state.language);

  const { isLoading, error, data } = useQuery({
    queryKey: [`initiative${id}`],
    queryFn: async () => fetchInitiative(id),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  if (isLoading) return <Loading />;

  if (error)
    return (
      <div className="stakeholder-page initiative">
        {
          <section className="py-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h2 className="mb-1 mt-space">{error.message}</h2>
                </div>
              </div>
            </div>
          </section>
        }
      </div>
    );

  const initiative = data;
  const photo = getPhotoIfPresent(initiative);
  const imgStyle = {
    backgroundImage: 'url(' + apiUrl?.slice(0, -4) + photo + ')',
    //backgroundSize: 'contain', // Scales the image to fit within the container
    backgroundSize: 'cover', // Scales the image to cover the container
    backgroundPosition: 'center center', // Centers the image within the container
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  };

  const interests = initiative.interest;
  const targetb2b = initiative.target.filter((target) => target.type === 'b2b').map((target) => target.name);
  const targetb2c = initiative.target.filter((target) => target.type === 'b2c').map((target) => target.name);

  const handleClaim = (event) => {
    event.stopPropagation();
    const newData = {
      data: {
        user_id: JSON.parse(sessionStorage.getItem('stytch-user'))?.user_id,
      },
    };

    claimInitiative(initiative.id, newData);
  };

  const reformatDate = (dateString) => dateString.split('-').reverse().join('/');


  return (
    <div className="stakeholder-page initiative">
      {initiative && (
        <section className="py-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h2 className="mb-1 mt-space">{initiative.name}</h2>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="bg-white py-4 pb-5">
        {initiative && (
          <div className="container-fluid">
            <div className="row content">
              {photo && (
                <div className="col-12 my-4 d-xl-none">
                  <div className="round-img" style={imgStyle}></div>
                </div>
              )}
              <div className="col-md-8 col-lg-12 col-xl-10 two-cols-lg">
                <div className="break-inside-avoid">
                  <div className="font-sans-s color-black-50 mt-4">
                    {getTranslation({ key: 'description', language })}
                  </div>
                  <div className="font-serif-m mt-1 mr-sm-5">
                    {language === 'en' ? initiative.description_en : initiative.description}
                  </div>
                </div>

                {initiative.interest.length > 0 && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'interests', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      {interests.map((interest, j) => {
                        const interestName = getTranslation({
                          key: typeof interest === 'string' ? interest : interest.name,
                          categoryKey: 'interestslist',
                          language,
                        });
                        return (
                          <span key={j}>
                            {j > 0 ? ', ' : ''}
                            {interestName}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}

                {initiative.category.length > 0 && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'category', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      {initiative.category.map((category, j) => {
                        const interestName = getTranslation({
                          key: typeof category === 'string' ? category : category.name,
                          language,
                        });
                        return (
                          <span key={j}>
                            {j > 0 ? ', ' : ''}
                            {interestName}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}

                {initiative.target.length > 0 && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    {initiative.target && (
                      <div className="font-sans-s color-black-50 mt-4">
                        {getTranslation({ key: 'target', language })}
                      </div>
                    )}

                    {targetb2b.length > 0 && (
                      <div className="font-serif-m mt-1">
                        <div>
                          <div className="font-serif-m mr-sm-5">
                            <GroupsIcon className="color-black-50"/> {getTranslation({ key: 'b2b', language })}:
                          </div>
                          {targetb2b
                            .filter((target) => targetb2b.indexOf(target) < targetb2b.length - 1)
                            .map((target) => (
                              <span>
                                {getTranslation({
                                  key: target,
                                  language,
                                }) + ', '}
                              </span>
                            ))}
                          <span>
                            {getTranslation({
                              key: targetb2b[targetb2b.length - 1],
                              language,
                            })}
                          </span>
                        </div>
                      </div>
                    )}

                    {targetb2c.length > 0 && (
                      <div className="font-serif-m mt-1">
                        <div>
                          <div className="font-serif-m mt-2 mr-sm-5">
                            <GroupsIcon className="color-black-50"/> {getTranslation({ key: 'b2c', language })}:
                          </div>
                          {targetb2c
                            .filter((target) => targetb2c.indexOf(target) < targetb2c.length - 1)
                            .map((target) => (
                              <span key={target}>
                                {getTranslation({
                                  key: target,
                                  language,
                                }) + ', '}
                              </span>
                            ))}
                          <span>
                            {targetb2c[targetb2c.length - 1] === 'undeterminedB2C'
                              ? ''
                              : getTranslation({
                                  key: targetb2c[targetb2c.length - 1],
                                  language,
                                })}
                          </span>
                        </div>
                      </div>
                    )}

                    {targetb2c.length === 0 && targetb2b.length === 0 && (
                      <div className="font-serif-m mt-1">
                        <span>
                          {getTranslation({
                            key: 'no_targets_given',
                            language,
                          })}
                        </span>
                      </div>
                    )}

                    {/* <div className="font-serif-m mt-1">
                        {target.map((t, j) => {
                          
                          const targetName = getTranslation({key: t, language})
                          return (
                            <span key={j}>
                              {j > 0 ? ', ' : ''}
                              {targetName}
                            </span>
                          );
                        })}
                      </div>*/}
                  </div>
                )}

                {initiative.price && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">{getTranslation({ key: 'price', language })}</div>
                    <div className="font-serif-m mt-1">
                      {(initiative.price && initiative.price > 0) ? (
                        <div>{initiative.price} euro</div>
                      ) : (
                        <div>{getTranslation({ key: 'free', language })}</div>
                      )}
                    </div>
                  </div>
                )}

                {initiative.capacity && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'capacity', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      <div>{initiative.capacity}</div>
                    </div>
                  </div>
                )}

                {initiative.registration && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'registration', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      <div>{initiative.registration}</div>
                    </div>
                  </div>
                )}

                {initiative.funding && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'funding', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      <div><SavingsIcon className="color-black-50"/> {initiative.funding}</div>
                    </div>
                  </div>
                )}

                {(initiative.contact_email || initiative.contact_tel) && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'contacts', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      <div><EmailIcon className="color-black-50"/> {initiative.contact_email && (
                          <>
                            <a href={`mailto:${initiative.contact_email}`} className="hover_email">{initiative.contact_email}</a>
                          </>
                        )}
                        { initiative.contact_tel ? <div><PhoneIcon className="color-black-50" /> {initiative.contact_tel}</div> : ''}
                      </div>
                    </div>
                  </div>
                )}

                {initiative.website && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'website', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      <div><LanguageIcon className="color-black-50"/> <a href={addhttp(initiative.website)} target="_blank" className="external hover_email" rel="noreferrer">
                        {cleanUrlText(initiative.website)}
                      </a></div>
                    </div>
                  </div>
                )}

                {initiative.address && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'location', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      <div><LocationOnIcon className="color-black-50"/> {initiative.address}</div>
                    </div>
                  </div>
                )}

                {(initiative.start_date ||
                  initiative.start_date_hour ||
                  initiative.end_date ||
                  initiative.end_date_hour) && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'event_date', language })}
                    </div>


                    { (initiative.start_date && initiative.end_date) && (
                      <div className="font-serif-m mt-1">
                        { getTranslation({ key: 'period', language}) }: {reformatDate(initiative.start_date)} - {reformatDate(initiative.end_date)}
                      </div>
                    )}
                    { (initiative.start_date == null || initiative.end_date == null) && (
                      <div className="font-serif-m mt-1">
                        { initiative.start_date !== null && (
                          <p>{getTranslation({ key: 'start_date', language })} : {reformatDate(initiative.start_date)}</p>
                        )}
                        { initiative.end_date !== null && (
                          <p>{getTranslation({ key: 'end_date', language })} : {reformatDate(initiative.end_date)} </p>
                        )}
                      </div>
                    )}

                    {initiative.opening_hours?.split(' - ')[0] && (
                      <div className="font-serif-m mt-1">
                        {getTranslation({ key: 'opening_hour', language, })}: {initiative.opening_hours?.split(' - ')[0]}
                      </div>
                    )}
                    {initiative.opening_hours?.split(' - ')[1] && (
                      <div className="font-serif-m mt-1">
                        {getTranslation({ key: 'closing_hour', language })}: {initiative.opening_hours?.split(' - ')[1]}
                      </div>
                    )}
                  </div>
                )}

                {/* {initiative?.user_id && (
                  <>
                    {sessionStorage.getItem('stytch-user') && (
                      <>
                        {JSON.parse(sessionStorage.getItem('stytch-user'))?.user_id !== initiative?.user_id && (
                          <div className="break-inside-avoid mr-xl-5 pt-1 font-sans-s font-700 color-c2">
                            <LinkScrollTop className="color-c2" to={`/suggestion/initiative/${initiative.id}`}>
                              {getTranslation({ key: 'suggest', language })}
                            </LinkScrollTop>
                          </div>
                        )}
                      </>
                    )}

                    {sessionStorage.getItem('stytch-user') === null && (
                      <div className="break-inside-avoid mr-xl-5 pt-1 color-c2">
                        <LinkScrollTop className="color-c2" to={`/suggestion/initiative/${initiative.id}`}>
                          {getTranslation({ key: 'suggest', language })}
                        </LinkScrollTop>
                      </div>
                    )}
                  </>
                )} */}
              </div>
              {photo && (
                <div className="col-md-2 my-4 d-none d-xl-flex justify-content-end">
                  <div className="round-img" style={imgStyle}></div>
                </div>
              )}
            </div>
            {sessionStorage.getItem('stytch-user') && (initiative?.user_id === null || initiative?.user_id === '') && (
              <div>
                <div className="font-sans-s color-black-50 mt-4">{getTranslation({ key: 'claim', language })}</div>

                <div className="font-serif-m mt-1">
                  <div>
                    <p
                      className="color-c3 font-serif-m font-strong-m my-1 cursor-pointer claim-button"
                      onClick={(event) => handleClaim(event)}
                    >
                      Claim
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </section>

      {(initiative?.expert?.length > 0 || initiative?.stakeholder?.length > 0) && (
        <section className="stakeholders-cards py-4 pb-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h2 className="mt-space-2 mb-space">{getTranslation({ key: 'related', language })}</h2>
              </div>
            </div>
            <div className="row">
              {initiative.expert &&
                initiative.expert.map((d, i) => {
                  if (!d.interest) {
                    d.interest = [];
                  }
                  return <ExpertCard d={d} key={i} />;
                })}
              {initiative.stakeholder &&
                initiative.stakeholder.map((d, i) => {
                  if (!d.interest) {
                    d.interest = [];
                  }
                  return <StakeholderCard d={d} key={i} />;
                })}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Initiative;
