import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" className="container d-flex flex-column justify-content-center align-items-center vh-100">
      <h1 className="display-1 font-weight-bold">Server Error</h1>
      <p className="lead">Oops! Something went wrong.</p>
      <p className="lead mb-4">Please contact the owner of the site.</p>
      <p className="mb-4">
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
