import { useQuery } from '@tanstack/react-query';
import { flatten } from 'lodash';
import React from 'react';
import { getTranslation } from '../dictionary';
import { expertsQueryOptions } from '../services/ExpertService';
import { initiativesQueryOptions } from '../services/InitiativeService';
import { stakeholdersQueryOptions } from '../services/StakeholderService';
import { useLocaleStore } from '../store';
import ExpertCard from './ExpertCard';
import InitiativeCard from './InitiativeCard';
import LinkScrollTop from './LinkScrollTop';
import StakeholderCard from './StakeholderCard';

const Cards = ({ selected }) => {
  const language = useLocaleStore((state) => state.language);

  const { data: initiatives } = useQuery(initiativesQueryOptions);
  const { data: experts } = useQuery(expertsQueryOptions);
  const { data: stakeholders } = useQuery(stakeholdersQueryOptions);

  const selCards = {
    initiatives,
    experts,
    stakeholders,
  }[selected];

  const renderCard = (data, i) => {
    switch (selected) {
      case 'experts':
        return <ExpertCard d={data} key={i} />;
      case 'stakeholders':
        return <StakeholderCard d={data} key={i} />;
      case 'initiatives':
        return <InitiativeCard d={data} key={i} />;
      default:
        return null;
    }
  };

  return (
    <div className="container-fluid">
      <div className="row stakeholders-cards-container">
        {selCards && flatten(selCards).slice(0, 6).map(renderCard)}
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <LinkScrollTop className="button l my-space-3 rounded-3" to={`/${selected}`}>
            {`${getTranslation({ key: 'see_all', language })} ${getTranslation({ key: selected, language })}`}
          </LinkScrollTop>
        </div>
      </div>
    </div>
  );
};

export default Cards;
