import { useEffect, useState } from 'react';
import { useStytchUser } from '@stytch/react';

const useStytchUserLoader = () => {
  const { user: stytchUser } = useStytchUser();
  const [user, setUser] = useState(() => {
    const savedUser = sessionStorage.getItem('stytch-user');
    return savedUser ? JSON.parse(savedUser) : stytchUser;
  });

  useEffect(() => {
    if (stytchUser) {
      sessionStorage.setItem('stytch-user', JSON.stringify(stytchUser));
      setUser(stytchUser);
    }
  }, [stytchUser]);

  return { user };
};

export default useStytchUserLoader;
