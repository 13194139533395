import { queryOptions } from '@tanstack/react-query';
import axios from '../axios';
import { transformEntity } from '../utils';

export const fetchStakeholders = async () => {
  const response = (await axios.get(`/stakeholders?populate=*`)).data;
  const stakeholders = response.data.map((stakeholders) => {
    return transformEntity(stakeholders);
  });
  return stakeholders;
};

export const stakeholdersQueryOptions = queryOptions({
  queryKey: ['stakeholders'],
  queryFn: fetchStakeholders,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
});

export const fetchUserStakeholders = async (userId) => {
  const response = (await axios.get(`/stakeholders?filters[user_id][$eq]=${userId}&populate=*`)).data;
  const stakeholders = response.data.map((stakeholders) => {
    return transformEntity(stakeholders);
  });
  return stakeholders;
};

export const updateUserStakeholder = async (id, newdata) =>
  await axios.put(`/stakeholders/${id}`, { data: newdata });

export const fetchStakeholder = async (id) => {
  const response = (await axios.get(`/stakeholders/${id}?populate=*`)).data;
  return transformEntity(response.data);
};

export const createUserStakeholder = async (data) => await axios.post(`/stakeholders`, data);

export const claimStakeholder = async (id, data) =>
  await axios.put(`/stakeholders/claim/${id}`, data).then(() => {
    window.location.reload();
  });
