import { useQuery } from '@tanstack/react-query';
import { default as React } from 'react';
import { useLoaderData } from 'react-router-dom';
import ExpertCard from '../components/ExpertCard';
import InitiativeCard from '../components/InitiativeCard';
import Loading from '../components/Loading';
import { getTranslation } from '../dictionary';
import { claimStakeholder, fetchStakeholder } from '../services/StakeholderService';
import { apiUrl } from '../settings';
import { useLocaleStore } from '../store';
import { addhttp, cleanUrlText, getLogoIfPresent } from '../utils';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import GroupsIcon from '@mui/icons-material/Groups';
import LanguageIcon from '@mui/icons-material/Language';

function Stakeholder() {
  const language = useLocaleStore((state) => state.language);
  const { id } = useLoaderData();

  const { isLoading, error, data } = useQuery({
    queryKey: [`stakeholder${id}`],
    queryFn: async () => fetchStakeholder(id),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  if (isLoading) return <Loading />;

  if (error)
    return (
      <div className="stakeholder-page initiative">
        {
          <section className="py-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h2 className="mb-1 mt-space">{error.message}</h2>
                </div>
              </div>
            </div>
          </section>
        }
      </div>
    );

  const stakeholder = data;
  const logo = getLogoIfPresent(stakeholder);
  const imgStyle = {
    backgroundImage: 'url(' + apiUrl?.slice(0, -4) + logo + ')',
    //backgroundSize: 'contain', // Scales the image to fit within the container
    backgroundSize: 'cover', // Scales the image to cover the container
    backgroundPosition: 'center center', // Centers the image within the container
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  };

  const interests = stakeholder.interest;
  const activities = stakeholder.sector;
  const targetb2b = stakeholder.target.filter((target) => target.type === 'b2b').map((target) => target.name);
  const targetb2c = stakeholder.target.filter((target) => target.type === 'b2c').map((target) => target.name);

  const handleClaim = (event) => {
    event.stopPropagation();
    const newData = {
      data: {
        user_id: JSON.parse(sessionStorage.getItem('stytch-user'))?.user_id,
      },
    };

    claimStakeholder(stakeholder.id, newData);
  };


  return (
    <div className="stakeholder-page stakeholder">
      {stakeholder && (
        <section className="cards-wrapper py-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h2 className="mb-1 mt-space">{stakeholder.name}</h2>
                <h5 className="color-c2 font-strong-m mt-2 pb-2">
                  {getTranslation({ key: 'stakeholder', language })}
                  {stakeholder.website && ' — '}
                  {stakeholder.website && (
                    <a
                      href={addhttp(stakeholder.website)}
                      target="_blank"
                      className="font-strong-m external color-purple"
                      rel="noreferrer"
                    >
                      {cleanUrlText(stakeholder.website)}
                    </a>
                  )}
                </h5>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="bg-white py-4 pb-5">
        {stakeholder && (
          <div className="container-fluid card-indicator">
            <div className="row content">
              {logo && (
                <div className="col-12 my-4 d-xl-none">
                  <div className="round-img" style={imgStyle}></div>
                </div>
              )}
              <div className="col-md-8 col-lg-12 col-xl-10 two-cols-lg">
                <div className="break-inside-avoid">
                  <div className="font-sans-s color-black-50 mt-4">
                    {getTranslation({ key: 'description', language })}
                  </div>
                  <div className="font-serif-m mt-1 mr-sm-5">
                    {language === 'en' ? stakeholder.description_en : stakeholder.description}
                  </div>
                </div>

                {interests.length > 0 && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'interests', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      {interests.map((interest, j) => {
                        const interestName = getTranslation({
                          key: interest.name,
                          categoryKey: 'interestslist',
                          language,
                        });
                        return (
                          <span key={j}>
                            {j > 0 ? ', ' : ''}
                            {interestName}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}

                {activities.length > 0 && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'activities', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      {activities.map((activity, j) => {
                        const activityName = getTranslation({
                          key: activity.name,
                          language,
                        });
                        return (
                          <span key={j}>
                            {j > 0 ? ', ' : ''}
                            {activityName}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}

                {stakeholder.target.length > 0 && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    {stakeholder.target && (
                      <div className="font-sans-s color-black-50 mt-4">
                        {getTranslation({ key: 'target', language })}
                      </div>
                    )}

                    {targetb2b.length > 0 && (
                      <div className="font-serif-m mt-1">
                        <div>
                          <div className="font-serif-m mr-sm-5">
                            <GroupsIcon className="color-black-50" /> {getTranslation({ key: 'b2b', language })}:
                          </div>
                          {targetb2b
                            .filter((target) => {
                              return targetb2b.indexOf(target) < targetb2b.length - 1;
                            })
                            .map((target) => (
                              <span key={target}>
                                {target === 'undeterminedB2B'
                                  ? ''
                                  : getTranslation({
                                      key: target,
                                      language,
                                    }) + ', '}
                              </span>
                            ))}
                          <span>
                            {targetb2b[targetb2b.length - 1] === 'undeterminedB2B'
                              ? ''
                              : getTranslation({
                                  key: targetb2b[targetb2b.length - 1],
                                  language,
                                })}
                          </span>
                        </div>
                      </div>
                    )}

                    {targetb2c.length > 0 && (
                      <div className="font-serif-m mt-1">
                        <div>
                          <div className="font-serif-m mt-2 mr-sm-5">
                            <GroupsIcon className="color-black-50" /> {getTranslation({ key: 'b2c', language })}:
                          </div>
                          {targetb2c
                            .filter((target) => {
                              return targetb2c.indexOf(target) < targetb2c.length - 1;
                            })
                            .map((target) => (
                              <span>
                                {getTranslation({
                                  key: target,
                                  language,
                                }) + ', '}
                              </span>
                            ))}
                          <span>
                            {getTranslation({
                              key: targetb2c[targetb2c.length - 1],
                              language,
                            })}
                          </span>
                        </div>
                      </div>
                    )}

                    {targetb2c.length === 0 && targetb2b.length === 0 && (
                      <div className="font-serif-m mt-1">
                        <span>
                          {getTranslation({
                            key: 'no_targets_given',
                            language,
                          })}
                        </span>
                      </div>
                    )}

                    {/* <div className="font-serif-m mt-1">
                        {target.map((t, j) => {
                          
                          const targetName = getTranslation({key: t, language});
                          return (
                            <span key={j}>
                              {j > 0 ? ', ' : ''}
                              {targetName}
                            </span>
                          );
                        })}
                      </div>*/}
                  </div>
                )}

                {(stakeholder.contact_email || stakeholder.contact_tel) && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'contacts', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      <div className="py-1">
                        {' '}
                        <EmailIcon className="color-black-50" />{' '}
                        {stakeholder.contact_email && (
                          <>
                            <a href={`mailto:${stakeholder.contact_email}`} className="hover_email">{stakeholder.contact_email}</a>
                          </>
                        )}
                        {stakeholder.contact_tel ? (
                          <div>
                            <PhoneIcon className="color-black-50" /> {stakeholder.contact_tel}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {stakeholder.website && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'website', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      <div><LanguageIcon className="color-black-50"/> <a href={addhttp(stakeholder.website)} target="_blank" className="external hover_email" rel="noreferrer">
                        {cleanUrlText(stakeholder.website)}
                      </a></div>
                    </div>
                  </div>
                )}

                {stakeholder.address && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'location', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      <div>
                        <LocationOnIcon className="color-black-50" /> {stakeholder.address}
                      </div>
                    </div>
                  </div>
                )}

                <div className="break-inside-avoid mr-xl-5 pt-1">
                  {stakeholder.opening_hours &&
                    (stakeholder.opening_hours.monday ||
                      stakeholder.opening_hours.tuesday ||
                      stakeholder.opening_hours.wednesday ||
                      stakeholder.opening_hours.thursday ||
                      stakeholder.opening_hours.friday ||
                      stakeholder.opening_hours.saturday ||
                      stakeholder.opening_hours.sunday) && (
                      <div className="font-sans-s color-black-50 mt-4">
                        {getTranslation({ key: 'opening_hours', language })}
                      </div>
                    )}

                  {stakeholder.opening_hours && stakeholder.opening_hours.monday && (
                    <div className="font-serif-m mt-1">
                      {getTranslation({ key: 'monday', language })}: {stakeholder.opening_hours.monday!=="?" ? stakeholder.opening_hours.monday : getTranslation({ key: 'unknown', language })}
                    </div>
                  )}
                  {stakeholder.opening_hours && stakeholder.opening_hours.tuesday && (
                    <div className="font-serif-m mt-1">
                      {getTranslation({ key: 'tuesday', language })}: {stakeholder.opening_hours.tuesday!=="?" ? stakeholder.opening_hours.tuesday : getTranslation({ key: 'unknown', language })}
                    </div>
                  )}
                  {stakeholder.opening_hours && stakeholder.opening_hours.wednesday && (
                    <div className="font-serif-m mt-1">
                      {getTranslation({ key: 'wednesday', language })}: {stakeholder.opening_hours.wednesday!=="?" ? stakeholder.opening_hours.wednesday : getTranslation({ key: 'unknown', language })}
                    </div>
                  )}
                  {stakeholder.opening_hours && stakeholder.opening_hours.thursday && (
                    <div className="font-serif-m mt-1">
                      {getTranslation({ key: 'thursday', language })}: {stakeholder.opening_hours.thursday!=="?" ? stakeholder.opening_hours.thursday : getTranslation({ key: 'unknown', language })}
                    </div>
                  )}
                  {stakeholder.opening_hours && stakeholder.opening_hours.friday && (
                    <div className="font-serif-m mt-1">
                      {getTranslation({ key: 'friday', language })}: {stakeholder.opening_hours.friday!=="?" ? stakeholder.opening_hours.friday : getTranslation({ key: 'unknown', language })}
                    </div>
                  )}
                  {stakeholder.opening_hours && stakeholder.opening_hours.saturday && (
                    <div className="font-serif-m mt-1">
                      {getTranslation({ key: 'saturday', language })}: {stakeholder.opening_hours.saturday!=="?" ? stakeholder.opening_hours.saturday : getTranslation({ key: 'unknown', language })}
                    </div>
                  )}
                  {stakeholder.opening_hours && stakeholder.opening_hours.sunday && (
                    <div className="font-serif-m mt-1">
                      {getTranslation({ key: 'sunday', language })}: {stakeholder.opening_hours.sunday!=="?" ? stakeholder.opening_hours.sunday : getTranslation({ key: 'unknown', language })}
                    </div>
                  )}
                </div>

                {/* {stakeholder?.user_id && (
                  <>
                    {sessionStorage.getItem('stytch-user') && (
                      <>
                        {JSON.parse(sessionStorage.getItem('stytch-user'))?.user_id !== stakeholder?.user_id && (
                          <div className="break-inside-avoid mr-xl-5 pt-1">
                            <LinkScrollTop className="color-c2" to={`/suggestion/stakeholder/${stakeholder.id}`}>
                              {getTranslation({ key: 'suggest', language })}
                            </LinkScrollTop>
                          </div>
                        )}
                      </>
                    )}

                    {sessionStorage.getItem('stytch-user') === null && (
                      <div className="break-inside-avoid mr-xl-5 pt-1">
                        <button
                          className="color-c2"
                          onClick={() => navigate(`/suggestion/stakeholder/${stakeholder.id}`)}
                        >
                          {getTranslation({ key: 'suggest', language })}
                        </button>
                      </div>
                    )}
                  </>
                )} */}
              </div>
              {logo && (
                <div className="col-md-2 my-4 d-none d-xl-flex justify-content-end">
                  <div className="round-img" style={imgStyle}></div>
                </div>
              )}
            </div>
            {sessionStorage.getItem('stytch-user') &&
              (stakeholder?.user_id === null || stakeholder?.user_id === '') && (
                <div>
                  <div className="font-sans-s color-black-50 mt-4">{getTranslation({ key: 'claim', language })}</div>
                  <div className="font-serif-m mt-1">
                    <div>
                      <p
                        className="color-c2 font-serif-m font-strong-m my-1 cursor-pointer claim-button"
                        onClick={(event) => handleClaim(event)}
                      >
                        Claim
                      </p>
                    </div>
                  </div>
                </div>
              )}
          </div>
        )}
      </section>

      {(stakeholder.initiative.length > 0 || stakeholder.expert.length > 0) && (
        <section className="stakeholders-cards py-4 pb-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h2 className="mt-space-2 mb-space">{getTranslation({ key: 'related', language })}</h2>
              </div>
            </div>
            <div className="row">
              {stakeholder.initiative.map((d, i) => (
                <InitiativeCard d={d} key={i} />
              ))}
              {stakeholder.expert.map((d, i) => {
                if (!d.interest) {
                  d.interest = [];
                }
                return <ExpertCard d={d} key={i} />;
              })}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Stakeholder;
