import { useStytch } from '@stytch/react';
import React, { useCallback } from 'react'; // Import useCallback for performance optimization
import x from '../assets/images/menu-x.svg';
import LinkScrollTop from '../components/LinkScrollTop';
import { getTranslation } from '../dictionary';
import { useLocaleStore } from '../store';
import { closeMenuMobile } from '../utils';

function MenuMobile() {
  const language = useLocaleStore((state) => state.language);
  const setLanguage = useLocaleStore((state) => state.setLanguage);

  const stytchClient = useStytch();
  const user = JSON.parse(sessionStorage.getItem('stytch-user'));

  // Extracted the callback to avoid recreation on each render
  const handleLogout = useCallback(() => {
    sessionStorage.removeItem('stytch-user');
    sessionStorage.removeItem('stytch-session');
    stytchClient.session.revoke();
  }, [stytchClient.session]);

  // Function to reload the page after 1 second
  const reloadPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <nav id="menu-mobile">
      <div className="top">
        <LinkScrollTop className="font-strong-m color-white hover-white-50" to={'/'}>
          EcoFoodMap <span className="font-serif-m header-serif">Leuven</span>
        </LinkScrollTop>

        <a href="#BOTTOM" onClick={closeMenuMobile} className="color-white-50">
          <img src={x} alt="closeMenuMobile" />
        </a>
      </div>

      <div className="middle">
        {['/', '/indicators', '/experts', '/initiatives', '/stakeholders', '/about'].map((path) => (
          <LinkScrollTop key={path} className="item-large" to={path}>
            {getTranslation({ key: path.slice(1), language })}
          </LinkScrollTop>
        ))}

        {/* Render sign-up link if no user */}
        {!user && (
          <LinkScrollTop to={'/sign-up'} className="item-large">
            {getTranslation({ key: 'doe_mee', language })}
          </LinkScrollTop>
        )}

        {/* Render user data link and logout link if user */}
        {user && (
          <>
            <LinkScrollTop
              to={'/data'}
              className="item-large"
              onClick={(e) => {
                e.preventDefault();
                reloadPage(); // Call the reloadPage function
                window.location.href = '/data';
              }}
            >
              {getTranslation({ key: 'user_info', language })}
            </LinkScrollTop>
            <a href="/" className="item-large" onClick={handleLogout}>
              Logout
            </a>
          </>
        )}
      </div>

      <div className="bottom">
        <span>
          <a
            href="#BOTTOM"
            className={`xs-language-el ${language === 'en' ? 'active' : ''}`}
            onClick={() => setLanguage('en')}
          >
            ENGLISH
          </a>

          <span className="color-white-50">{' · '}</span>
          <a
            href="#BOTTOM"
            className={`xs-language-el ${language === 'nl' ? 'active' : ''}`}
            onClick={() => setLanguage('nl')}
          >
            NEDERLANDS
          </a>
        </span>
      </div>
    </nav>
  );
}

export default MenuMobile;
