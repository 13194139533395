import React from 'react';
import { getTranslation } from '../dictionary';
import { formatNumberWithCommas } from '../utils';
import { useLocaleStore } from '../store';

const customTooltipStyle = {
  background: 'white',
  color: '#333',
  boxShadow: '0 3px 4px rgba(0, 0, 0, 0.4)',
  padding: '2px',
  textAlign: 'left',
  borderRadius: '6px',
};

const tooltipDetailsStyle = {
  margin: '13px 19px',
};

const unstyledListStyle = {
  listStyle: 'none',
  padding: 0,
  margin: 0,
};

const TrendTooltip = ({ active, payload, indicator }) => {
  const { language } = useLocaleStore((state) => state);

  if (active && payload && payload.length) {
    return (
      <div style={customTooltipStyle}>
        <div style={tooltipDetailsStyle}>
          <ul style={unstyledListStyle}>
            <li key="data" className="stat-text fs-1">
              {formatNumberWithCommas(payload[0].payload.data)}
            </li>
            <li key="unit" className="stat-unit">
              {getTranslation({ key: indicator.dataset[0].unit, language })}
            </li>
            <li key="year" className="stat-unit">
              {payload[0].payload.year}
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return null;
};

export default TrendTooltip;
