import React from 'react';
import logos from '../assets/images/logos.png';
import { getTranslation } from '../dictionary';
import { useLocaleStore } from '../store';
import LinkScrollTop from './LinkScrollTop';

function Footer() {
  const language = useLocaleStore((state) => state.language);

  const renderLink = (to, { key, categoryKey, language }) => (
    <LinkScrollTop className="color-white font-serif-m my-1 cursor-pointer hoverFooterLink d-block" to={to} key={key}>
      {getTranslation({ key, categoryKey, language })}
    </LinkScrollTop>
  );

  return (
    <footer id="footer">
      <div className="container-fluid">
        <div className="row py-5">
          <div className="col-12">
            <p className="color-white font-strong-ml">EcoFoodMap</p>
            <p className="color-white font-serif-l">Leuven</p>
          </div>
        </div>

        <div className="row pb-5">
          <div className="col-xl-4 col-md-6">
            <p className="color-white-50 font-sans-s">{getTranslation({ key: 'indicators', language })}</p>
            {[
              'social_sustainability',
              'economic_sustainability',
              'urban_rural_integration',
              'environmental_sustainability',
              'food_governance',
            ].map((category) =>
              renderLink(`/indicators/indicator_category/${category}`, {
                key: category,
                categoryKey: 'indicatorCategories',
                language,
              }),
            )}
            <p className="color-white-50 font-sans-s my-4"></p>
          </div>

          <div className="col-xl-4 col-md-6">
            <p className="color-white-50 font-sans-s">{getTranslation({ key: 'stakeholder_ecosystem', language })}</p>
            {['stakeholders', 'experts', 'initiatives'].map((section) =>
              renderLink(`/${section}`, { key: section, language }),
            )}
            <p className="color-white-50 font-sans-s my-4"></p>
            <p className="color-white-50 font-sans-s">{getTranslation({ key: 'information', language })}</p>
            {['about', 'privacy_cookies'].map((section) => renderLink(`/${section}`, { key: section, language }))}
          </div>
        </div>

        <div className="row pb-5">
          <div className="col-xl-4 col-md-6 mt-2">
            <p className="color-white-50 font-sans-s">{getTranslation({ key: 'supported', language })}</p>
          </div>
          <div className="col-xl-5 col-md-6 mt-2 position-relative">
            <img className="logos" src={logos} alt="logos" />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
