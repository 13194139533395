const SvgNodesEn = ({ handleClick }) => {
  return (
    <g onClick={handleClick} id="nodes">
      <g onClick={handleClick} id="100">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M405,2120.2c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C384.6,2074.5,405,2095,405,2120.2"
        />
        <g>
          <path d="M341.2,2109v1h-4.5v2.7h3.9v1h-3.9v3h4.5v1h-5.7v-8.7H341.2z" />
          <path d="M344.8,2115.2l-1.8,2.5h-1.2l2.3-3.3l-2.1-3h1.3l1.5,2.2l1.6-2.2h1.2l-2.1,3l2.3,3.3h-1.2L344.8,2115.2z" />
          <path
            d="M349,2112.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6L349,2112.3L349,2112.3z"
          />
          <path
            d="M352.5,2114.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C353.7,2117.8,352.5,2116.5,352.5,2114.5z M353.6,2113.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C354.5,2112.2,353.8,2112.8,353.6,2113.9z"
          />
          <path
            d="M359.5,2111.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2111.3z"
          />
          <path
            d="M366.6,2112.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6L366.6,2112.3L366.6,2112.3z"
          />
          <path
            d="M376.9,2117.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C379.9,2116.5,378.6,2117.8,376.9,2117.8z
				 M376.9,2116.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C375.1,2116,375.8,2116.9,376.9,2116.9z"
          />
          <path
            d="M384,2109.8c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2109.8z"
          />
        </g>
        <g>
          <path
            d="M337.5,2130.3v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C338.6,2131.4,337.9,2130.9,337.5,2130.3z M337.5,2128.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2128.3z"
          />
          <path
            d="M346.2,2131.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C349.1,2130,347.9,2131.4,346.2,2131.4z
				 M346.2,2130.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C344.3,2129.5,345,2130.4,346.2,2130.4z"
          />
          <path d="M351.6,2131.2h-1.1v-8.7h1.1V2131.2z" />
          <path d="M354.5,2131.2h-1.1v-8.7h1.1V2131.2z" />
          <path
            d="M361.4,2131.2h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1L361.4,2131.2L361.4,2131.2z"
          />
          <path
            d="M363.4,2125.8h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6L363.4,2125.8L363.4,2125.8z"
          />
          <path d="M367.3,2122.5h1.2v1.3h-1.2V2122.5z M368.4,2131.2h-1.1v-6.4h1.1V2131.2z" />
          <path
            d="M372.8,2131.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C375.7,2130,374.5,2131.4,372.8,2131.4z
				 M372.8,2130.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C370.9,2129.5,371.7,2130.4,372.8,2130.4z"
          />
          <path
            d="M377.1,2124.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2124.9z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="99">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M2027.2,2229.2c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2006.7,2183.5,2027.2,2204,2027.2,2229.2"
        />
        <g>
          <path
            d="M1948.3,2225.8c1.1,0,1.9-0.7,2.3-1.8l1.1,0.4c-0.5,1.5-1.7,2.4-3.4,2.4c-2.3,0-3.9-1.8-3.9-4.5c0-2.7,1.7-4.5,3.9-4.5
				c1.7,0,2.9,0.9,3.4,2.4l-1.1,0.4c-0.3-1.1-1.1-1.8-2.3-1.8c-1.6,0-2.8,1.4-2.8,3.5S1946.7,2225.8,1948.3,2225.8z"
          />
          <path
            d="M1955.4,2226.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1958.4,2225.5,1957.1,2226.8,1955.4,2226.8z
				 M1955.4,2225.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1953.6,2225,1954.3,2225.9,1955.4,2225.9z"
          />
          <path
            d="M1959.9,2224.7c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1959.9,2224.7z"
          />
          <path
            d="M1965.8,2221.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2221.3z"
          />
          <path
            d="M1976.2,2226.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1979.1,2225.5,1977.9,2226.8,1976.2,2226.8z
				 M1976.2,2225.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1974.3,2225,1975,2225.9,1976.2,2225.9z"
          />
          <path
            d="M1983.3,2218.8c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2218.8z"
          />
          <path d="M1992.1,2220.3l1.3,4.7l1.3-4.7h1.1l-1.9,6.4h-1l-1.3-4.7l-1.3,4.7h-1l-1.9-6.4h1.1l1.3,4.7l1.3-4.7H1992.1z" />
          <path
            d="M1996.4,2225c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1997.3,2226.8,1996.4,2226.1,1996.4,2225z M2000.3,2223.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2223.4z"
          />
          <path
            d="M2003.4,2224.7c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2003.4,2224.7z"
          />
          <path
            d="M2009.3,2221.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2221.3z"
          />
          <path
            d="M2012.9,2223.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2014.1,2226.8,2012.9,2225.5,2012.9,2223.5z M2014,2222.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2014.9,2221.2,2014.2,2221.8,2014,2222.9z"
          />
        </g>
        <g>
          <path
            d="M1959.3,2237c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1960.3,2240.3,1959.3,2238.8,1959.3,2237z M1962.2,2234.7c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C1964,2235.3,1963.1,2234.7,1962.2,2234.7z"
          />
          <path d="M1966.9,2231.5h1.2v1.3h-1.2V2231.5z M1968,2240.2h-1.1v-6.4h1.1V2240.2z" />
          <path
            d="M1970,2238.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1970,2238.2z"
          />
          <path
            d="M1976.8,2239.3v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1977.9,2240.3,1977.2,2239.9,1976.8,2239.3z M1976.8,2237.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2237.3z"
          />
          <path
            d="M1985.5,2240.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1988.4,2239,1987.2,2240.3,1985.5,2240.3z
				 M1985.5,2239.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1983.6,2238.5,1984.4,2239.4,1985.5,2239.4
				z"
          />
          <path
            d="M1990,2238.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1990,2238.2z"
          />
          <path
            d="M1995.2,2238.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1996.2,2240.3,1995.2,2239.6,1995.2,2238.5z M1999.2,2237l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2237z"
          />
          <path d="M2003.2,2240.2h-1.1v-8.7h1.1V2240.2z" />
        </g>
      </g>
      <g onClick={handleClick} id="98">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1399.2,1670.3c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1378.7,1624.5,1399.2,1645,1399.2,1670.3"
        />
        <g>
          <path
            d="M1314.1,1666c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1315,1667.9,1314.1,1667.2,1314.1,1666z M1318,1664.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V1664.5z"
          />
          <path
            d="M1323.8,1659.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1659.9z"
          />
          <path
            d="M1327.5,1659.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1659.9z"
          />
          <path
            d="M1331,1667.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1333.9,1666.6,1332.7,1667.9,1331,1667.9z
				 M1331,1667c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1329.1,1666,1329.8,1667,1331,1667z"
          />
          <path
            d="M1338.6,1662.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1662.5z"
          />
          <path
            d="M1339.4,1664.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1340.4,1667.9,1339.4,1666.4,1339.4,1664.6z M1342.3,1662.2c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C1344.1,1662.9,1343.2,1662.2,1342.3,1662.2z"
          />
          <path
            d="M1346.5,1666c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1347.5,1667.9,1346.5,1667.2,1346.5,1666z M1350.5,1664.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1664.5z"
          />
          <path
            d="M1354.5,1666.8v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1355.6,1667.9,1354.9,1667.5,1354.5,1666.8z M1354.5,1664.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1664.8z"
          />
          <path d="M1360.6,1659h1.2v1.3h-1.2V1659z M1361.7,1667.8h-1.1v-6.4h1.1V1667.8z" />
          <path d="M1364.7,1667.8h-1.1v-8.7h1.1V1667.8z" />
          <path d="M1366.4,1659h1.2v1.3h-1.2V1659z M1367.6,1667.8h-1.1v-6.4h1.1V1667.8z" />
          <path
            d="M1369.6,1662.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1662.3z"
          />
          <path
            d="M1373.9,1670c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C1375.5,1669.4,1375,1670,1373.9,1670z"
          />
          <path
            d="M1386.1,1667.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1389,1666.6,1387.8,1667.9,1386.1,1667.9z
				 M1386.1,1667c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1384.2,1666,1385,1667,1386.1,1667z"
          />
          <path
            d="M1393.2,1659.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1659.9z"
          />
        </g>
        <g>
          <path
            d="M1323.2,1679.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1324.2,1681.4,1323.2,1680.7,1323.2,1679.6z M1327.2,1678l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1678z"
          />
          <path
            d="M1333.4,1676c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1676z"
          />
          <path
            d="M1334.1,1679.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1335.1,1681.4,1334.1,1680.7,1334.1,1679.6z M1338.1,1678l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1678z"
          />
          <path
            d="M1342.1,1680.4v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1343.2,1681.4,1342.5,1681,1342.1,1680.4z M1342.1,1678.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1678.3z"
          />
          <path d="M1349.4,1681.3h-1.1v-8.7h1.1V1681.3z" />
          <path
            d="M1350.7,1678.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1352,1681.4,1350.7,1680.1,1350.7,1678.1z M1351.9,1677.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1352.7,1675.7,1352,1676.4,1351.9,1677.4z"
          />
          <path d="M1362.7,1681.3h-1.1v-8.7h1.1V1681.3z" />
          <path
            d="M1364,1679.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1365,1681.4,1364,1680.7,1364,1679.6z M1368,1678l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V1678z"
          />
          <path
            d="M1370.9,1674.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1674.9z"
          />
          <path
            d="M1377.4,1678.1c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1378.4,1681.4,1377.4,1679.9,1377.4,1678.1z M1380.3,1675.8c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C1382.1,1676.4,1381.2,1675.8,1380.3,1675.8z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="97">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1279.1,1886.6c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1258.6,1840.9,1279.1,1861.3,1279.1,1886.6"
        />
        <g>
          <path d="M1197.8,1875.3v1h-4.5v2.7h3.9v1h-3.9v3h4.5v1h-5.7v-8.7H1197.8z" />
          <path d="M1201.5,1881.6l-1.8,2.5h-1.2l2.3-3.3l-2.1-3h1.3l1.5,2.2l1.6-2.2h1.2l-2.1,3l2.3,3.3h-1.2L1201.5,1881.6z" />
          <path
            d="M1205.7,1878.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1878.7z"
          />
          <path
            d="M1209.2,1880.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1210.4,1884.2,1209.2,1882.9,1209.2,1880.9z M1210.3,1880.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1211.2,1878.5,1210.5,1879.2,1210.3,1880.2z"
          />
          <path
            d="M1216.2,1877.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1877.7z"
          />
          <path
            d="M1223.2,1878.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1878.7z"
          />
          <path
            d="M1233.6,1884.2c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1236.5,1882.9,1235.3,1884.2,1233.6,1884.2z
				 M1233.6,1883.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1231.7,1882.3,1232.5,1883.3,1233.6,1883.3
				z"
          />
          <path
            d="M1240.7,1876.2c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1876.2z"
          />
          <path
            d="M1245.5,1875.3h1.1v3.2c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1875.3z"
          />
          <path
            d="M1254.9,1884.2c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1257.9,1882.9,1256.6,1884.2,1254.9,1884.2z
				 M1254.9,1883.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1253.1,1882.3,1253.8,1883.3,1254.9,1883.3
				z"
          />
          <path
            d="M1260.3,1884.1h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1884.1z"
          />
          <path
            d="M1269.4,1880.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1270.6,1884.2,1269.4,1882.9,1269.4,1880.9z M1270.5,1880.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1271.4,1878.5,1270.7,1879.2,1270.5,1880.2z"
          />
        </g>
        <g>
          <path
            d="M1215.9,1889.7c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1889.7z"
          />
          <path
            d="M1216.4,1895.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1217.3,1897.7,1216.4,1897,1216.4,1895.9z M1220.3,1894.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1894.3z"
          />
          <path
            d="M1226.6,1892.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1892.3z"
          />
          <path
            d="M1228.9,1897.6h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1897.6z"
          />
          <path d="M1238.3,1888.9h1.2v1.3h-1.2V1888.9z M1239.5,1897.6h-1.1v-6.4h1.1V1897.6z" />
          <path
            d="M1241.3,1891.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1891.2z"
          />
          <path
            d="M1250.6,1899.9c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C1253.5,1898.9,1252.3,1899.9,1250.6,1899.9z M1250.7,1892.1c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C1252.5,1892.8,1251.6,1892.1,1250.7,1892.1z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="96">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1281,1442.8c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1260.5,1397,1281,1417.5,1281,1442.8"
        />
        <g>
          <path d="M1196.7,1424.8h1.2v7.7h4.4v1h-5.5V1424.8z" />
          <path
            d="M1202.9,1431.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1203.8,1433.6,1202.9,1432.9,1202.9,1431.8z M1206.8,1430.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1430.2z"
          />
          <path
            d="M1209.7,1427.1h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1427.1z"
          />
          <path
            d="M1216.2,1430.3c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1217.2,1433.6,1216.2,1432.1,1216.2,1430.3z M1219.1,1428c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C1220.9,1428.6,1220,1428,1219.1,1428z"
          />
          <path
            d="M1227.2,1431.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1228.1,1433.6,1227.2,1432.9,1227.2,1431.8z M1231.1,1430.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1430.2z"
          />
          <path d="M1235.7,1433.5l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H1235.7z" />
          <path
            d="M1239.7,1431.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1240.7,1433.6,1239.7,1432.9,1239.7,1431.8z M1243.7,1430.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1430.2z"
          />
          <path d="M1246.6,1424.8h1.2v1.3h-1.2V1424.8z M1247.7,1433.5h-1.1v-6.4h1.1V1433.5z" />
          <path d="M1250.7,1433.5h-1.1v-8.7h1.1V1433.5z" />
          <path
            d="M1252,1431.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1252.9,1433.6,1252,1432.9,1252,1431.8z M1255.9,1430.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1430.2z"
          />
          <path
            d="M1260,1432.6v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1261.1,1433.6,1260.4,1433.2,1260,1432.6z M1260,1430.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1430.5z"
          />
          <path d="M1267.2,1433.5h-1.1v-8.7h1.1V1433.5z" />
          <path
            d="M1268.6,1430.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1269.8,1433.6,1268.6,1432.3,1268.6,1430.3z M1269.7,1429.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1270.6,1427.9,1269.9,1428.6,1269.7,1429.6z"
          />
        </g>
        <g>
          <path
            d="M1198.4,1439.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1439.1z"
          />
          <path
            d="M1201.8,1447.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1204.8,1445.8,1203.6,1447.1,1201.8,1447.1z
				 M1201.8,1446.2c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1200,1445.3,1200.7,1446.2,1201.8,1446.2z"
          />
          <path
            d="M1209.5,1441.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1441.7z"
          />
          <path
            d="M1214.7,1445c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1214.7,1445z"
          />
          <path
            d="M1225.5,1447h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1447z"
          />
          <path
            d="M1227.5,1445c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1227.5,1445z"
          />
          <path
            d="M1233.4,1441.6h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1441.6z"
          />
          <path
            d="M1236.9,1445.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1237.8,1447.1,1236.9,1446.4,1236.9,1445.3z M1240.8,1443.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1443.7z"
          />
          <path d="M1243.7,1438.3h1.2v1.3h-1.2V1438.3z M1244.9,1447h-1.1v-6.4h1.1V1447z" />
          <path
            d="M1246.7,1440.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1440.6z"
          />
          <path
            d="M1253,1445.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1254,1447.1,1253,1446.4,1253,1445.3z M1257,1443.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V1443.7z"
          />
          <path
            d="M1261,1446.1v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1262.1,1447.1,1261.4,1446.7,1261,1446.1z M1261,1444.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1444.1z"
          />
          <path d="M1268.3,1447h-1.1v-8.7h1.1V1447z" />
          <path
            d="M1269.7,1443.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1270.9,1447.1,1269.7,1445.8,1269.7,1443.8z M1270.8,1443.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1271.6,1441.5,1271,1442.1,1270.8,1443.2z"
          />
        </g>
        <g>
          <path
            d="M1203.5,1452.7c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1452.7z"
          />
          <path
            d="M1207,1460.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1210,1459.3,1208.7,1460.7,1207,1460.7z
				 M1207,1459.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1205.2,1458.8,1205.9,1459.7,1207,1459.7z"
          />
          <path
            d="M1213.9,1460.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1216.8,1459.3,1215.6,1460.7,1213.9,1460.7z
				 M1213.9,1459.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1212,1458.8,1212.7,1459.7,1213.9,1459.7z"
          />
          <path
            d="M1217.8,1457.3c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1218.8,1460.7,1217.8,1459.1,1217.8,1457.3z M1220.7,1455c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C1222.5,1455.6,1221.6,1455,1220.7,1455z"
          />
          <path
            d="M1232.1,1452.7c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1452.7z"
          />
          <path
            d="M1232.5,1458.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1233.5,1460.6,1232.5,1459.9,1232.5,1458.8z M1236.5,1457.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1457.3z"
          />
          <path
            d="M1242.7,1455.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1455.2z"
          />
          <path
            d="M1245,1460.5h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1460.5z"
          />
          <path d="M1254.5,1451.8h1.2v1.3h-1.2V1451.8z M1255.6,1460.5h-1.1v-6.4h1.1V1460.5z" />
          <path
            d="M1257.4,1454.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1454.2z"
          />
          <path
            d="M1266.7,1462.8c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C1269.7,1461.8,1268.4,1462.8,1266.7,1462.8z M1266.8,1455c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C1268.6,1455.7,1267.7,1455,1266.8,1455z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="95">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M2109.3,1570.8c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.7-20.5-45.7-45.8c0-25.3,20.5-45.8,45.7-45.8
			C2088.8,1525,2109.3,1545.5,2109.3,1570.8"
        />
        <g>
          <path d="M2040.2,1554.7v-8.7h5.6v1h-4.5v2.8h3.8v1h-3.8v3.9H2040.2z" />
          <path d="M2047.2,1546h1.2v1.3h-1.2V1546z M2048.3,1554.7h-1.1v-6.4h1.1V1554.7z" />
          <path
            d="M2050.1,1548.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1548.3z"
          />
          <path
            d="M2056.5,1553c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2057.4,1554.8,2056.5,1554.1,2056.5,1553z M2060.4,1551.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1551.5z"
          />
          <path
            d="M2063.4,1548.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1548.3z"
          />
          <path
            d="M2072.8,1553.9c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2070.9,1553,2071.7,1553.9,2072.8,1553.9z"
          />
          <path d="M2076.5,1546h1.2v1.3h-1.2V1546z M2077.7,1554.7h-1.1v-6.4h1.1V1554.7z" />
          <path
            d="M2079,1553c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2079.9,1554.8,2079,1554.1,2079,1553z M2082.9,1551.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V1551.5z"
          />
          <path d="M2087,1554.7h-1.1v-8.7h1.1V1554.7z" />
        </g>
        <g>
          <path
            d="M2040.8,1562.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1562.9z"
          />
          <path
            d="M2041.5,1565.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2042.7,1568.4,2041.5,1567,2041.5,1565.1z M2042.6,1564.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2043.5,1562.7,2042.8,1563.3,2042.6,1564.4z"
          />
          <path
            d="M2048.7,1566.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2048.7,1566.3z"
          />
          <path
            d="M2057,1568.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2059.9,1567,2058.7,1568.4,2057,1568.4z
				 M2057,1567.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2055.1,1566.5,2055.8,1567.4,2057,1567.4z"
          />
          <path
            d="M2066.4,1568.2h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1568.2z"
          />
          <path
            d="M2071.5,1562.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1562.9z"
          />
          <path
            d="M2075.2,1567.4c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2073.3,1566.5,2074.1,1567.4,2075.2,1567.4z"
          />
          <path
            d="M2078.6,1565.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2079.8,1568.4,2078.6,1567,2078.6,1565.1z M2079.7,1564.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2080.5,1562.7,2079.9,1563.3,2079.7,1564.4z"
          />
          <path
            d="M2085.7,1566.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2085.7,1566.3z"
          />
        </g>
        <g>
          <path
            d="M2031.7,1580c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2032.6,1581.8,2031.7,1581.2,2031.7,1580z M2035.6,1578.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1578.5z"
          />
          <path d="M2039.7,1581.7h-1.1v-8.7h1.1V1581.7z" />
          <path d="M2042.6,1581.7h-1.1v-8.7h1.1V1581.7z" />
          <path
            d="M2046.9,1581.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2049.9,1580.5,2048.7,1581.9,2046.9,1581.9z
				 M2046.9,1580.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2045.1,1580,2045.8,1580.9,2046.9,1580.9z"
          />
          <path
            d="M2053.8,1580.9c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2051.9,1580,2052.7,1580.9,2053.8,1580.9z"
          />
          <path
            d="M2057.1,1580c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2058,1581.8,2057.1,1581.2,2057.1,1580z M2061,1578.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V1578.5z"
          />
          <path
            d="M2064.2,1576.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1576.3z"
          />
          <path
            d="M2067.7,1578.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2068.9,1581.9,2067.7,1580.5,2067.7,1578.6z M2068.8,1577.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2069.7,1576.2,2069,1576.8,2068.8,1577.9z"
          />
          <path
            d="M2074.3,1578.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2075.3,1581.9,2074.3,1580.4,2074.3,1578.6z M2077.2,1576.2c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C2079,1576.9,2078.1,1576.2,2077.2,1576.2z"
          />
          <path
            d="M2085.9,1576.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1576.3z"
          />
          <path
            d="M2092.4,1581.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2095.4,1580.5,2094.2,1581.9,2092.4,1581.9z
				 M2092.4,1580.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2090.6,1580,2091.3,1580.9,2092.4,1580.9z"
          />
        </g>
        <g>
          <path
            d="M2028.8,1593.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2028.8,1593.3z"
          />
          <path
            d="M2039.6,1595.3h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1595.3z"
          />
          <path
            d="M2041.5,1593.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2041.5,1593.3z"
          />
          <path
            d="M2047.5,1589.8h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1589.8z"
          />
          <path
            d="M2050.9,1593.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2051.9,1595.4,2050.9,1594.7,2050.9,1593.5z M2054.9,1592l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1592z"
          />
          <path d="M2057.8,1586.5h1.2v1.3h-1.2V1586.5z M2058.9,1595.3h-1.1v-6.4h1.1V1595.3z" />
          <path
            d="M2060.8,1588.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1588.9z"
          />
          <path
            d="M2067.1,1593.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2068.1,1595.4,2067.1,1594.7,2067.1,1593.5z M2071.1,1592l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1592z"
          />
          <path
            d="M2075.1,1594.3v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2076.2,1595.4,2075.5,1595,2075.1,1594.3z M2075.1,1592.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1592.3z"
          />
          <path d="M2081.2,1586.5h1.2v1.3h-1.2V1586.5z M2082.3,1595.3h-1.1v-6.4h1.1V1595.3z" />
          <path d="M2085.3,1595.3h-1.1v-8.7h1.1V1595.3z" />
          <path d="M2087,1586.5h1.2v1.3h-1.2V1586.5z M2088.2,1595.3h-1.1v-6.4h1.1V1595.3z" />
          <path
            d="M2090.2,1589.8h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1589.8z"
          />
          <path
            d="M2094.5,1597.5c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C2096.1,1596.9,2095.6,1597.5,2094.5,1597.5z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="94">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2276.5,794.8c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2256.1,749.1,2276.5,769.5,2276.5,794.8"
        />
        <g>
          <path
            d="M2199.2,776.8h2.8c2.7,0,4.3,1.7,4.3,4.4c0,2.7-1.7,4.4-4.3,4.4h-2.8V776.8z M2202,784.5c2,0,3.2-1.3,3.2-3.4
				c0-2-1.2-3.4-3.2-3.4h-1.6v6.7L2202,784.5L2202,784.5z"
          />
          <path
            d="M2207.2,782.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.4,1.8-2.6,1.8C2208.4,785.7,2207.2,784.3,2207.2,782.3z M2208.3,781.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2209.2,780,2208.5,780.6,2208.3,781.7z"
          />
          <path
            d="M2215.3,785.5h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V785.5
				z"
          />
          <path
            d="M2224.3,783.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2225.3,785.6,2224.3,784.9,2224.3,783.8z M2228.3,782.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V782.3z"
          />
          <path
            d="M2231.2,779.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V779.2z"
          />
          <path
            d="M2237.7,782.3c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2238.7,785.7,2237.7,784.1,2237.7,782.3z M2240.6,780c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C2242.4,780.6,2241.5,780,2240.6,780z"
          />
          <path
            d="M2251.9,777.7c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V777.7z"
          />
          <path
            d="M2255.4,785.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2258.4,784.3,2257.2,785.7,2255.4,785.7z M2255.4,784.7
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2253.6,783.8,2254.3,784.7,2255.4,784.7z"
          />
          <path
            d="M2263.1,780.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V780.2z"
          />
        </g>
        <g>
          <path d="M2188.3,799.2h-1.1v-8.7h1.1V799.2z" />
          <path
            d="M2192.6,799.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2195.6,798,2194.3,799.4,2192.6,799.4z
				 M2192.6,798.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2190.7,797.5,2191.5,798.4,2192.6,798.4z"
          />
          <path
            d="M2199.5,798.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2197.6,797.5,2198.4,798.4,2199.5,798.4z"
          />
          <path
            d="M2202.8,797.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2203.7,799.3,2202.8,798.7,2202.8,797.5z M2206.7,796l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V796z"
          />
          <path d="M2210.8,799.2h-1.1v-8.7h1.1V799.2z" />
          <path
            d="M2217.3,793.3c-0.1-0.3-0.2-0.6-0.2-0.8c0-0.6,0.2-1,0.6-1.4s0.9-0.6,1.5-0.6c0.6,0,1.1,0.2,1.4,0.5s0.5,0.8,0.5,1.2
				c0,0.6-0.2,1-0.5,1.5c-0.2,0.2-0.5,0.5-1,0.8l1.6,1.9c0.1-0.3,0.2-0.6,0.2-0.7c0-0.2,0.1-0.4,0.1-0.7h1c-0.1,0.6-0.2,1.1-0.4,1.6
				c-0.2,0.5-0.3,0.7-0.3,0.6l1.5,1.9h-1.4l-0.8-1c-0.3,0.4-0.6,0.6-0.9,0.8c-0.5,0.3-1,0.4-1.6,0.4c-0.9,0-1.6-0.2-2-0.7
				c-0.4-0.5-0.6-1-0.6-1.7c0-0.7,0.2-1.2,0.6-1.7c0.2-0.3,0.7-0.6,1.4-1C2217.7,794,2217.4,793.6,2217.3,793.3z M2219.9,798.2
				c0.3-0.2,0.6-0.4,0.7-0.7l-2-2.4c-0.6,0.4-0.9,0.7-1.1,0.9c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.5,0.2,0.8,0.5,1.1s0.7,0.4,1.1,0.4
				C2219.2,798.5,2219.5,798.4,2219.9,798.2z M2219.8,793.2c0.2-0.3,0.3-0.6,0.3-0.9c0-0.3-0.1-0.5-0.3-0.7
				c-0.2-0.2-0.4-0.3-0.7-0.3c-0.4,0-0.7,0.1-0.9,0.4c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.2,0.1,0.5,0.2,0.7s0.4,0.5,0.7,0.9
				C2219.4,793.6,2219.6,793.4,2219.8,793.2z"
          />
          <path
            d="M2228.6,797.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2228.6,797.3z"
          />
          <path
            d="M2233.9,796.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2235.1,799.4,2233.9,798,2233.9,796.1z M2235,795.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2235.9,793.7,2235.2,794.3,2235,795.4z"
          />
          <path
            d="M2240.4,797.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2241.3,799.3,2240.4,798.7,2240.4,797.5z M2244.3,796l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V796z"
          />
          <path
            d="M2247.4,797.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2247.4,797.3z"
          />
          <path
            d="M2255.7,799.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2258.7,798,2257.5,799.4,2255.7,799.4z M2255.7,798.4
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2253.9,797.5,2254.6,798.4,2255.7,798.4z"
          />
          <path
            d="M2260.1,792.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V792.9z"
          />
          <path
            d="M2266.4,797.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2267.4,799.3,2266.4,798.7,2266.4,797.5z M2270.4,796l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V796z"
          />
          <path d="M2274.4,799.2h-1.1v-8.7h1.1V799.2z" />
        </g>
        <g>
          <path
            d="M2222.1,804.7c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V804.7z"
          />
          <path
            d="M2225.6,812.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2228.6,811.3,2227.4,812.7,2225.6,812.7z M2225.6,811.7
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2223.8,810.8,2224.5,811.7,2225.6,811.7z"
          />
          <path
            d="M2232.5,812.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2235.4,811.3,2234.2,812.7,2232.5,812.7z M2232.5,811.7
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2230.6,810.8,2231.4,811.7,2232.5,811.7z"
          />
          <path
            d="M2236.4,809.4c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2237.4,812.7,2236.4,811.2,2236.4,809.4z M2239.4,807c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C2241.2,807.7,2240.3,807,2239.4,807z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="93">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M1686.3,2230.5c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1665.8,2184.7,1686.3,2205.2,1686.3,2230.5"
        />
        <g>
          <path
            d="M1612.6,2214.4h-1.2v-8.7h3.3c1.8,0,3,0.9,3,2.5c0,1.6-1.2,2.5-3,2.5h-2.2V2214.4z M1614.7,2209.8c1.2,0,1.9-0.5,1.9-1.5
				s-0.7-1.5-1.9-1.5h-2.1v3.1H1614.7z"
          />
          <path
            d="M1622.3,2209.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2209.1z"
          />
          <path
            d="M1626,2214.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1629,2213.2,1627.7,2214.6,1626,2214.6z
				 M1626,2213.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1624.2,2212.7,1624.9,2213.6,1626,2213.6z"
          />
          <path
            d="M1629.9,2211.2c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1630.9,2214.6,1629.9,2213,1629.9,2211.2z M1632.9,2208.9c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C1634.7,2209.5,1633.8,2208.9,1632.9,2208.9z"
          />
          <path
            d="M1642.6,2214.4h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2214.4z"
          />
          <path
            d="M1647,2213.6c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1645.1,2212.7,1645.9,2213.6,1647,2213.6z"
          />
          <path
            d="M1650.9,2209h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2209z"
          />
          <path d="M1654.8,2205.7h1.2v1.3h-1.2V2205.7z M1656,2214.4h-1.1v-6.4h1.1V2214.4z" />
          <path
            d="M1660.3,2214.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1663.3,2213.2,1662.1,2214.6,1660.3,2214.6z
				 M1660.3,2213.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1658.5,2212.7,1659.2,2213.6,1660.3,2213.6
				z"
          />
          <path
            d="M1664.7,2208.1h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2208.1z"
          />
        </g>
        <g>
          <path
            d="M1621.5,2226c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1621.5,2226z"
          />
          <path
            d="M1632.3,2227.9h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2227.9z"
          />
          <path
            d="M1637.4,2222.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2222.7z"
          />
          <path
            d="M1639.7,2227v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1640.8,2228.1,1640.1,2227.6,1639.7,2227z M1639.7,2225c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2225z"
          />
          <path d="M1647,2227.9h-1.1v-8.7h1.1V2227.9z" />
          <path
            d="M1653.8,2227.9h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2227.9z"
          />
          <path
            d="M1655.8,2226c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1655.8,2226z"
          />
        </g>
        <g>
          <path
            d="M1624.6,2241.5h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2241.5z"
          />
          <path
            d="M1626.6,2239.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1626.6,2239.5z"
          />
          <path
            d="M1631.9,2238.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1633.1,2241.6,1631.9,2240.2,1631.9,2238.3z M1633,2237.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1633.9,2235.9,1633.2,2236.5,1633,2237.6z"
          />
          <path
            d="M1638.5,2238.3c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1639.5,2241.6,1638.5,2240.1,1638.5,2238.3z M1641.4,2235.9c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C1643.2,2236.6,1642.3,2235.9,1641.4,2235.9z"
          />
          <path
            d="M1651.1,2240.5v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1652.2,2241.6,1651.5,2241.2,1651.1,2240.5z M1651.1,2238.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2238.5z"
          />
          <path
            d="M1657.6,2243.7c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C1659.2,2243.1,1658.7,2243.7,1657.6,2243.7z"
          />
        </g>
        <g>
          <path
            d="M1615.5,2247.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2247.1z"
          />
          <path
            d="M1618.9,2255.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1621.9,2253.8,1620.7,2255.1,1618.9,2255.1z
				 M1618.9,2254.2c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1617.1,2253.2,1617.8,2254.2,1618.9,2254.2
				z"
          />
          <path
            d="M1625.8,2255.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1628.8,2253.8,1627.5,2255.1,1625.8,2255.1z
				 M1625.8,2254.2c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1624,2253.2,1624.7,2254.2,1625.8,2254.2z"
          />
          <path
            d="M1629.7,2251.8c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1630.7,2255.1,1629.7,2253.6,1629.7,2251.8z M1632.7,2249.4c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C1634.5,2250.1,1633.6,2249.4,1632.7,2249.4z"
          />
          <path
            d="M1638.5,2254v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1639.5,2255.1,1638.8,2254.7,1638.5,2254z M1638.4,2252c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2252z"
          />
          <path
            d="M1644.1,2253.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1645,2255.1,1644.1,2254.4,1644.1,2253.3z M1648,2251.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2251.7z"
          />
          <path
            d="M1651,2248.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2248.6z"
          />
          <path d="M1658.9,2251.6l2.8-3h1.4l-2.5,2.5l2.7,3.9h-1.3l-2.2-3.1l-0.9,0.9v2.1h-1.1v-8.7h1.1V2251.6z" />
          <path
            d="M1664.5,2253c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1664.5,2253z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="92">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M4189.5,886.4c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C4169.1,840.7,4189.5,861.1,4189.5,886.4"
        />
        <g>
          <path d="M4114.1,868.4v1h-4.5v2.7h3.9v1h-3.9v3h4.5v1h-5.7v-8.7L4114.1,868.4L4114.1,868.4z" />
          <path
            d="M4118.4,869.3c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V869.3z"
          />
          <path
            d="M4122.1,869.3c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V869.3z"
          />
          <path
            d="M4122.7,873.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C4123.9,877.3,4122.7,875.9,4122.7,873.9z M4123.8,873.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C4124.6,871.6,4124,872.2,4123.8,873.3z"
          />
          <path
            d="M4132.2,876.3c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C4130.3,875.4,4131.1,876.3,4132.2,876.3z"
          />
          <path
            d="M4136.2,871.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V871.7z"
          />
          <path d="M4140.1,868.4h1.2v1.3h-1.2V868.4z M4141.2,877.1h-1.1v-6.4h1.1V877.1z" />
          <path d="M4144.7,877.1l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H4144.7z" />
          <path
            d="M4148.8,873.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C4150,877.3,4148.8,875.9,4148.8,873.9z M4149.9,873.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C4150.8,871.6,4150.1,872.2,4149.9,873.3z"
          />
          <path
            d="M4155.8,870.8h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V870.8z"
          />
          <path
            d="M4162.2,873.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C4163.4,877.3,4162.2,875.9,4162.2,873.9z M4163.3,873.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C4164.2,871.6,4163.5,872.2,4163.3,873.3z"
          />
          <path
            d="M4169.4,875.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L4169.4,875.2z"
          />
          <path
            d="M4175.3,875.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L4175.3,875.2z"
          />
        </g>
        <g>
          <path
            d="M4121.7,890.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C4124.6,889.4,4123.4,890.8,4121.7,890.8z M4121.7,889.8
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C4119.8,888.9,4120.6,889.8,4121.7,889.8z"
          />
          <path
            d="M4128.8,882.8c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V882.8z"
          />
          <path
            d="M4133.2,887.5c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-0.9h1.1v8.5h-1.1v-3.1c-0.4,0.6-1.1,1.1-2,1.1
				C4134.2,890.8,4133.2,889.3,4133.2,887.5z M4136.1,885.1c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C4137.9,885.8,4137,885.1,4136.1,885.1z"
          />
          <path
            d="M4145.9,890.6h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V890.6z"
          />
          <path
            d="M4147.2,888.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C4148.1,890.7,4147.2,890.1,4147.2,888.9z M4151.2,887.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V887.4z"
          />
          <path d="M4155.2,890.6h-1.1v-8.7h1.1V890.6z" />
          <path d="M4157,881.9h1.2v1.3h-1.2V881.9z M4158.1,890.6h-1.1v-6.4h1.1V890.6z" />
          <path
            d="M4160.1,885.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V885.2z"
          />
          <path
            d="M4164.5,892.9c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C4166.1,892.3,4165.6,892.9,4164.5,892.9z"
          />
        </g>
        <g>
          <path d="M4129.8,904.2h-1.1v-8.7h1.1V904.2z" />
          <path
            d="M4131.1,902.4c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C4132.1,904.3,4131.1,903.6,4131.1,902.4z M4135.1,900.9l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V900.9z"
          />
          <path
            d="M4139.1,903.2v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C4140.2,904.3,4139.5,903.9,4139.1,903.2z M4139.1,901.2c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V901.2z"
          />
          <path
            d="M4144.8,901c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2l1,0.3
				c-0.4,1.1-1.3,1.8-2.6,1.8C4146,904.3,4144.8,902.9,4144.8,901z M4145.9,900.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C4146.8,898.6,4146.1,899.2,4145.9,900.3z"
          />
          <path d="M4152.9,904.2h-1.1v-8.7h1.1V904.2z" />
          <path
            d="M4154.9,902.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L4154.9,902.2z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="91">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1554.3,1441.3c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8s20.5-45.8,45.8-45.8
			C1533.8,1395.5,1554.3,1416,1554.3,1441.3"
        />
        <g>
          <path d="M1487.8,1423.3h1.2v7.7h4.4v1h-5.5V1423.3z" />
          <path
            d="M1494,1428.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1495.2,1432.1,1494,1430.8,1494,1428.8z M1495.1,1428.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1496,1426.5,1495.3,1427.1,1495.1,1428.2z"
          />
          <path d="M1502.6,1432l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H1502.6z" />
          <path
            d="M1506.7,1428.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1507.9,1432.1,1506.7,1430.8,1506.7,1428.8z M1507.8,1428.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1508.7,1426.5,1508,1427.1,1507.8,1428.2z"
          />
          <path d="M1514.8,1432h-1.1v-8.7h1.1V1432z" />
          <path
            d="M1523,1432.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1526,1430.8,1524.8,1432.1,1523,1432.1z
				 M1523,1431.2c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1521.2,1430.3,1521.9,1431.2,1523,1431.2z"
          />
          <path
            d="M1530.1,1424.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1424.1z"
          />
        </g>
        <g>
          <path
            d="M1466.9,1443.6c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1466.9,1443.6z"
          />
          <path
            d="M1477.7,1445.5h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1445.5z"
          />
          <path
            d="M1479.7,1443.6c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1479.7,1443.6z"
          />
          <path
            d="M1485.6,1440.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1440.1z"
          />
          <path
            d="M1489.1,1443.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1490,1445.6,1489.1,1445,1489.1,1443.8z M1493,1442.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V1442.3z"
          />
          <path d="M1495.9,1436.8h1.2v1.3h-1.2V1436.8z M1497.1,1445.5h-1.1v-6.4h1.1V1445.5z" />
          <path
            d="M1498.9,1439.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1439.2z"
          />
          <path
            d="M1505.3,1443.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1506.2,1445.6,1505.3,1445,1505.3,1443.8z M1509.2,1442.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1442.3z"
          />
          <path
            d="M1513.3,1444.6v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1514.3,1445.7,1513.6,1445.2,1513.3,1444.6z M1513.2,1442.6c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1442.6z"
          />
          <path d="M1519.3,1436.8h1.2v1.3h-1.2V1436.8z M1520.5,1445.5h-1.1v-6.4h1.1V1445.5z" />
          <path d="M1523.4,1445.5h-1.1v-8.7h1.1V1445.5z" />
          <path d="M1525.2,1436.8h1.2v1.3h-1.2V1436.8z M1526.3,1445.5h-1.1v-6.4h1.1V1445.5z" />
          <path
            d="M1528.3,1440.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1440.1z"
          />
          <path
            d="M1532.7,1447.8c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C1534.3,1447.2,1533.8,1447.8,1532.7,1447.8z"
          />
          <path d="M1542.3,1436.8h1.2v1.3h-1.2V1436.8z M1543.4,1445.5h-1.1v-6.4h1.1V1445.5z" />
          <path
            d="M1545.2,1439.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1439.2z"
          />
        </g>
        <g>
          <path d="M1474.8,1459h-1.1v-8.7h1.1V1459z" />
          <path
            d="M1479.1,1459.2c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1482.1,1457.8,1480.8,1459.2,1479.1,1459.2z
				 M1479.1,1458.2c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1477.3,1457.3,1478,1458.2,1479.1,1458.2z"
          />
          <path
            d="M1486,1458.2c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1484.1,1457.3,1484.9,1458.2,1486,1458.2z"
          />
          <path
            d="M1489.3,1457.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1490.2,1459.2,1489.3,1458.5,1489.3,1457.3z M1493.2,1455.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1455.8z"
          />
          <path d="M1497.3,1459h-1.1v-8.7h1.1V1459z" />
          <path
            d="M1505.7,1451.2c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1451.2z"
          />
          <path
            d="M1506.2,1457.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1507.1,1459.2,1506.2,1458.5,1506.2,1457.3z M1510.1,1455.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1455.8z"
          />
          <path
            d="M1516.4,1453.8c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1453.8z"
          />
          <path
            d="M1518.7,1459h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V1459z
				"
          />
          <path d="M1528.1,1450.3h1.2v1.3h-1.2V1450.3z M1529.3,1459h-1.1v-6.4h1.1V1459z" />
          <path
            d="M1531.1,1452.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1452.7z"
          />
          <path
            d="M1540.4,1461.4c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C1543.3,1460.3,1542.1,1461.4,1540.4,1461.4z M1540.5,1453.5c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2
				c0.9,0,1.8-0.7,1.8-2.1v-0.2C1542.3,1454.2,1541.4,1453.5,1540.5,1453.5z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="90">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2952.5,998.5c0,25.3-20.5,45.7-45.8,45.7c-25.3,0-45.8-20.5-45.8-45.7c0-25.3,20.5-45.8,45.8-45.8
			C2932,952.8,2952.5,973.3,2952.5,998.5"
        />
        <g>
          <path
            d="M2883.2,973.4c0.5,1,1.5,1.7,2.7,1.7s2-0.6,2-1.6c0-1.1-1-1.2-2.3-1.6c-1.4-0.3-2.7-0.7-2.7-2.3c0-1.5,1.3-2.5,3-2.5
				c1.5,0,2.6,0.7,3.1,1.7l-0.9,0.7c-0.5-0.8-1.1-1.4-2.3-1.4c-1.1,0-1.8,0.6-1.8,1.4c0,0.9,0.8,1.1,2,1.4c1.4,0.3,3,0.6,3,2.5
				c0,1.6-1.4,2.7-3.2,2.7c-1.5,0-3-0.8-3.6-1.9L2883.2,973.4z"
          />
          <path
            d="M2892.9,975.1c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2891,974.2,2891.7,975.1,2892.9,975.1z"
          />
          <path
            d="M2896.6,967.2h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1V972c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V967.2z"
          />
          <path
            d="M2906,976c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2909,974.7,2907.7,976,2906,976z M2906,975.1
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2904.2,974.2,2904.9,975.1,2906,975.1z"
          />
          <path
            d="M2912.9,976c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2915.8,974.7,2914.6,976,2912.9,976z M2912.9,975.1
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2911,974.2,2911.7,975.1,2912.9,975.1z"
          />
          <path d="M2918.3,975.9h-1.1v-8.7h1.1V975.9z" />
          <path
            d="M2924.8,970c-0.1-0.3-0.2-0.6-0.2-0.8c0-0.6,0.2-1,0.6-1.4s0.9-0.6,1.5-0.6c0.6,0,1.1,0.2,1.4,0.5s0.5,0.8,0.5,1.2
				c0,0.6-0.2,1-0.5,1.5c-0.2,0.2-0.5,0.5-1,0.8l1.6,1.9c0.1-0.3,0.2-0.6,0.2-0.7c0-0.2,0.1-0.4,0.1-0.7h1c-0.1,0.6-0.2,1.1-0.4,1.6
				c-0.2,0.5-0.3,0.7-0.3,0.6l1.5,1.9h-1.4l-0.8-1c-0.3,0.4-0.6,0.6-0.9,0.8c-0.5,0.3-1,0.4-1.6,0.4c-0.9,0-1.6-0.2-2-0.7
				c-0.4-0.5-0.6-1-0.6-1.7c0-0.7,0.2-1.2,0.6-1.7c0.2-0.3,0.7-0.6,1.4-1C2925.2,970.6,2925,970.3,2924.8,970z M2927.4,974.9
				c0.3-0.2,0.6-0.4,0.7-0.7l-2-2.4c-0.6,0.4-0.9,0.7-1.1,0.9c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.5,0.2,0.8,0.5,1.1s0.7,0.4,1.1,0.4
				C2926.7,975.2,2927.1,975.1,2927.4,974.9z M2927.3,969.9c0.2-0.3,0.3-0.6,0.3-0.9c0-0.3-0.1-0.5-0.3-0.7
				c-0.2-0.2-0.4-0.3-0.7-0.3c-0.4,0-0.7,0.1-0.9,0.4c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.2,0.1,0.5,0.2,0.7s0.4,0.5,0.7,0.9
				C2926.9,970.3,2927.2,970.1,2927.3,969.9z"
          />
        </g>
        <g>
          <path d="M2877.4,980.5h1.2v1.3h-1.2V980.5z M2878.5,989.2h-1.1v-6.4h1.1V989.2z" />
          <path
            d="M2880.3,982.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V982.9z"
          />
          <path
            d="M2887.3,987.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2887.3,987.3z"
          />
          <path
            d="M2893.3,983.8h-1v-0.9h1V981h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V983.8z"
          />
          <path d="M2897.2,980.5h1.2v1.3h-1.2V980.5z M2898.3,989.2h-1.1v-6.4h1.1V989.2z" />
          <path
            d="M2900.3,983.8h-1v-0.9h1V981h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V983.8z"
          />
          <path
            d="M2909.3,989.2h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V989.2z"
          />
          <path
            d="M2911.3,983.8h-1v-0.9h1V981h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V983.8z"
          />
          <path d="M2915.2,980.5h1.2v1.3h-1.2V980.5z M2916.4,989.2h-1.1v-6.4h1.1V989.2z" />
          <path
            d="M2920.7,989.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2923.7,988,2922.5,989.4,2920.7,989.4z M2920.7,988.4
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2918.9,987.5,2919.6,988.4,2920.7,988.4z"
          />
          <path
            d="M2925.1,982.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V982.9z"
          />
          <path
            d="M2932.1,987.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2932.1,987.3z"
          />
        </g>
        <g>
          <path d="M2869.9,994h1.2v1.3h-1.2V994z M2871,1002.8h-1.1v-6.4h1.1V1002.8z" />
          <path
            d="M2874,1002.8h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1002.8z"
          />
          <path
            d="M2884.5,1001.8v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2885.6,1002.9,2884.9,1002.5,2884.5,1001.8z M2884.5,999.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V999.8z"
          />
          <path d="M2891.8,1002.8h-1.1V994h1.1V1002.8z" />
          <path
            d="M2893.2,999.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2894.4,1002.9,2893.2,1001.5,2893.2,999.6z M2894.3,998.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2895.2,997.2,2894.5,997.8,2894.3,998.9z"
          />
          <path
            d="M2901.3,1002.8h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1002.8z"
          />
          <path
            d="M2910.3,999.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2911.5,1002.9,2910.3,1001.5,2910.3,999.6z M2911.4,998.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2912.3,997.2,2911.6,997.8,2911.4,998.9z"
          />
          <path
            d="M2917.3,996.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V996.4z"
          />
          <path
            d="M2924.4,997.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V997.3z"
          />
          <path d="M2928.3,994h1.2v1.3h-1.2V994z M2929.4,1002.8h-1.1v-6.4h1.1V1002.8z" />
          <path
            d="M2931.2,996.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V996.4z"
          />
          <path
            d="M2940.5,1005.1c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2943.5,1004,2942.3,1005.1,2940.5,1005.1z M2940.7,997.2c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C2942.4,997.9,2941.6,997.2,2940.7,997.2z"
          />
        </g>
        <g>
          <path
            d="M2871.9,1014.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2871.9,1014.3z"
          />
          <path
            d="M2882.7,1016.3h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1016.3z"
          />
          <path
            d="M2884.7,1014.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2884.7,1014.3z"
          />
          <path
            d="M2890.6,1010.8h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1010.8z"
          />
          <path
            d="M2894.1,1014.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2895,1016.4,2894.1,1015.7,2894.1,1014.6z M2898.1,1013l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1013z"
          />
          <path d="M2901,1007.5h1.2v1.3h-1.2V1007.5z M2902.1,1016.3h-1.1v-6.4h1.1V1016.3z" />
          <path
            d="M2903.9,1009.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1009.9z"
          />
          <path
            d="M2910.3,1014.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2911.2,1016.4,2910.3,1015.7,2910.3,1014.6z M2914.2,1013l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1013z"
          />
          <path
            d="M2918.3,1015.3v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2919.4,1016.4,2918.7,1016,2918.3,1015.3z M2918.3,1013.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1013.3z"
          />
          <path d="M2924.4,1007.5h1.2v1.3h-1.2V1007.5z M2925.5,1016.3h-1.1v-6.4h1.1V1016.3z" />
          <path d="M2928.4,1016.3h-1.1v-8.7h1.1V1016.3z" />
          <path d="M2930.2,1007.5h1.2v1.3h-1.2V1007.5z M2931.3,1016.3h-1.1v-6.4h1.1V1016.3z" />
          <path
            d="M2933.3,1010.8h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1010.8z"
          />
          <path
            d="M2937.7,1018.5c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C2939.3,1017.9,2938.8,1018.5,2937.7,1018.5z"
          />
        </g>
        <g>
          <path
            d="M2882,1028.9v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2883.1,1029.9,2882.4,1029.5,2882,1028.9z M2882,1026.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1026.8z"
          />
          <path
            d="M2891.5,1024.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1024.5z"
          />
          <path d="M2892.6,1021.1h1.2v1.3h-1.2V1021.1z M2893.8,1029.8h-1.1v-6.4h1.1V1029.8z" />
          <path
            d="M2895.6,1023.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1023.4z"
          />
          <path
            d="M2905,1029c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2903.1,1028.1,2903.9,1029,2905,1029z"
          />
          <path d="M2908.7,1021.1h1.2v1.3h-1.2V1021.1z M2909.9,1029.8h-1.1v-6.4h1.1V1029.8z" />
          <path
            d="M2912.8,1028.9v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2913.9,1029.9,2913.2,1029.5,2912.8,1028.9z M2912.8,1026.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1026.8z"
          />
          <path d="M2920,1029.8h-1.1v-8.7h1.1V1029.8z" />
          <path
            d="M2921.4,1026.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2922.6,1029.9,2921.4,1028.6,2921.4,1026.6z M2922.5,1025.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2923.4,1024.2,2922.7,1024.9,2922.5,1025.9z"
          />
          <path
            d="M2928.6,1027.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2928.6,1027.8z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="89">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3150.2,1228c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3129.7,1182.2,3150.2,1202.7,3150.2,1228"
        />
        <g>
          <path
            d="M3076.8,1211.9h-1.2v-8.7h3.3c1.8,0,3,0.9,3,2.5c0,1.6-1.2,2.5-3,2.5h-2.2V1211.9z M3078.9,1207.3c1.2,0,1.9-0.5,1.9-1.5
				c0-1-0.7-1.5-1.9-1.5h-2.1v3.1H3078.9z"
          />
          <path
            d="M3082.7,1208.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3083.9,1212.1,3082.7,1210.7,3082.7,1208.7z M3083.8,1208.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3084.7,1206.4,3084,1207,3083.8,1208.1z"
          />
          <path
            d="M3093,1206.6c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1206.6z"
          />
          <path
            d="M3096.8,1211.1c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3094.9,1210.2,3095.7,1211.1,3096.8,1211.1z"
          />
          <path
            d="M3100.1,1208.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3101.3,1212.1,3100.1,1210.7,3100.1,1208.7z M3101.2,1208.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3102.1,1206.4,3101.4,1207,3101.2,1208.1z"
          />
          <path
            d="M3108.2,1211v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3109.3,1212.1,3108.6,1211.6,3108.2,1211z M3108.2,1209c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1209z"
          />
          <path
            d="M3114.5,1206.5h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1206.5z"
          />
          <path d="M3118.4,1203.2h1.2v1.3h-1.2V1203.2z M3119.6,1211.9h-1.1v-6.4h1.1V1211.9z" />
          <path
            d="M3123.9,1212.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3126.9,1210.7,3125.7,1212.1,3123.9,1212.1z
				 M3123.9,1211.1c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3122.1,1210.2,3122.8,1211.1,3123.9,1211.1
				z"
          />
          <path
            d="M3128.3,1205.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1205.6z"
          />
        </g>
        <g>
          <path
            d="M3078.7,1225.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3081.7,1224.2,3080.5,1225.6,3078.7,1225.6z
				 M3078.7,1224.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3076.9,1223.7,3077.6,1224.6,3078.7,1224.6
				z"
          />
          <path
            d="M3085.8,1217.6c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1217.6z"
          />
          <path
            d="M3091.7,1225.4h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1225.4z"
          />
          <path
            d="M3100.8,1222.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3102,1225.6,3100.8,1224.2,3100.8,1222.3z M3101.9,1221.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3102.8,1219.9,3102.1,1220.5,3101.9,1221.6z"
          />
          <path
            d="M3107.3,1223.7c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3108.2,1225.5,3107.3,1224.9,3107.3,1223.7z M3111.3,1222.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1222.2z"
          />
          <path
            d="M3114.4,1220h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1220z"
          />
          <path
            d="M3121.7,1223.7c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3122.6,1225.5,3121.7,1224.9,3121.7,1223.7z M3125.7,1222.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1222.2z"
          />
          <path
            d="M3128.7,1223.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3128.7,1223.5z"
          />
        </g>
        <g>
          <path
            d="M3072.5,1238.1c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3070.6,1237.2,3071.4,1238.1,3072.5,1238.1z"
          />
          <path
            d="M3075.8,1235.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3077,1239.1,3075.8,1237.7,3075.8,1235.8z M3076.9,1235.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3077.8,1233.4,3077.1,1234,3076.9,1235.1z"
          />
          <path
            d="M3082.8,1232.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1232.6z"
          />
          <path
            d="M3089.9,1233.5h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1233.5z"
          />
          <path
            d="M3097.1,1233.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1233.7z"
          />
          <path
            d="M3097.9,1235.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3099.1,1239.1,3097.9,1237.7,3097.9,1235.8z M3099,1235.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3099.9,1233.4,3099.2,1234,3099,1235.1z"
          />
          <path
            d="M3111.3,1239.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3114.2,1237.7,3113,1239.1,3111.3,1239.1z
				 M3111.3,1238.1c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3109.4,1237.2,3110.2,1238.1,3111.3,1238.1
				z"
          />
          <path
            d="M3118.4,1231.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1231.1z"
          />
          <path
            d="M3123.4,1233.5h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1233.5z"
          />
          <path
            d="M3127.3,1230.2h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1230.2z"
          />
          <path
            d="M3133.8,1235.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3135,1239.1,3133.8,1237.7,3133.8,1235.8z M3134.9,1235.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3135.8,1233.4,3135.1,1234,3134.9,1235.1z"
          />
        </g>
        <g>
          <path
            d="M3093.2,1252.5h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1252.5z"
          />
          <path
            d="M3102.3,1249.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3103.5,1252.6,3102.3,1251.3,3102.3,1249.3z M3103.4,1248.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3104.3,1246.9,3103.6,1247.5,3103.4,1248.6z"
          />
          <path
            d="M3108.8,1250.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3109.7,1252.6,3108.8,1251.9,3108.8,1250.8z M3112.7,1249.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1249.2z"
          />
          <path d="M3116.8,1252.5h-1.1v-8.7h1.1V1252.5z" />
        </g>
      </g>
      <g onClick={handleClick} id="88">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M1866.1,2230.4c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1845.6,2184.6,1866.1,2205.1,1866.1,2230.4"
        />
        <g>
          <path d="M1801.2,2207.5h1.1v7.1h4v0.9h-5.1V2207.5z" />
          <path
            d="M1806.9,2212.6c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1
				l0.9,0.3c-0.4,1-1.2,1.7-2.4,1.7C1808,2215.7,1806.9,2214.4,1806.9,2212.6z M1807.9,2212h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1808.8,2210.4,1808.1,2211,1807.9,2212z"
          />
          <path d="M1814.9,2215.6l-2.2-5.9h1.1l1.7,4.6l1.6-4.6h1.1l-2.2,5.9H1814.9z" />
          <path
            d="M1818.7,2212.6c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1
				l0.9,0.3c-0.4,1-1.2,1.7-2.4,1.7C1819.8,2215.7,1818.7,2214.4,1818.7,2212.6z M1819.7,2212h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1820.5,2210.4,1819.9,2211,1819.7,2212z"
          />
          <path d="M1826.2,2215.6h-1v-8.1h1V2215.6z" />
          <path
            d="M1833.7,2215.7c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1
				C1836.5,2214.4,1835.3,2215.7,1833.7,2215.7z M1833.7,2214.8c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2
				C1832,2214,1832.7,2214.8,1833.7,2214.8z"
          />
          <path
            d="M1840.3,2208.3c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0V2208.3z"
          />
        </g>
        <g>
          <path
            d="M1791.1,2220.8c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0V2220.8z"
          />
          <path
            d="M1794.4,2228.2c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C1797.1,2226.9,1796,2228.2,1794.4,2228.2z
				 M1794.4,2227.3c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1792.6,2226.4,1793.3,2227.3,1794.4,2227.3z"
          />
          <path
            d="M1800.7,2228.2c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1
				C1803.4,2226.9,1802.3,2228.2,1800.7,2228.2z M1800.7,2227.3c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2
				C1799,2226.4,1799.7,2227.3,1800.7,2227.3z"
          />
          <path
            d="M1804.3,2225.1c0-1.7,0.9-3.1,2.6-3.1c0.8,0,1.5,0.4,1.8,1v-3h1v8.1h-1v-0.9c-0.3,0.6-1,1-1.8,1
				C1805.2,2228.2,1804.3,2226.8,1804.3,2225.1z M1807,2222.9c-1.1,0-1.7,0.9-1.7,2.2c0,1.3,0.6,2.2,1.7,2.2c0.8,0,1.7-0.6,1.7-2
				v-0.4C1808.7,2223.5,1807.9,2222.9,1807,2222.9z"
          />
          <path
            d="M1815,2226.2c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1815,2226.2z"
          />
          <path
            d="M1821.4,2227.2v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C1822.4,2228.2,1821.7,2227.8,1821.4,2227.2z M1821.4,2225.3c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V2225.3z"
          />
          <path d="M1827,2220h1.1v1.2h-1.1V2220z M1828,2228h-1v-5.9h1V2228z" />
          <path d="M1830.7,2228h-1v-8.1h1V2228z" />
          <path d="M1833.4,2228h-1v-8.1h1V2228z" />
          <path
            d="M1834.7,2226.5c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C1835.5,2228.1,1834.7,2227.5,1834.7,2226.5z M1838.3,2225l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V2225z"
          />
          <path
            d="M1843.3,2230.2c-1.3,0-2.2-0.6-2.6-1.7l0.9-0.4c0.2,0.8,0.8,1.2,1.6,1.2c1,0,1.7-0.5,1.7-1.8v-0.7c-0.3,0.6-1,1-1.8,1
				c-1.6,0-2.5-1.3-2.5-2.9c0-1.6,0.9-2.9,2.5-2.9c0.8,0,1.5,0.4,1.8,1v-0.9h1v5.3C1846,2229.2,1844.9,2230.2,1843.3,2230.2z
				 M1843.4,2222.9c-1.1,0-1.7,0.8-1.7,2c0,1.2,0.7,2,1.7,2c0.8,0,1.6-0.6,1.6-1.9v-0.2C1845,2223.6,1844.2,2222.9,1843.4,2222.9z"
          />
          <path
            d="M1847.3,2225.1c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1
				l0.9,0.3c-0.4,1-1.2,1.7-2.4,1.7C1848.4,2228.2,1847.3,2226.9,1847.3,2225.1z M1848.3,2224.5h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1849.1,2222.9,1848.5,2223.5,1848.3,2224.5z"
          />
        </g>
        <g>
          <path
            d="M1792.2,2238.9c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C1793,2240.6,1792.2,2240,1792.2,2238.9z M1795.8,2237.5l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V2237.5z"
          />
          <path
            d="M1798.6,2234.6h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V2234.6z"
          />
          <path
            d="M1804.5,2237.6c0-1.7,0.9-3.1,2.6-3.1c0.8,0,1.5,0.4,1.8,1v-3h1v8.1h-1v-0.9c-0.3,0.6-1,1-1.8,1
				C1805.4,2240.6,1804.5,2239.2,1804.5,2237.6z M1807.2,2235.4c-1.1,0-1.7,0.9-1.7,2.2c0,1.3,0.6,2.2,1.7,2.2c0.8,0,1.7-0.6,1.7-2
				v-0.4C1808.9,2236,1808.1,2235.4,1807.2,2235.4z"
          />
          <path
            d="M1814.7,2237.6c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1
				l0.9,0.3c-0.4,1-1.2,1.7-2.4,1.7C1815.8,2240.6,1814.7,2239.4,1814.7,2237.6z M1815.7,2237h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1816.5,2235.4,1815.9,2236,1815.7,2237z"
          />
          <path d="M1823.2,2238.2l-1.6,2.3h-1.1l2.1-3.1l-2-2.8h1.2l1.4,2.1l1.4-2.1h1.1l-2,2.8l2.2,3.1h-1.1L1823.2,2238.2z" />
          <path
            d="M1829.2,2239.7c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C1827.5,2238.9,1828.2,2239.7,1829.2,2239.7z"
          />
          <path
            d="M1832.3,2237.6c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1
				l0.9,0.3c-0.4,1-1.2,1.7-2.4,1.7C1833.4,2240.6,1832.3,2239.4,1832.3,2237.6z M1833.3,2237h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1834.1,2235.4,1833.5,2236,1833.3,2237z"
          />
          <path
            d="M1838.9,2238.7c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1838.9,2238.7z"
          />
          <path
            d="M1844.4,2238.7c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1844.4,2238.7z"
          />
        </g>
        <g>
          <path
            d="M1794,2252.1v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C1795,2253.1,1794.3,2252.7,1794,2252.1z M1794,2250.3c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V2250.3z"
          />
          <path
            d="M1804.3,2253h-1v-0.8c-0.3,0.5-0.9,0.9-1.7,0.9c-1.3,0-2-0.9-2-2.1v-3.9h1v3.7c0,0.8,0.4,1.4,1.2,1.4
				c0.7,0,1.5-0.5,1.5-1.5v-3.5h1V2253z"
          />
          <path
            d="M1809,2248.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0V2248.1z
				"
          />
          <path
            d="M1812.5,2252.2c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C1810.7,2251.4,1811.5,2252.2,1812.5,2252.2z"
          />
          <path
            d="M1816,2244.9h1v3c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.5,0.6-1.5,1.5
				v3.5h-1V2244.9z"
          />
          <path
            d="M1821.8,2251.4c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C1822.7,2253.1,1821.8,2252.5,1821.8,2251.4z M1825.5,2250l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V2250z"
          />
          <path
            d="M1828.3,2251.2c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1828.3,2251.2z"
          />
          <path d="M1833.6,2244.9h1.1v1.2h-1.1V2244.9z M1834.7,2253h-1v-5.9h1V2253z" />
          <path
            d="M1836.4,2247.1h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V2247.1z"
          />
          <path
            d="M1844.9,2255.1c-1.3,0-2.2-0.6-2.6-1.7l0.9-0.4c0.2,0.8,0.8,1.2,1.6,1.2c1,0,1.7-0.5,1.7-1.8v-0.7c-0.3,0.6-1,1-1.8,1
				c-1.6,0-2.5-1.3-2.5-2.9c0-1.6,0.9-2.9,2.5-2.9c0.8,0,1.5,0.4,1.8,1v-0.9h1v5.3C1847.7,2254.2,1846.5,2255.1,1844.9,2255.1z
				 M1845,2247.9c-1.1,0-1.7,0.8-1.7,2c0,1.2,0.7,2,1.7,2c0.8,0,1.6-0.6,1.6-1.9v-0.2C1846.7,2248.5,1845.9,2247.9,1845,2247.9z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="87">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M4216.5,1241.8c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C4196,1196,4216.5,1216.5,4216.5,1241.8"
        />
        <g>
          <path d="M4150,1217h1.2v7.7h4.4v1h-5.5V1217z" />
          <path
            d="M4156.2,1222.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C4157.5,1225.9,4156.2,1224.5,4156.2,1222.6z M4157.3,1221.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C4158.2,1220.2,4157.5,1220.8,4157.3,1221.9z"
          />
          <path d="M4164.9,1225.7l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H4164.9z" />
          <path
            d="M4169,1222.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C4170.2,1225.9,4169,1224.5,4169,1222.6z M4170.1,1221.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C4171,1220.2,4170.3,1220.8,4170.1,1221.9z"
          />
          <path d="M4177.1,1225.7h-1.1v-8.7h1.1V1225.7z" />
          <path
            d="M4185.3,1225.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C4188.2,1224.5,4187,1225.9,4185.3,1225.9z
				 M4185.3,1224.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C4183.4,1224,4184.2,1224.9,4185.3,1224.9z"
          />
          <path
            d="M4192.4,1217.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1217.9z"
          />
        </g>
        <g>
          <path
            d="M4143.6,1234c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1234z"
          />
          <path
            d="M4144.4,1236.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C4145.6,1239.4,4144.4,1238,4144.4,1236.1z M4145.5,1235.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C4146.4,1233.7,4145.7,1234.3,4145.5,1235.4z"
          />
          <path
            d="M4151.5,1237.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L4151.5,1237.3z"
          />
          <path
            d="M4156.9,1236.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C4158.1,1239.4,4156.9,1238,4156.9,1236.1z M4158,1235.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C4158.9,1233.7,4158.2,1234.3,4158,1235.4z"
          />
          <path
            d="M4163.9,1232.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1232.9z"
          />
          <path
            d="M4170.9,1233.8h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1233.8z"
          />
          <path
            d="M4176,1239.3h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1239.3z"
          />
          <path
            d="M4185,1236.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C4186.2,1239.4,4185,1238,4185,1236.1z M4186.1,1235.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C4187,1233.7,4186.3,1234.3,4186.1,1235.4z"
          />
          <path
            d="M4192,1232.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1232.9z"
          />
          <path
            d="M4199.1,1233.8h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1233.8z"
          />
        </g>
        <g>
          <path
            d="M4149.4,1251.8v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C4150.5,1252.9,4149.8,1252.5,4149.4,1251.8z M4149.4,1249.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1249.8z"
          />
          <path
            d="M4155.1,1249.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C4156.3,1252.9,4155.1,1251.6,4155.1,1249.6z M4156.2,1248.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C4157.1,1247.2,4156.4,1247.8,4156.2,1248.9z"
          />
          <path
            d="M4162.3,1247.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1247.3z"
          />
          <path d="M4170.2,1246.4l1.3,4.7l1.3-4.7h1.1l-1.9,6.4h-1l-1.3-4.7l-1.3,4.7h-1l-1.9-6.4h1.1l1.3,4.7l1.3-4.7H4170.2z" />
          <path
            d="M4174.6,1249.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C4175.8,1252.9,4174.6,1251.6,4174.6,1249.6z M4175.7,1248.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C4176.6,1247.2,4175.9,1247.8,4175.7,1248.9z"
          />
          <path
            d="M4181.1,1249.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C4182.4,1252.9,4181.1,1251.6,4181.1,1249.6z M4182.3,1248.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C4183.1,1247.2,4182.4,1247.8,4182.3,1248.9z"
          />
          <path
            d="M4188.2,1246.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1246.4z"
          />
        </g>
        <g>
          <path
            d="M4154.8,1268.6c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C4157.8,1267.6,4156.5,1268.6,4154.8,1268.6z M4154.9,1260.8c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2
				c0.9,0,1.8-0.7,1.8-2.1v-0.2C4156.7,1261.4,4155.8,1260.8,4154.9,1260.8z"
          />
          <path
            d="M4162.9,1261c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1261z"
          />
          <path
            d="M4166.6,1266.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C4169.6,1265.1,4168.4,1266.4,4166.6,1266.4z
				 M4166.6,1265.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C4164.8,1264.6,4165.5,1265.5,4166.6,1265.5
				z"
          />
          <path
            d="M4176,1266.3h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1266.3z"
          />
          <path
            d="M4178.9,1265.4v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C4180,1266.4,4179.3,1266,4178.9,1265.4z M4178.9,1263.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1263.3z"
          />
          <path
            d="M4185.2,1264.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L4185.2,1264.3z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="86">
        <path
          style={{ fill: '#33CC61' }}
          className="node"
          d="M2886.2,1942.7c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2865.7,1897,2886.2,1917.5,2886.2,1942.7"
        />
        <g>
          <path
            d="M2816.1,1927.1c1,0,1.8-0.6,2.1-1.6l1,0.3c-0.5,1.4-1.6,2.2-3.1,2.2c-2.1,0-3.6-1.7-3.6-4.2c0-2.5,1.5-4.2,3.6-4.2
				c1.5,0,2.6,0.9,3.1,2.2l-1,0.3c-0.3-1-1-1.6-2.1-1.6c-1.5,0-2.5,1.3-2.5,3.2C2813.5,1925.8,2814.6,1927.1,2816.1,1927.1z"
          />
          <path
            d="M2822.6,1928c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C2825.3,1926.8,2824.2,1928,2822.6,1928z
				 M2822.6,1927.2c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C2820.9,1926.3,2821.6,1927.2,2822.6,1927.2z"
          />
          <path
            d="M2827.6,1927.9h-1v-5.9h1v0.8c0.3-0.5,0.9-0.9,1.7-0.9c0.8,0,1.3,0.4,1.6,1c0.3-0.5,1-1,1.9-1c1.2,0,1.9,0.9,1.9,2.1v3.9
				h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5v3.5h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5V1927.9z"
          />
          <path
            d="M2837.4,1927.1v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C2838.4,1928,2837.7,1927.7,2837.4,1927.1z M2837.4,1925.2c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1925.2z"
          />
          <path d="M2844.1,1927.9h-1v-8.1h1V1927.9z" />
          <path
            d="M2845.3,1925c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C2846.5,1928,2845.3,1926.8,2845.3,1925z M2846.4,1924.4h3.2c0-0.8-0.5-1.6-1.5-1.6
				C2847.2,1922.8,2846.5,1923.4,2846.4,1924.4z"
          />
          <path d="M2853.8,1925.6l-1.6,2.3h-1.1l2.1-3.1l-2-2.8h1.2l1.4,2.1l1.4-2.1h1.1l-2,2.8l2.2,3.1h-1.1L2853.8,1925.6z" />
          <path d="M2857.5,1919.9h1.1v1.2h-1.1V1919.9z M2858.5,1927.9h-1v-5.9h1V1927.9z" />
          <path
            d="M2860.4,1922.9h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.5,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V1922.9z"
          />
          <path
            d="M2864.4,1930c-0.2,0-0.4,0-0.5,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,0.9-0.7l0.3-0.7l-2.2-5.7h1.1l1.7,4.5
				l1.6-4.5h1.1l-2.5,6.5C2865.9,1929.5,2865.4,1930,2864.4,1930z"
          />
        </g>
        <g>
          <path
            d="M2838.7,1940.5c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1
				C2841.5,1939.3,2840.3,1940.5,2838.7,1940.5z M2838.7,1939.7c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2
				C2837,1938.8,2837.7,1939.7,2838.7,1939.7z"
          />
          <path
            d="M2845.3,1933.1c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0V1933.1z"
          />
        </g>
        <g>
          <path
            d="M2808.6,1955c-1.3,0-2.2-0.6-2.6-1.7l0.9-0.4c0.2,0.8,0.8,1.2,1.6,1.2c1,0,1.7-0.5,1.7-1.8v-0.7c-0.3,0.6-1,1-1.8,1
				c-1.6,0-2.5-1.3-2.5-2.9c0-1.6,0.9-2.9,2.5-2.9c0.8,0,1.5,0.4,1.8,1v-0.9h1v5.3C2811.4,1954.1,2810.2,1955,2808.6,1955z
				 M2808.7,1947.8c-1.1,0-1.7,0.8-1.7,2c0,1.2,0.7,2,1.7,2c0.8,0,1.6-0.6,1.6-1.9v-0.2C2810.4,1948.4,2809.6,1947.8,2808.7,1947.8z
				"
          />
          <path
            d="M2815.4,1953c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C2818.1,1951.8,2817,1953,2815.4,1953z
				 M2815.4,1952.1c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C2813.7,1951.3,2814.3,1952.1,2815.4,1952.1z"
          />
          <path d="M2820.9,1952.9l-2.2-5.9h1.1l1.7,4.6l1.6-4.6h1.1l-2.2,5.9H2820.9z" />
          <path
            d="M2824.7,1949.9c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1
				l0.9,0.3c-0.4,1-1.2,1.7-2.4,1.7C2825.8,1953,2824.7,1951.8,2824.7,1949.9z M2825.7,1949.3h3.2c0-0.8-0.5-1.6-1.5-1.6
				C2826.5,1947.8,2825.9,1948.3,2825.7,1949.3z"
          />
          <path d="M2834.2,1948c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0V1948z" />
          <path
            d="M2835.3,1947h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V1947z"
          />
          <path
            d="M2842.6,1952.9h-1v-5.9h1v0.8c0.3-0.5,0.9-0.9,1.7-0.9c0.8,0,1.3,0.4,1.6,1c0.3-0.5,1-1,1.9-1c1.2,0,1.9,0.9,1.9,2.1v3.9
				h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5v3.5h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5V1952.9z"
          />
          <path
            d="M2851,1949.9c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C2852.1,1953,2851,1951.8,2851,1949.9z M2852,1949.3h3.2c0-0.8-0.5-1.6-1.5-1.6
				C2852.8,1947.8,2852.2,1948.3,2852,1949.3z"
          />
          <path
            d="M2857.5,1947h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V1947z"
          />
          <path
            d="M2863.9,1947.9h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V1947.9z"
          />
          <path
            d="M2867.1,1951.3c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C2868,1953,2867.1,1952.3,2867.1,1951.3z M2870.8,1949.9l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V1949.9z"
          />
          <path d="M2874.5,1952.9h-1v-8.1h1V1952.9z" />
        </g>
        <g>
          <path
            d="M2815.7,1963.5c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L2815.7,1963.5z"
          />
          <path
            d="M2821.2,1960.3h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.5,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V1960.3z"
          />
          <path
            d="M2827.9,1960.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				V1960.5z"
          />
          <path
            d="M2833.7,1965.4h-1v-0.8c-0.3,0.5-0.9,0.9-1.7,0.9c-1.3,0-2-0.9-2-2.1v-3.9h1v3.7c0,0.8,0.4,1.4,1.2,1.4
				c0.7,0,1.5-0.5,1.5-1.5v-3.5h1V1965.4z"
          />
          <path
            d="M2837.7,1964.6c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C2835.9,1963.8,2836.7,1964.6,2837.7,1964.6z"
          />
          <path
            d="M2841.3,1960.3h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V1960.3z"
          />
          <path
            d="M2849.7,1965.4h-1v-0.8c-0.3,0.5-0.9,0.9-1.7,0.9c-1.3,0-2-0.9-2-2.1v-3.9h1v3.7c0,0.8,0.4,1.4,1.2,1.4
				c0.7,0,1.5-0.5,1.5-1.5v-3.5h1V1965.4z"
          />
          <path
            d="M2854.4,1960.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				V1960.5z"
          />
          <path
            d="M2855.1,1962.4c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1
				l0.9,0.3c-0.4,1-1.2,1.7-2.4,1.7C2856.2,1965.5,2855.1,1964.2,2855.1,1962.4z M2856.1,1961.8h3.2c0-0.8-0.5-1.6-1.5-1.6
				C2856.9,1960.2,2856.3,1960.8,2856.1,1961.8z"
          />
          <path
            d="M2861.7,1963.5c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L2861.7,1963.5z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="85">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M2384.9,496.6c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2364.4,450.8,2384.9,471.3,2384.9,496.6"
        />
        <g>
          <path d="M2300.6,478.6h1.2v7.7h4.4v1h-5.5V478.6z" />
          <path
            d="M2306.8,485.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2307.7,487.4,2306.8,486.7,2306.8,485.6z M2310.7,484l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V484z"
          />
          <path
            d="M2313.7,480.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V480.9z"
          />
          <path
            d="M2320.1,484.1c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2321.1,487.4,2320.1,485.9,2320.1,484.1z M2323,481.8c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C2324.8,482.4,2323.9,481.8,2323,481.8z"
          />
          <path
            d="M2331.1,485.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2332,487.4,2331.1,486.7,2331.1,485.6z M2335,484l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4V484
				z"
          />
          <path d="M2339.6,487.3l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H2339.6z" />
          <path
            d="M2343.7,485.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2344.6,487.4,2343.7,486.7,2343.7,485.6z M2347.6,484l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V484z"
          />
          <path d="M2350.5,478.6h1.2v1.3h-1.2V478.6z M2351.6,487.3h-1.1v-6.4h1.1V487.3z" />
          <path d="M2354.6,487.3h-1.1v-8.7h1.1V487.3z" />
          <path
            d="M2355.9,485.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2356.8,487.4,2355.9,486.7,2355.9,485.6z M2359.8,484l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V484z"
          />
          <path
            d="M2363.9,486.4v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2365,487.4,2364.3,487,2363.9,486.4z M2363.9,484.4c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V484.4z"
          />
          <path d="M2371.1,487.3h-1.1v-8.7h1.1V487.3z" />
          <path
            d="M2372.5,484.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2373.7,487.4,2372.5,486.1,2372.5,484.1z M2373.6,483.5h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2374.5,481.8,2373.8,482.4,2373.6,483.5z"
          />
        </g>
        <g>
          <path
            d="M2302.3,492.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V492.9z"
          />
          <path
            d="M2305.8,500.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2308.7,499.6,2307.5,500.9,2305.8,500.9z M2305.8,500
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2303.9,499.1,2304.6,500,2305.8,500z"
          />
          <path
            d="M2313.4,495.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V495.5z"
          />
          <path
            d="M2318.6,498.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2318.6,498.8z"
          />
          <path
            d="M2329.4,500.8h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V500.8z"
          />
          <path
            d="M2331.4,498.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2331.4,498.8z"
          />
          <path
            d="M2337.3,495.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V495.4z"
          />
          <path
            d="M2340.8,499.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2341.7,500.9,2340.8,500.2,2340.8,499.1z M2344.7,497.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V497.6z"
          />
          <path d="M2347.6,492.1h1.2v1.3h-1.2V492.1z M2348.8,500.8h-1.1v-6.4h1.1V500.8z" />
          <path
            d="M2350.6,494.5h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V494.5z"
          />
          <path
            d="M2357,499.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2357.9,500.9,2357,500.2,2357,499.1z M2360.9,497.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V497.6z"
          />
          <path
            d="M2364.9,499.9v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2366,500.9,2365.3,500.5,2364.9,499.9z M2364.9,497.9c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V497.9z"
          />
          <path d="M2372.2,500.8h-1.1v-8.7h1.1V500.8z" />
          <path
            d="M2373.6,497.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2374.8,500.9,2373.6,499.6,2373.6,497.6z M2374.7,497h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2375.6,495.3,2374.9,495.9,2374.7,497z"
          />
        </g>
        <g>
          <path
            d="M2307.4,506.5c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1V508h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V506.5z"
          />
          <path
            d="M2310.9,514.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2313.9,513.1,2312.7,514.5,2310.9,514.5z M2310.9,513.5
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2309.1,512.6,2309.8,513.5,2310.9,513.5z"
          />
          <path
            d="M2317.8,514.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2320.7,513.1,2319.5,514.5,2317.8,514.5z M2317.8,513.5
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2315.9,512.6,2316.7,513.5,2317.8,513.5z"
          />
          <path
            d="M2321.7,511.2c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2322.7,514.5,2321.7,513,2321.7,511.2z M2324.6,508.8c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C2326.4,509.5,2325.5,508.8,2324.6,508.8z"
          />
          <path
            d="M2336,506.5c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1V508h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V506.5z"
          />
          <path
            d="M2336.4,512.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3V510c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2337.4,514.4,2336.4,513.8,2336.4,512.6z M2340.4,511.1l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V511.1z"
          />
          <path
            d="M2346.6,509.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1V508h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V509.1z"
          />
          <path
            d="M2348.9,514.3h-1.1V508h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V514.3
				z"
          />
          <path d="M2358.4,505.6h1.2v1.3h-1.2V505.6z M2359.5,514.3h-1.1V508h1.1V514.3z" />
          <path
            d="M2361.3,508h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V508z"
          />
          <path
            d="M2370.6,516.7c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1V508h1.1v5.8
				C2373.6,515.6,2372.4,516.7,2370.6,516.7z M2370.7,508.8c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C2372.5,509.5,2371.7,508.8,2370.7,508.8z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="84">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3306.2,1670.3c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3285.7,1624.6,3306.2,1645,3306.2,1670.3"
        />
        <g>
          <path d="M3223.6,1674.5v-8.7h5.6v1h-4.5v2.8h3.8v1h-3.8v3.9H3223.6z" />
          <path
            d="M3233.1,1674.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3236.1,1673.3,3234.9,1674.7,3233.1,1674.7z
				 M3233.1,1673.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3231.3,1672.8,3232,1673.7,3233.1,1673.7z"
          />
          <path
            d="M3240,1674.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3243,1673.3,3241.7,1674.7,3240,1674.7z M3240,1673.7
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3238.1,1672.8,3238.9,1673.7,3240,1673.7z"
          />
          <path
            d="M3243.9,1671.4c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3244.9,1674.7,3243.9,1673.2,3243.9,1671.4z M3246.9,1669c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C3248.7,1669.7,3247.8,1669,3246.9,1669z"
          />
          <path d="M3255.5,1665.8h1.2v7.7h4.4v1h-5.5V1665.8z" />
          <path d="M3262.1,1665.8h1.2v1.3h-1.2V1665.8z M3263.2,1674.5h-1.1v-6.4h1.1V1674.5z" />
          <path
            d="M3265.2,1669.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1669.1z"
          />
          <path
            d="M3268.7,1671.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3269.9,1674.7,3268.7,1673.3,3268.7,1671.4z M3269.8,1670.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3270.7,1669,3270,1669.6,3269.8,1670.7z"
          />
          <path
            d="M3279,1669.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1669.3z"
          />
          <path
            d="M3279.7,1672.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3280.7,1674.6,3279.7,1674,3279.7,1672.8z M3283.7,1671.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1671.3z"
          />
          <path
            d="M3289.2,1673.7c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3287.3,1672.8,3288.1,1673.7,3289.2,1673.7z"
          />
          <path
            d="M3293.3,1676.8c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C3294.9,1676.2,3294.4,1676.8,3293.3,1676.8z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="83">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3576.2,1000.2c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3555.7,954.4,3576.2,974.9,3576.2,1000.2"
        />
        <g>
          <path d="M3499.4,988.9v8.7h-1.2v-4h-4.7v4h-1.2v-8.7h1.2v3.7h4.7v-3.7H3499.4z" />
          <path
            d="M3500.8,994.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3502.1,997.8,3500.8,996.4,3500.8,994.5z M3501.9,993.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3502.8,992.1,3502.1,992.7,3501.9,993.8z"
          />
          <path
            d="M3508,992.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V992.2z"
          />
          <path
            d="M3511.5,994.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3512.8,997.8,3511.5,996.4,3511.5,994.5z M3512.6,993.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3513.5,992.1,3512.8,992.7,3512.6,993.8z"
          />
          <path
            d="M3521.8,992.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V992.3z"
          />
          <path
            d="M3525.6,997.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3528.5,996.4,3527.3,997.8,3525.6,997.8z M3525.6,996.8
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3523.7,995.9,3524.4,996.8,3525.6,996.8z"
          />
          <path
            d="M3532.3,999.9c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3535.3,998.9,3534.1,999.9,3532.3,999.9z M3532.5,992.1c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C3534.2,992.8,3533.4,992.1,3532.5,992.1z"
          />
          <path
            d="M3536.7,994.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3537.9,997.8,3536.7,996.4,3536.7,994.5z M3537.8,993.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3538.7,992.1,3538,992.7,3537.8,993.8z"
          />
          <path
            d="M3543.7,991.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V991.3z"
          />
          <path
            d="M3550.1,994.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3551.3,997.8,3550.1,996.4,3550.1,994.5z M3551.2,993.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3552.1,992.1,3551.4,992.7,3551.2,993.8z"
          />
          <path d="M3557.1,988.9h1.2v1.3h-1.2V988.9z M3558.2,997.6h-1.1v-6.4h1.1V997.6z" />
          <path
            d="M3560.2,992.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V992.2z"
          />
          <path
            d="M3564.6,999.9c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C3566.2,999.3,3565.7,999.9,3564.6,999.9z"
          />
        </g>
        <g>
          <path
            d="M3507.2,1011.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3510.1,1009.9,3508.9,1011.3,3507.2,1011.3z
				 M3507.2,1010.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3505.3,1009.4,3506.1,1010.3,3507.2,1010.3
				z"
          />
          <path
            d="M3514.3,1003.3c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1003.3z"
          />
          <path
            d="M3519.3,1009.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3519.3,1009.2z"
          />
          <path
            d="M3527.5,1011.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3530.5,1009.9,3529.3,1011.3,3527.5,1011.3z
				 M3527.5,1010.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3525.7,1009.4,3526.4,1010.3,3527.5,1010.3
				z"
          />
          <path
            d="M3534.5,1010.3c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3532.5,1009.4,3533.3,1010.3,3534.5,1010.3z"
          />
          <path d="M3538.2,1002.4h1.2v1.3h-1.2V1002.4z M3539.3,1011.1h-1.1v-6.4h1.1V1011.1z" />
          <path
            d="M3540.7,1008c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3541.9,1011.3,3540.7,1009.9,3540.7,1008z M3541.8,1007.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3542.7,1005.6,3542,1006.2,3541.8,1007.3z"
          />
          <path
            d="M3547.9,1005.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1005.7z"
          />
          <path
            d="M3552.2,1013.4c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C3553.8,1012.8,3553.3,1013.4,3552.2,1013.4z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="82">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1290.7,814.6c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1270.2,768.9,1290.7,789.4,1290.7,814.6"
        />
        <g>
          <path d="M1221.9,791.8l3.1,8.1h-1.1l-0.8-2.1h-3.6l-0.8,2.1h-1.1l3.1-8.1H1221.9z M1219.9,796.8h2.9l-1.5-3.8L1219.9,796.8z" />
          <path d="M1227.6,799.8l-2.2-5.9h1.1l1.7,4.6l1.6-4.6h1.1l-2.2,5.9H1227.6z" />
          <path
            d="M1231.3,798.3c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C1232.2,799.9,1231.3,799.3,1231.3,798.3z M1234.9,796.8l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V796.8z"
          />
          <path d="M1237.6,791.8h1.1v1.2h-1.1V791.8z M1238.7,799.8h-1V794h1V799.8z" />
          <path d="M1241.4,799.8h-1v-8.1h1V799.8z" />
          <path
            d="M1242.6,798.3c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C1243.5,799.9,1242.6,799.3,1242.6,798.3z M1246.2,796.8l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V796.8z"
          />
          <path
            d="M1250,799v0.9h-1v-8.1h1v3c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C1251,800,1250.3,799.6,1250,799z M1249.9,797.1c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V797.1z"
          />
          <path d="M1255.6,791.8h1.1v1.2h-1.1V791.8z M1256.6,799.8h-1V794h1V799.8z" />
          <path d="M1259.3,799.8h-1v-8.1h1V799.8z" />
          <path d="M1261,791.8h1.1v1.2h-1.1V791.8z M1262,799.8h-1V794h1V799.8z" />
          <path
            d="M1263.9,794.8h-0.9V794h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V794.8z"
          />
          <path
            d="M1267.9,801.9c-0.2,0-0.4,0-0.6,0V801c0.2,0,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,0.9-0.7l0.3-0.7l-2.2-5.7h1.1l1.7,4.5
				l1.6-4.5h1.1l-2.5,6.5C1269.4,801.4,1268.9,801.9,1267.9,801.9z"
          />
        </g>
        <g>
          <path
            d="M1212.7,812.4c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S1214.3,812.4,1212.7,812.4z M1212.7,811.6
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1211,810.7,1211.7,811.6,1212.7,811.6z"
          />
          <path
            d="M1219.3,805c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9V806c0-1.1,0.6-1.8,1.8-1.8
				c0.2,0,0.3,0,0.5,0V805z"
          />
          <path
            d="M1223.9,810.5c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1223.9,810.5z"
          />
          <path
            d="M1233.9,812.3h-1v-0.8c-0.3,0.5-0.9,0.9-1.7,0.9c-1.3,0-2-0.9-2-2.1v-3.9h1v3.7c0,0.8,0.4,1.4,1.2,1.4
				c0.7,0,1.5-0.6,1.5-1.5v-3.5h1V812.3z"
          />
          <path
            d="M1235.7,810.5c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1235.7,810.5z"
          />
          <path
            d="M1241.2,807.3h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V807.3z"
          />
          <path
            d="M1244.4,810.7c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C1245.2,812.4,1244.4,811.8,1244.4,810.7z M1248,809.3l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V809.3z"
          />
          <path d="M1250.7,804.2h1.1v1.2h-1.1V804.2z M1251.7,812.3h-1v-5.9h1V812.3z" />
          <path
            d="M1253.4,806.4h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V806.4z"
          />
          <path
            d="M1259.3,810.7c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C1260.2,812.4,1259.3,811.8,1259.3,810.7z M1262.9,809.3l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V809.3z"
          />
          <path
            d="M1266.7,811.5v0.9h-1v-8.1h1v3c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C1267.7,812.4,1267,812,1266.7,811.5z M1266.6,809.6c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V809.6z"
          />
          <path d="M1273.3,812.3h-1v-8.1h1V812.3z" />
          <path
            d="M1274.6,809.4c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C1275.7,812.4,1274.6,811.2,1274.6,809.4z M1275.6,808.8h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1276.5,807.2,1275.8,807.8,1275.6,808.8z"
          />
        </g>
        <g>
          <path
            d="M1222.6,819.8h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V819.8z"
          />
          <path
            d="M1229.3,819.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0V819.9z
				"
          />
          <path
            d="M1229.9,823.2c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C1230.8,824.9,1229.9,824.3,1229.9,823.2z M1233.6,821.8l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V821.8z"
          />
          <path
            d="M1236.3,818.9h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V818.9z"
          />
          <path
            d="M1242.8,823c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1242.8,823z"
          />
          <path
            d="M1249.1,823.9v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C1250.1,824.9,1249.5,824.5,1249.1,823.9z M1249.1,822.1c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V822.1z"
          />
          <path
            d="M1257.1,824.9c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S1258.7,824.9,1257.1,824.9z M1257.1,824
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1255.4,823.2,1256.1,824,1257.1,824z"
          />
          <path
            d="M1264.1,819.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0V819.9z
				"
          />
          <path
            d="M1265.4,819.8h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V819.8z"
          />
        </g>
        <g>
          <path
            d="M1229.3,837.4c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S1230.9,837.4,1229.3,837.4z M1229.3,836.5
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1227.6,835.7,1228.2,836.5,1229.3,836.5z"
          />
          <path
            d="M1234.3,836.4v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C1235.3,837.4,1234.6,837,1234.3,836.4z M1234.3,834.5c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V834.5z"
          />
          <path
            d="M1240.1,832.3h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V832.3z"
          />
          <path d="M1243.7,829.2h1.1v1.2h-1.1V829.2z M1244.8,837.3h-1v-5.9h1V837.3z" />
          <path
            d="M1248.8,837.4c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S1250.4,837.4,1248.8,837.4z M1248.8,836.5
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1247.1,835.7,1247.7,836.5,1248.8,836.5z"
          />
          <path
            d="M1252.8,831.4h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V831.4z"
          />
          <path
            d="M1259.2,835.4c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1259.2,835.4z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="81">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M2307.4,1435.2c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2286.9,1389.5,2307.4,1410,2307.4,1435.2"
        />
        <g>
          <path
            d="M2232.9,1418.3c1.1,0,1.9-0.7,2.3-1.8l1.1,0.4c-0.5,1.5-1.7,2.4-3.4,2.4c-2.3,0-3.9-1.8-3.9-4.5c0-2.7,1.7-4.5,3.9-4.5
				c1.7,0,2.9,0.9,3.4,2.4l-1.1,0.4c-0.3-1.1-1.1-1.8-2.3-1.8c-1.6,0-2.8,1.4-2.8,3.5S2231.3,1418.3,2232.9,1418.3z"
          />
          <path
            d="M2240,1419.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2243,1418,2241.7,1419.3,2240,1419.3z M2240,1418.4
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2238.1,1417.5,2238.9,1418.4,2240,1418.4z"
          />
          <path
            d="M2245.4,1419.2h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1419.2z"
          />
          <path
            d="M2256,1418.3v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2257.1,1419.3,2256.4,1418.9,2256,1418.3z M2256,1416.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1416.3z"
          />
          <path
            d="M2261.7,1416c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2262.9,1419.3,2261.7,1418,2261.7,1416z M2262.8,1415.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2263.7,1413.6,2263,1414.3,2262.8,1415.4z"
          />
          <path
            d="M2268.9,1413.8h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1413.8z"
          />
          <path d="M2272.8,1410.5h1.2v1.3h-1.2V1410.5z M2274,1419.2h-1.1v-6.4h1.1V1419.2z" />
          <path
            d="M2276,1413.8h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1413.8z"
          />
          <path d="M2279.9,1410.5h1.2v1.3h-1.2V1410.5z M2281,1419.2h-1.1v-6.4h1.1V1419.2z" />
          <path d="M2284.5,1419.2l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H2284.5z" />
          <path
            d="M2288.6,1416c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2289.8,1419.3,2288.6,1418,2288.6,1416z M2289.7,1415.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2290.6,1413.6,2289.9,1414.3,2289.7,1415.4z"
          />
        </g>
        <g>
          <path
            d="M2237.4,1431.8v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2238.5,1432.8,2237.8,1432.4,2237.4,1431.8z M2237.4,1429.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1429.8z"
          />
          <path
            d="M2246.8,1427.4c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1427.4z"
          />
          <path d="M2248,1424h1.2v1.3h-1.2V1424z M2249.1,1432.7h-1.1v-6.4h1.1V1432.7z" />
          <path
            d="M2253.5,1431.9c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2251.6,1431,2252.4,1431.9,2253.5,1431.9z"
          />
          <path d="M2257.2,1424h1.2v1.3h-1.2V1424z M2258.4,1432.7h-1.1v-6.4h1.1V1432.7z" />
          <path
            d="M2260.2,1426.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1426.3z"
          />
          <path
            d="M2269.5,1435c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2272.5,1434,2271.2,1435,2269.5,1435z M2269.6,1427.2c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C2271.4,1427.9,2270.5,1427.2,2269.6,1427.2z"
          />
          <path
            d="M2280.7,1432.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2283.6,1431.5,2282.4,1432.8,2280.7,1432.8z
				 M2280.7,1431.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2278.8,1431,2279.5,1431.9,2280.7,1431.9z"
          />
          <path
            d="M2287.8,1424.8c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1424.8z"
          />
        </g>
        <g>
          <path
            d="M2231.6,1444.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2231.6,1444.3z"
          />
          <path
            d="M2242.4,1446.2h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1446.2z"
          />
          <path
            d="M2244.4,1444.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2244.4,1444.3z"
          />
          <path
            d="M2250.3,1440.8h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1440.8z"
          />
          <path
            d="M2253.8,1444.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2254.7,1446.3,2253.8,1445.7,2253.8,1444.5z M2257.7,1443l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1443z"
          />
          <path d="M2260.6,1437.5h1.2v1.3h-1.2V1437.5z M2261.8,1446.2h-1.1v-6.4h1.1V1446.2z" />
          <path
            d="M2263.6,1439.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1439.9z"
          />
          <path
            d="M2270,1444.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2270.9,1446.3,2270,1445.7,2270,1444.5z M2273.9,1443l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V1443z"
          />
          <path
            d="M2278,1445.3v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2279,1446.4,2278.3,1445.9,2278,1445.3z M2277.9,1443.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1443.3z"
          />
          <path d="M2285.2,1446.2h-1.1v-8.7h1.1V1446.2z" />
          <path
            d="M2286.6,1443c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2287.8,1446.4,2286.6,1445,2286.6,1443z M2287.7,1442.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2288.6,1440.7,2287.9,1441.3,2287.7,1442.4z"
          />
        </g>
        <g>
          <path
            d="M2253,1451.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1451.9z"
          />
          <path
            d="M2256.4,1459.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2259.4,1458.5,2258.2,1459.9,2256.4,1459.9z
				 M2256.4,1458.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2254.6,1458,2255.3,1458.9,2256.4,1458.9z"
          />
          <path
            d="M2263.3,1459.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2266.3,1458.5,2265,1459.9,2263.3,1459.9z
				 M2263.3,1458.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2261.5,1458,2262.2,1458.9,2263.3,1458.9z"
          />
          <path
            d="M2267.2,1456.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2268.2,1459.9,2267.2,1458.4,2267.2,1456.6z M2270.2,1454.2c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C2272,1454.9,2271.1,1454.2,2270.2,1454.2z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="80">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2482.1,1437.1c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2461.6,1391.3,2482.1,1411.8,2482.1,1437.1"
        />
        <g>
          <path d="M2407.6,1419l3.4,8.7h-1.2l-0.9-2.3h-3.9l-0.9,2.3h-1.2l3.4-8.7H2407.6z M2405.4,1424.5h3.1l-1.6-4.1L2405.4,1424.5z" />
          <path
            d="M2414.8,1419.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1419.9z"
          />
          <path
            d="M2418.6,1419.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1419.9z"
          />
          <path
            d="M2422,1427.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2425,1426.6,2423.8,1427.9,2422,1427.9z M2422,1427
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2420.2,1426,2420.9,1427,2422,1427z"
          />
          <path
            d="M2429.7,1422.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1422.5z"
          />
          <path
            d="M2430.5,1424.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2431.5,1427.9,2430.5,1426.4,2430.5,1424.6z M2433.4,1422.3c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C2435.2,1422.9,2434.3,1422.3,2433.4,1422.3z"
          />
          <path
            d="M2437.6,1426.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2438.5,1427.9,2437.6,1427.2,2437.6,1426.1z M2441.6,1424.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1424.5z"
          />
          <path
            d="M2445.6,1426.8v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2446.7,1427.9,2446,1427.5,2445.6,1426.8z M2445.6,1424.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1424.8z"
          />
          <path d="M2451.7,1419h1.2v1.3h-1.2V1419z M2452.8,1427.8h-1.1v-6.4h1.1V1427.8z" />
          <path d="M2455.7,1427.8h-1.1v-8.7h1.1V1427.8z" />
          <path d="M2457.5,1419h1.2v1.3h-1.2V1419z M2458.7,1427.8h-1.1v-6.4h1.1V1427.8z" />
          <path
            d="M2460.7,1422.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1422.3z"
          />
          <path
            d="M2465,1430c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C2466.6,1429.4,2466.1,1430,2465,1430z"
          />
        </g>
        <g>
          <path
            d="M2401.7,1441.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2404.6,1440.1,2403.4,1441.4,2401.7,1441.4z
				 M2401.7,1440.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2399.8,1439.6,2400.5,1440.5,2401.7,1440.5
				z"
          />
          <path
            d="M2408.8,1433.4c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1433.4z"
          />
          <path
            d="M2413.7,1439.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2413.7,1439.3z"
          />
          <path
            d="M2424.5,1441.3h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1441.3z"
          />
          <path
            d="M2426.5,1439.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2426.5,1439.3z"
          />
          <path
            d="M2432.4,1435.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1435.9z"
          />
          <path
            d="M2435.9,1439.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2436.8,1441.4,2435.9,1440.7,2435.9,1439.6z M2439.9,1438l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1438z"
          />
          <path d="M2442.8,1432.6h1.2v1.3h-1.2V1432.6z M2443.9,1441.3h-1.1v-6.4h1.1V1441.3z" />
          <path
            d="M2445.7,1434.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1434.9z"
          />
          <path
            d="M2452.1,1439.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2453,1441.4,2452.1,1440.7,2452.1,1439.6z M2456,1438l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V1438z"
          />
          <path
            d="M2460.1,1440.4v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2461.2,1441.4,2460.5,1441,2460.1,1440.4z M2460.1,1438.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1438.3z"
          />
          <path d="M2467.3,1441.3h-1.1v-8.7h1.1V1441.3z" />
          <path
            d="M2469.5,1443.5c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C2471.1,1442.9,2470.6,1443.5,2469.5,1443.5z"
          />
        </g>
        <g>
          <path
            d="M2397.7,1453.9v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2398.7,1454.9,2398,1454.5,2397.7,1453.9z M2397.6,1451.9c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1451.9z"
          />
          <path
            d="M2407.1,1449.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1449.5z"
          />
          <path
            d="M2410.8,1454.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2413.8,1453.6,2412.6,1454.9,2410.8,1454.9z
				 M2410.8,1454c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2409,1453.1,2409.7,1454,2410.8,1454z"
          />
          <path
            d="M2414.7,1451.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2415.7,1454.9,2414.7,1453.4,2414.7,1451.6z M2417.7,1449.3c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C2419.5,1449.9,2418.6,1449.3,2417.7,1449.3z"
          />
          <path
            d="M2427.4,1454.8h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1454.8z"
          />
          <path
            d="M2431.8,1454c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2429.9,1453.1,2430.7,1454,2431.8,1454z"
          />
          <path
            d="M2435.1,1451.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2436.3,1454.9,2435.1,1453.6,2435.1,1451.6z M2436.2,1451h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2437.1,1449.3,2436.4,1449.9,2436.2,1451z"
          />
          <path
            d="M2441.7,1451.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2442.7,1454.9,2441.7,1453.4,2441.7,1451.6z M2444.7,1449.3c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C2446.5,1449.9,2445.6,1449.3,2444.7,1449.3z"
          />
          <path
            d="M2456,1446.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1446.9z"
          />
          <path
            d="M2459.4,1454.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2462.4,1453.6,2461.2,1454.9,2459.4,1454.9z
				 M2459.4,1454c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2457.6,1453.1,2458.3,1454,2459.4,1454z"
          />
          <path
            d="M2466.3,1454.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2469.3,1453.6,2468,1454.9,2466.3,1454.9z
				 M2466.3,1454c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2464.5,1453.1,2465.2,1454,2466.3,1454z"
          />
          <path
            d="M2470.2,1451.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2471.2,1454.9,2470.2,1453.4,2470.2,1451.6z M2473.2,1449.3c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C2475,1449.9,2474.1,1449.3,2473.2,1449.3z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="79">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M2165.2,629c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2144.8,583.3,2165.2,603.7,2165.2,629"
        />
        <g>
          <path
            d="M2090.6,610.4c0.5,1,1.5,1.7,2.7,1.7s2-0.6,2-1.6c0-1.1-1-1.2-2.3-1.6c-1.4-0.3-2.7-0.7-2.7-2.3c0-1.5,1.3-2.5,3-2.5
				c1.5,0,2.6,0.7,3.1,1.7l-0.9,0.7c-0.4-0.8-1.1-1.4-2.3-1.4c-1.1,0-1.8,0.6-1.8,1.4c0,0.9,0.8,1.1,2,1.4c1.4,0.3,3,0.6,3,2.5
				c0,1.6-1.4,2.7-3.2,2.7c-1.5,0-3-0.8-3.6-1.9L2090.6,610.4z"
          />
          <path
            d="M2097.7,604.2h1.1v3.2c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V604.2z"
          />
          <path
            d="M2104.1,609.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2105.4,613.1,2104.1,611.8,2104.1,609.8z M2105.3,609.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2106.1,607.4,2105.4,608,2105.3,609.1z"
          />
          <path d="M2112.2,613h-1.1v-8.7h1.1V613z" />
          <path
            d="M2116.8,605.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V605.1z"
          />
          <path
            d="M2118,611c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8c0-1.1,1-1.9,2.3-1.9
				c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9c1.1,0.3,2.3,0.5,2.3,1.9
				c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2118,611z"
          />
          <path
            d="M2124.8,612v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2125.9,613.1,2125.2,612.7,2124.8,612z M2124.8,610c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V610z"
          />
          <path
            d="M2130.4,611.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2131.4,613.1,2130.4,612.4,2130.4,611.3z M2134.4,609.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V609.7z"
          />
          <path
            d="M2139.9,612.1c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2138,611.2,2138.8,612.1,2139.9,612.1z"
          />
          <path
            d="M2143.2,609.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2144.4,613.1,2143.2,611.8,2143.2,609.8z M2144.3,609.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2145.2,607.4,2144.5,608,2144.3,609.1z"
          />
        </g>
        <g>
          <path
            d="M2087.6,624.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2088.5,626.6,2087.6,625.9,2087.6,624.8z M2091.6,623.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V623.2z"
          />
          <path d="M2095.6,626.5h-1.1v-8.7h1.1V626.5z" />
          <path d="M2098.5,626.5h-1.1v-8.7h1.1V626.5z" />
          <path
            d="M2102.9,626.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2105.8,625.3,2104.6,626.6,2102.9,626.6z
				 M2102.9,625.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2101,624.8,2101.7,625.7,2102.9,625.7z"
          />
          <path
            d="M2109.8,625.7c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2107.9,624.8,2108.6,625.7,2109.8,625.7z"
          />
          <path
            d="M2113,624.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2114,626.6,2113,625.9,2113,624.8z M2117,623.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4V623.2
				z"
          />
          <path
            d="M2120.1,621.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V621.1z"
          />
          <path
            d="M2123.6,623.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2124.8,626.6,2123.6,625.3,2123.6,623.3z M2124.7,622.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2125.6,620.9,2124.9,621.6,2124.7,622.6z"
          />
          <path
            d="M2130.2,623.3c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2131.2,626.6,2130.2,625.1,2130.2,623.3z M2133.2,621c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C2135,621.6,2134.1,621,2133.2,621z"
          />
          <path
            d="M2141.9,621.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V621.1z"
          />
          <path
            d="M2148.4,626.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2151.3,625.3,2150.1,626.6,2148.4,626.6z
				 M2148.4,625.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2146.5,624.8,2147.2,625.7,2148.4,625.7z"
          />
        </g>
        <g>
          <path
            d="M2075.2,638c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2075.2,638z"
          />
          <path
            d="M2086,640h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5c0.8,0,1.6-0.6,1.6-1.7
				v-3.8h1.1V640z"
          />
          <path
            d="M2088,638c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8c0-1.1,1-1.9,2.3-1.9
				c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9c1.1,0.3,2.3,0.5,2.3,1.9
				c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2088,638z"
          />
          <path
            d="M2093.9,634.6h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V634.6z"
          />
          <path
            d="M2097.4,638.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2098.3,640.1,2097.4,639.4,2097.4,638.3z M2101.4,636.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V636.7z"
          />
          <path d="M2104.3,631.3h1.2v1.3h-1.2V631.3z M2105.4,640h-1.1v-6.4h1.1V640z" />
          <path
            d="M2107.2,633.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V633.6z"
          />
          <path
            d="M2113.6,638.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2114.5,640.1,2113.6,639.4,2113.6,638.3z M2117.5,636.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V636.7z"
          />
          <path
            d="M2121.6,639.1v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2122.7,640.1,2122,639.7,2121.6,639.1z M2121.6,637.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V637.1z"
          />
          <path d="M2128.8,640h-1.1v-8.7h1.1V640z" />
          <path
            d="M2130.2,636.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2131.4,640.1,2130.2,638.8,2130.2,636.8z M2131.3,636.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2132.2,634.5,2131.5,635.1,2131.3,636.2z"
          />
          <path
            d="M2143.8,632.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V632.1z"
          />
          <path
            d="M2147.3,640.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2150.3,638.8,2149,640.1,2147.3,640.1z
				 M2147.3,639.2c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2145.5,638.3,2146.2,639.2,2147.3,639.2z"
          />
          <path
            d="M2154.2,640.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2157.1,638.8,2155.9,640.1,2154.2,640.1z
				 M2154.2,639.2c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2152.3,638.3,2153.1,639.2,2154.2,639.2z"
          />
          <path
            d="M2158.1,636.8c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2159.1,640.1,2158.1,638.6,2158.1,636.8z M2161,634.5c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C2162.8,635.1,2161.9,634.5,2161,634.5z"
          />
        </g>
        <g>
          <path
            d="M2083.3,651.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2084.2,653.6,2083.3,652.9,2083.3,651.8z M2087.2,650.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V650.3z"
          />
          <path
            d="M2090.3,648.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V648.1z"
          />
          <path d="M2099.2,653.5h-1.1v-8.7h1.1V653.5z" />
          <path
            d="M2100.6,651.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2101.5,653.6,2100.6,652.9,2100.6,651.8z M2104.5,650.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V650.3z"
          />
          <path
            d="M2110.8,648.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V648.2z"
          />
          <path
            d="M2114.4,655.8c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2117.4,654.8,2116.1,655.8,2114.4,655.8z M2114.5,648c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C2116.3,648.7,2115.4,648,2114.5,648z"
          />
          <path
            d="M2118.7,650.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2120,653.6,2118.7,652.3,2118.7,650.3z M2119.9,649.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2120.7,648,2120,648.6,2119.9,649.7z"
          />
          <path
            d="M2132.9,648.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V648.2z"
          />
          <path
            d="M2133.7,650.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2134.9,653.6,2133.7,652.3,2133.7,650.3z M2134.8,649.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2135.7,648,2135,648.6,2134.8,649.7z"
          />
          <path
            d="M2140.9,648.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V648.1z"
          />
          <path
            d="M2144.3,651.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2145.3,653.6,2144.3,652.9,2144.3,651.8z M2148.3,650.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V650.3z"
          />
          <path d="M2151.2,644.8h1.2v1.3h-1.2V644.8z M2152.3,653.5h-1.1v-6.4h1.1V653.5z" />
          <path d="M2155.2,653.5h-1.1v-8.7h1.1V653.5z" />
        </g>
      </g>
      <g onClick={handleClick} id="78">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M2275.3,329.7c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2254.8,284,2275.3,304.4,2275.3,329.7"
        />
        <g>
          <path
            d="M2201.9,320.4h-1.2v-8.7h3.3c1.8,0,3,0.9,3,2.5c0,1.6-1.2,2.5-3,2.5h-2.2V320.4z M2204,315.8c1.2,0,1.9-0.5,1.9-1.5
				c0-1-0.7-1.5-1.9-1.5h-2.1v3.1H2204z"
          />
          <path
            d="M2207.8,317.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2209.1,320.6,2207.8,319.2,2207.8,317.2z M2209,316.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2209.8,314.9,2209.1,315.5,2209,316.6z"
          />
          <path
            d="M2218.1,315.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V315.1z"
          />
          <path
            d="M2221.9,319.6c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2220,318.7,2220.8,319.6,2221.9,319.6z"
          />
          <path
            d="M2225.2,317.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2226.5,320.6,2225.2,319.2,2225.2,317.2z M2226.3,316.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2227.2,314.9,2226.5,315.5,2226.3,316.6z"
          />
          <path
            d="M2233.3,319.5v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2234.4,320.6,2233.7,320.1,2233.3,319.5z M2233.3,317.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V317.5z"
          />
          <path
            d="M2239.6,315h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V315z"
          />
          <path d="M2243.6,311.7h1.2v1.3h-1.2V311.7z M2244.7,320.4h-1.1v-6.4h1.1V320.4z" />
          <path
            d="M2249,320.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2252,319.2,2250.8,320.6,2249,320.6z M2249,319.6
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2247.2,318.7,2247.9,319.6,2249,319.6z"
          />
          <path
            d="M2253.4,314.1h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V314.1z"
          />
        </g>
        <g>
          <path
            d="M2192.2,334.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2195.1,332.7,2193.9,334.1,2192.2,334.1z
				 M2192.2,333.1c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2190.3,332.2,2191,333.1,2192.2,333.1z"
          />
          <path
            d="M2199.3,326.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V326.1z"
          />
          <path
            d="M2204.2,332c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2204.2,332z"
          />
          <path
            d="M2210,325.2h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1V330c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V325.2z"
          />
          <path
            d="M2219.4,334.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2222.3,332.7,2221.1,334.1,2219.4,334.1z M2219.4,333.1
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2217.5,332.2,2218.3,333.1,2219.4,333.1z"
          />
          <path
            d="M2224.8,333v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2225.9,334.1,2225.2,333.6,2224.8,333z M2224.8,331c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V331z"
          />
          <path
            d="M2232,333v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2233.1,334.1,2232.4,333.6,2232,333z M2232,331c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V331z"
          />
          <path d="M2238.1,325.2h1.2v1.3h-1.2V325.2z M2239.3,333.9h-1.1v-6.4h1.1V333.9z" />
          <path
            d="M2241.1,327.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1V330c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V327.6z"
          />
          <path
            d="M2250.4,336.3c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2253.3,335.2,2252.1,336.3,2250.4,336.3z M2250.5,328.4c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C2252.3,329.1,2251.4,328.4,2250.5,328.4z"
          />
          <path
            d="M2258.5,332.2c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2259.5,334,2258.5,333.4,2258.5,332.2z M2262.5,330.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V330.7z"
          />
          <path
            d="M2265.6,332c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2265.6,332z"
          />
        </g>
        <g>
          <path
            d="M2191.3,345.7c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2192.2,347.6,2191.3,346.9,2191.3,345.7z M2195.2,344.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V344.2z"
          />
          <path
            d="M2198.2,341.1h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V341.1z"
          />
          <path
            d="M2208.5,344.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2209.7,347.6,2208.5,346.2,2208.5,344.3z M2209.6,343.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2210.5,341.9,2209.8,342.5,2209.6,343.6z"
          />
          <path d="M2217.6,344.9l-1.8,2.5h-1.2l2.3-3.3l-2.1-3h1.3l1.5,2.2l1.6-2.2h1.2l-2.1,3l2.3,3.3h-1.2L2217.6,344.9z" />
          <path
            d="M2222.7,346.5v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2223.8,347.6,2223.1,347.2,2222.7,346.5z M2222.7,344.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V344.5z"
          />
          <path
            d="M2228.4,344.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2229.6,347.6,2228.4,346.2,2228.4,344.3z M2229.5,343.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2230.4,341.9,2229.7,342.5,2229.5,343.6z"
          />
          <path
            d="M2238.7,342.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V342.2z"
          />
          <path d="M2239.9,338.7h1.2v1.3h-1.2V338.7z M2241,347.5h-1.1v-6.4h1.1V347.5z" />
          <path
            d="M2242.4,344.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2243.6,347.6,2242.4,346.2,2242.4,344.3z M2243.5,343.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2244.4,341.9,2243.7,342.5,2243.5,343.6z"
          />
          <path
            d="M2249.4,341.1h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V341.1z"
          />
          <path
            d="M2258.9,346.6c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2256.9,345.7,2257.7,346.6,2258.9,346.6z"
          />
          <path
            d="M2262.2,344.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2263.4,347.6,2262.2,346.2,2262.2,344.3z M2263.3,343.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2264.2,341.9,2263.5,342.5,2263.3,343.6z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="77">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M2167,503.9c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2146.6,458.1,2167,478.6,2167,503.9"
        />
        <g>
          <path
            d="M2085.4,493.7c1.1,0,1.9-0.7,2.3-1.8l1.1,0.4c-0.5,1.5-1.7,2.4-3.4,2.4c-2.3,0-3.9-1.8-3.9-4.5c0-2.7,1.7-4.5,3.9-4.5
				c1.7,0,2.9,0.9,3.4,2.4l-1.1,0.4c-0.3-1.1-1.1-1.8-2.3-1.8c-1.6,0-2.8,1.4-2.8,3.5S2083.8,493.7,2085.4,493.7z"
          />
          <path
            d="M2092.5,494.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2095.4,493.4,2094.2,494.7,2092.5,494.7z
				 M2092.5,493.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2090.6,492.8,2091.4,493.8,2092.5,493.8z"
          />
          <path
            d="M2097,492.6c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2097,492.6z"
          />
          <path
            d="M2102.9,489.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V489.2z"
          />
          <path
            d="M2113.3,494.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2116.2,493.4,2115,494.7,2113.3,494.7z
				 M2113.3,493.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2111.4,492.8,2112.1,493.8,2113.3,493.8z"
          />
          <path
            d="M2120.4,486.7c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V486.7z"
          />
          <path
            d="M2126.3,493.7v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2127.4,494.7,2126.7,494.3,2126.3,493.7z M2126.3,491.6c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V491.6z"
          />
          <path
            d="M2137.5,494.6h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V494.6z"
          />
          <path
            d="M2139.7,496.8c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C2141.3,496.2,2140.8,496.8,2139.7,496.8z"
          />
          <path d="M2145.4,485.8h1.2v1.3h-1.2V485.8z M2146.5,494.6h-1.1v-6.4h1.1V494.6z" />
          <path
            d="M2148.4,488.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V488.2z"
          />
          <path
            d="M2157.7,496.9c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2160.6,495.9,2159.4,496.9,2157.7,496.9z M2157.8,489.1c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C2159.5,489.7,2158.7,489.1,2157.8,489.1z"
          />
        </g>
        <g>
          <path d="M2095.4,499.4h1.2v1.3h-1.2V499.4z M2096.6,508.1h-1.1v-6.4h1.1V508.1z" />
          <path
            d="M2098.4,501.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V501.7z"
          />
          <path
            d="M2109.3,506.1c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2109.3,506.1z"
          />
          <path
            d="M2116.1,508.1h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V508.1
				z"
          />
          <path
            d="M2125.1,506.4c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2126.1,508.2,2125.1,507.5,2125.1,506.4z M2129.1,504.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V504.8z"
          />
          <path d="M2133.1,508.1h-1.1v-8.7h1.1V508.1z" />
          <path d="M2136,508.1h-1.1v-8.7h1.1V508.1z" />
          <path
            d="M2137.4,504.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2138.6,508.2,2137.4,506.9,2137.4,504.9z M2138.5,504.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2139.4,502.5,2138.7,503.2,2138.5,504.2z"
          />
          <path
            d="M2147.7,502.8c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V502.8z"
          />
        </g>
        <g>
          <path d="M2100.9,521.6l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H2100.9z" />
          <path
            d="M2107.9,521.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2110.9,520.4,2109.7,521.7,2107.9,521.7z
				 M2107.9,520.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2106.1,519.9,2106.8,520.8,2107.9,520.8z"
          />
          <path d="M2113.4,521.6h-1.1v-8.7h1.1V521.6z" />
          <path
            d="M2120.2,521.6h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V521.6z"
          />
          <path
            d="M2123.2,521.6h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V521.6
				z"
          />
          <path
            d="M2132.2,518.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2133.4,521.7,2132.2,520.4,2132.2,518.4z M2133.3,517.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2134.2,516.1,2133.5,516.7,2133.3,517.8z"
          />
          <path
            d="M2139.4,519.6c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2139.4,519.6z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="76">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3107.1,999c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3086.6,953.2,3107.1,973.7,3107.1,999"
        />
        <g>
          <path d="M3050.3,990.4l-3.1-8.1h1.2l2.5,6.7l2.5-6.7h1.1l-3,8.1H3050.3z" />
          <path
            d="M3055,988.8c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C3055.9,990.5,3055,989.9,3055,988.8z M3058.7,987.4l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V987.4z"
          />
          <path d="M3062.4,990.4h-1v-8.1h1V990.4z" />
          <path
            d="M3068.8,990.4h-1v-0.8c-0.3,0.5-0.9,0.9-1.7,0.9c-1.3,0-2-0.9-2-2.1v-3.9h1v3.7c0,0.8,0.4,1.4,1.2,1.4
				c0.7,0,1.5-0.6,1.5-1.5v-3.5h1V990.4z"
          />
          <path
            d="M3070,987.5c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C3071.2,990.5,3070,989.3,3070,987.5z M3071.1,986.8h3.2c0-0.8-0.5-1.6-1.5-1.6
				C3071.9,985.3,3071.2,985.8,3071.1,986.8z"
          />
        </g>
        <g>
          <path
            d="M3032.3,1001.3c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C3033.1,1003,3032.3,1002.3,3032.3,1001.3z M3035.9,999.9l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V999.9z"
          />
          <path
            d="M3038.8,1001c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L3038.8,1001z"
          />
          <path
            d="M3044.2,1001c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L3044.2,1001z"
          />
          <path d="M3049.5,994.8h1.1v1.2h-1.1V994.8z M3050.6,1002.9h-1V997h1V1002.9z" />
          <path
            d="M3054.5,1005c-1.3,0-2.2-0.6-2.6-1.7l0.9-0.4c0.2,0.8,0.8,1.2,1.6,1.2c1,0,1.7-0.5,1.7-1.8v-0.7c-0.3,0.6-1,1-1.8,1
				c-1.6,0-2.5-1.3-2.5-2.9c0-1.6,0.9-2.9,2.5-2.9c0.8,0,1.5,0.4,1.8,1V997h1v5.3C3057.2,1004.1,3056.1,1005,3054.5,1005z
				 M3054.6,997.8c-1.1,0-1.7,0.8-1.7,2s0.7,2,1.7,2c0.8,0,1.6-0.6,1.6-1.9v-0.2C3056.2,998.4,3055.4,997.8,3054.6,997.8z"
          />
          <path
            d="M3058.9,997h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.5,0.6-1.5,1.5
				v3.5h-1V997z"
          />
          <path
            d="M3064.8,999.9c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C3065.9,1003,3064.8,1001.7,3064.8,999.9z M3065.8,999.3h3.2c0-0.8-0.5-1.6-1.5-1.6
				C3066.7,997.7,3066,998.3,3065.8,999.3z"
          />
          <path
            d="M3070.9,999.9c0-1.7,0.9-3.1,2.6-3.1c0.8,0,1.5,0.4,1.8,1v-3h1v8.1h-1v-0.9c-0.3,0.6-1,1-1.8,1
				C3071.8,1003,3070.9,1001.6,3070.9,999.9z M3073.6,997.8c-1.1,0-1.7,0.9-1.7,2.2c0,1.3,0.6,2.2,1.7,2.2c0.8,0,1.7-0.6,1.7-2v-0.4
				C3075.3,998.4,3074.5,997.8,3073.6,997.8z"
          />
          <path
            d="M3081.7,997.9h-0.9V997h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V997.9z"
          />
          <path
            d="M3087.7,1003c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S3089.3,1003,3087.7,1003z M3087.7,1002.1
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C3085.9,1001.3,3086.6,1002.1,3087.7,1002.1z"
          />
        </g>
        <g>
          <path
            d="M3030.3,1015.5c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S3031.9,1015.5,3030.3,1015.5z M3030.3,1014.6
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C3028.5,1013.7,3029.2,1014.6,3030.3,1014.6z"
          />
          <path
            d="M3037.3,1010.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				V1010.5z"
          />
          <path d="M3038.4,1007.3h1.1v1.2h-1.1V1007.3z M3039.4,1015.3h-1v-5.9h1V1015.3z" />
          <path
            d="M3043.4,1017.5c-1.3,0-2.2-0.6-2.6-1.7l0.9-0.4c0.2,0.8,0.8,1.2,1.6,1.2c1,0,1.7-0.5,1.7-1.8v-0.7c-0.3,0.6-1,1-1.8,1
				c-1.6,0-2.5-1.3-2.5-2.9c0-1.6,0.9-2.9,2.5-2.9c0.8,0,1.5,0.4,1.8,1v-0.9h1v5.3C3046.1,1016.5,3045,1017.5,3043.4,1017.5z
				 M3043.5,1010.2c-1.1,0-1.7,0.8-1.7,2s0.7,2,1.7,2c0.8,0,1.6-0.6,1.6-1.9v-0.2C3045.1,1010.9,3044.3,1010.2,3043.5,1010.2z"
          />
          <path d="M3047.7,1007.3h1.1v1.2h-1.1V1007.3z M3048.8,1015.3h-1v-5.9h1V1015.3z" />
          <path
            d="M3050.5,1009.5h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V1009.5z"
          />
          <path
            d="M3062.7,1015.5c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S3064.3,1015.5,3062.7,1015.5z M3062.7,1014.6
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C3061,1013.7,3061.6,1014.6,3062.7,1014.6z"
          />
          <path
            d="M3069.2,1008.1c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0V1008.1z"
          />
          <path
            d="M3076.3,1008.1c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0V1008.1z"
          />
          <path
            d="M3079.5,1015.5c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S3081.1,1015.5,3079.5,1015.5z M3079.5,1014.6
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C3077.8,1013.7,3078.4,1014.6,3079.5,1014.6z"
          />
          <path
            d="M3085.8,1015.5c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S3087.4,1015.5,3085.8,1015.5z M3085.8,1014.6
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C3084.1,1013.7,3084.8,1014.6,3085.8,1014.6z"
          />
          <path
            d="M3089.4,1012.4c0-1.7,0.9-3.1,2.6-3.1c0.8,0,1.5,0.4,1.8,1v-3h1v8.1h-1v-0.9c-0.3,0.6-1,1-1.8,1
				C3090.3,1015.5,3089.4,1014.1,3089.4,1012.4z M3092.1,1010.2c-1.1,0-1.7,0.9-1.7,2.2c0,1.3,0.6,2.2,1.7,2.2c0.8,0,1.7-0.6,1.7-2
				v-0.4C3093.8,1010.8,3093,1010.2,3092.1,1010.2z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="75">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M2720.6,2369.5c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2700.1,2323.7,2720.6,2344.2,2720.6,2369.5"
        />
        <g>
          <path
            d="M2664.1,2352.1c-0.4,0.8-1.3,1.4-2.5,1.4c-2.2,0-3.9-1.8-3.9-4.5c0-2.7,1.7-4.5,4-4.5c1.7,0,2.9,0.9,3.4,2.4l-1.1,0.4
				c-0.3-1.1-1.1-1.8-2.3-1.8c-1.7,0-2.8,1.4-2.8,3.5c0,2.1,1.1,3.5,2.8,3.5c1.4,0,2.4-1.1,2.4-2.6h-2.5v-1h3.6v4.5h-1.1V2352.1z"
          />
          <path d="M2668,2353.4h-1.1v-8.7h1.1V2353.4z" />
          <path
            d="M2672.4,2353.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2675.3,2352.2,2674.1,2353.5,2672.4,2353.5z
				 M2672.4,2352.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2670.5,2351.7,2671.3,2352.6,2672.4,2352.6
				z"
          />
          <path
            d="M2677.8,2352.5v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2678.9,2353.5,2678.2,2353.1,2677.8,2352.5z M2677.8,2350.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2350.5z"
          />
          <path
            d="M2683.4,2351.7c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2684.4,2353.5,2683.4,2352.8,2683.4,2351.7z M2687.4,2350.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2350.2z"
          />
          <path d="M2691.4,2353.4h-1.1v-8.7h1.1V2353.4z" />
        </g>
        <g>
          <path
            d="M2640.8,2363.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2642,2367.1,2640.8,2365.7,2640.8,2363.8z M2641.9,2363.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2642.8,2361.4,2642.1,2362,2641.9,2363.1z"
          />
          <path
            d="M2647.8,2360.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2360.6z"
          />
          <path
            d="M2657.1,2369.3c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2660,2368.2,2658.8,2369.3,2657.1,2369.3z M2657.2,2361.4c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C2659,2362.1,2658.1,2361.4,2657.2,2361.4z"
          />
          <path
            d="M2661.4,2365.2c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2662.3,2367,2661.4,2366.4,2661.4,2365.2z M2665.3,2363.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2363.7z"
          />
          <path
            d="M2670.7,2369.3c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2673.7,2368.2,2672.4,2369.3,2670.7,2369.3z M2670.8,2361.4c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C2672.6,2362.1,2671.7,2361.4,2670.8,2361.4z"
          />
          <path
            d="M2675,2363.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2676.3,2367.1,2675,2365.7,2675,2363.8z M2676.1,2363.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2677,2361.4,2676.3,2362,2676.1,2363.1z"
          />
          <path
            d="M2683.1,2366.9h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V2366.9z"
          />
          <path
            d="M2692.2,2363.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2693.4,2367.1,2692.2,2365.7,2692.2,2363.8z M2693.3,2363.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2694.2,2361.4,2693.5,2362,2693.3,2363.1z"
          />
          <path
            d="M2699.2,2360.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2360.6z"
          />
          <path
            d="M2706.2,2361.5h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2361.5z"
          />
        </g>
        <g>
          <path d="M2645.7,2371.7h1.2v1.3h-1.2V2371.7z M2646.9,2380.4h-1.1v-6.4h1.1V2380.4z" />
          <path
            d="M2648.7,2374.1h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2374.1z"
          />
          <path
            d="M2662.7,2375.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2375.2z"
          />
          <path
            d="M2663.5,2377.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2664.7,2380.6,2663.5,2379.2,2663.5,2377.3z M2664.6,2376.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2665.5,2374.9,2664.8,2375.5,2664.6,2376.6z"
          />
          <path
            d="M2670,2378.7c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2670.9,2380.6,2670,2379.9,2670,2378.7z M2674,2377.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V2377.2z"
          />
          <path d="M2678,2380.4h-1.1v-8.7h1.1V2380.4z" />
          <path d="M2679.8,2371.7h1.2v1.3h-1.2V2371.7z M2680.9,2380.4h-1.1v-6.4h1.1V2380.4z" />
          <path
            d="M2682.9,2378.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2682.9,2378.5z"
          />
          <path d="M2688.6,2371.7h1.2v1.3h-1.2V2371.7z M2689.7,2380.4h-1.1v-6.4h1.1V2380.4z" />
          <path
            d="M2691.6,2374.1h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2374.1z"
          />
          <path
            d="M2700.9,2382.8c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2703.8,2381.7,2702.6,2382.8,2700.9,2382.8z M2701,2374.9c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C2702.7,2375.6,2701.9,2374.9,2701,2374.9z"
          />
        </g>
        <g>
          <path
            d="M2660.6,2391.4c0.5,1,1.5,1.7,2.7,1.7s2-0.6,2-1.6c0-1.1-1-1.2-2.3-1.6c-1.4-0.3-2.7-0.7-2.7-2.3c0-1.5,1.3-2.5,3-2.5
				c1.5,0,2.6,0.7,3.1,1.7l-0.9,0.7c-0.5-0.8-1.1-1.4-2.3-1.4c-1.1,0-1.8,0.6-1.8,1.4c0,0.9,0.8,1.1,2,1.4c1.4,0.3,3,0.6,3,2.5
				c0,1.6-1.4,2.7-3.2,2.7c-1.5,0-3-0.8-3.6-1.9L2660.6,2391.4z"
          />
          <path
            d="M2667.7,2385.2h2.8c2.7,0,4.3,1.7,4.3,4.4c0,2.7-1.7,4.4-4.3,4.4h-2.8V2385.2z M2670.5,2392.9c2,0,3.2-1.3,3.2-3.4
				c0-2-1.2-3.4-3.2-3.4h-1.6v6.7H2670.5z"
          />
          <path
            d="M2682.2,2392.7c-0.4,0.8-1.3,1.4-2.5,1.4c-2.2,0-3.9-1.8-3.9-4.5c0-2.7,1.7-4.5,4-4.5c1.7,0,2.9,0.9,3.4,2.4l-1.1,0.4
				c-0.3-1.1-1.1-1.8-2.3-1.8c-1.7,0-2.8,1.4-2.8,3.5c0,2.1,1.1,3.5,2.8,3.5c1.4,0,2.4-1.1,2.4-2.6h-2.5v-1h3.6v4.5h-1.1V2392.7z"
          />
          <path
            d="M2685.1,2392c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2685.1,2392z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="74">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M1528,2231.3c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1507.5,2185.5,1528,2206,1528,2231.3"
        />
        <g>
          <path d="M1463.1,2220.9h1.1v7.1h4v0.9h-5.1V2220.9z" />
          <path
            d="M1468.8,2226c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C1469.9,2229.1,1468.8,2227.8,1468.8,2226z M1469.8,2225.4h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1470.7,2223.8,1470,2224.4,1469.8,2225.4z"
          />
          <path d="M1476.8,2229l-2.2-5.9h1.1l1.7,4.6l1.6-4.6h1.1l-2.2,5.9H1476.8z" />
          <path
            d="M1480.6,2226c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C1481.7,2229.1,1480.6,2227.8,1480.6,2226z M1481.6,2225.4h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1482.4,2223.8,1481.8,2224.4,1481.6,2225.4z"
          />
          <path d="M1488.1,2229h-1v-8.1h1V2229z" />
          <path
            d="M1495.6,2229.1c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1
				C1498.4,2227.8,1497.2,2229.1,1495.6,2229.1z M1495.6,2228.2c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2
				C1493.9,2227.4,1494.6,2228.2,1495.6,2228.2z"
          />
          <path
            d="M1502.2,2221.7c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0V2221.7z"
          />
        </g>
        <g>
          <path
            d="M1439.4,2233.4h1v3c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V2233.4z"
          />
          <path
            d="M1447.8,2241.6c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1
				C1450.5,2240.3,1449.4,2241.6,1447.8,2241.6z M1447.8,2240.7c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2
				C1446.1,2239.8,1446.8,2240.7,1447.8,2240.7z"
          />
          <path
            d="M1452.6,2241.4h-1v-5.9h1v0.8c0.3-0.5,0.9-0.9,1.7-0.9c0.8,0,1.3,0.4,1.6,1c0.3-0.5,1-1,1.9-1c1.2,0,1.9,0.9,1.9,2.1v3.9
				h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5v3.5h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5V2241.4z"
          />
          <path
            d="M1460.7,2238.5c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1
				l0.9,0.3c-0.4,1-1.2,1.7-2.4,1.7C1461.8,2241.6,1460.7,2240.3,1460.7,2238.5z M1461.7,2237.9h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1462.6,2236.3,1461.9,2236.9,1461.7,2237.9z"
          />
          <path
            d="M1471.5,2240.7c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C1469.7,2239.8,1470.4,2240.7,1471.5,2240.7z"
          />
          <path
            d="M1477,2241.6c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C1479.8,2240.3,1478.7,2241.6,1477,2241.6z
				 M1477,2240.7c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1475.3,2239.8,1476,2240.7,1477,2240.7z"
          />
          <path
            d="M1481.8,2241.4h-1v-5.9h1v0.8c0.3-0.5,0.9-0.9,1.7-0.9c0.8,0,1.3,0.4,1.6,1c0.3-0.5,1-1,1.9-1c1.2,0,1.9,0.9,1.9,2.1v3.9
				h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5v3.5h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5V2241.4z"
          />
          <path
            d="M1491.4,2240.6v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C1492.4,2241.6,1491.7,2241.2,1491.4,2240.6z M1491.4,2238.7c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V2238.7z"
          />
          <path
            d="M1499.2,2241.6c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1
				C1501.9,2240.3,1500.8,2241.6,1499.2,2241.6z M1499.2,2240.7c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2
				C1497.4,2239.8,1498.1,2240.7,1499.2,2240.7z"
          />
          <path
            d="M1503.1,2239.6c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1503.1,2239.6z"
          />
          <path
            d="M1508.3,2236.4h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V2236.4z"
          />
          <path d="M1511.7,2233.4h1.1v1.2h-1.1V2233.4z M1512.8,2241.4h-1v-5.9h1V2241.4z" />
          <path
            d="M1514.2,2235.6h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V2235.6z"
          />
          <path
            d="M1522.6,2243.6c-1.3,0-2.2-0.6-2.6-1.7l0.9-0.4c0.2,0.8,0.8,1.2,1.6,1.2c1,0,1.7-0.5,1.7-1.8v-0.7c-0.3,0.6-1,1-1.8,1
				c-1.6,0-2.5-1.3-2.5-2.9c0-1.6,0.9-2.9,2.5-2.9c0.8,0,1.5,0.4,1.8,1v-0.9h1v5.3C1525.3,2242.6,1524.2,2243.6,1522.6,2243.6z
				 M1522.7,2236.3c-1.1,0-1.7,0.8-1.7,2c0,1.2,0.7,2,1.7,2c0.8,0,1.6-0.6,1.6-1.9v-0.2C1524.3,2237,1523.5,2236.3,1522.7,2236.3z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="73">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2977.7,1319c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2957.2,1273.3,2977.7,1293.8,2977.7,1319"
        />
        <g>
          <path d="M2920.1,1294.3v8.7h-1.2v-4h-4.7v4h-1.2v-8.7h1.2v3.7h4.7v-3.7H2920.1z" />
          <path
            d="M2924.5,1303.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2927.4,1301.8,2926.2,1303.1,2924.5,1303.1z
				 M2924.5,1302.2c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2922.6,1301.3,2923.4,1302.2,2924.5,1302.2
				z"
          />
          <path
            d="M2932.1,1297.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1297.7z"
          />
          <path
            d="M2932.9,1299.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2934.1,1303.1,2932.9,1301.8,2932.9,1299.8z M2934,1299.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2934.9,1297.4,2934.2,1298.1,2934,1299.1z"
          />
          <path
            d="M2942.5,1302.2c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2940.6,1301.3,2941.3,1302.2,2942.5,1302.2z"
          />
          <path
            d="M2945.7,1301.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2946.7,1303.1,2945.7,1302.4,2945.7,1301.3z M2949.7,1299.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1299.7z"
          />
        </g>
        <g>
          <path
            d="M2914.1,1316.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2917.1,1315.3,2915.9,1316.6,2914.1,1316.6z
				 M2914.1,1315.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2912.3,1314.8,2913,1315.7,2914.1,1315.7z"
          />
          <path
            d="M2921.3,1308.6c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1308.6z"
          />
          <path
            d="M2925,1308.6c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1308.6z"
          />
          <path
            d="M2925.5,1313.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2926.7,1316.6,2925.5,1315.3,2925.5,1313.3z M2926.6,1312.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2927.5,1311,2926.8,1311.6,2926.6,1312.7z"
          />
          <path
            d="M2935.8,1311.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1311.2z"
          />
          <path d="M2937,1307.8h1.2v1.3h-1.2V1307.8z M2938.1,1316.5h-1.1v-6.4h1.1V1316.5z" />
          <path
            d="M2939.9,1310.1h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1310.1z"
          />
          <path
            d="M2949.2,1318.8c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2952.2,1317.8,2951,1318.8,2949.2,1318.8z M2949.4,1311c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C2951.1,1311.7,2950.3,1311,2949.4,1311z"
          />
        </g>
        <g>
          <path
            d="M2901.9,1328c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2901.9,1328z"
          />
          <path
            d="M2912.7,1330h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1330z"
          />
          <path
            d="M2914.7,1328c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2914.7,1328z"
          />
          <path
            d="M2920.6,1324.6h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1324.6z"
          />
          <path
            d="M2924.1,1328.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2925,1330.1,2924.1,1329.4,2924.1,1328.3z M2928,1326.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1326.8z"
          />
          <path d="M2930.9,1321.3h1.2v1.3h-1.2V1321.3z M2932.1,1330h-1.1v-6.4h1.1V1330z" />
          <path
            d="M2933.9,1323.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1323.7z"
          />
          <path
            d="M2940.3,1328.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2941.2,1330.1,2940.3,1329.4,2940.3,1328.3z M2944.2,1326.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1326.8z"
          />
          <path
            d="M2948.3,1329.1v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2949.3,1330.1,2948.6,1329.7,2948.3,1329.1z M2948.2,1327.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1327.1z"
          />
          <path d="M2955.5,1330h-1.1v-8.7h1.1V1330z" />
          <path
            d="M2956.9,1326.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2958.1,1330.1,2956.9,1328.8,2956.9,1326.8z M2958,1326.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2958.9,1324.5,2958.2,1325.1,2958,1326.2z"
          />
        </g>
        <g>
          <path
            d="M2923.3,1335.7c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1335.7z"
          />
          <path
            d="M2926.7,1343.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2929.7,1342.3,2928.5,1343.7,2926.7,1343.7z
				 M2926.7,1342.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2924.9,1341.8,2925.6,1342.7,2926.7,1342.7
				z"
          />
          <path
            d="M2933.6,1343.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2936.6,1342.3,2935.3,1343.7,2933.6,1343.7z
				 M2933.6,1342.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2931.8,1341.8,2932.5,1342.7,2933.6,1342.7
				z"
          />
          <path
            d="M2937.5,1340.4c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2938.5,1343.7,2937.5,1342.2,2937.5,1340.4z M2940.5,1338c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C2942.3,1338.7,2941.4,1338,2940.5,1338z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="72">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M640.9,2118.7c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C620.4,2073,640.9,2093.5,640.9,2118.7"
        />
        <g>
          <path d="M568,2095.9l3.1,8.1H570l-0.8-2.1h-3.6l-0.8,2.1h-1.1l3.1-8.1H568z M565.9,2100.9h2.9l-1.5-3.8L565.9,2100.9z" />
          <path
            d="M574.5,2103.2c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C572.7,2102.3,573.4,2103.2,574.5,2103.2z"
          />
          <path
            d="M580.3,2103.2c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C578.5,2102.3,579.3,2103.2,580.3,2103.2z"
          />
          <path
            d="M583.4,2101c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C584.5,2104,583.4,2102.8,583.4,2101z M584.4,2100.4h3.2c0-0.8-0.5-1.6-1.5-1.6
				C585.2,2098.8,584.6,2099.4,584.4,2100.4z"
          />
          <path
            d="M590,2102.1c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L590,2102.1z"
          />
          <path
            d="M595.4,2102.1c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L595.4,2102.1z"
          />
          <path d="M600.7,2095.9h1.1v1.2h-1.1V2095.9z M601.8,2103.9h-1v-5.9h1V2103.9z" />
          <path
            d="M604.4,2103.1v0.9h-1v-8.1h1v3c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C605.4,2104,604.8,2103.7,604.4,2103.1z M604.4,2101.2c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V2101.2z"
          />
          <path d="M610.1,2095.9h1.1v1.2h-1.1V2095.9z M611.1,2103.9h-1v-5.9h1V2103.9z" />
          <path d="M613.8,2103.9h-1v-8.1h1V2103.9z" />
          <path d="M615.5,2095.9h1.1v1.2h-1.1V2095.9z M616.5,2103.9h-1v-5.9h1V2103.9z" />
          <path
            d="M618.3,2098.9h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L618.3,2098.9L618.3,2098.9z"
          />
          <path
            d="M622.4,2106c-0.2,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,0.9-0.7l0.3-0.7l-2.2-5.7h1.1l1.7,4.5l1.6-4.5
				h1.1l-2.5,6.5C623.9,2105.5,623.4,2106,622.4,2106z"
          />
        </g>
        <g>
          <path
            d="M559.4,2116.5c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C562.1,2115.3,561,2116.5,559.4,2116.5z
				 M559.4,2115.7c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C557.6,2114.8,558.3,2115.7,559.4,2115.7z"
          />
          <path
            d="M565.9,2109.1c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0L565.9,2109.1L565.9,2109.1z"
          />
          <path
            d="M570.5,2111.4h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L570.5,2111.4L570.5,2111.4z"
          />
          <path
            d="M573.8,2113.5c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C574.9,2116.5,573.8,2115.3,573.8,2113.5z M574.8,2112.9h3.2c0-0.8-0.5-1.6-1.5-1.6
				C575.6,2111.3,575,2111.9,574.8,2112.9z"
          />
          <path
            d="M582.6,2115.6c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C580.9,2114.8,581.6,2115.6,582.6,2115.6z"
          />
          <path
            d="M586.1,2108.3h1v3c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.5,0.6-1.5,1.5
				v3.5h-1L586.1,2108.3L586.1,2108.3z"
          />
          <path
            d="M592.4,2110.5h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L592.4,2110.5L592.4,2110.5z"
          />
          <path
            d="M601.1,2116.5c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C603.8,2115.3,602.7,2116.5,601.1,2116.5z
				 M601.1,2115.7c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C599.4,2114.8,600,2115.7,601.1,2115.7z"
          />
          <path d="M606.1,2116.4h-1v-8.1h1V2116.4z" />
          <path
            d="M610.1,2116.5c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C612.8,2115.3,611.7,2116.5,610.1,2116.5z
				 M610.1,2115.7c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C608.4,2114.8,609.1,2115.7,610.1,2115.7z"
          />
          <path
            d="M616.4,2118.5c-1.3,0-2.2-0.6-2.6-1.7l0.9-0.4c0.2,0.8,0.8,1.2,1.6,1.2c1,0,1.7-0.5,1.7-1.8v-0.7c-0.3,0.6-1,1-1.8,1
				c-1.6,0-2.5-1.3-2.5-2.9c0-1.6,0.9-2.9,2.5-2.9c0.8,0,1.5,0.4,1.8,1v-0.9h1v5.3C619.1,2117.6,618,2118.5,616.4,2118.5z
				 M616.5,2111.3c-1.1,0-1.7,0.8-1.7,2c0,1.2,0.7,2,1.7,2c0.8,0,1.6-0.6,1.6-1.9v-0.2C618.1,2111.9,617.3,2111.3,616.5,2111.3z"
          />
          <path d="M620.7,2108.3h1.1v1.2h-1.1V2108.3z M621.8,2116.4h-1v-5.9h1V2116.4z" />
          <path
            d="M623.1,2113.5c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3H624c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C624.2,2116.5,623.1,2115.3,623.1,2113.5z M624.1,2112.9h3.2c0-0.8-0.5-1.6-1.5-1.6
				C624.9,2111.3,624.3,2111.9,624.1,2112.9z"
          />
          <path
            d="M629.7,2114.6c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L629.7,2114.6z"
          />
        </g>
        <g>
          <path
            d="M561.2,2121.6c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0L561.2,2121.6L561.2,2121.6z"
          />
          <path
            d="M564.4,2129c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C567.1,2127.8,566,2129,564.4,2129z
				 M564.4,2128.1c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C562.7,2127.3,563.3,2128.1,564.4,2128.1z"
          />
          <path
            d="M571.4,2124c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L571.4,2124L571.4,2124z"
          />
          <path
            d="M576.2,2127.1c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L576.2,2127.1z"
          />
          <path
            d="M586.2,2128.9h-1v-0.8c-0.3,0.5-0.9,0.9-1.7,0.9c-1.3,0-2-0.9-2-2.1v-3.9h1v3.7c0,0.8,0.4,1.4,1.2,1.4
				c0.7,0,1.5-0.5,1.5-1.5v-3.5h1V2128.9z"
          />
          <path
            d="M588,2127.1c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L588,2127.1z"
          />
          <path
            d="M593.5,2123.9h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L593.5,2123.9L593.5,2123.9z"
          />
          <path
            d="M596.7,2127.3c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C597.6,2129,596.7,2128.3,596.7,2127.3z M600.3,2125.9l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L600.3,2125.9L600.3,2125.9z"
          />
          <path d="M603,2120.8h1.1v1.2H603V2120.8z M604.1,2128.9h-1v-5.9h1V2128.9z" />
          <path
            d="M605.8,2123h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.5,0.6-1.5,1.5
				v3.5h-1L605.8,2123L605.8,2123z"
          />
          <path
            d="M611.6,2127.3c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C612.5,2129,611.6,2128.3,611.6,2127.3z M615.3,2125.9l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L615.3,2125.9L615.3,2125.9z"
          />
          <path
            d="M619,2128v0.9h-1v-8.1h1v3c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C620,2129,619.4,2128.6,619,2128z M619,2126.2c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V2126.2z"
          />
          <path d="M625.7,2128.9h-1v-8.1h1V2128.9z" />
          <path
            d="M627,2125.9c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C628.1,2129,627,2127.8,627,2125.9z M628,2125.3h3.2c0-0.8-0.5-1.6-1.5-1.6
				C628.8,2123.8,628.2,2124.3,628,2125.3z"
          />
        </g>
        <g>
          <path
            d="M579.1,2134.1c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0L579.1,2134.1L579.1,2134.1z"
          />
          <path
            d="M579.5,2139.8c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C580.4,2141.5,579.5,2140.8,579.5,2139.8z M583.2,2138.3l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L583.2,2138.3L583.2,2138.3z"
          />
          <path
            d="M588.9,2136.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L588.9,2136.5L588.9,2136.5z"
          />
          <path
            d="M591,2141.4h-1v-5.9h1v0.8c0.3-0.5,0.9-0.9,1.7-0.9c0.8,0,1.3,0.4,1.6,1c0.3-0.5,1-1,1.9-1c1.2,0,1.9,0.9,1.9,2.1v3.9h-1
				v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5v3.5h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5V2141.4z"
          />
          <path d="M599.8,2133.3h1.1v1.2h-1.1V2133.3z M600.8,2141.4h-1v-5.9h1V2141.4z" />
          <path
            d="M602.5,2135.5h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L602.5,2135.5L602.5,2135.5z"
          />
          <path
            d="M611.1,2143.5c-1.3,0-2.2-0.6-2.6-1.7l0.9-0.4c0.2,0.8,0.8,1.2,1.6,1.2c1,0,1.7-0.5,1.7-1.8v-0.7c-0.3,0.6-1,1-1.8,1
				c-1.6,0-2.5-1.3-2.5-2.9c0-1.6,0.9-2.9,2.5-2.9c0.8,0,1.5,0.4,1.8,1v-0.9h1v5.3C613.8,2142.5,612.7,2143.5,611.1,2143.5z
				 M611.2,2136.3c-1.1,0-1.7,0.8-1.7,2c0,1.2,0.7,2,1.7,2c0.8,0,1.6-0.6,1.6-1.9v-0.2C612.8,2136.9,612,2136.3,611.2,2136.3z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="71">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M3229.4,2136.2c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3208.9,2090.4,3229.4,2110.9,3229.4,2136.2"
        />
        <g>
          <path d="M3156.2,2118.2v1h-4.5v2.7h3.9v1h-3.9v3h4.5v1h-5.7v-8.7H3156.2z" />
          <path d="M3159.9,2124.4l-1.8,2.5h-1.2l2.3-3.3l-2.1-3h1.3l1.5,2.2l1.6-2.2h1.2l-2.1,3l2.3,3.3h-1.2L3159.9,2124.4z" />
          <path
            d="M3165,2126v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3166.1,2127,3165.4,2126.6,3165,2126z M3165,2124c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2124z"
          />
          <path
            d="M3170.7,2123.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3171.9,2127,3170.7,2125.7,3170.7,2123.7z M3171.8,2123.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3172.7,2121.4,3172,2122,3171.8,2123.1z"
          />
          <path
            d="M3181,2121.6c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2121.6z"
          />
          <path d="M3182.2,2118.2h1.2v1.3h-1.2V2118.2z M3183.3,2126.9h-1.1v-6.4h1.1V2126.9z" />
          <path
            d="M3184.7,2123.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3185.9,2127,3184.7,2125.7,3184.7,2123.7z M3185.8,2123.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3186.7,2121.4,3186,2122,3185.8,2123.1z"
          />
          <path
            d="M3191.7,2120.5h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2120.5z"
          />
          <path
            d="M3201.1,2126.1c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3199.2,2125.2,3200,2126.1,3201.1,2126.1z"
          />
          <path
            d="M3204.4,2123.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3205.7,2127,3204.4,2125.7,3204.4,2123.7z M3205.6,2123.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3206.4,2121.4,3205.7,2122,3205.6,2123.1z"
          />
          <path
            d="M3211,2123.7c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3212,2127,3211,2125.5,3211,2123.7z M3214,2121.4c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.4
				C3215.8,2122,3214.9,2121.4,3214,2121.4z"
          />
        </g>
        <g>
          <path d="M3141,2131.7h1.2v1.3h-1.2V2131.7z M3142.2,2140.4h-1.1v-6.4h1.1V2140.4z" />
          <path
            d="M3144.7,2140.4h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V2140.4z"
          />
          <path
            d="M3155,2139.5v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3156,2140.5,3155.3,2140.1,3155,2139.5z M3154.9,2137.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2137.5z"
          />
          <path
            d="M3160.2,2138.7c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3161.2,2140.5,3160.2,2139.8,3160.2,2138.7z M3164.2,2137.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2137.2z"
          />
          <path
            d="M3169.3,2139.6c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3167.4,2138.7,3168.2,2139.6,3169.3,2139.6z"
          />
          <path
            d="M3172.9,2135h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2135z"
          />
          <path
            d="M3181.2,2140.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3184.2,2139.2,3183,2140.5,3181.2,2140.5z
				 M3181.2,2139.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3179.4,2138.7,3180.1,2139.6,3181.2,2139.6
				z"
          />
          <path
            d="M3188,2132.5c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2132.5z"
          />
          <path
            d="M3193.3,2139.6c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3191.4,2138.7,3192.2,2139.6,3193.3,2139.6z"
          />
          <path d="M3197.8,2140.4h-1.1v-8.7h1.1V2140.4z" />
          <path d="M3199.2,2131.7h1.2v1.3h-1.2V2131.7z M3200.4,2140.4h-1.1v-6.4h1.1V2140.4z" />
          <path
            d="M3202.9,2140.4h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V2140.4z"
          />
          <path
            d="M3211.6,2138.7c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3212.5,2140.5,3211.6,2139.8,3211.6,2138.7z M3215.5,2137.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2137.2z"
          />
          <path
            d="M3218.3,2135h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2135z"
          />
          <path
            d="M3221.4,2137.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3222.6,2140.5,3221.4,2139.2,3221.4,2137.2z M3222.5,2136.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3223.4,2134.9,3222.7,2135.5,3222.5,2136.6z"
          />
        </g>
        <g>
          <path
            d="M3167,2153.1c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3165.1,2152.2,3165.9,2153.1,3167,2153.1z"
          />
          <path
            d="M3170.8,2145.2h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2145.2z"
          />
          <path
            d="M3177.2,2152.2c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3178.1,2154,3177.2,2153.4,3177.2,2152.2z M3181.1,2150.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2150.7z"
          />
          <path
            d="M3184.1,2147.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2147.6z"
          />
          <path
            d="M3193.3,2156.3c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3196.3,2155.2,3195.1,2156.3,3193.3,2156.3z M3193.5,2148.4c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C3195.2,2149.1,3194.4,2148.4,3193.5,2148.4z"
          />
          <path
            d="M3197.7,2150.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3198.9,2154.1,3197.7,2152.7,3197.7,2150.8z M3198.8,2150.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3199.7,2148.4,3199,2149,3198.8,2150.1z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="70">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M3696.7,1797.1c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3676.2,1751.3,3696.7,1771.8,3696.7,1797.1"
        />
        <g>
          <path d="M3630.2,1779h1.2v7.7h4.4v1h-5.5V1779z" />
          <path
            d="M3636.4,1784.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3637.6,1787.9,3636.4,1786.6,3636.4,1784.6z M3637.5,1783.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3638.4,1782.2,3637.7,1782.8,3637.5,1783.9z"
          />
          <path d="M3645.1,1787.8l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H3645.1z" />
          <path
            d="M3649.2,1784.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3650.4,1787.9,3649.2,1786.6,3649.2,1784.6z M3650.3,1783.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3651.2,1782.2,3650.5,1782.8,3650.3,1783.9z"
          />
          <path d="M3657.3,1787.8h-1.1v-8.7h1.1V1787.8z" />
          <path
            d="M3665.5,1787.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3668.4,1786.6,3667.2,1787.9,3665.5,1787.9z
				 M3665.5,1787c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3663.6,1786,3664.3,1787,3665.5,1787z"
          />
          <path
            d="M3672.6,1779.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1779.9z"
          />
        </g>
        <g>
          <path
            d="M3616.2,1799.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3616.2,1799.3z"
          />
          <path
            d="M3627,1801.3h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1801.3z"
          />
          <path
            d="M3628.9,1799.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3628.9,1799.3z"
          />
          <path
            d="M3634.9,1795.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1795.9z"
          />
          <path
            d="M3638.3,1799.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3639.3,1801.4,3638.3,1800.7,3638.3,1799.6z M3642.3,1798l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1798z"
          />
          <path d="M3645.2,1792.6h1.2v1.3h-1.2V1792.6z M3646.3,1801.3h-1.1v-6.4h1.1V1801.3z" />
          <path
            d="M3648.1,1794.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1794.9z"
          />
          <path
            d="M3654.5,1799.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3655.4,1801.4,3654.5,1800.7,3654.5,1799.6z M3658.5,1798l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1798z"
          />
          <path
            d="M3662.5,1800.4v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3663.6,1801.4,3662.9,1801,3662.5,1800.4z M3662.5,1798.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1798.3z"
          />
          <path d="M3668.6,1792.6h1.2v1.3h-1.2V1792.6z M3669.7,1801.3h-1.1v-6.4h1.1V1801.3z" />
          <path d="M3672.6,1801.3h-1.1v-8.7h1.1V1801.3z" />
          <path d="M3674.4,1792.6h1.2v1.3h-1.2V1792.6z M3675.6,1801.3h-1.1v-6.4h1.1V1801.3z" />
          <path
            d="M3677.6,1795.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1795.9z"
          />
          <path
            d="M3681.9,1803.6c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C3683.5,1802.9,3683,1803.6,3681.9,1803.6z"
          />
        </g>
        <g>
          <path
            d="M3622.1,1813.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3623.1,1814.9,3622.1,1814.2,3622.1,1813.1z M3626.1,1811.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1811.5z"
          />
          <path d="M3633,1808.4l1.3,4.7l1.3-4.7h1.1l-1.9,6.4h-1l-1.3-4.7l-1.3,4.7h-1l-1.9-6.4h1.1l1.3,4.7l1.3-4.7H3633z" />
          <path
            d="M3637.3,1813.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3638.2,1814.9,3637.3,1814.2,3637.3,1813.1z M3641.2,1811.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1811.5z"
          />
          <path d="M3645.3,1811.4l2.8-3h1.4l-2.5,2.5l2.7,3.9h-1.3l-2.2-3.1l-0.9,0.9v2.1h-1.1v-8.7h1.1V1811.4z" />
          <path
            d="M3650.2,1811.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3651.4,1814.9,3650.2,1813.6,3650.2,1811.6z M3651.3,1811h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3652.2,1809.3,3651.5,1809.9,3651.3,1811z"
          />
          <path
            d="M3657.2,1808.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1808.4z"
          />
          <path d="M3664,1806.1h1.2v1.3h-1.2V1806.1z M3665.2,1814.8h-1.1v-6.4h1.1V1814.8z" />
          <path
            d="M3667,1808.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1808.4z"
          />
          <path
            d="M3676.3,1817.1c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3679.3,1816.1,3678,1817.1,3676.3,1817.1z M3676.4,1809.3c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C3678.2,1810,3677.3,1809.3,3676.4,1809.3z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="69">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M2391.2,2433.6c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2370.8,2387.8,2391.2,2408.3,2391.2,2433.6"
        />
        <g>
          <path
            d="M2315,2424.3h-1.2v-8.7h3.3c1.8,0,3,0.9,3,2.5c0,1.6-1.2,2.5-3,2.5h-2.2V2424.3z M2317.1,2419.6c1.2,0,1.9-0.5,1.9-1.5
				s-0.7-1.5-1.9-1.5h-2.1v3.1H2317.1z"
          />
          <path
            d="M2324.6,2419c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2419z"
          />
          <path
            d="M2325.4,2421.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2326.6,2424.4,2325.4,2423.1,2325.4,2421.1z M2326.5,2420.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2327.4,2418.7,2326.7,2419.3,2326.5,2420.4z"
          />
          <path
            d="M2332.5,2422.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2332.5,2422.3z"
          />
          <path
            d="M2337.8,2421.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2339.1,2424.4,2337.8,2423.1,2337.8,2421.1z M2339,2420.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2339.8,2418.7,2339.1,2419.3,2339,2420.4z"
          />
          <path
            d="M2344.9,2417.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2417.9z"
          />
          <path
            d="M2354.3,2423.4c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2352.4,2422.5,2353.1,2423.4,2354.3,2423.4z"
          />
          <path
            d="M2357.6,2421.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2358.8,2424.4,2357.6,2423.1,2357.6,2421.1z M2358.7,2420.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2359.6,2418.7,2358.9,2419.3,2358.7,2420.4z"
          />
          <path
            d="M2371,2424.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2373.9,2423.1,2372.7,2424.4,2371,2424.4z M2371,2423.5
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2369.1,2422.5,2369.9,2423.5,2371,2423.5z"
          />
          <path
            d="M2378.1,2416.4c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2416.4z"
          />
        </g>
        <g>
          <path d="M2308.7,2431.4l1.3,4.7l1.3-4.7h1.1l-1.9,6.4h-1l-1.3-4.7l-1.3,4.7h-1l-1.9-6.4h1.1l1.3,4.7l1.3-4.7H2308.7z" />
          <path
            d="M2313,2436.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2313.9,2437.9,2313,2437.2,2313,2436.1z M2317,2434.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V2434.5z"
          />
          <path
            d="M2320.1,2435.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2320.1,2435.8z"
          />
          <path
            d="M2326,2432.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2432.4z"
          />
          <path
            d="M2329.5,2434.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2330.7,2437.9,2329.5,2436.6,2329.5,2434.6z M2330.6,2433.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2331.5,2432.2,2330.8,2432.9,2330.6,2433.9z"
          />
          <path
            d="M2343.7,2432.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2432.5z"
          />
          <path
            d="M2344.4,2434.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2345.7,2437.9,2344.4,2436.6,2344.4,2434.6z M2345.6,2433.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2346.4,2432.2,2345.7,2432.9,2345.6,2433.9z"
          />
          <path
            d="M2354,2437c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2352.1,2436.1,2352.9,2437,2354,2437z"
          />
          <path
            d="M2360.3,2437.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2363.2,2436.6,2362,2437.9,2360.3,2437.9z
				 M2360.3,2437c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2358.4,2436.1,2359.2,2437,2360.3,2437z"
          />
          <path d="M2366.3,2437.8l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H2366.3z" />
          <path
            d="M2370.4,2434.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2371.6,2437.9,2370.4,2436.6,2370.4,2434.6z M2371.5,2433.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2372.4,2432.2,2371.7,2432.9,2371.5,2433.9z"
          />
          <path
            d="M2380.7,2432.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2432.5z"
          />
          <path
            d="M2382.3,2440c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C2383.9,2439.4,2383.4,2440,2382.3,2440z"
          />
        </g>
        <g>
          <path d="M2309.2,2442.6h1.2v1.3h-1.2V2442.6z M2310.4,2451.3h-1.1v-6.4h1.1V2451.3z" />
          <path
            d="M2312.2,2444.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2444.9z"
          />
          <path
            d="M2321.8,2443.4c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2443.4z"
          />
          <path
            d="M2326.1,2446c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2446z"
          />
          <path
            d="M2326.8,2449.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2327.7,2451.4,2326.8,2450.7,2326.8,2449.6z M2330.8,2448l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2448z"
          />
          <path
            d="M2333.8,2449.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2333.8,2449.3z"
          />
          <path
            d="M2339.8,2445.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2445.9z"
          />
          <path
            d="M2347,2446c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1c0.2,0,0.3,0,0.4,0
				V2446z"
          />
          <path
            d="M2353.3,2451.3h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2451.3z"
          />
          <path
            d="M2357.7,2450.5c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2355.8,2449.6,2356.5,2450.5,2357.7,2450.5z"
          />
          <path
            d="M2361.6,2445.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2445.9z"
          />
          <path
            d="M2370.6,2451.3h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2451.3z"
          />
          <path
            d="M2375.7,2446c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2446z"
          />
          <path
            d="M2376.5,2448.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2377.7,2451.4,2376.5,2450.1,2376.5,2448.1z M2377.6,2447.5h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2378.5,2445.8,2377.8,2446.4,2377.6,2447.5z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="68">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M1800.5,1506.2c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1780,1460.5,1800.5,1481,1800.5,1506.2"
        />
        <g>
          <path
            d="M1726,1501.6c0.5,1,1.4,1.5,2.5,1.5s1.9-0.5,1.9-1.4c0-1-0.9-1.1-2.2-1.4c-1.3-0.3-2.5-0.7-2.5-2.2
				c0-1.4,1.2-2.3,2.8-2.3c1.4,0,2.4,0.7,2.9,1.6l-0.8,0.7c-0.4-0.8-1.1-1.3-2.1-1.3c-1,0-1.7,0.5-1.7,1.3c0,0.9,0.8,1,1.9,1.3
				c1.3,0.3,2.8,0.6,2.8,2.3c0,1.5-1.3,2.5-2.9,2.5c-1.4,0-2.7-0.7-3.3-1.8L1726,1501.6z"
          />
          <path
            d="M1733.5,1503v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C1734.5,1504,1733.9,1503.6,1733.5,1503z M1733.5,1501.2c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1501.2z"
          />
          <path
            d="M1738.8,1501c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C1739.9,1504,1738.8,1502.8,1738.8,1501z M1739.8,1500.4h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1740.6,1498.8,1740,1499.4,1739.8,1500.4z"
          />
          <path
            d="M1747.6,1503.1c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C1745.9,1502.3,1746.6,1503.1,1747.6,1503.1z"
          />
          <path
            d="M1755.8,1503.9h-1v-0.8c-0.3,0.5-0.9,0.9-1.7,0.9c-1.3,0-2-0.9-2-2.1v-3.9h1v3.7c0,0.8,0.4,1.4,1.2,1.4
				c0.7,0,1.5-0.5,1.5-1.5v-3.5h1V1503.9z"
          />
          <path d="M1758.4,1503.9h-1v-8.1h1V1503.9z" />
          <path
            d="M1759.7,1502.3c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C1760.5,1504,1759.7,1503.4,1759.7,1502.3z M1763.3,1500.9l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V1500.9z"
          />
          <path
            d="M1766.2,1498.9h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V1498.9z"
          />
          <path d="M1769.8,1495.8h1.1v1.2h-1.1V1495.8z M1770.9,1503.9h-1v-5.9h1V1503.9z" />
          <path
            d="M1774.9,1504c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C1777.6,1502.8,1776.5,1504,1774.9,1504z
				 M1774.9,1503.2c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1773.2,1502.3,1773.8,1503.2,1774.9,1503.2z"
          />
          <path
            d="M1778.9,1498h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V1498z"
          />
        </g>
        <g>
          <path
            d="M1739,1516.5c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C1741.7,1515.3,1740.6,1516.5,1739,1516.5z
				 M1739,1515.6c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1737.3,1514.8,1738,1515.6,1739,1515.6z"
          />
          <path
            d="M1743,1510.5h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V1510.5z"
          />
          <path d="M1753.9,1516.4h-1v-8.1h1V1516.4z" />
          <path
            d="M1755.1,1514.8c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C1756,1516.5,1755.1,1515.8,1755.1,1514.8z M1758.8,1513.4l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V1513.4z"
          />
          <path
            d="M1761.5,1510.5h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V1510.5z"
          />
          <path
            d="M1767.4,1513.4c0-1.7,0.9-3.1,2.6-3.1c0.8,0,1.5,0.4,1.8,1v-3h1v8.1h-1v-0.9c-0.3,0.6-1,1-1.8,1
				C1768.3,1516.5,1767.4,1515.1,1767.4,1513.4z M1770.2,1511.3c-1.1,0-1.7,0.9-1.7,2.2c0,1.3,0.6,2.2,1.7,2.2c0.8,0,1.7-0.6,1.7-2
				v-0.4C1771.8,1511.9,1771,1511.3,1770.2,1511.3z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="67">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M3054.5,2135.5c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3034,2089.7,3054.5,2110.2,3054.5,2135.5"
        />
        <g>
          <path d="M2981.3,2110.7v1h-4.5v2.7h3.9v1h-3.9v3h4.5v1h-5.7v-8.7H2981.3z" />
          <path d="M2985,2116.9l-1.8,2.5h-1.2l2.3-3.3l-2.1-3h1.3l1.5,2.2l1.6-2.2h1.2l-2.1,3l2.3,3.3h-1.2L2985,2116.9z" />
          <path
            d="M2990.1,2118.5v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2991.2,2119.6,2990.5,2119.2,2990.1,2118.5z M2990.1,2116.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2116.5z"
          />
          <path
            d="M2995.8,2116.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2997,2119.6,2995.8,2118.2,2995.8,2116.3z M2996.9,2115.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2997.8,2113.9,2997.1,2114.5,2996.9,2115.6z"
          />
          <path
            d="M3006.1,2114.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2114.2z"
          />
          <path d="M3007.3,2110.7h1.2v1.3h-1.2V2110.7z M3008.4,2119.4h-1.1v-6.4h1.1V2119.4z" />
          <path
            d="M3009.8,2116.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3011,2119.6,3009.8,2118.2,3009.8,2116.3z M3010.9,2115.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3011.8,2113.9,3011.1,2114.5,3010.9,2115.6z"
          />
          <path
            d="M3016.8,2113.1h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2113.1z"
          />
          <path
            d="M3026.2,2118.6c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3024.3,2117.7,3025.1,2118.6,3026.2,2118.6z"
          />
          <path
            d="M3029.5,2116.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3030.8,2119.6,3029.5,2118.2,3029.5,2116.3z M3030.7,2115.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3031.5,2113.9,3030.8,2114.5,3030.7,2115.6z"
          />
          <path
            d="M3036.1,2116.3c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3037.1,2119.6,3036.1,2118.1,3036.1,2116.3z M3039.1,2113.9c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C3040.9,2114.6,3040,2113.9,3039.1,2113.9z"
          />
        </g>
        <g>
          <path
            d="M2981.5,2129.8c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2982.5,2133.1,2981.5,2131.6,2981.5,2129.8z M2984.5,2127.4c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C2986.3,2128.1,2985.4,2127.4,2984.5,2127.4z"
          />
          <path d="M2989.1,2124.2h1.2v1.3h-1.2V2124.2z M2990.3,2133h-1.1v-6.4h1.1V2133z" />
          <path
            d="M2992.3,2131c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2992.3,2131z"
          />
          <path
            d="M3001.3,2127.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2127.7z"
          />
          <path
            d="M3007.6,2133h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2133z"
          />
          <path
            d="M3010.5,2132v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3011.6,2133.1,3010.9,2132.7,3010.5,2132z M3010.4,2130c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2130z"
          />
          <path
            d="M3016.8,2127.5h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2127.5z"
          />
          <path d="M3020.7,2124.2h1.2v1.3h-1.2V2124.2z M3021.8,2133h-1.1v-6.4h1.1V2133z" />
          <path
            d="M3026.2,2133.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3029.1,2131.8,3027.9,2133.1,3026.2,2133.1z
				 M3026.2,2132.2c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3024.3,2131.2,3025.1,2132.2,3026.2,2132.2
				z"
          />
          <path
            d="M3030.5,2126.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2126.6z"
          />
        </g>
        <g>
          <path
            d="M2981.9,2146.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2984.9,2145.3,2983.7,2146.6,2981.9,2146.6z
				 M2981.9,2145.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2980.1,2144.7,2980.8,2145.7,2981.9,2145.7
				z"
          />
          <path
            d="M2989,2138.6c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2138.6z"
          />
          <path
            d="M2994,2141.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2141.1z"
          />
          <path
            d="M2998,2137.7h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2137.7z"
          />
          <path
            d="M3004.4,2143.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3005.6,2146.6,3004.4,2145.3,3004.4,2143.3z M3005.5,2142.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3006.4,2140.9,3005.7,2141.5,3005.5,2142.6z"
          />
          <path
            d="M3018.1,2138.6c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2138.6z"
          />
          <path
            d="M3021.5,2146.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3024.5,2145.3,3023.3,2146.6,3021.5,2146.6z
				 M3021.5,2145.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3019.7,2144.7,3020.4,2145.7,3021.5,2145.7
				z"
          />
          <path
            d="M3028.4,2146.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3031.3,2145.3,3030.1,2146.6,3028.4,2146.6z
				 M3028.4,2145.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3026.5,2144.7,3027.3,2145.7,3028.4,2145.7
				z"
          />
          <path
            d="M3032.3,2143.3c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3033.3,2146.6,3032.3,2145.1,3032.3,2143.3z M3035.3,2141c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C3037.1,2141.6,3036.2,2141,3035.3,2141z"
          />
        </g>
        <g>
          <path
            d="M2975.1,2158c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2975.1,2158z"
          />
          <path
            d="M2985.9,2160h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2160z"
          />
          <path
            d="M2988.8,2159.1v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2989.9,2160.1,2989.2,2159.7,2988.8,2159.1z M2988.8,2157c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2157z"
          />
          <path
            d="M2996,2159.1v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2997.1,2160.1,2996.4,2159.7,2996,2159.1z M2996,2157c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2157z"
          />
          <path d="M3003.2,2160h-1.1v-8.7h1.1V2160z" />
          <path
            d="M3005.4,2162.3c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C3007,2161.6,3006.6,2162.3,3005.4,2162.3z"
          />
          <path
            d="M3017.6,2159.2c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3015.7,2158.3,3016.5,2159.2,3017.6,2159.2z"
          />
          <path
            d="M3021.4,2151.3h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2151.3z"
          />
          <path
            d="M3027.8,2158.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3028.7,2160.1,3027.8,2159.4,3027.8,2158.3z M3031.7,2156.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2156.7z"
          />
          <path d="M3034.6,2151.3h1.2v1.3h-1.2V2151.3z M3035.8,2160h-1.1v-6.4h1.1V2160z" />
          <path
            d="M3037.6,2153.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2153.6z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="66">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M3398.8,2135.4c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3378.3,2089.7,3398.8,2110.2,3398.8,2135.4"
        />
        <g>
          <path
            d="M3326.8,2117.4h2.8c2.7,0,4.3,1.7,4.3,4.4c0,2.7-1.7,4.4-4.3,4.4h-2.8V2117.4z M3329.6,2125.2c2,0,3.2-1.3,3.2-3.4
				c0-2-1.2-3.4-3.2-3.4h-1.6v6.7H3329.6z"
          />
          <path
            d="M3334.8,2123c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3336,2126.3,3334.8,2125,3334.8,2123z M3335.9,2122.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3336.8,2120.6,3336.1,2121.2,3335.9,2122.3z"
          />
          <path
            d="M3344.3,2128.5c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3347.2,2127.5,3346,2128.5,3344.3,2128.5z M3344.4,2120.6c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C3346.1,2121.3,3345.3,2120.6,3344.4,2120.6z"
          />
          <path
            d="M3352.3,2120.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2120.9z"
          />
          <path
            d="M3353.1,2123c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3354.3,2126.3,3353.1,2125,3353.1,2123z M3354.2,2122.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3355.1,2120.6,3354.4,2121.2,3354.2,2122.3z"
          />
          <path
            d="M3359.7,2123c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3360.9,2126.3,3359.7,2125,3359.7,2123z M3360.8,2122.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3361.7,2120.6,3361,2121.2,3360.8,2122.3z"
          />
          <path
            d="M3373.1,2126.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3376,2125,3374.8,2126.3,3373.1,2126.3z
				 M3373.1,2125.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3371.2,2124.4,3371.9,2125.4,3373.1,2125.4
				z"
          />
          <path
            d="M3380.2,2118.3c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2118.3z"
          />
        </g>
        <g>
          <path
            d="M3318.2,2137.7c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3318.2,2137.7z"
          />
          <path
            d="M3329.1,2139.7h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2139.7z"
          />
          <path
            d="M3331,2137.7c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3331,2137.7z"
          />
          <path
            d="M3337,2134.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2134.3z"
          />
          <path
            d="M3340.4,2138c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3341.4,2139.8,3340.4,2139.1,3340.4,2138z M3344.4,2136.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2136.4z"
          />
          <path d="M3347.3,2131h1.2v1.3h-1.2V2131z M3348.4,2139.7h-1.1v-6.4h1.1V2139.7z" />
          <path
            d="M3350.2,2133.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2133.3z"
          />
          <path
            d="M3356.6,2138c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3357.5,2139.8,3356.6,2139.1,3356.6,2138z M3360.5,2136.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2136.4z"
          />
          <path
            d="M3364.6,2138.8v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3365.7,2139.8,3365,2139.4,3364.6,2138.8z M3364.6,2136.7c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2136.7z"
          />
          <path d="M3370.7,2131h1.2v1.3h-1.2V2131z M3371.8,2139.7h-1.1v-6.4h1.1V2139.7z" />
          <path d="M3374.7,2139.7h-1.1v-8.7h1.1V2139.7z" />
          <path d="M3376.5,2131h1.2v1.3h-1.2V2131z M3377.7,2139.7h-1.1v-6.4h1.1V2139.7z" />
          <path
            d="M3379.6,2134.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2134.3z"
          />
          <path
            d="M3384,2141.9c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C3385.6,2141.3,3385.1,2141.9,3384,2141.9z"
          />
        </g>
        <g>
          <path d="M3313.5,2153.2l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H3313.5z" />
          <path d="M3317.9,2144.5h1.2v1.3h-1.2V2144.5z M3319.1,2153.2h-1.1v-6.4h1.1V2153.2z" />
          <path
            d="M3324.2,2147.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2147.9z"
          />
          <path
            d="M3325.6,2147.8h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2147.8z"
          />
          <path
            d="M3334.6,2153.2h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2153.2z"
          />
          <path
            d="M3336,2150c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2l1,0.3
				c-0.4,1.1-1.3,1.8-2.6,1.8C3337.2,2153.3,3336,2152,3336,2150z M3337.1,2149.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3338,2147.7,3337.3,2148.3,3337.1,2149.4z"
          />
          <path
            d="M3347,2151.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3347,2151.2z"
          />
          <path d="M3352.7,2144.5h1.2v1.3h-1.2V2144.5z M3353.8,2153.2h-1.1v-6.4h1.1V2153.2z" />
          <path
            d="M3358.1,2155.5c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3361.1,2154.5,3359.8,2155.5,3358.1,2155.5z M3358.2,2147.7c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C3360,2148.4,3359.1,2147.7,3358.2,2147.7z"
          />
          <path
            d="M3362.9,2146.8h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2146.8z"
          />
          <path
            d="M3369.2,2151.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3370.2,2153.3,3369.2,2152.6,3369.2,2151.5z M3373.2,2149.9l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2149.9z"
          />
          <path d="M3377.2,2153.2h-1.1v-8.7h1.1V2153.2z" />
          <path d="M3379,2144.5h1.2v1.3h-1.2V2144.5z M3380.2,2153.2h-1.1v-6.4h1.1V2153.2z" />
          <path
            d="M3382,2146.8h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2146.8z"
          />
          <path
            d="M3391.3,2155.5c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3394.2,2154.5,3393,2155.5,3391.3,2155.5z M3391.4,2147.7c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C3393.1,2148.4,3392.3,2147.7,3391.4,2147.7z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="65">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M2666.8,331.6c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2646.3,285.8,2666.8,306.3,2666.8,331.6"
        />
        <g>
          <path
            d="M2597.1,306.8h2.8c2.7,0,4.3,1.7,4.3,4.4c0,2.7-1.7,4.4-4.3,4.4h-2.8V306.8z M2599.9,314.5c2,0,3.2-1.3,3.2-3.4
				c0-2-1.2-3.4-3.2-3.4h-1.6v6.7L2599.9,314.5L2599.9,314.5z"
          />
          <path
            d="M2605.2,312.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2606.4,315.7,2605.2,314.3,2605.2,312.4z M2606.3,311.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2607.1,310,2606.5,310.6,2606.3,311.7z"
          />
          <path
            d="M2612.3,313.6c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2612.3,313.6z"
          />
          <path d="M2618,306.8h1.2v1.3h-1.2V306.8z M2619.2,315.5h-1.1v-6.4h1.1V315.5z" />
          <path
            d="M2624.3,310.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V310.3z"
          />
          <path
            d="M2625.1,312.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2626.3,315.7,2625.1,314.3,2625.1,312.4z M2626.2,311.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2627,310,2626.4,310.6,2626.2,311.7z"
          />
          <path
            d="M2636.1,310.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V310.1z"
          />
          <path
            d="M2642.6,315.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2645.5,314.3,2644.3,315.7,2642.6,315.7z M2642.6,314.7
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2640.7,313.8,2641.4,314.7,2642.6,314.7z"
          />
        </g>
        <g>
          <path
            d="M2586.6,328.2c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2584.7,327.3,2585.5,328.2,2586.6,328.2z"
          />
          <path
            d="M2592.9,329.2c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2595.8,327.9,2594.6,329.2,2592.9,329.2z M2592.9,328.3
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2591,327.3,2591.8,328.3,2592.9,328.3z"
          />
          <path
            d="M2598.3,329.1h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V329.1
				z"
          />
          <path
            d="M2608.9,328.1v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2610,329.2,2609.3,328.8,2608.9,328.1z M2608.9,326.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V326.1z"
          />
          <path d="M2615,320.3h1.2v1.3h-1.2V320.3z M2616.1,329.1h-1.1v-6.4h1.1V329.1z" />
          <path
            d="M2618,322.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V322.7z"
          />
          <path
            d="M2624.4,325.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2625.6,329.2,2624.4,327.8,2624.4,325.9z M2625.5,325.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2626.4,323.5,2625.7,324.1,2625.5,325.2z"
          />
          <path
            d="M2638,321.2c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V321.2z"
          />
          <path
            d="M2641.5,329.2c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2644.5,327.9,2643.2,329.2,2641.5,329.2z M2641.5,328.3
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2639.7,327.3,2640.4,328.3,2641.5,328.3z"
          />
          <path
            d="M2648.4,329.2c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2651.3,327.9,2650.1,329.2,2648.4,329.2z M2648.4,328.3
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2646.5,327.3,2647.3,328.3,2648.4,328.3z"
          />
          <path
            d="M2652.3,325.9c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2653.3,329.2,2652.3,327.7,2652.3,325.9z M2655.2,323.5c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C2657,324.2,2656.1,323.5,2655.2,323.5z"
          />
        </g>
        <g>
          <path
            d="M2583.3,340.6c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2583.3,340.6z"
          />
          <path
            d="M2589.1,333.8h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V333.8z"
          />
          <path
            d="M2598.5,342.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2601.4,341.4,2600.2,342.7,2598.5,342.7z M2598.5,341.8
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2596.6,340.8,2597.4,341.8,2598.5,341.8z"
          />
          <path
            d="M2603.9,341.7v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2605,342.7,2604.3,342.3,2603.9,341.7z M2603.9,339.6c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V339.6z"
          />
          <path
            d="M2611.1,341.7v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2612.2,342.7,2611.5,342.3,2611.1,341.7z M2611.1,339.6c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V339.6z"
          />
          <path d="M2617.2,333.8h1.2v1.3h-1.2V333.8z M2618.4,342.6h-1.1v-6.4h1.1V342.6z" />
          <path
            d="M2620.2,336.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V336.2z"
          />
          <path
            d="M2629.5,344.9c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2632.4,343.9,2631.2,344.9,2629.5,344.9z M2629.6,337.1c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C2631.3,337.7,2630.5,337.1,2629.6,337.1z"
          />
          <path d="M2642.1,336.2l1.3,4.7l1.3-4.7h1.1l-1.9,6.4h-1l-1.3-4.7l-1.3,4.7h-1l-1.9-6.4h1.1l1.3,4.7l1.3-4.7H2642.1z" />
          <path d="M2646.8,333.8h1.2v1.3h-1.2V333.8z M2648,342.6h-1.1v-6.4h1.1V342.6z" />
          <path
            d="M2650,337.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V337.2z"
          />
          <path
            d="M2653.9,333.8h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V333.8z"
          />
        </g>
        <g>
          <path
            d="M2595.8,356.2c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2598.7,354.9,2597.5,356.2,2595.8,356.2z M2595.8,355.3
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2593.9,354.4,2594.6,355.3,2595.8,355.3z"
          />
          <path
            d="M2600.3,350.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V350.7z"
          />
          <path
            d="M2604.2,347.4h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V347.4z"
          />
          <path
            d="M2610.7,352.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2611.9,356.2,2610.7,354.9,2610.7,352.9z M2611.8,352.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2612.7,350.5,2612,351.2,2611.8,352.2z"
          />
          <path
            d="M2621,350.8c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V350.8z"
          />
          <path
            d="M2626.2,350.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V350.7z"
          />
          <path
            d="M2633.5,350.8c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V350.8z"
          />
          <path d="M2634.6,347.4h1.2v1.3h-1.2V347.4z M2635.8,356.1h-1.1v-6.4h1.1V356.1z" />
          <path
            d="M2638.7,355.2v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2639.8,356.2,2639.1,355.8,2638.7,355.2z M2638.7,353.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V353.1z"
          />
          <path
            d="M2645,354.1c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2645,354.1z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="64">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M1817.9,1230.3c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1797.4,1184.5,1817.9,1205,1817.9,1230.3"
        />
        <g>
          <path d="M1742.7,1208.3h-1.2v-8.7h1.2V1208.3z" />
          <path
            d="M1744.6,1201.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1201.9z"
          />
          <path
            d="M1754,1207.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1752.1,1206.5,1752.9,1207.4,1754,1207.4z"
          />
          <path d="M1758.9,1208.3h-1.1v-8.7h1.1V1208.3z" />
          <path
            d="M1765.7,1208.3h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1208.3z"
          />
          <path
            d="M1767.7,1206.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1767.7,1206.3z"
          />
          <path d="M1773.4,1199.5h1.2v1.3h-1.2V1199.5z M1774.5,1208.3h-1.1v-6.4h1.1V1208.3z" />
          <path
            d="M1778.9,1208.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C1781.8,1207.1,1780.6,1208.4,1778.9,1208.4z
				 M1778.9,1207.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1777,1206.5,1777.8,1207.5,1778.9,1207.5z"
          />
          <path
            d="M1783.2,1201.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1201.9z"
          />
          <path
            d="M1796.5,1208.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C1799.4,1207.1,1798.2,1208.4,1796.5,1208.4z
				 M1796.5,1207.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1794.6,1206.5,1795.3,1207.5,1796.5,1207.5
				z"
          />
          <path
            d="M1803.6,1200.4c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1200.4z"
          />
        </g>
        <g>
          <path
            d="M1737.3,1219.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1737.3,1219.8z"
          />
          <path
            d="M1748.1,1221.8h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1221.8z"
          />
          <path
            d="M1750.1,1219.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1750.1,1219.8z"
          />
          <path
            d="M1756,1216.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1216.3z"
          />
          <path
            d="M1759.5,1220.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1760.4,1221.9,1759.5,1221.2,1759.5,1220.1z M1763.4,1218.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1218.5z"
          />
          <path d="M1766.3,1213h1.2v1.3h-1.2V1213z M1767.5,1221.8h-1.1v-6.4h1.1V1221.8z" />
          <path
            d="M1769.3,1215.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1215.4z"
          />
          <path
            d="M1775.7,1220.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1776.6,1221.9,1775.7,1221.2,1775.7,1220.1z M1779.6,1218.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1218.5z"
          />
          <path
            d="M1783.7,1220.8v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1784.7,1221.9,1784,1221.5,1783.7,1220.8z M1783.6,1218.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1218.8z"
          />
          <path d="M1789.7,1213h1.2v1.3h-1.2V1213z M1790.9,1221.8h-1.1v-6.4h1.1V1221.8z" />
          <path d="M1793.8,1221.8h-1.1v-8.7h1.1V1221.8z" />
          <path d="M1795.6,1213h1.2v1.3h-1.2V1213z M1796.7,1221.8h-1.1v-6.4h1.1V1221.8z" />
          <path
            d="M1798.7,1216.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1216.3z"
          />
          <path
            d="M1803.1,1224c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C1804.7,1223.4,1804.2,1224,1803.1,1224z"
          />
        </g>
        <g>
          <path
            d="M1749.6,1234.5c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1747.7,1233.6,1748.5,1234.5,1749.6,1234.5z"
          />
          <path
            d="M1756.7,1230c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1230z"
          />
          <path d="M1757.8,1226.6h1.2v1.3h-1.2V1226.6z M1759,1235.3h-1.1v-6.4h1.1V1235.3z" />
          <path
            d="M1761,1229.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1229.9z"
          />
          <path
            d="M1764.5,1232.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1765.7,1235.4,1764.5,1234.1,1764.5,1232.1z M1765.6,1231.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1766.5,1229.7,1765.8,1230.4,1765.6,1231.4z"
          />
          <path
            d="M1774.8,1230c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1230z"
          />
          <path d="M1776,1226.6h1.2v1.3h-1.2V1226.6z M1777.1,1235.3h-1.1v-6.4h1.1V1235.3z" />
          <path
            d="M1778.4,1233.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1779.4,1235.4,1778.4,1234.7,1778.4,1233.6z M1782.4,1232l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1232z"
          />
          <path d="M1789.1,1226.6h1.2v1.3h-1.2V1226.6z M1790.3,1235.3h-1.1v-6.4h1.1V1235.3z" />
          <path
            d="M1792.1,1228.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1228.9z"
          />
        </g>
        <g>
          <path
            d="M1757.7,1247.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1758.7,1249.7,1757.7,1249,1757.7,1247.9z M1761.7,1246.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1246.4z"
          />
          <path
            d="M1767.1,1251.9c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C1770,1250.9,1768.8,1251.9,1767.1,1251.9z M1767.2,1244.1c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C1769,1244.8,1768.1,1244.1,1767.2,1244.1z"
          />
          <path
            d="M1775.2,1244.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1244.3z"
          />
          <path
            d="M1778.9,1249.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C1781.8,1248.4,1780.6,1249.7,1778.9,1249.7z
				 M1778.9,1248.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1777,1247.9,1777.8,1248.8,1778.9,1248.8z"
          />
          <path d="M1782.9,1245.6h3.3v1h-3.3V1245.6z" />
          <path
            d="M1743.8,1262.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1744.8,1264.3,1743.8,1263.6,1743.8,1262.5z M1747.8,1261l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1261z"
          />
          <path
            d="M1755.8,1264.2h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1264.2z"
          />
          <path
            d="M1760.2,1263.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1758.3,1262.5,1759,1263.4,1760.2,1263.4z"
          />
          <path
            d="M1764.1,1258.8h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1258.8z"
          />
          <path d="M1768,1255.5h1.2v1.3h-1.2V1255.5z M1769.2,1264.2h-1.1v-6.4h1.1V1264.2z" />
          <path
            d="M1773.5,1264.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C1776.4,1263,1775.2,1264.3,1773.5,1264.3z
				 M1773.5,1263.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1771.6,1262.5,1772.4,1263.4,1773.5,1263.4
				z"
          />
          <path
            d="M1777.8,1257.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1257.9z"
          />
          <path d="M1784.6,1255.5h1.2v1.3h-1.2V1255.5z M1785.8,1264.2h-1.1v-6.4h1.1V1264.2z" />
          <path
            d="M1787.6,1257.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1257.9z"
          />
          <path
            d="M1796.9,1266.5c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C1799.9,1265.5,1798.6,1266.5,1796.9,1266.5z M1797,1258.7c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C1798.8,1259.4,1797.9,1258.7,1797,1258.7z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="63">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3627.4,750.1c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3606.9,704.3,3627.4,724.8,3627.4,750.1"
        />
        <g>
          <path d="M3572.2,726.3v7.7h-1.2v-7.7h-3v-1h7.1v1H3572.2z" />
          <path d="M3576.2,725.3h1.2v1.3h-1.2V725.3z M3577.4,734h-1.1v-6.4h1.1V734z" />
          <path
            d="M3580.3,734h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V734z"
          />
          <path
            d="M3589.4,730.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3590.6,734.2,3589.4,732.8,3589.4,730.9z M3590.5,730.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3591.4,728.5,3590.7,729.1,3590.5,730.2z"
          />
        </g>
        <g>
          <path
            d="M3554.9,744.4c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3555.9,747.7,3554.9,746.2,3554.9,744.4z M3557.8,742c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C3559.6,742.7,3558.7,742,3557.8,742z"
          />
          <path
            d="M3562.1,744.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3563.3,747.7,3562.1,746.3,3562.1,744.4z M3563.2,743.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3564.1,742,3563.4,742.6,3563.2,743.7z"
          />
          <path
            d="M3568.7,744.4c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3569.7,747.7,3568.7,746.2,3568.7,744.4z M3571.6,742c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C3573.4,742.7,3572.5,742,3571.6,742z"
          />
          <path d="M3576.3,738.8h1.2v1.3h-1.2V738.8z M3577.4,747.6h-1.1v-6.4h1.1V747.6z" />
          <path
            d="M3581.8,746.7c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3579.9,745.8,3580.7,746.7,3581.8,746.7z"
          />
          <path
            d="M3585,745.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3586,747.7,3585,747,3585,745.8z M3589,744.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4V744.3z"
          />
          <path
            d="M3592.1,742.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V742.1z"
          />
          <path
            d="M3595.6,744.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3596.9,747.7,3595.6,746.3,3595.6,744.4z M3596.7,743.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3597.6,742,3596.9,742.6,3596.7,743.7z"
          />
          <path
            d="M3602.2,744.4c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3603.2,747.7,3602.2,746.2,3602.2,744.4z M3605.2,742c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C3607,742.7,3606.1,742,3605.2,742z"
          />
        </g>
        <g>
          <path
            d="M3544.3,755.6h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V755.6z"
          />
          <path
            d="M3550.8,761.2c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3553.7,759.9,3552.5,761.2,3550.8,761.2z M3550.8,760.3
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3548.9,759.3,3549.6,760.3,3550.8,760.3z"
          />
          <path
            d="M3559.1,759.1c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3559.1,759.1z"
          />
          <path
            d="M3569.9,761.1h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V761.1z"
          />
          <path
            d="M3571.9,759.1c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3571.9,759.1z"
          />
          <path
            d="M3577.8,755.6h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V755.6z"
          />
          <path
            d="M3581.3,759.4c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3582.2,761.2,3581.3,760.5,3581.3,759.4z M3585.2,757.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V757.8z"
          />
          <path d="M3588.1,752.3h1.2v1.3h-1.2V752.3z M3589.3,761.1h-1.1v-6.4h1.1V761.1z" />
          <path
            d="M3591.1,754.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V754.7z"
          />
          <path
            d="M3597.5,759.4c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3598.4,761.2,3597.5,760.5,3597.5,759.4z M3601.4,757.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V757.8z"
          />
          <path
            d="M3605.4,760.1v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3606.5,761.2,3605.8,760.8,3605.4,760.1z M3605.4,758.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V758.1z"
          />
          <path d="M3612.7,761.1h-1.1v-8.7h1.1V761.1z" />
          <path
            d="M3614.1,757.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3615.3,761.2,3614.1,759.9,3614.1,757.9z M3615.2,757.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3616.1,755.5,3615.4,756.1,3615.2,757.2z"
          />
        </g>
        <g>
          <path
            d="M3573,766.7c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V766.7z"
          />
          <path
            d="M3576.5,774.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3579.5,773.4,3578.2,774.7,3576.5,774.7z M3576.5,773.8
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3574.7,772.9,3575.4,773.8,3576.5,773.8z"
          />
          <path
            d="M3583.4,774.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3586.3,773.4,3585.1,774.7,3583.4,774.7z M3583.4,773.8
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3581.5,772.9,3582.2,773.8,3583.4,773.8z"
          />
          <path
            d="M3587.3,771.4c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3588.3,774.7,3587.3,773.2,3587.3,771.4z M3590.2,769.1c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C3592,769.7,3591.1,769.1,3590.2,769.1z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="62">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3149.6,1425.9c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3129.1,1380.1,3149.6,1400.6,3149.6,1425.9"
        />
        <g>
          <path
            d="M3068.1,1416.6h-1.2v-8.7h3.3c1.8,0,3,0.9,3,2.5c0,1.6-1.2,2.5-3,2.5h-2.2V1416.6z M3070.2,1411.9c1.2,0,1.9-0.5,1.9-1.5
				c0-1-0.7-1.5-1.9-1.5h-2.1v3.1H3070.2z"
          />
          <path
            d="M3077.7,1411.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1411.3z"
          />
          <path
            d="M3078.5,1413.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3079.7,1416.7,3078.5,1415.4,3078.5,1413.4z M3079.6,1412.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3080.5,1411,3079.8,1411.6,3079.6,1412.7z"
          />
          <path
            d="M3086.6,1415.6v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3087.7,1416.7,3087,1416.3,3086.6,1415.6z M3086.6,1413.6c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1413.6z"
          />
          <path
            d="M3092.2,1414.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3093.2,1416.7,3092.2,1416,3092.2,1414.9z M3096.2,1413.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1413.3z"
          />
          <path
            d="M3102.4,1411.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1411.3z"
          />
          <path
            d="M3103.2,1413.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3104.4,1416.7,3103.2,1415.4,3103.2,1413.4z M3104.3,1412.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3105.2,1411,3104.5,1411.6,3104.3,1412.7z"
          />
          <path
            d="M3109.8,1413.4c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3110.8,1416.7,3109.8,1415.2,3109.8,1413.4z M3112.7,1411c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C3114.5,1411.7,3113.6,1411,3112.7,1411z"
          />
          <path
            d="M3117.4,1410.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1410.2z"
          />
          <path
            d="M3123.8,1413.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3125.1,1416.7,3123.8,1415.4,3123.8,1413.4z M3124.9,1412.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3125.8,1411,3125.1,1411.6,3124.9,1412.7z"
          />
          <path
            d="M3131,1414.6c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3131,1414.6z"
          />
          <path
            d="M3136.9,1414.6c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3136.9,1414.6z"
          />
        </g>
        <g>
          <path
            d="M3063.1,1424.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1424.7z"
          />
          <path
            d="M3069.6,1430.2c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3072.6,1428.9,3071.4,1430.2,3069.6,1430.2z
				 M3069.6,1429.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3067.8,1428.4,3068.5,1429.3,3069.6,1429.3
				z"
          />
          <path
            d="M3080.4,1429.3c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3078.5,1428.4,3079.2,1429.3,3080.4,1429.3z"
          />
          <path
            d="M3084.1,1421.4h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1421.4z"
          />
          <path
            d="M3090.5,1428.4c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3091.4,1430.2,3090.5,1429.5,3090.5,1428.4z M3094.5,1426.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1426.8z"
          />
          <path
            d="M3097.4,1423.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1423.7z"
          />
          <path
            d="M3106.7,1432.4c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3109.6,1431.4,3108.4,1432.4,3106.7,1432.4z M3106.8,1424.6c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2
				c0.9,0,1.8-0.7,1.8-2.1v-0.2C3108.6,1425.2,3107.7,1424.6,3106.8,1424.6z"
          />
          <path
            d="M3111,1426.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3112.3,1430.2,3111,1428.9,3111,1426.9z M3112.1,1426.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3113,1424.5,3112.3,1425.2,3112.1,1426.2z"
          />
          <path
            d="M3124.7,1422.2c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1422.2z"
          />
          <path
            d="M3128.2,1430.2c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3131.1,1428.9,3129.9,1430.2,3128.2,1430.2z
				 M3128.2,1429.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3126.3,1428.4,3127,1429.3,3128.2,1429.3z"
          />
          <path
            d="M3135,1430.2c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3138,1428.9,3136.8,1430.2,3135,1430.2z M3135,1429.3
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3133.2,1428.4,3133.9,1429.3,3135,1429.3z"
          />
          <path
            d="M3138.9,1426.9c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3139.9,1430.2,3138.9,1428.7,3138.9,1426.9z M3141.9,1424.6c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C3143.7,1425.2,3142.8,1424.6,3141.9,1424.6z"
          />
        </g>
        <g>
          <path
            d="M3088,1434.9h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1434.9z"
          />
          <path
            d="M3094.4,1441.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3095.3,1443.7,3094.4,1443,3094.4,1441.9z M3098.4,1440.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1440.3z"
          />
          <path
            d="M3102.4,1442.7v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3103.5,1443.7,3102.8,1443.3,3102.4,1442.7z M3102.4,1440.7c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1440.7z"
          />
          <path d="M3108.5,1434.9h1.2v1.3h-1.2V1434.9z M3109.6,1443.6h-1.1v-6.4h1.1V1443.6z" />
          <path
            d="M3111.6,1438.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1438.2z"
          />
          <path
            d="M3115.7,1441.6c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3115.7,1441.6z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="61">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1027.2,1665.5c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1006.7,1619.7,1027.2,1640.2,1027.2,1665.5"
        />
        <g>
          <path
            d="M953.1,1660.8c0.5,1,1.4,1.5,2.5,1.5s1.9-0.5,1.9-1.4c0-1-0.9-1.1-2.2-1.4c-1.3-0.3-2.5-0.7-2.5-2.2
				c0-1.4,1.2-2.3,2.8-2.3c1.4,0,2.4,0.7,2.9,1.6l-0.8,0.7c-0.4-0.8-1.1-1.3-2.1-1.3c-1,0-1.7,0.5-1.7,1.3c0,0.9,0.8,1,1.9,1.3
				c1.3,0.3,2.8,0.6,2.8,2.3c0,1.5-1.3,2.5-2.9,2.5c-1.4,0-2.7-0.7-3.3-1.8L953.1,1660.8z"
          />
          <path
            d="M964.3,1663.1h-1v-0.8c-0.3,0.5-0.9,0.9-1.7,0.9c-1.3,0-2-0.9-2-2.1v-3.9h1v3.7c0,0.8,0.4,1.4,1.2,1.4
				c0.7,0,1.5-0.5,1.5-1.5v-3.5h1V1663.1z"
          />
          <path
            d="M966.1,1661.3c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L966.1,1661.3z"
          />
          <path
            d="M971.6,1658.1h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L971.6,1658.1L971.6,1658.1z"
          />
          <path
            d="M974.8,1661.6c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C975.6,1663.2,974.8,1662.6,974.8,1661.6z M978.4,1660.1l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L978.4,1660.1L978.4,1660.1z"
          />
          <path d="M981.1,1655.1h1.1v1.2h-1.1V1655.1z M982.1,1663.1h-1v-5.9h1V1663.1z" />
          <path
            d="M983.8,1657.3h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L983.8,1657.3L983.8,1657.3z"
          />
          <path
            d="M989.7,1661.6c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C990.6,1663.2,989.7,1662.6,989.7,1661.6z M993.3,1660.1l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L993.3,1660.1L993.3,1660.1z"
          />
          <path
            d="M997.1,1662.3v0.9h-1v-8.1h1v3c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C998.1,1663.3,997.4,1662.9,997.1,1662.3z M997,1660.4c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1660.4z"
          />
          <path d="M1003.7,1663.1h-1v-8.1h1V1663.1z" />
          <path
            d="M1005,1660.2c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C1006.1,1663.3,1005,1662,1005,1660.2z M1006,1659.6h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1006.9,1658,1006.2,1658.6,1006,1659.6z"
          />
        </g>
        <g>
          <path
            d="M952.1,1674.8c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C950.3,1674,951,1674.8,952.1,1674.8z"
          />
          <path
            d="M958.6,1670.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L958.6,1670.7L958.6,1670.7z"
          />
          <path
            d="M962,1675.7c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C964.7,1674.5,963.6,1675.7,962,1675.7z
				 M962,1674.9c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C960.3,1674,961,1674.9,962,1674.9z"
          />
          <path
            d="M967,1674.8v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C968,1675.7,967.4,1675.3,967,1674.8z M967,1672.9c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1672.9z"
          />
          <path
            d="M978.6,1674.8c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C976.9,1674,977.6,1674.8,978.6,1674.8z"
          />
          <path
            d="M982.1,1667.5h1v3c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.5,0.6-1.5,1.5
				v3.5h-1L982.1,1667.5L982.1,1667.5z"
          />
          <path
            d="M990.7,1675.7c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C993.5,1674.5,992.4,1675.7,990.7,1675.7z
				 M990.7,1674.9c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C989,1674,989.7,1674.9,990.7,1674.9z"
          />
          <path d="M994.7,1667.5h1.1v1.2h-1.1V1667.5z M995.8,1675.6h-1v-5.9h1V1675.6z" />
          <path
            d="M999.8,1674.8c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C998.1,1674,998.8,1674.8,999.8,1674.8z"
          />
          <path
            d="M1002.9,1672.7c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1
				l0.9,0.3c-0.4,1-1.2,1.7-2.4,1.7C1004,1675.7,1002.9,1674.5,1002.9,1672.7z M1003.9,1672.1h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1004.7,1670.5,1004.1,1671.1,1003.9,1672.1z"
          />
          <path
            d="M1009.5,1673.8c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1009.5,1673.8z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="60">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M2875.1,2135.9c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2854.6,2090.2,2875.1,2110.6,2875.1,2135.9"
        />
        <g>
          <path d="M2808.6,2117.9h1.2v7.7h4.4v1h-5.5V2117.9z" />
          <path
            d="M2814.8,2123.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2816,2126.8,2814.8,2125.4,2814.8,2123.5z M2815.9,2122.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2816.8,2121.1,2816.1,2121.7,2815.9,2122.8z"
          />
          <path d="M2823.5,2126.6l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H2823.5z" />
          <path
            d="M2827.5,2123.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2828.8,2126.8,2827.5,2125.4,2827.5,2123.5z M2828.7,2122.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2829.5,2121.1,2828.9,2121.7,2828.7,2122.8z"
          />
          <path d="M2835.7,2126.6h-1.1v-8.7h1.1V2126.6z" />
          <path
            d="M2843.8,2126.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2846.8,2125.4,2845.6,2126.8,2843.8,2126.8z
				 M2843.8,2125.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2842,2124.9,2842.7,2125.8,2843.8,2125.8z"
          />
          <path
            d="M2851,2118.8c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2118.8z"
          />
        </g>
        <g>
          <path
            d="M2789,2140.3h-1.2v-8.7h3.3c1.8,0,3,0.9,3,2.5c0,1.4-0.9,2.2-2.2,2.5l2.4,3.7h-1.3l-2.3-3.7h-1.7V2140.3z M2791.1,2135.7
				c1.2,0,1.9-0.5,1.9-1.5s-0.7-1.5-1.9-1.5h-2.1v3.1H2791.1z"
          />
          <path
            d="M2796.5,2134.4c-0.1-0.3-0.2-0.6-0.2-0.8c0-0.6,0.2-1,0.6-1.4s0.9-0.6,1.5-0.6c0.6,0,1.1,0.2,1.4,0.5s0.5,0.8,0.5,1.2
				c0,0.6-0.2,1-0.5,1.5c-0.2,0.2-0.5,0.5-1,0.8l1.6,1.9c0.1-0.3,0.2-0.6,0.2-0.7s0.1-0.4,0.1-0.7h1c-0.1,0.6-0.2,1.1-0.4,1.6
				s-0.3,0.7-0.3,0.6l1.5,1.9h-1.4l-0.8-1c-0.3,0.4-0.6,0.6-0.9,0.8c-0.5,0.3-1,0.4-1.6,0.4c-0.9,0-1.6-0.2-2-0.7
				c-0.4-0.5-0.6-1-0.6-1.7c0-0.7,0.2-1.2,0.6-1.7c0.2-0.3,0.7-0.6,1.4-1C2796.9,2135.1,2796.6,2134.7,2796.5,2134.4z
				 M2799.1,2139.3c0.3-0.2,0.6-0.4,0.7-0.7l-2-2.4c-0.6,0.4-0.9,0.7-1.1,0.9c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.5,0.2,0.8,0.5,1.1
				c0.3,0.2,0.7,0.4,1.1,0.4C2798.4,2139.6,2798.8,2139.5,2799.1,2139.3z M2799,2134.3c0.2-0.3,0.3-0.6,0.3-0.9
				c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-0.4,0-0.7,0.1-0.9,0.4c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.2,0.1,0.5,0.2,0.7
				s0.4,0.5,0.7,0.9C2798.6,2134.7,2798.9,2134.5,2799,2134.3z"
          />
          <path
            d="M2803.8,2131.6h2.8c2.7,0,4.3,1.7,4.3,4.4c0,2.7-1.7,4.4-4.3,4.4h-2.8V2131.6z M2806.6,2139.3c2,0,3.2-1.3,3.2-3.4
				c0-2-1.2-3.4-3.2-3.4h-1.6v6.7H2806.6z"
          />
          <path d="M2814.2,2131.6h0.9l-2.8,8.7h-0.9L2814.2,2131.6z" />
          <path d="M2816.9,2140.3h-1.2v-8.7h1.2V2140.3z" />
          <path
            d="M2818.8,2134h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2134z"
          />
          <path
            d="M2825.6,2134h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2134z"
          />
          <path
            d="M2835,2140.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2838,2139.1,2836.8,2140.5,2835,2140.5z M2835,2139.5
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2833.2,2138.6,2833.9,2139.5,2835,2139.5z"
          />
          <path d="M2841,2140.3l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H2841z" />
          <path
            d="M2845,2138.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2846,2140.4,2845,2139.8,2845,2138.6z M2849,2137.1l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V2137.1z"
          />
          <path
            d="M2852.1,2134.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2134.9z"
          />
          <path d="M2856,2131.6h1.2v1.3h-1.2V2131.6z M2857.2,2140.3h-1.1v-6.4h1.1V2140.3z" />
          <path
            d="M2861.5,2140.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2864.5,2139.1,2863.2,2140.5,2861.5,2140.5z
				 M2861.5,2139.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2859.7,2138.6,2860.4,2139.5,2861.5,2139.5
				z"
          />
          <path
            d="M2865.8,2134h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2134z"
          />
        </g>
        <g>
          <path d="M2787.5,2144.9h1.2v1.3h-1.2V2144.9z M2788.7,2153.7h-1.1v-6.4h1.1V2153.7z" />
          <path
            d="M2790.5,2147.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2147.3z"
          />
          <path
            d="M2801.4,2151.7c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2801.4,2151.7z"
          />
          <path
            d="M2812.2,2153.7h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2153.7z"
          />
          <path
            d="M2814.1,2151.7c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2814.1,2151.7z"
          />
          <path
            d="M2820.1,2148.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2148.2z"
          />
          <path
            d="M2823.5,2151.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2824.5,2153.8,2823.5,2153.1,2823.5,2151.9z M2827.5,2150.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2150.4z"
          />
          <path d="M2830.4,2144.9h1.2v1.3h-1.2V2144.9z M2831.5,2153.7h-1.1v-6.4h1.1V2153.7z" />
          <path
            d="M2833.4,2147.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2147.3z"
          />
          <path
            d="M2839.7,2151.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2840.7,2153.8,2839.7,2153.1,2839.7,2151.9z M2843.7,2150.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2150.4z"
          />
          <path
            d="M2847.7,2152.7v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2848.8,2153.8,2848.1,2153.4,2847.7,2152.7z M2847.7,2150.7c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2150.7z"
          />
          <path d="M2853.8,2144.9h1.2v1.3h-1.2V2144.9z M2854.9,2153.7h-1.1v-6.4h1.1V2153.7z" />
          <path d="M2857.9,2153.7h-1.1v-8.7h1.1V2153.7z" />
          <path d="M2859.6,2144.9h1.2v1.3h-1.2V2144.9z M2860.8,2153.7h-1.1v-6.4h1.1V2153.7z" />
          <path
            d="M2862.8,2148.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2148.2z"
          />
          <path
            d="M2867.1,2155.9c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C2868.7,2155.3,2868.2,2155.9,2867.1,2155.9z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="59">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M783.4,1289c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C762.9,1243.2,783.4,1263.7,783.4,1289"
        />
        <g>
          <path d="M719.3,1266.1l3.1,8.1h-1.1l-0.8-2.1h-3.6l-0.8,2.1H715l3.1-8.1H719.3z M717.2,1271.1h2.9l-1.5-3.8L717.2,1271.1z" />
          <path
            d="M724.4,1273.3v0.9h-1v-8.1h1v3c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C725.4,1274.3,724.8,1273.9,724.4,1273.3z M724.4,1271.5c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1271.5z"
          />
          <path d="M730,1266.1h1.1v1.2H730V1266.1z M731.1,1274.2h-1v-5.9h1V1274.2z" />
          <path d="M733.8,1274.2h-1v-8.1h1V1274.2z" />
          <path d="M735.4,1266.1h1.1v1.2h-1.1V1266.1z M736.5,1274.2h-1v-5.9h1V1274.2z" />
          <path
            d="M738.3,1269.2h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L738.3,1269.2L738.3,1269.2z"
          />
          <path
            d="M742.3,1276.3c-0.2,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,0.9-0.7l0.3-0.7l-2.2-5.7h1.1l1.7,4.5
				l1.6-4.5h1.1l-2.5,6.5C743.8,1275.7,743.4,1276.3,742.3,1276.3z"
          />
          <path
            d="M751.4,1269.2h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L751.4,1269.2L751.4,1269.2z"
          />
          <path
            d="M757.4,1274.3c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S759,1274.3,757.4,1274.3z M757.4,1273.4
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C755.7,1272.6,756.3,1273.4,757.4,1273.4z"
          />
        </g>
        <g>
          <path d="M711.5,1278.6h1.1v1.2h-1.1V1278.6z M712.6,1286.6h-1v-5.9h1V1286.6z" />
          <path
            d="M715.3,1286.6h-1v-5.9h1v0.8c0.3-0.5,0.9-0.9,1.7-0.9c0.8,0,1.3,0.4,1.6,1c0.3-0.5,1-1,1.9-1c1.2,0,1.9,0.9,1.9,2.1v3.9
				h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5v3.5h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5V1286.6z"
          />
          <path
            d="M725,1285.8v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C726,1286.8,725.4,1286.4,725,1285.8z M725,1283.9c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1283.9z"
          />
          <path d="M731.7,1286.6h-1v-8.1h1V1286.6z" />
          <path
            d="M733,1283.7c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3H734c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C734.1,1286.8,733,1285.5,733,1283.7z M734,1283.1h3.2c0-0.8-0.5-1.6-1.5-1.6
				C734.8,1281.5,734.2,1282.1,734,1283.1z"
          />
          <path
            d="M740.5,1286.6h-1v-5.9h1v0.8c0.3-0.5,0.9-0.9,1.7-0.9c0.8,0,1.3,0.4,1.6,1c0.3-0.5,1-1,1.9-1c1.2,0,1.9,0.9,1.9,2.1v3.9
				h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5v3.5h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5V1286.6z"
          />
          <path
            d="M748.8,1283.7c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C750,1286.8,748.8,1285.5,748.8,1283.7z M749.9,1283.1h3.2c0-0.8-0.5-1.6-1.5-1.6
				C750.7,1281.5,750,1282.1,749.9,1283.1z"
          />
          <path
            d="M755.3,1280.8h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L755.3,1280.8L755.3,1280.8z"
          />
          <path
            d="M761.8,1281.6h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L761.8,1281.6L761.8,1281.6z"
          />
        </g>
        <g>
          <path
            d="M722.1,1298.4c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C720.3,1297.5,721,1298.4,722.1,1298.4z"
          />
          <path d="M725.5,1291.1h1.1v1.2h-1.1V1291.1z M726.5,1299.1h-1v-5.9h1V1299.1z" />
          <path
            d="M731.3,1294.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L731.3,1294.2L731.3,1294.2z"
          />
          <path
            d="M734.8,1298.4c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C733,1297.5,733.7,1298.4,734.8,1298.4z"
          />
          <path
            d="M742.9,1299.1h-1v-0.8c-0.3,0.5-0.9,0.9-1.7,0.9c-1.3,0-2-0.9-2-2.1v-3.9h1v3.7c0,0.8,0.4,1.4,1.2,1.4
				c0.7,0,1.5-0.6,1.5-1.5v-3.5h1V1299.1z"
          />
          <path d="M745.6,1299.1h-1v-8.1h1V1299.1z" />
          <path
            d="M746.8,1297.5c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C747.7,1299.2,746.8,1298.6,746.8,1297.5z M750.4,1296.1l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L750.4,1296.1L750.4,1296.1z"
          />
          <path
            d="M756.2,1294.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L756.2,1294.2L756.2,1294.2z"
          />
        </g>
        <g>
          <path
            d="M708.4,1310c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C709.2,1311.7,708.4,1311.1,708.4,1310z M712,1308.6l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L712,1308.6L712,1308.6z"
          />
          <path
            d="M715.8,1310.7v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C716.8,1311.7,716.1,1311.3,715.8,1310.7z M715.7,1308.9c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1308.9z"
          />
          <path
            d="M722.4,1310.7v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C723.4,1311.7,722.8,1311.3,722.4,1310.7z M722.4,1308.9c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1308.9z"
          />
          <path
            d="M731.1,1306.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L731.1,1306.7L731.1,1306.7z"
          />
          <path
            d="M734.6,1311.7c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S736.2,1311.7,734.6,1311.7z M734.6,1310.9
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C732.9,1310,733.5,1310.9,734.6,1310.9z"
          />
          <path
            d="M738.1,1310c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C739,1311.7,738.1,1311.1,738.1,1310z M741.8,1308.6l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L741.8,1308.6L741.8,1308.6z"
          />
          <path
            d="M746.8,1310.8c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C745.1,1310,745.8,1310.8,746.8,1310.8z"
          />
          <path
            d="M750.3,1303.5h1v3c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.5,0.6-1.5,1.5
				v3.5h-1L750.3,1303.5L750.3,1303.5z"
          />
          <path
            d="M756.2,1308.7c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C757.4,1311.7,756.2,1310.5,756.2,1308.7z M757.3,1308h3.2c0-0.8-0.5-1.6-1.5-1.6
				C758.1,1306.5,757.4,1307.1,757.3,1308z"
          />
          <path
            d="M762.8,1309.8c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L762.8,1309.8z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="58">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3171,1802.1c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3150.5,1756.3,3171,1776.8,3171,1802.1"
        />
        <g>
          <path
            d="M3098,1799.6h-1.2v-8.7h3.3c1.8,0,3,0.9,3,2.5c0,1.6-1.2,2.5-3,2.5h-2.2V1799.6z M3100.1,1794.9c1.2,0,1.9-0.5,1.9-1.5
				s-0.7-1.5-1.9-1.5h-2.1v3.1H3100.1z"
          />
          <path
            d="M3106.9,1799.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3109.8,1798.4,3108.6,1799.7,3106.9,1799.7z
				 M3106.9,1798.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3105,1797.8,3105.8,1798.8,3106.9,1798.8z"
          />
          <path
            d="M3112.3,1798.6v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3113.4,1799.7,3112.7,1799.3,3112.3,1798.6z M3112.3,1796.6c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1796.6z"
          />
          <path
            d="M3123.5,1799.6h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1799.6z"
          />
          <path d="M3126.4,1799.6h-1.1v-8.7h1.1V1799.6z" />
          <path
            d="M3127.7,1797.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3128.7,1799.7,3127.7,1799,3127.7,1797.8z M3131.7,1796.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1796.3z"
          />
          <path
            d="M3134.8,1794.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1794.1z"
          />
          <path d="M3138.7,1790.8h1.2v1.3h-1.2V1790.8z M3139.9,1799.6h-1.1v-6.4h1.1V1799.6z" />
          <path
            d="M3144.2,1799.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3147.2,1798.4,3146,1799.7,3144.2,1799.7z
				 M3144.2,1798.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3142.4,1797.8,3143.1,1798.8,3144.2,1798.8
				z"
          />
          <path
            d="M3148.6,1793.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1793.2z"
          />
        </g>
        <g>
          <path d="M3115.3,1804.3v8.7h-1.2v-4h-4.7v4h-1.2v-8.7h1.2v3.7h4.7v-3.7H3115.3z" />
          <path
            d="M3116.7,1809.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3117.9,1813.2,3116.7,1811.9,3116.7,1809.9z M3117.8,1809.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3118.7,1807.5,3118,1808.1,3117.8,1809.2z"
          />
          <path
            d="M3123.2,1811.4c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3124.2,1813.2,3123.2,1812.5,3123.2,1811.4z M3127.2,1809.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1809.8z"
          />
          <path d="M3131.2,1813.1h-1.1v-8.7h1.1V1813.1z" />
          <path
            d="M3133.2,1807.6h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1807.6z"
          />
          <path
            d="M3137.2,1804.3h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1804.3z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="57">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M1867.3,2540.8c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1846.8,2495,1867.3,2515.5,1867.3,2540.8"
        />
        <g>
          <path d="M1800.8,2529.5h1.2v7.7h4.4v1h-5.5V2529.5z" />
          <path
            d="M1807,2535.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1808.3,2538.4,1807,2537,1807,2535.1z M1808.1,2534.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1809,2532.7,1808.3,2533.3,1808.1,2534.4z"
          />
          <path d="M1815.7,2538.3l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H1815.7z" />
          <path
            d="M1819.8,2535.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1821,2538.4,1819.8,2537,1819.8,2535.1z M1820.9,2534.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1821.8,2532.7,1821.1,2533.3,1820.9,2534.4z"
          />
          <path d="M1827.9,2538.3h-1.1v-8.7h1.1V2538.3z" />
          <path
            d="M1836.1,2538.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1839,2537.1,1837.8,2538.4,1836.1,2538.4z
				 M1836.1,2537.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1834.2,2536.5,1835,2537.5,1836.1,2537.5z"
          />
          <path
            d="M1843.2,2530.4c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2530.4z"
          />
        </g>
        <g>
          <path d="M1810.5,2545.4l1.3,4.7l1.3-4.7h1.1l-1.9,6.4h-1l-1.3-4.7l-1.3,4.7h-1l-1.9-6.4h1.1l1.3,4.7l1.3-4.7H1810.5z" />
          <path
            d="M1814.8,2550.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1815.8,2551.9,1814.8,2551.2,1814.8,2550.1z M1818.8,2548.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2548.5z"
          />
          <path
            d="M1821.9,2549.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1821.9,2549.8z"
          />
          <path
            d="M1827.8,2546.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2546.3z"
          />
          <path
            d="M1831.4,2548.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1832.6,2551.9,1831.4,2550.6,1831.4,2548.6z M1832.5,2547.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1833.3,2546.2,1832.7,2546.8,1832.5,2547.9z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="56">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M806,1667.4c0,25.3-20.5,45.8-45.8,45.8s-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			S806,1642.1,806,1667.4"
        />
        <g>
          <path d="M742.2,1649.4v1h-4.5v2.7h3.9v1h-3.9v3h4.5v1h-5.7v-8.7H742.2z" />
          <path d="M745.8,1655.6l-1.8,2.5h-1.2l2.3-3.3l-2.1-3h1.3l1.5,2.2l1.6-2.2h1.2l-2.1,3l2.3,3.3h-1.2L745.8,1655.6z" />
          <path
            d="M750,1652.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6L750,1652.7L750,1652.7z"
          />
          <path
            d="M753.5,1655c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2l1,0.3
				c-0.4,1.1-1.3,1.8-2.6,1.8C754.7,1658.3,753.5,1656.9,753.5,1655z M754.6,1654.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C755.5,1652.6,754.8,1653.2,754.6,1654.3z"
          />
          <path
            d="M760.5,1651.8h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1651.8z"
          />
          <path
            d="M767.6,1652.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6L767.6,1652.7L767.6,1652.7z"
          />
          <path
            d="M777.9,1658.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C780.9,1656.9,779.6,1658.3,777.9,1658.3z
				 M777.9,1657.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C776.1,1656.4,776.8,1657.3,777.9,1657.3z"
          />
          <path
            d="M785,1650.3c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1650.3z"
          />
        </g>
        <g>
          <path
            d="M744.4,1666.4c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0L744.4,1666.4L744.4,1666.4z"
          />
          <path
            d="M748.1,1671.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C751.1,1670.4,749.9,1671.8,748.1,1671.8z
				 M748.1,1670.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C746.3,1669.9,747,1670.8,748.1,1670.8z"
          />
          <path
            d="M755,1671.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C757.9,1670.4,756.7,1671.8,755,1671.8z M755,1670.8
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C753.1,1669.9,753.9,1670.8,755,1670.8z"
          />
          <path
            d="M762.1,1663.8c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1663.8z"
          />
          <path
            d="M763.2,1666.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6L763.2,1666.2L763.2,1666.2z"
          />
          <path
            d="M769.7,1671.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C772.7,1670.4,771.5,1671.8,769.7,1671.8z
				 M769.7,1670.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C767.9,1669.9,768.6,1670.8,769.7,1670.8z"
          />
          <path
            d="M775.2,1670.7v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C776.2,1671.8,775.5,1671.4,775.2,1670.7z M775.1,1668.7c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1668.7z"
          />
        </g>
        <g>
          <path
            d="M742.9,1677.3c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1677.3z"
          />
          <path
            d="M743.3,1683.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C744.3,1685.3,743.3,1684.6,743.3,1683.5z M747.3,1681.9l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4L747.3,1681.9L747.3,1681.9z"
          />
          <path
            d="M753.5,1679.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0L753.5,1679.9L753.5,1679.9z"
          />
          <path
            d="M755.8,1685.2h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				L755.8,1685.2L755.8,1685.2z"
          />
          <path d="M765.3,1676.4h1.2v1.3h-1.2V1676.4z M766.4,1685.2h-1.1v-6.4h1.1V1685.2z" />
          <path
            d="M768.2,1678.8h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1678.8z"
          />
          <path
            d="M777.5,1687.5c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C780.5,1686.5,779.3,1687.5,777.5,1687.5z M777.6,1679.6c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C779.4,1680.3,778.6,1679.6,777.6,1679.6z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="55">
        <path
          style={{ fill: '#33CC61' }}
          className="node"
          d="M2218.2,2231.4c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2197.8,2185.6,2218.2,2206.1,2218.2,2231.4"
        />
        <g>
          <path
            d="M2133.2,2222.1h-1.2v-8.7h3.3c1.8,0,3,0.9,3,2.5c0,1.6-1.2,2.5-3,2.5h-2.2V2222.1z M2135.3,2217.4c1.2,0,1.9-0.5,1.9-1.5
				s-0.7-1.5-1.9-1.5h-2.1v3.1H2135.3z"
          />
          <path
            d="M2142.1,2222.2c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2145.1,2220.9,2143.8,2222.2,2142.1,2222.2z
				 M2142.1,2221.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2140.3,2220.4,2141,2221.3,2142.1,2221.3z"
          />
          <path d="M2147.5,2222.1h-1.1v-8.7h1.1V2222.1z" />
          <path d="M2149.3,2213.4h1.2v1.3h-1.2V2213.4z M2150.5,2222.1h-1.1v-6.4h1.1V2222.1z" />
          <path
            d="M2154.9,2221.3c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2152.9,2220.4,2153.7,2221.3,2154.9,2221.3z"
          />
          <path
            d="M2159,2224.4c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C2160.6,2223.8,2160.1,2224.4,2159,2224.4z"
          />
          <path
            d="M2168.8,2220.1c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2168.8,2220.1z"
          />
          <path
            d="M2174.7,2216.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2216.7z"
          />
          <path d="M2178.6,2213.4h1.2v1.3h-1.2V2213.4z M2179.8,2222.1h-1.1v-6.4h1.1V2222.1z" />
          <path
            d="M2182.7,2222.1h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V2222.1z"
          />
          <path
            d="M2197.3,2222.1h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2222.1z"
          />
          <path d="M2200.2,2222.1h-1.1v-8.7h1.1V2222.1z" />
          <path
            d="M2207,2222.1h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2222.1z"
          />
          <path
            d="M2209,2220.1c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2209,2220.1z"
          />
        </g>
        <g>
          <path
            d="M2135.6,2227.8c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2227.8z"
          />
          <path
            d="M2139.1,2235.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2142.1,2234.4,2140.9,2235.8,2139.1,2235.8z
				 M2139.1,2234.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2137.3,2233.9,2138,2234.8,2139.1,2234.8z"
          />
          <path
            d="M2146.8,2230.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2230.3z"
          />
          <path
            d="M2152,2233.7c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2152,2233.7z"
          />
          <path
            d="M2162.8,2235.6h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2235.6z"
          />
          <path
            d="M2164.7,2233.7c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2164.7,2233.7z"
          />
          <path
            d="M2170.7,2230.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2230.2z"
          />
          <path
            d="M2174.1,2233.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2175.1,2235.7,2174.1,2235.1,2174.1,2233.9z M2178.1,2232.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2232.4z"
          />
          <path d="M2181,2226.9h1.2v1.3h-1.2V2226.9z M2182.1,2235.6h-1.1v-6.4h1.1V2235.6z" />
          <path
            d="M2184,2229.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2229.3z"
          />
          <path
            d="M2190.3,2233.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2191.2,2235.7,2190.3,2235.1,2190.3,2233.9z M2194.3,2232.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2232.4z"
          />
          <path
            d="M2198.3,2234.7v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2199.4,2235.8,2198.7,2235.3,2198.3,2234.7z M2198.3,2232.7c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2232.7z"
          />
          <path d="M2205.5,2235.6h-1.1v-8.7h1.1V2235.6z" />
          <path
            d="M2206.9,2232.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2208.1,2235.8,2206.9,2234.4,2206.9,2232.4z M2208,2231.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2208.9,2230.1,2208.2,2230.7,2208,2231.8z"
          />
        </g>
        <g>
          <path
            d="M2139.3,2241.3c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2241.3z"
          />
          <path
            d="M2142.8,2249.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2145.7,2247.9,2144.5,2249.3,2142.8,2249.3z
				 M2142.8,2248.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2140.9,2247.4,2141.7,2248.3,2142.8,2248.3
				z"
          />
          <path
            d="M2149.6,2249.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2152.6,2247.9,2151.4,2249.3,2149.6,2249.3z
				 M2149.6,2248.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2147.8,2247.4,2148.5,2248.3,2149.6,2248.3
				z"
          />
          <path
            d="M2153.5,2246c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2154.6,2249.3,2153.5,2247.8,2153.5,2246z M2156.5,2243.6c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C2158.3,2244.3,2157.4,2243.6,2156.5,2243.6z"
          />
          <path
            d="M2165.2,2247.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2165.2,2247.2z"
          />
          <path
            d="M2171.4,2251.4c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C2173,2250.8,2172.5,2251.4,2171.4,2251.4z"
          />
          <path
            d="M2177.3,2247.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2177.3,2247.2z"
          />
          <path
            d="M2183.2,2243.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2243.7z"
          />
          <path
            d="M2186.7,2246c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2188,2249.3,2186.7,2247.9,2186.7,2246z M2187.8,2245.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2188.7,2243.6,2188,2244.2,2187.8,2245.3z"
          />
          <path
            d="M2194.8,2249.1h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V2249.1z"
          />
          <path
            d="M2204.5,2247.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2204.5,2247.2z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="54">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1026.4,1440.6c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1006,1394.9,1026.4,1415.4,1026.4,1440.6"
        />
        <g>
          <path d="M962.3,1411.5l3.1,8.1h-1.1l-0.8-2.1h-3.6l-0.8,2.1H958l3.1-8.1H962.3z M960.2,1416.5h2.9l-1.5-3.8L960.2,1416.5z" />
          <path
            d="M967.4,1418.7v0.9h-1v-8.1h1v3c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C968.4,1419.7,967.8,1419.3,967.4,1418.7z M967.4,1416.9c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1416.9z"
          />
          <path d="M973.1,1411.5h1.1v1.2h-1.1V1411.5z M974.1,1419.6h-1v-5.9h1V1419.6z" />
          <path d="M976.8,1419.6h-1v-8.1h1V1419.6z" />
          <path d="M978.4,1411.5h1.1v1.2h-1.1V1411.5z M979.5,1419.6h-1v-5.9h1V1419.6z" />
          <path
            d="M981.3,1414.6h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L981.3,1414.6L981.3,1414.6z"
          />
          <path
            d="M985.4,1421.7c-0.2,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,0.9-0.7l0.3-0.7l-2.2-5.7h1.1l1.7,4.5
				l1.6-4.5h1.1l-2.5,6.5C986.8,1421.1,986.4,1421.7,985.4,1421.7z"
          />
          <path
            d="M994.4,1414.6h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L994.4,1414.6L994.4,1414.6z"
          />
          <path
            d="M1000.4,1419.7c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S1002,1419.7,1000.4,1419.7z M1000.4,1418.8
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C998.7,1418,999.4,1418.8,1000.4,1418.8z"
          />
        </g>
        <g>
          <path
            d="M956.8,1431.3c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C955.1,1430.5,955.8,1431.3,956.8,1431.3z"
          />
          <path
            d="M962.6,1432.2c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S964.2,1432.2,962.6,1432.2z M962.6,1431.3
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C960.9,1430.5,961.6,1431.3,962.6,1431.3z"
          />
          <path
            d="M969,1432.2c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S970.6,1432.2,969,1432.2z M969,1431.3
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C967.3,1430.5,967.9,1431.3,969,1431.3z"
          />
          <path
            d="M976,1427.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L976,1427.2L976,1427.2z"
          />
          <path
            d="M976.7,1429.1c0-1.7,0.9-3.1,2.6-3.1c0.8,0,1.5,0.4,1.8,1v-3h1v8.1h-1v-0.9c-0.3,0.6-1,1-1.8,1
				C977.7,1432.2,976.7,1430.8,976.7,1429.1z M979.5,1427c-1.1,0-1.7,0.9-1.7,2.2c0,1.3,0.6,2.2,1.7,2.2c0.8,0,1.7-0.6,1.7-2v-0.4
				C981.1,1427.6,980.3,1427,979.5,1427z"
          />
          <path d="M983.7,1424h1.1v1.2h-1.1V1424z M984.8,1432.1h-1v-5.9h1V1432.1z" />
          <path
            d="M986.5,1426.2h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L986.5,1426.2L986.5,1426.2z"
          />
          <path
            d="M992.4,1430.5c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C993.2,1432.2,992.4,1431.5,992.4,1430.5z M996,1429l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L996,1429L996,1429z"
          />
          <path
            d="M998.9,1427.1H998v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L998.9,1427.1L998.9,1427.1z"
          />
          <path
            d="M1002.1,1429.1c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1
				l0.9,0.3c-0.4,1-1.2,1.7-2.4,1.7C1003.3,1432.2,1002.1,1430.9,1002.1,1429.1z M1003.2,1428.5h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1004,1426.9,1003.3,1427.5,1003.2,1428.5z"
          />
        </g>
        <g>
          <path
            d="M961,1443.7v0.9h-1v-8.1h1v3c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C962,1444.7,961.3,1444.3,961,1443.7z M961,1441.8c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1441.8z"
          />
          <path
            d="M966.3,1441.6c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C967.4,1444.7,966.3,1443.4,966.3,1441.6z M967.3,1441h3.2c0-0.8-0.5-1.6-1.5-1.6
				C968.1,1439.4,967.5,1440,967.3,1441z"
          />
          <path
            d="M972.9,1439.5H972v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L972.9,1439.5L972.9,1439.5z"
          />
          <path d="M980.2,1438.7l1.2,4.4l1.2-4.4h1l-1.8,5.9H981l-1.2-4.4l-1.2,4.4h-0.9l-1.8-5.9h1l1.2,4.4l1.2-4.4H980.2z" />
          <path
            d="M984.2,1441.6c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C985.3,1444.7,984.2,1443.4,984.2,1441.6z M985.2,1441h3.2c0-0.8-0.5-1.6-1.5-1.6
				C986.1,1439.4,985.4,1440,985.2,1441z"
          />
          <path
            d="M990.3,1441.6c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C991.4,1444.7,990.3,1443.4,990.3,1441.6z M991.3,1441h3.2c0-0.8-0.5-1.6-1.5-1.6
				C992.1,1439.4,991.5,1440,991.3,1441z"
          />
          <path
            d="M996.7,1438.7h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L996.7,1438.7L996.7,1438.7z"
          />
        </g>
        <g>
          <path
            d="M963,1456.2v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C964,1457.1,963.4,1456.7,963,1456.2z M963,1454.3c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1454.3z"
          />
          <path
            d="M971.7,1452.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L971.7,1452.1L971.7,1452.1z"
          />
          <path d="M972.8,1448.9h1.1v1.2h-1.1V1448.9z M973.8,1457h-1v-5.9h1V1457z" />
          <path
            d="M976.5,1457h-1v-5.9h1v0.8c0.3-0.5,0.9-0.9,1.7-0.9c0.8,0,1.3,0.4,1.6,1c0.3-0.5,1-1,1.9-1c1.2,0,1.9,0.9,1.9,2.1v3.9h-1
				v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5v3.5h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5V1457z"
          />
          <path
            d="M984.8,1455.4c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C985.7,1457.1,984.8,1456.5,984.8,1455.4z M988.5,1454l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L988.5,1454L988.5,1454z"
          />
          <path
            d="M994.3,1452.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L994.3,1452.1L994.3,1452.1z"
          />
          <path
            d="M995.7,1459.1c-0.2,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,0.9-0.7l0.3-0.7l-2.2-5.7h1.1l1.7,4.5
				l1.6-4.5h1.1l-2.5,6.5C997.2,1458.5,996.7,1459.1,995.7,1459.1z"
          />
        </g>
        <g>
          <path
            d="M956.7,1468.6v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C957.7,1469.6,957.1,1469.2,956.7,1468.6z M956.7,1466.8c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1466.8z"
          />
          <path
            d="M965.4,1464.6c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L965.4,1464.6L965.4,1464.6z"
          />
          <path
            d="M968.9,1469.6c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C971.6,1468.4,970.5,1469.6,968.9,1469.6z
				 M968.9,1468.7c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C967.1,1467.9,967.8,1468.7,968.9,1468.7z"
          />
          <path
            d="M972.5,1466.6c0-1.7,0.9-3.1,2.6-3.1c0.8,0,1.5,0.4,1.8,1v-3h1v8.1h-1v-0.9c-0.3,0.6-1,1-1.8,1
				C973.4,1469.6,972.5,1468.2,972.5,1466.6z M975.2,1464.4c-1.1,0-1.7,0.9-1.7,2.2c0,1.3,0.6,2.2,1.7,2.2c0.8,0,1.7-0.6,1.7-2v-0.4
				C976.9,1465,976,1464.4,975.2,1464.4z"
          />
          <path
            d="M984.2,1469.5h-1v-0.8c-0.3,0.5-0.9,0.9-1.7,0.9c-1.3,0-2-0.9-2-2.1v-3.9h1v3.7c0,0.8,0.4,1.4,1.2,1.4
				c0.7,0,1.5-0.5,1.5-1.5v-3.5h1V1469.5z"
          />
          <path
            d="M988.2,1468.7c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C986.5,1467.9,987.2,1468.7,988.2,1468.7z"
          />
          <path
            d="M991.3,1466.6c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C992.4,1469.6,991.3,1468.4,991.3,1466.6z M992.3,1465.9h3.2c0-0.8-0.5-1.6-1.5-1.6
				C993.1,1464.4,992.5,1464.9,992.3,1465.9z"
          />
          <path
            d="M1000.8,1464.6c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L1000.8,1464.6L1000.8,1464.6z"
          />
          <path
            d="M1002,1467.7c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1002,1467.7z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="53">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M977.3,1170c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C956.9,1124.3,977.3,1144.8,977.3,1170"
        />
        <g>
          <path
            d="M902,1158.8h2.8c2.7,0,4.3,1.7,4.3,4.4c0,2.7-1.7,4.4-4.3,4.4H902V1158.8z M904.8,1166.5c2,0,3.2-1.3,3.2-3.4
				c0-2-1.2-3.4-3.2-3.4h-1.6v6.7H904.8z"
          />
          <path d="M910.4,1158.8h1.2v1.3h-1.2V1158.8z M911.6,1167.5h-1.1v-6.4h1.1V1167.5z" />
          <path
            d="M913.5,1165.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L913.5,1165.5z"
          />
          <path
            d="M920.4,1166.6v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C921.5,1167.6,920.8,1167.2,920.4,1166.6z M920.4,1164.6c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1164.6z"
          />
          <path
            d="M929,1167.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C932,1166.3,930.8,1167.6,929,1167.6z M929,1166.7
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C927.2,1165.8,927.9,1166.7,929,1166.7z"
          />
          <path
            d="M933.5,1165.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L933.5,1165.5z"
          />
          <path
            d="M938.8,1165.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C939.7,1167.6,938.8,1166.9,938.8,1165.8z M942.8,1164.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4L942.8,1164.3L942.8,1164.3z"
          />
          <path d="M946.8,1167.5h-1.1v-8.7h1.1V1167.5z" />
          <path
            d="M955,1167.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C957.9,1166.3,956.7,1167.6,955,1167.6z M955,1166.7
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C953.1,1165.8,953.9,1166.7,955,1166.7z"
          />
          <path
            d="M962.1,1159.6c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1159.6z"
          />
        </g>
        <g>
          <path
            d="M912.7,1181h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				L912.7,1181L912.7,1181z"
          />
          <path
            d="M921.7,1179.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C922.6,1181.1,921.7,1180.5,921.7,1179.3z M925.7,1177.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4L925.7,1177.8L925.7,1177.8z"
          />
          <path
            d="M928.6,1174.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1174.7z"
          />
          <path
            d="M940.5,1181h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1L940.5,1181L940.5,1181z"
          />
          <path
            d="M945.6,1175.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0L945.6,1175.7L945.6,1175.7z"
          />
          <path
            d="M946.4,1177.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C947.6,1181.2,946.4,1179.8,946.4,1177.9z M947.5,1177.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C948.4,1175.5,947.7,1176.1,947.5,1177.2z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="52">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M2041.1,794.2c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2020.6,748.4,2041.1,768.9,2041.1,794.2"
        />
        <g>
          <path d="M1966,773.1h-1.2v-8.7h1.2V773.1z" />
          <path
            d="M1967.8,766.8h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V766.8z"
          />
          <path
            d="M1977.3,772.3c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1975.4,771.4,1976.1,772.3,1977.3,772.3z"
          />
          <path d="M1982.1,773.1h-1.1v-8.7h1.1V773.1z" />
          <path
            d="M1989,773.1h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V773.1z"
          />
          <path
            d="M1990.9,771.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1990.9,771.2z"
          />
          <path d="M1996.7,764.4h1.2v1.3h-1.2V764.4z M1997.8,773.1h-1.1v-6.4h1.1V773.1z" />
          <path
            d="M2002.1,773.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2005.1,771.9,2003.9,773.3,2002.1,773.3z
				 M2002.1,772.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2000.3,771.4,2001,772.3,2002.1,772.3z"
          />
          <path
            d="M2006.5,766.8h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V766.8z"
          />
          <path
            d="M2019.7,773.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2022.7,771.9,2021.5,773.3,2019.7,773.3z
				 M2019.7,772.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2017.9,771.4,2018.6,772.3,2019.7,772.3z"
          />
          <path
            d="M2026.8,765.3c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V765.3z"
          />
        </g>
        <g>
          <path
            d="M1960.6,784.7c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1960.6,784.7z"
          />
          <path
            d="M1971.4,786.6h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V786.6z"
          />
          <path
            d="M1973.4,784.7c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1973.4,784.7z"
          />
          <path
            d="M1979.3,781.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V781.2z"
          />
          <path
            d="M1982.8,784.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1983.7,786.8,1982.8,786.1,1982.8,784.9z M1986.7,783.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V783.4z"
          />
          <path d="M1989.6,777.9h1.2v1.3h-1.2V777.9z M1990.7,786.6h-1.1v-6.4h1.1V786.6z" />
          <path
            d="M1992.6,780.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V780.3z"
          />
          <path
            d="M1998.9,784.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1999.9,786.8,1998.9,786.1,1998.9,784.9z M2002.9,783.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V783.4z"
          />
          <path
            d="M2006.9,785.7v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2008,786.8,2007.3,786.4,2006.9,785.7z M2006.9,783.7c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V783.7z"
          />
          <path d="M2013,777.9h1.2v1.3h-1.2V777.9z M2014.2,786.6h-1.1v-6.4h1.1V786.6z" />
          <path d="M2017.1,786.6h-1.1v-8.7h1.1V786.6z" />
          <path d="M2018.8,777.9h1.2v1.3h-1.2V777.9z M2020,786.6h-1.1v-6.4h1.1V786.6z" />
          <path
            d="M2022,781.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V781.2z"
          />
          <path
            d="M2026.3,788.9c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C2027.9,788.3,2027.4,788.9,2026.3,788.9z"
          />
        </g>
        <g>
          <path
            d="M1972.9,799.3c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1971,798.4,1971.8,799.3,1972.9,799.3z"
          />
          <path
            d="M1979.9,794.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V794.9z"
          />
          <path d="M1981.1,791.4h1.2v1.3h-1.2V791.4z M1982.2,800.2h-1.1v-6.4h1.1V800.2z" />
          <path
            d="M1984.2,794.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V794.7z"
          />
          <path
            d="M1987.8,797c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2l1,0.3
				c-0.4,1.1-1.3,1.8-2.6,1.8C1989,800.3,1987.8,798.9,1987.8,797z M1988.9,796.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1989.8,794.6,1989.1,795.2,1988.9,796.3z"
          />
          <path
            d="M1998.1,794.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V794.9z"
          />
          <path d="M1999.2,791.4h1.2v1.3h-1.2V791.4z M2000.4,800.2h-1.1v-6.4h1.1V800.2z" />
          <path
            d="M2001.7,798.4c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2002.6,800.3,2001.7,799.6,2001.7,798.4z M2005.6,796.9l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V796.9z"
          />
          <path d="M2012.4,791.4h1.2v1.3h-1.2V791.4z M2013.5,800.2h-1.1v-6.4h1.1V800.2z" />
          <path
            d="M2015.4,793.8h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V793.8z"
          />
        </g>
        <g>
          <path
            d="M1986.9,805.8c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V805.8z"
          />
          <path
            d="M1990.4,813.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1993.4,812.5,1992.1,813.8,1990.4,813.8z
				 M1990.4,812.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1988.6,811.9,1989.3,812.9,1990.4,812.9z"
          />
          <path
            d="M1997.3,813.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2000.2,812.5,1999,813.8,1997.3,813.8z
				 M1997.3,812.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1995.4,811.9,1996.1,812.9,1997.3,812.9z"
          />
          <path
            d="M2001.2,810.5c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2002.2,813.8,2001.2,812.3,2001.2,810.5z M2004.1,808.2c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C2005.9,808.8,2005,808.2,2004.1,808.2z"
          />
        </g>
        <g>
          <path
            d="M1967.2,824.6v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1968.3,825.6,1967.6,825.2,1967.2,824.6z M1967.2,822.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V822.5z"
          />
          <path
            d="M1976.6,820.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V820.2z"
          />
          <path
            d="M1980.4,825.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1983.3,824.3,1982.1,825.6,1980.4,825.6z
				 M1980.4,824.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1978.5,823.8,1979.3,824.7,1980.4,824.7z"
          />
          <path
            d="M1987.3,824.7c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1985.4,823.8,1986.1,824.7,1987.3,824.7z"
          />
          <path
            d="M1990.6,822.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1991.8,825.6,1990.6,824.3,1990.6,822.3z M1991.7,821.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1992.6,819.9,1991.9,820.6,1991.7,821.6z"
          />
          <path
            d="M1997.8,823.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1997.8,823.5z"
          />
          <path
            d="M2003.7,823.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2003.7,823.5z"
          />
          <path d="M2009.4,816.8h1.2v1.3h-1.2V816.8z M2010.5,825.5h-1.1v-6.4h1.1V825.5z" />
          <path
            d="M2012.4,819.1h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V819.1z"
          />
          <path
            d="M2021.6,827.8c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2024.6,826.8,2023.4,827.8,2021.6,827.8z M2021.8,820c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C2023.5,820.6,2022.7,820,2021.8,820z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="51">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1136,1019.6c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1115.5,973.9,1136,994.4,1136,1019.6"
        />
        <g>
          <path
            d="M1068.2,1007.8c0.5,1,1.5,1.7,2.7,1.7s2-0.6,2-1.6c0-1.1-1-1.2-2.3-1.6c-1.4-0.3-2.7-0.7-2.7-2.3c0-1.5,1.3-2.5,3-2.5
				c1.5,0,2.6,0.7,3.1,1.7l-0.9,0.7c-0.4-0.8-1.1-1.4-2.3-1.4c-1.1,0-1.8,0.6-1.8,1.4c0,0.9,0.8,1.1,2,1.4c1.4,0.3,3,0.6,3,2.5
				c0,1.6-1.4,2.7-3.2,2.7c-1.5,0-3-0.8-3.6-1.9L1068.2,1007.8z"
          />
          <path
            d="M1075.3,1001.6h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1001.6z"
          />
          <path
            d="M1081.7,1008.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1082.6,1010.5,1081.7,1009.8,1081.7,1008.6z M1085.6,1007.1l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1007.1z"
          />
          <path
            d="M1091.9,1005.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1005.1z"
          />
          <path
            d="M1092.6,1007.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1093.9,1010.5,1092.6,1009.1,1092.6,1007.2z M1093.7,1006.5h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1094.6,1004.8,1093.9,1005.4,1093.7,1006.5z"
          />
          <path
            d="M1106,1010.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1109,1009.1,1107.8,1010.5,1106,1010.5z
				 M1106,1009.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1104.2,1008.6,1104.9,1009.5,1106,1009.5z"
          />
          <path
            d="M1113.1,1002.5c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1002.5z"
          />
        </g>
        <g>
          <path
            d="M1049.6,1022.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1050.5,1024,1049.6,1023.3,1049.6,1022.1z M1053.5,1020.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1020.6z"
          />
          <path
            d="M1056.5,1017.5h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1017.5z"
          />
          <path d="M1063.3,1015.1h1.2v1.3h-1.2V1015.1z M1064.4,1023.9h-1.1v-6.4h1.1V1023.9z" />
          <path
            d="M1067.3,1023.9h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1023.9z"
          />
          <path
            d="M1076.3,1022.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1077.3,1024,1076.3,1023.3,1076.3,1022.1z M1080.3,1020.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1020.6z"
          />
          <path d="M1084.3,1023.9h-1.1v-8.7h1.1V1023.9z" />
          <path
            d="M1092.8,1016c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1016z"
          />
          <path
            d="M1093.2,1022.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1094.2,1024,1093.2,1023.3,1093.2,1022.1z M1097.2,1020.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1020.6z"
          />
          <path
            d="M1103.4,1018.6c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1018.6z"
          />
          <path
            d="M1105.7,1023.9h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1023.9z"
          />
          <path d="M1115.2,1015.1h1.2v1.3h-1.2V1015.1z M1116.3,1023.9h-1.1v-6.4h1.1V1023.9z" />
          <path
            d="M1118.1,1017.5h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1017.5z"
          />
          <path
            d="M1127.4,1026.2c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C1130.4,1025.1,1129.2,1026.2,1127.4,1026.2z M1127.6,1018.3c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2
				c0.9,0,1.8-0.7,1.8-2.1v-0.2C1129.3,1019,1128.5,1018.3,1127.6,1018.3z"
          />
        </g>
        <g>
          <path d="M1054.7,1028.6h1.2v1.3h-1.2V1028.6z M1055.8,1037.4h-1.1v-6.4h1.1V1037.4z" />
          <path
            d="M1057.6,1031h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1031z"
          />
          <path
            d="M1067.9,1035.7c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1068.8,1037.5,1067.9,1036.8,1067.9,1035.7z M1071.8,1034.1l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1034.1z"
          />
          <path
            d="M1077.2,1039.7c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C1080.1,1038.7,1078.9,1039.7,1077.2,1039.7z M1077.3,1031.9c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2
				c0.9,0,1.8-0.7,1.8-2.1v-0.2C1079.1,1032.5,1078.2,1031.9,1077.3,1031.9z"
          />
          <path
            d="M1085.3,1032.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1032.1z"
          />
          <path d="M1086.4,1028.6h1.2v1.3h-1.2V1028.6z M1087.6,1037.4h-1.1v-6.4h1.1V1037.4z" />
          <path
            d="M1092,1036.5c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1090,1035.6,1090.8,1036.5,1092,1036.5z"
          />
          <path
            d="M1100.8,1037.4h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1037.4z"
          />
          <path d="M1103.7,1037.4h-1.1v-8.7h1.1V1037.4z" />
          <path
            d="M1105.7,1031.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1031.9z"
          />
          <path
            d="M1114.7,1037.4h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1037.4z"
          />
          <path
            d="M1119.8,1032.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1032.1z"
          />
          <path
            d="M1120.6,1034.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1121.8,1037.5,1120.6,1036.2,1120.6,1034.2z M1121.7,1033.5h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1122.5,1031.8,1121.9,1032.4,1121.7,1033.5z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="50">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3909.5,890.5c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.7,45.8-45.7
			C3889,844.8,3909.5,865.2,3909.5,890.5"
        />
        <g>
          <path
            d="M3827.2,888.2h-1.1v-8.1h3.1c1.7,0,2.8,0.8,2.8,2.3c0,1.5-1.1,2.3-2.8,2.3h-2V888.2z M3829.2,883.9
				c1.1,0,1.8-0.5,1.8-1.4s-0.6-1.4-1.8-1.4h-1.9v2.8H3829.2z"
          />
          <path
            d="M3832.7,885.2c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C3833.8,888.3,3832.7,887,3832.7,885.2z M3833.7,884.6h3.2c0-0.8-0.5-1.6-1.5-1.6
				C3834.5,883.1,3833.9,883.6,3833.7,884.6z"
          />
          <path
            d="M3842.2,883.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0V883.3z
				"
          />
          <path
            d="M3845.7,887.4c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C3843.9,886.6,3844.7,887.4,3845.7,887.4z"
          />
          <path
            d="M3848.8,885.2c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C3849.9,888.3,3848.8,887,3848.8,885.2z M3849.8,884.6h3.2c0-0.8-0.5-1.6-1.5-1.6
				C3850.6,883.1,3850,883.6,3849.8,884.6z"
          />
          <path d="M3855.2,880.1h1.1v1.2h-1.1V880.1z M3856.2,888.2h-1v-5.9h1V888.2z" />
          <path d="M3859.4,888.2l-2.2-5.9h1.1l1.7,4.6l1.6-4.6h1.1l-2.2,5.9H3859.4z" />
          <path
            d="M3863.2,885.2c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C3864.3,888.3,3863.2,887,3863.2,885.2z M3864.2,884.6h3.2c0-0.8-0.5-1.6-1.5-1.6
				C3865.1,883.1,3864.4,883.6,3864.2,884.6z"
          />
          <path
            d="M3869.3,885.2c0-1.7,0.9-3.1,2.6-3.1c0.8,0,1.5,0.4,1.8,1v-3h1v8.1h-1v-0.9c-0.3,0.6-1,1-1.8,1
				C3870.2,888.3,3869.3,886.9,3869.3,885.2z M3872,883.1c-1.1,0-1.7,0.9-1.7,2.2c0,1.3,0.6,2.2,1.7,2.2c0.8,0,1.7-0.6,1.7-2V885
				C3873.7,883.7,3872.9,883.1,3872,883.1z"
          />
          <path d="M3880.9,888.2h-1v-8.1h1V888.2z" />
          <path
            d="M3882.2,885.2c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C3883.3,888.3,3882.2,887,3882.2,885.2z M3883.2,884.6h3.2c0-0.8-0.5-1.6-1.5-1.6
				C3884,883.1,3883.4,883.6,3883.2,884.6z"
          />
          <path d="M3890.2,888.2l-2.2-5.9h1.1l1.7,4.6l1.6-4.6h1.1l-2.2,5.9H3890.2z" />
          <path
            d="M3893.9,885.2c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C3895.1,888.3,3893.9,887,3893.9,885.2z M3895,884.6h3.2c0-0.8-0.5-1.6-1.5-1.6
				C3895.8,883.1,3895.2,883.6,3895,884.6z"
          />
          <path d="M3901.4,888.2h-1v-8.1h1V888.2z" />
        </g>
        <g>
          <path
            d="M3831.9,900.8c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S3833.5,900.8,3831.9,900.8z M3831.9,899.9
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C3830.2,899.1,3830.9,899.9,3831.9,899.9z"
          />
          <path
            d="M3838.5,893.4c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0V893.4z"
          />
          <path
            d="M3845.5,893.4c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0V893.4z"
          />
          <path
            d="M3848.7,900.8c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S3850.3,900.8,3848.7,900.8z M3848.7,899.9
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C3847,899.1,3847.7,899.9,3848.7,899.9z"
          />
          <path
            d="M3855,900.8c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S3856.6,900.8,3855,900.8z M3855,899.9
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C3853.3,899.1,3854,899.9,3855,899.9z"
          />
          <path
            d="M3858.6,897.7c0-1.7,0.9-3.1,2.6-3.1c0.8,0,1.5,0.4,1.8,1v-3h1v8.1h-1v-0.9c-0.3,0.6-1,1-1.8,1
				C3859.6,900.8,3858.6,899.4,3858.6,897.7z M3861.4,895.6c-1.1,0-1.7,0.9-1.7,2.2c0,1.3,0.6,2.2,1.7,2.2c0.8,0,1.7-0.6,1.7-2v-0.4
				C3863,896.1,3862.2,895.6,3861.4,895.6z"
          />
          <path
            d="M3869.4,898.8c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L3869.4,898.8z"
          />
          <path
            d="M3874.3,899.1c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C3875.1,900.8,3874.3,900.1,3874.3,899.1z M3877.9,897.6l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V897.6z"
          />
          <path
            d="M3883.2,893.4c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0V893.4z"
          />
          <path
            d="M3883.7,897.7c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C3884.8,900.8,3883.7,899.5,3883.7,897.7z M3884.7,897.1h3.2c0-0.8-0.5-1.6-1.5-1.6
				C3885.5,895.5,3884.9,896.1,3884.7,897.1z"
          />
          <path
            d="M3890.3,895.6h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V895.6z"
          />
          <path
            d="M3894.3,902.7c-0.2,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,0.9-0.7l0.3-0.7l-2.2-5.7h1.1l1.7,4.5
				l1.6-4.5h1.1l-2.5,6.5C3895.8,902.2,3895.3,902.7,3894.3,902.7z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="49">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3764.6,889.5c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3744.1,843.8,3764.6,864.3,3764.6,889.5"
        />
        <g>
          <path d="M3678.5,887v-8.7h5.6v1h-4.5v2.8h3.8v1h-3.8v3.9L3678.5,887L3678.5,887z" />
          <path
            d="M3688,887.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3691,885.8,3689.8,887.1,3688,887.1z M3688,886.2
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3686.2,885.3,3686.9,886.2,3688,886.2z"
          />
          <path
            d="M3694.9,887.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3697.8,885.8,3696.6,887.1,3694.9,887.1z M3694.9,886.2
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3693,885.3,3693.8,886.2,3694.9,886.2z"
          />
          <path
            d="M3698.8,883.8c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3699.8,887.1,3698.8,885.6,3698.8,883.8z M3701.8,881.5c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C3703.6,882.1,3702.7,881.5,3701.8,881.5z"
          />
          <path
            d="M3710.4,885c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3710.4,885z"
          />
          <path
            d="M3717.3,886.1v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3718.4,887.1,3717.7,886.7,3717.3,886.1z M3717.3,884.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V884.1z"
          />
          <path
            d="M3723,883.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2l1,0.3
				c-0.4,1.1-1.3,1.8-2.6,1.8C3724.2,887.1,3723,885.8,3723,883.8z M3724.1,883.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3725,881.4,3724.3,882.1,3724.1,883.2z"
          />
          <path
            d="M3730,880.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V880.6z"
          />
          <path
            d="M3736.4,883.8c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3737.4,887.1,3736.4,885.6,3736.4,883.8z M3739.4,881.5c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C3741.2,882.1,3740.3,881.5,3739.4,881.5z"
          />
          <path d="M3744,878.3h1.2v1.3h-1.2V878.3z M3745.2,887h-1.1v-6.4h1.1V887z" />
          <path
            d="M3747,880.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V880.6z"
          />
          <path
            d="M3756.3,889.3c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3759.2,888.3,3758,889.3,3756.3,889.3z M3756.4,881.5c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C3758.2,882.2,3757.3,881.5,3756.4,881.5z"
          />
        </g>
        <g>
          <path
            d="M3703.9,899.6v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3705,900.6,3704.3,900.2,3703.9,899.6z M3703.9,897.6c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V897.6z"
          />
          <path
            d="M3712.5,900.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3715.5,899.3,3714.3,900.6,3712.5,900.6z M3712.5,899.7
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3710.7,898.8,3711.4,899.7,3712.5,899.7z"
          />
          <path d="M3720.8,894.1l1.3,4.7l1.3-4.7h1.1l-1.9,6.4h-1l-1.3-4.7l-1.3,4.7h-1l-1.9-6.4h1.1l1.3,4.7l1.3-4.7H3720.8z" />
          <path
            d="M3725.2,897.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3726.4,900.6,3725.2,899.3,3725.2,897.3z M3726.3,896.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3727.2,895,3726.5,895.6,3726.3,896.7z"
          />
          <path
            d="M3735.5,895.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V895.2z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="48">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3473.8,1798.4c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3453.3,1752.7,3473.8,1773.2,3473.8,1798.4"
        />
        <g>
          <path
            d="M3401.8,1787.2h2.8c2.7,0,4.3,1.7,4.3,4.4c0,2.7-1.7,4.4-4.3,4.4h-2.8V1787.2z M3404.6,1794.9c2,0,3.2-1.3,3.2-3.4
				c0-2-1.2-3.4-3.2-3.4h-1.6v6.7H3404.6z"
          />
          <path
            d="M3409.8,1792.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3411,1796,3409.8,1794.7,3409.8,1792.7z M3410.9,1792.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3411.8,1790.4,3411.1,1791,3410.9,1792.1z"
          />
          <path
            d="M3419.3,1798.2c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3422.2,1797.2,3421,1798.2,3419.3,1798.2z M3419.4,1790.4c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C3421.1,1791.1,3420.3,1790.4,3419.4,1790.4z"
          />
          <path
            d="M3427.3,1790.6c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1790.6z"
          />
          <path
            d="M3428.1,1792.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3429.3,1796,3428.1,1794.7,3428.1,1792.7z M3429.2,1792.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3430.1,1790.4,3429.4,1791,3429.2,1792.1z"
          />
          <path
            d="M3434.7,1792.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3435.9,1796,3434.7,1794.7,3434.7,1792.7z M3435.8,1792.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3436.7,1790.4,3436,1791,3435.8,1792.1z"
          />
          <path
            d="M3448.1,1796c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3451,1794.7,3449.8,1796,3448.1,1796z M3448.1,1795.1
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3446.2,1794.2,3446.9,1795.1,3448.1,1795.1z"
          />
          <path
            d="M3455.2,1788c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1788z"
          />
        </g>
        <g>
          <path
            d="M3389.8,1808.5v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3390.9,1809.6,3390.2,1809.1,3389.8,1808.5z M3389.8,1806.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1806.5z"
          />
          <path
            d="M3399.2,1804.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1804.1z"
          />
          <path
            d="M3402.9,1809.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3405.9,1808.2,3404.7,1809.6,3402.9,1809.6z
				 M3402.9,1808.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3401.1,1807.7,3401.8,1808.6,3402.9,1808.6
				z"
          />
          <path d="M3407,1805.4h3.3v1h-3.3V1805.4z" />
          <path
            d="M3412,1807.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3412,1807.5z"
          />
          <path
            d="M3422.8,1809.4h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1809.4z"
          />
          <path
            d="M3425.7,1809.4h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1809.4z"
          />
          <path
            d="M3434.8,1806.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3436,1809.6,3434.8,1808.2,3434.8,1806.3z M3435.9,1805.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3436.8,1803.9,3436.1,1804.5,3435.9,1805.6z"
          />
          <path
            d="M3445.1,1804.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1804.1z"
          />
          <path
            d="M3446.4,1807.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3446.4,1807.5z"
          />
          <path
            d="M3452.2,1800.7h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1800.7z"
          />
          <path d="M3459,1800.7h1.2v1.3h-1.2V1800.7z M3460.1,1809.4h-1.1v-6.4h1.1V1809.4z" />
          <path
            d="M3463.1,1808.5v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3464.1,1809.6,3463.4,1809.1,3463.1,1808.5z M3463,1806.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1806.5z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="47">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M2969.5,1670.3c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2949.1,1624.5,2969.5,1645,2969.5,1670.3"
        />
        <g>
          <path d="M2888.8,1659h1.2v7.7h4.4v1h-5.5V1659z" />
          <path
            d="M2895,1664.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2896.2,1667.9,2895,1666.5,2895,1664.6z M2896.1,1663.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2897,1662.2,2896.3,1662.8,2896.1,1663.9z"
          />
          <path d="M2903.6,1667.7l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H2903.6z" />
          <path
            d="M2907.7,1664.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2909,1667.9,2907.7,1666.5,2907.7,1664.6z M2908.9,1663.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2909.7,1662.2,2909,1662.8,2908.9,1663.9z"
          />
          <path d="M2915.8,1667.7h-1.1v-8.7h1.1V1667.7z" />
          <path
            d="M2924,1667.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2927,1666.5,2925.8,1667.9,2924,1667.9z M2924,1666.9
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2922.2,1666,2922.9,1666.9,2924,1666.9z"
          />
          <path
            d="M2931.2,1659.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1659.9z"
          />
          <path
            d="M2938.8,1659.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1659.9z"
          />
          <path
            d="M2942.2,1667.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2945.2,1666.5,2944,1667.9,2942.2,1667.9z
				 M2942.2,1666.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2940.4,1666,2941.1,1666.9,2942.2,1666.9z"
          />
          <path
            d="M2949.1,1667.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2952,1666.5,2950.8,1667.9,2949.1,1667.9z
				 M2949.1,1666.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2947.2,1666,2948,1666.9,2949.1,1666.9z"
          />
          <path
            d="M2953,1664.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2954,1667.9,2953,1666.4,2953,1664.6z M2956,1662.2c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.4
				C2957.8,1662.9,2956.9,1662.2,2956,1662.2z"
          />
        </g>
        <g>
          <path
            d="M2897.2,1678.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2898.4,1681.4,2897.2,1680,2897.2,1678.1z M2898.3,1677.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2899.2,1675.7,2898.5,1676.3,2898.3,1677.4z"
          />
          <path
            d="M2903.8,1678.1c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2904.8,1681.4,2903.8,1679.9,2903.8,1678.1z M2906.7,1675.7c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C2908.5,1676.4,2907.6,1675.7,2906.7,1675.7z"
          />
          <path
            d="M2916.5,1681.2h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1681.2z"
          />
          <path
            d="M2920.8,1680.4c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2918.9,1679.5,2919.7,1680.4,2920.8,1680.4z"
          />
          <path
            d="M2924.1,1679.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2925,1681.4,2924.1,1680.7,2924.1,1679.5z M2928.1,1678l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1678z"
          />
          <path
            d="M2931.2,1675.8h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1675.8z"
          />
          <path d="M2935.1,1672.5h1.2v1.3h-1.2V1672.5z M2936.2,1681.2h-1.1v-6.4h1.1V1681.2z" />
          <path
            d="M2940.6,1681.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2943.5,1680,2942.3,1681.4,2940.6,1681.4z
				 M2940.6,1680.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2938.7,1679.5,2939.5,1680.4,2940.6,1680.4
				z"
          />
          <path
            d="M2944.9,1674.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1674.9z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="46">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3414.1,1222.5c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8s20.5-45.8,45.8-45.8
			C3393.6,1176.8,3414.1,1197.2,3414.1,1222.5"
        />
        <g>
          <path
            d="M3340.7,1217.8c0.5,1,1.4,1.5,2.5,1.5s1.9-0.5,1.9-1.4c0-1-0.9-1.1-2.2-1.4c-1.3-0.3-2.5-0.7-2.5-2.2
				c0-1.4,1.2-2.3,2.8-2.3c1.4,0,2.4,0.7,2.9,1.6l-0.8,0.7c-0.4-0.8-1.1-1.3-2.1-1.3c-1,0-1.7,0.5-1.7,1.3c0,0.9,0.8,1,1.9,1.3
				c1.3,0.3,2.8,0.6,2.8,2.3c0,1.5-1.3,2.5-2.9,2.5c-1.4,0-2.7-0.7-3.3-1.8L3340.7,1217.8z"
          />
          <path
            d="M3347.4,1215.2h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V1215.2z"
          />
          <path
            d="M3354.1,1215.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				V1215.3z"
          />
          <path
            d="M3354.8,1217.2c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1
				l0.9,0.3c-0.4,1-1.2,1.7-2.4,1.7C3355.9,1220.3,3354.8,1219,3354.8,1217.2z M3355.8,1216.6h3.2c0-0.8-0.5-1.6-1.5-1.6
				C3356.6,1215.1,3356,1215.6,3355.8,1216.6z"
          />
          <path
            d="M3361.2,1214.3h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V1214.3z"
          />
          <path
            d="M3369.8,1222.3c-1.3,0-2.2-0.6-2.6-1.7l0.9-0.4c0.2,0.8,0.8,1.2,1.6,1.2c1,0,1.7-0.5,1.7-1.8v-0.7c-0.3,0.6-1,1-1.8,1
				c-1.6,0-2.5-1.3-2.5-2.9c0-1.6,0.9-2.9,2.5-2.9c0.8,0,1.5,0.4,1.8,1v-0.9h1v5.3C3372.6,1221.4,3371.4,1222.3,3369.8,1222.3z
				 M3369.9,1215.1c-1.1,0-1.7,0.8-1.7,2s0.7,2,1.7,2c0.8,0,1.6-0.6,1.6-1.9v-0.2C3371.6,1215.7,3370.8,1215.1,3369.9,1215.1z"
          />
          <path
            d="M3374.4,1215.2h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.5,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V1215.2z"
          />
          <path
            d="M3378.1,1212.1h1v3c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V1212.1z"
          />
          <path
            d="M3390.3,1220.3c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S3391.9,1220.3,3390.3,1220.3z M3390.3,1219.4
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C3388.6,1218.6,3389.2,1219.4,3390.3,1219.4z"
          />
          <path
            d="M3396.8,1212.9c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0V1212.9z"
          />
        </g>
        <g>
          <path
            d="M3343.1,1225.4c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0V1225.4z"
          />
          <path
            d="M3346.3,1232.8c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S3348,1232.8,3346.3,1232.8z M3346.3,1231.9
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C3344.6,1231.1,3345.3,1231.9,3346.3,1231.9z"
          />
          <path
            d="M3352.7,1232.8c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S3354.3,1232.8,3352.7,1232.8z M3352.7,1231.9
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C3351,1231.1,3351.6,1231.9,3352.7,1231.9z"
          />
          <path
            d="M3356.3,1229.7c0-1.7,0.9-3.1,2.6-3.1c0.8,0,1.5,0.4,1.8,1v-3h1v8.1h-1v-0.9c-0.3,0.6-1,1-1.8,1
				C3357.2,1232.8,3356.3,1231.4,3356.3,1229.7z M3359,1227.6c-1.1,0-1.7,0.9-1.7,2.2c0,1.3,0.6,2.2,1.7,2.2c0.8,0,1.7-0.6,1.7-2
				v-0.4C3360.7,1228.1,3359.9,1227.6,3359,1227.6z"
          />
          <path
            d="M3366.9,1224.6h1v3c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V1224.6z"
          />
          <path
            d="M3372.8,1231.1c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C3373.6,1232.8,3372.8,1232.1,3372.8,1231.1z M3376.4,1229.6l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V1229.6z"
          />
          <path
            d="M3380.2,1231.8v0.9h-1v-8.1h1v3c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C3381.2,1232.8,3380.5,1232.4,3380.2,1231.8z M3380.1,1229.9c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1229.9z"
          />
          <path d="M3385.8,1224.6h1.1v1.2h-1.1V1224.6z M3386.8,1232.6h-1v-5.9h1V1232.6z" />
          <path
            d="M3388.7,1227.6h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V1227.6z"
          />
          <path
            d="M3392.5,1230.8c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L3392.5,1230.8z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="45">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M1865.4,330.8c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1844.9,285,1865.4,305.5,1865.4,330.8"
        />
        <g>
          <path
            d="M1786.9,313.8c1.1,0,1.9-0.7,2.3-1.8l1.1,0.4c-0.5,1.5-1.7,2.4-3.4,2.4c-2.3,0-3.9-1.8-3.9-4.5c0-2.7,1.7-4.5,3.9-4.5
				c1.7,0,2.9,0.9,3.4,2.4l-1.1,0.4c-0.3-1.1-1.1-1.8-2.3-1.8c-1.6,0-2.8,1.4-2.8,3.5S1785.3,313.8,1786.9,313.8z"
          />
          <path
            d="M1793.8,314.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1796.7,313.5,1795.5,314.8,1793.8,314.8z
				 M1793.8,313.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1791.9,313,1792.7,313.9,1793.8,313.9z"
          />
          <path
            d="M1797.9,308.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V308.3z"
          />
          <path
            d="M1807.1,313.9c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1805.1,313,1805.9,313.9,1807.1,313.9z"
          />
          <path
            d="M1810.1,311.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1811.4,314.8,1810.1,313.5,1810.1,311.5z M1811.2,310.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1812.1,309.2,1811.4,309.8,1811.2,310.9z"
          />
          <path
            d="M1816.9,308.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V308.3z"
          />
          <path
            d="M1823.7,309.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V309.3z"
          />
          <path
            d="M1830.7,309.4c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V309.4z"
          />
          <path
            d="M1831.2,313c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1832.1,314.8,1831.2,314.1,1831.2,313z M1835.1,311.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V311.4z"
          />
          <path
            d="M1838,309.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V309.3z"
          />
          <path d="M1841.7,306h1.2v1.3h-1.2V306z M1842.8,314.7h-1.1v-6.4h1.1V314.7z" />
          <path
            d="M1846.9,314.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1849.9,313.5,1848.6,314.8,1846.9,314.8z
				 M1846.9,313.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1845.1,313,1845.8,313.9,1846.9,313.9z"
          />
          <path
            d="M1851,308.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V308.3z"
          />
        </g>
        <g>
          <path
            d="M1790.3,328.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1793.3,327,1792,328.4,1790.3,328.4z
				 M1790.3,327.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1788.5,326.5,1789.2,327.4,1790.3,327.4z"
          />
          <path
            d="M1797.4,320.4c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V320.4z"
          />
          <path
            d="M1802.4,326.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1802.4,326.3z"
          />
          <path
            d="M1808.2,319.5h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V319.5z"
          />
          <path
            d="M1817.5,328.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1820.5,327,1819.3,328.4,1817.5,328.4z
				 M1817.5,327.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1815.7,326.5,1816.4,327.4,1817.5,327.4z"
          />
          <path
            d="M1823,327.3v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1824,328.4,1823.3,327.9,1823,327.3z M1822.9,325.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V325.3z"
          />
          <path
            d="M1830.2,327.3v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1831.3,328.4,1830.6,327.9,1830.2,327.3z M1830.2,325.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V325.3z"
          />
          <path d="M1836.3,319.5h1.2v1.3h-1.2V319.5z M1837.4,328.2h-1.1v-6.4h1.1V328.2z" />
          <path
            d="M1839.2,321.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V321.9z"
          />
          <path
            d="M1848.5,330.5c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9V327
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C1851.5,329.5,1850.3,330.5,1848.5,330.5z M1848.6,322.7c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C1850.4,323.4,1849.6,322.7,1848.6,322.7z"
          />
        </g>
        <g>
          <path d="M1782.3,333h1.2v1.3h-1.2V333z M1783.4,341.7h-1.1v-6.4h1.1V341.7z" />
          <path
            d="M1785.2,335.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V335.4z"
          />
          <path
            d="M1792.3,336.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V336.3z"
          />
          <path
            d="M1798.7,341.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1801.7,340.5,1800.5,341.9,1798.7,341.9z
				 M1798.7,340.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1796.9,340,1797.6,340.9,1798.7,340.9z"
          />
          <path
            d="M1807.1,339.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1807.1,339.8z"
          />
          <path
            d="M1817.9,341.7h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V341.7z"
          />
          <path
            d="M1820.8,340.8v0.9h-1.1V333h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1821.9,341.9,1821.2,341.4,1820.8,340.8z M1820.8,338.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V338.8z"
          />
          <path
            d="M1832,341.7h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V341.7z"
          />
          <path
            d="M1837.1,336.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V336.5z"
          />
          <path
            d="M1839.4,340.8v0.9h-1.1V333h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1840.5,341.9,1839.8,341.4,1839.4,340.8z M1839.4,338.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V338.8z"
          />
          <path
            d="M1845,340c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3c0.3-1,1.2-1.8,2.5-1.8
				c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1846,341.8,1845,341.2,1845,340z M1849,338.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4V338.5z"
          />
          <path
            d="M1851.9,335.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V335.4z"
          />
        </g>
        <g>
          <path
            d="M1789.6,355.3h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V355.3
				z"
          />
          <path
            d="M1798.7,352.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1799.9,355.4,1798.7,354,1798.7,352.1z M1799.8,351.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1800.7,349.7,1800,350.3,1799.8,351.4z"
          />
          <path
            d="M1808.1,357.6c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C1811.1,356.5,1809.8,357.6,1808.1,357.6z M1808.2,349.7c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C1810,350.4,1809.1,349.7,1808.2,349.7z"
          />
          <path
            d="M1812.4,353.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1813.3,355.4,1812.4,354.7,1812.4,353.5z M1816.3,352l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V352z"
          />
          <path d="M1819,351.2h3.3v1h-3.3V351.2z" />
          <path
            d="M1824.9,355.3h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V355.3
				z"
          />
          <path
            d="M1833.9,353.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1834.9,355.4,1833.9,354.7,1833.9,353.5z M1837.9,352l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V352z"
          />
          <path d="M1841.9,355.3h-1.1v-8.7h1.1V355.3z" />
          <path d="M1844.9,355.3h-1.1v-8.7h1.1V355.3z" />
          <path
            d="M1846.8,353.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1846.8,353.3z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="44">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2457,1260.6c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2436.6,1214.9,2457,1235.4,2457,1260.6"
        />
        <g>
          <path d="M2383.3,1242.6l3.4,8.7h-1.2l-0.9-2.3h-3.9l-0.9,2.3h-1.2l3.4-8.7H2383.3z M2381,1248h3.1l-1.6-4.1L2381,1248z" />
          <path
            d="M2390.3,1250.5c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2388.4,1249.6,2389.2,1250.5,2390.3,1250.5z"
          />
          <path
            d="M2396.6,1250.5c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2394.7,1249.6,2395.5,1250.5,2396.6,1250.5z"
          />
          <path
            d="M2400,1248.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2401.2,1251.5,2400,1250.1,2400,1248.2z M2401.1,1247.5h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2402,1245.8,2401.3,1246.4,2401.1,1247.5z"
          />
          <path
            d="M2407.1,1249.4c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2407.1,1249.4z"
          />
          <path
            d="M2413,1249.4c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2413,1249.4z"
          />
          <path d="M2418.7,1242.6h1.2v1.3h-1.2V1242.6z M2419.9,1251.3h-1.1v-6.4h1.1V1251.3z" />
          <path
            d="M2422.8,1250.4v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2423.9,1251.5,2423.2,1251,2422.8,1250.4z M2422.8,1248.4c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1248.4z"
          />
          <path d="M2428.9,1242.6h1.2v1.3h-1.2V1242.6z M2430,1251.3h-1.1v-6.4h1.1V1251.3z" />
          <path d="M2431.8,1242.6h1.2v1.3h-1.2V1242.6z M2433,1251.3h-1.1v-6.4h1.1V1251.3z" />
          <path
            d="M2434.9,1245.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1245.9z"
          />
          <path
            d="M2439.3,1253.6c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C2440.9,1253,2440.4,1253.6,2439.3,1253.6z"
          />
        </g>
        <g>
          <path
            d="M2374.1,1259.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1259.4z"
          />
          <path
            d="M2380.6,1265c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2383.5,1263.6,2382.3,1265,2380.6,1265z M2380.6,1264
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2378.7,1263.1,2379.5,1264,2380.6,1264z"
          />
          <path
            d="M2388.9,1262.9c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2388.9,1262.9z"
          />
          <path
            d="M2399.7,1264.8h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1264.8z"
          />
          <path
            d="M2401.7,1262.9c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2401.7,1262.9z"
          />
          <path
            d="M2407.6,1259.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1259.4z"
          />
          <path
            d="M2411.1,1263.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2412,1265,2411.1,1264.3,2411.1,1263.1z M2415.1,1261.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1261.6z"
          />
          <path d="M2418,1256.1h1.2v1.3h-1.2V1256.1z M2419.1,1264.8h-1.1v-6.4h1.1V1264.8z" />
          <path
            d="M2420.9,1258.5h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1258.5z"
          />
          <path
            d="M2427.3,1263.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2428.2,1265,2427.3,1264.3,2427.3,1263.1z M2431.2,1261.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1261.6z"
          />
          <path
            d="M2435.3,1263.9v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2436.4,1265,2435.7,1264.6,2435.3,1263.9z M2435.2,1261.9c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1261.9z"
          />
          <path d="M2442.5,1264.8h-1.1v-8.7h1.1V1264.8z" />
          <path
            d="M2444.7,1267.1c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C2446.3,1266.5,2445.8,1267.1,2444.7,1267.1z"
          />
        </g>
        <g>
          <path
            d="M2372.7,1277.4v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2373.7,1278.5,2373,1278.1,2372.7,1277.4z M2372.6,1275.4c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1275.4z"
          />
          <path
            d="M2382.1,1273.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1273.1z"
          />
          <path
            d="M2385.8,1278.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2388.8,1277.2,2387.5,1278.5,2385.8,1278.5z
				 M2385.8,1277.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2384,1276.6,2384.7,1277.6,2385.8,1277.6z"
          />
          <path
            d="M2389.7,1275.2c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2390.7,1278.5,2389.7,1277,2389.7,1275.2z M2392.7,1272.8c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C2394.5,1273.5,2393.6,1272.8,2392.7,1272.8z"
          />
          <path
            d="M2402.4,1278.4h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1278.4z"
          />
          <path
            d="M2406.8,1277.5c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2404.9,1276.6,2405.7,1277.5,2406.8,1277.5z"
          />
          <path
            d="M2410.1,1275.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2411.3,1278.5,2410.1,1277.1,2410.1,1275.2z M2411.2,1274.5h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2412.1,1272.8,2411.4,1273.4,2411.2,1274.5z"
          />
          <path
            d="M2416.7,1275.2c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2417.7,1278.5,2416.7,1277,2416.7,1275.2z M2419.6,1272.8c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C2421.4,1273.5,2420.5,1272.8,2419.6,1272.8z"
          />
          <path
            d="M2431,1270.5c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1270.5z"
          />
          <path
            d="M2434.4,1278.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2437.4,1277.2,2436.2,1278.5,2434.4,1278.5z
				 M2434.4,1277.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2432.6,1276.6,2433.3,1277.6,2434.4,1277.6
				z"
          />
          <path
            d="M2441.3,1278.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2444.3,1277.2,2443,1278.5,2441.3,1278.5z
				 M2441.3,1277.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2439.5,1276.6,2440.2,1277.6,2441.3,1277.6
				z"
          />
          <path
            d="M2445.2,1275.2c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2446.2,1278.5,2445.2,1277,2445.2,1275.2z M2448.2,1272.8c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C2450,1273.5,2449.1,1272.8,2448.2,1272.8z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="43">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3339.1,997.9c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3318.6,952.1,3339.1,972.6,3339.1,997.9"
        />
        <g>
          <path
            d="M3276.3,966.7c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3277.3,970,3276.3,968.5,3276.3,966.7z M3279.3,964.3c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C3281.1,965,3280.2,964.3,3279.3,964.3z"
          />
          <path
            d="M3282.9,966.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3284.1,970,3282.9,968.6,3282.9,966.7z M3284,966h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3284.9,964.3,3284.2,964.9,3284,966z"
          />
          <path
            d="M3291.8,972.2c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3294.7,971.1,3293.5,972.2,3291.8,972.2z M3291.9,964.3c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C3293.6,965,3292.8,964.3,3291.9,964.3z"
          />
          <path
            d="M3299.2,964.6c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V964.6z"
          />
          <path
            d="M3299.4,966.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3300.6,970,3299.4,968.6,3299.4,966.7z M3300.5,966h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3301.4,964.3,3300.7,964.9,3300.5,966z"
          />
          <path
            d="M3305.4,966.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3306.6,970,3305.4,968.6,3305.4,966.7z M3306.5,966h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3307.3,964.3,3306.7,964.9,3306.5,966z"
          />
          <path
            d="M3272.5,982c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3275.5,980.6,3274.3,982,3272.5,982z M3272.5,981.1
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3270.7,980.1,3271.4,981.1,3272.5,981.1z"
          />
          <path
            d="M3279,974c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1V975c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V974z"
          />
          <path
            d="M3281.5,979.9c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3281.5,979.9z"
          />
          <path
            d="M3289.2,982c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3292.1,980.6,3290.9,982,3289.2,982z M3289.2,981.1
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3287.3,980.1,3288,981.1,3289.2,981.1z"
          />
          <path
            d="M3295.5,981c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3293.6,980.1,3294.3,981,3295.5,981z"
          />
          <path d="M3298.6,973.1h1.2v1.3h-1.2V973.1z M3299.7,981.9h-1.1v-6.4h1.1V981.9z" />
          <path
            d="M3300.5,978.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3301.7,982,3300.5,980.6,3300.5,978.7z M3301.6,978h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3302.5,976.3,3301.8,976.9,3301.6,978z"
          />
          <path
            d="M3307.1,976.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V976.4z"
          />
          <path
            d="M3309.9,980.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3310.8,982,3309.9,981.3,3309.9,980.1z M3313.9,978.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V978.6z"
          />
          <path d="M3317.3,981.9h-1.1v-8.7h1.1V981.9z" />
        </g>
        <g>
          <path
            d="M3255.2,993.5c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3253.3,992.6,3254.1,993.5,3255.2,993.5z"
          />
          <path
            d="M3260.9,994.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3263.8,993.2,3262.6,994.5,3260.9,994.5z M3260.9,993.6
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3259,992.6,3259.8,993.6,3260.9,993.6z"
          />
          <path
            d="M3264.6,988h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V988z"
          />
          <path
            d="M3271,992.4c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3271,992.4z"
          />
          <path
            d="M3275.7,991.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3277,994.5,3275.7,993.2,3275.7,991.2z M3276.8,990.5h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3277.7,988.8,3277,989.4,3276.8,990.5z"
          />
          <path
            d="M3282.1,988h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V988z"
          />
          <path
            d="M3288.5,992.4c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3288.5,992.4z"
          />
          <path
            d="M3298.7,994.4h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3V988h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7V988h1.1V994.4z"
          />
          <path
            d="M3300.1,992.4c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3300.1,992.4z"
          />
          <path
            d="M3306.7,992.7c0-1.3,1-1.8,2.4-2.1l1.5-0.3V990c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3307.6,994.5,3306.7,993.8,3306.7,992.7z M3310.7,991.1l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V991.1z"
          />
          <path
            d="M3314.1,993.4v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3315.2,994.5,3314.5,994.1,3314.1,993.4z M3314.1,991.4c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V991.4z"
          />
          <path
            d="M3322.1,994.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3325.1,993.2,3323.9,994.5,3322.1,994.5z M3322.1,993.6
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3320.3,992.6,3321,993.6,3322.1,993.6z"
          />
          <path
            d="M3330.9,994.4h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3V988h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7V988h1.1V994.4z"
          />
          <path
            d="M3332.3,988.9h-1V988h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V988.9z"
          />
        </g>
        <g>
          <path
            d="M3266.6,1005.9c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3266.6,1005.9z"
          />
          <path
            d="M3276.8,1007.9h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1007.9z"
          />
          <path
            d="M3278.2,1005.9c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3278.2,1005.9z"
          />
          <path
            d="M3283.5,1002.5h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1002.5z"
          />
          <path
            d="M3286.4,1006.2c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3287.3,1008,3286.4,1007.3,3286.4,1006.2z M3290.3,1004.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1004.6z"
          />
          <path d="M3292.6,999.2h1.2v1.3h-1.2V999.2z M3293.7,1007.9h-1.1v-6.4h1.1V1007.9z" />
          <path
            d="M3295,1001.5h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1001.5z"
          />
          <path
            d="M3300.7,1006.2c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3301.6,1008,3300.7,1007.3,3300.7,1006.2z M3304.7,1004.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1004.6z"
          />
          <path
            d="M3308.1,1007v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3309.2,1008,3308.5,1007.6,3308.1,1007z M3308.1,1004.9c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1004.9z"
          />
          <path d="M3314.7,1007.9h-1.1v-8.7h1.1V1007.9z" />
          <path
            d="M3315.5,1004.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3316.7,1008,3315.5,1006.7,3315.5,1004.7z M3316.6,1004h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3317.5,1002.3,3316.8,1003,3316.6,1004z"
          />
        </g>
        <g>
          <path
            d="M3285.9,1012.5c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1012.5z"
          />
          <path
            d="M3288.8,1020.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C3291.7,1019.2,3290.5,1020.5,3288.8,1020.5z
				 M3288.8,1019.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3286.9,1018.7,3287.7,1019.6,3288.8,1019.6
				z"
          />
          <path
            d="M3295,1020.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C3298,1019.2,3296.8,1020.5,3295,1020.5z
				 M3295,1019.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4s-1.8,0.9-1.8,2.4C3293.2,1018.7,3293.9,1019.6,3295,1019.6z"
          />
          <path
            d="M3298.3,1017.2c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3299.3,1020.5,3298.3,1019,3298.3,1017.2z M3301.3,1014.9c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C3303.1,1015.5,3302.2,1014.9,3301.3,1014.9z"
          />
          <path
            d="M3265.1,1031.6c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3263.2,1030.7,3263.9,1031.6,3265.1,1031.6z"
          />
          <path
            d="M3270.6,1032.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C3273.6,1031.2,3272.4,1032.5,3270.6,1032.5z
				 M3270.6,1031.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4s-1.8,0.9-1.8,2.4C3268.8,1030.7,3269.5,1031.6,3270.6,1031.6z"
          />
          <path
            d="M3274.2,1026h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1026z"
          />
          <path
            d="M3280.5,1030.4c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3280.5,1030.4z"
          />
          <path
            d="M3290.6,1032.4h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1032.4z"
          />
          <path
            d="M3292.8,1032.4h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1032.4z"
          />
          <path
            d="M3302.6,1031.5v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3303.7,1032.5,3303,1032.1,3302.6,1031.5z M3302.6,1029.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1029.5z"
          />
          <path
            d="M3308.2,1027h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1027z"
          />
          <path d="M3311.4,1023.7h1.2v1.3h-1.2V1023.7z M3312.5,1032.4h-1.1v-6.4h1.1V1032.4z" />
          <path
            d="M3316.1,1032.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C3319.1,1031.2,3317.9,1032.5,3316.1,1032.5z
				 M3316.1,1031.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3314.3,1030.7,3315,1031.6,3316.1,1031.6z"
          />
          <path
            d="M3319.8,1026h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1026z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="42">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M790.2,813.7c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C769.7,768,790.2,788.4,790.2,813.7"
        />
        <g>
          <path
            d="M721,804.4h-1.2v-8.7h3.3c1.8,0,3,0.9,3,2.5c0,1.4-0.9,2.2-2.2,2.5l2.4,3.7h-1.3l-2.3-3.7H721L721,804.4L721,804.4z
				 M723.2,799.8c1.2,0,1.9-0.5,1.9-1.5c0-1-0.7-1.5-1.9-1.5H721v3.1L723.2,799.8L723.2,799.8z"
          />
          <path
            d="M727.3,801.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C728.5,804.6,727.3,803.2,727.3,801.2z M728.4,800.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C729.3,798.9,728.6,799.5,728.4,800.6z"
          />
          <path
            d="M736.8,803.6c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C734.9,802.7,735.7,803.6,736.8,803.6z"
          />
          <path
            d="M743.1,804.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C746.1,803.2,744.9,804.6,743.1,804.6z
				 M743.1,803.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C741.3,802.7,742,803.6,743.1,803.6z"
          />
          <path d="M749.1,804.4l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H749.1z" />
          <path
            d="M753.2,801.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C754.4,804.6,753.2,803.2,753.2,801.2z M754.3,800.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C755.2,798.9,754.5,799.5,754.3,800.6z"
          />
          <path
            d="M763.5,799.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0L763.5,799.1L763.5,799.1z"
          />
          <path
            d="M765.1,806.7c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C766.7,806.1,766.2,806.7,765.1,806.7z"
          />
        </g>
        <g>
          <path
            d="M721.7,818.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C724.7,816.7,723.4,818.1,721.7,818.1z
				 M721.7,817.1c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C719.9,816.2,720.6,817.1,721.7,817.1z"
          />
          <path
            d="M728.8,810.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V810.1z"
          />
          <path
            d="M733.2,814.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C734.4,818.1,733.2,816.7,733.2,814.8z M734.3,814.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C735.2,812.4,734.5,813,734.3,814.1z"
          />
          <path
            d="M740.2,811.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1V814c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V811.6z"
          />
          <path
            d="M746.6,814.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C747.8,818.1,746.6,816.7,746.6,814.8z M747.7,814.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C748.6,812.4,747.9,813,747.7,814.1z"
          />
          <path
            d="M756.9,812.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0L756.9,812.7L756.9,812.7z"
          />
          <path
            d="M760.6,820.3c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C763.5,819.2,762.3,820.3,760.6,820.3z M760.7,812.4c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C762.4,813.1,761.6,812.4,760.7,812.4z"
          />
          <path
            d="M765.7,820.2c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C767.3,819.6,766.8,820.2,765.7,820.2z"
          />
        </g>
        <g>
          <path
            d="M710.4,823.6c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9H709v5.4h-1.1V826h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V823.6z"
          />
          <path
            d="M714.7,826.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0L714.7,826.2L714.7,826.2z"
          />
          <path
            d="M718.4,831.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C721.4,830.3,720.2,831.6,718.4,831.6z
				 M718.4,830.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C716.6,829.7,717.3,830.7,718.4,830.7z"
          />
          <path
            d="M723.9,831.5h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				L723.9,831.5L723.9,831.5z"
          />
          <path
            d="M738.3,830.5v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C739.4,831.6,738.7,831.2,738.3,830.5z M738.3,828.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V828.5z"
          />
          <path d="M744.4,822.7h1.2v1.3h-1.2V822.7z M745.5,831.5h-1.1v-6.4h1.1V831.5z" />
          <path
            d="M749.9,831.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C752.8,830.3,751.6,831.6,749.9,831.6z
				 M749.9,830.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C748,829.7,748.8,830.7,749.9,830.7z"
          />
          <path
            d="M755.3,831.5h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2H762v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				L755.3,831.5L755.3,831.5z"
          />
          <path
            d="M764.3,829.7c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C765.3,831.6,764.3,830.9,764.3,829.7z M768.3,828.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				L768.3,828.2L768.3,828.2z"
          />
          <path
            d="M771.4,829.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L771.4,829.5z"
          />
          <path
            d="M777.3,829.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L777.3,829.5z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="41">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M2385.4,205.8c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2364.9,160,2385.4,180.5,2385.4,205.8"
        />
        <g>
          <path
            d="M2309.4,187.8h2.8c2.7,0,4.3,1.7,4.3,4.4c0,2.7-1.7,4.4-4.3,4.4h-2.8V187.8z M2312.2,195.5c2,0,3.2-1.3,3.2-3.4
				c0-2-1.2-3.4-3.2-3.4h-1.6v6.7L2312.2,195.5L2312.2,195.5z"
          />
          <path d="M2317.9,187.8h1.2v1.3h-1.2V187.8z M2319,196.5h-1.1v-6.4h1.1V196.5z" />
          <path d="M2322.5,196.5l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H2322.5z" />
          <path
            d="M2326.6,193.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2327.8,196.6,2326.6,195.3,2326.6,193.3z M2327.7,192.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2328.6,190.9,2327.9,191.6,2327.7,192.6z"
          />
          <path
            d="M2336.9,191.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V191.2z"
          />
          <path
            d="M2338.2,194.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2338.2,194.5z"
          />
          <path d="M2343.9,187.8h1.2v1.3h-1.2V187.8z M2345.1,196.5h-1.1v-6.4h1.1V196.5z" />
          <path
            d="M2347.1,191.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V191.1z"
          />
          <path
            d="M2351.4,198.8c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C2353,198.1,2352.5,198.8,2351.4,198.8z"
          />
          <path
            d="M2363.6,196.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2366.5,195.3,2365.3,196.6,2363.6,196.6z M2363.6,195.7
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2361.7,194.8,2362.5,195.7,2363.6,195.7z"
          />
          <path
            d="M2370.7,188.6c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V188.6z"
          />
        </g>
        <g>
          <path
            d="M2307.8,202.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V202.1z"
          />
          <path
            d="M2311.3,210.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2314.3,208.8,2313.1,210.1,2311.3,210.1z M2311.3,209.2
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2309.5,208.3,2310.2,209.2,2311.3,209.2z"
          />
          <path
            d="M2318.2,210.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2321.1,208.8,2319.9,210.1,2318.2,210.1z M2318.2,209.2
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2316.3,208.3,2317.1,209.2,2318.2,209.2z"
          />
          <path
            d="M2322.1,206.8c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2323.1,210.1,2322.1,208.6,2322.1,206.8z M2325.1,204.5c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C2326.9,205.1,2326,204.5,2325.1,204.5z"
          />
          <path
            d="M2336.1,210.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2339.1,208.8,2337.9,210.1,2336.1,210.1z M2336.1,209.2
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2334.3,208.3,2335,209.2,2336.1,209.2z"
          />
          <path
            d="M2343.2,202.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V202.1z"
          />
          <path
            d="M2347,202.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V202.1z"
          />
          <path
            d="M2347.5,206.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2348.7,210.1,2347.5,208.8,2347.5,206.8z M2348.6,206.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2349.5,204.5,2348.8,205.1,2348.6,206.2z"
          />
          <path
            d="M2357.8,204.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V204.7z"
          />
          <path d="M2359,201.3h1.2v1.3h-1.2V201.3z M2360.1,210h-1.1v-6.4h1.1V210z" />
          <path
            d="M2361.9,203.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V203.6z"
          />
          <path
            d="M2371.2,212.3c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2374.2,211.3,2372.9,212.3,2371.2,212.3z M2371.3,204.5c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C2373.1,205.2,2372.2,204.5,2371.3,204.5z"
          />
        </g>
        <g>
          <path d="M2304.2,214.8h1.2v1.3h-1.2V214.8z M2305.4,223.5h-1.1v-6.4h1.1V223.5z" />
          <path
            d="M2307.2,217.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V217.2z"
          />
          <path d="M2319,223.5h-1.1v-8.7h1.1V223.5z" />
          <path
            d="M2320.3,221.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2321.3,223.6,2320.3,222.9,2320.3,221.8z M2324.3,220.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V220.3z"
          />
          <path
            d="M2330.5,218.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V218.2z"
          />
          <path
            d="M2334.2,225.8c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2337.1,224.8,2335.9,225.8,2334.2,225.8z M2334.3,218c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C2336,218.7,2335.2,218,2334.3,218z"
          />
          <path
            d="M2338.5,220.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2339.7,223.7,2338.5,222.3,2338.5,220.3z M2339.6,219.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2340.5,218,2339.8,218.6,2339.6,219.7z"
          />
          <path
            d="M2352.7,218.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V218.2z"
          />
          <path
            d="M2353.4,220.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2354.6,223.7,2353.4,222.3,2353.4,220.3z M2354.5,219.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2355.4,218,2354.7,218.6,2354.5,219.7z"
          />
          <path
            d="M2360.6,218.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V218.1z"
          />
          <path
            d="M2364.1,221.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2365,223.6,2364.1,222.9,2364.1,221.8z M2368,220.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V220.3z"
          />
          <path d="M2370.9,214.8h1.2v1.3h-1.2V214.8z M2372.1,223.5h-1.1v-6.4h1.1V223.5z" />
          <path d="M2375,223.5h-1.1v-8.7h1.1V223.5z" />
        </g>
      </g>
      <g onClick={handleClick} id="40">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1354.1,674.8c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1333.6,629.1,1354.1,649.6,1354.1,674.8"
        />
        <g>
          <path d="M1271.6,663.6h1.2v7.7h4.4v1h-5.5V663.6z" />
          <path
            d="M1277.8,669.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1279,672.4,1277.8,671.1,1277.8,669.1z M1278.9,668.5h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1279.8,666.8,1279.1,667.4,1278.9,668.5z"
          />
          <path d="M1286.4,672.3l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H1286.4z" />
          <path
            d="M1290.5,669.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1291.7,672.4,1290.5,671.1,1290.5,669.1z M1291.6,668.5h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1292.5,666.8,1291.8,667.4,1291.6,668.5z"
          />
          <path d="M1298.6,672.3h-1.1v-8.7h1.1V672.3z" />
          <path
            d="M1306.8,672.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1309.8,671.1,1308.5,672.4,1306.8,672.4z
				 M1306.8,671.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1305,670.6,1305.7,671.5,1306.8,671.5z"
          />
          <path
            d="M1313.9,664.4c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V664.4z"
          />
          <path
            d="M1321.5,664.4c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V664.4z"
          />
          <path
            d="M1325,672.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1327.9,671.1,1326.7,672.4,1325,672.4z M1325,671.5
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1323.1,670.6,1323.9,671.5,1325,671.5z"
          />
          <path
            d="M1329.5,670.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1329.5,670.3z"
          />
          <path
            d="M1335.4,670.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1335.4,670.3z"
          />
          <path d="M1341.1,663.6h1.2v1.3h-1.2V663.6z M1342.2,672.3h-1.1v-6.4h1.1V672.3z" />
          <path d="M1345.2,672.3h-1.1v-8.7h1.1V672.3z" />
        </g>
        <g>
          <path
            d="M1274.7,682.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1275.9,685.9,1274.7,684.6,1274.7,682.6z M1275.8,682h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1276.7,680.3,1276,680.9,1275.8,682z"
          />
          <path
            d="M1281.7,679.5h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V679.5z"
          />
          <path
            d="M1288.1,682.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1289.3,685.9,1288.1,684.6,1288.1,682.6z M1289.2,682h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1290.1,680.3,1289.4,680.9,1289.2,682z"
          />
          <path
            d="M1298.4,680.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V680.5z"
          />
          <path
            d="M1302,688.1c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7c-0.3,0.6-1.1,1.1-1.9,1.1
				c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8C1305,687.1,1303.8,688.1,1302,688.1z
				 M1302.1,680.3c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2C1303.9,681,1303.1,680.3,1302.1,680.3z
				"
          />
          <path
            d="M1307.2,688.1c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C1308.8,687.5,1308.3,688.1,1307.2,688.1z"
          />
          <path
            d="M1321.9,685.8h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V685.8z"
          />
          <path
            d="M1323.8,683.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1323.8,683.8z"
          />
          <path
            d="M1329.2,682.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1330.4,685.9,1329.2,684.6,1329.2,682.6z M1330.3,682h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1331.2,680.3,1330.5,680.9,1330.3,682z"
          />
          <path
            d="M1335.7,682.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1336.8,685.9,1335.7,684.4,1335.7,682.6z M1338.7,680.3c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C1340.5,680.9,1339.6,680.3,1338.7,680.3z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="39">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M2248.4,2735.1c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2227.9,2689.4,2248.4,2709.8,2248.4,2735.1"
        />
        <g>
          <path d="M2166.6,2717.1v1h-4.5v2.7h3.9v1h-3.9v3h4.5v1h-5.7v-8.7H2166.6z" />
          <path d="M2170.3,2723.3l-1.8,2.5h-1.2l2.3-3.3l-2.1-3h1.3l1.5,2.2l1.6-2.2h1.2l-2.1,3l2.3,3.3h-1.2L2170.3,2723.3z" />
          <path
            d="M2174.5,2720.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2720.4z"
          />
          <path
            d="M2178,2722.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2179.2,2726,2178,2724.6,2178,2722.6z M2179.1,2722h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2180,2720.3,2179.3,2720.9,2179.1,2722z"
          />
          <path
            d="M2185,2719.5h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2719.5z"
          />
          <path
            d="M2192,2720.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2720.4z"
          />
          <path
            d="M2202.4,2726c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2205.3,2724.6,2204.1,2726,2202.4,2726z
				 M2202.4,2725c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2200.5,2724.1,2201.3,2725,2202.4,2725z"
          />
          <path
            d="M2209.5,2718c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2718z"
          />
          <path
            d="M2219.4,2725.8h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2725.8z"
          />
          <path
            d="M2224.5,2720.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2720.5z"
          />
          <path
            d="M2226.8,2724.9v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2227.8,2726,2227.1,2725.5,2226.8,2724.9z M2226.7,2722.9c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2722.9z"
          />
          <path
            d="M2232.4,2724.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2233.3,2725.9,2232.4,2725.3,2232.4,2724.1z M2236.4,2722.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2722.6z"
          />
          <path
            d="M2239.3,2719.5h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2719.5z"
          />
        </g>
        <g>
          <path
            d="M2173.5,2730.6h1.1v3.2c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2730.6z"
          />
          <path
            d="M2179.9,2736.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2181.1,2739.5,2179.9,2738.1,2179.9,2736.2z M2181,2735.5h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2181.9,2733.8,2181.2,2734.4,2181,2735.5z"
          />
          <path
            d="M2186.4,2737.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2187.4,2739.4,2186.4,2738.8,2186.4,2737.6z M2190.4,2736.1l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2736.1z"
          />
          <path
            d="M2193.5,2733.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2733.9z"
          />
          <path d="M2201.3,2730.6h1.2v1.3h-1.2V2730.6z M2202.4,2739.3h-1.1v-6.4h1.1V2739.3z" />
          <path
            d="M2204.4,2737.4c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2204.4,2737.4z"
          />
          <path d="M2211.2,2739.3h-1.1v-8.7h1.1V2739.3z" />
          <path
            d="M2212.6,2737.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2213.5,2739.4,2212.6,2738.8,2212.6,2737.6z M2216.5,2736.1l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2736.1z"
          />
          <path
            d="M2219.5,2733h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2733z"
          />
          <path
            d="M2225.9,2736.2c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2226.9,2739.5,2225.9,2738,2225.9,2736.2z M2228.9,2733.8c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C2230.7,2734.5,2229.8,2733.8,2228.9,2733.8z"
          />
        </g>
        <g>
          <path
            d="M2187.5,2749.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2188.8,2753,2187.5,2751.6,2187.5,2749.7z M2188.7,2749h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2189.5,2747.3,2188.9,2747.9,2188.7,2749z"
          />
          <path
            d="M2197.3,2745c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2745z"
          />
          <path
            d="M2201.1,2745c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2745z"
          />
          <path
            d="M2201.6,2749.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2202.8,2753,2201.6,2751.6,2201.6,2749.7z M2202.7,2749h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2203.6,2747.3,2202.9,2747.9,2202.7,2749z"
          />
          <path
            d="M2211.2,2752c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2209.2,2751.1,2210,2752,2211.2,2752z"
          />
          <path
            d="M2215.1,2747.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2747.4z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="38">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3846,751.5c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3825.5,705.7,3846,726.2,3846,751.5"
        />
        <g>
          <path d="M3757.5,747h1.2v7.7h4.4v1h-5.5V747z" />
          <path
            d="M3763.7,752.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3764.9,755.8,3763.7,754.5,3763.7,752.5z M3764.8,751.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3765.7,750.1,3765,750.8,3764.8,751.8z"
          />
          <path d="M3772.3,755.7l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H3772.3z" />
          <path
            d="M3776.4,752.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3777.7,755.8,3776.4,754.5,3776.4,752.5z M3777.5,751.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3778.4,750.1,3777.7,750.8,3777.5,751.8z"
          />
          <path d="M3784.5,755.7h-1.1V747h1.1V755.7z" />
          <path
            d="M3792.7,755.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3795.7,754.5,3794.5,755.8,3792.7,755.8z M3792.7,754.9
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3790.9,754,3791.6,754.9,3792.7,754.9z"
          />
          <path
            d="M3799.9,747.8c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V747.8z"
          />
          <path d="M3804.6,747h1.2v1.3h-1.2V747z M3805.8,755.7h-1.1v-6.4h1.1V755.7z" />
          <path
            d="M3807.6,749.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V749.3z"
          />
          <path
            d="M3817,754.9c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3815.1,754,3815.9,754.9,3817,754.9z"
          />
          <path
            d="M3823.3,755.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3826.2,754.5,3825,755.8,3823.3,755.8z M3823.3,754.9
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3821.4,754,3822.2,754.9,3823.3,754.9z"
          />
          <path
            d="M3828.7,755.7h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V755.7
				z"
          />
          <path
            d="M3837.8,752.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3839,755.8,3837.8,754.5,3837.8,752.5z M3838.9,751.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3839.8,750.1,3839.1,750.8,3838.9,751.8z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="37">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M1829.3,1055c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.7,45.8-45.7
			C1808.8,1009.3,1829.3,1029.8,1829.3,1055"
        />
        <g>
          <path
            d="M1753,1044.8v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1754.1,1045.9,1753.4,1045.5,1753,1044.8z M1753,1042.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1042.8z"
          />
          <path
            d="M1762.4,1040.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1040.5z"
          />
          <path
            d="M1763.2,1042.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1764.4,1045.9,1763.2,1044.5,1763.2,1042.6z M1764.3,1041.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1765.2,1040.2,1764.5,1040.8,1764.3,1041.9z"
          />
          <path
            d="M1770.4,1043.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1770.4,1043.8z"
          />
          <path
            d="M1775.7,1042.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1776.9,1045.9,1775.7,1044.5,1775.7,1042.6z M1776.8,1041.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1777.7,1040.2,1777,1040.8,1776.8,1041.9z"
          />
          <path
            d="M1782.7,1039.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1039.4z"
          />
          <path
            d="M1792.1,1044.9c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1790.2,1044,1791,1044.9,1792.1,1044.9z"
          />
          <path
            d="M1795.5,1042.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1796.7,1045.9,1795.5,1044.5,1795.5,1042.6z M1796.6,1041.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1797.4,1040.2,1796.8,1040.8,1796.6,1041.9z"
          />
          <path
            d="M1808.8,1045.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1811.8,1044.6,1810.6,1045.9,1808.8,1045.9z
				 M1808.8,1045c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1807,1044,1807.7,1045,1808.8,1045z"
          />
          <path
            d="M1816,1037.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1037.9z"
          />
        </g>
        <g>
          <path d="M1740.7,1059.3h-1.1v-8.7h1.1V1059.3z" />
          <path
            d="M1745.1,1059.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1748,1058.1,1746.8,1059.4,1745.1,1059.4z
				 M1745.1,1058.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1743.2,1057.5,1743.9,1058.5,1745.1,1058.5
				z"
          />
          <path
            d="M1752,1058.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1750.1,1057.5,1750.8,1058.4,1752,1058.4z"
          />
          <path
            d="M1755.2,1057.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1756.2,1059.4,1755.2,1058.7,1755.2,1057.6z M1759.2,1056l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1056z"
          />
          <path d="M1763.2,1059.3h-1.1v-8.7h1.1V1059.3z" />
          <path
            d="M1770,1058.3v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1771.1,1059.4,1770.4,1059,1770,1058.3z M1770,1056.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1056.3z"
          />
          <path
            d="M1779.4,1054c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1054z"
          />
          <path
            d="M1783.2,1059.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1786.1,1058.1,1784.9,1059.4,1783.2,1059.4z
				 M1783.2,1058.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1781.3,1057.5,1782,1058.5,1783.2,1058.5z"
          />
          <path
            d="M1790.1,1058.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1788.2,1057.5,1788.9,1058.4,1790.1,1058.4z"
          />
          <path
            d="M1793.4,1056.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1794.6,1059.4,1793.4,1058.1,1793.4,1056.1z M1794.5,1055.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1795.4,1053.7,1794.7,1054.3,1794.5,1055.4z"
          />
          <path
            d="M1800.5,1057.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1800.5,1057.3z"
          />
          <path
            d="M1806.5,1057.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1806.5,1057.3z"
          />
          <path d="M1812.2,1050.5h1.2v1.3h-1.2V1050.5z M1813.3,1059.3h-1.1v-6.4h1.1V1059.3z" />
          <path
            d="M1815.1,1052.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1052.9z"
          />
          <path
            d="M1824.4,1061.6c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C1827.4,1060.6,1826.2,1061.6,1824.4,1061.6z M1824.5,1053.8c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2
				c0.9,0,1.8-0.7,1.8-2.1v-0.2C1826.3,1054.4,1825.5,1053.8,1824.5,1053.8z"
          />
        </g>
        <g>
          <path d="M1747.3,1064.1h1.2v1.3h-1.2V1064.1z M1748.4,1072.8h-1.1v-6.4h1.1V1072.8z" />
          <path
            d="M1750.2,1066.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1066.4z"
          />
          <path
            d="M1759.9,1064.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1064.9z"
          />
          <path
            d="M1764.1,1067.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1067.5z"
          />
          <path
            d="M1764.8,1071.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1765.8,1072.9,1764.8,1072.2,1764.8,1071.1z M1768.8,1069.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1069.5z"
          />
          <path
            d="M1771.9,1070.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1771.9,1070.8z"
          />
          <path
            d="M1777.8,1067.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1067.4z"
          />
          <path
            d="M1785.1,1067.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1067.5z"
          />
          <path
            d="M1791.3,1072.8h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1072.8z"
          />
          <path
            d="M1795.7,1072c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1793.8,1071.1,1794.6,1072,1795.7,1072z"
          />
          <path
            d="M1799.6,1067.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1067.4z"
          />
          <path
            d="M1808.6,1072.8h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1072.8z"
          />
          <path
            d="M1813.8,1067.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1067.5z"
          />
          <path
            d="M1814.5,1069.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1815.7,1072.9,1814.5,1071.6,1814.5,1069.6z M1815.6,1068.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1816.5,1067.2,1815.8,1067.9,1815.6,1068.9z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="36">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M1526.5,2433.6c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1506,2387.8,1526.5,2408.3,1526.5,2433.6"
        />
        <g>
          <path d="M1459.1,2424.3l-2.7-7.2v7.2h-1.1v-8.7h1.6l2.7,7.2l2.7-7.2h1.5v8.7h-1.1v-7.2l-2.7,7.2H1459.1z" />
          <path
            d="M1470.7,2424.3h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2424.3z"
          />
          <path
            d="M1472.5,2417.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2417.9z"
          />
          <path d="M1479.3,2415.5h1.2v1.3h-1.2V2415.5z M1480.5,2424.3h-1.1v-6.4h1.1V2424.3z" />
          <path
            d="M1484.9,2423.4c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1483,2422.5,1483.7,2423.4,1484.9,2423.4z"
          />
          <path d="M1488.6,2415.5h1.2v1.3h-1.2V2415.5z M1489.7,2424.3h-1.1v-6.4h1.1V2424.3z" />
          <path
            d="M1492.6,2423.3v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1493.7,2424.4,1493,2424,1492.6,2423.3z M1492.6,2421.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2421.3z"
          />
          <path
            d="M1498.3,2422.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1499.2,2424.4,1498.3,2423.7,1498.3,2422.6z M1502.2,2421l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2421z"
          />
          <path d="M1506.3,2424.3h-1.1v-8.7h1.1V2424.3z" />
        </g>
        <g>
          <path
            d="M1454.3,2429.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V2429.9z"
          />
          <path
            d="M1457.8,2437.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1460.7,2436.6,1459.5,2437.9,1457.8,2437.9z
				 M1457.8,2437c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1455.9,2436.1,1456.7,2437,1457.8,2437z"
          />
          <path
            d="M1464.7,2437.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1467.6,2436.6,1466.4,2437.9,1464.7,2437.9z
				 M1464.7,2437c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1462.8,2436.1,1463.5,2437,1464.7,2437z"
          />
          <path
            d="M1468.6,2434.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1469.6,2437.9,1468.6,2436.4,1468.6,2434.6z M1471.5,2432.3c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C1473.3,2432.9,1472.4,2432.3,1471.5,2432.3z"
          />
          <path d="M1484,2431.4l1.3,4.7l1.3-4.7h1.1l-1.9,6.4h-1l-1.3-4.7l-1.3,4.7h-1l-1.9-6.4h1.1l1.3,4.7l1.3-4.7H1484z" />
          <path
            d="M1488.3,2436.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1489.3,2437.9,1488.3,2437.2,1488.3,2436.1z M1492.3,2434.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2434.5z"
          />
          <path
            d="M1495.4,2435.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1495.4,2435.8z"
          />
          <path
            d="M1501.3,2432.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2432.4z"
          />
          <path
            d="M1504.8,2434.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1506,2437.9,1504.8,2436.6,1504.8,2434.6z M1505.9,2433.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1506.8,2432.2,1506.1,2432.9,1505.9,2433.9z"
          />
        </g>
        <g>
          <path
            d="M1451.8,2450.5c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1449.9,2449.6,1450.7,2450.5,1451.8,2450.5z"
          />
          <path
            d="M1458.1,2451.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1461,2450.1,1459.8,2451.4,1458.1,2451.4z
				 M1458.1,2450.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1456.2,2449.6,1457,2450.5,1458.1,2450.5z"
          />
          <path
            d="M1463.5,2451.3h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V2451.3z"
          />
          <path
            d="M1474.1,2450.4v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1475.2,2451.4,1474.5,2451,1474.1,2450.4z M1474.1,2448.4c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2448.4z"
          />
          <path
            d="M1482.8,2451.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1485.7,2450.1,1484.5,2451.4,1482.8,2451.4z
				 M1482.8,2450.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1480.9,2449.6,1481.6,2450.5,1482.8,2450.5
				z"
          />
          <path
            d="M1487.2,2449.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1487.2,2449.3z"
          />
          <path
            d="M1493.2,2445.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V2445.9z"
          />
          <path d="M1497.1,2442.6h1.2v1.3h-1.2V2442.6z M1498.2,2451.3h-1.1v-6.4h1.1V2451.3z" />
          <path
            d="M1500.1,2444.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2444.9z"
          />
          <path
            d="M1509.4,2453.6c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C1512.3,2452.6,1511.1,2453.6,1509.4,2453.6z M1509.5,2445.8c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C1511.2,2446.5,1510.4,2445.8,1509.5,2445.8z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="35">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M1865.2,506.6c0,25.3-20.5,45.7-45.8,45.7c-25.3,0-45.8-20.5-45.8-45.7c0-25.3,20.5-45.8,45.8-45.8
			C1844.7,460.9,1865.2,481.3,1865.2,506.6"
        />
        <g>
          <path
            d="M1797.9,498h-1.1V490h3.1c1.7,0,2.8,0.8,2.8,2.3c0,1.5-1.1,2.3-2.8,2.3h-2V498z M1799.8,493.7c1.1,0,1.8-0.5,1.8-1.4
				s-0.6-1.4-1.8-1.4h-1.9v2.8L1799.8,493.7L1799.8,493.7z"
          />
          <path
            d="M1806.8,493.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0V493.2z
				"
          />
          <path
            d="M1807.5,495.1c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C1808.6,498.2,1807.5,496.9,1807.5,495.1z M1808.5,494.5h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1809.4,492.9,1808.7,493.5,1808.5,494.5z"
          />
          <path
            d="M1814.1,496.2c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1814.1,496.2z"
          />
          <path
            d="M1819,495.1c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C1820.2,498.2,1819,496.9,1819,495.1z M1820.1,494.5h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1820.9,492.9,1820.2,493.5,1820.1,494.5z"
          />
          <path
            d="M1825.5,492.2h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V492.2z"
          />
          <path
            d="M1834.2,497.3c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C1832.4,496.4,1833.2,497.3,1834.2,497.3z"
          />
          <path
            d="M1837.3,495.1c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C1838.4,498.2,1837.3,496.9,1837.3,495.1z M1838.3,494.5h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1839.1,492.9,1838.5,493.5,1838.3,494.5z"
          />
        </g>
        <g>
          <path
            d="M1804.2,510.6c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S1805.8,510.6,1804.2,510.6z M1804.2,509.8
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1802.5,508.9,1803.1,509.8,1804.2,509.8z"
          />
          <path
            d="M1810.7,503.2c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0V503.2z"
          />
          <path d="M1816.2,510.5h-1v-8.1h1V510.5z" />
          <path
            d="M1820.2,510.6c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S1821.8,510.6,1820.2,510.6z M1820.2,509.8
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1818.5,508.9,1819.2,509.8,1820.2,509.8z"
          />
          <path
            d="M1826.6,509.7c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C1824.8,508.9,1825.5,509.7,1826.6,509.7z"
          />
          <path
            d="M1829.6,508.9c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C1830.5,510.6,1829.6,510,1829.6,508.9z M1833.2,507.5l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V507.5z"
          />
          <path d="M1837,510.5h-1v-8.1h1V510.5z" />
        </g>
        <g>
          <path
            d="M1805.3,521.2c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1805.3,521.2z"
          />
          <path
            d="M1810.6,514.9h1v3c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.5,0.6-1.5,1.5
				v3.5h-1V514.9z"
          />
          <path
            d="M1819.2,523.1c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S1820.9,523.1,1819.2,523.1z M1819.2,522.2
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1817.5,521.4,1818.2,522.2,1819.2,522.2z"
          />
          <path
            d="M1824.3,522.1v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C1825.3,523.1,1824.6,522.7,1824.3,522.1z M1824.2,520.3c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V520.3z"
          />
          <path
            d="M1830.1,521.2c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1830.1,521.2z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="34">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1137.1,1170.9c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1116.6,1125.1,1137.1,1145.6,1137.1,1170.9"
        />
        <g>
          <path d="M1070.7,1152.9h1.2v7.7h4.4v1h-5.5V1152.9z" />
          <path
            d="M1076.9,1158.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1078.1,1161.7,1076.9,1160.4,1076.9,1158.4z M1078,1157.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1078.9,1156.1,1078.2,1156.7,1078,1157.8z"
          />
          <path d="M1085.5,1161.6l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H1085.5z" />
          <path
            d="M1089.6,1158.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1090.8,1161.7,1089.6,1160.4,1089.6,1158.4z M1090.7,1157.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1091.6,1156.1,1090.9,1156.7,1090.7,1157.8z"
          />
          <path d="M1097.7,1161.6h-1.1v-8.7h1.1V1161.6z" />
          <path
            d="M1105.9,1161.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1108.9,1160.4,1107.6,1161.7,1105.9,1161.7z
				 M1105.9,1160.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1104,1159.9,1104.8,1160.8,1105.9,1160.8z"
          />
          <path
            d="M1113,1153.7c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1153.7z"
          />
        </g>
        <g>
          <path
            d="M1083,1167.3c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1167.3z"
          />
          <path
            d="M1083.5,1171.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1084.8,1175.3,1083.5,1173.9,1083.5,1171.9z M1084.7,1171.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1085.5,1169.6,1084.8,1170.2,1084.7,1171.3z"
          />
          <path
            d="M1090.1,1171.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1091.3,1175.3,1090.1,1173.9,1090.1,1171.9z M1091.2,1171.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1092.1,1169.6,1091.4,1170.2,1091.2,1171.3z"
          />
          <path
            d="M1096.7,1171.9c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1097.7,1175.3,1096.7,1173.7,1096.7,1171.9z M1099.6,1169.6c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C1101.4,1170.2,1100.5,1169.6,1099.6,1169.6z"
          />
        </g>
        <g>
          <path
            d="M1063.5,1187.7v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1064.6,1188.8,1063.9,1188.3,1063.5,1187.7z M1063.5,1185.7c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1185.7z"
          />
          <path
            d="M1072.9,1183.4c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1183.4z"
          />
          <path
            d="M1076.7,1188.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1079.6,1187.4,1078.4,1188.8,1076.7,1188.8z
				 M1076.7,1187.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1074.8,1186.9,1075.5,1187.8,1076.7,1187.8
				z"
          />
          <path
            d="M1080.6,1185.5c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1081.6,1188.8,1080.6,1187.3,1080.6,1185.5z M1083.5,1183.1c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C1085.3,1183.8,1084.4,1183.1,1083.5,1183.1z"
          />
          <path
            d="M1093.3,1188.6h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1188.6z"
          />
          <path
            d="M1097.6,1187.8c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1095.7,1186.9,1096.5,1187.8,1097.6,1187.8z"
          />
          <path
            d="M1101.6,1183.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1183.2z"
          />
          <path d="M1105.5,1179.9h1.2v1.3h-1.2V1179.9z M1106.6,1188.6h-1.1v-6.4h1.1V1188.6z" />
          <path
            d="M1111,1188.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1113.9,1187.4,1112.7,1188.8,1111,1188.8z
				 M1111,1187.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1109.1,1186.9,1109.8,1187.8,1111,1187.8z"
          />
          <path
            d="M1115.3,1182.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1182.3z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="33">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3417.1,1426.3c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3396.6,1380.5,3417.1,1401,3417.1,1426.3"
        />
        <g>
          <path
            d="M3348.5,1400.7c0.5,1,1.5,1.7,2.7,1.7s2-0.6,2-1.6c0-1.1-1-1.2-2.3-1.6c-1.4-0.3-2.7-0.7-2.7-2.3c0-1.5,1.3-2.5,3-2.5
				c1.5,0,2.6,0.7,3.1,1.7l-0.9,0.7c-0.5-0.8-1.1-1.4-2.3-1.4c-1.1,0-1.8,0.6-1.8,1.4c0,0.9,0.8,1.1,2,1.4c1.4,0.3,3,0.6,3,2.5
				c0,1.6-1.4,2.7-3.2,2.7c-1.5,0-3-0.8-3.6-1.9L3348.5,1400.7z"
          />
          <path
            d="M3355.2,1400.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3356.4,1403.4,3355.2,1402,3355.2,1400.1z M3356.3,1399.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3357.2,1397.7,3356.5,1398.3,3356.3,1399.4z"
          />
          <path
            d="M3362.2,1396.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1396.9z"
          />
          <path
            d="M3369.2,1401.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3369.2,1401.3z"
          />
          <path
            d="M3374.5,1400.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3375.7,1403.4,3374.5,1402,3374.5,1400.1z M3375.6,1399.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3376.5,1397.7,3375.8,1398.3,3375.6,1399.4z"
          />
          <path
            d="M3387.9,1403.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3390.9,1402,3389.7,1403.4,3387.9,1403.4z
				 M3387.9,1402.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3386.1,1401.5,3386.8,1402.4,3387.9,1402.4
				z"
          />
          <path
            d="M3395,1395.4c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1395.4z"
          />
        </g>
        <g>
          <path
            d="M3354.8,1416.7h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1416.7z"
          />
          <path
            d="M3359.9,1411.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1411.5z"
          />
          <path
            d="M3363.6,1419.1c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3366.5,1418,3365.3,1419.1,3363.6,1419.1z M3363.7,1411.2c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C3365.5,1411.9,3364.6,1411.2,3363.7,1411.2z"
          />
          <path
            d="M3367.9,1413.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3369.1,1416.9,3367.9,1415.5,3367.9,1413.6z M3369,1412.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3369.9,1411.2,3369.2,1411.8,3369,1412.9z"
          />
          <path
            d="M3374.9,1410.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1410.4z"
          />
          <path
            d="M3384.4,1415.9c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3382.4,1415,3383.2,1415.9,3384.4,1415.9z"
          />
          <path
            d="M3387.7,1413.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.8,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3388.9,1416.9,3387.7,1415.5,3387.7,1413.6z M3388.8,1412.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3389.7,1411.2,3389,1411.8,3388.8,1412.9z"
          />
          <path
            d="M3348.3,1423.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1423.3z"
          />
          <path
            d="M3354.7,1428.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C3357.7,1427.5,3356.5,1428.9,3354.7,1428.9z
				 M3354.7,1427.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4s-1.8,0.9-1.8,2.4C3352.9,1427,3353.6,1427.9,3354.7,1427.9z"
          />
          <path
            d="M3362.4,1427c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3363.4,1428.9,3362.4,1428.2,3362.4,1427z M3366.4,1425.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1425.5z"
          />
          <path
            d="M3368.9,1425.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3369.9,1428.9,3368.9,1427.4,3368.9,1425.6z M3371.9,1423.2c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C3373.7,1423.9,3372.8,1423.2,3371.9,1423.2z"
          />
          <path
            d="M3376.6,1422.4h1.1v6.9c0,1.1-0.7,1.7-1.8,1.7c-0.3,0-0.4,0-0.6,0v-0.9c0.1,0,0.2,0,0.4,0c0.6,0,0.9-0.3,0.9-0.9V1422.4z
				 M3376.5,1420h1.2v1.3h-1.2V1420z"
          />
          <path
            d="M3384.5,1428.7h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1428.7z"
          />
          <path
            d="M3386.5,1426.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3386.5,1426.8z"
          />
          <path
            d="M3392.4,1423.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1423.3z"
          />
        </g>
        <g>
          <path
            d="M3339.1,1441.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3337.2,1440.5,3338,1441.4,3339.1,1441.4z"
          />
          <path
            d="M3345.4,1442.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3348.3,1441.1,3347.1,1442.4,3345.4,1442.4z
				 M3345.4,1441.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3343.5,1440.5,3344.3,1441.5,3345.4,1441.5
				z"
          />
          <path
            d="M3349.7,1435.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1435.9z"
          />
          <path
            d="M3356.7,1440.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3356.7,1440.3z"
          />
          <path
            d="M3367.5,1442.3h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1442.3z"
          />
          <path
            d="M3370.4,1442.3h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1442.3z"
          />
          <path
            d="M3381,1441.3v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3382.1,1442.4,3381.4,1442,3381,1441.3z M3381,1439.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1439.3z"
          />
          <path
            d="M3387.3,1436.8h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1436.8z"
          />
          <path d="M3391.3,1433.5h1.2v1.3h-1.2V1433.5z M3392.4,1442.3h-1.1v-6.4h1.1V1442.3z" />
          <path
            d="M3396.7,1442.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3399.7,1441.1,3398.5,1442.4,3396.7,1442.4z
				 M3396.7,1441.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3394.9,1440.5,3395.6,1441.5,3396.7,1441.5
				z"
          />
          <path
            d="M3401.1,1435.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1435.9z"
          />
        </g>
        <g>
          <path
            d="M3350.5,1454.9v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3351.5,1455.9,3350.8,1455.5,3350.5,1454.9z M3350.4,1452.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1452.8z"
          />
          <path
            d="M3356.1,1454.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3357,1455.9,3356.1,1455.2,3356.1,1454.1z M3360,1452.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1452.5z"
          />
          <path
            d="M3363.2,1450.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1450.4z"
          />
          <path
            d="M3367.3,1450.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1450.4z"
          />
          <path
            d="M3370.8,1452.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3372,1455.9,3370.8,1454.6,3370.8,1452.6z M3371.9,1451.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3372.8,1450.2,3372.1,1450.9,3371.9,1451.9z"
          />
          <path
            d="M3381.1,1450.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1450.5z"
          />
          <path
            d="M3382.3,1449.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1449.4z"
          />
          <path
            d="M3389.3,1453.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3389.3,1453.8z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="32">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3579.6,1311.1c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3559.1,1265.3,3579.6,1285.8,3579.6,1311.1"
        />
        <g>
          <path
            d="M3498.4,1308.5h-1.2v-8.7h3.3c1.8,0,3,0.9,3,2.5c0,1.6-1.2,2.5-3,2.5h-2.2V1308.5z M3500.5,1303.9c1.2,0,1.9-0.5,1.9-1.5
				c0-1-0.7-1.5-1.9-1.5h-2.1v3.1H3500.5z"
          />
          <path
            d="M3504.9,1306.6c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3504.9,1306.6z"
          />
          <path
            d="M3511,1310.8c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C3512.6,1310.2,3512.1,1310.8,3511,1310.8z"
          />
          <path
            d="M3519.4,1307.7c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3517.4,1306.8,3518.2,1307.7,3519.4,1307.7z"
          />
          <path
            d="M3523.1,1299.8h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1299.8z"
          />
          <path
            d="M3532.5,1308.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3535.5,1307.3,3534.2,1308.7,3532.5,1308.7z
				 M3532.5,1307.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3530.6,1306.8,3531.4,1307.7,3532.5,1307.7
				z"
          />
          <path d="M3537.9,1308.5h-1.1v-8.7h1.1V1308.5z" />
          <path
            d="M3542.3,1308.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3545.2,1307.3,3544,1308.7,3542.3,1308.7z
				 M3542.3,1307.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3540.4,1306.8,3541.2,1307.7,3542.3,1307.7
				z"
          />
          <path
            d="M3549.1,1310.9c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3552,1309.8,3550.8,1310.9,3549.1,1310.9z M3549.2,1303c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C3550.9,1303.7,3550.1,1303,3549.2,1303z"
          />
          <path d="M3553.8,1299.8h1.2v1.3h-1.2V1299.8z M3554.9,1308.5h-1.1v-6.4h1.1V1308.5z" />
          <path
            d="M3559.3,1307.7c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3557.4,1306.8,3558.2,1307.7,3559.3,1307.7z"
          />
          <path
            d="M3562.6,1306.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3563.5,1308.7,3562.6,1308,3562.6,1306.8z M3566.5,1305.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1305.3z"
          />
          <path d="M3570.6,1308.5h-1.1v-8.7h1.1V1308.5z" />
        </g>
        <g>
          <path
            d="M3499.8,1320.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3500.7,1322.2,3499.8,1321.5,3499.8,1320.3z M3503.8,1318.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1318.8z"
          />
          <path
            d="M3507.8,1322.1h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1322.1z"
          />
          <path
            d="M3518.4,1321.1v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3519.5,1322.2,3518.8,1321.8,3518.4,1321.1z M3518.4,1319.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1319.1z"
          />
          <path d="M3524.5,1313.3h1.2v1.3h-1.2V1313.3z M3525.6,1322.1h-1.1v-6.4h1.1V1322.1z" />
          <path d="M3529.1,1322.1l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H3529.1z" />
          <path
            d="M3533.1,1320.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3534,1322.2,3533.1,1321.5,3533.1,1320.3z M3537.1,1318.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1318.8z"
          />
          <path d="M3541.1,1322.1h-1.1v-8.7h1.1V1322.1z" />
          <path
            d="M3542.5,1318.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3543.7,1322.2,3542.5,1320.8,3542.5,1318.9z M3543.6,1318.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3544.5,1316.5,3543.8,1317.1,3543.6,1318.2z"
          />
          <path
            d="M3549.5,1315.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1315.7z"
          />
          <path
            d="M3558.9,1321.2c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3557,1320.3,3557.8,1321.2,3558.9,1321.2z"
          />
          <path
            d="M3562.2,1318.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3563.5,1322.2,3562.2,1320.8,3562.2,1318.9z M3563.3,1318.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3564.2,1316.5,3563.5,1317.1,3563.3,1318.2z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="31">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1139.4,815.7c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1118.9,770,1139.4,790.5,1139.4,815.7"
        />
        <g>
          <path
            d="M1049.8,809.8v-5.3h1.2v5.2c0,1.8,0.9,2.6,2.3,2.6c1.3,0,2.3-0.8,2.3-2.6v-5.2h1.2v5.3c0,2.4-1.5,3.6-3.4,3.6
				C1051.3,813.3,1049.8,812.2,1049.8,809.8z"
          />
          <path
            d="M1058.4,811.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1058.4,811.2z"
          />
          <path
            d="M1063.6,810c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2l1,0.3
				c-0.4,1.1-1.3,1.8-2.6,1.8C1064.8,813.3,1063.6,812,1063.6,810z M1064.7,809.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1065.6,807.7,1064.9,808.3,1064.7,809.4z"
          />
          <path
            d="M1075.4,813.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1078.3,812,1077.1,813.3,1075.4,813.3z
				 M1075.4,812.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1073.5,811.5,1074.3,812.4,1075.4,812.4z"
          />
          <path
            d="M1082.4,805.3c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V805.3z"
          />
          <path
            d="M1088.2,812.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1086.2,811.5,1087,812.4,1088.2,812.4z"
          />
          <path
            d="M1091.8,804.5h1.1v3.2c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V804.5z"
          />
          <path
            d="M1098,810c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2l1,0.3
				c-0.4,1.1-1.3,1.8-2.6,1.8C1099.3,813.3,1098,812,1098,810z M1099.2,809.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1100,807.7,1099.3,808.3,1099.2,809.4z"
          />
          <path
            d="M1106,813.2h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V813.2
				z"
          />
          <path d="M1115.3,804.5h1.2v1.3h-1.2V804.5z M1116.5,813.2h-1.1v-6.4h1.1V813.2z" />
          <path
            d="M1120.7,812.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1118.8,811.5,1119.6,812.4,1120.7,812.4z"
          />
          <path
            d="M1123.8,811.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1124.7,813.3,1123.8,812.6,1123.8,811.5z M1127.8,809.9l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V809.9z"
          />
          <path d="M1131.7,813.2h-1.1v-8.7h1.1V813.2z" />
          <path
            d="M1133.5,811.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1133.5,811.2z"
          />
        </g>
        <g>
          <path d="M1058,818h1.2v1.3h-1.2V818z M1059.2,826.7h-1.1v-6.4h1.1V826.7z" />
          <path
            d="M1061,820.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V820.4z"
          />
          <path
            d="M1071.2,825c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1072.2,826.8,1071.2,826.1,1071.2,825z M1075.2,823.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V823.5z"
          />
          <path
            d="M1080.6,829c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7c-0.3,0.6-1.1,1.1-1.9,1.1
				c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8C1083.5,828,1082.3,829,1080.6,829z
				 M1080.7,821.2c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C1082.4,821.9,1081.6,821.2,1080.7,821.2z"
          />
          <path
            d="M1088.6,821.4c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V821.4z"
          />
          <path d="M1089.8,818h1.2v1.3h-1.2V818z M1090.9,826.7h-1.1v-6.4h1.1V826.7z" />
          <path
            d="M1095.3,825.9c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1093.4,825,1094.2,825.9,1095.3,825.9z"
          />
          <path
            d="M1104.1,826.7h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V826.7z"
          />
          <path d="M1107,826.7h-1.1V818h1.1V826.7z" />
          <path
            d="M1109,821.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V821.3z"
          />
          <path
            d="M1118.1,826.7h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V826.7z"
          />
          <path
            d="M1123.2,821.4c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V821.4z"
          />
          <path
            d="M1123.9,823.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1125.1,826.9,1123.9,825.5,1123.9,823.5z M1125,822.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1125.9,821.2,1125.2,821.8,1125,822.9z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="30">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M867.4,2119.5c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C846.9,2073.8,867.4,2094.3,867.4,2119.5"
        />
        <g>
          <path d="M798.6,2102.9l1.7,6.5l1.7-6.5h1.1l-2.2,8.1h-1.1l-1.7-6.3l-1.7,6.3h-1.1l-2.2-8.1h1.1l1.7,6.5l1.7-6.5H798.6z" />
          <path d="M804.1,2102.9h1.1v1.2h-1.1V2102.9z M805.1,2111h-1v-5.9h1V2111z" />
          <path d="M807.8,2111h-1v-8.1h1V2111z" />
          <path d="M810.5,2111h-1v-8.1h1V2111z" />
          <path d="M812.2,2102.9h1.1v1.2h-1.1V2102.9z M813.2,2111h-1v-5.9h1V2111z" />
          <path
            d="M814.9,2105.1h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L814.9,2105.1L814.9,2105.1z"
          />
          <path
            d="M823.5,2113.1c-1.3,0-2.2-0.6-2.6-1.7l0.9-0.4c0.2,0.8,0.8,1.2,1.6,1.2c1,0,1.7-0.5,1.7-1.8v-0.7c-0.3,0.6-1,1-1.8,1
				c-1.6,0-2.5-1.3-2.5-2.9c0-1.6,0.9-2.9,2.5-2.9c0.8,0,1.5,0.4,1.8,1v-0.9h1v5.3C826.2,2112.1,825.1,2113.1,823.5,2113.1z
				 M823.6,2105.9c-1.1,0-1.7,0.8-1.7,2c0,1.2,0.7,2,1.7,2c0.8,0,1.6-0.6,1.6-1.9v-0.2C825.2,2106.5,824.4,2105.9,823.6,2105.9z"
          />
          <path
            d="M827.9,2105.1h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L827.9,2105.1L827.9,2105.1z"
          />
          <path
            d="M833.8,2108c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C834.9,2111.1,833.8,2109.8,833.8,2108z M834.8,2107.4h3.2c0-0.8-0.5-1.6-1.5-1.6
				C835.7,2105.8,835,2106.4,834.8,2107.4z"
          />
          <path
            d="M840.4,2109.1c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L840.4,2109.1z"
          />
          <path
            d="M845.9,2109.1c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L845.9,2109.1z"
          />
        </g>
        <g>
          <path
            d="M798.7,2123.6c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C801.4,2122.3,800.3,2123.6,798.7,2123.6z
				 M798.7,2122.7c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C797,2121.8,797.6,2122.7,798.7,2122.7z"
          />
          <path
            d="M805.2,2116.2c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5H802v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0L805.2,2116.2L805.2,2116.2z"
          />
          <path
            d="M812.2,2116.2c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5H809v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0L812.2,2116.2L812.2,2116.2z"
          />
          <path
            d="M812.7,2121.9c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C813.5,2123.5,812.7,2122.9,812.7,2121.9z M816.3,2120.4l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L816.3,2120.4L816.3,2120.4z"
          />
          <path
            d="M822.1,2118.6c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L822.1,2118.6L822.1,2118.6z"
          />
          <path
            d="M824.2,2123.4h-1v-5.9h1v0.8c0.3-0.5,0.9-0.9,1.7-0.9c0.8,0,1.3,0.4,1.6,1c0.3-0.5,1-1,1.9-1c1.2,0,1.9,0.9,1.9,2.1v3.9
				h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5v3.5h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5V2123.4z"
          />
          <path
            d="M832.6,2120.5c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C833.7,2123.6,832.6,2122.3,832.6,2120.5z M833.6,2119.9h3.2c0-0.8-0.5-1.6-1.5-1.6
				C834.4,2118.3,833.8,2118.9,833.6,2119.9z"
          />
          <path
            d="M842.1,2118.6c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L842.1,2118.6L842.1,2118.6z"
          />
          <path
            d="M843.3,2121.6c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L843.3,2121.6z"
          />
        </g>
        <g>
          <path
            d="M779.7,2131.1h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L779.7,2131.1L779.7,2131.1z"
          />
          <path
            d="M785.6,2136.2c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C788.3,2135,787.2,2136.2,785.6,2136.2z
				 M785.6,2135.3c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C783.9,2134.5,784.6,2135.3,785.6,2135.3z"
          />
          <path
            d="M791.3,2134.5c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C792.1,2136.2,791.3,2135.6,791.3,2134.5z M794.9,2133.1l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L794.9,2133.1L794.9,2133.1z"
          />
          <path
            d="M797.1,2133.2c0-1.7,0.9-3.1,2.6-3.1c0.8,0,1.5,0.4,1.8,1v-3h1v8.1h-1v-0.9c-0.3,0.6-1,1-1.8,1
				C798.1,2136.2,797.1,2134.8,797.1,2133.2z M799.9,2131c-1.1,0-1.7,0.9-1.7,2.2c0,1.3,0.6,2.2,1.7,2.2c0.8,0,1.7-0.6,1.7-2v-0.4
				C801.5,2131.6,800.7,2131,799.9,2131z"
          />
          <path
            d="M803.6,2134.5c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C804.5,2136.2,803.6,2135.6,803.6,2134.5z M807.3,2133.1l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L807.3,2133.1L807.3,2133.1z"
          />
          <path
            d="M810.9,2135.2v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C811.9,2136.2,811.2,2135.8,810.9,2135.2z M810.9,2133.4c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V2133.4z"
          />
          <path
            d="M816.6,2131.1h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L816.6,2131.1L816.6,2131.1z"
          />
          <path d="M821.9,2128h0.8l-2.6,8.1h-0.8L821.9,2128z" />
          <path d="M823.1,2128h1.1v1.2h-1.1V2128z M824.2,2136.1h-1v-5.9h1V2136.1z" />
          <path
            d="M825.7,2130.2h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L825.7,2130.2L825.7,2130.2z"
          />
          <path
            d="M832,2130.2h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.5,0.6-1.5,1.5
				v3.5h-1L832,2130.2L832,2130.2z"
          />
          <path
            d="M840.5,2136.2c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C843.2,2135,842.1,2136.2,840.5,2136.2z
				 M840.5,2135.3c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C838.8,2134.5,839.5,2135.3,840.5,2135.3z"
          />
          <path d="M845.9,2136.1l-2.2-5.9h1.1l1.7,4.6l1.6-4.6h1.1l-2.2,5.9H845.9z" />
          <path
            d="M849.5,2134.5c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C850.4,2136.2,849.5,2135.6,849.5,2134.5z M853.2,2133.1l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L853.2,2133.1L853.2,2133.1z"
          />
          <path
            d="M855.9,2131.1H855v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L855.9,2131.1L855.9,2131.1z"
          />
          <path
            d="M859.1,2133.2c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3H860c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C860.2,2136.2,859.1,2135,859.1,2133.2z M860.1,2132.5h3.2c0-0.8-0.5-1.6-1.5-1.6
				C860.9,2131,860.3,2131.5,860.1,2132.5z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="29">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M1944.4,1229.3c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1923.9,1183.5,1944.4,1204,1944.4,1229.3"
        />
        <g>
          <path d="M1873.7,1211.2l3.4,8.7h-1.2l-0.9-2.3h-3.9l-0.9,2.3h-1.2l3.4-8.7H1873.7z M1871.5,1216.7h3.1l-1.6-4.1L1871.5,1216.7z" />
          <path d="M1879.8,1220l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H1879.8z" />
          <path
            d="M1883.9,1218.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1884.8,1220.1,1883.9,1219.4,1883.9,1218.3z M1887.8,1216.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1216.7z"
          />
          <path d="M1890.7,1211.2h1.2v1.3h-1.2V1211.2z M1891.9,1220h-1.1v-6.4h1.1V1220z" />
          <path d="M1894.8,1220h-1.1v-8.7h1.1V1220z" />
          <path
            d="M1896.1,1218.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1897,1220.1,1896.1,1219.4,1896.1,1218.3z M1900.1,1216.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1216.7z"
          />
          <path
            d="M1904.1,1219v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1905.2,1220.1,1904.5,1219.7,1904.1,1219z M1904.1,1217c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1217z"
          />
          <path d="M1910.2,1211.2h1.2v1.3h-1.2V1211.2z M1911.3,1220h-1.1v-6.4h1.1V1220z" />
          <path d="M1914.2,1220h-1.1v-8.7h1.1V1220z" />
          <path d="M1916,1211.2h1.2v1.3h-1.2V1211.2z M1917.2,1220h-1.1v-6.4h1.1V1220z" />
          <path
            d="M1919.2,1214.5h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1214.5z"
          />
          <path
            d="M1923.5,1222.2c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C1925.1,1221.6,1924.6,1222.2,1923.5,1222.2z"
          />
        </g>
        <g>
          <path
            d="M1871.5,1233.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1874.4,1232.3,1873.2,1233.6,1871.5,1233.6z
				 M1871.5,1232.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1869.6,1231.8,1870.3,1232.7,1871.5,1232.7
				z"
          />
          <path
            d="M1878.6,1225.6c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1225.6z"
          />
          <path
            d="M1882.9,1230.3c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-0.9h1.1v8.5h-1.1v-3.1c-0.4,0.6-1.1,1.1-2,1.1
				C1884,1233.6,1882.9,1232.1,1882.9,1230.3z M1885.9,1228c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C1887.7,1228.6,1886.8,1228,1885.9,1228z"
          />
          <path
            d="M1895.7,1233.5h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1233.5z"
          />
          <path
            d="M1897,1231.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1897.9,1233.6,1897,1232.9,1897,1231.8z M1900.9,1230.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1230.2z"
          />
          <path d="M1905,1233.5h-1.1v-8.7h1.1V1233.5z" />
          <path d="M1906.7,1224.8h1.2v1.3h-1.2V1224.8z M1907.9,1233.5h-1.1v-6.4h1.1V1233.5z" />
          <path
            d="M1912.5,1225.6c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1225.6z"
          />
          <path d="M1913.4,1224.8h1.2v1.3h-1.2V1224.8z M1914.5,1233.5h-1.1v-6.4h1.1V1233.5z" />
          <path
            d="M1915.9,1230.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1917.1,1233.6,1915.9,1232.3,1915.9,1230.3z M1917,1229.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1917.9,1227.9,1917.2,1228.6,1917,1229.6z"
          />
          <path
            d="M1922.5,1230.3c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1923.5,1233.6,1922.5,1232.1,1922.5,1230.3z M1925.5,1228c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C1927.3,1228.6,1926.4,1228,1925.5,1228z"
          />
        </g>
        <g>
          <path
            d="M1873.5,1246.1v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1874.6,1247.1,1873.9,1246.7,1873.5,1246.1z M1873.5,1244.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1244.1z"
          />
          <path
            d="M1879.2,1243.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1880.4,1247.1,1879.2,1245.8,1879.2,1243.8z M1880.3,1243.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1881.2,1241.5,1880.5,1242.1,1880.3,1243.2z"
          />
          <path
            d="M1889.5,1241.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1241.7z"
          />
          <path
            d="M1890.8,1245c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1890.8,1245z"
          />
          <path
            d="M1899.1,1247.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1902.1,1245.8,1900.9,1247.1,1899.1,1247.1z
				 M1899.1,1246.2c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1897.3,1245.3,1898,1246.2,1899.1,1246.2z"
          />
          <path
            d="M1903.5,1240.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1240.6z"
          />
          <path
            d="M1910.3,1240.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1240.6z"
          />
          <path
            d="M1916.8,1243.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1918,1247.1,1916.8,1245.8,1916.8,1243.8z M1917.9,1243.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1918.7,1241.5,1918.1,1242.1,1917.9,1243.2z"
          />
          <path d="M1924.9,1247h-1.1v-8.7h1.1V1247z" />
        </g>
      </g>
      <g onClick={handleClick} id="28">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M4063.3,890.4c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C4042.8,844.7,4063.3,865.2,4063.3,890.4"
        />
        <g>
          <path
            d="M3997.1,880.3c1.1,0,1.9-0.7,2.3-1.8l1.1,0.4c-0.5,1.5-1.7,2.4-3.4,2.4c-2.3,0-3.9-1.8-3.9-4.5c0-2.7,1.7-4.5,3.9-4.5
				c1.7,0,2.9,0.9,3.4,2.4l-1.1,0.4c-0.3-1.1-1.1-1.8-2.3-1.8c-1.6,0-2.8,1.4-2.8,3.5S3995.5,880.3,3997.1,880.3z"
          />
          <path d="M4002.8,881.2h-1.1v-8.7h1.1V881.2z" />
          <path
            d="M4004.1,879.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C4005,881.3,4004.1,880.6,4004.1,879.5z M4008.1,877.9l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V877.9z"
          />
          <path
            d="M4014.3,875.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V875.9z"
          />
          <path d="M4015.5,872.4h1.2v1.3h-1.2V872.4z M4016.6,881.2h-1.1v-6.4h1.1V881.2z" />
          <path
            d="M4018.6,875.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V875.7z"
          />
          <path
            d="M4023,883.4c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C4024.6,882.8,4024.1,883.4,4023,883.4z"
          />
          <path
            d="M4035.1,881.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C4038.1,880,4036.8,881.3,4035.1,881.3z M4035.1,880.4
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C4033.3,879.4,4034,880.4,4035.1,880.4z"
          />
          <path
            d="M4042.2,873.3c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V873.3z"
          />
        </g>
        <g>
          <path
            d="M3984,886.8c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V886.8z"
          />
          <path
            d="M3987.4,894.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3990.4,893.5,3989.2,894.8,3987.4,894.8z M3987.4,893.9
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3985.6,893,3986.3,893.9,3987.4,893.9z"
          />
          <path
            d="M3994.3,894.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3997.3,893.5,3996,894.8,3994.3,894.8z M3994.3,893.9
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3992.5,893,3993.2,893.9,3994.3,893.9z"
          />
          <path
            d="M3998.2,891.5c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3999.2,894.8,3998.2,893.3,3998.2,891.5z M4001.2,889.2c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C4003,889.8,4002.1,889.2,4001.2,889.2z"
          />
          <path d="M4010.8,894.7h-1.1v-8.7h1.1V894.7z" />
          <path
            d="M4012.1,893c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C4013.1,894.8,4012.1,894.1,4012.1,893z M4016.1,891.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V891.4z"
          />
          <path
            d="M4020.1,893.8v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C4021.2,894.8,4020.5,894.4,4020.1,893.8z M4020.1,891.7c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V891.7z"
          />
          <path
            d="M4025.8,891.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C4027,894.8,4025.8,893.5,4025.8,891.5z M4026.9,890.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C4027.8,889.1,4027.1,889.8,4026.9,890.8z"
          />
          <path d="M4033.9,894.7h-1.1v-8.7h1.1V894.7z" />
          <path d="M4036.8,894.7h-1.1v-8.7h1.1V894.7z" />
          <path d="M4038.6,885.9h1.2v1.3h-1.2V885.9z M4039.8,894.7h-1.1v-6.4h1.1V894.7z" />
          <path
            d="M4041.6,888.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V888.3z"
          />
          <path
            d="M4050.9,897c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7c-0.3,0.6-1.1,1.1-1.9,1.1
				c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8C4053.8,896,4052.6,897,4050.9,897z M4051,889.2
				c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2C4052.8,889.8,4051.9,889.2,4051,889.2z"
          />
        </g>
        <g>
          <path
            d="M3983.2,909.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C3986.2,908,3984.9,909.3,3983.2,909.3z
				 M3983.2,908.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4s-1.8,0.9-1.8,2.4C3981.4,907.5,3982.1,908.4,3983.2,908.4z"
          />
          <path
            d="M3989.5,907.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3989.5,907.2z"
          />
          <path
            d="M4000,909.2h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V909.2z"
          />
          <path
            d="M4001.6,907.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L4001.6,907.2z"
          />
          <path
            d="M4007.1,903.8h-1v-0.9h1V901h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V903.8z"
          />
          <path
            d="M4010.2,907.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C4011.2,909.3,4010.2,908.6,4010.2,907.5z M4014.2,905.9l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V905.9z"
          />
          <path d="M4016.7,900.5h1.2v1.3h-1.2V900.5z M4017.9,909.2h-1.1v-6.4h1.1V909.2z" />
          <path
            d="M4019.3,902.8h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V902.8z"
          />
          <path
            d="M4025.3,907.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C4026.3,909.3,4025.3,908.6,4025.3,907.5z M4029.3,905.9l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V905.9z"
          />
          <path
            d="M4033,908.3v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C4034,909.3,4033.3,908.9,4033,908.3z M4032.9,906.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V906.3z"
          />
          <path d="M4038.7,900.5h1.2v1.3h-1.2V900.5z M4039.8,909.2h-1.1v-6.4h1.1V909.2z" />
          <path d="M4042.4,909.2h-1.1v-8.7h1.1V909.2z" />
          <path d="M4043.8,900.5h1.2v1.3h-1.2V900.5z M4044.9,909.2h-1.1v-6.4h1.1V909.2z" />
          <path
            d="M4046.6,903.8h-1v-0.9h1V901h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V903.8z"
          />
          <path
            d="M4050.5,911.5c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C4052.2,910.9,4051.7,911.5,4050.5,911.5z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="27">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M461.8,1435.1c0,25.3-20.5,45.8-45.8,45.8s-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			S461.8,1409.9,461.8,1435.1"
        />
        <g>
          <path d="M397.9,1423.9v1h-4.5v2.7h3.9v1h-3.9v3h4.5v1h-5.7v-8.7H397.9z" />
          <path d="M401.6,1430.1l-1.8,2.5h-1.2l2.3-3.3l-2.1-3h1.3l1.5,2.2l1.6-2.2h1.2l-2.1,3l2.3,3.3h-1.2L401.6,1430.1z" />
          <path
            d="M405.8,1427.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6L405.8,1427.2L405.8,1427.2z"
          />
          <path
            d="M409.3,1429.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C410.5,1432.8,409.3,1431.4,409.3,1429.4z M410.4,1428.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C411.3,1427.1,410.6,1427.7,410.4,1428.8z"
          />
          <path
            d="M416.3,1426.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1426.3z"
          />
          <path
            d="M423.3,1427.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6L423.3,1427.2L423.3,1427.2z"
          />
          <path
            d="M433.7,1432.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C436.6,1431.4,435.4,1432.8,433.7,1432.8z
				 M433.7,1431.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C431.8,1430.9,432.5,1431.8,433.7,1431.8z"
          />
          <path
            d="M440.8,1424.8c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1424.8z"
          />
        </g>
        <g>
          <path
            d="M386.3,1445.2v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C387.4,1446.3,386.7,1445.8,386.3,1445.2z M386.3,1443.2c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1443.2z"
          />
          <path d="M392.4,1437.4h1.2v1.3h-1.2V1437.4z M393.5,1446.1h-1.1v-6.4h1.1V1446.1z" />
          <path
            d="M397.9,1446.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C400.8,1444.9,399.6,1446.3,397.9,1446.3z
				 M397.9,1445.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C396,1444.4,396.7,1445.3,397.9,1445.3z"
          />
          <path
            d="M401.8,1443c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C402.8,1446.3,401.8,1444.8,401.8,1443z M404.7,1440.6c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C406.5,1441.3,405.6,1440.6,404.7,1440.6z"
          />
          <path d="M409.4,1437.4h1.2v1.3h-1.2V1437.4z M410.5,1446.1h-1.1v-6.4h1.1V1446.1z" />
          <path d="M414,1446.1l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H414z" />
          <path
            d="M418.1,1443c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2l1,0.3
				c-0.4,1.1-1.3,1.8-2.6,1.8C419.3,1446.3,418.1,1444.9,418.1,1443z M419.2,1442.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C420.1,1440.6,419.4,1441.2,419.2,1442.3z"
          />
          <path
            d="M428.4,1440.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0L428.4,1440.9L428.4,1440.9z"
          />
          <path
            d="M429.7,1444.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L429.7,1444.2z"
          />
          <path d="M435.4,1437.4h1.2v1.3h-1.2V1437.4z M436.6,1446.1h-1.1v-6.4h1.1V1446.1z" />
          <path
            d="M438.6,1440.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6L438.6,1440.7L438.6,1440.7z"
          />
          <path
            d="M442.9,1448.4c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C444.5,1447.8,444,1448.4,442.9,1448.4z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="26">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1122.5,2119.3c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1102.1,2073.6,1122.5,2094.1,1122.5,2119.3"
        />
        <g>
          <path
            d="M1048.9,2108.7c-0.4,0.8-1.3,1.4-2.5,1.4c-2.2,0-3.9-1.8-3.9-4.5c0-2.7,1.7-4.5,4-4.5c1.7,0,2.9,0.9,3.4,2.4l-1.1,0.4
				c-0.3-1.1-1.1-1.8-2.3-1.8c-1.7,0-2.8,1.4-2.8,3.5c0,2.1,1.1,3.5,2.8,3.5c1.4,0,2.4-1.1,2.4-2.6h-2.5v-1h3.6v4.5h-1.1V2108.7z"
          />
          <path
            d="M1055,2104.8c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2104.8z"
          />
          <path
            d="M1055.7,2106.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1057,2110.2,1055.7,2108.8,1055.7,2106.9z M1056.9,2106.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1057.7,2104.5,1057,2105.1,1056.9,2106.2z"
          />
          <path
            d="M1062.3,2106.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1063.5,2110.2,1062.3,2108.8,1062.3,2106.9z M1063.4,2106.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1064.3,2104.5,1063.6,2105.1,1063.4,2106.2z"
          />
          <path
            d="M1069.3,2103.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2103.7z"
          />
          <path
            d="M1080.2,2108.1c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1080.2,2108.1z"
          />
          <path
            d="M1087,2109.1v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1088.1,2110.2,1087.4,2109.7,1087,2109.1z M1087,2107.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2107.1z"
          />
          <path
            d="M1092.7,2108.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1093.6,2110.1,1092.7,2109.5,1092.7,2108.3z M1096.6,2106.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2106.8z"
          />
          <path
            d="M1102.1,2109.2c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1100.2,2108.3,1101,2109.2,1102.1,2109.2z"
          />
          <path
            d="M1105.5,2106.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1106.7,2110.2,1105.5,2108.8,1105.5,2106.9z M1106.6,2106.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1107.5,2104.5,1106.8,2105.1,1106.6,2106.2z"
          />
        </g>
        <g>
          <path d="M1039.3,2114.8h1.2v1.3h-1.2V2114.8z M1040.5,2123.5h-1.1v-6.4h1.1V2123.5z" />
          <path
            d="M1042.3,2117.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2117.2z"
          />
          <path
            d="M1052.5,2121.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1053.4,2123.7,1052.5,2123,1052.5,2121.8z M1056.5,2120.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2120.3z"
          />
          <path
            d="M1059.4,2117.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2117.2z"
          />
          <path
            d="M1065.8,2120.4c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1066.8,2123.7,1065.8,2122.2,1065.8,2120.4z M1068.8,2118c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C1070.6,2118.7,1069.7,2118,1068.8,2118z"
          />
          <path
            d="M1076.8,2121.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1077.8,2123.7,1076.8,2123,1076.8,2121.8z M1080.8,2120.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2120.3z"
          />
          <path
            d="M1087,2118.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2118.3z"
          />
          <path
            d="M1090.7,2123.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1093.7,2122.3,1092.5,2123.7,1090.7,2123.7z
				 M1090.7,2122.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1088.9,2121.8,1089.6,2122.7,1090.7,2122.7
				z"
          />
          <path
            d="M1100.1,2123.5h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V2123.5z"
          />
          <path
            d="M1102,2117.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2117.2z"
          />
          <path
            d="M1108.4,2120.4c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1109.4,2123.7,1108.4,2122.2,1108.4,2120.4z M1111.3,2118c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C1113.1,2118.7,1112.2,2118,1111.3,2118z"
          />
        </g>
        <g>
          <path d="M1047.5,2137.1h-1.1v-8.7h1.1V2137.1z" />
          <path d="M1049.2,2128.3h1.2v1.3h-1.2V2128.3z M1050.4,2137.1h-1.1v-6.4h1.1V2137.1z" />
          <path d="M1053.8,2137.1l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H1053.8z" />
          <path d="M1058.3,2128.3h1.2v1.3h-1.2V2128.3z M1059.5,2137.1h-1.1v-6.4h1.1V2137.1z" />
          <path
            d="M1061.3,2130.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2130.7z"
          />
          <path
            d="M1070.6,2139.4c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C1073.6,2138.4,1072.3,2139.4,1070.6,2139.4z M1070.7,2131.5c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C1072.5,2132.2,1071.6,2131.5,1070.7,2131.5z"
          />
          <path
            d="M1078.7,2135.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1079.7,2137.2,1078.7,2136.5,1078.7,2135.3z M1082.7,2133.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2133.8z"
          />
          <path
            d="M1088.9,2131.8c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V2131.8z"
          />
          <path
            d="M1089.7,2133.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1090.9,2137.2,1089.7,2135.8,1089.7,2133.9z M1090.8,2133.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1091.7,2131.5,1091,2132.1,1090.8,2133.2z"
          />
          <path
            d="M1096.2,2135.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1097.1,2137.2,1096.2,2136.5,1096.2,2135.3z M1100.2,2133.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V2133.8z"
          />
          <path
            d="M1103.2,2135.1c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1103.2,2135.1z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="25">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M2485.4,331.3c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2464.9,285.5,2485.4,306,2485.4,331.3"
        />
        <g>
          <path
            d="M2408.7,327.8c1.1,0,1.9-0.7,2.3-1.8l1.1,0.4c-0.5,1.5-1.7,2.4-3.4,2.4c-2.3,0-3.9-1.8-3.9-4.5c0-2.7,1.7-4.5,3.9-4.5
				c1.7,0,2.9,0.9,3.4,2.4l-1.1,0.4c-0.3-1.1-1.1-1.8-2.3-1.8c-1.6,0-2.8,1.4-2.8,3.5S2407.1,327.8,2408.7,327.8z"
          />
          <path
            d="M2415.8,328.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2418.8,327.5,2417.5,328.9,2415.8,328.9z M2415.8,327.9
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2414,327,2414.7,327.9,2415.8,327.9z"
          />
          <path
            d="M2420.1,322.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V322.4z"
          />
          <path d="M2428.6,328.7l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H2428.6z" />
          <path
            d="M2432.7,325.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2434,328.9,2432.7,327.5,2432.7,325.6z M2433.8,324.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2434.7,323.2,2434,323.8,2433.8,324.9z"
          />
          <path
            d="M2439.7,322.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V322.4z"
          />
          <path d="M2446.6,320h1.2v1.3h-1.2V320z M2447.7,328.7h-1.1v-6.4h1.1V328.7z" />
          <path
            d="M2449.1,325.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2450.3,328.9,2449.1,327.5,2449.1,325.6z M2450.2,324.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2451.1,323.2,2450.4,323.8,2450.2,324.9z"
          />
          <path
            d="M2456.1,322.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V322.4z"
          />
          <path
            d="M2465.5,327.9c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2463.6,327,2464.4,327.9,2465.5,327.9z"
          />
          <path
            d="M2468.8,325.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2470.1,328.9,2468.8,327.5,2468.8,325.6z M2469.9,324.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2470.8,323.2,2470.1,323.8,2469.9,324.9z"
          />
        </g>
        <g>
          <path d="M2408.2,333.5h1.2v1.3h-1.2V333.5z M2409.3,342.2h-1.1v-6.4h1.1V342.2z" />
          <path
            d="M2411.1,335.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V335.9z"
          />
          <path
            d="M2422,340.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2422,340.3z"
          />
          <path
            d="M2427.7,333.5h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V333.5z"
          />
          <path
            d="M2437.1,342.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2440.1,341,2438.9,342.4,2437.1,342.4z M2437.1,341.4
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2435.3,340.5,2436,341.4,2437.1,341.4z"
          />
          <path
            d="M2442.6,341.3v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2443.6,342.4,2442.9,342,2442.6,341.3z M2442.5,339.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V339.3z"
          />
          <path
            d="M2449.8,341.3v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2450.9,342.4,2450.2,342,2449.8,341.3z M2449.8,339.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V339.3z"
          />
          <path d="M2455.9,333.5h1.2v1.3h-1.2V333.5z M2457,342.2h-1.1v-6.4h1.1V342.2z" />
          <path
            d="M2458.8,335.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V335.9z"
          />
          <path
            d="M2468.1,344.6c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9V341
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2471.1,343.5,2469.9,344.6,2468.1,344.6z M2468.2,336.7c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C2470,337.4,2469.2,336.7,2468.2,336.7z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="24">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2802.9,1229c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2782.4,1183.3,2802.9,1203.8,2802.9,1229"
        />
        <g>
          <path
            d="M2725.5,1211h2.8c2.7,0,4.3,1.7,4.3,4.4c0,2.7-1.7,4.4-4.3,4.4h-2.8V1211z M2728.3,1218.8c2,0,3.2-1.3,3.2-3.4
				c0-2-1.2-3.4-3.2-3.4h-1.6v6.7H2728.3z"
          />
          <path
            d="M2733.6,1216.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2734.8,1219.9,2733.6,1218.5,2733.6,1216.6z M2734.7,1215.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2735.6,1214.2,2734.9,1214.8,2734.7,1215.9z"
          />
          <path
            d="M2741.7,1219.8h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1219.8z"
          />
          <path
            d="M2750.7,1218c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2751.6,1219.9,2750.7,1219.2,2750.7,1218z M2754.6,1216.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1216.5z"
          />
          <path
            d="M2757.6,1213.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1213.4z"
          />
          <path
            d="M2764,1216.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2765,1219.9,2764,1218.4,2764,1216.6z M2767,1214.2c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C2768.8,1214.9,2767.9,1214.2,2767,1214.2z"
          />
          <path
            d="M2778.3,1211.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1211.9z"
          />
          <path
            d="M2781.8,1219.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2784.7,1218.6,2783.5,1219.9,2781.8,1219.9z
				 M2781.8,1219c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2779.9,1218,2780.6,1219,2781.8,1219z"
          />
          <path
            d="M2789.4,1214.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1214.5z"
          />
        </g>
        <g>
          <path
            d="M2727.1,1231.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2727.1,1231.3z"
          />
          <path
            d="M2737.9,1233.3h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1233.3z"
          />
          <path
            d="M2739.9,1231.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2739.9,1231.3z"
          />
          <path
            d="M2745.8,1227.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1227.9z"
          />
          <path
            d="M2749.3,1231.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2750.2,1233.4,2749.3,1232.7,2749.3,1231.6z M2753.3,1230l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1230z"
          />
          <path d="M2756.2,1224.5h1.2v1.3h-1.2V1224.5z M2757.3,1233.3h-1.1v-6.4h1.1V1233.3z" />
          <path
            d="M2759.1,1226.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1226.9z"
          />
          <path
            d="M2765.5,1231.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2766.4,1233.4,2765.5,1232.7,2765.5,1231.6z M2769.4,1230l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1230z"
          />
          <path
            d="M2773.5,1232.4v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2774.6,1233.4,2773.9,1233,2773.5,1232.4z M2773.5,1230.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1230.3z"
          />
          <path d="M2780.7,1233.3h-1.1v-8.7h1.1V1233.3z" />
          <path
            d="M2782.1,1230.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2783.3,1233.4,2782.1,1232.1,2782.1,1230.1z M2783.2,1229.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2784.1,1227.7,2783.4,1228.4,2783.2,1229.4z"
          />
        </g>
        <g>
          <path
            d="M2748.5,1238.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1238.9z"
          />
          <path
            d="M2752,1246.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2754.9,1245.6,2753.7,1246.9,2752,1246.9z M2752,1246
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2750.1,1245.1,2750.8,1246,2752,1246z"
          />
          <path
            d="M2758.8,1246.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2761.8,1245.6,2760.6,1246.9,2758.8,1246.9z
				 M2758.8,1246c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2757,1245.1,2757.7,1246,2758.8,1246z"
          />
          <path
            d="M2762.7,1243.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2763.7,1246.9,2762.7,1245.4,2762.7,1243.6z M2765.7,1241.3c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C2767.5,1241.9,2766.6,1241.3,2765.7,1241.3z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="23">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M2141.5,2435.1c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2121,2389.3,2141.5,2409.8,2141.5,2435.1"
        />
        <g>
          <path
            d="M2064.8,2431.7c1.1,0,1.9-0.7,2.3-1.8l1.1,0.4c-0.5,1.5-1.7,2.4-3.4,2.4c-2.3,0-3.9-1.8-3.9-4.5c0-2.7,1.7-4.5,3.9-4.5
				c1.7,0,2.9,0.9,3.4,2.4l-1.1,0.4c-0.3-1.1-1.1-1.8-2.3-1.8c-1.6,0-2.8,1.4-2.8,3.5S2063.2,2431.7,2064.8,2431.7z"
          />
          <path
            d="M2071.9,2432.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2074.9,2431.4,2073.7,2432.7,2071.9,2432.7z
				 M2071.9,2431.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2070.1,2430.8,2070.8,2431.8,2071.9,2431.8
				z"
          />
          <path
            d="M2076.3,2426.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2426.2z"
          />
          <path d="M2084.7,2432.6l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H2084.7z" />
          <path
            d="M2088.8,2429.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2090.1,2432.7,2088.8,2431.3,2088.8,2429.4z M2090,2428.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2090.8,2427,2090.1,2427.6,2090,2428.7z"
          />
          <path
            d="M2095.9,2426.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2426.2z"
          />
          <path d="M2102.7,2423.8h1.2v1.3h-1.2V2423.8z M2103.8,2432.6h-1.1v-6.4h1.1V2432.6z" />
          <path
            d="M2105.2,2429.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2106.4,2432.7,2105.2,2431.3,2105.2,2429.4z M2106.3,2428.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2107.2,2427,2106.5,2427.6,2106.3,2428.7z"
          />
          <path
            d="M2112.2,2426.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2426.2z"
          />
          <path
            d="M2121.6,2431.7c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2119.7,2430.8,2120.5,2431.7,2121.6,2431.7z"
          />
          <path
            d="M2125,2429.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2126.2,2432.7,2125,2431.3,2125,2429.4z M2126.1,2428.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2126.9,2427,2126.3,2427.6,2126.1,2428.7z"
          />
        </g>
        <g>
          <path d="M2064.3,2437.3h1.2v1.3h-1.2V2437.3z M2065.4,2446.1h-1.1v-6.4h1.1V2446.1z" />
          <path
            d="M2067.2,2439.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2439.7z"
          />
          <path
            d="M2078.1,2444.1c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2078.1,2444.1z"
          />
          <path
            d="M2083.9,2437.3h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2437.3z"
          />
          <path
            d="M2093.2,2446.2c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2096.2,2444.9,2095,2446.2,2093.2,2446.2z
				 M2093.2,2445.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2091.4,2444.3,2092.1,2445.3,2093.2,2445.3
				z"
          />
          <path
            d="M2098.7,2445.1v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2099.8,2446.2,2099.1,2445.8,2098.7,2445.1z M2098.7,2443.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2443.1z"
          />
          <path
            d="M2105.9,2445.1v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2107,2446.2,2106.3,2445.8,2105.9,2445.1z M2105.9,2443.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V2443.1z"
          />
          <path d="M2112,2437.3h1.2v1.3h-1.2V2437.3z M2113.1,2446.1h-1.1v-6.4h1.1V2446.1z" />
          <path
            d="M2115,2439.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V2439.7z"
          />
          <path
            d="M2124.2,2448.4c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2127.2,2447.4,2126,2448.4,2124.2,2448.4z M2124.4,2440.6c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C2126.1,2441.2,2125.3,2440.6,2124.4,2440.6z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="22">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M2070.9,1228.6c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2050.4,1182.9,2070.9,1203.4,2070.9,1228.6"
        />
        <g>
          <path
            d="M1994.6,1212.6h-1.2v-8.7h3.3c1.8,0,3,0.9,3,2.5c0,1.6-1.2,2.5-3,2.5h-2.2V1212.6z M1996.7,1207.9c1.2,0,1.9-0.5,1.9-1.5
				c0-1-0.7-1.5-1.9-1.5h-2.1v3.1H1996.7z"
          />
          <path
            d="M2004.2,1207.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1207.3z"
          />
          <path
            d="M2005,1209.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2006.2,1212.7,2005,1211.4,2005,1209.4z M2006.1,1208.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2007,1207,2006.3,1207.7,2006.1,1208.7z"
          />
          <path
            d="M2012.2,1210.6c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2012.2,1210.6z"
          />
          <path
            d="M2017.5,1209.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2018.7,1212.7,2017.5,1211.4,2017.5,1209.4z M2018.6,1208.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2019.5,1207,2018.8,1207.7,2018.6,1208.7z"
          />
          <path
            d="M2024.5,1206.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1206.2z"
          />
          <path
            d="M2033.9,1211.8c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2032,1210.9,2032.8,1211.8,2033.9,1211.8z"
          />
          <path
            d="M2037.3,1209.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2038.5,1212.7,2037.3,1211.4,2037.3,1209.4z M2038.4,1208.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2039.2,1207,2038.6,1207.7,2038.4,1208.7z"
          />
          <path
            d="M2050.6,1212.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2053.6,1211.4,2052.4,1212.7,2050.6,1212.7z
				 M2050.6,1211.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2048.8,1210.9,2049.5,1211.8,2050.6,1211.8
				z"
          />
          <path
            d="M2057.8,1204.7c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1204.7z"
          />
        </g>
        <g>
          <path
            d="M1995.4,1224.1c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1995.4,1224.1z"
          />
          <path
            d="M2001.2,1217.4h1.1v3.2c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1217.4z"
          />
          <path
            d="M2010.6,1226.2c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2013.5,1224.9,2012.3,1226.2,2010.6,1226.2z
				 M2010.6,1225.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2008.7,1224.4,2009.5,1225.3,2010.6,1225.3
				z"
          />
          <path
            d="M2018.2,1220.8c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1220.8z"
          />
          <path
            d="M2019.6,1220.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1220.7z"
          />
          <path
            d="M2030,1225.3c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2028.1,1224.4,2028.9,1225.3,2030,1225.3z"
          />
          <path
            d="M2033.8,1217.4h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1217.4z"
          />
          <path
            d="M2040.1,1224.4c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2041.1,1226.2,2040.1,1225.5,2040.1,1224.4z M2044.1,1222.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1222.8z"
          />
          <path d="M2047,1217.4h1.2v1.3h-1.2V1217.4z M2048.1,1226.1h-1.1v-6.4h1.1V1226.1z" />
          <path
            d="M2049.9,1219.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1219.7z"
          />
        </g>
        <g>
          <path
            d="M1994.4,1236.4c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1995.4,1239.7,1994.4,1238.2,1994.4,1236.4z M1997.3,1234.1c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C1999.1,1234.7,1998.2,1234.1,1997.3,1234.1z"
          />
          <path d="M2002,1230.9h1.2v1.3h-1.2V1230.9z M2003.1,1239.6h-1.1v-6.4h1.1V1239.6z" />
          <path
            d="M2005.1,1237.6c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2005.1,1237.6z"
          />
          <path
            d="M2011,1234.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1234.2z"
          />
          <path
            d="M2018.3,1234.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1234.3z"
          />
          <path d="M2019.4,1230.9h1.2v1.3h-1.2V1230.9z M2020.6,1239.6h-1.1v-6.4h1.1V1239.6z" />
          <path
            d="M2023.5,1238.7v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2024.6,1239.7,2023.9,1239.3,2023.5,1238.7z M2023.5,1236.7c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1236.7z"
          />
          <path
            d="M2034.7,1239.6h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1239.6z"
          />
          <path
            d="M2036.7,1234.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1234.2z"
          />
          <path d="M2040.6,1230.9h1.2v1.3h-1.2V1230.9z M2041.7,1239.6h-1.1v-6.4h1.1V1239.6z" />
          <path
            d="M2046.1,1239.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2049,1238.4,2047.8,1239.7,2046.1,1239.7z
				 M2046.1,1238.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2044.2,1237.9,2045,1238.8,2046.1,1238.8z"
          />
          <path
            d="M2050.4,1233.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1233.3z"
          />
        </g>
        <g>
          <path
            d="M2002.1,1253.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2005,1251.9,2003.8,1253.3,2002.1,1253.3z
				 M2002.1,1252.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2000.2,1251.4,2001,1252.3,2002.1,1252.3z"
          />
          <path
            d="M2007.5,1252.2v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2008.6,1253.3,2007.9,1252.8,2007.5,1252.2z M2007.5,1250.2c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1250.2z"
          />
          <path
            d="M2013.2,1250c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2014.4,1253.3,2013.2,1251.9,2013.2,1250z M2014.3,1249.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2015.2,1247.6,2014.5,1248.2,2014.3,1249.3z"
          />
          <path
            d="M2023.5,1247.8c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1247.8z"
          />
          <path
            d="M2024.2,1251.4c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2025.2,1253.2,2024.2,1252.6,2024.2,1251.4z M2028.2,1249.9l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1249.9z"
          />
          <path
            d="M2031.3,1247.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1247.7z"
          />
          <path
            d="M2037.8,1253.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2040.7,1251.9,2039.5,1253.3,2037.8,1253.3z
				 M2037.8,1252.3c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2035.9,1251.4,2036.7,1252.3,2037.8,1252.3
				z"
          />
          <path
            d="M2045.4,1247.8c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1247.8z"
          />
          <path
            d="M2046.8,1251.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2046.8,1251.2z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="21">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M4009.1,1249.6c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3988.6,1203.9,4009.1,1224.3,4009.1,1249.6"
        />
        <g>
          <path
            d="M3927.9,1244.6c0.5,1,1.5,1.7,2.7,1.7s2-0.6,2-1.6c0-1.1-1-1.2-2.3-1.6c-1.4-0.3-2.7-0.7-2.7-2.3c0-1.5,1.3-2.5,3-2.5
				c1.5,0,2.6,0.7,3.1,1.7l-0.9,0.7c-0.5-0.8-1.1-1.4-2.3-1.4c-1.1,0-1.8,0.6-1.8,1.4c0,0.9,0.8,1.1,2,1.4c1.4,0.3,3,0.6,3,2.5
				c0,1.6-1.4,2.7-3.2,2.7c-1.5,0-3-0.8-3.6-1.9L3927.9,1244.6z"
          />
          <path
            d="M3940,1247.1h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1247.1z"
          />
          <path
            d="M3942,1245.1c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3942,1245.1z"
          />
          <path
            d="M3947.9,1241.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1241.7z"
          />
          <path
            d="M3951.4,1245.4c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3952.3,1247.2,3951.4,1246.5,3951.4,1245.4z M3955.3,1243.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1243.8z"
          />
          <path d="M3958.2,1238.4h1.2v1.3h-1.2V1238.4z M3959.4,1247.1h-1.1v-6.4h1.1V1247.1z" />
          <path
            d="M3961.2,1240.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1240.7z"
          />
          <path
            d="M3967.5,1245.4c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3968.5,1247.2,3967.5,1246.5,3967.5,1245.4z M3971.5,1243.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1243.8z"
          />
          <path
            d="M3975.5,1246.2v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3976.6,1247.2,3975.9,1246.8,3975.5,1246.2z M3975.5,1244.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1244.1z"
          />
          <path d="M3981.6,1238.4h1.2v1.3h-1.2V1238.4z M3982.8,1247.1h-1.1v-6.4h1.1V1247.1z" />
          <path d="M3985.7,1247.1h-1.1v-8.7h1.1V1247.1z" />
          <path d="M3987.5,1238.4h1.2v1.3h-1.2V1238.4z M3988.6,1247.1h-1.1v-6.4h1.1V1247.1z" />
          <path
            d="M3990.6,1241.7h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1241.7z"
          />
          <path
            d="M3994.9,1249.3c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C3996.5,1248.7,3996.1,1249.3,3994.9,1249.3z"
          />
        </g>
        <g>
          <path
            d="M3948.1,1252.7c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1252.7z"
          />
          <path
            d="M3948.6,1258.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3949.5,1260.7,3948.6,1260,3948.6,1258.9z M3952.5,1257.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1257.3z"
          />
          <path
            d="M3955.7,1255.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1255.2z"
          />
          <path d="M3959.6,1251.9h1.2v1.3h-1.2V1251.9z M3960.7,1260.6h-1.1v-6.4h1.1V1260.6z" />
          <path
            d="M3965,1262.9c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3967.9,1261.9,3966.7,1262.9,3965,1262.9z M3965.1,1255.1c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C3966.8,1255.8,3966,1255.1,3965.1,1255.1z"
          />
          <path
            d="M3974.8,1260.6h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1260.6z"
          />
          <path
            d="M3976.2,1257.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3977.4,1260.7,3976.2,1259.4,3976.2,1257.4z M3977.3,1256.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3978.2,1255.1,3977.5,1255.7,3977.3,1256.8z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="20">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M976.4,1018.7c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C956,973,976.4,993.4,976.4,1018.7"
        />
        <g>
          <path
            d="M910.4,1016.4h-1.1v-8.1h3.1c1.7,0,2.8,0.8,2.8,2.3c0,1.3-0.8,2.1-2.1,2.3l2.2,3.4h-1.2l-2.2-3.4h-1.6V1016.4z
				 M912.3,1012.1c1.1,0,1.8-0.5,1.8-1.4s-0.6-1.4-1.8-1.4h-1.9v2.8H912.3z"
          />
          <path
            d="M916.1,1013.4c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C917.3,1016.5,916.1,1015.3,916.1,1013.4z M917.2,1012.8h3.2c0-0.8-0.5-1.6-1.5-1.6
				C918,1011.3,917.3,1011.8,917.2,1012.8z"
          />
          <path
            d="M927.3,1016.4h-1v-0.8c-0.3,0.5-0.9,0.9-1.7,0.9c-1.3,0-2-0.9-2-2.1v-3.9h1v3.7c0,0.8,0.4,1.4,1.2,1.4
				c0.7,0,1.5-0.6,1.5-1.5v-3.5h1V1016.4z"
          />
          <path
            d="M929.1,1014.6c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L929.1,1014.6z"
          />
          <path
            d="M934,1013.4c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3H935c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C935.1,1016.5,934,1015.3,934,1013.4z M935,1012.8h3.2c0-0.8-0.5-1.6-1.5-1.6
				C935.8,1011.3,935.2,1011.8,935,1012.8z"
          />
          <path
            d="M946.3,1016.5c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S947.9,1016.5,946.3,1016.5z M946.3,1015.6
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C944.6,1014.8,945.3,1015.6,946.3,1015.6z"
          />
          <path
            d="M952.9,1009.1c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0V1009.1z"
          />
        </g>
        <g>
          <path
            d="M906,1021.6c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0L906,1021.6L906,1021.6z"
          />
          <path
            d="M906.4,1027.3c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C907.3,1029,906.4,1028.3,906.4,1027.3z M910.1,1025.8l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L910.1,1025.8L910.1,1025.8z"
          />
          <path
            d="M915.8,1024c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L915.8,1024L915.8,1024z"
          />
          <path
            d="M918,1028.9h-1v-5.9h1v0.8c0.3-0.5,0.9-0.9,1.7-0.9c0.8,0,1.3,0.4,1.6,1c0.3-0.5,1-1,1.9-1c1.2,0,1.9,0.9,1.9,2.1v3.9h-1
				v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5v3.5h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5V1028.9z"
          />
          <path
            d="M933.9,1023l1.2,4.4l1.2-4.4h1l-1.8,5.9h-0.9l-1.2-4.4l-1.2,4.4h-0.9l-1.8-5.9h1l1.2,4.4l1.2-4.4L933.9,1023L933.9,1023z
				"
          />
          <path
            d="M937.9,1027.3c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C938.8,1029,937.9,1028.3,937.9,1027.3z M941.6,1025.8l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L941.6,1025.8L941.6,1025.8z"
          />
          <path
            d="M944.4,1027c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L944.4,1027z"
          />
          <path
            d="M949.9,1023.8H949v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L949.9,1023.8L949.9,1023.8z"
          />
          <path
            d="M953.2,1025.9c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C954.3,1029,953.2,1027.7,953.2,1025.9z M954.2,1025.3h3.2c0-0.8-0.5-1.6-1.5-1.6
				C955,1023.7,954.4,1024.3,954.2,1025.3z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="19">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1274.4,1670.3c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1253.9,1624.6,1274.4,1645,1274.4,1670.3"
        />
        <g>
          <path d="M1203.7,1659l3.4,8.7h-1.2l-0.9-2.3h-3.9l-0.9,2.3h-1.2l3.4-8.7H1203.7z M1201.5,1664.5h3.1l-1.6-4.1L1201.5,1664.5z" />
          <path d="M1209.8,1667.8l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H1209.8z" />
          <path
            d="M1213.9,1666.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1214.8,1667.9,1213.9,1667.2,1213.9,1666.1z M1217.8,1664.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1664.5z"
          />
          <path d="M1220.7,1659h1.2v1.3h-1.2V1659z M1221.8,1667.8h-1.1v-6.4h1.1V1667.8z" />
          <path d="M1224.8,1667.8h-1.1v-8.7h1.1V1667.8z" />
          <path
            d="M1226.1,1666.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1227,1667.9,1226.1,1667.2,1226.1,1666.1z M1230,1664.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1664.5z"
          />
          <path
            d="M1234.1,1666.9v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1235.2,1667.9,1234.5,1667.5,1234.1,1666.9z M1234.1,1664.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1664.8z"
          />
          <path d="M1240.2,1659h1.2v1.3h-1.2V1659z M1241.3,1667.8h-1.1v-6.4h1.1V1667.8z" />
          <path d="M1244.2,1667.8h-1.1v-8.7h1.1V1667.8z" />
          <path d="M1246,1659h1.2v1.3h-1.2V1659z M1247.2,1667.8h-1.1v-6.4h1.1V1667.8z" />
          <path
            d="M1249.1,1662.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1662.4z"
          />
          <path
            d="M1253.5,1670c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C1255.1,1669.4,1254.6,1670,1253.5,1670z"
          />
        </g>
        <g>
          <path
            d="M1194.2,1681.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1197.1,1680.1,1195.9,1681.4,1194.2,1681.4z
				 M1194.2,1680.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1192.3,1679.6,1193.1,1680.5,1194.2,1680.5
				z"
          />
          <path
            d="M1201.3,1673.4c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1673.4z"
          />
          <path
            d="M1205.6,1679.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1206.6,1681.4,1205.6,1680.7,1205.6,1679.6z M1209.6,1678l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1678z"
          />
          <path
            d="M1215.8,1676c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1676z"
          />
          <path
            d="M1216.5,1679.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1217.5,1681.4,1216.5,1680.7,1216.5,1679.6z M1220.5,1678l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1678z"
          />
          <path
            d="M1224.5,1680.4v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1225.6,1681.4,1224.9,1681,1224.5,1680.4z M1224.5,1678.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1678.3z"
          />
          <path d="M1231.7,1681.3h-1.1v-8.7h1.1V1681.3z" />
          <path
            d="M1233.1,1678.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1234.3,1681.4,1233.1,1680.1,1233.1,1678.1z M1234.2,1677.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1235.1,1675.7,1234.4,1676.4,1234.2,1677.4z"
          />
          <path d="M1245.1,1681.3h-1.1v-8.7h1.1V1681.3z" />
          <path
            d="M1246.4,1679.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1247.3,1681.4,1246.4,1680.7,1246.4,1679.6z M1250.4,1678l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1678z"
          />
          <path
            d="M1253.3,1674.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1674.9z"
          />
          <path
            d="M1259.7,1678.1c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1260.7,1681.4,1259.7,1679.9,1259.7,1678.1z M1262.7,1675.8c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C1264.5,1676.4,1263.6,1675.8,1262.7,1675.8z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="18">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M859.5,1443.9c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C839,1398.1,859.5,1418.6,859.5,1443.9"
        />
        <g>
          <path
            d="M783,1432.1c0.5,1,1.5,1.7,2.7,1.7s2-0.6,2-1.6c0-1.1-1-1.2-2.3-1.6c-1.4-0.3-2.7-0.7-2.7-2.3c0-1.5,1.3-2.5,3-2.5
				c1.5,0,2.6,0.7,3.1,1.7l-0.9,0.7c-0.4-0.8-1.1-1.4-2.3-1.4c-1.1,0-1.8,0.6-1.8,1.4c0,0.9,0.8,1.1,2,1.4c1.4,0.3,3,0.6,3,2.5
				c0,1.6-1.4,2.7-3.2,2.7c-1.5,0-3-0.8-3.6-1.9L783,1432.1z"
          />
          <path
            d="M795.1,1434.6h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1434.6z"
          />
          <path
            d="M797.1,1432.6c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L797.1,1432.6z"
          />
          <path
            d="M803,1429.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6L803,1429.2L803,1429.2z"
          />
          <path
            d="M806.5,1432.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C807.4,1434.7,806.5,1434,806.5,1432.9z M810.5,1431.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				L810.5,1431.3L810.5,1431.3z"
          />
          <path d="M813.4,1425.9h1.2v1.3h-1.2V1425.9z M814.5,1434.6h-1.1v-6.4h1.1V1434.6z" />
          <path
            d="M816.3,1428.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1428.2z"
          />
          <path
            d="M822.7,1432.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C823.6,1434.7,822.7,1434,822.7,1432.9z M826.6,1431.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				L826.6,1431.3L826.6,1431.3z"
          />
          <path
            d="M830.7,1433.7v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C831.8,1434.7,831.1,1434.3,830.7,1433.7z M830.7,1431.6c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1431.6z"
          />
          <path d="M837.9,1434.6h-1.1v-8.7h1.1V1434.6z" />
          <path
            d="M839.3,1431.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C840.5,1434.7,839.3,1433.4,839.3,1431.4z M840.4,1430.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C841.3,1429,840.6,1429.7,840.4,1430.7z"
          />
        </g>
        <g>
          <path
            d="M782.4,1446.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L782.4,1446.3z"
          />
          <path
            d="M790.7,1448.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C793.6,1447.1,792.4,1448.4,790.7,1448.4z
				 M790.7,1447.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C788.8,1446.6,789.5,1447.5,790.7,1447.5z"
          />
          <path d="M795,1439.6h1.2v1.3H795V1439.6z M796.1,1448.3H795v-6.4h1.1V1448.3z" />
          <path d="M799,1448.3h-1.1v-8.7h1.1V1448.3z" />
          <path
            d="M805.5,1442.4c-0.1-0.3-0.2-0.6-0.2-0.8c0-0.6,0.2-1,0.6-1.4s0.9-0.6,1.5-0.6c0.6,0,1.1,0.2,1.4,0.5
				c0.3,0.3,0.5,0.8,0.5,1.2c0,0.6-0.2,1-0.5,1.5c-0.2,0.2-0.5,0.5-1,0.8l1.6,1.9c0.1-0.3,0.2-0.6,0.2-0.7c0-0.2,0.1-0.4,0.1-0.7h1
				c-0.1,0.6-0.2,1.1-0.4,1.6c-0.2,0.5-0.3,0.7-0.3,0.6l1.5,1.9h-1.4l-0.8-1c-0.3,0.4-0.6,0.6-0.9,0.8c-0.5,0.3-1,0.4-1.6,0.4
				c-0.9,0-1.6-0.2-2-0.7c-0.4-0.5-0.6-1-0.6-1.7c0-0.7,0.2-1.2,0.6-1.7c0.2-0.3,0.7-0.6,1.4-1
				C805.9,1443,805.7,1442.7,805.5,1442.4z M808.1,1447.3c0.3-0.2,0.6-0.4,0.7-0.7l-2-2.4c-0.6,0.4-0.9,0.7-1.1,0.9
				c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.5,0.2,0.8,0.5,1.1s0.7,0.4,1.1,0.4C807.4,1447.6,807.8,1447.5,808.1,1447.3z M808,1442.3
				c0.2-0.3,0.3-0.6,0.3-0.9c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-0.4,0-0.7,0.1-0.9,0.4c-0.1,0.1-0.1,0.3-0.1,0.5
				c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.4,0.5,0.7,0.9C807.6,1442.7,807.9,1442.5,808,1442.3z"
          />
          <path d="M820.6,1441.9l1.3,4.7l1.3-4.7h1.1l-1.9,6.4h-1l-1.3-4.7l-1.3,4.7h-1l-1.9-6.4h1.1l1.3,4.7l1.3-4.7H820.6z" />
          <path
            d="M824.9,1446.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C825.9,1448.4,824.9,1447.7,824.9,1446.6z M828.9,1445l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				L828.9,1445L828.9,1445z"
          />
          <path
            d="M832,1442.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6L832,1442.9L832,1442.9z"
          />
          <path
            d="M835.5,1445.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C836.7,1448.4,835.5,1447.1,835.5,1445.1z M836.6,1444.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C837.5,1442.7,836.8,1443.4,836.6,1444.4z"
          />
          <path
            d="M845.8,1443c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0L845.8,1443L845.8,1443z"
          />
        </g>
        <g>
          <path
            d="M779.6,1461.6h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				L779.6,1461.6L779.6,1461.6z"
          />
          <path
            d="M788.6,1459.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C789.6,1461.7,788.6,1461,788.6,1459.9z M792.6,1458.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				L792.6,1458.4L792.6,1458.4z"
          />
          <path
            d="M795.5,1455.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1455.3z"
          />
          <path
            d="M801.9,1459.9c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C802.8,1461.7,801.9,1461,801.9,1459.9z M805.8,1458.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				L805.8,1458.4L805.8,1458.4z"
          />
          <path
            d="M811.2,1463.9c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C814.2,1462.9,812.9,1463.9,811.2,1463.9z M811.3,1456.1c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				v-0.2C813.1,1456.8,812.2,1456.1,811.3,1456.1z"
          />
          <path
            d="M815.6,1458.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C816.8,1461.7,815.6,1460.4,815.6,1458.4z M816.7,1457.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C817.6,1456.1,816.9,1456.7,816.7,1457.8z"
          />
          <path
            d="M823.7,1461.6h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				L823.7,1461.6L823.7,1461.6z"
          />
          <path
            d="M832.7,1458.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C833.9,1461.7,832.7,1460.4,832.7,1458.4z M833.8,1457.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C834.7,1456.1,834,1456.7,833.8,1457.8z"
          />
          <path
            d="M839.7,1455.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1455.3z"
          />
          <path
            d="M846.8,1456.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6L846.8,1456.2L846.8,1456.2z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="17">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3304.3,1524c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3283.8,1478.3,3304.3,1498.7,3304.3,1524"
        />
        <g>
          <path d="M3238.6,1499.2l3.4,8.7h-1.2l-0.9-2.3h-3.9l-0.9,2.3h-1.2l3.4-8.7H3238.6z M3236.4,1504.7h3.1l-1.6-4.1L3236.4,1504.7z" />
          <path
            d="M3244.2,1507v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3245.3,1508.1,3244.6,1507.7,3244.2,1507z M3244.2,1505c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1505z"
          />
          <path d="M3250.3,1499.2h1.2v1.3h-1.2V1499.2z M3251.4,1508h-1.1v-6.4h1.1V1508z" />
          <path d="M3254.3,1508h-1.1v-8.7h1.1V1508z" />
          <path d="M3256.1,1499.2h1.2v1.3h-1.2V1499.2z M3257.3,1508h-1.1v-6.4h1.1V1508z" />
          <path
            d="M3259.3,1502.5h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1502.5z"
          />
          <path
            d="M3263.6,1510.2c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C3265.2,1509.6,3264.7,1510.2,3263.6,1510.2z"
          />
          <path
            d="M3273.4,1502.5h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1502.5z"
          />
          <path
            d="M3279.9,1508.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3282.9,1506.8,3281.6,1508.1,3279.9,1508.1z
				 M3279.9,1507.2c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3278.1,1506.2,3278.8,1507.2,3279.9,1507.2
				z"
          />
        </g>
        <g>
          <path d="M3230.2,1512.8h1.2v1.3h-1.2V1512.8z M3231.4,1521.5h-1.1v-6.4h1.1V1521.5z" />
          <path
            d="M3234.3,1521.5h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1521.5z"
          />
          <path
            d="M3244.9,1520.6v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3246,1521.6,3245.3,1521.2,3244.9,1520.6z M3244.9,1518.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1518.5z"
          />
          <path d="M3252.1,1521.5h-1.1v-8.7h1.1V1521.5z" />
          <path
            d="M3253.5,1518.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3254.7,1521.6,3253.5,1520.3,3253.5,1518.3z M3254.6,1517.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3255.5,1515.9,3254.8,1516.6,3254.6,1517.6z"
          />
          <path
            d="M3261.6,1521.5h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1521.5z"
          />
          <path
            d="M3270.7,1518.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3271.9,1521.6,3270.7,1520.3,3270.7,1518.3z M3271.8,1517.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3272.7,1515.9,3272,1516.6,3271.8,1517.6z"
          />
          <path
            d="M3277.7,1515.1h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1515.1z"
          />
          <path
            d="M3284.7,1516.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1516.1z"
          />
        </g>
        <g>
          <path
            d="M3227,1533.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3227.9,1535.1,3227,1534.4,3227,1533.3z M3230.9,1531.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1531.7z"
          />
          <path
            d="M3235,1534.1v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3236.1,1535.1,3235.4,1534.7,3235,1534.1z M3235,1532.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1532.1z"
          />
          <path
            d="M3242.2,1534.1v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3243.3,1535.1,3242.6,1534.7,3242.2,1534.1z M3242.2,1532.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1532.1z"
          />
          <path
            d="M3251.6,1529.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1529.7z"
          />
          <path
            d="M3255.4,1535.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3258.3,1533.8,3257.1,1535.1,3255.4,1535.1z
				 M3255.4,1534.2c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3253.5,1533.3,3254.2,1534.2,3255.4,1534.2
				z"
          />
          <path
            d="M3260.8,1534.1v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3261.9,1535.1,3261.2,1534.7,3260.8,1534.1z M3260.8,1532.1c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1532.1z"
          />
          <path
            d="M3270.2,1529.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1529.7z"
          />
          <path d="M3271.4,1526.3h1.2v1.3h-1.2V1526.3z M3272.5,1535h-1.1v-6.4h1.1V1535z" />
          <path
            d="M3273.8,1533.3c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3274.8,1535.1,3273.8,1534.4,3273.8,1533.3z M3277.8,1531.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1531.7z"
          />
          <path
            d="M3280.9,1529.6h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1529.6z"
          />
          <path
            d="M3284.4,1531.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3285.7,1535.1,3284.4,1533.8,3284.4,1531.8z M3285.5,1531.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3286.4,1529.5,3285.7,1530.1,3285.5,1531.2z"
          />
        </g>
        <g>
          <path
            d="M3227.1,1540.6c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1540.6z"
          />
          <path
            d="M3230.6,1548.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3233.5,1547.3,3232.3,1548.7,3230.6,1548.7z
				 M3230.6,1547.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3228.7,1546.8,3229.5,1547.7,3230.6,1547.7
				z"
          />
          <path
            d="M3237.4,1548.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3240.4,1547.3,3239.2,1548.7,3237.4,1548.7z
				 M3237.4,1547.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3235.6,1546.8,3236.3,1547.7,3237.4,1547.7
				z"
          />
          <path
            d="M3241.3,1545.3c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3242.3,1548.7,3241.3,1547.1,3241.3,1545.3z M3244.3,1543c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C3246.1,1543.6,3245.2,1543,3244.3,1543z"
          />
          <path
            d="M3255.4,1547.7c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3253.5,1546.8,3254.3,1547.7,3255.4,1547.7z"
          />
          <path
            d="M3259.2,1539.8h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1539.8z"
          />
          <path
            d="M3268.6,1548.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3271.5,1547.3,3270.3,1548.7,3268.6,1548.7z
				 M3268.6,1547.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3266.7,1546.8,3267.4,1547.7,3268.6,1547.7
				z"
          />
          <path d="M3272.8,1539.8h1.2v1.3h-1.2V1539.8z M3274,1548.5h-1.1v-6.4h1.1V1548.5z" />
          <path
            d="M3278.4,1547.7c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3276.5,1546.8,3277.2,1547.7,3278.4,1547.7z"
          />
          <path
            d="M3281.7,1545.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3282.9,1548.7,3281.7,1547.3,3281.7,1545.3z M3282.8,1544.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3283.7,1543,3283,1543.6,3282.8,1544.7z"
          />
          <path
            d="M3288.9,1546.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3288.9,1546.5z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="16">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M750.7,2491.2c0,25.3-20.5,45.8-45.8,45.8s-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			S750.7,2465.9,750.7,2491.2"
        />
        <g>
          <path d="M685.8,2474.5v0.9h-4.2v2.5h3.6v0.9h-3.6v2.8h4.2v0.9h-5.3v-8.1H685.8z" />
          <path
            d="M686.8,2479.7c0-1.7,0.9-3.1,2.6-3.1c0.8,0,1.5,0.4,1.8,1v-3h1v8.1h-1v-0.9c-0.3,0.6-1,1-1.8,1
				C687.7,2482.7,686.8,2481.3,686.8,2479.7z M689.5,2477.5c-1.1,0-1.7,0.9-1.7,2.2c0,1.3,0.6,2.2,1.7,2.2c0.8,0,1.7-0.6,1.7-2v-0.4
				C691.2,2478.1,690.4,2477.5,689.5,2477.5z"
          />
          <path
            d="M698.5,2482.6h-1v-0.8c-0.3,0.5-0.9,0.9-1.7,0.9c-1.3,0-2-0.9-2-2.1v-3.9h1v3.7c0,0.8,0.4,1.4,1.2,1.4
				c0.7,0,1.5-0.5,1.5-1.5v-3.5h1V2482.6z"
          />
          <path
            d="M702.5,2481.8c0.8,0,1.3-0.5,1.5-1.2l0.9,0.4c-0.3,1-1.1,1.7-2.4,1.7c-1.6,0-2.8-1.2-2.8-3.1c0-1.8,1.1-3.1,2.8-3.1
				c1.2,0,2.1,0.7,2.3,1.7l-0.9,0.4c-0.2-0.7-0.7-1.2-1.5-1.2c-1,0-1.8,0.8-1.8,2.2C700.8,2481,701.5,2481.8,702.5,2481.8z"
          />
          <path
            d="M705.6,2481c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C706.4,2482.7,705.6,2482.1,705.6,2481z M709.2,2479.6l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L709.2,2479.6L709.2,2479.6z"
          />
          <path
            d="M712.1,2477.6h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L712.1,2477.6L712.1,2477.6z"
          />
          <path d="M715.7,2474.5h1.1v1.2h-1.1V2474.5z M716.8,2482.6h-1v-5.9h1V2482.6z" />
          <path
            d="M720.8,2482.7c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C723.5,2481.5,722.4,2482.7,720.8,2482.7z
				 M720.8,2481.8c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C719.1,2481,719.7,2481.8,720.8,2481.8z"
          />
          <path
            d="M724.8,2476.7h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L724.8,2476.7L724.8,2476.7z"
          />
        </g>
        <g>
          <path
            d="M682,2495.2c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C684.7,2494,683.6,2495.2,682,2495.2z
				 M682,2494.3c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C680.3,2493.5,680.9,2494.3,682,2494.3z"
          />
          <path
            d="M688.5,2487.8c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0L688.5,2487.8L688.5,2487.8z"
          />
          <path
            d="M695.6,2487.8c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0L695.6,2487.8L695.6,2487.8z"
          />
          <path
            d="M696,2493.5c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C696.8,2495.2,696,2494.5,696,2493.5z M699.6,2492.1l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L699.6,2492.1L699.6,2492.1z"
          />
          <path
            d="M705.4,2490.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L705.4,2490.2L705.4,2490.2z"
          />
          <path
            d="M707.5,2495.1h-1v-5.9h1v0.8c0.3-0.5,0.9-0.9,1.7-0.9c0.8,0,1.3,0.4,1.6,1c0.3-0.5,1-1,1.9-1c1.2,0,1.9,0.9,1.9,2.1v3.9
				h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5v3.5h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5V2495.1z"
          />
          <path
            d="M715.9,2492.1c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C717,2495.2,715.9,2493.9,715.9,2492.1z M716.9,2491.5h3.2c0-0.8-0.5-1.6-1.5-1.6
				C717.7,2489.9,717.1,2490.5,716.9,2491.5z"
          />
          <path
            d="M725.4,2490.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L725.4,2490.2L725.4,2490.2z"
          />
          <path
            d="M726.6,2493.2c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L726.6,2493.2z"
          />
        </g>
        <g>
          <path
            d="M666.9,2507.7c-1.6,0-2.7-1.2-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1C669.7,2506.4,668.5,2507.7,666.9,2507.7z
				 M666.9,2506.8c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C665.2,2505.9,665.9,2506.8,666.9,2506.8z"
          />
          <path
            d="M670.9,2501.7h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L670.9,2501.7L670.9,2501.7z"
          />
          <path
            d="M681,2505.7c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L681,2505.7z"
          />
          <path
            d="M690.9,2507.5h-1v-0.8c-0.3,0.5-0.9,0.9-1.7,0.9c-1.3,0-2-0.9-2-2.1v-3.9h1v3.7c0,0.8,0.4,1.4,1.2,1.4
				c0.7,0,1.5-0.5,1.5-1.5v-3.5h1V2507.5z"
          />
          <path
            d="M692.8,2505.7c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9c0-0.6-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6
				c0-1,0.9-1.8,2.2-1.8c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8
				c1,0.2,2.2,0.4,2.2,1.7c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L692.8,2505.7z"
          />
          <path
            d="M698.2,2502.5h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L698.2,2502.5L698.2,2502.5z"
          />
          <path
            d="M701.4,2506c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C702.3,2507.6,701.4,2507,701.4,2506z M705.1,2504.5l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L705.1,2504.5L705.1,2504.5z"
          />
          <path d="M707.8,2499.5h1.1v1.2h-1.1V2499.5z M708.8,2507.5h-1v-5.9h1V2507.5z" />
          <path
            d="M710.5,2501.7h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L710.5,2501.7L710.5,2501.7z"
          />
          <path
            d="M716.4,2506c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C717.2,2507.6,716.4,2507,716.4,2506z M720,2504.5l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L720,2504.5L720,2504.5z"
          />
          <path
            d="M723.7,2506.7v0.9h-1v-8.1h1v3c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C724.7,2507.7,724.1,2507.3,723.7,2506.7z M723.7,2504.8c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V2504.8z"
          />
          <path d="M729.4,2499.5h1.1v1.2h-1.1V2499.5z M730.4,2507.5h-1v-5.9h1V2507.5z" />
          <path d="M733.1,2507.5h-1v-8.1h1V2507.5z" />
          <path d="M734.8,2499.5h1.1v1.2h-1.1V2499.5z M735.8,2507.5h-1v-5.9h1V2507.5z" />
          <path
            d="M737.6,2502.5h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L737.6,2502.5L737.6,2502.5z"
          />
          <path
            d="M741.7,2509.6c-0.2,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,0.9-0.7l0.3-0.7l-2.2-5.7h1.1l1.7,4.5
				l1.6-4.5h1.1l-2.5,6.5C743.1,2509.1,742.7,2509.6,741.7,2509.6z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="15">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3735.5,1121.7c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3715.1,1076,3735.5,1096.4,3735.5,1121.7"
        />
        <g>
          <path d="M3669.1,1091.9h1.2v7.7h4.4v1h-5.5V1091.9z" />
          <path
            d="M3675.3,1097.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3676.5,1100.8,3675.3,1099.5,3675.3,1097.5z M3676.4,1096.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3677.3,1095.1,3676.6,1095.7,3676.4,1096.8z"
          />
          <path d="M3683.9,1100.7l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H3683.9z" />
          <path
            d="M3688,1097.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3689.2,1100.8,3688,1099.5,3688,1097.5z M3689.1,1096.8h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3690,1095.1,3689.3,1095.7,3689.1,1096.8z"
          />
          <path d="M3696.1,1100.7h-1.1v-8.7h1.1V1100.7z" />
          <path
            d="M3704.3,1100.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3707.3,1099.5,3706.1,1100.8,3704.3,1100.8z
				 M3704.3,1099.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3702.5,1098.9,3703.2,1099.9,3704.3,1099.9
				z"
          />
          <path
            d="M3711.4,1092.8c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1092.8z"
          />
        </g>
        <g>
          <path
            d="M3665.6,1113.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3663.7,1112.5,3664.5,1113.4,3665.6,1113.4z"
          />
          <path
            d="M3671.7,1114.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C3674.6,1113,3673.4,1114.3,3671.7,1114.3z
				 M3671.7,1113.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3669.8,1112.5,3670.6,1113.4,3671.7,1113.4
				z"
          />
          <path
            d="M3675.8,1105.5h1.1v3.2c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1105.5z"
          />
          <path
            d="M3682,1111c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.8,0,1.5-0.5,1.7-1.2l1,0.3
				c-0.4,1.1-1.3,1.8-2.6,1.8C3683.2,1114.3,3682,1113,3682,1111z M3683.1,1110.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3684,1108.6,3683.3,1109.3,3683.1,1110.3z"
          />
          <path
            d="M3692,1108.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1108.9z"
          />
          <path
            d="M3692.5,1111c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3693.8,1114.3,3692.5,1113,3692.5,1111z M3693.6,1110.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3694.5,1108.6,3693.8,1109.3,3693.6,1110.3z"
          />
          <path
            d="M3699.3,1107.8h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1107.8z"
          />
          <path
            d="M3708.5,1113.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3706.6,1112.5,3707.4,1113.4,3708.5,1113.4z"
          />
          <path
            d="M3711.6,1111c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3712.8,1114.3,3711.6,1113,3711.6,1111z M3712.7,1110.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3713.6,1108.6,3712.9,1109.3,3712.7,1110.3z"
          />
          <path d="M3673,1117.5h1.2v1.3h-1.2V1117.5z M3674.1,1126.2h-1.1v-6.4h1.1V1126.2z" />
          <path
            d="M3675.7,1119.8h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1119.8z"
          />
          <path
            d="M3687.4,1118.3c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1118.3z"
          />
          <path
            d="M3690.7,1126.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C3693.6,1125,3692.4,1126.3,3690.7,1126.3z
				 M3690.7,1125.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4s-1.8,0.9-1.8,2.4C3688.8,1124.5,3689.6,1125.4,3690.7,1125.4z"
          />
          <path
            d="M3697.3,1126.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C3700.2,1125,3699,1126.3,3697.3,1126.3z
				 M3697.3,1125.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4s-1.8,0.9-1.8,2.4C3695.4,1124.5,3696.2,1125.4,3697.3,1125.4z"
          />
          <path
            d="M3701,1123c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3702,1126.3,3701,1124.8,3701,1123z M3703.9,1120.7c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C3705.7,1121.3,3704.8,1120.7,3703.9,1120.7z"
          />
        </g>
        <g>
          <path
            d="M3662,1138.7h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1138.7z"
          />
          <path
            d="M3671.1,1135.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3672.3,1138.8,3671.1,1137.5,3671.1,1135.5z M3672.2,1134.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3673.1,1133.2,3672.4,1133.8,3672.2,1134.9z"
          />
          <path
            d="M3678.2,1136.7c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3678.2,1136.7z"
          />
          <path
            d="M3684.1,1136.7c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3684.1,1136.7z"
          />
          <path
            d="M3689.4,1137c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3690.3,1138.8,3689.4,1138.1,3689.4,1137z M3693.4,1135.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1135.4z"
          />
          <path
            d="M3698.7,1141c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3701.7,1140,3700.5,1141,3698.7,1141z M3698.8,1133.2c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C3700.6,1133.9,3699.8,1133.2,3698.8,1133.2z"
          />
          <path d="M3703.5,1130h1.2v1.3h-1.2V1130z M3704.6,1138.7h-1.1v-6.4h1.1V1138.7z" />
          <path
            d="M3706.4,1132.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1132.3z"
          />
          <path
            d="M3715.7,1141c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3718.7,1140,3717.4,1141,3715.7,1141z M3715.8,1133.2c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C3717.6,1133.9,3716.7,1133.2,3715.8,1133.2z"
          />
        </g>
        <g>
          <path d="M3667,1141.5h1.2v1.3h-1.2V1141.5z M3668.1,1150.2h-1.1v-6.4h1.1V1150.2z" />
          <path
            d="M3670,1143.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1143.9z"
          />
          <path
            d="M3681.8,1150.2h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1150.2z"
          />
          <path
            d="M3690.8,1147c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3692,1150.4,3690.8,1149,3690.8,1147z M3691.9,1146.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3692.8,1144.7,3692.1,1145.3,3691.9,1146.4z"
          />
          <path
            d="M3697.4,1147c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3698.4,1150.4,3697.4,1148.8,3697.4,1147z M3700.4,1144.7c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C3702.2,1145.3,3701.3,1144.7,3700.4,1144.7z"
          />
          <path d="M3705,1141.5h1.2v1.3h-1.2V1141.5z M3706.1,1150.2h-1.1v-6.4h1.1V1150.2z" />
          <path
            d="M3707.5,1148.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3708.4,1150.3,3707.5,1149.6,3707.5,1148.5z M3711.4,1147l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1147z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="14">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2805.6,1425.7c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2785.1,1379.9,2805.6,1400.4,2805.6,1425.7"
        />
        <g>
          <path
            d="M2733.6,1407.7h2.8c2.7,0,4.3,1.7,4.3,4.4c0,2.7-1.7,4.4-4.3,4.4h-2.8V1407.7z M2736.4,1415.4c2,0,3.2-1.3,3.2-3.4
				c0-2-1.2-3.4-3.2-3.4h-1.6v6.7H2736.4z"
          />
          <path
            d="M2741.7,1413.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2742.9,1416.5,2741.7,1415.2,2741.7,1413.2z M2742.8,1412.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2743.7,1410.9,2743,1411.5,2742.8,1412.6z"
          />
          <path
            d="M2751.1,1418.7c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C2754.1,1417.7,2752.8,1418.7,2751.1,1418.7z M2751.2,1410.9c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2
				c0.9,0,1.8-0.7,1.8-2.1v-0.2C2753,1411.6,2752.1,1410.9,2751.2,1410.9z"
          />
          <path
            d="M2759.2,1411.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1411.1z"
          />
          <path
            d="M2760,1413.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2761.2,1416.5,2760,1415.2,2760,1413.2z M2761.1,1412.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2762,1410.9,2761.3,1411.5,2761.1,1412.6z"
          />
          <path
            d="M2766.5,1413.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2767.8,1416.5,2766.5,1415.2,2766.5,1413.2z M2767.6,1412.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2768.5,1410.9,2767.8,1411.5,2767.6,1412.6z"
          />
          <path
            d="M2779.9,1416.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2782.9,1415.2,2781.7,1416.5,2779.9,1416.5z
				 M2779.9,1415.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2778.1,1414.7,2778.8,1415.6,2779.9,1415.6
				z"
          />
          <path
            d="M2787,1408.5c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1408.5z"
          />
        </g>
        <g>
          <path
            d="M2715.6,1428c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2715.6,1428z"
          />
          <path
            d="M2726.4,1429.9h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1429.9z"
          />
          <path
            d="M2728.4,1428c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2728.4,1428z"
          />
          <path
            d="M2734.3,1424.5h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1424.5z"
          />
          <path
            d="M2737.8,1428.2c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2738.7,1430,2737.8,1429.4,2737.8,1428.2z M2741.7,1426.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1426.7z"
          />
          <path d="M2744.6,1421.2h1.2v1.3h-1.2V1421.2z M2745.8,1429.9h-1.1v-6.4h1.1V1429.9z" />
          <path
            d="M2747.6,1423.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1423.6z"
          />
          <path
            d="M2754,1428.2c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2754.9,1430,2754,1429.4,2754,1428.2z M2757.9,1426.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V1426.7z"
          />
          <path
            d="M2762,1429v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2763,1430.1,2762.3,1429.6,2762,1429z M2761.9,1427c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1427z"
          />
          <path d="M2769.2,1429.9h-1.1v-8.7h1.1V1429.9z" />
          <path
            d="M2770.6,1426.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2771.8,1430.1,2770.6,1428.7,2770.6,1426.8z M2771.7,1426.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2772.6,1424.4,2771.9,1425,2771.7,1426.1z"
          />
          <path
            d="M2784.2,1422.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1422.1z"
          />
          <path
            d="M2787.7,1430.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2790.6,1428.7,2789.4,1430.1,2787.7,1430.1z
				 M2787.7,1429.1c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2785.8,1428.2,2786.6,1429.1,2787.7,1429.1
				z"
          />
          <path
            d="M2794.6,1430.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2797.5,1428.7,2796.3,1430.1,2794.6,1430.1z
				 M2794.6,1429.1c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2792.7,1428.2,2793.4,1429.1,2794.6,1429.1
				z"
          />
          <path
            d="M2798.5,1426.8c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2799.5,1430.1,2798.5,1428.6,2798.5,1426.8z M2801.4,1424.4c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.5C2803.2,1425.1,2802.3,1424.4,2801.4,1424.4z"
          />
        </g>
        <g>
          <path
            d="M2727.6,1442.6c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2725.7,1441.7,2726.5,1442.6,2727.6,1442.6z"
          />
          <path
            d="M2733.9,1443.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2736.9,1442.2,2735.7,1443.6,2733.9,1443.6z
				 M2733.9,1442.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2732.1,1441.7,2732.8,1442.6,2733.9,1442.6
				z"
          />
          <path
            d="M2738.3,1437.1h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1437.1z"
          />
          <path
            d="M2745.3,1441.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2745.3,1441.5z"
          />
          <path
            d="M2756.1,1443.4h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1443.4z"
          />
          <path
            d="M2759,1443.4h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1443.4z"
          />
          <path
            d="M2769.6,1442.5v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2770.7,1443.6,2770,1443.2,2769.6,1442.5z M2769.6,1440.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1440.5z"
          />
          <path
            d="M2775.9,1438h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1438z"
          />
          <path d="M2779.8,1434.7h1.2v1.3h-1.2V1434.7z M2781,1443.4h-1.1v-6.4h1.1V1443.4z" />
          <path
            d="M2785.3,1443.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2788.3,1442.2,2787,1443.6,2785.3,1443.6z
				 M2785.3,1442.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2783.4,1441.7,2784.2,1442.6,2785.3,1442.6
				z"
          />
          <path
            d="M2789.6,1437.1h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1437.1z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="13">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M3847.5,1564.2c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3827,1518.4,3847.5,1538.9,3847.5,1564.2"
        />
        <g>
          <path
            d="M3775.5,1546.2h2.8c2.7,0,4.3,1.7,4.3,4.4c0,2.7-1.7,4.4-4.3,4.4h-2.8V1546.2z M3778.3,1553.9c2,0,3.2-1.3,3.2-3.4
				c0-2-1.2-3.4-3.2-3.4h-1.6v6.7H3778.3z"
          />
          <path
            d="M3783.6,1551.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3784.8,1555,3783.6,1553.7,3783.6,1551.7z M3784.7,1551.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3785.6,1549.4,3784.9,1550,3784.7,1551.1z"
          />
          <path
            d="M3793,1557.2c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3796,1556.2,3794.7,1557.2,3793,1557.2z M3793.1,1549.4c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C3794.9,1550.1,3794,1549.4,3793.1,1549.4z"
          />
          <path
            d="M3801.1,1549.6c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1549.6z"
          />
          <path
            d="M3801.8,1551.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3803.1,1555,3801.8,1553.7,3801.8,1551.7z M3803,1551.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3803.8,1549.4,3803.1,1550,3803,1551.1z"
          />
          <path
            d="M3808.4,1551.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3809.6,1555,3808.4,1553.7,3808.4,1551.7z M3809.5,1551.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3810.4,1549.4,3809.7,1550,3809.5,1551.1z"
          />
          <path
            d="M3821.8,1555c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3824.8,1553.7,3823.5,1555,3821.8,1555z M3821.8,1554.1
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3820,1553.2,3820.7,1554.1,3821.8,1554.1z"
          />
          <path
            d="M3828.9,1547c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1547z"
          />
        </g>
        <g>
          <path
            d="M3778.5,1566.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3778.5,1566.5z"
          />
          <path
            d="M3786.8,1567.6c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3784.9,1566.7,3785.7,1567.6,3786.8,1567.6z"
          />
          <path d="M3790.6,1559.7h1.2v1.3h-1.2V1559.7z M3791.7,1568.4h-1.1v-6.4h1.1V1568.4z" />
          <path
            d="M3793.1,1565.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3794.3,1568.6,3793.1,1567.2,3793.1,1565.2z M3794.2,1564.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3795.1,1562.9,3794.4,1563.5,3794.2,1564.6z"
          />
          <path
            d="M3800.1,1562.1h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1562.1z"
          />
          <path
            d="M3807.1,1563h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1563z"
          />
          <path d="M3811,1559.7h1.2v1.3h-1.2V1559.7z M3812.2,1568.4h-1.1v-6.4h1.1V1568.4z" />
          <path
            d="M3816.8,1560.6c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1560.6z"
          />
          <path d="M3817.7,1559.7h1.2v1.3h-1.2V1559.7z M3818.8,1568.4h-1.1v-6.4h1.1V1568.4z" />
          <path
            d="M3823.2,1567.6c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3821.3,1566.7,3822.1,1567.6,3823.2,1567.6z"
          />
        </g>
        <g>
          <path
            d="M3771.3,1581.9h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1581.9z"
          />
          <path
            d="M3773.1,1575.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1575.6z"
          />
          <path
            d="M3779.5,1578.8c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3780.5,1582.1,3779.5,1580.6,3779.5,1578.8z M3782.5,1576.4c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C3784.3,1577.1,3783.4,1576.4,3782.5,1576.4z"
          />
          <path
            d="M3786.7,1578.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3788,1582.1,3786.7,1580.7,3786.7,1578.8z M3787.8,1578.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3788.7,1576.4,3788,1577,3787.8,1578.1z"
          />
          <path
            d="M3797,1576.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1576.7z"
          />
          <path
            d="M3799.3,1581v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3800.4,1582.1,3799.7,1581.6,3799.3,1581z M3799.3,1579c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1579z"
          />
          <path d="M3805.4,1573.2h1.2v1.3h-1.2V1573.2z M3806.6,1581.9h-1.1v-6.4h1.1V1581.9z" />
          <path
            d="M3808.4,1575.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1575.6z"
          />
          <path
            d="M3815.3,1575.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1575.6z"
          />
          <path d="M3822.1,1573.2h1.2v1.3h-1.2V1573.2z M3823.2,1581.9h-1.1v-6.4h1.1V1581.9z" />
          <path
            d="M3825,1575.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1575.6z"
          />
          <path
            d="M3834.3,1584.3c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C3837.3,1583.2,3836.1,1584.3,3834.3,1584.3z M3834.4,1576.4c-1.2,0-1.9,0.9-1.9,2.2s0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1v-0.2
				C3836.2,1577.1,3835.3,1576.4,3834.4,1576.4z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="12">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1437.6,815.6c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1417.1,769.9,1437.6,790.4,1437.6,815.6"
        />
        <g>
          <path
            d="M1377.7,806.2c1,0,1.8-0.6,2.1-1.6l1,0.3c-0.5,1.4-1.6,2.2-3.1,2.2c-2.1,0-3.6-1.7-3.6-4.2s1.5-4.2,3.6-4.2
				c1.5,0,2.6,0.9,3.1,2.2l-1,0.3c-0.3-1-1-1.6-2.1-1.6c-1.5,0-2.5,1.3-2.5,3.2S1376.2,806.2,1377.7,806.2z"
          />
          <path
            d="M1384.3,807.2c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S1385.9,807.2,1384.3,807.2z M1384.3,806.3
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1382.6,805.5,1383.2,806.3,1384.3,806.3z"
          />
          <path
            d="M1388.4,805.3c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1388.4,805.3z"
          />
          <path
            d="M1393.9,802.1h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V802.1z"
          />
          <path
            d="M1403.4,807.2c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S1405,807.2,1403.4,807.2z M1403.4,806.3
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1401.7,805.5,1402.4,806.3,1403.4,806.3z"
          />
          <path
            d="M1410,799.8c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0V799.8z"
          />
        </g>
        <g>
          <path
            d="M1368.1,819.6h-1v-5.9h1v0.8c0.3-0.5,0.9-0.9,1.7-0.9c0.8,0,1.3,0.4,1.6,1c0.3-0.5,1-1,1.9-1c1.2,0,1.9,0.9,1.9,2.1v3.9
				h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5v3.5h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5V819.6z"
          />
          <path
            d="M1379.2,819.7c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S1380.8,819.7,1379.2,819.7z M1379.2,818.8
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1377.5,818,1378.1,818.8,1379.2,818.8z"
          />
          <path
            d="M1383.3,814.5h-0.9v-0.9h0.9V812h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V814.5z"
          />
          <path
            d="M1389.3,819.7c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S1390.9,819.7,1389.3,819.7z M1389.3,818.8
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1387.6,818,1388.3,818.8,1389.3,818.8z"
          />
          <path
            d="M1396.4,814.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0V814.7z
				"
          />
          <path d="M1397.4,811.5h1.1v1.2h-1.1V811.5z M1398.5,819.6h-1v-5.9h1V819.6z" />
          <path
            d="M1400.3,817.7c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1400.3,817.7z"
          />
          <path
            d="M1405.2,816.6c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C1406.4,819.7,1405.2,818.4,1405.2,816.6z M1406.3,816h3.2c0-0.8-0.5-1.6-1.5-1.6
				C1407.1,814.4,1406.4,815,1406.3,816z"
          />
          <path
            d="M1411.3,816.6c0-1.7,0.9-3.1,2.6-3.1c0.8,0,1.5,0.4,1.8,1v-3h1v8.1h-1v-0.9c-0.3,0.6-1,1-1.8,1
				C1412.2,819.7,1411.3,818.3,1411.3,816.6z M1414,814.5c-1.1,0-1.7,0.9-1.7,2.2c0,1.3,0.6,2.2,1.7,2.2c0.8,0,1.7-0.6,1.7-2v-0.4
				C1415.7,815,1414.9,814.5,1414,814.5z"
          />
        </g>
        <g>
          <path
            d="M1369.6,827h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V827z"
          />
          <path
            d="M1376.3,827.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0V827.2z
				"
          />
          <path
            d="M1376.9,830.4c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3V828c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C1377.8,832.1,1376.9,831.5,1376.9,830.4z M1380.6,829l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3V829z"
          />
          <path
            d="M1383.3,826.2h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1V826.2z"
          />
          <path
            d="M1389.7,830.2c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L1389.7,830.2z"
          />
          <path
            d="M1396.1,831.2v2.9h-1v-7.9h1v0.9c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C1397.1,832.2,1396.4,831.8,1396.1,831.2z M1396.1,829.3c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V829.3z"
          />
          <path
            d="M1404.1,832.2c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S1405.7,832.2,1404.1,832.2z M1404.1,831.3
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C1402.4,830.4,1403,831.3,1404.1,831.3z"
          />
          <path
            d="M1411.1,827.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0V827.2z
				"
          />
          <path
            d="M1412.4,827h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5V827z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="11">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1517.4,675.6c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1496.9,629.9,1517.4,650.3,1517.4,675.6"
        />
        <g>
          <path d="M1450,655.6h-1l-4.8-7v7h-1.1v-8.7h1.2l4.5,6.7v-6.7h1.1V655.6z" />
          <path
            d="M1456.9,655.6h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V655.6z"
          />
          <path
            d="M1459.9,655.6h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V655.6
				z"
          />
          <path
            d="M1470.4,654.6v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1471.5,655.7,1470.8,655.3,1470.4,654.6z M1470.4,652.6c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V652.6z"
          />
          <path
            d="M1476.1,652.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1477.4,655.7,1476.1,654.3,1476.1,652.4z M1477.2,651.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1478.1,650,1477.4,650.6,1477.2,651.7z"
          />
          <path
            d="M1486.4,650.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V650.3z"
          />
          <path
            d="M1494,655.7c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1497,654.4,1495.8,655.7,1494,655.7z M1494,654.8
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1492.2,653.8,1492.9,654.8,1494,654.8z"
          />
          <path
            d="M1501.1,647.7c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V647.7z"
          />
        </g>
        <g>
          <path
            d="M1431.1,667.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1431.1,667.3z"
          />
          <path
            d="M1437,663.8h-1v-0.9h1V661h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V663.8z"
          />
          <path
            d="M1440.6,666.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1441.8,669.4,1440.6,668.1,1440.6,666.1z M1441.7,665.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1442.6,663.7,1441.9,664.3,1441.7,665.4z"
          />
          <path
            d="M1448.7,668.3v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1449.7,669.4,1449,669,1448.7,668.3z M1448.6,666.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V666.3z"
          />
          <path
            d="M1454.9,667.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1454.9,667.3z"
          />
          <path d="M1462.6,660.5h0.9l-2.8,8.7h-0.9L1462.6,660.5z" />
          <path
            d="M1463.7,666.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1464.9,669.4,1463.7,668.1,1463.7,666.1z M1464.8,665.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1465.6,663.7,1465,664.3,1464.8,665.4z"
          />
          <path d="M1471.8,669.3h-1.1v-8.7h1.1V669.3z" />
          <path
            d="M1473.1,666.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1474.4,669.4,1473.1,668.1,1473.1,666.1z M1474.2,665.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1475.1,663.7,1474.4,664.3,1474.2,665.4z"
          />
          <path
            d="M1481.2,669.3h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V669.3
				z"
          />
          <path
            d="M1490.3,666.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1491.5,669.4,1490.3,668.1,1490.3,666.1z M1491.4,665.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1492.3,663.7,1491.6,664.3,1491.4,665.4z"
          />
          <path
            d="M1497.3,662.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V662.9z"
          />
          <path
            d="M1504.3,663.8h-1v-0.9h1V661h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V663.8z"
          />
          <path
            d="M1508.5,667.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1508.5,667.3z"
          />
        </g>
        <g>
          <g>
            <linearGradient
              id="SVGID_1_"
              gradientUnits="userSpaceOnUse"
              x1="1456.0747"
              y1="2243.415"
              x2="1489.8438"
              y2="2243.415"
              gradientTransform="matrix(1 0 0 -1 0 2921.6411)"
            >
              <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
              <stop offset="1" style={{ stopColor: '#000000' }} />
            </linearGradient>
            <path
              style={{ fill: 'url(#SVGID_1_)' }}
              d="M1456.9,673.9h1.2v1.3h-1.2V673.9z M1458.1,682.6h-1.1v-6.4h1.1V682.6z"
            />

            <linearGradient
              id="SVGID_2_"
              gradientUnits="userSpaceOnUse"
              x1="1456.0747"
              y1="2242.2903"
              x2="1489.8438"
              y2="2242.2903"
              gradientTransform="matrix(1 0 0 -1 0 2921.6411)"
            >
              <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
              <stop offset="1" style={{ stopColor: '#000000' }} />
            </linearGradient>
            <path
              style={{ fill: 'url(#SVGID_2_)' }}
              d="M1459.9,676.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
					c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V676.2z"
            />

            <linearGradient
              id="SVGID_3_"
              gradientUnits="userSpaceOnUse"
              x1="1456.0747"
              y1="2243.1292"
              x2="1489.8438"
              y2="2243.1292"
              gradientTransform="matrix(1 0 0 -1 0 2921.6411)"
            >
              <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
              <stop offset="1" style={{ stopColor: '#000000' }} />
            </linearGradient>
            <path
              style={{ fill: 'url(#SVGID_3_)' }}
              d="M1470.8,677.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
					c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V677.2z"
            />

            <linearGradient
              id="SVGID_4_"
              gradientUnits="userSpaceOnUse"
              x1="1456.0747"
              y1="2243.415"
              x2="1489.8438"
              y2="2243.415"
              gradientTransform="matrix(1 0 0 -1 0 2921.6411)"
            >
              <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
              <stop offset="1" style={{ stopColor: '#000000' }} />
            </linearGradient>
            <path
              style={{ fill: 'url(#SVGID_4_)' }}
              d="M1474.8,673.9h1.1v3.2c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
					c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V673.9z"
            />

            <linearGradient
              id="SVGID_5_"
              gradientUnits="userSpaceOnUse"
              x1="1456.0747"
              y1="2242.2231"
              x2="1489.8438"
              y2="2242.2231"
              gradientTransform="matrix(1 0 0 -1 0 2921.6411)"
            >
              <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
              <stop offset="1" style={{ stopColor: '#000000' }} />
            </linearGradient>
            <path
              style={{ fill: 'url(#SVGID_5_)' }}
              d="M1481.2,679.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2
					c0.9,0,1.5-0.5,1.7-1.2l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1482.4,682.7,1481.2,681.4,1481.2,679.4z M1482.3,678.7h3.4
					c0-0.9-0.5-1.7-1.6-1.7C1483.2,677,1482.5,677.7,1482.3,678.7z"
            />
          </g>
          <g>
            <path d="M1456.9,673.9h1.2v1.3h-1.2V673.9z M1458.1,682.6h-1.1v-6.4h1.1V682.6z" />
            <path
              d="M1459.9,676.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
					c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V676.2z"
            />
            <path
              d="M1470.8,677.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
					c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V677.2z"
            />
            <path
              d="M1474.8,673.9h1.1v3.2c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
					c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V673.9z"
            />
            <path
              d="M1481.2,679.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
					l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1482.4,682.7,1481.2,681.4,1481.2,679.4z M1482.3,678.7h3.4c0-0.9-0.5-1.7-1.6-1.7
					C1483.2,677,1482.5,677.7,1482.3,678.7z"
            />
          </g>
        </g>
        <g>
          <path
            d="M1460.5,707.3c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1458.6,706.4,1459.3,707.3,1460.5,707.3z"
          />
          <path
            d="M1464.2,699.4h1.1v3.2c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V699.4z"
          />
          <path
            d="M1470.6,706.4c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1471.5,708.2,1470.6,707.5,1470.6,706.4z M1474.5,704.8l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V704.8z"
          />
          <path d="M1477.4,699.4h1.2v1.3h-1.2V699.4z M1478.6,708.1h-1.1v-6.4h1.1V708.1z" />
          <path
            d="M1480.4,701.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V701.7z"
          />
        </g>
        <g>
          <path
            d="M1440.9,692.5c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1441.9,695.8,1440.9,694.3,1440.9,692.5z M1443.9,690.1c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C1445.7,690.7,1444.8,690.1,1443.9,690.1z"
          />
          <path d="M1448.5,686.9h1.2v1.3h-1.2V686.9z M1449.7,695.6h-1.1v-6.4h1.1V695.6z" />
          <path
            d="M1451.6,693.7c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1451.6,693.7z"
          />
          <path
            d="M1457.6,690.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V690.2z"
          />
          <path
            d="M1464.8,690.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V690.3z"
          />
          <path d="M1466,686.9h1.2v1.3h-1.2V686.9z M1467.1,695.6h-1.1v-6.4h1.1V695.6z" />
          <path
            d="M1470.1,694.7v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1471.1,695.8,1470.4,695.3,1470.1,694.7z M1470,692.7c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V692.7z"
          />
          <path
            d="M1481.2,695.6h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V695.6z"
          />
          <path
            d="M1483.2,690.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V690.2z"
          />
          <path d="M1487.1,686.9h1.2v1.3h-1.2V686.9z M1488.3,695.6h-1.1v-6.4h1.1V695.6z" />
          <path
            d="M1492.6,695.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1495.6,694.4,1494.4,695.8,1492.6,695.8z
				 M1492.6,694.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1490.8,693.9,1491.5,694.8,1492.6,694.8z"
          />
          <path
            d="M1497,689.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V689.3z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="10">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M634.9,1245.8c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C614.4,1200,634.9,1220.5,634.9,1245.8"
        />
        <g>
          <path d="M570,1229.1h1.1v7.1h4v0.9H570L570,1229.1L570,1229.1z" />
          <path
            d="M575.7,1234.3c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C576.8,1237.3,575.7,1236.1,575.7,1234.3z M576.7,1233.6h3.2c0-0.8-0.5-1.6-1.5-1.6
				C577.5,1232.1,576.9,1232.6,576.7,1233.6z"
          />
          <path d="M583.7,1237.2l-2.2-5.9h1.1l1.7,4.6l1.6-4.6h1.1l-2.2,5.9H583.7z" />
          <path
            d="M587.5,1234.3c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.5,1.2,2.5,2.9v0.3h-4.2c0.1,1.2,0.8,2,1.8,2c0.8,0,1.3-0.4,1.5-1.1l0.9,0.3
				c-0.4,1-1.2,1.7-2.4,1.7C588.6,1237.3,587.5,1236.1,587.5,1234.3z M588.5,1233.6h3.2c0-0.8-0.5-1.6-1.5-1.6
				C589.3,1232.1,588.7,1232.6,588.5,1233.6z"
          />
          <path d="M594.9,1237.2h-1v-8.1h1V1237.2z" />
          <path
            d="M602.5,1237.3c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S604.1,1237.3,602.5,1237.3z M602.5,1236.4
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C600.8,1235.6,601.5,1236.4,602.5,1236.4z"
          />
          <path
            d="M609.1,1229.9c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5h-0.9v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0L609.1,1229.9L609.1,1229.9z"
          />
        </g>
        <g>
          <path
            d="M552.1,1247.8c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L552.1,1247.8z"
          />
          <path
            d="M562,1249.7h-1v-0.8c-0.3,0.5-0.9,0.9-1.7,0.9c-1.3,0-2-0.9-2-2.1v-3.9h1v3.7c0,0.8,0.4,1.4,1.2,1.4
				c0.7,0,1.5-0.6,1.5-1.5v-3.5h1V1249.7z"
          />
          <path
            d="M563.8,1247.8c0.3,0.7,1,1.1,1.8,1.1c0.7,0,1.3-0.3,1.3-0.9s-0.6-0.7-1.5-0.9c-1-0.2-2-0.4-2-1.6c0-1,0.9-1.8,2.2-1.8
				c1.1,0,1.9,0.5,2.3,1.2l-0.7,0.6c-0.3-0.6-0.9-1-1.6-1c-0.7,0-1.1,0.3-1.1,0.9c0,0.6,0.5,0.7,1.3,0.8c1,0.2,2.2,0.4,2.2,1.7
				c0,1.1-1,1.9-2.3,1.9c-1.1,0-2.1-0.5-2.6-1.4L563.8,1247.8z"
          />
          <path
            d="M569.3,1244.7h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9h-1.3v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L569.3,1244.7L569.3,1244.7z"
          />
          <path
            d="M572.5,1248.1c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C573.4,1249.8,572.5,1249.1,572.5,1248.1z M576.2,1246.7l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L576.2,1246.7L576.2,1246.7z"
          />
          <path d="M578.8,1241.6h1.1v1.2h-1.1V1241.6z M579.9,1249.7h-1v-5.9h1V1249.7z" />
          <path
            d="M581.6,1243.8h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L581.6,1243.8L581.6,1243.8z"
          />
          <path
            d="M587.5,1248.1c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C588.3,1249.8,587.5,1249.1,587.5,1248.1z M591.1,1246.7l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L591.1,1246.7L591.1,1246.7z"
          />
          <path
            d="M594.8,1248.8v0.9h-1v-8.1h1v3c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C595.8,1249.8,595.2,1249.4,594.8,1248.8z M594.8,1246.9c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1246.9z"
          />
          <path d="M600.4,1241.6h1.1v1.2h-1.1V1241.6z M601.5,1249.7h-1v-5.9h1V1249.7z" />
          <path d="M603.1,1241.6h1.1v1.2h-1.1V1241.6z M604.2,1249.7h-1v-5.9h1V1249.7z" />
          <path
            d="M606,1244.7h-0.9v-0.9h0.9v-1.7h1v1.7h1.3v0.9H607v3.4c0,0.6,0.3,0.7,0.8,0.7c0.2,0,0.4,0,0.6-0.1v0.9
				c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0-1.6-0.3-1.6-1.5L606,1244.7L606,1244.7z"
          />
          <path
            d="M610.1,1251.8c-0.2,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,0.9-0.7l0.3-0.7l-2.2-5.7h1.1l1.7,4.5
				l1.6-4.5h1.1l-2.5,6.5C611.5,1251.2,611.1,1251.8,610.1,1251.8z"
          />
          <path d="M618.9,1241.6h1.1v1.2h-1.1V1241.6z M620,1249.7h-1v-5.9h1V1249.7z" />
          <path
            d="M621.6,1243.8h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L621.6,1243.8L621.6,1243.8z"
          />
        </g>
        <g>
          <path
            d="M555.5,1264.3c-1.3,0-2.2-0.6-2.6-1.7l0.9-0.4c0.2,0.8,0.8,1.2,1.6,1.2c1,0,1.7-0.5,1.7-1.8v-0.7c-0.3,0.6-1,1-1.8,1
				c-1.6,0-2.5-1.3-2.5-2.9c0-1.6,0.9-2.9,2.5-2.9c0.8,0,1.5,0.4,1.8,1v-0.9h1v5.3C558.2,1263.3,557,1264.3,555.5,1264.3z
				 M555.6,1257c-1.1,0-1.7,0.8-1.7,2s0.7,2,1.7,2c0.8,0,1.6-0.6,1.6-1.9v-0.2C557.2,1257.7,556.4,1257,555.6,1257z"
          />
          <path d="M560.9,1262.1h-1v-8.1h1V1262.1z" />
          <path
            d="M564.9,1262.3c-1.6,0-2.7-1.2-2.7-3.1s1.1-3.1,2.7-3.1s2.7,1.2,2.7,3.1S566.5,1262.3,564.9,1262.3z M564.9,1261.4
				c1,0,1.7-0.9,1.7-2.2c0-1.3-0.7-2.2-1.7-2.2s-1.7,0.9-1.7,2.2C563.2,1260.5,563.9,1261.4,564.9,1261.4z"
          />
          <path
            d="M569.9,1261.3v0.9h-1v-8.1h1v3c0.3-0.6,1-1,1.8-1c1.6,0,2.6,1.4,2.6,3.1c0,1.7-0.9,3.1-2.6,3.1
				C570.9,1262.3,570.3,1261.9,569.9,1261.3z M569.9,1259.4c0,1.3,0.8,2,1.7,2c1.1,0,1.7-0.9,1.7-2.2c0-1.3-0.6-2.2-1.7-2.2
				c-0.8,0-1.7,0.6-1.7,2V1259.4z"
          />
          <path
            d="M575.1,1260.6c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C576,1262.2,575.1,1261.6,575.1,1260.6z M578.8,1259.1l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L578.8,1259.1L578.8,1259.1z"
          />
          <path d="M582.5,1262.1h-1v-8.1h1V1262.1z" />
          <path
            d="M590.3,1254.9c-0.2,0-0.3,0-0.4,0c-0.6,0-0.9,0.3-0.9,1v0.4h1.3v0.9h-1.3v5h-1v-5H587v-0.9h0.9v-0.5
				c0-1.1,0.6-1.8,1.8-1.8c0.2,0,0.3,0,0.5,0L590.3,1254.9L590.3,1254.9z"
          />
          <path
            d="M590.7,1260.6c0-1.2,0.9-1.7,2.2-1.9l1.4-0.3v-0.2c0-0.7-0.4-1.1-1.2-1.1c-0.7,0-1.2,0.3-1.4,1l-1-0.2
				c0.3-1,1.1-1.6,2.3-1.6c1.3,0,2.1,0.6,2.1,1.9v2.9c0,0.4,0.2,0.5,0.6,0.4v0.8c-0.9,0.1-1.4-0.1-1.5-0.7c-0.4,0.5-1,0.8-1.8,0.8
				C591.6,1262.2,590.7,1261.6,590.7,1260.6z M594.4,1259.1l-1.1,0.2c-0.9,0.2-1.5,0.4-1.5,1.1c0,0.6,0.4,0.9,1.1,0.9
				c0.9,0,1.6-0.5,1.6-1.3L594.4,1259.1L594.4,1259.1z"
          />
          <path
            d="M600.1,1257.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5,0.7-1.5,1.7v3.2h-1v-5.9h1v1c0.3-0.6,0.8-1,1.6-1c0.2,0,0.3,0,0.4,0
				L600.1,1257.3L600.1,1257.3z"
          />
          <path
            d="M602.2,1262.1h-1v-5.9h1v0.8c0.3-0.5,0.9-0.9,1.7-0.9c0.8,0,1.3,0.4,1.6,1c0.3-0.5,1-1,1.9-1c1.2,0,1.9,0.9,1.9,2.1v3.9
				h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5v3.5h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4c-0.7,0-1.4,0.6-1.4,1.5V1262.1z"
          />
          <path d="M611,1254.1h1.1v1.2H611V1254.1z M612,1262.1h-1v-5.9h1V1262.1z" />
          <path
            d="M613.7,1256.3h1v0.8c0.3-0.5,0.9-0.9,1.8-0.9c1.2,0,2,0.9,2,2.1v3.9h-1v-3.6c0-0.8-0.3-1.4-1.2-1.4
				c-0.7,0-1.5,0.6-1.5,1.5v3.5h-1L613.7,1256.3L613.7,1256.3z"
          />
          <path
            d="M622.3,1264.3c-1.3,0-2.2-0.6-2.6-1.7l0.9-0.4c0.2,0.8,0.8,1.2,1.6,1.2c1,0,1.7-0.5,1.7-1.8v-0.7c-0.3,0.6-1,1-1.8,1
				c-1.6,0-2.5-1.3-2.5-2.9c0-1.6,0.9-2.9,2.5-2.9c0.8,0,1.5,0.4,1.8,1v-0.9h1v5.3C625,1263.3,623.9,1264.3,622.3,1264.3z
				 M622.4,1257c-1.1,0-1.7,0.8-1.7,2s0.7,2,1.7,2c0.8,0,1.6-0.6,1.6-1.9v-0.2C624,1257.7,623.2,1257,622.4,1257z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="9">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1682.8,672.7c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1662.3,626.9,1682.8,647.4,1682.8,672.7"
        />
        <g>
          <path
            d="M1606,656.6h-1.2v-8.7h3.3c1.8,0,3,0.9,3,2.5c0,1.6-1.2,2.5-3,2.5h-2.2V656.6z M1608.1,652c1.2,0,1.9-0.5,1.9-1.5
				c0-1-0.7-1.5-1.9-1.5h-2.1v3.1L1608.1,652L1608.1,652z"
          />
          <path
            d="M1615.7,651.4c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V651.4z"
          />
          <path
            d="M1619.4,656.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1622.3,655.4,1621.1,656.8,1619.4,656.8z
				 M1619.4,655.8c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1617.5,654.9,1618.3,655.8,1619.4,655.8z"
          />
          <path d="M1625.9,654.1l-1.8,2.5h-1.2l2.3-3.3l-2.1-3h1.3l1.5,2.2l1.6-2.2h1.2l-2.1,3l2.3,3.3h-1.2L1625.9,654.1z" />
          <path d="M1629.9,647.9h1.2v1.3h-1.2V647.9z M1631,656.6h-1.1v-6.4h1.1V656.6z" />
          <path
            d="M1633.9,656.6h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V656.6
				z"
          />
          <path d="M1643.4,647.9h1.2v1.3h-1.2V647.9z M1644.5,656.6h-1.1v-6.4h1.1V656.6z" />
          <path
            d="M1646.5,651.2h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V651.2z"
          />
          <path
            d="M1650.9,658.9c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C1652.5,658.3,1652,658.9,1650.9,658.9z"
          />
          <path
            d="M1663,656.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1666,655.4,1664.8,656.8,1663,656.8z M1663,655.8
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1661.2,654.9,1661.9,655.8,1663,655.8z"
          />
          <path
            d="M1670.1,648.8c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V648.8z"
          />
        </g>
        <g>
          <path
            d="M1594.5,669.2v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1595.5,670.3,1594.8,669.9,1594.5,669.2z M1594.4,667.2c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V667.2z"
          />
          <path
            d="M1603.5,664.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V664.9z"
          />
          <path d="M1604.3,661.4h1.2v1.3h-1.2V661.4z M1605.5,670.2h-1.1v-6.4h1.1V670.2z" />
          <path
            d="M1608,670.2h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V670.2
				z"
          />
          <path
            d="M1616.7,668.4c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1617.6,670.3,1616.7,669.6,1616.7,668.4z M1620.6,666.9l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V666.9z"
          />
          <path
            d="M1626.5,664.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V664.9z"
          />
          <path
            d="M1627.7,672.4c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C1629.3,671.8,1628.8,672.4,1627.7,672.4z"
          />
          <path
            d="M1636.4,669.2v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1637.5,670.3,1636.8,669.9,1636.4,669.2z M1636.4,667.2c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V667.2z"
          />
          <path
            d="M1645.5,664.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V664.9z"
          />
          <path
            d="M1648.8,670.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1651.8,669,1650.6,670.3,1648.8,670.3z
				 M1648.8,669.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1647,668.4,1647.7,669.4,1648.8,669.4z"
          />
          <path
            d="M1652.4,667c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1653.4,670.3,1652.4,668.8,1652.4,667z M1655.3,664.6c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C1657.1,665.3,1656.2,664.6,1655.3,664.6z"
          />
          <path
            d="M1664.7,670.2h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V670.2z"
          />
          <path
            d="M1668.7,669.3c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1666.8,668.4,1667.6,669.3,1668.7,669.3z"
          />
          <path
            d="M1671.7,667c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2l1,0.3
				c-0.4,1.1-1.3,1.8-2.6,1.8C1672.9,670.3,1671.7,668.9,1671.7,667z M1672.8,666.3h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1673.7,664.6,1673,665.2,1672.8,666.3z"
          />
          <path
            d="M1681.6,664.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V664.9z"
          />
        </g>
        <g>
          <path
            d="M1604.1,682c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1605,683.8,1604.1,683.1,1604.1,682z M1608,680.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V680.4z"
          />
          <path
            d="M1611,677.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V677.3z"
          />
          <path
            d="M1617.4,680.5c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1618.4,683.8,1617.4,682.3,1617.4,680.5z M1620.3,678.2c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C1622.1,678.8,1621.2,678.2,1620.3,678.2z"
          />
          <path
            d="M1630,682.8v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1631.1,683.8,1630.4,683.4,1630,682.8z M1630,680.7c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V680.7z"
          />
          <path
            d="M1638.6,683.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1641.6,682.5,1640.4,683.8,1638.6,683.8z
				 M1638.6,682.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1636.8,682,1637.5,682.9,1638.6,682.9z"
          />
          <path d="M1642.9,674.9h1.2v1.3h-1.2V674.9z M1644.1,683.7h-1.1v-6.4h1.1V683.7z" />
          <path
            d="M1645.9,677.3h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V677.3z"
          />
          <path
            d="M1652.9,678.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V678.3z"
          />
          <path
            d="M1663.3,683.8c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1666.2,682.5,1665,683.8,1663.3,683.8z
				 M1663.3,682.9c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1661.4,682,1662.1,682.9,1663.3,682.9z"
          />
          <path
            d="M1670.4,675.8c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V675.8z"
          />
        </g>
        <g>
          <path
            d="M1604.8,696.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1602.9,695.5,1603.7,696.4,1604.8,696.4z"
          />
          <path
            d="M1611.1,697.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1614,696,1612.8,697.3,1611.1,697.3z
				 M1611.1,696.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1609.2,695.5,1610,696.4,1611.1,696.4z"
          />
          <path
            d="M1615.4,690.8h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V690.8z"
          />
          <path
            d="M1622.4,695.2c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1622.4,695.2z"
          />
          <path
            d="M1633.2,697.2h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V697.2z"
          />
          <path
            d="M1636.2,697.2h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V697.2
				z"
          />
          <path
            d="M1646.8,696.3v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1647.8,697.3,1647.1,696.9,1646.8,696.3z M1646.7,694.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V694.3z"
          />
          <path
            d="M1653.1,691.8h-1v-0.9h1V689h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V691.8z"
          />
          <path d="M1657,688.5h1.2v1.3h-1.2V688.5z M1658.1,697.2h-1.1v-6.4h1.1V697.2z" />
          <path
            d="M1662.5,697.3c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1665.4,696,1664.2,697.3,1662.5,697.3z
				 M1662.5,696.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1660.6,695.5,1661.3,696.4,1662.5,696.4z"
          />
          <path
            d="M1666.8,690.8h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V690.8z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="8">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M3952,1759.8c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3931.6,1714.1,3952,1734.6,3952,1759.8"
        />
        <g>
          <path d="M3885.6,1735h1.2v7.7h4.4v1h-5.5V1735z" />
          <path
            d="M3891.8,1740.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3893,1743.9,3891.8,1742.6,3891.8,1740.6z M3892.9,1739.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3893.8,1738.2,3893.1,1738.9,3892.9,1739.9z"
          />
          <path d="M3900.4,1743.8l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H3900.4z" />
          <path
            d="M3904.5,1740.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3905.7,1743.9,3904.5,1742.6,3904.5,1740.6z M3905.6,1739.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3906.5,1738.2,3905.8,1738.9,3905.6,1739.9z"
          />
          <path d="M3912.6,1743.8h-1.1v-8.7h1.1V1743.8z" />
          <path
            d="M3920.8,1743.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3923.8,1742.6,3922.5,1743.9,3920.8,1743.9z
				 M3920.8,1743c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3919,1742.1,3919.7,1743,3920.8,1743z"
          />
          <path
            d="M3927.9,1735.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1735.9z"
          />
        </g>
        <g>
          <path
            d="M3875.1,1757.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3878,1756.1,3876.8,1757.4,3875.1,1757.4z
				 M3875.1,1756.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3873.2,1755.6,3874,1756.5,3875.1,1756.5z"
          />
          <path
            d="M3882.7,1752c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1752z"
          />
          <path
            d="M3884.1,1751.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1751.9z"
          />
          <path
            d="M3888.1,1748.6h1.1v3.2c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1748.6z"
          />
          <path
            d="M3897.4,1757.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3900.4,1756.1,3899.2,1757.4,3897.4,1757.4z
				 M3897.4,1756.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3895.6,1755.6,3896.3,1756.5,3897.4,1756.5
				z"
          />
          <path
            d="M3901.3,1754.1c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3902.4,1757.4,3901.3,1755.9,3901.3,1754.1z M3904.3,1751.8c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C3906.1,1752.4,3905.2,1751.8,3904.3,1751.8z"
          />
          <path
            d="M3911.5,1757.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3914.5,1756.1,3913.3,1757.4,3911.5,1757.4z
				 M3911.5,1756.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3909.7,1755.6,3910.4,1756.5,3911.5,1756.5
				z"
          />
          <path d="M3918,1754.8l-1.8,2.5h-1.2l2.3-3.3l-2.1-3h1.3l1.5,2.2l1.6-2.2h1.2l-2.1,3l2.3,3.3h-1.2L3918,1754.8z" />
          <path
            d="M3922.4,1759.6c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C3924,1758.9,3923.5,1759.6,3922.4,1759.6z"
          />
          <path d="M3932,1748.6h1.2v1.3h-1.2V1748.6z M3933.2,1757.3h-1.1v-6.4h1.1V1757.3z" />
          <path
            d="M3935,1750.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1750.9z"
          />
        </g>
        <g>
          <path
            d="M3871.5,1768.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3871.5,1768.8z"
          />
          <path
            d="M3882.3,1770.8h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1770.8z"
          />
          <path
            d="M3884.3,1768.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3884.3,1768.8z"
          />
          <path
            d="M3890.2,1765.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1765.4z"
          />
          <path
            d="M3893.7,1769.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3894.6,1770.9,3893.7,1770.2,3893.7,1769.1z M3897.6,1767.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1767.6z"
          />
          <path d="M3900.5,1762.1h1.2v1.3h-1.2V1762.1z M3901.7,1770.8h-1.1v-6.4h1.1V1770.8z" />
          <path
            d="M3903.5,1764.5h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1764.5z"
          />
          <path
            d="M3909.9,1769.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3910.8,1770.9,3909.9,1770.2,3909.9,1769.1z M3913.8,1767.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1767.6z"
          />
          <path
            d="M3917.9,1769.9v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3918.9,1770.9,3918.2,1770.5,3917.9,1769.9z M3917.8,1767.9c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1767.9z"
          />
          <path d="M3923.9,1762.1h1.2v1.3h-1.2V1762.1z M3925.1,1770.8h-1.1v-6.4h1.1V1770.8z" />
          <path d="M3928,1770.8h-1.1v-8.7h1.1V1770.8z" />
          <path d="M3929.8,1762.1h1.2v1.3h-1.2V1762.1z M3930.9,1770.8h-1.1v-6.4h1.1V1770.8z" />
          <path
            d="M3932.9,1765.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1765.4z"
          />
          <path
            d="M3937.3,1773.1c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C3938.9,1772.5,3938.4,1773.1,3937.3,1773.1z"
          />
        </g>
        <g>
          <path
            d="M3887.7,1781.2c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3888.7,1784.5,3887.7,1783,3887.7,1781.2z M3890.7,1778.8c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C3892.5,1779.4,3891.6,1778.8,3890.7,1778.8z"
          />
          <path
            d="M3894.9,1781.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3896.1,1784.5,3894.9,1783.1,3894.9,1781.2z M3896,1780.5h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3896.9,1778.8,3896.2,1779.4,3896,1780.5z"
          />
          <path
            d="M3903,1783.4v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3904.1,1784.5,3903.4,1784,3903,1783.4z M3903,1781.4c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1781.4z"
          />
          <path
            d="M3908.7,1782.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3909.6,1784.4,3908.7,1783.8,3908.7,1782.6z M3912.6,1781.1l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1781.1z"
          />
          <path
            d="M3915.7,1778.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1778.9z"
          />
          <path
            d="M3919.3,1781.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3920.5,1784.5,3919.3,1783.1,3919.3,1781.2z M3920.4,1780.5h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3921.2,1778.8,3920.6,1779.4,3920.4,1780.5z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="7">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2784.2,1624.7c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2763.7,1579,2784.2,1599.4,2784.2,1624.7"
        />
        <g>
          <path d="M2713.5,1606.7l3.4,8.7h-1.2l-0.9-2.3h-3.9l-0.9,2.3h-1.2l3.4-8.7H2713.5z M2711.3,1612.1h3.1l-1.6-4.1L2711.3,1612.1z" />
          <path d="M2719.6,1615.4l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H2719.6z" />
          <path
            d="M2723.7,1613.7c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2724.6,1615.5,2723.7,1614.9,2723.7,1613.7z M2727.6,1612.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1612.2z"
          />
          <path d="M2730.5,1606.7h1.2v1.3h-1.2V1606.7z M2731.6,1615.4h-1.1v-6.4h1.1V1615.4z" />
          <path d="M2734.6,1615.4h-1.1v-8.7h1.1V1615.4z" />
          <path
            d="M2735.9,1613.7c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2736.8,1615.5,2735.9,1614.9,2735.9,1613.7z M2739.8,1612.2l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1612.2z"
          />
          <path
            d="M2743.9,1614.5v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2745,1615.6,2744.3,1615.1,2743.9,1614.5z M2743.9,1612.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1612.5z"
          />
          <path d="M2750,1606.7h1.2v1.3h-1.2V1606.7z M2751.1,1615.4h-1.1v-6.4h1.1V1615.4z" />
          <path d="M2754,1615.4h-1.1v-8.7h1.1V1615.4z" />
          <path d="M2755.8,1606.7h1.2v1.3h-1.2V1606.7z M2756.9,1615.4h-1.1v-6.4h1.1V1615.4z" />
          <path
            d="M2758.9,1610h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1610z"
          />
          <path
            d="M2763.3,1617.7c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C2764.9,1617.1,2764.4,1617.7,2763.3,1617.7z"
          />
        </g>
        <g>
          <path
            d="M2703.8,1629.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2706.7,1627.7,2705.5,1629.1,2703.8,1629.1z
				 M2703.8,1628.1c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2701.9,1627.2,2702.6,1628.1,2703.8,1628.1
				z"
          />
          <path
            d="M2710.9,1621.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1621.1z"
          />
          <path
            d="M2715.8,1627c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2715.8,1627z"
          />
          <path
            d="M2726.7,1628.9h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1628.9z"
          />
          <path
            d="M2728.6,1627c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2728.6,1627z"
          />
          <path
            d="M2734.5,1623.5h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1623.5z"
          />
          <path
            d="M2738,1627.2c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2739,1629.1,2738,1628.4,2738,1627.2z M2742,1625.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.4,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V1625.7z"
          />
          <path d="M2744.9,1620.2h1.2v1.3h-1.2V1620.2z M2746,1628.9h-1.1v-6.4h1.1V1628.9z" />
          <path
            d="M2747.8,1622.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1622.6z"
          />
          <path
            d="M2754.2,1627.2c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.4v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2755.1,1629.1,2754.2,1628.4,2754.2,1627.2z M2758.1,1625.7l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1625.7z"
          />
          <path
            d="M2762.2,1628v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2763.3,1629.1,2762.6,1628.7,2762.2,1628z M2762.2,1626c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1626z"
          />
          <path d="M2769.4,1628.9h-1.1v-8.7h1.1V1628.9z" />
          <path
            d="M2771.6,1631.2c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9
				l1.8-4.9h1.1l-2.7,7.1C2773.2,1630.6,2772.7,1631.2,2771.6,1631.2z"
          />
        </g>
        <g>
          <path
            d="M2699.8,1641.5v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2700.9,1642.6,2700.1,1642.2,2699.8,1641.5z M2699.7,1639.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1639.5z"
          />
          <path
            d="M2709.2,1637.2c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1637.2z"
          />
          <path
            d="M2712.9,1642.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2715.9,1641.3,2714.7,1642.6,2712.9,1642.6z
				 M2712.9,1641.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2711.1,1640.7,2711.8,1641.7,2712.9,1641.7
				z"
          />
          <path
            d="M2716.8,1639.3c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2717.8,1642.6,2716.8,1641.1,2716.8,1639.3z M2719.8,1636.9c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C2721.6,1637.6,2720.7,1636.9,2719.8,1636.9z"
          />
          <path
            d="M2729.5,1642.5h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1642.5z"
          />
          <path
            d="M2733.9,1641.6c0.9,0,1.4-0.6,1.6-1.3l1,0.5c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2732,1640.7,2732.8,1641.6,2733.9,1641.6z"
          />
          <path
            d="M2737.2,1639.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2738.4,1642.6,2737.2,1641.2,2737.2,1639.3z M2738.3,1638.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2739.2,1636.9,2738.5,1637.5,2738.3,1638.6z"
          />
          <path
            d="M2743.8,1639.3c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2744.8,1642.6,2743.8,1641.1,2743.8,1639.3z M2746.8,1636.9c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C2748.6,1637.6,2747.7,1636.9,2746.8,1636.9z"
          />
          <path
            d="M2758.1,1634.6c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1634.6z"
          />
          <path
            d="M2761.6,1642.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2764.5,1641.3,2763.3,1642.6,2761.6,1642.6z
				 M2761.6,1641.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2759.7,1640.7,2760.4,1641.7,2761.6,1641.7
				z"
          />
          <path
            d="M2768.4,1642.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C2771.4,1641.3,2770.2,1642.6,2768.4,1642.6z
				 M2768.4,1641.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2766.6,1640.7,2767.3,1641.7,2768.4,1641.7
				z"
          />
          <path
            d="M2772.3,1639.3c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2773.3,1642.6,2772.3,1641.1,2772.3,1639.3z M2775.3,1636.9c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1
				v-0.4C2777.1,1637.6,2776.2,1636.9,2775.3,1636.9z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="6">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1521.5,380c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1501,334.3,1521.5,354.8,1521.5,380"
        />
        <g>
          <path d="M1456,355.3v1h-4.5v2.7h3.9v1h-3.9v3h4.5v1h-5.7v-8.7L1456,355.3L1456,355.3z" />
          <path
            d="M1460.3,356.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V356.1z"
          />
          <path
            d="M1464,356.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V356.1z"
          />
          <path d="M1464.9,355.3h1.2v1.3h-1.2V355.3z M1466.1,364h-1.1v-6.4h1.1V364z" />
          <path
            d="M1470.5,363.2c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1468.6,362.3,1469.3,363.2,1470.5,363.2z"
          />
          <path d="M1474.2,355.3h1.2v1.3h-1.2V355.3z M1475.3,364h-1.1v-6.4h1.1V364z" />
          <path
            d="M1476.7,360.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1477.9,364.1,1476.7,362.8,1476.7,360.8z M1477.8,360.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1478.7,358.5,1478,359.1,1477.8,360.2z"
          />
          <path
            d="M1483.7,357.6h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V357.6z"
          />
          <path
            d="M1493.1,363.2c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1491.2,362.3,1492,363.2,1493.1,363.2z"
          />
          <path
            d="M1497.3,366.3c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C1498.9,365.7,1498.4,366.3,1497.3,366.3z"
          />
        </g>
        <g>
          <path
            d="M1448.8,377.6c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1451.8,376.3,1450.6,377.6,1448.8,377.6z
				 M1448.8,376.7c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1447,375.8,1447.7,376.7,1448.8,376.7z"
          />
          <path
            d="M1456,369.6c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V369.6z"
          />
          <path d="M1461.9,377.5h-1.1v-8.7h1.1V377.5z" />
          <path
            d="M1463.2,375.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1464.1,377.6,1463.2,376.9,1463.2,375.8z M1467.2,374.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V374.3z"
          />
          <path
            d="M1470.2,375.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1470.2,375.5z"
          />
          <path
            d="M1476.2,372.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V372.1z"
          />
          <path
            d="M1485.1,377.5h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V377.5
				z"
          />
          <path d="M1494.6,368.8h1.2v1.3h-1.2V368.8z M1495.7,377.5h-1.1v-6.4h1.1V377.5z" />
          <path d="M1498.6,377.5h-1.1v-8.7h1.1V377.5z" />
          <path
            d="M1500,374.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2l1,0.3
				c-0.4,1.1-1.3,1.8-2.6,1.8C1501.2,377.6,1500,376.3,1500,374.3z M1501.1,373.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1502,372,1501.3,372.6,1501.1,373.7z"
          />
        </g>
        <g>
          <path
            d="M1447.9,387.9c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1448.9,391.2,1447.9,389.7,1447.9,387.9z M1450.9,385.5c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C1452.7,386.2,1451.8,385.5,1450.9,385.5z"
          />
          <path
            d="M1455.1,387.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1456.3,391.2,1455.1,389.8,1455.1,387.9z M1456.2,387.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1457.1,385.5,1456.4,386.1,1456.2,387.2z"
          />
          <path d="M1463.2,391h-1.1v-8.7h1.1V391z" />
          <path d="M1465,382.3h1.2v1.3h-1.2V382.3z M1466.1,391h-1.1v-6.4h1.1V391z" />
          <path d="M1469.6,391l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H1469.6z" />
          <path
            d="M1473.7,387.9c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1474.9,391.2,1473.7,389.8,1473.7,387.9z M1474.8,387.2h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1475.7,385.5,1475,386.1,1474.8,387.2z"
          />
          <path
            d="M1484,385.8c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V385.8z"
          />
          <path
            d="M1485.6,393.3c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C1487.2,392.7,1486.7,393.3,1485.6,393.3z"
          />
          <path d="M1495.2,382.3h1.2v1.3h-1.2V382.3z M1496.3,391h-1.1v-6.4h1.1V391z" />
          <path
            d="M1498.1,384.7h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V384.7z"
          />
        </g>
        <g>
          <path
            d="M1449,404.5h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V404.5z"
          />
          <path
            d="M1454.1,399.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V399.3z"
          />
          <path
            d="M1456.4,403.6v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1457.5,404.7,1456.8,404.3,1456.4,403.6z M1456.4,401.6c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V401.6z"
          />
          <path
            d="M1462,402.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1463,404.7,1462,404,1462,402.8z M1466,401.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4V401.3z"
          />
          <path
            d="M1468.9,398.2h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V398.2z"
          />
          <path
            d="M1479.1,402.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1480.1,404.7,1479.1,404,1479.1,402.8z M1483.1,401.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V401.3z"
          />
          <path
            d="M1489.3,399.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V399.3z"
          />
          <path
            d="M1490.1,401.4c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1491.3,404.7,1490.1,403.3,1490.1,401.4z M1491.2,400.7h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1492.1,399,1491.4,399.6,1491.2,400.7z"
          />
          <path
            d="M1496.6,402.8c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1497.5,404.7,1496.6,404,1496.6,402.8z M1500.6,401.3l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V401.3z"
          />
          <path
            d="M1503.6,402.6c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1503.6,402.6z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="5">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M980.2,815.5c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C959.8,769.8,980.2,790.2,980.2,815.5"
        />
        <g>
          <path d="M918.3,813h-1l-4.8-7v7h-1.1v-8.7h1.2l4.5,6.7v-6.7h1.1L918.3,813L918.3,813z" />
          <path
            d="M919.7,809.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C920.9,813.1,919.7,811.8,919.7,809.8z M920.8,809.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C921.7,807.4,921,808,920.8,809.1z"
          />
          <path
            d="M926.3,809.8c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C927.5,813.1,926.3,811.8,926.3,809.8z M927.4,809.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C928.3,807.4,927.6,808,927.4,809.1z"
          />
          <path
            d="M932.9,809.8c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C933.9,813.1,932.9,811.6,932.9,809.8z M935.8,807.5c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C937.6,808.1,936.7,807.5,935.8,807.5z"
          />
          <path
            d="M947.1,805.1c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V805.1z"
          />
          <path
            d="M950.6,813.1c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C953.6,811.8,952.4,813.1,950.6,813.1z
				 M950.6,812.2c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C948.8,811.2,949.5,812.2,950.6,812.2z"
          />
          <path
            d="M958.3,807.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5H955v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0L958.3,807.7L958.3,807.7z"
          />
        </g>
        <g>
          <path
            d="M908.6,825.6v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C909.7,826.6,909,826.2,908.6,825.6z M908.6,823.5c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V823.5z"
          />
          <path
            d="M914.3,823.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C915.6,826.6,914.3,825.3,914.3,823.3z M915.4,822.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C916.3,820.9,915.6,821.6,915.4,822.6z"
          />
          <path
            d="M921.5,824.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L921.5,824.5z"
          />
          <path
            d="M927.4,821.1h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6L927.4,821.1L927.4,821.1z"
          />
          <path d="M931.3,817.8h1.2v1.3h-1.2V817.8z M932.5,826.5h-1.1v-6.4h1.1V826.5z" />
          <path
            d="M936.9,825.7c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C935,824.8,935.8,825.7,936.9,825.7z"
          />
          <path d="M940.6,817.8h1.2v1.3h-1.2V817.8z M941.7,826.5h-1.1v-6.4h1.1V826.5z" />
          <path
            d="M943.1,823.3c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C944.1,826.6,943.1,825.1,943.1,823.3z M946.1,821c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C947.9,821.6,947,821,946.1,821z"
          />
          <path
            d="M950.3,823.3c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C951.6,826.6,950.3,825.3,950.3,823.3z M951.4,822.6h3.4c0-0.9-0.5-1.7-1.6-1.7
				C952.3,820.9,951.6,821.6,951.4,822.6z"
          />
          <path
            d="M957.5,824.5c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L957.5,824.5z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="4">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M3256.6,747.7c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C3236.2,702,3256.6,722.5,3256.6,747.7"
        />
        <g>
          <path
            d="M3186.5,731.7h-1.2V723h3.3c1.8,0,3,0.9,3,2.5c0,1.4-0.9,2.2-2.2,2.5l2.4,3.7h-1.3l-2.3-3.7h-1.7V731.7z M3188.6,727
				c1.2,0,1.9-0.5,1.9-1.5c0-1-0.7-1.5-1.9-1.5h-2.1v3.1L3188.6,727L3188.6,727z"
          />
          <path
            d="M3192.8,728.5c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3194,731.8,3192.8,730.5,3192.8,728.5z M3193.9,727.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3194.8,726.2,3194.1,726.8,3193.9,727.9z"
          />
          <path d="M3200.9,731.7h-1.1V723h1.1V731.7z" />
          <path d="M3202.6,723h1.2v1.3h-1.2V723z M3203.8,731.7h-1.1v-6.4h1.1V731.7z" />
          <path
            d="M3205.1,730c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3206,731.8,3205.1,731.1,3205.1,730z M3209.1,728.4l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V728.4z"
          />
          <path
            d="M3213.1,730.8v0.9h-1.1V723h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3214.2,731.8,3213.5,731.4,3213.1,730.8z M3213.1,728.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V728.8z"
          />
          <path d="M3219.2,723h1.2v1.3h-1.2V723z M3220.3,731.7h-1.1v-6.4h1.1V731.7z" />
          <path d="M3223.2,731.7h-1.1V723h1.1V731.7z" />
          <path d="M3225,723h1.2v1.3h-1.2V723z M3226.2,731.7h-1.1v-6.4h1.1V731.7z" />
          <path
            d="M3228.2,726.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V726.3z"
          />
          <path
            d="M3232.5,734c-0.3,0-0.4,0-0.6,0V733c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C3234.1,733.4,3233.6,734,3232.5,734z"
          />
        </g>
        <g>
          <path
            d="M3188.8,745.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3s3,1.3,3,3.3C3191.7,744,3190.5,745.4,3188.8,745.4z M3188.8,744.4
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C3186.9,743.5,3187.7,744.4,3188.8,744.4z"
          />
          <path
            d="M3195.9,737.3c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V737.3z"
          />
          <path
            d="M3200.3,742c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-0.9h1.1v8.5h-1.1v-3.1c-0.4,0.6-1.1,1.1-2,1.1
				C3201.3,745.4,3200.3,743.8,3200.3,742z M3203.2,739.7c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C3205,740.3,3204.1,739.7,3203.2,739.7z"
          />
          <path
            d="M3213,745.2h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V745.2z"
          />
          <path
            d="M3214.3,743.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3215.2,745.3,3214.3,744.6,3214.3,743.5z M3218.3,742l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V742z"
          />
          <path d="M3222.3,745.2h-1.1v-8.7h1.1V745.2z" />
          <path d="M3224.1,736.5h1.2v1.3h-1.2V736.5z M3225.2,745.2h-1.1v-6.4h1.1V745.2z" />
          <path
            d="M3227.2,739.8h-1v-0.9h1V737h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V739.8z"
          />
          <path
            d="M3231.6,747.5c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C3233.2,746.9,3232.7,747.5,3231.6,747.5z"
          />
        </g>
        <g>
          <path d="M3184.8,758.7h-1.1V750h1.1V758.7z" />
          <path
            d="M3186.1,757c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3187,758.8,3186.1,758.2,3186.1,757z M3190,755.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V755.5z"
          />
          <path
            d="M3194.1,757.8v0.9h-1.1V750h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C3195.2,758.9,3194.5,758.4,3194.1,757.8z M3194,755.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V755.8z"
          />
          <path
            d="M3199.8,755.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3201,758.9,3199.8,757.5,3199.8,755.6z M3200.9,754.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3201.8,753.2,3201.1,753.8,3200.9,754.9z"
          />
          <path d="M3207.9,758.7h-1.1V750h1.1V758.7z" />
          <path
            d="M3209.8,756.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L3209.8,756.8z"
          />
          <path
            d="M3219,757c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3c0.3-1,1.2-1.8,2.5-1.8
				c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3219.9,758.8,3219,758.2,3219,757z M3222.9,755.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V755.5z"
          />
          <path
            d="M3225.9,752.4h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V752.4z"
          />
          <path
            d="M3232.3,755.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1V750h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C3233.3,758.9,3232.3,757.4,3232.3,755.6z M3235.3,753.2c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C3237.1,753.9,3236.2,753.2,3235.3,753.2z"
          />
        </g>
        <g>
          <path
            d="M3195.2,771.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C3193.3,770.5,3194.1,771.4,3195.2,771.4z"
          />
          <path
            d="M3202.3,767c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V767z"
          />
          <path d="M3203.4,763.5h1.2v1.3h-1.2V763.5z M3204.6,772.2h-1.1v-6.4h1.1V772.2z" />
          <path
            d="M3206.6,766.8h-1v-0.9h1V764h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V766.8z"
          />
          <path
            d="M3210.1,769.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C3211.3,772.4,3210.1,771,3210.1,769.1z M3211.2,768.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C3212.1,766.7,3211.4,767.3,3211.2,768.4z"
          />
          <path
            d="M3220.4,767c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V767z"
          />
          <path d="M3221.6,763.5h1.2v1.3h-1.2V763.5z M3222.7,772.2h-1.1v-6.4h1.1V772.2z" />
          <path
            d="M3224,770.5c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C3225,772.4,3224,771.7,3224,770.5z M3228,769l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4V769z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="3">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M2025.3,1435.3c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2004.8,1389.5,2025.3,1410,2025.3,1435.3"
        />
        <g>
          <path d="M1959.2,1410.5v1h-4.5v2.7h3.9v1h-3.9v3h4.5v1h-5.7v-8.7H1959.2z" />
          <path
            d="M1963.3,1418.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1961.4,1417.5,1962.2,1418.4,1963.3,1418.4z"
          />
          <path
            d="M1969.6,1419.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1972.5,1418,1971.3,1419.4,1969.6,1419.4z
				 M1969.6,1418.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1967.7,1417.5,1968.5,1418.4,1969.6,1418.4
				z"
          />
          <path
            d="M1973.9,1412.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1412.9z"
          />
          <path
            d="M1983.3,1419.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1986.2,1418,1985,1419.4,1983.3,1419.4z
				 M1983.3,1418.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1981.4,1417.5,1982.2,1418.4,1983.3,1418.4
				z"
          />
          <path
            d="M1988.7,1419.2h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7
				V1419.2z"
          />
          <path d="M1998.2,1410.5h1.2v1.3h-1.2V1410.5z M1999.3,1419.2h-1.1v-6.4h1.1V1419.2z" />
          <path
            d="M2003.7,1418.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2001.8,1417.5,2002.6,1418.4,2003.7,1418.4z"
          />
        </g>
        <g>
          <path d="M1954,1432.8l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H1954z" />
          <path d="M1958.5,1424h1.2v1.3h-1.2V1424z M1959.6,1432.8h-1.1v-6.4h1.1V1432.8z" />
          <path
            d="M1961,1431c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1961.9,1432.9,1961,1432.2,1961,1431z M1964.9,1429.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V1429.5z"
          />
          <path
            d="M1968.9,1431.8v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1970,1432.9,1969.3,1432.5,1968.9,1431.8z M1968.9,1429.8c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1429.8z"
          />
          <path d="M1975,1424h1.2v1.3h-1.2V1424z M1976.2,1432.8h-1.1v-6.4h1.1V1432.8z" />
          <path d="M1979.1,1432.8h-1.1v-8.7h1.1V1432.8z" />
          <path d="M1980.9,1424h1.2v1.3h-1.2V1424z M1982,1432.8h-1.1v-6.4h1.1V1432.8z" />
          <path
            d="M1984,1427.3h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1427.3z"
          />
          <path
            d="M1988.4,1435c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C1990,1434.4,1989.5,1435,1988.4,1435z"
          />
          <path
            d="M2000.5,1432.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2003.5,1431.6,2002.3,1432.9,2000.5,1432.9z
				 M2000.5,1432c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1998.7,1431,1999.4,1432,2000.5,1432z"
          />
          <path
            d="M2007.6,1424.9c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V1424.9z"
          />
        </g>
        <g>
          <path
            d="M1949.6,1444.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1949.6,1444.3z"
          />
          <path
            d="M1960.4,1446.3h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1446.3z"
          />
          <path
            d="M1962.3,1444.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1962.3,1444.3z"
          />
          <path
            d="M1968.3,1440.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1440.9z"
          />
          <path
            d="M1971.7,1444.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1972.7,1446.4,1971.7,1445.7,1971.7,1444.6z M1975.7,1443l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1443z"
          />
          <path d="M1978.6,1437.5h1.2v1.3h-1.2V1437.5z M1979.7,1446.3h-1.1v-6.4h1.1V1446.3z" />
          <path
            d="M1981.6,1439.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V1439.9z"
          />
          <path
            d="M1987.9,1444.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1988.9,1446.4,1987.9,1445.7,1987.9,1444.6z M1991.9,1443l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1443z"
          />
          <path
            d="M1995.9,1445.4v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1997,1446.4,1996.3,1446,1995.9,1445.4z M1995.9,1443.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V1443.3z"
          />
          <path d="M2003.1,1446.3h-1.1v-8.7h1.1V1446.3z" />
          <path
            d="M2004.5,1443.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2005.7,1446.4,2004.5,1445.1,2004.5,1443.1z M2005.6,1442.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2006.5,1440.7,2005.8,1441.4,2005.6,1442.4z"
          />
        </g>
        <g>
          <path
            d="M1950.4,1458.1c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1951.3,1459.9,1950.4,1459.2,1950.4,1458.1z M1954.3,1456.5l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V1456.5z"
          />
          <path
            d="M1959.7,1462.1c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1v-0.9h1.1v5.8
				C1962.7,1461.1,1961.4,1462.1,1959.7,1462.1z M1959.8,1454.3c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2
				c0.9,0,1.8-0.7,1.8-2.1v-0.2C1961.6,1455,1960.7,1454.3,1959.8,1454.3z"
          />
          <path
            d="M1967.8,1454.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1454.5z"
          />
          <path d="M1968.9,1451.1h1.2v1.3h-1.2V1451.1z M1970.1,1459.8h-1.1v-6.4h1.1V1459.8z" />
          <path
            d="M1974.5,1459c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C1972.6,1458.1,1973.3,1459,1974.5,1459z"
          />
          <path
            d="M1983.3,1459.8h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1459.8z"
          />
          <path d="M1986.2,1459.8h-1.1v-8.7h1.1V1459.8z" />
          <path
            d="M1988.2,1454.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V1454.4z"
          />
          <path
            d="M1997.2,1459.8h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V1459.8z"
          />
          <path
            d="M2002.3,1454.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V1454.5z"
          />
          <path
            d="M2003.1,1456.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2004.3,1459.9,2003.1,1458.6,2003.1,1456.6z M2004.2,1455.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2005.1,1454.2,2004.4,1454.9,2004.2,1455.9z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="2">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M2080.1,330.9c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C2059.7,285.2,2080.1,305.6,2080.1,330.9"
        />
        <g>
          <path
            d="M2005.7,314c1.1,0,1.9-0.7,2.3-1.8l1.1,0.4c-0.5,1.5-1.7,2.4-3.4,2.4c-2.3,0-3.9-1.8-3.9-4.5c0-2.7,1.7-4.5,3.9-4.5
				c1.7,0,2.9,0.9,3.4,2.4l-1.1,0.4c-0.3-1.1-1.1-1.8-2.3-1.8c-1.6,0-2.8,1.4-2.8,3.5S2004.1,314,2005.7,314z"
          />
          <path
            d="M2012.8,315c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2015.7,313.7,2014.5,315,2012.8,315z M2012.8,314.1
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2010.9,313.1,2011.7,314.1,2012.8,314.1z"
          />
          <path
            d="M2018.2,314.9h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1V311c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1V311c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V314.9z"
          />
          <path
            d="M2028.8,313.9v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2029.9,315,2029.2,314.6,2028.8,313.9z M2028.8,311.9c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V311.9z"
          />
          <path
            d="M2034.5,311.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2035.7,315,2034.5,313.6,2034.5,311.7z M2035.6,311h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2036.5,309.3,2035.8,309.9,2035.6,311z"
          />
          <path
            d="M2041.7,309.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V309.4z"
          />
          <path d="M2045.6,306.1h1.2v1.3h-1.2V306.1z M2046.7,314.9h-1.1v-6.4h1.1V314.9z" />
          <path
            d="M2048.7,309.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V309.4z"
          />
          <path d="M2052.7,306.1h1.2v1.3h-1.2V306.1z M2053.8,314.9h-1.1v-6.4h1.1V314.9z" />
          <path d="M2057.3,314.9l-2.4-6.4h1.2l1.8,5l1.8-5h1.1l-2.4,6.4H2057.3z" />
          <path
            d="M2061.4,311.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C2062.6,315,2061.4,313.6,2061.4,311.7z M2062.5,311h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2063.3,309.3,2062.7,309.9,2062.5,311z"
          />
        </g>
        <g>
          <path
            d="M1996.2,330.7c-1.4,0-2.4-0.6-2.8-1.8l1-0.4c0.2,0.8,0.9,1.3,1.8,1.3c1.1,0,1.9-0.6,1.9-1.9v-0.7
				c-0.3,0.6-1.1,1.1-1.9,1.1c-1.7,0-2.8-1.4-2.8-3.2c0-1.7,1-3.2,2.8-3.2c0.9,0,1.6,0.4,1.9,1.1V322h1.1v5.8
				C1999.1,329.7,1997.9,330.7,1996.2,330.7z M1996.3,322.9c-1.2,0-1.9,0.9-1.9,2.2c0,1.3,0.7,2.2,1.9,2.2c0.9,0,1.8-0.7,1.8-2.1
				V325C1998,323.5,1997.2,322.9,1996.3,322.9z"
          />
          <path d="M2002,328.4h-1.1v-8.7h1.1V328.4z" />
          <path
            d="M2006.4,328.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2009.3,327.2,2008.1,328.5,2006.4,328.5z
				 M2006.4,327.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2004.5,326.7,2005.3,327.6,2006.4,327.6z"
          />
          <path
            d="M2011.8,327.5v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2012.9,328.5,2012.2,328.1,2011.8,327.5z M2011.8,325.4c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V325.4z"
          />
          <path
            d="M2017.4,326.7c0-1.3,1-1.8,2.4-2.1l1.5-0.3V324c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2018.4,328.5,2017.4,327.8,2017.4,326.7z M2021.4,325.1l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1
				c0.9,0,1.7-0.6,1.7-1.4V325.1z"
          />
          <path d="M2025.4,328.4h-1.1v-8.7h1.1V328.4z" />
          <path
            d="M2033.6,328.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2036.6,327.2,2035.4,328.5,2033.6,328.5z
				 M2033.6,327.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2031.8,326.7,2032.5,327.6,2033.6,327.6z"
          />
          <path
            d="M2040.8,320.5c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1V323h-1V322h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V320.5z"
          />
          <path
            d="M2044.5,320.5c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1V323h-1V322h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V320.5z"
          />
          <path
            d="M2045,325.2c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2l1,0.3
				c-0.4,1.1-1.3,1.8-2.6,1.8C2046.2,328.5,2045,327.2,2045,325.2z M2046.1,324.5h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2047,322.8,2046.3,323.5,2046.1,324.5z"
          />
          <path
            d="M2055.3,323.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1V322h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V323.1z"
          />
          <path
            d="M2056.7,326.4c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2056.7,326.4z"
          />
          <path
            d="M2068.8,328.5c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2071.8,327.2,2070.5,328.5,2068.8,328.5z
				 M2068.8,327.6c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2067,326.7,2067.7,327.6,2068.8,327.6z"
          />
          <path
            d="M2075.9,320.5c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1V323h-1V322h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V320.5z"
          />
        </g>
        <g>
          <path d="M1995.5,341.9h-1.1v-8.7h1.1V341.9z" />
          <path
            d="M1996.7,338.7c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1997.9,342,1996.7,340.7,1996.7,338.7z M1997.8,338.1h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1998.6,336.4,1998,337,1997.8,338.1z"
          />
          <path
            d="M2003.6,339.9c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2003.6,339.9z"
          />
          <path
            d="M2009.2,339.9c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2009.2,339.9z"
          />
          <path
            d="M2017.2,339.9c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2017.2,339.9z"
          />
          <path
            d="M2027.8,341.9h-1.1V341c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V341.9z"
          />
          <path
            d="M2029.5,339.9c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L2029.5,339.9z"
          />
          <path
            d="M2035.2,336.5h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V336.5z"
          />
          <path
            d="M2038.4,340.2c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2039.4,342,2038.4,341.3,2038.4,340.2z M2042.4,338.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V338.6z"
          />
          <path d="M2045,333.2h1.2v1.3h-1.2V333.2z M2046.2,341.9h-1.1v-6.4h1.1V341.9z" />
          <path
            d="M2047.8,335.5h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1V338c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V335.5z"
          />
          <path
            d="M2053.9,340.2c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C2054.8,342,2053.9,341.3,2053.9,340.2z M2057.8,338.6l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V338.6z"
          />
          <path
            d="M2061.6,341v0.9h-1.1v-8.7h1.1v3.3c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2062.7,342,2062,341.6,2061.6,341z M2061.6,339c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V339z"
          />
          <path d="M2068.6,341.9h-1.1v-8.7h1.1V341.9z" />
          <path
            d="M2070.6,344.2c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C2072.2,343.6,2071.7,344.2,2070.6,344.2z"
          />
        </g>
        <g>
          <path
            d="M2014.5,355.3v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C2015.6,356.4,2014.8,355.9,2014.5,355.3z M2014.4,353.3c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V353.3z"
          />
          <path
            d="M2023.7,350.9c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V350.9z"
          />
          <path
            d="M2027.1,356.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2030.1,355,2028.9,356.4,2027.1,356.4z
				 M2027.1,355.4c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2025.3,354.5,2026,355.4,2027.1,355.4z"
          />
          <path
            d="M2030.8,353c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2031.8,356.4,2030.8,354.8,2030.8,353z M2033.8,350.7c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C2035.6,351.3,2034.7,350.7,2033.8,350.7z"
          />
          <path
            d="M2043.3,356.2h-1.1v-0.9c-0.4,0.5-1,1-1.9,1c-1.4,0-2.1-0.9-2.1-2.3v-4.2h1.1v4c0,0.9,0.4,1.5,1.3,1.5
				c0.8,0,1.6-0.6,1.6-1.7v-3.8h1.1V356.2z"
          />
          <path
            d="M2047.4,355.4c0.9,0,1.4-0.6,1.6-1.3l1,0.4c-0.3,1.1-1.2,1.9-2.6,1.9c-1.8,0-3-1.4-3-3.3c0-2,1.2-3.3,3-3.3
				c1.3,0,2.2,0.8,2.5,1.8l-0.9,0.5c-0.2-0.8-0.7-1.3-1.6-1.3c-1.1,0-1.9,0.9-1.9,2.4C2045.5,354.5,2046.3,355.4,2047.4,355.4z"
          />
          <path
            d="M2050.5,353c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2l1,0.3
				c-0.4,1.1-1.3,1.8-2.6,1.8C2051.7,356.4,2050.5,355,2050.5,353z M2051.6,352.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C2052.5,350.7,2051.8,351.3,2051.6,352.4z"
          />
          <path
            d="M2026.2,363c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V363z"
          />
          <path
            d="M2029.5,371c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2032.4,369.6,2031.2,371,2029.5,371z M2029.5,370
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2027.6,369.1,2028.3,370,2029.5,370z"
          />
          <path
            d="M2036.1,371c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C2039,369.6,2037.8,371,2036.1,371z M2036.1,370
				c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C2034.2,369.1,2035,370,2036.1,370z"
          />
          <path
            d="M2039.7,367.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C2040.8,371,2039.7,369.4,2039.7,367.6z M2042.7,365.3c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C2044.5,365.9,2043.6,365.3,2042.7,365.3z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="1">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1435.8,507.6c0,25.3-20.5,45.8-45.8,45.8c-25.3,0-45.8-20.5-45.8-45.8c0-25.3,20.5-45.8,45.8-45.8
			C1415.3,461.8,1435.8,482.3,1435.8,507.6"
        />
        <g>
          <path d="M1361.6,498.3h-1.2v-8.7h1.2V498.3z" />
          <path
            d="M1363.5,491.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V491.9z"
          />
          <path
            d="M1370.5,492.8h-1v-0.9h1V490h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V492.8z"
          />
          <path
            d="M1374,495.1c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2l1,0.3
				c-0.4,1.1-1.3,1.8-2.6,1.8C1375.3,498.4,1374,497.1,1374,495.1z M1375.1,494.4h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1376,492.7,1375.3,493.3,1375.1,494.4z"
          />
          <path
            d="M1381,491.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.4,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V491.9z"
          />
          <path
            d="M1388,496.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1388,496.3z"
          />
          <path d="M1393.8,489.5h1.2v1.3h-1.2V489.5z M1394.9,498.3h-1.1v-6.4h1.1V498.3z" />
          <path
            d="M1396.9,492.8h-1v-0.9h1V490h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V492.8z"
          />
          <path
            d="M1401.3,500.5c-0.3,0-0.4,0-0.6,0v-0.9c0.2,0,0.3,0.1,0.5,0.1c0.5,0,0.7-0.2,1-0.8l0.3-0.8l-2.4-6.1h1.2l1.8,4.9l1.8-4.9
				h1.1l-2.7,7.1C1402.9,499.9,1402.4,500.5,1401.3,500.5z"
          />
          <path
            d="M1413.4,498.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1416.4,497.1,1415.2,498.4,1413.4,498.4z
				 M1413.4,497.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1411.6,496.5,1412.3,497.5,1413.4,497.5z"
          />
          <path
            d="M1420.5,490.4c-0.2,0-0.3,0-0.5,0c-0.6,0-1,0.3-1,1.1v0.4h1.4v0.9h-1.4v5.4h-1.1v-5.4h-1v-0.9h1v-0.5c0-1.2,0.7-2,2-2
				c0.2,0,0.3,0,0.6,0V490.4z"
          />
        </g>
        <g>
          <path
            d="M1364.2,511.8h-1.1v-6.4h1.1v0.9c0.3-0.5,0.9-1,1.8-1c0.9,0,1.4,0.4,1.8,1.1c0.4-0.5,1-1.1,2-1.1c1.3,0,2.1,0.9,2.1,2.3
				v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7v3.8h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6c-0.7,0-1.5,0.6-1.5,1.7V511.8
				z"
          />
          <path
            d="M1376.3,511.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1379.2,510.6,1378,511.9,1376.3,511.9z
				 M1376.3,511c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1374.4,510.1,1375.1,511,1376.3,511z"
          />
          <path
            d="M1380.8,506.4h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V506.4z"
          />
          <path
            d="M1387.3,511.9c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1390.2,510.6,1389,511.9,1387.3,511.9z
				 M1387.3,511c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1385.4,510.1,1386.1,511,1387.3,511z"
          />
          <path
            d="M1394.9,506.5c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V506.5z"
          />
          <path d="M1396.1,503.1h1.2v1.3h-1.2V503.1z M1397.2,511.8h-1.1v-6.4h1.1V511.8z" />
          <path
            d="M1399.2,509.8c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1399.2,509.8z"
          />
          <path
            d="M1404.5,508.6c0-1.9,1.2-3.3,2.9-3.3c1.8,0,2.7,1.4,2.7,3.1v0.4h-4.6c0.1,1.3,0.8,2.2,1.9,2.2c0.9,0,1.5-0.5,1.7-1.2
				l1,0.3c-0.4,1.1-1.3,1.8-2.6,1.8C1405.7,511.9,1404.5,510.6,1404.5,508.6z M1405.6,507.9h3.4c0-0.9-0.5-1.7-1.6-1.7
				C1406.5,506.2,1405.8,506.9,1405.6,507.9z"
          />
          <path
            d="M1411.1,508.6c0-1.8,1-3.3,2.8-3.3c0.9,0,1.6,0.4,2,1v-3.3h1.1v8.7h-1.1v-0.9c-0.4,0.6-1.1,1.1-2,1.1
				C1412.1,511.9,1411.1,510.4,1411.1,508.6z M1414,506.3c-1.2,0-1.9,1-1.9,2.3c0,1.4,0.7,2.3,1.9,2.3c0.9,0,1.8-0.7,1.8-2.1v-0.5
				C1415.8,506.9,1414.9,506.3,1414,506.3z"
          />
        </g>
      </g>
    </g>
  );
};

export default SvgNodesEn;
