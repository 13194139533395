import { queryOptions } from '@tanstack/react-query';
import axios from '../axios';
import { flattenAttributes } from '../utils';

export const fetchCategories = async () => {
  const response = await axios.get(`/categories`);
  return response.data;
};

export const categoriesQueryOptions = queryOptions({
  queryKey: ['categories'],
  queryFn: fetchCategories,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  select: (data) => flattenAttributes(data),
});
