import { useQuery } from '@tanstack/react-query';
import { default as React } from 'react';
import { useLoaderData } from 'react-router-dom';
import InitiativeCard from '../components/InitiativeCard';
import Loading from '../components/Loading';
import StakeholderCard from '../components/StakeholderCard';
import { getTranslation } from '../dictionary';
import { claimExpert, fetchExpert } from '../services/ExpertService.js';
import { apiUrl } from '../settings';
import { useLocaleStore } from '../store';
import { addhttp, cleanUrlText, getPhotoIfPresent } from '../utils';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';

function Expert() {
  const language = useLocaleStore((state) => state.language);
  const { id } = useLoaderData();
  const {
    isLoading,
    error,
    data: expert,
  } = useQuery({
    queryKey: [`expert${id}`],
    queryFn: () => fetchExpert(id),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const handleClaim = (event) => {
    event.stopPropagation();
    const newData = {
      data: {
        user_id: JSON.parse(sessionStorage.getItem('stytch-user'))?.user_id,
      },
    };

    claimExpert(id, newData);
  };

  if (isLoading) return <Loading />;

  const photo = expert ? getPhotoIfPresent(expert) : null;
  const imgStyle = {
    backgroundImage: 'url(' + apiUrl?.slice(0, -4) + photo + ')',
    //backgroundSize: 'contain', // Scales the image to fit within the container
    backgroundSize: 'cover', // Scales the image to cover the container
    backgroundPosition: 'center center', // Centers the image within the container
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  };

  const interests = expert?.interest;
  if (error) {
    return <>{error.message}</>;
  }
  return (
    <div className="stakeholder-page expert">
      {expert && (
        <section className="py-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h2 className="mb-1 mt-space">{expert.name}</h2>
                <h5 className="color-c1 font-strong-m mt-2 pb-2">
                  {getTranslation({ key: 'expert', language })}
                  {expert.website && ' — '}
                  {expert.website && (
                    <a href={addhttp(expert.website)} target="_blank" className="external color-c1" rel="noreferrer">
                      {cleanUrlText(expert.website)}
                    </a>
                  )}
                </h5>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="bg-white py-4 pb-5">
        {expert && (
          <div className="container-fluid">
            <div className="row content">
              {photo && (
                <div className="col-12 my-4 d-xl-none">
                  <div className="round-img" style={imgStyle}></div>
                </div>
              )}
              <div className="col-md-8 col-lg-12 col-xl-10 two-cols-lg">
                <div className="break-inside-avoid">
                  <div className="font-sans-s color-black-50 mt-4">
                    {getTranslation({ key: 'description', language })}
                  </div>
                  <div className="font-serif-m mt-1 mr-sm-5">
                    {language === 'en' ? expert.description_en : expert.description}
                  </div>
                </div>

                {interests.length > 0 && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'interests', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      {interests?.map((interest, j) => {
                        let interestName = getTranslation({
                          key: typeof interest === 'string' ? interest : interest.name,
                          categoryKey: 'interestslist',
                          language,
                        });

                        return (
                          <span key={j}>
                            {j > 0 ? ', ' : ''}
                            {interestName}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}

                {(expert.contact_email || expert.contact_tel) && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'contacts', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      {/*<div>{expert.name}</div>*/}
                      <div className="py-1">
                        <EmailIcon className="color-black-50"/> {expert.contact_email && (
                          <>
                            <a href={`mailto:${expert.contact_email}`} className="hover_email">{expert.contact_email}</a>
                          </>
                        )}
                      </div>
                      { expert.contact_tel ? <div><PhoneIcon className="color-black-50" /> {expert.contact_tel}</div> : ''}
                    </div>
                  </div>
                )}

                {expert.website && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'website', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      <div><LanguageIcon className="color-black-50"/> <a href={addhttp(expert.website)} target="_blank" className="external hover_email" rel="noreferrer">
                        {cleanUrlText(expert.website)}
                      </a></div>
                    </div>
                  </div>
                )}

                {expert.address && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'location', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      <div><LocationOnIcon className="color-black-50"/> {expert.address}</div>
                    </div>
                  </div>
                )}

                {/* {expert?.user_id && (
                  <>
                    {sessionStorage.getItem('stytch-user') && (
                      <>
                        {JSON.parse(sessionStorage.getItem('stytch-user'))?.user_id !== expert?.user_id && (
                          <div className="break-inside-avoid mr-xl-5 pt-1">
                            <LinkScrollTop className="color-c2" to={`/suggestion/expert/${expert.id}`}>
                              {getTranslation({ key: 'suggest', language })}
                            </LinkScrollTop>
                          </div>
                        )}
                      </>
                    )}

                    {sessionStorage.getItem('stytch-user') === null && (
                      <div className="break-inside-avoid mr-xl-5 pt-1">
                        <button className="color-c2" onClick={() => navigate(`/suggestion/expert/${expert.id}`)}>
                          {getTranslation({ key: 'suggest', language })}
                        </button>
                      </div>
                    )}
                  </>
                )} */}
              </div>

              {photo && (
                <div className="col-md-2 my-4 d-none d-xl-flex justify-content-end">
                  <div className="round-img" style={imgStyle}></div>
                </div>
              )}
            </div>
            {sessionStorage.getItem('stytch-user') && (expert?.user_id === null || expert?.user_id === '') && (
              <div>
                <div className="font-sans-s color-black-50 mt-4">{getTranslation({ key: 'claim', language })}</div>
                <div className="font-serif-m mt-1">
                  <div>
                    <p
                      className="color-c1 font-serif-m font-strong-m my-1 cursor-pointer claim-button"
                      onClick={(event) => handleClaim(event)}
                    >
                      Claim
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </section>

      {(expert?.initiative?.length > 0 || expert?.stakeholder?.length > 0) && (
        <section className="stakeholders-cards py-4 pb-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h2 className="mt-space-2 mb-space">{getTranslation({ key: 'related', language })}</h2>
              </div>
            </div>
            <div className="row">
              {expert.initiative.map((d, i) => {
                return <InitiativeCard d={d} key={i} />;
              })}
              {expert.stakeholder.map((d, i) => {
                if (!d.interest) {
                  d.interest = [];
                }
                return <StakeholderCard d={d} key={i} />;
              })}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Expert;
