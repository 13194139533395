import React, { forwardRef, useImperativeHandle } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Chip } from '@mui/material';
import { getTranslation } from '../dictionary';

const InterestSelect = forwardRef(({ language, interests, selectedInterests, setSelectedInterests }, ref) => {
  const handleInterestSelection = (event) => {
    const value = event.target.value;
    setSelectedInterests(typeof value === 'string' ? value.split(',') : value);
  };

  useImperativeHandle(ref, () => ({
    register: (name) => ({
      onChange: (event) => {
        const value = event.target.value;
        setSelectedInterests(typeof value === 'string' ? value.split(',') : value);
      },
      value: selectedInterests,
      name,
    }),
  }));

  return (
    <FormControl fullWidth variant="standard" margin="normal">
      <InputLabel>{getTranslation({ key: 'interests', language })}</InputLabel>
      <Select
        label={getTranslation({ key: 'interests', language })}
        multiple
        value={selectedInterests}
        onChange={handleInterestSelection}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Chip
                key={value}
                label={interests.data.find((interest) => interest.name === value)[`name_${language}`]}
                style={{ marginRight: 4 }}
              />
            ))}
          </div>
        )}
      >
        {interests?.data?.map((interest) => (
          <MenuItem key={interest.id} value={interest.name}>
            {interest[`name_${language}`]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export default InterestSelect;
