import { useQuery } from '@tanstack/react-query';
import { groupBy } from 'lodash';
import React, { Suspense, useState } from 'react';
import openSidebarButton from '../assets/images/menu-sidebar-open.svg';
import IndicatorCard from '../components/IndicatorCard';
import LinkScrollTop from '../components/LinkScrollTop';
import Loading from '../components/Loading';
import { getTranslation } from '../dictionary';
import { indicatorsQueryOptions } from '../services/IndicatorService';
import { useLocaleStore } from '../store';
import { toggleSidebar } from '../utils';

const groupText = {
  social_sustainability: {
    en: 'Social indicators are defined as statistical measures that describe social trends and conditions that affect human well-being',
    nl: 'Sociale indicatoren worden gedefinieerd als statistische maatstaven die sociale trends en omstandigheden beschrijven die invloed hebben op het menselijk welzijn',
  },
  economic_sustainability: {
    nl: 'Economische indicatoren omvatten statistieken over de economische activiteit in Leuven',
    en: 'Economic indicators include statistics on economic activity in Leuven',
  },
  urban_rural_integration: {
    nl: 'Deze categorie omvat indicatoren met betrekking tot de relatie tussen stad en platteland',
    en: 'This category includes indicators related to urban-rural relationship',
  },
  environmental_sustainability: {
    nl: 'Deze categorie omvat indicatoren met betrekking tot de staat van het milieu en de omgeving in Leuven',
    en: 'This category includes indicators related to the state of the environment in Leuven',
  },
  food_governance: {
    nl: 'Deze categorie omvat indicatoren met betrekking tot het beleid in Leuven',
    en: 'This category includes indicators related to the policy in Leuven',
  },
};

function Grouped({ grouped }) {
  const language = useLocaleStore((state) => state.language);

  return (
    <div key="grouped">
      {grouped.map((g, i) => {
        const indicatorsGroup = g;
        const categoryId = indicatorsGroup[0].indicator_category;
        const categoryName =
          language === 'nl' ? indicatorsGroup[0].indicator_category_nl : indicatorsGroup[0].indicator_category_en;
        const subCategories = g.map((v) => {
          return {
            name: language === 'nl' ? v.impact_areas_nl : v.impact_areas_en,
            id: v.impact_areas_id,
          };
        });
        const uniqSubcategories = subCategories.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
        const uniqSubcategoriesBlocks = [];
        if (uniqSubcategories.length > 5) {
          const half = Math.ceil(uniqSubcategories.length / 2);
          uniqSubcategoriesBlocks.push(uniqSubcategories.slice(0, half));
          uniqSubcategoriesBlocks.push(uniqSubcategories.slice(half));
        } else {
          uniqSubcategoriesBlocks.push(uniqSubcategories);
        }

        return (
          <div className="container-fluid" id={categoryId} key={i}>
            <div className="row mt-5" key={`category-${i}`}>
              <div className="col-md-6 col-xl-4">
                <div className="large-small-header-combo">
                  <h2>{categoryName}</h2>
                </div>
                <p className="font-serif-m pr-md-5 custom-negative-margin-top mb-3">
                  {groupText[categoryId][language]}
                </p>
              </div>
              <div className="col-md-8 col-xl-8">
                <div className="large-small-header-combo">
                  <h5>{getTranslation({ key: 'categories', language })}</h5>
                </div>
                <div className="row custom-negative-margin-top mb-3">
                  {uniqSubcategoriesBlocks.map((block, i) => (
                    <div
                      key={i}
                      className={uniqSubcategoriesBlocks.length === 1 ? 'col-12' : 'col-sm-6 col-md-12 col-xl-6'}
                    >
                      {block.map((s, i) => (
                        <div key={i}>
                          <LinkScrollTop className="font-serif-m" to={`/indicators/impact_areas_id/${s.id}`}>
                            {`${s.name}  ››`}
                          </LinkScrollTop>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="row indicator-cards-container">
              {
                //shuffle(indicatorsGroup)
                indicatorsGroup
                  .filter((g) => {
                    return categoryId === 'social_sustainability'
                      ? g.geographic_area === 'municipality_leuven' || g.geographic_area === 'arrondissement_leuven'
                      : true;
                  })
                  .slice(0, 6)
                  .map((d, j) => {
                    return <IndicatorCard d={d} key={j} />;
                  })
              }
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <LinkScrollTop
                  className="button l my-space-3 rounded-3"
                  to={`/indicators/indicator_category/${categoryId}`}
                >
                  {getTranslation({ key: 'see_all', language })}
                </LinkScrollTop>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function Indicators() {
  const language = useLocaleStore((state) => state.language);

  const { data: indicators } = useQuery(indicatorsQueryOptions);

  const groupedBy = groupBy(indicators, 'indicator_category');
  const grouped = Object.values(groupedBy);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="indicators">
      <section className="indicators-intro pad-small">
        <div className="container-fluid">
          <div className="row pb-5">
            <div className="col-12">
              <div className="large-small-header-combo">
                <h2>{getTranslation({ key: 'indicators_dashboard', language })}</h2>
              </div>
              <p className="font-serif-l">
                {getTranslation({ key: 'indicators_context', language })}
              </p>
            </div>
          </div>
        </div>
      </section>

      <div id="content-overlay-box">
        <section id="overlay">
          <div id="sticky">
            <button
              id="toggleSidebar"
              onClick={() => {
                setSidebarOpen(!sidebarOpen);
                toggleSidebar();
              }}
            >
              {getTranslation({ key: 'filters', language })}&nbsp;
              <img id="sidebarIcon" src={openSidebarButton} alt="HamburgerMenu" />
            </button>
            <div id="sidebarList">
              <ul>
                {grouped.map((g, i) => {
                  const indicatorsGroup = g;
                  const categoryId = indicatorsGroup[0].indicator_category;
                  const categoryName =
                    language === 'nl'
                      ? indicatorsGroup[0].indicator_category_nl
                      : indicatorsGroup[0].indicator_category_en;
                  return (
                    <li key={i}>
                      <h6>
                        <a
                          className="color-c2"
                          href={`#${categoryId}`}
                          onClick={() => {
                            setSidebarOpen(!sidebarOpen);
                            toggleSidebar();
                          }}
                        >
                          <span>{categoryName}</span>
                        </a>
                      </h6>
                    </li>
                  );
                })}
              </ul>
              <div className="col-12"></div>
            </div>
          </div>
        </section>

        <section className="bg-purple py-2"></section>
        <div className="content">
          <section className="cards-wrapper">
            <Suspense fallback={<Loading />}>
              <Grouped grouped={grouped} />
            </Suspense>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Indicators;
