import React from 'react';
import { NavLink } from 'react-router-dom';
import { closeMenuMobile } from '../utils';

const LinkScrollTop = (props) => {
  const handleClick = () => {
    closeMenuMobile();
    window.scrollTo(0, 0);
  };

  return (
    <NavLink onClick={handleClick} activeclassname="active item" className="item" {...props}>
      {props.children}
    </NavLink>
  );
};

export default LinkScrollTop;
