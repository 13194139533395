import axios from 'axios';
import { apiUrl } from './settings';

const instance = axios.create({
  baseURL: apiUrl,
  headers: { 'Content-type': 'application/json', Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` },
});

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      console.error('Response error:', error.response.data);
    } else if (error.request) {
      console.error('Request error:', error.request);
    } else {
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  },
);

export default instance;
