import { queryOptions } from '@tanstack/react-query';
import axios from '../axios';
import { flattenAttributes } from '../utils';

export const fetchSectors = async () => {
  const response = await axios.get(`/sectors`);
  return flattenAttributes(response.data);
};

export const sectorsQueryOptions = queryOptions({
  queryKey: ['sectors'],
  queryFn: fetchSectors,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
});
