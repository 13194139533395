export const links = [
  {
    kumu_node_id: 1,
    kumu_node_title: 'Intensity of motorised transport',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 2,
    kumu_node_title: 'Competitive global offers of less sustainably produced food',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 3,
    kumu_node_title: 'Economic viability of sustainable agriculture',
    related_indicator1_id: 42,
    related_indicator1_name_en: 'Number of organic farms',
    related_indicator1_name_nl: 'Aantal biologische landbouwbedrijven',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 4,
    kumu_node_title: 'Reliability of quality labels and criteria',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 5,
    kumu_node_title: 'Need for pesticides',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 6,
    kumu_node_title: 'Efficiency of last mile delivery in urban areas',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 7,
    kumu_node_title: 'Availability of sustainably produced food',
    related_indicator1_id: 42,
    related_indicator1_name_en: 'Number of organic farms',
    related_indicator1_name_nl: 'Aantal biologische landbouwbedrijven',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 8,
    kumu_node_title: 'Level of orthodoxy in sustainability debate',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 9,
    kumu_node_title: 'Proximity of primary producer and point of consumption',
    related_indicator1_id: 34,
    related_indicator1_name_en: 'Number of farms selling directly to customers through CSA',
    related_indicator1_name_nl:
      'Aantal landbouwbedrijven die rechtstreeks aan consumenten verkopen via gemeenschapslandbouw (CSA)',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 10,
    kumu_node_title: 'Level of sustainability in global farming',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 11,
    kumu_node_title: 'Number of steps/elements in the distribution chain',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 12,
    kumu_node_title: 'Cost of motorised transport',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 13,
    kumu_node_title: 'Degree of scientific underpinning',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 14,
    kumu_node_title: 'Degree of sustainable food consumption',
    related_indicator1_id: 93,
    related_indicator1_name_en: 'Percentage of vegans',
    related_indicator1_name_nl: 'Percentage van de Belgen dat vegan is',
    related_indicator2_id: 94,
    related_indicator2_name_en: 'Percentage of vegetarians',
    related_indicator2_name_nl: 'Percentage van de Belgen dat vegetarisch is',
    related_indicator3_id: 95,
    related_indicator3_name_en: 'Percentage of flexitarians',
    related_indicator3_name_nl: 'Percentage van de Belgen dat flexitariër is',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 15,
    kumu_node_title: 'Level of coherence in food messaging in media',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 16,
    kumu_node_title: 'Education of farmers on sustainability',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 17,
    kumu_node_title: 'Ability to implement appropriate food choices',
    //related_indicator1_id: 74,
    //related_indicator1_name_en: 'Number of campaigns about collective action on food system changes',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 18,
    kumu_node_title: 'Sustainable soil & water management',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 19,
    kumu_node_title: 'Availability of arable land',
    related_indicator1_id: 56,
    related_indicator1_name_en: 'Total surface of land used for agriculture in the municipality of Leuven',
    related_indicator1_name_nl: 'Totale opppervlakte gebruikt door landbouw in de gemeente Leuven',
    related_indicator2_id: 57,
    related_indicator2_name_en: 'Percentage of total surface area used for agriculture in the municipality of Leuven',
    related_indicator2_name_nl: 'Percentage van de totale oppervlakte gebruikt voor landbouw in de gemeente Leuven',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 20,
    kumu_node_title: 'Reuse of farm waste',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 21,
    kumu_node_title: 'Sustainability fatigue',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 22,
    kumu_node_title: 'Presence of short chain distribution operators',
    related_indicator1_id: 34,
    related_indicator1_name_en: 'Number of farms selling directly to customers through CSA',
    related_indicator1_name_nl:
      'Aantal landbouwbedrijven die rechtstreeks aan consumenten verkopen via gemeenschapslandbouw (CSA)',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 23,
    kumu_node_title: 'Convenience in shopping',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 24,
    kumu_node_title: 'Demand for sustainable food',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 25,
    kumu_node_title: 'Convenience in shopping',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 26,
    kumu_node_title: 'Green space in and around living areas',
    related_indicator1_id: 63,
    related_indicator1_name_en: 'Forest area',
    related_indicator1_name_nl: 'Bosoppervlakte',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 27,
    kumu_node_title: 'Extent of biodiversity',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 28,
    kumu_node_title: 'Clarity of food labelling on sustainability',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 29,
    kumu_node_title: 'Availability of qualified personnel',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 30,
    kumu_node_title: 'Willingness of farmers to adapt/innovate',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 31,
    kumu_node_title: 'Use of chemicals in agriculture',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 32,
    kumu_node_title: 'Psychological ambivalence',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 33,
    kumu_node_title: 'Sense of urgency to adjust consumption patterns',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 34,
    kumu_node_title: 'Level of feed production',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 35,
    kumu_node_title: 'Presence of local shops',
    related_indicator1_id: 89,
    related_indicator1_name_en: 'Number of farm producers',
    related_indicator1_name_nl: 'Aantal hoeveproducenten',
    related_indicator2_id: 51,
    related_indicator2_name_en: 'Number of weekly markets',
    related_indicator2_name_nl: 'Aantal wekelijkse markten',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 36,
    kumu_node_title: 'Municipal food waste composting',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 37,
    kumu_node_title: 'presence of local processing infrastructure',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 38,
    kumu_node_title: 'Level of income',
    related_indicator1_id: 81,
    related_indicator1_name_en: 'Number of initiatives focused on people in poverty',
    related_indicator1_name_nl: 'Aantal initiatieven met als doelgroep mensen in kansarmoede',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 39,
    kumu_node_title: 'Extent of urban heat island effect',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 40,
    kumu_node_title: 'Level of fossil energy used',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 41,
    kumu_node_title: 'Diversity of food offering in large retail',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 42,
    kumu_node_title: 'Recovery of energy from biomass',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 43,
    kumu_node_title: 'degree of societal consensus about sustainable food consumption',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 44,
    kumu_node_title: 'Accessibiity to sustainably produced food',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 45,
    kumu_node_title: 'Concentration of shopping into suburban mega-malls',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 46,
    kumu_node_title: 'Strength of food habits',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 47,
    kumu_node_title: 'Level of food education',
    related_indicator1_id: 22,
    related_indicator1_name_en: 'Number of visitors Boeren met Klasse (schoolchildren)',
    related_indicator1_name_nl:
      'Aantal bezoekers in klasverband aan landbouwbedrijven die meedoen aan Boeren met Klasse',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 48,
    kumu_node_title: 'Degree of pro-sumership',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 49,
    kumu_node_title: 'Food spending power',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 50,
    kumu_node_title: 'Perceived level of food safety',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 51,
    kumu_node_title: 'Share of animal farming in agriculture',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 52,
    kumu_node_title: 'Inclusion of sustainability criteria in food processing',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 53,
    kumu_node_title: 'Disposal of manure',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 54,
    kumu_node_title: 'Ability to coordinate between primary producers',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 55,
    kumu_node_title: 'Policy stimulus for sustainable food systems',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 56,
    kumu_node_title: 'Extent of rooftop farming',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 57,
    kumu_node_title: 'Level of waste',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 58,
    kumu_node_title: 'Population Health',
    related_indicator1_id: 14,
    related_indicator1_name_en: 'Percentage of overweight adults (BMI >= 25)',
    related_indicator1_name_nl: 'Percentage van volwassenen met overgewicht',
    related_indicator2_id: 15,
    related_indicator2_name_en: 'Percentage of obese adults (BMI >= 30)',
    related_indicator2_name_nl: 'Percentage van volwassenen met obesitas',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 59,
    kumu_node_title: 'Ability to implement circular approaches',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 60,
    kumu_node_title: 'Level of R&D/Innovation in sustainability',
    //related_indicator1_id: 64,
    //related_indicator1_name_en:'Number of initiatives related to sustainable technologies',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator1_name_nl: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 61,
    kumu_node_title: 'Sustainable crop choices',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 62,
    kumu_node_title: 'Preparedness to change food habits',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 63,
    kumu_node_title: 'Time dedicated to sustainable food',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 64,
    kumu_node_title: 'Inclusion of sustainability criteria in agro-auctioning',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 65,
    kumu_node_title: 'Desire to combine food shopping with other trips',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 66,
    kumu_node_title: 'Degree of sustainability virtue signaling',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 67,
    kumu_node_title: 'Experienced disruption of the food supply chain',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 68,
    kumu_node_title: 'Speculation on land',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 69,
    kumu_node_title: 'Presence of waste recovery infrastructure',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 70,
    kumu_node_title: 'Level of sustainability awakening',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 71,
    kumu_node_title: 'Experienced impact of climate change',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 72,
    kumu_node_title: 'Accessibility of technologies for sustainable farming',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    //related_indicator1_id: 64,
    //related_indicator1_name_en:'Number of initiatives related to sustainable technologies',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 73,
    kumu_node_title: 'Horeca offering sustainable food',
    related_indicator1_id: 36,
    related_indicator1_name_en: 'Number of 100% vegetarian eating out places',
    related_indicator1_name_nl: 'Aantal restaurants met een 100% vegetarisch aanbod',
    related_indicator2_id: 37,
    related_indicator2_name_en: 'Number of 100% plant-based eating out places',
    related_indicator2_name_nl: 'Aantal restaurants met een 100% plantaardig aanbod',
    related_indicator3_id: 38,
    related_indicator3_name_en: 'Number of vegan-friendly eating out places',
    related_indicator3_name_nl: 'Aantal restaurants met veganistische opties',
    related_indicator4_id: 39,
    related_indicator4_name_en: 'Number of vegetarian-friendly eating out places',
    related_indicator4_name_nl: 'Aantal restaurants met vegetarische opties',
  },
  {
    kumu_node_id: 74,
    kumu_node_title: 'Level of home composting',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 75,
    kumu_node_title: 'Global engagement in realising SDGs',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 76,
    kumu_node_title: 'Value assigned to origin of food',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 77,
    kumu_node_title: 'Cost of buying in smaller volumes',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 78,
    kumu_node_title: "Perception of shopping as an experience'",
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 79,
    kumu_node_title: 'Shelfspace allocated to sustainable food at large retail',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    // related_indicator1_id: 52,
    // related_indicator1_name_en:
    //   'Number of retailers that sell certified regional products',
    related_indicator1_name_nl: '',
    //related_indicator2_id: 33,
    //related_indicator2_name_en:'Number of retailers and caterers that purchase regional food products',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator2_name_nl: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 80,
    kumu_node_title: 'Affordability of sustainably produced food',
    related_indicator1_id: 2,
    related_indicator1_name_en: 'Cost of a healthy food basket in the nearby market for a single person',
    related_indicator1_name_nl: 'Prijs voor een gezonde boodschappenmand voor een alleenstaand persoon',
    related_indicator2_id: 3,
    related_indicator2_name_en:
      'Cost of a healthy food basket in the nearby market for a family of two adults and two children',
    related_indicator2_name_nl:
      'Aantal landbouwbedrijven die rechtstreeks aan consumenten verkopen via gemeenschapslandbouw (CSA)',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 81,
    kumu_node_title: 'Competitive pricing of sustainable food',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 82,
    kumu_node_title: 'Availability of sustainable transport options',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 83,
    kumu_node_title: 'Heterogeneity of society',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 84,
    kumu_node_title: 'Food Literacy',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 85,
    kumu_node_title: 'Land available for sustainable food farming',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 86,
    kumu_node_title: 'Complexity of governmental structures',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 87,
    kumu_node_title: 'Level of resentment between groups',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 88,
    kumu_node_title: 'Level of food spillage and excess purchasing',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 89,
    kumu_node_title: 'Perception of meat as centre of the meal',
    related_indicator1_id: 4,
    related_indicator1_name_en: 'Consumer expenditure on meat',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 90,
    kumu_node_title: 'School & institutions implementing sustainability principles',
    related_indicator1_id: 22,
    related_indicator1_name_en: 'Number of visitors Boeren met Klasse (schoolchildren)',
    related_indicator1_name_nl:
      'Aantal bezoekers in klasverband aan landbouwbedrijven die meedoen aan Boeren met Klasse',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 91,
    kumu_node_title: 'Level of sustainability in local farming',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 92,
    kumu_node_title: 'Effectiveness of quality labels',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 93,
    kumu_node_title: 'Production surplus used by foodbanks',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 94,
    kumu_node_title: 'Demand for local & seasonal food',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 95,
    kumu_node_title: 'Financial resources allocated to sustainability',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    // related_indicator1_id: 18,
    // related_indicator1_name_en:
    //   'Budget that Leuven allocates for sustainable initiatives',
    related_indicator1_name_nl: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 96,
    kumu_node_title: 'Land available for sustainable food farming',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 97,
    kumu_node_title: 'Extent of home farming',
    related_indicator1_id: 25,
    related_indicator1_name_en: 'Number of vegetable gardens in the city region',
    related_indicator1_name_nl: 'Aantal buurtmoestuinen in de stadsomgeving',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 98,
    kumu_node_title: 'affordability of arable land',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 99,
    kumu_node_title: 'Cost of waste disposal',
    related_indicator1_id: '',
    related_indicator1_name_en: '',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
  {
    kumu_node_id: 100,
    kumu_node_title: 'Extent of pollution',
    related_indicator1_id: 65,
    related_indicator1_name_en: 'CO2-emission in agriculture',
    related_indicator1_name_nl: 'CO2-emissie in landbouw',
    related_indicator2_id: '',
    related_indicator2_name_en: '',
    related_indicator3_id: '',
    related_indicator3_name_en: '',
    related_indicator4_id: '',
    related_indicator4_name_en: '',
  },
];
