import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import searchIcon from '../assets/images/search.svg';
import ExpertCard from '../components/ExpertCard';
import InterestOverlay from '../components/InterestOverlay';
import LinkScrollTop from '../components/LinkScrollTop';
import { getTranslation } from '../dictionary';
import { expertsQueryOptions } from '../services/ExpertService';
import { useLocaleStore } from '../store';

function Experts() {
  const [interestsSelected, setInterestsSelected] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [interestsStats, setInterestStats] = useState({});

  const language = useLocaleStore((state) => state.language);
  const { data: experts, isLoading: expertsLoading } = useQuery(expertsQueryOptions);

  useEffect(() => {
    experts?.forEach((e) => {
      e.interest?.forEach((interest) => {
        const interestName = typeof interest === 'string' ? interest : interest.name;
        try {
          setInterestStats((prevState) => {
            if (!prevState.hasOwnProperty(interestName)) {
              return {
                ...prevState,
                [interestName]: {
                  count: 0,
                  name_nl: getTranslation({
                    key: interestName,
                    categoryKey: 'interestslist',
                    language: 'nl',
                  }),
                  name_en: getTranslation({
                    key: interestName,
                    categoryKey: 'interestslist',
                    language: 'en',
                  }),
                },
              };
            }
            return prevState;
          });
          if (e.interest[interestName]) {
            setInterestStats((prevState) => ({
              ...prevState,
              [interestName]: {
                ...prevState[interestName],
                count: prevState[interestName].count + 1,
              },
            }));
          }
        } catch (err) {
          setInterestStats((prevState) => ({
            ...prevState,
            [interestName]: {
              count: 0,
              name_nl: interestName,
              name_en: interestName,
            },
          }));
        }
      });
    });
  }, [experts, expertsLoading]);
  

  const updateFilters = (type, value) => {
    if (type === null) {
      setInterestsSelected([]);
      setSearchText('');
      setSearchIsOpen(false);
    } else if (type === 'search') {
      setSearchText(value);
    } else if (type === 'addInterest') {
      const interestName = typeof value === 'string' ? value : value.name;
      setInterestsSelected((prevState) => prevState.concat([interestName]));
    } else if (type === 'removeInterest') {
      const interestName = typeof value === 'string' ? value : value.name;
      setInterestsSelected((prevState) => prevState.filter((e) => e !== interestName));
    }
  };

  const getFilteredExperts = () => {
    return experts
      ?.filter((d) => {
        if (interestsSelected.length === 0) {
          return true;
        } else {
          return interestsSelected.every((v) => d.interest.some((interest) => interest.name === v));
        }
      })
      .filter((d) => {
        if (searchText.trim() === '') {
          return true;
        }
        return d.name.toLowerCase().indexOf(searchText.toLowerCase().trim()) !== -1;
      })
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  };  

  return (
    <>
      <section className="indicators-intro pad-small">
        <div className="container-fluid">
          <div className="row pb-5">
            <div className="col-12">
              <div className="large-small-header-combo">
                <h2>{getTranslation({ key: 'experts', language })}</h2>
              </div>
              {language === 'nl' ? (
                <p className="font-serif-l">
                  Deze pagina omvat alle experten in het Leuvense voedselsysteem, elk met hun eigen expertisedomein. Ben
                  je zelf een expert in het Leuvense voedselsysteem en zou je graag in de onderstaande lijst weergegeven
                  worden? Voeg dan{' '}
                  <LinkScrollTop className="hover-white u" to={`/sign-up`}>
                    hier
                  </LinkScrollTop>{' '}
                  je profiel toe.
                </p>
              ) : (
                <p className="font-serif-l">
                  This page includes all experts in the Leuven food system, each with their own field of expertise. Are
                  you an expert in the Leuven food system yourself and would you like to be displayed in the list below?
                  Add your profile{' '}
                  <span className="hover-white u" to={`/sign-up`}>
                    here
                  </span>
                  .{' '}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>

      <div id="content-overlay-box">
        <InterestOverlay
          interestsStats={interestsStats}
          interestsSelected={interestsSelected}
          updateFilters={updateFilters}
        />

        <div id="content">
          <section className="stakeholders-cards pad-small">
            <div className="container-fluid pad-small-b">
              <div className="row">
                <div className="col-12">
                  {searchIsOpen ? (
                    <div className="title-and-search open">
                      <div className="input-wrapper mr-2">
                        <img src={searchIcon} className="mr-2" alt="Search Icon" />
                        &nbsp;
                        <input
                          type="text"
                          placeholder={getTranslation({
                            key: 'expert_name',
                            language,
                          })}
                          className="font-strong-ll"
                          onChange={(e) => {
                            updateFilters('search', e.target.value);
                          }}
                          autoFocus
                        />
                      </div>
                      <span
                        onClick={() => {
                          updateFilters(null);
                        }}
                      >
                        <span className="font-strong-ll cursor-pointer">&times;</span>
                      </span>
                    </div>
                  ) : interestsSelected.length === 0 ? (
                    <div className="title-and-search">
                      <h2 className="mb-0">{getTranslation({ key: 'all_experts', language })}</h2>
                      <span className="open-search-link cursor-pointer" onClick={() => setSearchIsOpen(true)}>
                        <img src={searchIcon} alt="Search Icon" />
                        <span className="d-none d-md-inline ml-2">
                          &nbsp;{getTranslation({ key: 'search', language })}
                        </span>
                      </span>
                    </div>
                  ) : (
                    <div className="title-and-search">
                      <h2 className="mb-2 pb-1">
                        <span onClick={() => updateFilters(null)}>
                          {getTranslation({
                            key: 'back_to_all_experts',
                            language,
                          })}
                        </span>
                        <span className="hover-purple ml-2" onClick={() => updateFilters(null)}></span>
                      </h2>
                      <span className="open-search-link" onClick={() => setSearchIsOpen(true)}>
                        <img src={searchIcon} alt="Search Icon" />
                        <span className="d-none d-md-inline ml-2">
                          &nbsp;{getTranslation({ key: 'search', language })}
                        </span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                {getFilteredExperts()?.map((d, i) => {
                  return <ExpertCard d={d} key={`expert-${i}`} />;
                })}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Experts;
