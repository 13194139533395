import { queryOptions } from '@tanstack/react-query';
import axios from '../axios';
import { transformEntity, transformIndicator, transformIndicatorData, transformSingleIndicator } from '../utils';

export const fetchIndicators = async () => {
  const response = await axios.get(`/statistics?populate=*`);
  return transformIndicatorData(response.data);
};

export const indicatorsQueryOptions = queryOptions({
  queryKey: ['indicators'],
  queryFn: fetchIndicators,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
});

export const fetchIndicatorsForHomePage = async () => {
  const response = await axios.get(`/indicators?populate=*`);
  return transformIndicator(response.data);
};

export const indicatorsForHomePageQueryOptions = queryOptions({
  queryKey: ['indicatorsForHomePage'],
  queryFn: fetchIndicatorsForHomePage,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
});

export const fetchRandomSentenceIndicator = async (userId) => {
  const response = (await axios.get(`/statistics?filters[name_nl][$containsi]=${userId}`)).data;

  const experts = response.data.map((expert) => {
    return transformEntity(expert);
  });
  return experts;
};

export const fetchIndicator = async (id) => {
  const response = await axios.get(`/statistics/${id}?populate=*`);
  return transformSingleIndicator(response.data);
};

export const updateIndicator = async ({ id, data }) => {
  return await axios.put(`/statistics/${id}`, {
    data,
  });
};

export const getIndicatorWithGroup = ({ id, indicators }) => {
  const indicator = indicators?.filter((indicator) => indicator.id === id)[0];

  let group = [];
  if (indicator && indicator.group_id) {
    group = getGroupedIndicators({
      id: indicator.id,
      groupId: indicator.group_id,
      indicators,
    });
  }
  return { indicator, group };
};

export const getGroupedIndicators = ({ id, groupId, indicators }) => {
  return indicators
    ?.filter((v) => {
      return v.group_id === groupId;
    })
    .sort((v) => {
      return v.id === id ? -1 : 1;
    });
};

export const createDataset = async ({ dataset }) => {
  return await axios.post('/datasets', {
    data: {
      name: dataset.unit,
      amount: dataset.data,
      year: dataset.year,
    },
  });
};
export const updateDataset = async ({ dataset }) => {
  return await axios.put(`/datasets/${dataset.id}`, {
    data: {
      name: dataset.unit,
      amount: dataset.data,
      year: dataset.year,
    },
  });
};

export const deleteDataset = async ({ dataset }) => {
  return await axios.delete(`/datasets/${dataset.id}`);
};

export const connectDatasetToIndicator = async ({ indicatorId, dataset }) => {
  return await axios.put(`/statistics/${indicatorId}`, {
    data: {
      dataset: {
        connect: [dataset.id],
      },
    },
  });
};

export const disconnectDatasetToIndicator = async ({ indicatorId, dataset }) => {
  return await axios.put(`/statistics/${indicatorId}`, {
    data: {
      dataset: {
        disconnect: [dataset.id],
      },
    },
  });
};
