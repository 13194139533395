const SvgNodesNl = ({ handleClick }) => {
  return (
    <g onClick={handleClick} id="nodes">
      <g onClick={handleClick} id="100">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M405.039,2120.2271c0,25.269-20.484,45.75-45.75,45.75c-25.269,0-45.75-20.481-45.75-45.75
			c0-25.2661,20.481-45.75,45.75-45.75C384.555,2074.4771,405.039,2094.9609,405.039,2120.2271"
        />
        <g>
          <path
            d="M339.4478,2117.2539l-2.6636-7.1875v7.1875h-1.0942v-8.7324h1.5928l2.6514,7.1758l2.6631-7.1758h1.5444v8.7324h-1.082
				v-7.1875l-2.6758,7.1875H339.4478z"
          />
          <path
            d="M345.5312,2115.5391c0-1.2656,0.9849-1.7871,2.4077-2.0801l1.5444-0.3164v-0.2676c0-0.7285-0.4014-1.1426-1.2524-1.1426
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0332,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0547v3.1016c0,0.4141,0.2554,0.5469,0.6812,0.4492v0.877
				c-0.9731,0.1211-1.5322-0.1094-1.666-0.7188c-0.3892,0.5-1.0947,0.8281-1.958,0.8281
				C346.4678,2117.3633,345.5312,2116.6816,345.5312,2115.5391z M349.4834,2113.9941l-1.2402,0.2676
				c-0.9731,0.1953-1.6299,0.4629-1.6299,1.2285c0,0.6191,0.4502,0.9844,1.1436,0.9844c0.936,0,1.7266-0.5703,1.7266-1.3867
				V2113.9941z"
          />
          <path
            d="M352.5986,2111.8301h-0.9854v-0.9375h0.9854v-1.8594h1.082v1.8594h1.4473v0.9375h-1.4473v3.7324
				c0,0.6699,0.3286,0.791,0.8877,0.791c0.2559,0,0.438-0.0117,0.6934-0.0723v0.9355c-0.2798,0.0605-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.582V2111.8301z"
          />
          <path
            d="M356.126,2114.0801c0-1.8984,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3398
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C357.3423,2117.3867,356.126,2116.0371,356.126,2114.0801z M357.2329,2113.4102
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C358.1206,2111.707,357.4272,2112.3281,357.2329,2113.4102z"
          />
          <path d="M367.3291,2117.2539l-2.3833-6.3613h1.1553l1.7876,5.0234l1.7637-5.0234h1.1431l-2.3838,6.3613H367.3291z" />
          <path
            d="M371.3687,2115.5391c0-1.2656,0.9849-1.7871,2.4077-2.0801l1.5444-0.3164v-0.2676c0-0.7285-0.4014-1.1426-1.2524-1.1426
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0332,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0547v3.1016c0,0.4141,0.2554,0.5469,0.6812,0.4492v0.877
				c-0.9731,0.1211-1.5322-0.1094-1.666-0.7188c-0.3892,0.5-1.0947,0.8281-1.958,0.8281
				C372.3052,2117.3633,371.3687,2116.6816,371.3687,2115.5391z M375.3208,2113.9941l-1.2402,0.2676
				c-0.9731,0.1953-1.6299,0.4629-1.6299,1.2285c0,0.6191,0.4502,0.9844,1.1436,0.9844c0.936,0,1.7266-0.5703,1.7266-1.3867
				V2113.9941z"
          />
          <path
            d="M378.2656,2110.8926h1.0947v0.8633c0.3647-0.5215,0.9971-0.9844,1.897-0.9844c1.3501,0,2.1162,0.9355,2.1162,2.2871
				v4.1953h-1.0947v-3.9043c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7949h-1.0947V2110.8926z"
          />
          <path d="M335.4644,2131.2539l-2.3833-6.3613h1.1553l1.7876,5.0234l1.7632-5.0234h1.1436l-2.3838,6.3613H335.4644z" />
          <path
            d="M339.5645,2128.0801c0-1.8984,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3398
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C340.7808,2131.3867,339.5645,2130.0371,339.5645,2128.0801z M340.6714,2127.4102
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C341.5591,2125.707,340.8657,2126.3281,340.6714,2127.4102z"
          />
          <path
            d="M349.8672,2125.9746c-0.1826-0.0352-0.3286-0.0469-0.5352-0.0469c-0.9243,0-1.666,0.7285-1.666,1.8477v3.4785h-1.0947
				v-6.3613h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0137,0.438,0.0254V2125.9746z"
          />
          <path d="M352.7017,2131.2539l-2.3833-6.3613h1.1553l1.7876,5.0234l1.7632-5.0234h1.1436l-2.3838,6.3613H352.7017z" />
          <path
            d="M362.2866,2131.2539h-1.0942v-0.877c-0.3652,0.5352-0.9854,1.0098-1.8853,1.0098c-1.3618,0-2.1284-0.9355-2.1284-2.2852
				v-4.209h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5933-0.5957,1.5933-1.6523v-3.832h1.0942V2131.2539z"
          />
          <path d="M364.0518,2122.5215h1.1919v1.2656h-1.1919V2122.5215z M365.1953,2131.2539h-1.0947v-6.3613h1.0947V2131.2539z" />
          <path d="M368.1147,2131.2539H367.02v-8.7324h1.0947V2131.2539z" />
          <path d="M369.8906,2122.5215h1.1919v1.2656h-1.1919V2122.5215z M371.0342,2131.2539h-1.0947v-6.3613h1.0947V2131.2539z" />
          <path
            d="M372.8589,2124.8926h1.0947v0.8633c0.3647-0.5215,0.9971-0.9844,1.897-0.9844c1.3496,0,2.1162,0.9355,2.1162,2.2871
				v4.1953h-1.0947v-3.9043c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7949h-1.0947V2124.8926z"
          />
          <path
            d="M382.1519,2133.5762c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8965v-0.7188c-0.3408,0.6211-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1738c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.4375,1.9458,1.0586v-0.9375
				h1.0942v5.7891C385.1069,2132.543,383.8789,2133.5762,382.1519,2133.5762z M382.2612,2125.7324
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2129,1.8604,2.2129c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C384.0249,2126.4141,383.1733,2125.7324,382.2612,2125.7324z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="99">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M2027.2111,2229.2229c0,25.269-20.4811,45.75-45.75,45.75c-25.2661,0-45.75-20.481-45.75-45.75
			c0-25.2659,20.4839-45.75,45.75-45.75C2006.73,2183.4729,2027.2111,2203.957,2027.2111,2229.2229"
        />
        <g>
          <path
            d="M1959.3882,2221.9316l4.0615-4.4141h1.3984l-3.5386,3.8057l3.709,4.9258h-1.374l-3.1133-4.0859l-1.1431,1.2285v2.8574
				h-1.1675v-8.7314h1.1675V2221.9316z"
          />
          <path
            d="M1968.1465,2226.3828c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1971.1016,2225.0449,1969.8853,2226.3828,1968.1465,2226.3828z M1968.1465,2225.4463
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1966.2979,2224.5225,1967.0273,2225.4463,1968.1465,2225.4463z"
          />
          <path
            d="M1972.2705,2224.2793c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L1972.2705,2224.2793z"
          />
          <path
            d="M1977.8423,2220.8252h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2559,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V2220.8252z"
          />
          <path d="M1985.2739,2226.249l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1985.2739z" />
          <path
            d="M1991.9648,2226.3828c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1994.9199,2225.0449,1993.7036,2226.3828,1991.9648,2226.3828z M1991.9648,2225.4463
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1990.1162,2224.5225,1990.8457,2225.4463,1991.9648,2225.4463z"
          />
          <path
            d="M1998.4604,2226.3828c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C2001.4155,2225.0449,2000.1992,2226.3828,1998.4604,2226.3828z M1998.4604,2225.4463
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1996.6118,2224.5225,1997.3413,2225.4463,1998.4604,2225.4463z"
          />
          <path
            d="M2005.7349,2220.9707c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2220.9707z"
          />
          <path
            d="M1938.6118,2238.5342c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1939.5483,2240.3584,1938.6118,2239.6777,1938.6118,2238.5342z M1942.564,2236.9902l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2236.9902z"
          />
          <path
            d="M1947.917,2232.3809c-0.1826-0.0244-0.292-0.0244-0.4746-0.0244c-0.6445,0-0.9971,0.2793-0.9971,1.0947v0.4375h1.4106
				v0.9365h-1.4106v5.4238h-1.0947v-5.4238h-0.9604v-0.9365h0.9604v-0.5107c0-1.2402,0.6812-1.9941,1.9824-1.9941
				c0.2188,0,0.3403,0.0117,0.584,0.0244V2232.3809z"
          />
          <path d="M1950.1426,2240.249l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1950.1426z" />
          <path
            d="M1953.8174,2238.5342c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1954.7539,2240.3584,1953.8174,2239.6777,1953.8174,2238.5342z M1957.7695,2236.9902l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2236.9902z"
          />
          <path d="M1961.4438,2240.249h-1.0947v-8.7314h1.0947V2240.249z" />
          <path d="M1964.5332,2240.249l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1964.5332z" />
          <path
            d="M1968.2686,2237.0752c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1969.4849,2240.3828,1968.2686,2239.0332,1968.2686,2237.0752z
				 M1969.3755,2236.4062h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1970.2632,2234.7031,1969.5698,2235.3232,1969.3755,2236.4062z"
          />
          <path
            d="M1978.2061,2234.9707c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2234.9707z"
          />
          <path
            d="M1982.9985,2233.8887l1.3135,4.7432l1.3257-4.7432h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H1982.9985z"
          />
          <path d="M1987.3906,2231.5176h1.1919v1.2646h-1.1919V2231.5176z M1988.5342,2240.249h-1.0947v-6.3604h1.0947V2240.249z" />
          <path
            d="M1989.9941,2233.8887h1.0947v6.8828c0,1.1318-0.7056,1.7031-1.7881,1.7031c-0.2554,0-0.4136-0.0127-0.6079-0.0361
				v-0.9492c0.1338,0.0244,0.2432,0.0371,0.4258,0.0371c0.6323,0,0.8755-0.3047,0.8755-0.9492V2233.8887z M1989.9453,2231.5176
				h1.1919v1.2646h-1.1919V2231.5176z"
          />
          <path
            d="M1992.1108,2237.0752c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1993.1206,2240.3828,1992.1108,2238.875,1992.1108,2237.0752z
				 M1995.0781,2234.7275c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1996.8784,2235.3721,1995.9785,2234.7275,1995.0781,2234.7275z"
          />
          <path
            d="M1998.959,2237.0752c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2000.1753,2240.3828,1998.959,2239.0332,1998.959,2237.0752z M2000.0659,2236.4062
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C2000.9536,2234.7031,2000.2603,2235.3232,2000.0659,2236.4062z"
          />
          <path
            d="M2008.897,2234.9707c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2234.9707z"
          />
          <path d="M2009.688,2231.5176h1.1919v1.2646h-1.1919V2231.5176z M2010.8315,2240.249h-1.0947v-6.3604h1.0947V2240.249z" />
          <path
            d="M2012.2915,2233.8887h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2233.8887z"
          />
          <path
            d="M2021.2197,2242.5723c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3408,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C2024.1748,2241.5381,2022.9468,2242.5723,2021.2197,2242.5723z M2021.3291,2234.7275
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C2023.0928,2235.4092,2022.2412,2234.7275,2021.3291,2234.7275z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="98">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1399.2111,1670.2889c0,25.2661-20.484,45.75-45.75,45.75c-25.27,0-45.75-20.4839-45.75-45.75
			c0-25.2689,20.48-45.75,45.75-45.75C1378.7271,1624.5389,1399.2111,1645.02,1399.2111,1670.2889"
        />
        <g>
          <path
            d="M1315.9702,1651.584h3.2227c1.8486,0,2.8335,0.8145,2.8335,2.2734c0,1.1191-0.6812,1.6787-1.3501,1.9102
				c0.9854,0.2676,1.6421,1.0332,1.6421,2.1396c0,1.5205-1.1069,2.4082-2.8701,2.4082h-3.478V1651.584z M1319.0952,1655.3291
				c1.168,0,1.8125-0.4502,1.8125-1.374c0-0.9365-0.6445-1.3867-1.8125-1.3867h-1.9941v2.7607H1319.0952z M1317.1011,1659.3301
				h2.2983c1.1675,0,1.7998-0.6074,1.7998-1.5078c0-0.8877-0.6323-1.4961-1.7998-1.4961h-2.2983V1659.3301z"
          />
          <path
            d="M1322.8916,1657.1416c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1324.1079,1660.4492,1322.8916,1659.0996,1322.8916,1657.1416z
				 M1323.9985,1656.4727h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1324.8862,1654.7695,1324.1929,1655.3896,1323.9985,1656.4727z"
          />
          <path
            d="M1329.7036,1654.8916h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2559,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V1654.8916z"
          />
          <path
            d="M1332.8057,1658.6006c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1333.7422,1660.4248,1332.8057,1659.7441,1332.8057,1658.6006z M1336.7578,1657.0566l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1657.0566z"
          />
          <path
            d="M1338.8389,1658.6006c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1339.7754,1660.4248,1338.8389,1659.7441,1338.8389,1658.6006z M1342.791,1657.0566l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1657.0566z"
          />
          <path d="M1346.4658,1660.3154h-1.0947v-8.7314h1.0947V1660.3154z" />
          <path
            d="M1349.0205,1659.3916v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C1350.1025,1660.4492,1349.3975,1660.0234,1349.0205,1659.3916z M1348.9961,1657.3721c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8608-0.9854,1.8608-2.3467c0-1.374-0.6689-2.3477-1.8608-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V1657.3721z"
          />
          <path
            d="M1354.2876,1658.6006c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1355.2241,1660.4248,1354.2876,1659.7441,1354.2876,1658.6006z M1358.2397,1657.0566l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1657.0566z"
          />
          <path
            d="M1360.3213,1658.6006c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1361.2578,1660.4248,1360.3213,1659.7441,1360.3213,1658.6006z M1364.2734,1657.0566l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1657.0566z"
          />
          <path
            d="M1370.1489,1655.0371c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V1655.0371z"
          />
          <path
            d="M1370.9893,1651.584h1.0947v3.2344c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1651.584z"
          />
          <path
            d="M1377.0474,1657.1416c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1378.2637,1660.4492,1377.0474,1659.0996,1377.0474,1657.1416z
				 M1378.1543,1656.4727h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1379.042,1654.7695,1378.3486,1655.3896,1378.1543,1656.4727z"
          />
          <path d="M1383.6401,1651.584h1.1919v1.2646h-1.1919V1651.584z M1384.7837,1660.3154h-1.0947v-6.3604h1.0947V1660.3154z" />
          <path
            d="M1385.8057,1657.1416c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1386.8154,1660.4492,1385.8057,1658.9414,1385.8057,1657.1416z
				 M1388.7729,1654.7939c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1390.5732,1655.4385,1389.6733,1654.7939,1388.7729,1654.7939z"
          />
          <path d="M1346.9702,1674.3154l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1346.9702z" />
          <path
            d="M1350.645,1672.6006c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1351.5815,1674.4248,1350.645,1673.7441,1350.645,1672.6006z M1354.5972,1671.0566l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1671.0566z"
          />
          <path
            d="M1357.1768,1667.9551h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1667.9551z"
          />
          <path d="M1315.1001,1688.3154h-1.0947v-8.7314h1.0947V1688.3154z" />
          <path
            d="M1316.0615,1686.6006c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1316.998,1688.4248,1316.0615,1687.7441,1316.0615,1686.6006z M1320.0137,1685.0566l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1685.0566z"
          />
          <path
            d="M1322.5933,1681.9551h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1681.9551z"
          />
          <path
            d="M1328.6514,1685.1416c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1329.6611,1688.4492,1328.6514,1686.9414,1328.6514,1685.1416z
				 M1331.6187,1682.7939c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1333.4189,1683.4385,1332.519,1682.7939,1331.6187,1682.7939z"
          />
          <path
            d="M1337.0322,1687.3916v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C1338.1143,1688.4492,1337.4092,1688.0234,1337.0322,1687.3916z M1337.0078,1685.3721c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8608-0.9854,1.8608-2.3467c0-1.374-0.6689-2.3477-1.8608-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V1685.3721z"
          />
          <path
            d="M1345.3159,1688.4492c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1348.271,1687.1113,1347.0547,1688.4492,1345.3159,1688.4492z M1345.3159,1687.5127
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1343.4673,1686.5889,1344.1968,1687.5127,1345.3159,1687.5127z"
          />
          <path
            d="M1354.3413,1688.3154h-1.0947v-0.876c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V1688.3154z"
          />
          <path
            d="M1359.7427,1681.9551l1.313,4.7432l1.3257-4.7432h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H1359.7427z"
          />
          <path
            d="M1366.6157,1690.6387c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3403,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.6055,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C1369.5708,1689.6045,1368.3428,1690.6387,1366.6157,1690.6387z M1366.7251,1682.7939
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C1368.4888,1683.4756,1367.6372,1682.7939,1366.7251,1682.7939z"
          />
          <path
            d="M1374.3276,1683.0371c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V1683.0371z"
          />
          <path
            d="M1377.6855,1688.4492c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1380.6406,1687.1113,1379.4243,1688.4492,1377.6855,1688.4492z M1377.6855,1687.5127
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1375.8369,1686.5889,1376.5664,1687.5127,1377.6855,1687.5127z"
          />
          <path
            d="M1381.6641,1681.9551h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1681.9551z"
          />
          <path
            d="M1387.7217,1685.1416c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1388.7314,1688.4492,1387.7217,1686.9414,1387.7217,1685.1416z
				 M1390.689,1682.7939c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1392.4893,1683.4385,1391.5894,1682.7939,1390.689,1682.7939z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="97">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1279.09,1886.6021c0,25.2649-20.481,45.75-45.75,45.75c-25.266,0-45.75-20.4851-45.75-45.75
			c0-25.27,20.484-45.75,45.75-45.75C1258.609,1840.8521,1279.09,1861.332,1279.09,1886.6021"
        />
        <g>
          <path d="M1205.2329,1876.6289l-3.3203-8.7314h1.2529l2.6997,7.2725l2.6997-7.2725h1.1919l-3.2959,8.7314H1205.2329z" />
          <path
            d="M1209.8071,1873.4551c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1211.0234,1876.7627,1209.8071,1875.4131,1209.8071,1873.4551z
				 M1210.9141,1872.7861h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1211.8018,1871.083,1211.1084,1871.7031,1210.9141,1872.7861z"
          />
          <path
            d="M1219.4775,1871.3506c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V1871.3506z"
          />
          <path
            d="M1220.1958,1874.6592c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9243-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L1220.1958,1874.6592z"
          />
          <path
            d="M1226.4243,1875.7051v3.1133h-1.0947v-8.5498h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C1227.5063,1876.7627,1226.8013,1876.3369,1226.4243,1875.7051z M1226.3999,1873.6855c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6685-2.3477-1.8604-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V1873.6855z"
          />
          <path
            d="M1235.2183,1871.3506c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V1871.3506z"
          />
          <path
            d="M1235.353,1873.4551c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1236.5693,1876.7627,1235.353,1875.4131,1235.353,1873.4551z M1236.46,1872.7861
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1237.3477,1871.083,1236.6543,1871.7031,1236.46,1872.7861z"
          />
          <path d="M1241.6782,1867.8975h1.1919v1.2646h-1.1919V1867.8975z M1242.8218,1876.6289h-1.0947v-6.3604h1.0947V1876.6289z" />
          <path
            d="M1243.5762,1873.4551c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1244.5859,1876.7627,1243.5762,1875.2549,1243.5762,1873.4551z
				 M1246.5435,1871.1074c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1248.3438,1871.752,1247.4438,1871.1074,1246.5435,1871.1074z"
          />
          <path d="M1250.5459,1867.8975h1.1919v1.2646h-1.1919V1867.8975z M1251.6895,1876.6289h-1.0947v-6.3604h1.0947V1876.6289z" />
          <path
            d="M1252.8818,1870.2686h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1870.2686z"
          />
          <path
            d="M1261.542,1878.9521c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3408,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C1264.4971,1877.918,1263.269,1878.9521,1261.542,1878.9521z M1261.6514,1871.1074
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C1263.415,1871.7891,1262.5635,1871.1074,1261.6514,1871.1074z"
          />
          <path d="M1198.9316,1890.6289l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1198.9316z" />
          <path
            d="M1202.3384,1888.9141c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1203.2749,1890.7383,1202.3384,1890.0576,1202.3384,1888.9141z M1206.2905,1887.3701l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1887.3701z"
          />
          <path
            d="M1208.603,1884.2686h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1884.2686z"
          />
          <path
            d="M1217.8604,1889.7051v3.1133h-1.0947v-8.5498h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C1218.9424,1890.7627,1218.2373,1890.3369,1217.8604,1889.7051z M1217.8359,1887.6855c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8608-0.9854,1.8608-2.3467c0-1.374-0.6689-2.3477-1.8608-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V1887.6855z"
          />
          <path
            d="M1222.8599,1888.9141c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1223.7964,1890.7383,1222.8599,1890.0576,1222.8599,1888.9141z M1226.812,1887.3701l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1887.3701z"
          />
          <path
            d="M1232.4204,1885.3506c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V1885.3506z"
          />
          <path
            d="M1233.1631,1885.2051h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2554,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V1885.2051z"
          />
          <path d="M1236.4473,1881.8975h1.1919v1.2646h-1.1919V1881.8975z M1237.5908,1890.6289h-1.0947v-6.3604h1.0947V1890.6289z" />
          <path
            d="M1241.3486,1889.8018c0.8755,0,1.3984-0.5596,1.5811-1.3496l0.9731,0.4502c-0.3042,1.0576-1.2285,1.8604-2.5542,1.8604
				c-1.7754,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2285-3.3086,3.0039-3.3086c1.3257,0,2.2256,0.7666,2.5298,1.8242
				l-0.9487,0.4746c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8994-1.9092,2.3594
				C1239.4395,1888.9023,1240.2178,1889.8018,1241.3486,1889.8018z"
          />
          <path
            d="M1249.5229,1890.6289h-1.0947v-0.876c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V1890.6289z"
          />
          <path d="M1251.7988,1890.6289h-1.0947v-8.7314h1.0947V1890.6289z" />
          <path d="M1252.9424,1881.8975h1.1919v1.2646h-1.1919V1881.8975z M1254.0859,1890.6289h-1.0947v-6.3604h1.0947V1890.6289z" />
          <path
            d="M1254.8398,1887.4551c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1256.0562,1890.7627,1254.8398,1889.4131,1254.8398,1887.4551z
				 M1255.9468,1886.7861h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1256.8345,1885.083,1256.1411,1885.7031,1255.9468,1886.7861z"
          />
          <path
            d="M1264.5103,1885.3506c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V1885.3506z"
          />
          <path
            d="M1264.6445,1887.4551c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1265.8608,1890.7627,1264.6445,1889.4131,1264.6445,1887.4551z
				 M1265.7515,1886.7861h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1266.6392,1885.083,1265.9458,1885.7031,1265.7515,1886.7861z"
          />
          <path d="M1193.4395,1904.6289l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1193.4395z" />
          <path
            d="M1199.8623,1904.7627c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1202.8174,1903.4248,1201.6011,1904.7627,1199.8623,1904.7627z M1199.8623,1903.8262
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1198.0137,1902.9023,1198.7432,1903.8262,1199.8623,1903.8262z"
          />
          <path
            d="M1203.1353,1901.4551c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1204.3516,1904.7627,1203.1353,1903.4131,1203.1353,1901.4551z
				 M1204.2422,1900.7861h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1205.1299,1899.083,1204.4365,1899.7031,1204.2422,1900.7861z"
          />
          <path
            d="M1209.0713,1901.4551c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1210.0811,1904.7627,1209.0713,1903.2549,1209.0713,1901.4551z
				 M1212.0386,1899.1074c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1213.8389,1899.752,1212.939,1899.1074,1212.0386,1899.1074z"
          />
          <path
            d="M1216.2363,1902.6592c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L1216.2363,1902.6592z"
          />
          <path
            d="M1220.9316,1901.4551c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1222.1479,1904.7627,1220.9316,1903.4131,1220.9316,1901.4551z
				 M1222.0386,1900.7861h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1222.9263,1899.083,1222.2329,1899.7031,1222.0386,1900.7861z"
          />
          <path d="M1228.4009,1904.6289h-1.0947v-8.7314h1.0947V1904.6289z" />
          <path
            d="M1230.6875,1903.7051v3.1133h-1.0947v-8.5498h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C1231.7695,1904.7627,1231.0645,1904.3369,1230.6875,1903.7051z M1230.6631,1901.6855c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8608-0.9854,1.8608-2.3467c0-1.374-0.6689-2.3477-1.8608-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V1901.6855z"
          />
          <path
            d="M1239.4819,1899.3506c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V1899.3506z"
          />
          <path
            d="M1242.5718,1904.7627c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1245.5269,1903.4248,1244.3105,1904.7627,1242.5718,1904.7627z M1242.5718,1903.8262
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1240.7231,1902.9023,1241.4526,1903.8262,1242.5718,1903.8262z"
          />
          <path
            d="M1245.8447,1901.4551c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1246.8545,1904.7627,1245.8447,1903.2549,1245.8447,1901.4551z
				 M1248.812,1899.1074c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1250.6123,1899.752,1249.7124,1899.1074,1248.812,1899.1074z"
          />
          <path
            d="M1257.9102,1904.6289h-1.0942v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8853,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5933-0.5957,1.5933-1.6533v-3.8311h1.0942V1904.6289z"
          />
          <path
            d="M1261.6577,1903.8018c0.8755,0,1.3984-0.5596,1.5811-1.3496l0.9727,0.4502c-0.3037,1.0576-1.228,1.8604-2.5537,1.8604
				c-1.7754,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2285-3.3086,3.0039-3.3086c1.3257,0,2.2256,0.7666,2.5298,1.8242
				l-0.9487,0.4746c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8994-1.9092,2.3594
				C1259.7485,1902.9023,1260.5269,1903.8018,1261.6577,1903.8018z"
          />
          <path
            d="M1264.9551,1899.2051h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2554,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V1899.2051z"
          />
          <path d="M1268.2393,1895.8975h1.1919v1.2646h-1.1919V1895.8975z M1269.3828,1904.6289h-1.0947v-6.3604h1.0947V1904.6289z" />
          <path
            d="M1270.1367,1901.4551c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1271.353,1904.7627,1270.1367,1903.4131,1270.1367,1901.4551z M1271.2437,1900.7861
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1272.1313,1899.083,1271.438,1899.7031,1271.2437,1900.7861z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="96">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1280.965,1442.7729c0,25.27-20.481,45.75-45.75,45.75c-25.266,0-45.75-20.48-45.75-45.75
			c0-25.2649,20.484-45.75,45.75-45.75C1260.484,1397.0229,1280.965,1417.5081,1280.965,1442.7729"
        />
        <g>
          <path
            d="M1225.9019,1424.498c-0.3525,0.8027-1.2891,1.4355-2.5415,1.4355c-2.2256,0-3.8794-1.8242-3.8794-4.4756
				c0-2.6631,1.666-4.5244,3.9521-4.5244c1.6782,0,2.8701,0.9121,3.3931,2.4209l-1.1187,0.3643
				c-0.3164-1.1064-1.1191-1.751-2.2622-1.751c-1.6538,0-2.7969,1.4102-2.7969,3.4785c0,2.0547,1.1431,3.4775,2.8091,3.4775
				c1.4106,0,2.3594-1.082,2.3833-2.6387h-2.5049v-1.0215h3.6362v4.5361h-1.0703V1424.498z"
          />
          <path
            d="M1231.6323,1420.5215c-0.1826-0.0361-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0117,0.438,0.0244V1420.5215z"
          />
          <path
            d="M1234.9897,1425.9336c-1.7393,0-2.9556-1.3379-2.9556-3.3086c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1237.9448,1424.5957,1236.7285,1425.9336,1234.9897,1425.9336z M1234.9897,1424.9971
				c1.1187,0,1.8481-0.9248,1.8481-2.3721c0-1.459-0.7295-2.3828-1.8481-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C1233.1411,1424.0723,1233.8706,1424.9971,1234.9897,1424.9971z"
          />
          <path
            d="M1238.9683,1419.4395h1.0947v0.8633c0.3647-0.5234,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1963h-1.0947v-3.9043c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6084-1.5928,1.666v3.7949h-1.0947V1419.4395z"
          />
          <path
            d="M1245.0259,1422.625c0-1.8115,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7324
				h-1.0942v-0.9248c-0.377,0.6328-1.0825,1.0586-1.9702,1.0586C1246.0356,1425.9336,1245.0259,1424.4258,1245.0259,1422.625z
				 M1247.9932,1420.2783c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3477,1.8604,2.3477
				c0.9004,0,1.8003-0.6572,1.8003-2.1162v-0.4502C1249.7935,1420.9229,1248.8936,1420.2783,1247.9932,1420.2783z"
          />
          <path
            d="M1205.8135,1438.875v0.9248h-1.0947v-8.7324h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3076c0,1.8008-1.0093,3.3086-2.7729,3.3086
				C1206.8955,1439.9336,1206.1904,1439.5078,1205.8135,1438.875z M1205.7891,1436.8564c0,1.459,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8604-0.9854,1.8604-2.3477c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1436.8564z"
          />
          <path
            d="M1211.1416,1436.625c0-1.8965,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1885,1.9336,2.1885c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1212.3579,1439.9336,1211.1416,1438.583,1211.1416,1436.625z M1212.2485,1435.957
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1213.1362,1434.2539,1212.4429,1434.874,1212.2485,1435.957z"
          />
          <path
            d="M1217.9292,1437.8291c0.3403,0.7422,1.1187,1.1924,1.9458,1.1924c0.7417,0,1.3984-0.3164,1.3984-1.0098
				s-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4629-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6318-0.9238-1.0332-1.7266-1.0332
				c-0.73,0-1.2163,0.3643-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4844L1217.9292,1437.8291z"
          />
          <path
            d="M1225.8965,1438.9727c0.8755,0,1.3984-0.5596,1.5811-1.3496l0.9727,0.4492c-0.3037,1.0586-1.228,1.8613-2.5537,1.8613
				c-1.7754,0-3.0039-1.3506-3.0039-3.3086c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7666,2.5298,1.8242
				l-0.9487,0.4746c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8994-1.9092,2.3584
				C1223.9873,1438.0723,1224.7656,1438.9727,1225.8965,1438.9727z"
          />
          <path
            d="M1229.291,1431.0674h1.0947v3.2354c0.3647-0.5234,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1963h-1.0947v-3.9043c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6084-1.5928,1.666v3.7949h-1.0947V1431.0674z"
          />
          <path d="M1235.7383,1431.0674h1.1919v1.2656h-1.1919V1431.0674z M1236.8818,1439.7998h-1.0947v-6.3604h1.0947V1439.7998z" />
          <path
            d="M1239.4365,1436.4062l2.8213-2.9668h1.3618l-2.481,2.5049l2.688,3.8555h-1.2769l-2.1895-3.0645l-0.9238,0.9355v2.1289
				h-1.0947v-8.7324h1.0947V1436.4062z"
          />
          <path
            d="M1245.5674,1438.875v0.9248h-1.0947v-8.7324h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3076c0,1.8008-1.0093,3.3086-2.7729,3.3086
				C1246.6494,1439.9336,1245.9443,1439.5078,1245.5674,1438.875z M1245.543,1436.8564c0,1.459,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8604-0.9854,1.8604-2.3477c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1436.8564z"
          />
          <path
            d="M1250.835,1438.085c0-1.2646,0.9849-1.7881,2.4077-2.0801l1.5444-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0332,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1006c0,0.4141,0.2554,0.5479,0.6812,0.4502v0.876
				c-0.9731,0.1211-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1251.7715,1439.9092,1250.835,1439.2275,1250.835,1438.085z M1254.7871,1436.54l-1.2402,0.2676
				c-0.9731,0.1953-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5723,1.7266-1.3867V1436.54
				z"
          />
          <path
            d="M1256.8682,1438.085c0-1.2646,0.9849-1.7881,2.4077-2.0801l1.5444-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0332,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1006c0,0.4141,0.2554,0.5479,0.6812,0.4502v0.876
				c-0.9731,0.1211-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1257.8047,1439.9092,1256.8682,1439.2275,1256.8682,1438.085z M1260.8203,1436.54l-1.2402,0.2676
				c-0.9731,0.1953-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5723,1.7266-1.3867V1436.54
				z"
          />
          <path
            d="M1266.6963,1434.5215c-0.1826-0.0361-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0117,0.438,0.0244V1434.5215z"
          />
          <path d="M1199.1406,1453.7998l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1199.1406z" />
          <path
            d="M1205.8315,1453.9336c-1.7393,0-2.9556-1.3379-2.9556-3.3086c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1208.7866,1452.5957,1207.5703,1453.9336,1205.8315,1453.9336z M1205.8315,1452.9971
				c1.1187,0,1.8481-0.9248,1.8481-2.3721c0-1.459-0.7295-2.3828-1.8481-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C1203.9829,1452.0723,1204.7124,1452.9971,1205.8315,1452.9971z"
          />
          <path
            d="M1212.3271,1453.9336c-1.7393,0-2.9556-1.3379-2.9556-3.3086c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1215.2822,1452.5957,1214.0659,1453.9336,1212.3271,1453.9336z M1212.3271,1452.9971
				c1.1187,0,1.8481-0.9248,1.8481-2.3721c0-1.459-0.7295-2.3828-1.8481-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C1210.4785,1452.0723,1211.208,1452.9971,1212.3271,1452.9971z"
          />
          <path
            d="M1219.6016,1448.5215c-0.1826-0.0361-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0117,0.438,0.0244V1448.5215z"
          />
          <path
            d="M1222.2056,1450.625c0-1.8115,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7324
				h-1.0942v-0.9248c-0.377,0.6328-1.0825,1.0586-1.9702,1.0586C1223.2153,1453.9336,1222.2056,1452.4258,1222.2056,1450.625z
				 M1225.1729,1448.2783c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3477,1.8604,2.3477
				c0.9004,0,1.8003-0.6572,1.8003-2.1162v-0.4502C1226.9731,1448.9229,1226.0732,1448.2783,1225.1729,1448.2783z"
          />
          <path
            d="M1234.5391,1453.7998h-1.0947v-0.876c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8994,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6543v-3.8301h1.0947V1453.7998z"
          />
          <path
            d="M1241.0347,1453.7998h-1.0942v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8853,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8994,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5933-0.5957,1.5933-1.6543v-3.8301h1.0942V1453.7998z"
          />
          <path
            d="M1245.7798,1448.5215c-0.1826-0.0361-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0117,0.438,0.0244V1448.5215z"
          />
          <path d="M1251.2168,1453.7998h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8389l-3.5996,4.585h3.6362V1453.7998z" />
          <path
            d="M1251.7778,1452.085c0-1.2646,0.9849-1.7881,2.4077-2.0801l1.5444-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0332,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1006c0,0.4141,0.2554,0.5479,0.6812,0.4502v0.876
				c-0.9731,0.1211-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1252.7144,1453.9092,1251.7778,1453.2275,1251.7778,1452.085z M1255.73,1450.54l-1.2402,0.2676
				c-0.9731,0.1953-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5723,1.7266-1.3867V1450.54
				z"
          />
          <path
            d="M1259.4048,1453.7998h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5234,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0337-1.083,2.0308-1.083c1.3135,0,2.104,0.9365,2.104,2.2861v4.1963
				h-1.0947v-3.9043c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6543v3.8066h-1.0947v-3.9043
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6543V1453.7998z"
          />
          <path
            d="M1268.1021,1450.625c0-1.8965,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1885,1.9336,2.1885c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1269.3184,1453.9336,1268.1021,1452.583,1268.1021,1450.625z M1269.209,1449.957
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1270.0967,1448.2539,1269.4033,1448.874,1269.209,1449.957z"
          />
          <path d="M1196.0024,1467.7998l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1196.0024z" />
          <path
            d="M1202.3281,1467.9336c-1.7393,0-2.9556-1.3379-2.9556-3.3086c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1205.2832,1466.5957,1204.0669,1467.9336,1202.3281,1467.9336z M1202.3281,1466.9971
				c1.1187,0,1.8481-0.9248,1.8481-2.3721c0-1.459-0.7295-2.3828-1.8481-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C1200.4795,1466.0723,1201.209,1466.9971,1202.3281,1466.9971z"
          />
          <path
            d="M1205.5034,1464.625c0-1.8965,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1885,1.9336,2.1885c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1206.7197,1467.9336,1205.5034,1466.583,1205.5034,1464.625z M1206.6104,1463.957
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1207.498,1462.2539,1206.8047,1462.874,1206.6104,1463.957z"
          />
          <path
            d="M1211.3428,1464.625c0-1.8115,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7324
				h-1.0942v-0.9248c-0.377,0.6328-1.0825,1.0586-1.9702,1.0586C1212.3525,1467.9336,1211.3428,1466.4258,1211.3428,1464.625z
				 M1214.3101,1462.2783c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3477,1.8604,2.3477
				c0.9004,0,1.8003-0.6572,1.8003-2.1162v-0.4502C1216.1104,1462.9229,1215.2104,1462.2783,1214.3101,1462.2783z"
          />
          <path
            d="M1218.4102,1465.8291c0.3403,0.7422,1.1187,1.1924,1.9458,1.1924c0.7417,0,1.3984-0.3164,1.3984-1.0098
				s-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4629-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6318-0.9243-1.0332-1.7266-1.0332
				c-0.73,0-1.2163,0.3643-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4844L1218.4102,1465.8291z"
          />
          <path
            d="M1223.0083,1464.625c0-1.8965,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1885,1.9336,2.1885c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1224.2246,1467.9336,1223.0083,1466.583,1223.0083,1464.625z M1224.1152,1463.957
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1225.0029,1462.2539,1224.3096,1462.874,1224.1152,1463.957z"
          />
          <path d="M1230.3799,1467.7998h-1.0947v-8.7324h1.0947V1467.7998z" />
          <path
            d="M1232.5693,1466.875v3.1133h-1.0947v-8.5488h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3076c0,1.8008-1.0093,3.3086-2.7729,3.3086
				C1233.6514,1467.9336,1232.9463,1467.5078,1232.5693,1466.875z M1232.5449,1464.8564c0,1.459,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8604-0.9854,1.8604-2.3477c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1464.8564z"
          />
          <path
            d="M1241.2666,1462.5215c-0.1826-0.0361-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0117,0.438,0.0244V1462.5215z"
          />
          <path
            d="M1244.2593,1467.9336c-1.7393,0-2.9556-1.3379-2.9556-3.3086c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1247.2144,1466.5957,1245.998,1467.9336,1244.2593,1467.9336z M1244.2593,1466.9971
				c1.1187,0,1.8481-0.9248,1.8481-2.3721c0-1.459-0.7295-2.3828-1.8481-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C1242.4106,1466.0723,1243.1401,1466.9971,1244.2593,1466.9971z"
          />
          <path
            d="M1247.4346,1464.625c0-1.8115,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7324
				h-1.0942v-0.9248c-0.377,0.6328-1.0825,1.0586-1.9702,1.0586C1248.4443,1467.9336,1247.4346,1466.4258,1247.4346,1464.625z
				 M1250.4019,1462.2783c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3477,1.8604,2.3477
				c0.9004,0,1.8003-0.6572,1.8003-2.1162v-0.4502C1252.2021,1462.9229,1251.3022,1462.2783,1250.4019,1462.2783z"
          />
          <path
            d="M1259.4028,1467.7998h-1.0942v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8853,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8994,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5933-0.5957,1.5933-1.6543v-3.8301h1.0942V1467.7998z"
          />
          <path
            d="M1263.0527,1466.9727c0.8755,0,1.3984-0.5596,1.5811-1.3496l0.9727,0.4492c-0.3037,1.0586-1.228,1.8613-2.5537,1.8613
				c-1.7754,0-3.0039-1.3506-3.0039-3.3086c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7666,2.5298,1.8242
				l-0.9487,0.4746c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8994-1.9092,2.3584
				C1261.1436,1466.0723,1261.9219,1466.9727,1263.0527,1466.9727z"
          />
          <path
            d="M1266.2534,1462.376h-0.9854v-0.9365h0.9854v-1.8613h1.082v1.8613h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2554,0,0.438-0.0117,0.6934-0.0732v0.9365c-0.2798,0.0615-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1462.376z"
          />
          <path d="M1269.4399,1459.0674h1.1919v1.2656h-1.1919V1459.0674z M1270.5835,1467.7998h-1.0947v-6.3604h1.0947V1467.7998z" />
          <path
            d="M1271.2402,1464.625c0-1.8965,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1885,1.9336,2.1885c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1272.4565,1467.9336,1271.2402,1466.583,1271.2402,1464.625z M1272.3472,1463.957
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1273.2349,1462.2539,1272.5415,1462.874,1272.3472,1463.957z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="95">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M2109.3049,1570.75c0,25.27-20.4849,45.75-45.75,45.75c-25.2699,0-45.7499-20.48-45.7499-45.75
			c0-25.266,20.48-45.75,45.7499-45.75C2088.8201,1525,2109.3049,1545.484,2109.3049,1570.75"
        />
        <g>
          <path d="M2039.1406,1553.7764v-8.7314h5.6426v1.0215h-4.4873v2.8086h3.7939v1.0215h-3.7939v3.8799H2039.1406z" />
          <path d="M2045.7461,1545.0449h1.1919v1.2646h-1.1919V1545.0449z M2046.8896,1553.7764h-1.0947v-6.3604h1.0947V1553.7764z" />
          <path
            d="M2048.3491,1547.416h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1547.416z"
          />
          <path
            d="M2054.3467,1552.0615c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C2055.2832,1553.8857,2054.3467,1553.2051,2054.3467,1552.0615z M2058.2988,1550.5176l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1550.5176z"
          />
          <path
            d="M2060.8784,1547.416h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1547.416z"
          />
          <path
            d="M2069.9404,1552.9492c0.8755,0,1.3984-0.5596,1.5811-1.3496l0.9731,0.4502c-0.3042,1.0576-1.2285,1.8604-2.5542,1.8604
				c-1.7754,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2285-3.3086,3.0039-3.3086c1.3257,0,2.2256,0.7666,2.5298,1.8242
				l-0.9487,0.4746c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8994-1.9092,2.3594
				C2068.0312,1552.0498,2068.8096,1552.9492,2069.9404,1552.9492z"
          />
          <path d="M2073.2866,1545.0449h1.1919v1.2646h-1.1919V1545.0449z M2074.4302,1553.7764h-1.0947v-6.3604h1.0947V1553.7764z" />
          <path
            d="M2076.4609,1551.0283c0.0239,1.4473,0.9482,2.043,2.0186,2.043c0.7661,0,1.2285-0.1338,1.6294-0.3047l0.1826,0.7666
				c-0.377,0.1699-1.0215,0.3652-1.958,0.3652c-1.812,0-2.8945-1.1924-2.8945-2.9678s1.0459-3.1738,2.7607-3.1738
				c1.9214,0,2.4321,1.6904,2.4321,2.7725c0,0.2188-0.0239,0.3896-0.0361,0.499H2076.4609z M2079.5981,1550.2617
				c0.0122-0.6807-0.2793-1.7393-1.4834-1.7393c-1.0825,0-1.5566,0.9971-1.6421,1.7393H2079.5981z M2076.4971,1546.2246
				c0-0.3408,0.2798-0.6201,0.6206-0.6201c0.3403,0,0.5957,0.2666,0.5957,0.6201c0,0.3281-0.2432,0.6201-0.5957,0.6201
				C2076.7524,1546.8447,2076.4971,1546.5527,2076.4971,1546.2246z M2078.7593,1546.2246c0-0.3408,0.2676-0.6201,0.6079-0.6201
				c0.3408,0,0.5962,0.2666,0.5962,0.6201c0,0.3281-0.2437,0.6201-0.5962,0.6201
				C2079.0024,1546.8447,2078.7593,1546.5527,2078.7593,1546.2246z"
          />
          <path d="M2082.7134,1553.7764h-1.0947v-8.7314h1.0947V1553.7764z" />
          <path
            d="M2083.7354,1550.6025c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2084.9517,1553.9102,2083.7354,1552.5605,2083.7354,1550.6025z
				 M2084.8423,1549.9336h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C2085.73,1548.2305,2085.0366,1548.8506,2084.8423,1549.9336z"
          />
          <path
            d="M2041.8594,1567.7764h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0337-1.083,2.0308-1.083c1.3135,0,2.104,0.9365,2.104,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V1567.7764z"
          />
          <path d="M2050.9463,1559.0449h1.1919v1.2646h-1.1919V1559.0449z M2052.0898,1567.7764h-1.0947v-6.3604h1.0947V1567.7764z" />
          <path
            d="M2053.1113,1564.6025c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C2054.1211,1567.9102,2053.1113,1566.4023,2053.1113,1564.6025z
				 M2056.0786,1562.2549c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C2057.8789,1562.8994,2056.979,1562.2549,2056.0786,1562.2549z"
          />
          <path
            d="M2059.96,1564.6025c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C2060.9697,1567.9102,2059.96,1566.4023,2059.96,1564.6025z
				 M2062.9272,1562.2549c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C2064.7275,1562.8994,2063.8276,1562.2549,2062.9272,1562.2549z"
          />
          <path
            d="M2066.8081,1564.6025c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2068.0244,1567.9102,2066.8081,1566.5605,2066.8081,1564.6025z M2067.915,1563.9336
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C2068.8027,1562.2305,2068.1094,1562.8506,2067.915,1563.9336z"
          />
          <path d="M2074.5449,1567.7764h-1.0947v-8.7314h1.0947V1567.7764z" />
          <path
            d="M2075.5669,1564.6025c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2076.7832,1567.9102,2075.5669,1566.5605,2075.5669,1564.6025z
				 M2076.6738,1563.9336h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C2077.5615,1562.2305,2076.8682,1562.8506,2076.6738,1563.9336z"
          />
          <path
            d="M2082.2085,1561.416h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1561.416z"
          />
          <path
            d="M2021.8911,1580.0615c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C2022.8276,1581.8857,2021.8911,1581.2051,2021.8911,1580.0615z M2025.8433,1578.5176l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1578.5176z"
          />
          <path
            d="M2027.9248,1580.0615c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C2028.8613,1581.8857,2027.9248,1581.2051,2027.9248,1580.0615z M2031.877,1578.5176l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1578.5176z"
          />
          <path
            d="M2034.4565,1575.416h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1575.416z"
          />
          <path
            d="M2043.3848,1584.0996c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3408,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C2046.3398,1583.0654,2045.1118,1584.0996,2043.3848,1584.0996z M2043.4941,1576.2549
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C2045.2578,1576.9365,2044.4062,1576.2549,2043.4941,1576.2549z"
          />
          <path
            d="M2047.3628,1578.6025c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2048.5791,1581.9102,2047.3628,1580.5605,2047.3628,1578.6025z
				 M2048.4697,1577.9336h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C2049.3574,1576.2305,2048.6641,1576.8506,2048.4697,1577.9336z"
          />
          <path
            d="M2057.9575,1575.416l1.313,4.7432l1.3257-4.7432h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H2057.9575z"
          />
          <path
            d="M2061.9604,1578.6025c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2063.1768,1581.9102,2061.9604,1580.5605,2061.9604,1578.6025z
				 M2063.0674,1577.9336h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C2063.9551,1576.2305,2063.2617,1576.8506,2063.0674,1577.9336z"
          />
          <path
            d="M2068.6021,1575.416h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1575.416z"
          />
          <path
            d="M2074.6602,1578.6025c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C2075.6699,1581.9102,2074.6602,1580.4023,2074.6602,1578.6025z
				 M2077.6274,1576.2549c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C2079.4277,1576.8994,2078.5278,1576.2549,2077.6274,1576.2549z"
          />
          <path d="M2085.7778,1581.7764l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H2085.7778z" />
          <path
            d="M2092.4688,1581.9102c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C2095.4238,1580.5723,2094.2075,1581.9102,2092.4688,1581.9102z M2092.4688,1580.9736
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C2090.6201,1580.0498,2091.3496,1580.9736,2092.4688,1580.9736z"
          />
          <path
            d="M2098.9644,1581.9102c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C2101.9194,1580.5723,2100.7031,1581.9102,2098.9644,1581.9102z M2098.9644,1580.9736
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C2097.1157,1580.0498,2097.8452,1580.9736,2098.9644,1580.9736z"
          />
          <path
            d="M2106.2388,1576.498c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V1576.498z"
          />
          <path
            d="M2027.2798,1592.6025c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C2028.2896,1595.9102,2027.2798,1594.4023,2027.2798,1592.6025z
				 M2030.2471,1590.2549c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C2032.0474,1590.8994,2031.1475,1590.2549,2030.2471,1590.2549z"
          />
          <path
            d="M2039.6133,1595.7764h-1.0942v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8853,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5933-0.5957,1.5933-1.6533v-3.8311h1.0942V1595.7764z"
          />
          <path
            d="M2046.1089,1595.7764h-1.0947v-0.876c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V1595.7764z"
          />
          <path
            d="M2050.854,1590.498c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V1590.498z"
          />
          <path d="M2056.291,1595.7764h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8389l-3.5996,4.585h3.6362V1595.7764z" />
          <path
            d="M2056.8521,1594.0615c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C2057.7886,1595.8857,2056.8521,1595.2051,2056.8521,1594.0615z M2060.8042,1592.5176l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1592.5176z"
          />
          <path
            d="M2062.8857,1594.0615c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C2063.8223,1595.8857,2062.8857,1595.2051,2062.8857,1594.0615z M2066.8379,1592.5176l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1592.5176z"
          />
          <path
            d="M2070.5122,1595.7764h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0337-1.083,2.0308-1.083c1.3135,0,2.104,0.9365,2.104,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V1595.7764z"
          />
          <path
            d="M2079.6479,1587.0449h1.0947v3.2344c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1587.0449z"
          />
          <path
            d="M2085.7056,1592.6025c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2086.9219,1595.9102,2085.7056,1594.5605,2085.7056,1592.6025z
				 M2086.8125,1591.9336h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C2087.7002,1590.2305,2087.0068,1590.8506,2086.8125,1591.9336z"
          />
          <path d="M2092.2988,1587.0449h1.1919v1.2646h-1.1919V1587.0449z M2093.4424,1595.7764h-1.0947v-6.3604h1.0947V1595.7764z" />
          <path
            d="M2094.4644,1592.6025c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C2095.4741,1595.9102,2094.4644,1594.4023,2094.4644,1592.6025z
				 M2097.4316,1590.2549c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C2099.2319,1590.8994,2098.332,1590.2549,2097.4316,1590.2549z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="94">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2276.5391,794.8052c0,25.2649-20.4841,45.75-45.75,45.75c-25.269,0-45.75-20.4851-45.75-45.75
			c0-25.2661,20.481-45.75,45.75-45.75C2256.0549,749.0552,2276.5391,769.5391,2276.5391,794.8052"
        />
        <g>
          <path d="M2189.4961,784.8315l-3.3203-8.7319h1.2529l2.7002,7.2725l2.6992-7.2725h1.1924l-3.2959,8.7319H2189.4961z" />
          <path
            d="M2197.3428,779.5537c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V779.5537z"
          />
          <path
            d="M2196.9541,783.1167c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2197.8906,784.9409,2196.9541,784.2598,2196.9541,783.1167z M2200.9072,781.5723l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V781.5723z"
          />
          <path
            d="M2202.2578,783.1167c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2203.1943,784.9409,2202.2578,784.2598,2202.2578,783.1167z M2206.2109,781.5723l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V781.5723z"
          />
          <path
            d="M2210.4932,787.1543c-1.3984,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6201-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C2213.4482,786.1206,2212.2197,787.1543,2210.4932,787.1543z M2210.6025,779.3105
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C2212.3652,779.9912,2211.5146,779.3105,2210.6025,779.3105z"
          />
          <path
            d="M2215.6504,778.4712h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V778.4712z"
          />
          <path
            d="M2220.918,783.1167c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2221.8545,784.9409,2220.918,784.2598,2220.918,783.1167z M2224.8711,781.5723l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V781.5723z"
          />
          <path
            d="M2226.2217,783.1167c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2227.1582,784.9409,2226.2217,784.2598,2226.2217,783.1167z M2230.1748,781.5723l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V781.5723z"
          />
          <path
            d="M2235.3203,779.5537c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V779.5537z"
          />
          <path d="M2237.9971,784.8315h-1.0947v-8.7319h1.0947V784.8315z" />
          <path
            d="M2241.2441,784.9653c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2244.1992,783.6274,2242.9834,784.9653,2241.2441,784.9653z M2241.2441,784.0288
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2239.3955,783.1045,2240.125,784.0288,2241.2441,784.0288z"
          />
          <path
            d="M2245.5879,781.4385l2.8213-2.9673h1.3623l-2.4814,2.5054l2.6875,3.855h-1.2764l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V781.4385z"
          />
          <path
            d="M2249.3955,783.1167c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2250.332,784.9409,2249.3955,784.2598,2249.3955,783.1167z M2253.3486,781.5723l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V781.5723z"
          />
          <path
            d="M2254.6982,783.1167c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2255.6348,784.9409,2254.6982,784.2598,2254.6982,783.1167z M2258.6514,781.5723l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V781.5723z"
          />
          <path d="M2261.5957,784.8315h-1.0947v-8.7319h1.0947V784.8315z" />
          <path
            d="M2263.3594,781.6572c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2264.5752,784.9653,2263.3594,783.6152,2263.3594,781.6572z M2264.4658,780.9888
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2265.3535,779.2861,2264.6602,779.9062,2264.4658,780.9888z"
          />
          <path
            d="M2269.2715,778.4712h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V778.4712z"
          />
          <path
            d="M2189.2295,795.6572c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2190.4453,798.9653,2189.2295,797.6152,2189.2295,795.6572z M2190.3359,794.9888
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2191.2236,793.2861,2190.5303,793.9062,2190.3359,794.9888z"
          />
          <path d="M2195.3359,790.0996h1.1924v1.2651h-1.1924V790.0996z M2196.4795,798.8315h-1.0947v-6.3604h1.0947V798.8315z" />
          <path d="M2202.0488,798.8315h-5.0098v-0.8271l3.6719-4.5967h-3.624v-0.9365h4.9258v0.8394l-3.5996,4.5845h3.6357V798.8315z" />
          <path
            d="M2205.1396,798.9653c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2208.0947,797.6274,2206.8789,798.9653,2205.1396,798.9653z M2205.1396,798.0288
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2203.291,797.1045,2204.0205,798.0288,2205.1396,798.0288z"
          />
          <path
            d="M2208.1943,795.6572c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2209.4102,798.9653,2208.1943,797.6152,2208.1943,795.6572z M2209.3008,794.9888
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2210.1885,793.2861,2209.4951,793.9062,2209.3008,794.9888z"
          />
          <path
            d="M2214.3496,792.4712h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V792.4712z"
          />
          <path
            d="M2220.5039,796.8613c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L2220.5039,796.8613z"
          />
          <path
            d="M2227.8516,801.1543c-1.3994,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6201-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C2230.8066,800.1206,2229.5781,801.1543,2227.8516,801.1543z M2227.9609,793.3105
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C2229.7236,793.9912,2228.873,793.3105,2227.9609,793.3105z"
          />
          <path
            d="M2231.3438,795.6572c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2232.5596,798.9653,2231.3438,797.6152,2231.3438,795.6572z M2232.4502,794.9888
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2233.3379,793.2861,2232.6445,793.9062,2232.4502,794.9888z"
          />
          <path
            d="M2238.5938,797.9072v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C2239.6758,798.9653,2238.9707,798.5396,2238.5938,797.9072z M2238.5693,795.8887c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V795.8887z"
          />
          <path
            d="M2246.3906,798.9653c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2249.3457,797.6274,2248.1299,798.9653,2246.3906,798.9653z M2246.3906,798.0288
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2244.542,797.1045,2245.2715,798.0288,2246.3906,798.0288z"
          />
          <path
            d="M2249.8818,792.4712h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V792.4712z"
          />
          <path
            d="M2255.4541,795.6572c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2256.4629,798.9653,2255.4541,797.4575,2255.4541,795.6572z
				 M2258.4209,793.3105c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2260.2207,793.9551,2259.3213,793.3105,2258.4209,793.3105z"
          />
          <path
            d="M2261.8164,795.6572c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2263.0322,798.9653,2261.8164,797.6152,2261.8164,795.6572z M2262.9229,794.9888
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2263.8105,793.2861,2263.1172,793.9062,2262.9229,794.9888z"
          />
          <path
            d="M2267.9717,792.4712h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V792.4712z"
          />
          <path d="M2215.3281,812.8315l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2215.3281z" />
          <path
            d="M2221.5312,812.9653c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2224.4863,811.6274,2223.2705,812.9653,2221.5312,812.9653z M2221.5312,812.0288
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2219.6826,811.1045,2220.4121,812.0288,2221.5312,812.0288z"
          />
          <path
            d="M2224.585,809.6572c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2225.8008,812.9653,2224.585,811.6152,2224.585,809.6572z M2225.6914,808.9888
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2226.5791,807.2861,2225.8857,807.9062,2225.6914,808.9888z"
          />
          <path
            d="M2230.3027,809.6572c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2231.3115,812.9653,2230.3027,811.4575,2230.3027,809.6572z
				 M2233.2695,807.3105c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2235.0693,807.9551,2234.1699,807.3105,2233.2695,807.3105z"
          />
          <path
            d="M2237.248,810.8613c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L2237.248,810.8613z"
          />
          <path
            d="M2241.7256,809.6572c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2242.9414,812.9653,2241.7256,811.6152,2241.7256,809.6572z M2242.832,808.9888
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2243.7197,807.2861,2243.0264,807.9062,2242.832,808.9888z"
          />
          <path d="M2248.9756,812.8315h-1.0947v-8.7319h1.0947V812.8315z" />
        </g>
      </g>
      <g onClick={handleClick} id="93">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M1686.312,2230.4651c0,25.2649-20.484,45.75-45.75,45.75c-25.265,0-45.75-20.4851-45.75-45.75
			c0-25.2661,20.485-45.75,45.75-45.75C1665.828,2184.7151,1686.312,2205.199,1686.312,2230.4651"
        />
        <g>
          <path
            d="M1596.5508,2220.4922h-1.168v-8.7314h3.332c1.8486,0,3.0283,0.8633,3.0283,2.5293c0,1.6416-1.1797,2.542-3.0283,2.542
				h-2.1641V2220.4922z M1598.6543,2215.8223c1.2402,0,1.9336-0.5469,1.9336-1.5322s-0.6934-1.5205-1.9336-1.5205h-2.1035v3.0527
				H1598.6543z"
          />
          <path
            d="M1605.3447,2215.2139c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9238,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.9004-1.1309,1.7637-1.1309c0.1699,0,0.3281,0.0127,0.4375,0.0244V2215.2139z"
          />
          <path
            d="M1608.2158,2220.626c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C1611.1709,2219.2881,1609.9551,2220.626,1608.2158,2220.626z M1608.2158,2219.6895
				c1.1191,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7295-2.3838-1.8486-2.3838s-1.8486,0.9238-1.8486,2.3838
				C1606.3672,2218.7656,1607.0967,2219.6895,1608.2158,2219.6895z"
          />
          <path
            d="M1611.2695,2217.3184c0-1.8125,1.0098-3.3086,2.7734-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C1612.2793,2220.626,1611.2695,2219.1182,1611.2695,2217.3184z
				 M1614.2373,2214.9707c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C1616.0371,2215.6152,1615.1377,2214.9707,1614.2373,2214.9707z"
          />
          <path
            d="M1623.1162,2220.4922h-1.0938v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8857,1.0098c-1.3613,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3887,1.5078,1.3252,1.5078c0.791,0,1.5938-0.5957,1.5938-1.6533v-3.8311h1.0938V2220.4922z"
          />
          <path
            d="M1626.6445,2219.665c0.876,0,1.3984-0.5596,1.5811-1.3496l0.9736,0.4502c-0.3047,1.0576-1.2285,1.8604-2.5547,1.8604
				c-1.7754,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2285-3.3086,3.0039-3.3086c1.3262,0,2.2256,0.7666,2.5303,1.8242
				l-0.9492,0.4746c-0.1826-0.7783-0.7051-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8994-1.9092,2.3594
				C1624.7354,2218.7656,1625.5137,2219.665,1626.6445,2219.665z"
          />
          <path
            d="M1629.7236,2215.0684h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2559,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5957,0.0967-0.9609,0.0967
				c-1.082,0-1.7021-0.3643-1.7021-1.5811V2215.0684z"
          />
          <path d="M1632.7881,2211.7607h1.1924v1.2646h-1.1924V2211.7607z M1633.9316,2220.4922h-1.0947v-6.3604h1.0947V2220.4922z" />
          <path
            d="M1634.4678,2217.3184c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.459-0.4629,1.6543-1.2168l0.96,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C1635.6836,2220.626,1634.4678,2219.2764,1634.4678,2217.3184z M1635.5742,2216.6494
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6172-1.7031C1636.4619,2214.9463,1635.7686,2215.5664,1635.5742,2216.6494z"
          />
          <path
            d="M1643.1406,2220.626c-1.7393,0-2.9561-1.3379-2.9561-3.3076c0-1.9707,1.2168-3.3086,2.9561-3.3086
				c1.7383,0,2.9551,1.3379,2.9551,3.3086C1646.0957,2219.2881,1644.8789,2220.626,1643.1406,2220.626z M1643.1406,2219.6895
				c1.1182,0,1.8477-0.9238,1.8477-2.3711c0-1.46-0.7295-2.3838-1.8477-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1641.292,2218.7656,1642.0215,2219.6895,1643.1406,2219.6895z"
          />
          <path d="M1648.2617,2220.4922l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7637-5.0225h1.1426l-2.3838,6.3604H1648.2617z" />
          <path
            d="M1651.5098,2217.3184c0-1.8975,1.2041-3.3086,2.9316-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5977
				c0.0615,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.46-0.4629,1.6543-1.2168l0.9609,0.3408
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C1652.7266,2220.626,1651.5098,2219.2764,1651.5098,2217.3184z M1652.6172,2216.6494
				h3.4287c-0.0234-0.9004-0.5107-1.7031-1.6172-1.7031C1653.5049,2214.9463,1652.8115,2215.5664,1652.6172,2216.6494z"
          />
          <path
            d="M1660.9609,2215.2139c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9238,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.9004-1.1309,1.7637-1.1309c0.1699,0,0.3281,0.0127,0.4375,0.0244V2215.2139z"
          />
          <path
            d="M1661.4609,2218.5225c0.3398,0.7412,1.1182,1.1914,1.9453,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6074-0.79-1.6533-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1436,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0938,0.2559,2.3467,0.4746,2.3467,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L1661.4609,2218.5225z"
          />
          <path
            d="M1668.9414,2219.665c0.875,0,1.3984-0.5596,1.5811-1.3496l0.9727,0.4502c-0.3037,1.0576-1.2285,1.8604-2.5537,1.8604
				c-1.7754,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2285-3.3086,3.0039-3.3086c1.3252,0,2.2256,0.7666,2.5293,1.8242
				l-0.9482,0.4746c-0.1826-0.7783-0.7061-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8994-1.9092,2.3594
				C1667.0322,2218.7656,1667.8105,2219.665,1668.9414,2219.665z"
          />
          <path
            d="M1671.8496,2211.7607h1.0947v3.2344c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2211.7607z"
          />
          <path
            d="M1680.376,2220.626c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C1683.3311,2219.2881,1682.1152,2220.626,1680.376,2220.626z M1680.376,2219.6895
				c1.1191,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7295-2.3838-1.8486-2.3838s-1.8486,0.9238-1.8486,2.3838
				C1678.5273,2218.7656,1679.2568,2219.6895,1680.376,2219.6895z"
          />
          <path
            d="M1684.0381,2215.0684h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3291,0.79,0.8877,0.79c0.2559,0,0.4385-0.0117,0.6934-0.0723v0.9365c-0.2793,0.0605-0.5957,0.0967-0.96,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V2215.0684z"
          />
          <path
            d="M1612.248,2236.8154c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2061,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8604-0.5723,1.8604-1.8975v-0.7178c-0.3398,0.6201-1.0576,1.0586-1.9453,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.875,0,1.6055,0.4385,1.9453,1.0586v-0.9365
				h1.0947v5.7891C1615.2031,2235.7812,1613.9746,2236.8154,1612.248,2236.8154z M1612.3574,2228.9707
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C1614.1211,2229.6523,1613.2695,2228.9707,1612.3574,2228.9707z"
          />
          <path
            d="M1615.7393,2231.3184c0-1.8975,1.2041-3.3086,2.9316-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5977
				c0.0615,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.46-0.4629,1.6543-1.2168l0.9609,0.3408
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C1616.9561,2234.626,1615.7393,2233.2764,1615.7393,2231.3184z M1616.8467,2230.6494
				h3.4287c-0.0234-0.9004-0.5107-1.7031-1.6172-1.7031C1617.7344,2228.9463,1617.041,2229.5664,1616.8467,2230.6494z"
          />
          <path
            d="M1622.9893,2233.5684v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7734,1.4961,2.7734,3.3086c0,1.7998-1.0098,3.3076-2.7734,3.3076
				C1624.0713,2234.626,1623.3662,2234.2002,1622.9893,2233.5684z M1622.9648,2231.5488c0,1.46,0.9004,2.1162,1.7998,2.1162
				c1.1924,0,1.8613-0.9854,1.8613-2.3467c0-1.374-0.6689-2.3477-1.8613-2.3477c-0.8994,0-1.7998,0.6445-1.7998,2.1289V2231.5488z"
          />
          <path
            d="M1631.5645,2229.2139c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9238,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.9004-1.1309,1.7637-1.1309c0.1699,0,0.3281,0.0127,0.4375,0.0244V2229.2139z"
          />
          <path
            d="M1636.9648,2234.4922h-1.0938v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8857,1.0098c-1.3613,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3887,1.5078,1.3252,1.5078c0.791,0,1.5938-0.5957,1.5938-1.6533v-3.8311h1.0938V2234.4922z"
          />
          <path d="M1637.8789,2225.7607h1.1914v1.2646h-1.1914V2225.7607z M1639.0225,2234.4922h-1.0947v-6.3604h1.0947V2234.4922z" />
          <path
            d="M1641.0898,2231.0996l2.8213-2.9678h1.3623l-2.4814,2.5049l2.6885,3.8555h-1.2773l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V2231.0996z"
          />
          <path
            d="M1645.8105,2229.0684h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2559,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5957,0.0967-0.9609,0.0967
				c-1.082,0-1.7021-0.3643-1.7021-1.5811V2229.0684z"
          />
          <path
            d="M1650.2021,2231.3184c0-1.8125,1.0098-3.3086,2.7725-3.3086c0.8877,0,1.5938,0.4258,1.9707,1.0459v-3.2949h1.0938v8.7314
				h-1.0938v-0.9238c-0.377,0.6318-1.083,1.0576-1.9707,1.0576C1651.2119,2234.626,1650.2021,2233.1182,1650.2021,2231.3184z
				 M1653.1689,2228.9707c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8008-0.6562,1.8008-2.1162v-0.4492C1654.9697,2229.6152,1654.0693,2228.9707,1653.1689,2228.9707z"
          />
          <path
            d="M1659.5195,2234.626c-1.7393,0-2.9561-1.3379-2.9561-3.3076c0-1.9707,1.2168-3.3086,2.9561-3.3086
				c1.7383,0,2.9551,1.3379,2.9551,3.3086C1662.4746,2233.2881,1661.2578,2234.626,1659.5195,2234.626z M1659.5195,2233.6895
				c1.1182,0,1.8477-0.9238,1.8477-2.3711c0-1.46-0.7295-2.3838-1.8477-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1657.6709,2232.7656,1658.4004,2233.6895,1659.5195,2233.6895z"
          />
          <path
            d="M1665.5283,2234.626c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C1668.4834,2233.2881,1667.2676,2234.626,1665.5283,2234.626z M1665.5283,2233.6895
				c1.1191,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7295-2.3838-1.8486-2.3838s-1.8486,0.9238-1.8486,2.3838
				C1663.6797,2232.7656,1664.4092,2233.6895,1665.5283,2233.6895z"
          />
          <path
            d="M1672.3164,2229.2139c-0.1826-0.0361-0.3291-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3281,0.0127,0.4385,0.0244V2229.2139z"
          />
          <path d="M1607.0967,2248.4922l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7637-5.0225h1.1426l-2.3838,6.3604H1607.0967z" />
          <path
            d="M1613.3008,2248.626c-1.7393,0-2.9561-1.3379-2.9561-3.3076c0-1.9707,1.2168-3.3086,2.9561-3.3086
				c1.7383,0,2.9551,1.3379,2.9551,3.3086C1616.2559,2247.2881,1615.0391,2248.626,1613.3008,2248.626z M1613.3008,2247.6895
				c1.1182,0,1.8477-0.9238,1.8477-2.3711c0-1.46-0.7295-2.3838-1.8477-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1611.4521,2246.7656,1612.1816,2247.6895,1613.3008,2247.6895z"
          />
          <path
            d="M1616.3545,2245.3184c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.459-0.4629,1.6543-1.2168l0.96,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C1617.5703,2248.626,1616.3545,2247.2764,1616.3545,2245.3184z M1617.4609,2244.6494
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6172-1.7031C1618.3486,2242.9463,1617.6553,2243.5664,1617.4609,2244.6494z"
          />
          <path
            d="M1622.0713,2245.3184c0-1.8125,1.0098-3.3086,2.7734-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C1623.0811,2248.626,1622.0713,2247.1182,1622.0713,2245.3184z
				 M1625.0391,2242.9707c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C1626.8389,2243.6152,1625.9395,2242.9707,1625.0391,2242.9707z"
          />
          <path
            d="M1629.0176,2246.5225c0.3398,0.7412,1.1182,1.1914,1.9453,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6074-0.79-1.6533-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1436,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0938,0.2559,2.3467,0.4746,2.3467,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L1629.0176,2246.5225z"
          />
          <path
            d="M1633.4941,2245.3184c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.459-0.4629,1.6543-1.2168l0.96,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C1634.71,2248.626,1633.4941,2247.2764,1633.4941,2245.3184z M1634.6006,2244.6494
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6172-1.7031C1635.4883,2242.9463,1634.7949,2243.5664,1634.6006,2244.6494z"
          />
          <path d="M1640.7441,2248.4922h-1.0947v-8.7314h1.0947V2248.4922z" />
          <path
            d="M1642.8115,2247.5684v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7734,1.4961,2.7734,3.3086c0,1.7998-1.0098,3.3076-2.7734,3.3076
				C1643.8936,2248.626,1643.1885,2248.2002,1642.8115,2247.5684z M1642.7871,2245.5488c0,1.46,0.9004,2.1162,1.7998,2.1162
				c1.1924,0,1.8613-0.9854,1.8613-2.3467c0-1.374-0.6689-2.3477-1.8613-2.3477c-0.8994,0-1.7998,0.6445-1.7998,2.1289V2245.5488z"
          />
          <path
            d="M1647.5928,2246.7773c0-1.2646,0.9854-1.7881,2.4082-2.0791l1.5439-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.252-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0342-0.2676c0.3047-1.0342,1.2412-1.7764,2.542-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2549,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0947,0.8271-1.958,0.8271
				C1648.5293,2248.6016,1647.5928,2247.9209,1647.5928,2246.7773z M1651.5449,2245.2334l-1.2402,0.2676
				c-0.9727,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.9365,0,1.7266-0.5713,1.7266-1.3867
				V2245.2334z"
          />
          <path
            d="M1653.6387,2242.1318h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2242.1318z"
          />
          <path
            d="M1660.7422,2245.0996l2.8213-2.9678h1.3623l-2.4814,2.5049l2.6885,3.8555h-1.2773l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V2245.0996z"
          />
          <path
            d="M1664.8545,2245.3184c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.459-0.4629,1.6543-1.2168l0.96,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C1666.0703,2248.626,1664.8545,2247.2764,1664.8545,2245.3184z M1665.9609,2244.6494
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6172-1.7031C1666.8486,2242.9463,1666.1553,2243.5664,1665.9609,2244.6494z"
          />
          <path
            d="M1671.0098,2242.1318h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2242.1318z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="92">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M4189.5391,886.406c0,25.266-20.4839,45.75-45.75,45.75c-25.269,0-45.75-20.484-45.75-45.75
			c0-25.2688,20.481-45.75,45.75-45.75C4169.0552,840.656,4189.5391,861.1372,4189.5391,886.406"
        />
        <g>
          <path
            d="M4101.6094,867.7002h2.7715c2.6523,0,4.3301,1.7026,4.3301,4.3662c0,2.6631-1.6777,4.3657-4.3301,4.3657h-2.7715
				V867.7002z M4104.3809,875.4229c1.9824,0,3.1875-1.3257,3.1875-3.3564c0-2.0435-1.2051-3.3564-3.1992-3.3564h-1.6172v6.7129
				H4104.3809z"
          />
          <path
            d="M4112.2402,876.5659c-1.7402,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2148-3.3076,2.9551-3.3076
				c1.7383,0,2.9551,1.3379,2.9551,3.3076C4115.1953,875.228,4113.9785,876.5659,4112.2402,876.5659z M4112.2402,875.6294
				c1.1172,0,1.8477-0.9243,1.8477-2.3716c0-1.459-0.7305-2.3833-1.8477-2.3833c-1.1191,0-1.8496,0.9243-1.8496,2.3833
				C4110.3906,874.7051,4111.1211,875.6294,4112.2402,875.6294z"
          />
          <path
            d="M4115.7812,873.2578c0-1.897,1.2031-3.3076,2.9297-3.3076c1.7754,0,2.7129,1.3501,2.7129,3.1133v0.3647h-4.5977
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C4116.9961,876.5659,4115.7812,875.2158,4115.7812,873.2578z M4116.8867,872.5894
				h3.4297c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026C4117.7754,870.8867,4117.082,871.5068,4116.8867,872.5894z"
          />
          <path d="M4123.5176,876.4321h-1.0957v-8.7319h1.0957V876.4321z" />
          <path
            d="M4125.1465,871.0083h-0.9844v-0.9365h0.9844v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8887,0.7905c0.2539,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2812,0.061-0.5977,0.0977-0.9609,0.0977
				c-1.084,0-1.7031-0.3652-1.7031-1.5811V871.0083z"
          />
          <path
            d="M4132.043,871.1543c-0.1816-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9238,0-1.666,0.7295-1.666,1.8486v3.478h-1.0938
				v-6.3604h1.0938v1.0825c0.2793-0.6445,0.9004-1.1313,1.7637-1.1313c0.1699,0,0.3281,0.0122,0.4375,0.0244V871.1543z"
          />
          <path
            d="M4132.4453,873.2578c0-1.897,1.2051-3.3076,2.9316-3.3076c1.7754,0,2.7109,1.3501,2.7109,3.1133v0.3647h-4.5957
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8496,0,1.459-0.4619,1.6523-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C4133.6621,876.5659,4132.4453,875.2158,4132.4453,873.2578z M4133.5527,872.5894
				h3.4297c-0.0254-0.8999-0.5117-1.7026-1.6191-1.7026C4134.4395,870.8867,4133.7461,871.5068,4133.5527,872.5894z"
          />
          <path
            d="M4141.8594,868.564c-0.1816-0.0244-0.291-0.0244-0.4727-0.0244c-0.6445,0-0.998,0.2798-0.998,1.0942v0.438h1.4102v0.9365
				h-1.4102v5.4238h-1.0938v-5.4238h-0.9609v-0.9365h0.9609v-0.5107c0-1.2402,0.6797-1.9946,1.9824-1.9946
				c0.2188,0,0.3398,0.0122,0.582,0.0244V868.564z"
          />
          <path
            d="M4145.2285,868.564c-0.1816-0.0244-0.291-0.0244-0.4727-0.0244c-0.6445,0-0.998,0.2798-0.998,1.0942v0.438h1.4102v0.9365
				h-1.4102v5.4238h-1.0938v-5.4238h-0.9609v-0.9365h0.9609v-0.5107c0-1.2402,0.6797-1.9946,1.9824-1.9946
				c0.2188,0,0.3398,0.0122,0.582,0.0244V868.564z"
          />
          <path
            d="M4145.3887,873.2578c0-1.897,1.2051-3.3076,2.9316-3.3076c1.7754,0,2.7109,1.3501,2.7109,3.1133v0.3647h-4.5957
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8496,0,1.459-0.4619,1.6523-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C4146.6055,876.5659,4145.3887,875.2158,4145.3887,873.2578z M4146.4961,872.5894
				h3.4297c-0.0254-0.8999-0.5117-1.7026-1.6191-1.7026C4147.3828,870.8867,4146.6895,871.5068,4146.4961,872.5894z"
          />
          <path
            d="M4152.0312,870.0718h1.0938v0.8633c0.3652-0.5229,0.998-0.9849,1.8965-0.9849c1.3516,0,2.1172,0.9365,2.1172,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6079-1.5938,1.666v3.7944h-1.0938V870.0718z"
          />
          <path
            d="M4158.0898,873.2578c0-1.812,1.0078-3.3076,2.7715-3.3076c0.8887,0,1.5938,0.4258,1.9707,1.0459v-3.2959h1.0938v8.7319
				h-1.0938v-0.9243c-0.377,0.6323-1.082,1.0581-1.9707,1.0581C4159.0977,876.5659,4158.0898,875.0581,4158.0898,873.2578z
				 M4161.0566,870.9111c-1.1914,0-1.8613,0.9727-1.8613,2.3467c0,1.3623,0.6699,2.3472,1.8613,2.3472
				c0.9004,0,1.7988-0.6567,1.7988-2.1157v-0.4502C4162.8555,871.5557,4161.957,870.9111,4161.0566,870.9111z"
          />
          <path
            d="M4165.377,867.7002h1.0938v3.2349c0.3652-0.5229,0.998-0.9849,1.8965-0.9849c1.3516,0,2.1172,0.9365,2.1172,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6079-1.5938,1.666v3.7944h-1.0938V867.7002z"
          />
          <path
            d="M4171.4336,873.2578c0-1.897,1.2051-3.3076,2.9316-3.3076c1.7754,0,2.7109,1.3501,2.7109,3.1133v0.3647h-4.5957
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8496,0,1.459-0.4619,1.6523-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C4172.6504,876.5659,4171.4336,875.2158,4171.4336,873.2578z M4172.541,872.5894
				h3.4297c-0.0254-0.8999-0.5117-1.7026-1.6191-1.7026C4173.4277,870.8867,4172.7344,871.5068,4172.541,872.5894z"
          />
          <path d="M4178.0273,867.7002h1.1914v1.2651h-1.1914V867.7002z M4179.1699,876.4321h-1.0938v-6.3604h1.0938V876.4321z" />
          <path
            d="M4180.1934,873.2578c0-1.812,1.0078-3.3076,2.7715-3.3076c0.8887,0,1.5938,0.4258,1.9707,1.0459v-3.2959h1.0938v8.7319
				h-1.0938v-0.9243c-0.377,0.6323-1.082,1.0581-1.9707,1.0581C4181.2012,876.5659,4180.1934,875.0581,4180.1934,873.2578z
				 M4183.1602,870.9111c-1.1914,0-1.8613,0.9727-1.8613,2.3467c0,1.3623,0.6699,2.3472,1.8613,2.3472
				c0.9004,0,1.7988-0.6567,1.7988-2.1157v-0.4502C4184.959,871.5557,4184.0605,870.9111,4183.1602,870.9111z"
          />
          <path d="M4136.9824,890.4321l-2.3828-6.3604h1.1543l1.7891,5.0225l1.7617-5.0225h1.1445l-2.3848,6.3604H4136.9824z" />
          <path
            d="M4140.6562,888.7173c0-1.2646,0.9844-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4023-1.1431-1.2539-1.1431
				c-0.8027,0-1.2773,0.377-1.4707,1.082l-1.0332-0.2676c0.3027-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4219,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6797,0.4502v0.8755
				c-0.9727,0.1216-1.5312-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.957,0.8267
				C4141.5918,890.5415,4140.6562,889.8604,4140.6562,888.7173z M4144.6094,887.1729l-1.2422,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9375,0,1.7285-0.5718,1.7285-1.3862
				V887.1729z"
          />
          <path
            d="M4147.1895,884.0718h1.0938v0.8633c0.3652-0.5229,0.998-0.9849,1.8965-0.9849c1.3516,0,2.1172,0.9365,2.1172,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6079-1.5938,1.666v3.7944h-1.0938V884.0718z"
          />
          <path
            d="M4106.9805,901.0391l2.8203-2.9673h1.3633l-2.4824,2.5054l2.6875,3.855h-1.2754l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0957v-8.7319h1.0957V901.0391z"
          />
          <path
            d="M4115.9688,898.0718l1.3125,4.7427l1.3262-4.7427h1.0938l-1.9336,6.3604h-0.959l-1.3262-4.7188l-1.3262,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3145-4.731H4115.9688z"
          />
          <path
            d="M4119.9121,902.7173c0-1.2646,0.9844-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4023-1.1431-1.2539-1.1431
				c-0.8027,0-1.2773,0.377-1.4707,1.082l-1.0332-0.2676c0.3027-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4219,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6797,0.4502v0.8755
				c-0.9727,0.1216-1.5312-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.957,0.8267
				C4120.8477,904.5415,4119.9121,903.8604,4119.9121,902.7173z M4123.8652,901.1729l-1.2422,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9375,0,1.7285-0.5718,1.7285-1.3862
				V901.1729z"
          />
          <path d="M4127.5391,904.4321h-1.0957v-8.7319h1.0957V904.4321z" />
          <path d="M4128.9492,895.7002h1.1914v1.2651h-1.1914V895.7002z M4130.0918,904.4321h-1.0938v-6.3604h1.0938V904.4321z" />
          <path
            d="M4131.7227,899.0083h-0.9844v-0.9365h0.9844v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8887,0.7905c0.2539,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2812,0.061-0.5977,0.0977-0.9609,0.0977
				c-1.084,0-1.7031-0.3652-1.7031-1.5811V899.0083z"
          />
          <path
            d="M4134.8848,901.2578c0-1.897,1.2051-3.3076,2.9316-3.3076c1.7754,0,2.7109,1.3501,2.7109,3.1133v0.3647h-4.5957
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8496,0,1.459-0.4619,1.6523-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C4136.1016,904.5659,4134.8848,903.2158,4134.8848,901.2578z M4135.9922,900.5894
				h3.4297c-0.0254-0.8999-0.5117-1.7026-1.6191-1.7026C4136.8789,898.8867,4136.1855,899.5068,4135.9922,900.5894z"
          />
          <path d="M4141.4785,895.7002h1.1914v1.2651h-1.1914V895.7002z M4142.6211,904.4321h-1.0938v-6.3604h1.0938V904.4321z" />
          <path
            d="M4144.25,899.0083h-0.9844v-0.9365h0.9844v-1.8608h1.084v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8867,0.7905c0.2559,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2793,0.061-0.5957,0.0977-0.9609,0.0977
				c-1.082,0-1.7031-0.3652-1.7031-1.5811V899.0083z"
          />
          <path
            d="M4147.998,902.4619c0.3398,0.7417,1.1191,1.1919,1.9453,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6074-0.7905-1.6543-1.0093c-1.0449-0.2314-2.1387-0.4624-2.1387-1.7637c0-1.1309,0.9844-1.9214,2.334-1.9214
				c1.1426,0,2.0801,0.5596,2.457,1.3135l-0.7793,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7285,0-1.2148,0.3647-1.2148,0.9365c0,0.5957,0.5586,0.7051,1.4453,0.8999c1.0957,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1055,2.0186-2.4688,2.0186c-1.1543,0-2.3105-0.4985-2.7969-1.4839L4147.998,902.4619z"
          />
          <path d="M4154.4941,904.4321h-1.0957v-8.7319h1.0957V904.4321z" />
          <path
            d="M4155.4531,902.7173c0-1.2646,0.9863-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4004-1.1431-1.252-1.1431
				c-0.8027,0-1.2773,0.377-1.4727,1.082l-1.0332-0.2676c0.3047-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4238,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6816,0.4502v0.8755
				c-0.9727,0.1216-1.5332-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.959,0.8267
				C4156.3906,904.5415,4155.4531,903.8604,4155.4531,902.7173z M4159.4062,901.1729l-1.2402,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9355,0,1.7266-0.5718,1.7266-1.3862
				V901.1729z"
          />
          <path
            d="M4163.082,903.5078v0.9243h-1.0957v-8.7319h1.0957v3.2959c0.377-0.6201,1.082-1.0459,1.9688-1.0459
				c1.7637,0,2.7734,1.4956,2.7734,3.3076c0,1.8003-1.0098,3.3081-2.7734,3.3081
				C4164.1641,904.5659,4163.459,904.1401,4163.082,903.5078z M4163.0566,901.4893c0,1.459,0.9004,2.1157,1.8008,2.1157
				c1.1914,0,1.8594-0.9849,1.8594-2.3472c0-1.374-0.668-2.3467-1.8594-2.3467c-0.9004,0-1.8008,0.6445-1.8008,2.1279V901.4893z"
          />
          <path
            d="M4168.4102,901.2578c0-1.897,1.2031-3.3076,2.9297-3.3076c1.7754,0,2.7129,1.3501,2.7129,3.1133v0.3647h-4.5977
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C4169.625,904.5659,4168.4102,903.2158,4168.4102,901.2578z M4169.5156,900.5894
				h3.4297c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026C4170.4043,898.8867,4169.7109,899.5068,4169.5156,900.5894z"
          />
          <path d="M4176.1465,904.4321h-1.0957v-8.7319h1.0957V904.4321z" />
          <path
            d="M4177.752,902.4619c0.3398,0.7417,1.1191,1.1919,1.9453,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6074-0.7905-1.6543-1.0093c-1.0449-0.2314-2.1387-0.4624-2.1387-1.7637c0-1.1309,0.9844-1.9214,2.334-1.9214
				c1.1426,0,2.0801,0.5596,2.457,1.3135l-0.7793,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7285,0-1.2148,0.3647-1.2148,0.9365c0,0.5957,0.5586,0.7051,1.4453,0.8999c1.0957,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1055,2.0186-2.4688,2.0186c-1.1543,0-2.3105-0.4985-2.7969-1.4839L4177.752,902.4619z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="91">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1554.2581,1441.293c0,25.266-20.4811,45.75-45.75,45.75c-25.2661,0-45.75-20.484-45.75-45.75
			s20.4839-45.75,45.75-45.75C1533.777,1395.543,1554.2581,1416.027,1554.2581,1441.293"
        />
        <g>
          <path
            d="M1488.8491,1424.3193l-2.6636-7.1875v7.1875h-1.0942v-8.7319h1.5928l2.6514,7.1753l2.6631-7.1753h1.5444v8.7319h-1.082
				v-7.1875l-2.6758,7.1875H1488.8491z"
          />
          <path
            d="M1494.9326,1422.6045c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1495.8691,1424.4287,1494.9326,1423.7476,1494.9326,1422.6045z M1498.8848,1421.0601l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1421.0601z"
          />
          <path
            d="M1502,1418.8955h-0.9854v-0.9365H1502v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1418.8955z"
          />
          <path
            d="M1505.5273,1421.145c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1506.7437,1424.4531,1505.5273,1423.103,1505.5273,1421.145z M1506.6343,1420.4766
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1507.522,1418.7739,1506.8286,1419.394,1506.6343,1420.4766z"
          />
          <path d="M1516.7305,1424.3193l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1516.7305z" />
          <path
            d="M1520.77,1422.6045c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1521.7065,1424.4287,1520.77,1423.7476,1520.77,1422.6045z M1524.7222,1421.0601l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1421.0601z"
          />
          <path
            d="M1527.667,1417.959h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1417.959z"
          />
          <path
            d="M1470.1411,1435.145c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1471.1509,1438.4531,1470.1411,1436.9453,1470.1411,1435.145z
				 M1473.1084,1432.7983c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1474.9087,1433.4429,1474.0088,1432.7983,1473.1084,1432.7983z"
          />
          <path
            d="M1482.8394,1438.3193h-1.0942v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8853,1.0093
				c-1.3618,0-2.1284-0.9365-2.1284-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083
				c0.7905,0,1.5933-0.5962,1.5933-1.6538v-3.8311h1.0942V1438.3193z"
          />
          <path
            d="M1489.7002,1438.3193h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093
				c-1.3618,0-2.1284-0.9365-2.1284-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083
				c0.7905,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V1438.3193z"
          />
          <path
            d="M1494.8101,1433.0415c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1433.0415z"
          />
          <path d="M1500.6123,1438.3193h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8394l-3.5996,4.5845h3.6362V1438.3193z" />
          <path
            d="M1501.5381,1436.6045c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1502.4746,1438.4287,1501.5381,1437.7476,1501.5381,1436.6045z M1505.4902,1435.0601l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1435.0601z"
          />
          <path
            d="M1507.9365,1436.6045c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1508.873,1438.4287,1507.9365,1437.7476,1507.9365,1436.6045z M1511.8887,1435.0601l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1435.0601z"
          />
          <path
            d="M1515.9282,1438.3193h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1438.3193z"
          />
          <path
            d="M1525.4287,1429.5874h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1429.5874z"
          />
          <path
            d="M1531.8516,1435.145c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1533.0679,1438.4531,1531.8516,1437.103,1531.8516,1435.145z M1532.9585,1434.4766
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1533.8462,1432.7739,1533.1528,1433.394,1532.9585,1434.4766z"
          />
          <path d="M1538.8096,1429.5874h1.1919v1.2651h-1.1919V1429.5874z M1539.9531,1438.3193h-1.0947v-6.3604h1.0947V1438.3193z" />
          <path
            d="M1541.3398,1435.145c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1542.3496,1438.4531,1541.3398,1436.9453,1541.3398,1435.145z
				 M1544.3071,1432.7983c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1546.1074,1433.4429,1545.2075,1432.7983,1544.3071,1432.7983z"
          />
          <path d="M1487.4814,1443.5874h1.1919v1.2651h-1.1919V1443.5874z M1488.625,1452.3193h-1.0947v-6.3604h1.0947V1452.3193z" />
          <path
            d="M1490.4497,1445.959h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1445.959z"
          />
          <path d="M1500.9722,1452.3193h-1.0947v-8.7319h1.0947V1452.3193z" />
          <path
            d="M1505.3145,1452.4531c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1508.2695,1451.1152,1507.0532,1452.4531,1505.3145,1452.4531z M1505.3145,1451.5166
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1503.4658,1450.5923,1504.1953,1451.5166,1505.3145,1451.5166z"
          />
          <path
            d="M1510.7524,1448.9263l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V1448.9263z"
          />
          <path
            d="M1515.6553,1450.6045c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1516.5918,1452.4287,1515.6553,1451.7476,1515.6553,1450.6045z M1519.6074,1449.0601l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1449.0601z"
          />
          <path d="M1523.647,1452.3193h-1.0947v-8.7319h1.0947V1452.3193z" />
          <path
            d="M1525.0337,1449.145c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1526.25,1452.4531,1525.0337,1451.103,1525.0337,1449.145z M1526.1406,1448.4766
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1527.0283,1446.7739,1526.335,1447.394,1526.1406,1448.4766z"
          />
          <path d="M1484.3311,1466.3193h-1.0947v-8.7319h1.0947V1466.3193z" />
          <path
            d="M1485.6572,1464.6045c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1486.5938,1466.4287,1485.6572,1465.748,1485.6572,1464.6045z M1489.6094,1463.0605l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2285c0,0.6191,0.4502,0.9844,1.1436,0.9844c0.936,0,1.7266-0.5723,1.7266-1.3867
				V1463.0605z"
          />
          <path
            d="M1492.5542,1459.959h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9033c0-0.8882-0.377-1.5571-1.3257-1.5571c-0.7905,0-1.5928,0.6079-1.5928,1.6665v3.7939h-1.0947V1459.959z"
          />
          <path
            d="M1498.9771,1463.1445c0-1.8115,1.0098-3.3071,2.7729-3.3071c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9248c-0.377,0.6328-1.0825,1.0586-1.9702,1.0586C1499.9868,1466.4531,1498.9771,1464.9453,1498.9771,1463.1445z
				 M1501.9443,1460.7983c-1.1914,0-1.8604,0.9727-1.8604,2.3462c0,1.3633,0.6689,2.3477,1.8604,2.3477
				c0.9004,0,1.8003-0.6562,1.8003-2.1152v-0.4512C1503.7446,1461.4429,1502.8447,1460.7983,1501.9443,1460.7983z"
          />
          <path
            d="M1507.7231,1465.3945v0.9248h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3071c0,1.8008-1.0093,3.3086-2.7729,3.3086
				C1508.8052,1466.4531,1508.1001,1466.0273,1507.7231,1465.3945z M1507.6987,1463.377c0,1.459,0.8999,2.1152,1.7998,2.1152
				c1.1919,0,1.8608-0.9844,1.8608-2.3477c0-1.3735-0.6689-2.3462-1.8608-2.3462c-0.8999,0-1.7998,0.6445-1.7998,2.1274V1463.377z"
          />
          <path
            d="M1516.3716,1466.4531c-1.7393,0-2.9556-1.3379-2.9556-3.3086c0-1.9692,1.2163-3.3071,2.9556-3.3071
				c1.7388,0,2.9551,1.3379,2.9551,3.3071C1519.3267,1465.1152,1518.1104,1466.4531,1516.3716,1466.4531z M1516.3716,1465.5166
				c1.1187,0,1.8481-0.9248,1.8481-2.3721c0-1.4585-0.7295-2.3828-1.8481-2.3828c-1.1191,0-1.8486,0.9243-1.8486,2.3828
				C1514.5229,1464.5918,1515.2524,1465.5166,1516.3716,1465.5166z"
          />
          <path
            d="M1525.7617,1466.3193h-1.0947v-0.876c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V1466.3193z"
          />
          <path
            d="M1531.5283,1459.959l1.313,4.7422l1.3257-4.7422h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H1531.5283z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="90">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2952.469,998.5231c0,25.266-20.4851,45.7499-45.75,45.7499c-25.27,0-45.75-20.4839-45.75-45.7499
			c0-25.269,20.48-45.75,45.75-45.75C2931.9839,952.7731,2952.469,973.254,2952.469,998.5231"
        />
        <g>
          <path
            d="M2882.3652,967.5493l-2.6631-7.1875v7.1875h-1.0947v-8.7319h1.5928l2.6514,7.1753l2.6631-7.1753h1.5449v8.7319h-1.083
				v-7.1875l-2.6748,7.1875H2882.3652z"
          />
          <path
            d="M2887.7188,965.8345c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2888.6553,967.6587,2887.7188,966.9775,2887.7188,965.8345z M2891.6719,964.29l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862V964.29
				z"
          />
          <path
            d="M2894.0557,962.1255h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V962.1255z"
          />
          <path
            d="M2896.8545,964.375c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2898.0703,967.6831,2896.8545,966.333,2896.8545,964.375z M2897.9609,963.7065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2898.8486,962.0039,2898.1553,962.624,2897.9609,963.7065z"
          />
          <path
            d="M2908.9199,961.189l1.3135,4.7427l1.3262-4.7427h1.0938l-1.9336,6.3604h-0.96l-1.3262-4.7188l-1.3252,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H2908.9199z"
          />
          <path
            d="M2912.498,965.8345c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2913.4346,967.6587,2912.498,966.9775,2912.498,965.8345z M2916.4512,964.29l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862V964.29
				z"
          />
          <path
            d="M2918.166,965.8345c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2919.1025,967.6587,2918.166,966.9775,2918.166,965.8345z M2922.1191,964.29l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862V964.29
				z"
          />
          <path
            d="M2927.6299,962.2715c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V962.2715z"
          />
          <path d="M2928.0557,958.8174h1.1924v1.2651h-1.1924V958.8174z M2929.1992,967.5493h-1.0947v-6.3604h1.0947V967.5493z" />
          <path
            d="M2930.2939,961.189h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V961.189z"
          />
          <path
            d="M2889.0371,979.5791c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L2889.0371,979.5791z"
          />
          <path
            d="M2896.6406,980.7222c0.875,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3047,1.0581-1.2285,1.8608-2.5537,1.8608
				c-1.7764,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7661,2.5293,1.8242
				l-0.9482,0.4741c-0.1826-0.7783-0.7061-1.3496-1.5811-1.3496c-1.1318,0-1.9102,0.8999-1.9102,2.3589
				C2894.7305,979.8223,2895.5088,980.7222,2896.6406,980.7222z"
          />
          <path
            d="M2899.6689,972.8174h1.0947v3.2349c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V972.8174z"
          />
          <path
            d="M2908.3174,981.6831c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2911.2725,980.3452,2910.0566,981.6831,2908.3174,981.6831z M2908.3174,980.7466
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2906.4688,979.8223,2907.1982,980.7466,2908.3174,980.7466z"
          />
          <path d="M2913.0254,981.5493h-1.0947v-8.7319h1.0947V981.5493z" />
          <path
            d="M2913.6826,978.375c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2914.8984,981.6831,2913.6826,980.333,2913.6826,978.375z M2914.7891,977.7065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2915.6768,976.0039,2914.9834,976.624,2914.7891,977.7065z"
          />
          <path
            d="M2919.96,975.189h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861v4.1958
				h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V975.189z"
          />
          <path
            d="M2865.0254,992.375c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2866.2412,995.6831,2865.0254,994.333,2865.0254,992.375z M2866.1318,991.7065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2867.0195,990.0039,2866.3262,990.624,2866.1318,991.7065z"
          />
          <path
            d="M2871.3027,989.189h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V989.189z"
          />
          <path d="M2879.2217,986.8174h1.1924v1.2651h-1.1924V986.8174z M2880.3652,995.5493h-1.0947v-6.3604h1.0947V995.5493z" />
          <path
            d="M2881.459,989.189h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V989.189z"
          />
          <path
            d="M2887.7354,993.5791c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L2887.7354,993.5791z"
          />
          <path
            d="M2892.9424,990.1255h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V990.1255z"
          />
          <path
            d="M2895.7402,992.375c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2896.9561,995.6831,2895.7402,994.333,2895.7402,992.375z M2896.8467,991.7065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2897.7344,990.0039,2897.041,990.624,2896.8467,991.7065z"
          />
          <path d="M2903.1123,995.5493h-1.0947v-8.7319h1.0947V995.5493z" />
          <path d="M2905.3008,995.5493h-1.0947v-8.7319h1.0947V995.5493z" />
          <path d="M2906.3467,986.8174h1.1924v1.2651h-1.1924V986.8174z M2907.4902,995.5493h-1.0947v-6.3604h1.0947V995.5493z" />
          <path
            d="M2908.585,989.189h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V989.189z"
          />
          <path
            d="M2917.1475,997.8721c-1.3984,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6201-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C2920.1025,996.8384,2918.874,997.8721,2917.1475,997.8721z M2917.2568,990.0283
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C2919.0195,990.709,2918.1689,990.0283,2917.2568,990.0283z"
          />
          <path
            d="M2920.7617,992.375c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2921.9775,995.6831,2920.7617,994.333,2920.7617,992.375z M2921.8682,991.7065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2922.7559,990.0039,2922.0625,990.624,2921.8682,991.7065z"
          />
          <path
            d="M2927.0381,989.189h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V989.189z"
          />
          <path
            d="M2934.5693,992.375c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2935.5781,995.6831,2934.5693,994.1753,2934.5693,992.375z
				 M2937.5361,990.0283c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2939.3359,990.6729,2938.4365,990.0283,2937.5361,990.0283z"
          />
          <path d="M2941.4414,986.8174h1.1924v1.2651h-1.1924V986.8174z M2942.585,995.5493h-1.0947v-6.3604h1.0947V995.5493z" />
          <path
            d="M2943.2422,992.375c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2944.458,995.6831,2943.2422,994.333,2943.2422,992.375z M2944.3486,991.7065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2945.2363,990.0039,2944.543,990.624,2944.3486,991.7065z"
          />
          <path
            d="M2867.7197,1006.375c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2868.7285,1009.6831,2867.7197,1008.1753,2867.7197,1006.375z
				 M2870.6865,1004.0283c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2872.4863,1004.6729,2871.5869,1004.0283,2870.6865,1004.0283z"
          />
          <path
            d="M2879.6885,1009.5493h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093
				c-1.3623,0-2.1279-0.9365-2.1279-2.2861v-4.208h1.0938v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083
				c0.79,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V1009.5493z"
          />
          <path
            d="M2885.8184,1009.5493h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093
				c-1.3623,0-2.1279-0.9365-2.1279-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083
				c0.79,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V1009.5493z"
          />
          <path
            d="M2890.1992,1004.2715c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1004.2715z"
          />
          <path d="M2895.2705,1009.5493h-5.0098v-0.8271l3.6719-4.5967h-3.624v-0.9365h4.9258v0.8394l-3.5996,4.5845h3.6357V1009.5493z" />
          <path
            d="M2895.4668,1007.8345c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2896.4033,1009.6587,2895.4668,1008.9775,2895.4668,1007.8345z M2899.4199,1006.29l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1006.29z"
          />
          <path
            d="M2901.1357,1007.8345c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2902.0723,1009.6587,2901.1357,1008.9775,2901.1357,1007.8345z M2905.0889,1006.29l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1006.29z"
          />
          <path
            d="M2908.3975,1009.5493h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0312-1.0825c1.3125,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1009.5493z"
          />
          <path
            d="M2917.1689,1000.8174h1.0947v3.2349c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1000.8174z"
          />
          <path
            d="M2922.8613,1006.375c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2924.0771,1009.6831,2922.8613,1008.333,2922.8613,1006.375z M2923.9678,1005.7065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2924.8555,1004.0039,2924.1621,1004.624,2923.9678,1005.7065z"
          />
          <path d="M2929.0898,1000.8174h1.1924v1.2651h-1.1924V1000.8174z M2930.2334,1009.5493h-1.0947v-6.3604h1.0947V1009.5493z" />
          <path
            d="M2930.8906,1006.375c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2931.8994,1009.6831,2930.8906,1008.1753,2930.8906,1006.375z
				 M2933.8574,1004.0283c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2935.6572,1004.6729,2934.7578,1004.0283,2933.8574,1004.0283z"
          />
          <path
            d="M2937.957,1007.5791c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L2937.957,1007.5791z"
          />
          <path d="M2942.7021,1005.5117h3.3203v0.9854h-3.3203V1005.5117z" />
          <path
            d="M2886.6416,1022.625v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C2887.7236,1023.6831,2887.0186,1023.2573,2886.6416,1022.625z M2886.6172,1020.6064c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1020.6064z"
          />
          <path
            d="M2895.3389,1018.2715c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1018.2715z"
          />
          <path d="M2895.7646,1014.8174h1.1924v1.2651h-1.1924V1014.8174z M2896.9082,1023.5493h-1.0947v-6.3604h1.0947V1023.5493z" />
          <path
            d="M2898.0029,1017.189h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1017.189z"
          />
          <path
            d="M2906.7002,1022.7222c0.875,0,1.3984-0.5591,1.5801-1.3496l0.9736,0.4497c-0.3047,1.0581-1.2285,1.8608-2.5537,1.8608
				c-1.7764,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7661,2.5293,1.8242
				l-0.9492,0.4741c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9102,0.8999-1.9102,2.3589
				C2904.79,1021.8223,2905.5684,1022.7222,2906.7002,1022.7222z"
          />
          <path d="M2909.6807,1014.8174h1.1924v1.2651h-1.1924V1014.8174z M2910.8242,1023.5493h-1.0947v-6.3604h1.0947V1023.5493z" />
          <path
            d="M2913.0137,1022.625v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C2914.0957,1023.6831,2913.3906,1023.2573,2913.0137,1022.625z M2912.9893,1020.6064c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1020.6064z"
          />
          <path
            d="M2917.9766,1020.375c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2919.1924,1023.6831,2917.9766,1022.333,2917.9766,1020.375z M2919.083,1019.7065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2919.9707,1018.0039,2919.2773,1018.624,2919.083,1019.7065z"
          />
          <path
            d="M2924.3994,1021.5791c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L2924.3994,1021.5791z"
          />
          <path
            d="M2882.7246,1032.1255h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1032.1255z"
          />
          <path
            d="M2888.4785,1037.6831c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2891.4336,1036.3452,2890.2178,1037.6831,2888.4785,1037.6831z M2888.4785,1036.7466
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2886.6299,1035.8223,2887.3594,1036.7466,2888.4785,1036.7466z"
          />
          <path
            d="M2891.6543,1034.375c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2892.8701,1037.6831,2891.6543,1036.333,2891.6543,1034.375z M2892.7607,1033.7065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2893.6484,1032.0039,2892.9551,1032.624,2892.7607,1033.7065z"
          />
          <path
            d="M2899.0264,1036.625v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C2900.1084,1037.6831,2899.4033,1037.2573,2899.0264,1036.625z M2899.002,1034.6064c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1034.6064z"
          />
          <path
            d="M2903.9277,1035.8345c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2904.8643,1037.6587,2903.9277,1036.9775,2903.9277,1035.8345z M2907.8809,1034.29l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1034.29z"
          />
          <path
            d="M2910.2412,1035.5791c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L2910.2412,1035.5791z"
          />
          <path
            d="M2915.4238,1035.5791c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L2915.4238,1035.5791z"
          />
          <path
            d="M2920.0225,1034.375c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2921.2383,1037.6831,2920.0225,1036.333,2920.0225,1034.375z M2921.1289,1033.7065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2922.0166,1032.0039,2921.3232,1032.624,2921.1289,1033.7065z"
          />
          <path
            d="M2926.2998,1031.189h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1031.189z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="89">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3150.1909,1227.9611c0,25.266-20.48,45.75-45.75,45.75c-25.2649,0-45.75-20.484-45.75-45.75
			c0-25.27,20.4851-45.75,45.75-45.75C3129.7109,1182.2111,3150.1909,1202.691,3150.1909,1227.9611"
        />
        <g>
          <path
            d="M3082.5684,1203.9873h-1.167v-8.7314h3.332c1.8486,0,3.0283,0.8633,3.0283,2.5293c0,1.6416-1.1797,2.542-3.0283,2.542
				h-2.165V1203.9873z M3084.6729,1199.3174c1.2402,0,1.9336-0.5469,1.9336-1.5322s-0.6934-1.5195-1.9336-1.5195h-2.1045v3.0518
				H3084.6729z"
          />
          <path
            d="M3087.9951,1200.8135c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3089.2109,1204.1211,3087.9951,1202.7715,3087.9951,1200.8135z
				 M3089.1016,1200.1445h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021
				C3089.9893,1198.4424,3089.2959,1199.0625,3089.1016,1200.1445z"
          />
          <path
            d="M3097.8105,1198.71c-0.1826-0.0371-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.4775h-1.0947
				v-6.3604h1.0947v1.083c0.2793-0.6445,0.8994-1.1318,1.7627-1.1318c0.1709,0,0.3291,0.0127,0.4385,0.0244V1198.71z"
          />
          <path
            d="M3101.0957,1203.1602c0.875,0,1.3984-0.5586,1.5801-1.3496l0.9736,0.4502c-0.3047,1.0576-1.2285,1.8604-2.5537,1.8604
				c-1.7764,0-3.0039-1.3496-3.0039-3.3076c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7656,2.5293,1.8242
				l-0.9492,0.4736c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9102,0.9004-1.9102,2.3594
				C3099.1855,1202.2607,3099.9639,1203.1602,3101.0957,1203.1602z"
          />
          <path
            d="M3103.9307,1200.8135c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3105.1465,1204.1211,3103.9307,1202.7715,3103.9307,1200.8135z
				 M3105.0371,1200.1445h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021
				C3105.9248,1198.4424,3105.2314,1199.0625,3105.0371,1200.1445z"
          />
          <path
            d="M3111.5459,1203.0635v3.1133h-1.0947v-8.5498h1.0947v0.9248c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4951,2.7725,3.3076c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C3112.6279,1204.1211,3111.9229,1203.6953,3111.5459,1203.0635z M3111.5215,1201.0449c0,1.459,0.8994,2.1152,1.7998,2.1152
				c1.1914,0,1.8604-0.9844,1.8604-2.3467c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1201.0449z"
          />
          <path
            d="M3117.3594,1198.5635h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.791,0.8877,0.791c0.2549,0,0.4375-0.0127,0.6934-0.0732v0.9365c-0.2803,0.0605-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1198.5635z"
          />
          <path d="M3120.79,1195.2559h1.1924v1.2646h-1.1924V1195.2559z M3121.9336,1203.9873h-1.0947v-6.3604h1.0947V1203.9873z" />
          <path
            d="M3122.834,1200.8135c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3124.0498,1204.1211,3122.834,1202.7715,3122.834,1200.8135z M3123.9404,1200.1445
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C3124.8281,1198.4424,3124.1348,1199.0625,3123.9404,1200.1445z"
          />
          <path d="M3084.1084,1217.9873l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3084.1084z" />
          <path
            d="M3087.6602,1216.2725c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2529-1.1426
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0547v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4502v0.875
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C3088.5967,1218.0967,3087.6602,1217.416,3087.6602,1216.2725z M3091.6133,1214.7285l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9844,1.1426,0.9844c0.9365,0,1.7275-0.5713,1.7275-1.3857
				V1214.7285z"
          />
          <path
            d="M3094.0703,1211.627h1.0947v0.8633c0.3643-0.5225,0.9971-0.9844,1.8965-0.9844c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1953h-1.0938v-3.9033c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1211.627z"
          />
          <path d="M3104.1543,1217.9873l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3104.1543z" />
          <path d="M3109.2998,1217.9873h-1.0947v-8.7314h1.0947V1217.9873z" />
          <path
            d="M3110.1992,1214.8135c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3111.415,1218.1211,3110.1992,1216.7715,3110.1992,1214.8135z M3111.3057,1214.1445
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C3112.1934,1212.4424,3111.5,1213.0625,3111.3057,1214.1445z"
          />
          <path
            d="M3116.2822,1214.8135c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3117.498,1218.1211,3116.2822,1216.7715,3116.2822,1214.8135z M3117.3887,1214.1445
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C3118.2764,1212.4424,3117.583,1213.0625,3117.3887,1214.1445z"
          />
          <path
            d="M3122.9482,1216.0176c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0088
				c0-0.6934-0.6084-0.791-1.6543-1.0098c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9209,2.335-1.9209
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.9004c1.0947,0.2549,2.3477,0.4736,2.3477,1.8604
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.498-2.7969-1.4834L3122.9482,1216.0176z"
          />
          <path
            d="M3088.876,1230.2725c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2529-1.1426
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0547v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4502v0.875
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C3089.8125,1232.0967,3088.876,1231.416,3088.876,1230.2725z M3092.8291,1228.7285l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9844,1.1426,0.9844c0.9365,0,1.7275-0.5713,1.7275-1.3857
				V1228.7285z"
          />
          <path d="M3096.3818,1231.9873h-1.0947v-8.7314h1.0947V1231.9873z" />
          <path
            d="M3097.8643,1230.0176c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0088
				c0-0.6934-0.6084-0.791-1.6543-1.0098c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9209,2.335-1.9209
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.9004c1.0947,0.2549,2.3477,0.4736,2.3477,1.8604
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.498-2.7969-1.4834L3097.8643,1230.0176z"
          />
          <path
            d="M3105.2246,1223.2559h1.0947v3.2344c0.3643-0.5225,0.9971-0.9844,1.8965-0.9844c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1953h-1.0938v-3.9033c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1223.2559z"
          />
          <path
            d="M3111.1611,1228.8135c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3112.377,1232.1211,3111.1611,1230.7715,3111.1611,1228.8135z M3112.2676,1228.1445
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C3113.1553,1226.4424,3112.4619,1227.0625,3112.2676,1228.1445z"
          />
          <path
            d="M3117.8506,1226.5635h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.791,0.8877,0.791c0.2549,0,0.4375-0.0127,0.6934-0.0732v0.9365c-0.2803,0.0605-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1226.5635z"
          />
          <path d="M3068.6699,1245.9873h-5.0098v-0.8271l3.6719-4.5967h-3.624v-0.9365h4.9258v0.8398l-3.5996,4.584h3.6357V1245.9873z" />
          <path
            d="M3073.5605,1239.627l1.3135,4.7432l1.3262-4.7432h1.0938l-1.9336,6.3604h-0.96l-1.3262-4.7188l-1.3252,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.7314l1.3135-4.7314H3073.5605z"
          />
          <path
            d="M3077.3809,1244.2725c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2529-1.1426
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0547v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4502v0.875
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C3078.3174,1246.0967,3077.3809,1245.416,3077.3809,1244.2725z M3081.334,1242.7285l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9844,1.1426,0.9844c0.9365,0,1.7275-0.5713,1.7275-1.3857
				V1242.7285z"
          />
          <path
            d="M3083.293,1244.2725c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2529-1.1426
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0547v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4502v0.875
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C3084.2295,1246.0967,3083.293,1245.416,3083.293,1244.2725z M3087.2461,1242.7285l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9844,1.1426,0.9844c0.9365,0,1.7275-0.5713,1.7275-1.3857
				V1242.7285z"
          />
          <path
            d="M3093,1240.71c-0.1826-0.0371-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.4775h-1.0947v-6.3604
				h1.0947v1.083c0.2793-0.6445,0.8994-1.1318,1.7627-1.1318c0.1709,0,0.3291,0.0127,0.4385,0.0244V1240.71z"
          />
          <path
            d="M3093.8887,1240.5635h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.791,0.8877,0.791c0.2549,0,0.4375-0.0127,0.6934-0.0732v0.9365c-0.2803,0.0605-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1240.5635z"
          />
          <path
            d="M3096.9297,1242.8135c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3098.1455,1246.1211,3096.9297,1244.7715,3096.9297,1242.8135z
				 M3098.0361,1242.1445h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021
				C3098.9238,1240.4424,3098.2305,1241.0625,3098.0361,1242.1445z"
          />
          <path
            d="M3104.5449,1245.0635v3.1133h-1.0947v-8.5498h1.0947v0.9248c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4951,2.7725,3.3076c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C3105.627,1246.1211,3104.9219,1245.6953,3104.5449,1245.0635z M3104.5205,1243.0449c0,1.459,0.8994,2.1152,1.7998,2.1152
				c1.1914,0,1.8604-0.9844,1.8604-2.3467c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1243.0449z"
          />
          <path
            d="M3115.2363,1245.9873h-1.0947v-0.875c-0.3643,0.5352-0.9844,1.0088-1.8848,1.0088c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0938v3.9766c0,0.9004,0.3896,1.5088,1.3262,1.5088c0.79,0,1.5928-0.5967,1.5928-1.6543v-3.8311h1.0947V1245.9873z"
          />
          <path
            d="M3116.5645,1239.627h1.0947v0.8633c0.3643-0.5225,0.9971-0.9844,1.8965-0.9844c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1953h-1.0938v-3.9033c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1239.627z"
          />
          <path
            d="M3123.1084,1240.5635h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.791,0.8877,0.791c0.2549,0,0.4375-0.0127,0.6934-0.0732v0.9365c-0.2803,0.0605-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1240.5635z"
          />
          <path d="M3130.2979,1245.9873l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3130.2979z" />
          <path
            d="M3133.8496,1244.2725c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2529-1.1426
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0547v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4502v0.875
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C3134.7861,1246.0967,3133.8496,1245.416,3133.8496,1244.2725z M3137.8027,1242.7285l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9844,1.1426,0.9844c0.9365,0,1.7275-0.5713,1.7275-1.3857
				V1242.7285z"
          />
          <path
            d="M3140.2598,1239.627h1.0947v0.8633c0.3643-0.5225,0.9971-0.9844,1.8965-0.9844c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1953h-1.0938v-3.9033c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1239.627z"
          />
          <path
            d="M3077.668,1256.8135c0-1.8125,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C3078.6768,1260.1211,3077.668,1258.6133,3077.668,1256.8135z
				 M3080.6348,1254.4668c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1152v-0.4502C3082.4346,1255.1113,3081.5352,1254.4668,3080.6348,1254.4668z"
          />
          <path
            d="M3084.3955,1256.8135c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3085.6113,1260.1211,3084.3955,1258.7715,3084.3955,1256.8135z M3085.502,1256.1445
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C3086.3896,1254.4424,3085.6963,1255.0625,3085.502,1256.1445z"
          />
          <path
            d="M3094.0898,1259.9873h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5225,0.9482-0.9844,1.8359-0.9844
				c0.8516,0,1.4473,0.4258,1.7637,1.082c0.3525-0.5225,1.0332-1.082,2.0312-1.082c1.3125,0,2.1035,0.9365,2.1035,2.2861v4.1953
				h-1.0947v-3.9033c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V1259.9873z"
          />
          <path
            d="M3102.6055,1258.2725c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2529-1.1426
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0547v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4502v0.875
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C3103.542,1260.0967,3102.6055,1259.416,3102.6055,1258.2725z M3106.5586,1256.7285l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9844,1.1426,0.9844c0.9365,0,1.7275-0.5713,1.7275-1.3857
				V1256.7285z"
          />
          <path
            d="M3108.5176,1258.2725c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2529-1.1426
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0547v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4502v0.875
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C3109.4541,1260.0967,3108.5176,1259.416,3108.5176,1258.2725z M3112.4707,1256.7285l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9844,1.1426,0.9844c0.9365,0,1.7275-0.5713,1.7275-1.3857
				V1256.7285z"
          />
          <path d="M3116.0225,1259.9873h-1.0947v-8.7314h1.0947V1259.9873z" />
          <path
            d="M3117.5303,1254.5635h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.791,0.8877,0.791c0.2549,0,0.4375-0.0127,0.6934-0.0732v0.9365c-0.2803,0.0605-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1254.5635z"
          />
          <path d="M3120.9609,1251.2559h1.1924v1.2646h-1.1924V1251.2559z M3122.1045,1259.9873h-1.0947v-6.3604h1.0947V1259.9873z" />
          <path
            d="M3123.4424,1253.627h1.0947v6.8838c0,1.1309-0.7061,1.7021-1.7881,1.7021c-0.2549,0-0.4131-0.0117-0.6084-0.0361v-0.9492
				c0.1338,0.0244,0.2441,0.0371,0.4258,0.0371c0.6328,0,0.876-0.3037,0.876-0.9492V1253.627z M3123.3936,1251.2559h1.1924v1.2646
				h-1.1924V1251.2559z"
          />
          <path
            d="M3125.4365,1256.8135c0-1.8125,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C3126.4453,1260.1211,3125.4365,1258.6133,3125.4365,1256.8135z
				 M3128.4033,1254.4668c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1152v-0.4502C3130.2031,1255.1113,3129.3037,1254.4668,3128.4033,1254.4668z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="88">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M1866.074,2230.3669c0,25.27-20.48,45.75-45.75,45.75c-25.265,0-45.75-20.48-45.75-45.75
			c0-25.2649,20.485-45.75,45.75-45.75C1845.594,2184.6169,1866.074,2205.1021,1866.074,2230.3669"
        />
        <g>
          <path
            d="M1802.0029,2213.3926l-2.6636-7.1875v7.1875h-1.0942v-8.7305h1.5928l2.6514,7.1738l2.6631-7.1738h1.5444v8.7305h-1.082
				v-7.1875l-2.6758,7.1875H1802.0029z"
          />
          <path
            d="M1807.5996,2211.6777c0-1.2637,0.9849-1.7871,2.4077-2.0781l1.5444-0.3164v-0.2676c0-0.7305-0.4014-1.1445-1.2524-1.1445
				c-0.8027,0-1.2769,0.377-1.4717,1.084l-1.0337-0.2676c0.3042-1.0352,1.2407-1.7773,2.542-1.7773
				c1.4229,0,2.2983,0.6934,2.2983,2.0566v3.1016c0,0.4121,0.2554,0.5469,0.6812,0.4492v0.875
				c-0.9731,0.123-1.5322-0.1094-1.666-0.7168c-0.3892,0.498-1.0947,0.8262-1.958,0.8262
				C1808.5361,2213.502,1807.5996,2212.8223,1807.5996,2211.6777z M1811.5518,2210.1348l-1.2402,0.2676
				c-0.9731,0.1934-1.6299,0.4609-1.6299,1.2266c0,0.6211,0.4502,0.9863,1.1436,0.9863c0.936,0,1.7266-0.5723,1.7266-1.3867
				V2210.1348z"
          />
          <path
            d="M1814.1807,2207.9688h-0.9854v-0.9355h0.9854v-1.8613h1.082v1.8613h1.4473v0.9355h-1.4473v3.7344
				c0,0.668,0.3286,0.7891,0.8877,0.7891c0.2559,0,0.438-0.0117,0.6934-0.0723v0.9375c-0.2798,0.0605-0.5957,0.0957-0.9604,0.0957
				c-1.0825,0-1.7026-0.3633-1.7026-1.5801V2207.9688z"
          />
          <path
            d="M1817.2212,2210.2188c0-1.8965,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3418
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1818.4375,2213.5273,1817.2212,2212.1777,1817.2212,2210.2188z
				 M1818.3281,2209.5508h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1819.2158,2207.8477,1818.5225,2208.4668,1818.3281,2209.5508z"
          />
          <path d="M1827.4512,2213.3926l-2.3833-6.3594h1.1553l1.7876,5.0215l1.7637-5.0215h1.1431l-2.3838,6.3594H1827.4512z" />
          <path
            d="M1831.0044,2211.6777c0-1.2637,0.9849-1.7871,2.4077-2.0781l1.5444-0.3164v-0.2676c0-0.7305-0.4014-1.1445-1.2524-1.1445
				c-0.8027,0-1.2769,0.377-1.4717,1.084l-1.0337-0.2676c0.3042-1.0352,1.2407-1.7773,2.542-1.7773
				c1.4229,0,2.2983,0.6934,2.2983,2.0566v3.1016c0,0.4121,0.2554,0.5469,0.6812,0.4492v0.875
				c-0.9731,0.123-1.5322-0.1094-1.666-0.7168c-0.3892,0.498-1.0947,0.8262-1.958,0.8262
				C1831.9409,2213.502,1831.0044,2212.8223,1831.0044,2211.6777z M1834.9565,2210.1348l-1.2402,0.2676
				c-0.9731,0.1934-1.6299,0.4609-1.6299,1.2266c0,0.6211,0.4502,0.9863,1.1436,0.9863c0.936,0,1.7266-0.5723,1.7266-1.3867
				V2210.1348z"
          />
          <path
            d="M1837.4146,2207.0332h1.0947v0.8633c0.3647-0.5234,0.9971-0.9863,1.897-0.9863c1.3496,0,2.1162,0.9375,2.1162,2.2871
				v4.1953h-1.0947v-3.9023c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.793h-1.0947V2207.0332z"
          />
          <path d="M1777.8325,2227.3926l-2.3833-6.3594h1.1553l1.7876,5.0215l1.7632-5.0215h1.1436l-2.3838,6.3594H1777.8325z" />
          <path
            d="M1784.4014,2227.5273c-1.7393,0-2.9556-1.3379-2.9556-3.3086s1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086S1786.1401,2227.5273,1784.4014,2227.5273z M1784.4014,2226.5898
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.459-0.7295-2.3828-1.8481-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C1782.5527,2225.666,1783.2822,2226.5898,1784.4014,2226.5898z"
          />
          <path
            d="M1787.8203,2224.2188c0-1.8965,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3418
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1789.0366,2227.5273,1787.8203,2226.1777,1787.8203,2224.2188z
				 M1788.9272,2223.5508h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1789.8149,2221.8477,1789.1216,2222.4668,1788.9272,2223.5508z"
          />
          <path
            d="M1793.9023,2224.2188c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0469v-3.2949h1.0942v8.7305
				h-1.0942v-0.9238c-0.377,0.6328-1.0825,1.0586-1.9702,1.0586C1794.9121,2227.5273,1793.9023,2226.0195,1793.9023,2224.2188z
				 M1796.8696,2221.8711c-1.1914,0-1.8604,0.9746-1.8604,2.3477c0,1.3613,0.6689,2.3477,1.8604,2.3477
				c0.9004,0,1.8003-0.6562,1.8003-2.1172V2224C1798.6699,2222.5156,1797.77,2221.8711,1796.8696,2221.8711z"
          />
          <path
            d="M1801.2134,2225.4238c0.3403,0.7402,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				s-0.6079-0.791-1.6538-1.0098c-1.0459-0.2305-2.1406-0.4609-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5605,2.4565,1.3145l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0352-1.7266-1.0352
				c-0.73,0-1.2163,0.3652-1.2163,0.9375c0,0.5957,0.5596,0.7051,1.4473,0.8984c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2168-1.1069,2.0195-2.4688,2.0195c-1.1553,0-2.3105-0.5-2.7974-1.4844L1801.2134,2225.4238z"
          />
          <path
            d="M1806.0547,2224.2188c0-1.8965,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3418
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1807.271,2227.5273,1806.0547,2226.1777,1806.0547,2224.2188z M1807.1616,2223.5508
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1808.0493,2221.8477,1807.356,2222.4668,1807.1616,2223.5508z"
          />
          <path d="M1813.6699,2227.3926h-1.0947v-8.7305h1.0947V2227.3926z" />
          <path d="M1816.6372,2227.3926l-2.3833-6.3594h1.1553l1.7876,5.0215l1.7632-5.0215h1.1436l-2.3838,6.3594H1816.6372z" />
          <path
            d="M1820.251,2224.2188c0-1.8965,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3418
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1821.4673,2227.5273,1820.251,2226.1777,1820.251,2224.2188z M1821.3579,2223.5508
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1822.2456,2221.8477,1821.5522,2222.4668,1821.3579,2223.5508z"
          />
          <path
            d="M1830.0669,2222.1152c-0.1826-0.0371-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7305-1.666,1.8477v3.4785h-1.0947
				v-6.3594h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0117,0.438,0.0234V2222.1152z"
          />
          <path
            d="M1830.9316,2225.4238c0.3403,0.7402,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				s-0.6079-0.791-1.6538-1.0098c-1.0459-0.2305-2.1406-0.4609-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1436,0,2.0796,0.5605,2.4565,1.3145l-0.7783,0.6211c-0.3281-0.6328-0.9243-1.0352-1.7266-1.0352
				c-0.73,0-1.2163,0.3652-1.2163,0.9375c0,0.5957,0.5596,0.7051,1.4473,0.8984c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2168-1.1069,2.0195-2.4688,2.0195c-1.1553,0-2.3105-0.5-2.7974-1.4844L1830.9316,2225.4238z"
          />
          <path
            d="M1837.3057,2226.4688v3.1133h-1.0947v-8.5488h1.0947v0.9238c0.377-0.6211,1.082-1.0469,1.9697-1.0469
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.8008-1.0093,3.3086-2.7729,3.3086
				C1838.3877,2227.5273,1837.6826,2227.1016,1837.3057,2226.4688z M1837.2812,2224.4492c0,1.4609,0.8999,2.1172,1.7998,2.1172
				c1.1919,0,1.8604-0.9863,1.8604-2.3477c0-1.373-0.6685-2.3477-1.8604-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V2224.4492z"
          />
          <path d="M1842.9014,2218.6621h1.1919v1.2637h-1.1919V2218.6621z M1844.0449,2227.3926h-1.0947v-6.3594h1.0947V2227.3926z" />
          <path d="M1846.4775,2227.3926h-1.0947v-8.7305h1.0947V2227.3926z" />
          <path d="M1848.9102,2227.3926h-1.0947v-8.7305h1.0947V2227.3926z" />
          <path d="M1850.1997,2218.6621h1.1919v1.2637h-1.1919V2218.6621z M1851.3433,2227.3926h-1.0947v-6.3594h1.0947V2227.3926z" />
          <path
            d="M1852.6812,2221.0332h1.0947v0.8633c0.3647-0.5234,0.9971-0.9863,1.897-0.9863c1.3496,0,2.1162,0.9375,2.1162,2.2871
				v4.1953h-1.0947v-3.9023c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.793h-1.0947V2221.0332z"
          />
          <path
            d="M1861.4878,2229.7168c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8984v-0.7168c-0.3408,0.6191-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1758c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.4395,1.9458,1.0586v-0.9355
				h1.0942v5.7891C1864.4429,2228.6816,1863.2148,2229.7168,1861.4878,2229.7168z M1861.5972,2221.8711
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2148,1.8604,2.2148c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C1863.3608,2222.5527,1862.5093,2221.8711,1861.5972,2221.8711z"
          />
          <path
            d="M1814.3262,2238.2188c0-1.8965,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3418
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1815.5425,2241.5273,1814.3262,2240.1777,1814.3262,2238.2188z
				 M1815.4331,2237.5508h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1816.3208,2235.8477,1815.6274,2236.4668,1815.4331,2237.5508z"
          />
          <path
            d="M1820.8462,2235.0332h1.0947v0.8633c0.3647-0.5234,0.9971-0.9863,1.897-0.9863c1.3496,0,2.1162,0.9375,2.1162,2.2871
				v4.1953h-1.0947v-3.9023c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.793h-1.0947V2235.0332z"
          />
          <path
            d="M1782.7344,2255.5273c-1.7393,0-2.9556-1.3379-2.9556-3.3086s1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086S1784.4731,2255.5273,1782.7344,2255.5273z M1782.7344,2254.5898
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.459-0.7295-2.3828-1.8481-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C1780.8857,2253.666,1781.6152,2254.5898,1782.7344,2254.5898z"
          />
          <path d="M1788.2202,2255.3926l-2.3833-6.3594h1.1553l1.7876,5.0215l1.7632-5.0215h1.1436l-2.3838,6.3594H1788.2202z" />
          <path
            d="M1791.834,2252.2188c0-1.8965,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3418
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1793.0503,2255.5273,1791.834,2254.1777,1791.834,2252.2188z M1792.9409,2251.5508
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1793.8286,2249.8477,1793.1353,2250.4668,1792.9409,2251.5508z"
          />
          <path
            d="M1801.6499,2250.1152c-0.1826-0.0371-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7305-1.666,1.8477v3.4785h-1.0947
				v-6.3594h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0117,0.438,0.0234V2250.1152z"
          />
          <path
            d="M1804.9346,2254.5664c0.8755,0,1.3984-0.5605,1.5811-1.3496l0.9727,0.4492c-0.3037,1.0586-1.228,1.8613-2.5537,1.8613
				c-1.7754,0-3.0039-1.3496-3.0039-3.3086c0-1.9707,1.2285-3.3086,3.0039-3.3086c1.3257,0,2.2256,0.7676,2.5298,1.8242
				l-0.9487,0.4746c-0.1826-0.7773-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.9004-1.9092,2.3594
				C1803.0254,2253.666,1803.8037,2254.5664,1804.9346,2254.5664z"
          />
          <path
            d="M1810.7251,2255.5273c-1.7393,0-2.9556-1.3379-2.9556-3.3086s1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086S1812.4639,2255.5273,1810.7251,2255.5273z M1810.7251,2254.5898
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.459-0.7295-2.3828-1.8481-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C1808.8765,2253.666,1809.606,2254.5898,1810.7251,2254.5898z"
          />
          <path
            d="M1814.582,2249.0332h1.0947v0.8633c0.3647-0.5234,0.9971-0.9863,1.897-0.9863c1.3501,0,2.1162,0.9375,2.1162,2.2871
				v4.1953h-1.0947v-3.9023c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.793h-1.0947V2249.0332z"
          />
          <path
            d="M1821.1021,2253.4238c0.3403,0.7402,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				s-0.6079-0.791-1.6538-1.0098c-1.0459-0.2305-2.1406-0.4609-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5605,2.4565,1.3145l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0352-1.7266-1.0352
				c-0.73,0-1.2163,0.3652-1.2163,0.9375c0,0.5957,0.5596,0.7051,1.4473,0.8984c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2168-1.1069,2.0195-2.4688,2.0195c-1.1553,0-2.3105-0.5-2.7974-1.4844L1821.1021,2253.4238z"
          />
          <path
            d="M1831.4287,2255.3926h-1.0947v-0.875c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9375-2.1284-2.2871
				v-4.207h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6543v-3.8301h1.0947V2255.3926z"
          />
          <path
            d="M1833.8506,2255.3926h-1.0947v-6.3594h1.0947v0.8633c0.3403-0.5234,0.9482-0.9863,1.8359-0.9863
				c0.8516,0,1.4473,0.4258,1.7637,1.084c0.3525-0.5234,1.0337-1.084,2.0308-1.084c1.3135,0,2.104,0.9375,2.104,2.2871v4.1953
				h-1.0947v-3.9023c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6191-1.5078,1.6523v3.8066h-1.0947v-3.9023
				c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6191-1.5078,1.6523V2255.3926z"
          />
          <path
            d="M1843.9595,2254.4688v3.1133h-1.0947v-8.5488h1.0947v0.9238c0.377-0.6211,1.082-1.0469,1.9697-1.0469
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.8008-1.0093,3.3086-2.7729,3.3086
				C1845.0415,2255.5273,1844.3364,2255.1016,1843.9595,2254.4688z M1843.9351,2252.4492c0,1.4609,0.8999,2.1172,1.7998,2.1172
				c1.1919,0,1.8608-0.9863,1.8608-2.3477c0-1.373-0.6689-2.3477-1.8608-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V2252.4492z"
          />
          <path
            d="M1849.7739,2249.9688h-0.9854v-0.9355h0.9854v-1.8613h1.082v1.8613h1.4473v0.9355h-1.4473v3.7344
				c0,0.668,0.3286,0.7891,0.8877,0.7891c0.2554,0,0.438-0.0117,0.6934-0.0723v0.9375c-0.2798,0.0605-0.5957,0.0957-0.9604,0.0957
				c-1.0825,0-1.7026-0.3633-1.7026-1.5801V2249.9688z"
          />
          <path d="M1853.2041,2246.6621h1.1919v1.2637h-1.1919V2246.6621z M1854.3477,2255.3926h-1.0947v-6.3594h1.0947V2255.3926z" />
          <path
            d="M1855.2476,2252.2188c0-1.8965,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3418
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1856.4639,2255.5273,1855.2476,2254.1777,1855.2476,2252.2188z
				 M1856.3545,2251.5508h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1857.2422,2249.8477,1856.5488,2250.4668,1856.3545,2251.5508z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="87">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M4216.5078,1241.777c0,25.266-20.4849,45.75-45.75,45.75c-25.27,0-45.75-20.484-45.75-45.75
			c0-25.265,20.48-45.75,45.75-45.75C4196.0229,1196.027,4216.5078,1216.512,4216.5078,1241.777"
        />
        <g>
          <path
            d="M4150.959,1224.8032l-2.6631-7.1875v7.1875h-1.0947v-8.7319h1.5928l2.6514,7.1753l2.6631-7.1753h1.5449v8.7319h-1.083
				v-7.1875l-2.6748,7.1875H4150.959z"
          />
          <path
            d="M4157.042,1223.0884c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C4157.9785,1224.9126,4157.042,1224.2314,4157.042,1223.0884z M4160.9951,1221.5439l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1221.5439z"
          />
          <path
            d="M4164.1094,1219.3794h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1219.3794z"
          />
          <path
            d="M4167.6377,1221.6289c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C4168.8535,1224.937,4167.6377,1223.5869,4167.6377,1221.6289z M4168.7441,1220.9604
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C4169.6318,1219.2578,4168.9385,1219.8779,4168.7441,1220.9604z"
          />
          <path d="M4178.8408,1224.8032l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H4178.8408z" />
          <path
            d="M4182.8789,1223.0884c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C4183.8154,1224.9126,4182.8789,1224.2314,4182.8789,1223.0884z M4186.832,1221.5439l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1221.5439z"
          />
          <path
            d="M4189.7764,1218.4429h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1218.4429z"
          />
          <path
            d="M4144.6094,1237.8789v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C4145.6914,1238.937,4144.9863,1238.5112,4144.6094,1237.8789z M4144.585,1235.8604c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1235.8604z"
          />
          <path
            d="M4153.2578,1238.937c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C4156.2129,1237.5991,4154.9971,1238.937,4153.2578,1238.937z M4153.2578,1238.0005
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C4151.4092,1237.0762,4152.1387,1238.0005,4153.2578,1238.0005z"
          />
          <path d="M4158.6963,1238.8032h-1.0947v-8.7319h1.0947V1238.8032z" />
          <path
            d="M4160.0215,1237.0884c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C4160.958,1238.9126,4160.0215,1238.2314,4160.0215,1237.0884z M4163.9746,1235.5439l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1235.5439z"
          />
          <path
            d="M4170.2148,1233.5254c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1233.5254z"
          />
          <path d="M4171.3711,1230.0713h1.1924v1.2651h-1.1924V1230.0713z M4172.5146,1238.8032h-1.0947v-6.3604h1.0947V1238.8032z" />
          <path
            d="M4174.4844,1236.833c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L4174.4844,1236.833z"
          />
          <path
            d="M4179.752,1237.0884c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C4180.6885,1238.9126,4179.752,1238.2314,4179.752,1237.0884z M4183.7051,1235.5439l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1235.5439z"
          />
          <path
            d="M4186.8193,1233.3794h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1233.3794z"
          />
          <path d="M4190.7363,1230.0713h1.1924v1.2651h-1.1924V1230.0713z M4191.8799,1238.8032h-1.0947v-6.3604h1.0947V1238.8032z" />
          <path
            d="M4193.2666,1235.6289c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C4194.4824,1238.937,4193.2666,1237.5869,4193.2666,1235.6289z M4194.373,1234.9604
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C4195.2607,1233.2578,4194.5674,1233.8779,4194.373,1234.9604z"
          />
          <path
            d="M4153.9395,1247.3794h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1247.3794z"
          />
          <path
            d="M4162.9521,1252.8032h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093
				c-1.3623,0-2.1279-0.9365-2.1279-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083
				c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1252.8032z"
          />
          <path
            d="M4164.9111,1250.833c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L4164.9111,1250.833z"
          />
          <path
            d="M4170.8232,1250.833c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L4170.8232,1250.833z"
          />
          <path
            d="M4176.1523,1249.6289c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C4177.3682,1252.937,4176.1523,1251.5869,4176.1523,1249.6289z M4177.2588,1248.9604
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C4178.1465,1247.2578,4177.4531,1247.8779,4177.2588,1248.9604z"
          />
          <path
            d="M4183.1592,1246.4429h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1246.4429z"
          />
          <path
            d="M4151.4277,1269.126c-1.3984,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C4154.3828,1268.0923,4153.1543,1269.126,4151.4277,1269.126z M4151.5371,1261.2822
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C4153.2998,1261.9629,4152.4492,1261.2822,4151.5371,1261.2822z"
          />
          <path
            d="M4159.5049,1261.5254c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1261.5254z"
          />
          <path
            d="M4163.2275,1266.937c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C4166.1826,1265.5991,4164.9668,1266.937,4163.2275,1266.937z M4163.2275,1266.0005
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C4161.3789,1265.0762,4162.1084,1266.0005,4163.2275,1266.0005z"
          />
          <path
            d="M4167.1328,1263.6289c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C4168.3486,1266.937,4167.1328,1265.5869,4167.1328,1263.6289z M4168.2393,1262.9604
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C4169.127,1261.2578,4168.4336,1261.8779,4168.2393,1262.9604z"
          />
          <path
            d="M4175.2344,1265.8789v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C4176.3164,1266.937,4175.6113,1266.5112,4175.2344,1265.8789z M4175.21,1263.8604c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1263.8604z"
          />
          <path
            d="M4180.9277,1263.6289c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C4182.1436,1266.937,4180.9277,1265.5869,4180.9277,1263.6289z M4182.0342,1262.9604
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C4182.9219,1261.2578,4182.2285,1261.8779,4182.0342,1262.9604z"
          />
          <path
            d="M4187.9346,1260.4429h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1260.4429z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="86">
        <path
          style={{ fill: '#33CC61' }}
          className="node"
          d="M2886.2029,1942.73c0,25.266-20.4839,45.75-45.75,45.75c-25.2688,0-45.75-20.484-45.75-45.75
			c0-25.265,20.4812-45.75,45.75-45.75C2865.719,1896.98,2886.2029,1917.465,2886.2029,1942.73"
        />
        <g>
          <path
            d="M2813.4043,1931.8555c1.1309,0,1.9463-0.6562,2.2617-1.7617l1.1074,0.3633c-0.5107,1.5098-1.7031,2.4336-3.3691,2.4336
				c-2.2617,0-3.9277-1.8359-3.9277-4.5s1.666-4.5,3.9277-4.5c1.666,0,2.8584,0.9238,3.3691,2.4316l-1.1074,0.3652
				c-0.3154-1.1055-1.1309-1.7637-2.2617-1.7637c-1.6172,0-2.7607,1.3984-2.7607,3.4668
				C2810.6436,1930.457,2811.7871,1931.8555,2813.4043,1931.8555z"
          />
          <path
            d="M2819.9863,1932.8906c-1.7393,0-2.9551-1.3379-2.9551-3.3086s1.2158-3.3086,2.9551-3.3086s2.9551,1.3379,2.9551,3.3086
				S2821.7256,1932.8906,2819.9863,1932.8906z M2819.9863,1931.9531c1.1182,0,1.8486-0.9238,1.8486-2.3711
				c0-1.459-0.7305-2.3828-1.8486-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C2818.1377,1931.0293,2818.8672,1931.9531,2819.9863,1931.9531z"
          />
          <path
            d="M2824.9365,1932.7559h-1.0947v-6.3594h1.0947v0.8633c0.3398-0.5234,0.9482-0.9863,1.8359-0.9863
				c0.8516,0,1.4473,0.4258,1.7637,1.084c0.3525-0.5234,1.0332-1.084,2.0312-1.084c1.3125,0,2.1035,0.9375,2.1035,2.2871v4.1953
				h-1.0947v-3.9023c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6191-1.5078,1.6523v3.8066h-1.0947v-3.9023
				c0-0.8887-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6191-1.5078,1.6523V1932.7559z"
          />
          <path
            d="M2835.0459,1931.832v3.1133h-1.0947v-8.5488h1.0947v0.9238c0.377-0.6211,1.082-1.0469,1.9697-1.0469
				c1.7637,0,2.7725,1.4961,2.7725,3.3086c0,1.8008-1.0088,3.3086-2.7725,3.3086
				C2836.1279,1932.8906,2835.4229,1932.4648,2835.0459,1931.832z M2835.0215,1929.8125c0,1.4609,0.8994,2.1172,1.7998,2.1172
				c1.1914,0,1.8604-0.9863,1.8604-2.3477c0-1.373-0.6689-2.3477-1.8604-2.3477c-0.9004,0-1.7998,0.6445-1.7998,2.1289V1929.8125z"
          />
          <path d="M2841.7852,1932.7559h-1.0947v-8.7305h1.0947V1932.7559z" />
          <path
            d="M2842.6855,1929.582c0-1.8965,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3418
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2843.9014,1932.8906,2842.6855,1931.541,2842.6855,1929.582z M2843.792,1928.9141
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2844.6797,1927.2109,2843.9863,1927.8301,2843.792,1928.9141z"
          />
          <path
            d="M2851.3574,1930.252l-1.751,2.5039h-1.1914l2.3223-3.3203l-2.1279-3.0391h1.252l1.5332,2.2246l1.5566-2.2246h1.2031
				l-2.1279,3.0391l2.335,3.3203h-1.2402L2851.3574,1930.252z"
          />
          <path d="M2854.8496,1924.0254h1.1924v1.2637h-1.1924V1924.0254z M2855.9932,1932.7559h-1.0947v-6.3594h1.0947V1932.7559z" />
          <path
            d="M2857.501,1927.332h-0.9844v-0.9355h0.9844v-1.8613h1.083v1.8613h1.4473v0.9355h-1.4473v3.7344
				c0,0.668,0.3281,0.7891,0.8877,0.7891c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9375c-0.2803,0.0605-0.5967,0.0957-0.9609,0.0957
				c-1.083,0-1.7031-0.3633-1.7031-1.5801V1927.332z"
          />
          <path
            d="M2860.543,1929.582c0-1.8965,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3418
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2861.7588,1932.8906,2860.543,1931.541,2860.543,1929.582z M2861.6494,1928.9141
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2862.5371,1927.2109,2861.8438,1927.8301,2861.6494,1928.9141z"
          />
          <path d="M2867.0137,1924.0254h1.1924v1.2637h-1.1924V1924.0254z M2868.1572,1932.7559h-1.0947v-6.3594h1.0947V1932.7559z" />
          <path
            d="M2869.665,1927.332h-0.9844v-0.9355h0.9844v-1.8613h1.083v1.8613h1.4473v0.9355h-1.4473v3.7344
				c0,0.668,0.3281,0.7891,0.8877,0.7891c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9375c-0.2803,0.0605-0.5967,0.0957-0.9609,0.0957
				c-1.083,0-1.7031-0.3633-1.7031-1.5801V1927.332z"
          />
          <path d="M2826.7432,1946.7559l-2.3838-6.3594h1.1553l1.7881,5.0215l1.7627-5.0215h1.1436l-2.3838,6.3594H2826.7432z" />
          <path
            d="M2830.2949,1945.041c0-1.2637,0.9854-1.7871,2.4082-2.0781l1.5449-0.3164v-0.2676c0-0.7305-0.4014-1.1445-1.2529-1.1445
				c-0.8027,0-1.2773,0.377-1.4717,1.084l-1.0332-0.2676c0.3037-1.0352,1.2402-1.7773,2.541-1.7773
				c1.4229,0,2.2988,0.6934,2.2988,2.0566v3.1016c0,0.4121,0.2559,0.5469,0.6807,0.4492v0.875
				c-0.9727,0.123-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C2831.2314,1946.8652,2830.2949,1946.1855,2830.2949,1945.041z M2834.248,1943.498l-1.2412,0.2676
				c-0.9727,0.1934-1.6289,0.4609-1.6289,1.2266c0,0.6211,0.4492,0.9863,1.1426,0.9863c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V1943.498z"
          />
          <path
            d="M2836.7051,1940.3965h1.0947v0.8633c0.3643-0.5234,0.9971-0.9863,1.8965-0.9863c1.3506,0,2.1162,0.9375,2.1162,2.2871
				v4.1953h-1.0938v-3.9023c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.793h-1.0947V1940.3965z"
          />
          <path
            d="M2844.7227,1943.582c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0469v-3.2949h1.0947v8.7305
				h-1.0947v-0.9238c-0.377,0.6328-1.082,1.0586-1.9697,1.0586C2845.7314,1946.8906,2844.7227,1945.3828,2844.7227,1943.582z
				 M2847.6895,1941.2344c-1.1914,0-1.8604,0.9746-1.8604,2.3477c0,1.3613,0.6689,2.3477,1.8604,2.3477
				c0.9004,0,1.7998-0.6562,1.7998-2.1172v-0.4492C2849.4893,1941.8789,2848.5898,1941.2344,2847.6895,1941.2344z"
          />
          <path
            d="M2851.4502,1943.582c0-1.8965,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3418
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2852.666,1946.8906,2851.4502,1945.541,2851.4502,1943.582z M2852.5566,1942.9141
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2853.4443,1941.2109,2852.751,1941.8301,2852.5566,1942.9141z"
          />
          <path
            d="M2799.0391,1960.8906c-1.7393,0-2.9551-1.3379-2.9551-3.3086s1.2158-3.3086,2.9551-3.3086s2.9551,1.3379,2.9551,3.3086
				S2800.7783,1960.8906,2799.0391,1960.8906z M2799.0391,1959.9531c1.1182,0,1.8486-0.9238,1.8486-2.3711
				c0-1.459-0.7305-2.3828-1.8486-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C2797.1904,1959.0293,2797.9199,1959.9531,2799.0391,1959.9531z"
          />
          <path d="M2803.917,1960.7559l-2.3838-6.3594h1.1553l1.7881,5.0215l1.7627-5.0215h1.1436l-2.3838,6.3594H2803.917z" />
          <path
            d="M2806.9219,1957.582c0-1.8965,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3418
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2808.1377,1960.8906,2806.9219,1959.541,2806.9219,1957.582z M2808.0283,1956.9141
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2808.916,1955.2109,2808.2227,1955.8301,2808.0283,1956.9141z"
          />
          <path
            d="M2816.1299,1955.4785c-0.1826-0.0371-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7305-1.666,1.8477v3.4785h-1.0947
				v-6.3594h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0117,0.4385,0.0234V1955.4785z"
          />
          <path
            d="M2816.2402,1952.0254h1.0947v3.2344c0.3643-0.5234,0.9971-0.9863,1.8965-0.9863c1.3506,0,2.1162,0.9375,2.1162,2.2871
				v4.1953h-1.0938v-3.9023c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.793h-1.0947V1952.0254z"
          />
          <path
            d="M2821.5674,1957.582c0-1.8965,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3418
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2822.7832,1960.8906,2821.5674,1959.541,2821.5674,1957.582z M2822.6738,1956.9141
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2823.5615,1955.2109,2822.8682,1955.8301,2822.6738,1956.9141z"
          />
          <path d="M2827.4307,1952.0254h1.1924v1.2637h-1.1924V1952.0254z M2828.5742,1960.7559h-1.0947v-6.3594h1.0947V1960.7559z" />
          <path
            d="M2828.8662,1957.582c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0469v-3.2949h1.0947v8.7305
				h-1.0947v-0.9238c-0.377,0.6328-1.082,1.0586-1.9697,1.0586C2829.875,1960.8906,2828.8662,1959.3828,2828.8662,1957.582z
				 M2831.833,1955.2344c-1.1914,0-1.8604,0.9746-1.8604,2.3477c0,1.3613,0.6689,2.3477,1.8604,2.3477
				c0.9004,0,1.7998-0.6562,1.7998-2.1172v-0.4492C2833.6328,1955.8789,2832.7334,1955.2344,2831.833,1955.2344z"
          />
          <path
            d="M2835.5684,1958.7871c0.3408,0.7402,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				s-0.6084-0.791-1.6543-1.0098c-1.0459-0.2305-2.1396-0.4609-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5605,2.4561,1.3145l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0352-1.7266-1.0352
				c-0.7295,0-1.2158,0.3652-1.2158,0.9375c0,0.5957,0.5586,0.7051,1.4463,0.8984c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2168-1.1064,2.0195-2.4688,2.0195c-1.1553,0-2.3105-0.5-2.7969-1.4844L2835.5684,1958.7871z"
          />
          <path
            d="M2840.3857,1958.7871c0.3408,0.7402,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				s-0.6084-0.791-1.6543-1.0098c-1.0459-0.2305-2.1396-0.4609-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5605,2.4561,1.3145l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0352-1.7266-1.0352
				c-0.7295,0-1.2158,0.3652-1.2158,0.9375c0,0.5957,0.5586,0.7051,1.4463,0.8984c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2168-1.1064,2.0195-2.4688,2.0195c-1.1553,0-2.3105-0.5-2.7969-1.4844L2840.3857,1958.7871z"
          />
          <path
            d="M2845.2275,1955.332h-0.9844v-0.9355h0.9844v-1.8613h1.083v1.8613h1.4473v0.9355h-1.4473v3.7344
				c0,0.668,0.3281,0.7891,0.8877,0.7891c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9375c-0.2803,0.0605-0.5967,0.0957-0.9609,0.0957
				c-1.083,0-1.7031-0.3633-1.7031-1.5801V1955.332z"
          />
          <path
            d="M2851.3945,1955.4785c-0.1826-0.0371-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7305-1.666,1.8477v3.4785h-1.0947
				v-6.3594h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0117,0.4385,0.0234V1955.4785z"
          />
          <path
            d="M2856.5518,1960.7559h-1.0947v-0.875c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9375-2.1279-2.2871
				v-4.207h1.0938v3.9766c0,0.9004,0.3896,1.5078,1.3262,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6543v-3.8301h1.0947V1960.7559z"
          />
          <path
            d="M2859.8369,1959.9297c0.875,0,1.3984-0.5605,1.5801-1.3496l0.9736,0.4492c-0.3047,1.0586-1.2285,1.8613-2.5537,1.8613
				c-1.7764,0-3.0039-1.3496-3.0039-3.3086c0-1.9707,1.2275-3.3086,3.0039-3.3086c1.3252,0,2.2256,0.7676,2.5293,1.8242
				l-0.9492,0.4746c-0.1816-0.7773-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9102,0.9004-1.9102,2.3594
				C2857.9268,1959.0293,2858.7051,1959.9297,2859.8369,1959.9297z"
          />
          <path
            d="M2862.6709,1955.332h-0.9844v-0.9355h0.9844v-1.8613h1.083v1.8613h1.4473v0.9355h-1.4473v3.7344
				c0,0.668,0.3281,0.7891,0.8877,0.7891c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9375c-0.2803,0.0605-0.5967,0.0957-0.9609,0.0957
				c-1.083,0-1.7031-0.3633-1.7031-1.5801V1955.332z"
          />
          <path
            d="M2870.5879,1960.7559h-1.0947v-0.875c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9375-2.1279-2.2871
				v-4.207h1.0947v3.9766c0,0.9004,0.3887,1.5078,1.3252,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6543v-3.8301h1.0947V1960.7559z"
          />
          <path
            d="M2874.6035,1955.4785c-0.1826-0.0371-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7305-1.666,1.8477v3.4785h-1.0947
				v-6.3594h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0117,0.4385,0.0234V1955.4785z"
          />
          <path
            d="M2874.2764,1957.582c0-1.8965,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3418
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2875.4922,1960.8906,2874.2764,1959.541,2874.2764,1957.582z M2875.3828,1956.9141
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2876.2705,1955.2109,2875.5771,1955.8301,2875.3828,1956.9141z"
          />
          <path
            d="M2880.1885,1954.3965h1.0947v0.8633c0.3643-0.5234,0.9971-0.9863,1.8965-0.9863c1.3506,0,2.1162,0.9375,2.1162,2.2871
				v4.1953h-1.0938v-3.9023c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.793h-1.0947V1954.3965z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="85">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M2384.875,496.582c0,25.2661-20.48,45.75-45.75,45.75c-25.2661,0-45.75-20.4839-45.75-45.75
			c0-25.27,20.4839-45.75,45.75-45.75C2364.395,450.832,2384.875,471.312,2384.875,496.582"
        />
        <g>
          <path
            d="M2301.1572,485.3071c-0.3525,0.8027-1.2891,1.4351-2.542,1.4351c-2.2256,0-3.8789-1.8242-3.8789-4.4756
				c0-2.6631,1.666-4.5239,3.9521-4.5239c1.6787,0,2.8701,0.9121,3.3926,2.4204l-1.1182,0.3647
				c-0.3164-1.1069-1.1191-1.7515-2.2627-1.7515c-1.6533,0-2.7969,1.4106-2.7969,3.4785c0,2.0552,1.1436,3.478,2.8096,3.478
				c1.4111,0,2.3594-1.0825,2.3838-2.6392h-2.5059v-1.0215h3.6367v4.5361h-1.0703V485.3071z"
          />
          <path
            d="M2306.4014,481.3306c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V481.3306z"
          />
          <path
            d="M2309.2725,486.7422c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2312.2275,485.4043,2311.0117,486.7422,2309.2725,486.7422z M2309.2725,485.8057
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2307.4238,484.8813,2308.1533,485.8057,2309.2725,485.8057z"
          />
          <path
            d="M2312.7646,480.248h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V480.248z"
          />
          <path
            d="M2318.3369,483.4341c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2319.3457,486.7422,2318.3369,485.2344,2318.3369,483.4341z
				 M2321.3037,481.0874c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2323.1035,481.7319,2322.2041,481.0874,2321.3037,481.0874z"
          />
          <path
            d="M2327.9463,485.6841v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C2329.0283,486.7422,2328.3232,486.3164,2327.9463,485.6841z M2327.9219,483.6655c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V483.6655z"
          />
          <path
            d="M2332.7881,483.4341c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2334.0039,486.7422,2332.7881,485.3921,2332.7881,483.4341z M2333.8945,482.7656
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2334.7822,481.063,2334.0889,481.6831,2333.8945,482.7656z"
          />
          <path
            d="M2339.0889,484.6382c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L2339.0889,484.6382z"
          />
          <path
            d="M2346.5703,485.7812c0.875,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3047,1.0581-1.2285,1.8608-2.5537,1.8608
				c-1.7764,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7661,2.5293,1.8242
				l-0.9482,0.4741c-0.1826-0.7783-0.7061-1.3496-1.5811-1.3496c-1.1318,0-1.9102,0.8999-1.9102,2.3589
				C2344.6602,484.8813,2345.4385,485.7812,2346.5703,485.7812z"
          />
          <path
            d="M2349.4775,477.8765h1.0947v3.2349c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V477.8765z"
          />
          <path d="M2355.4385,477.8765h1.1924v1.2651h-1.1924V477.8765z M2356.582,486.6084h-1.0947v-6.3604h1.0947V486.6084z" />
          <path
            d="M2358.6494,483.2153l2.8213-2.9673h1.3623l-2.4814,2.5054l2.6875,3.855h-1.2764l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V483.2153z"
          />
          <path
            d="M2364.2939,485.6841v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C2365.376,486.7422,2364.6709,486.3164,2364.2939,485.6841z M2364.2695,483.6655c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V483.6655z"
          />
          <path
            d="M2369.0742,484.8936c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2370.0107,486.7178,2369.0742,486.0366,2369.0742,484.8936z M2373.0273,483.3491l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V483.3491z"
          />
          <path
            d="M2374.6211,484.8936c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2375.5576,486.7178,2374.6211,486.0366,2374.6211,484.8936z M2378.5742,483.3491l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V483.3491z"
          />
          <path
            d="M2383.9639,481.3306c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V481.3306z"
          />
          <path d="M2304.4736,500.6084l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2304.4736z" />
          <path
            d="M2310.9199,500.7422c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2313.875,499.4043,2312.6592,500.7422,2310.9199,500.7422z M2310.9199,499.8057
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2309.0713,498.8813,2309.8008,499.8057,2310.9199,499.8057z"
          />
          <path
            d="M2317.1729,500.7422c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2320.1279,499.4043,2318.9121,500.7422,2317.1729,500.7422z M2317.1729,499.8057
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2315.3242,498.8813,2316.0537,499.8057,2317.1729,499.8057z"
          />
          <path
            d="M2324.2041,495.3306c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V495.3306z"
          />
          <path
            d="M2326.3223,497.4341c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2327.3311,500.7422,2326.3223,499.2344,2326.3223,497.4341z
				 M2329.2891,495.0874c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2331.0889,495.7319,2330.1895,495.0874,2329.2891,495.0874z"
          />
          <path
            d="M2338.4121,500.6084h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V500.6084z"
          />
          <path
            d="M2344.665,500.6084h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0938v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V500.6084z"
          />
          <path
            d="M2349.167,495.3306c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V495.3306z"
          />
          <path d="M2354.3604,500.6084h-5.0098v-0.8271l3.6719-4.5967h-3.624v-0.9365h4.9258v0.8394l-3.5996,4.5845h3.6357V500.6084z" />
          <path
            d="M2354.6787,498.8936c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2355.6152,500.7178,2354.6787,500.0366,2354.6787,498.8936z M2358.6318,497.3491l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V497.3491z"
          />
          <path
            d="M2362.0625,500.6084h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0312-1.0825c1.3135,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V500.6084z"
          />
          <path
            d="M2370.5176,497.4341c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2371.7334,500.7422,2370.5176,499.3921,2370.5176,497.4341z M2371.624,496.7656
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2372.5117,495.063,2371.8184,495.6831,2371.624,496.7656z"
          />
          <path d="M2298.9629,514.6084l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2298.9629z" />
          <path
            d="M2305.4092,514.7422c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2308.3643,513.4043,2307.1484,514.7422,2305.4092,514.7422z M2305.4092,513.8057
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2303.5605,512.8813,2304.29,513.8057,2305.4092,513.8057z"
          />
          <path
            d="M2308.7061,511.4341c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2309.9219,514.7422,2308.7061,513.3921,2308.7061,511.4341z M2309.8125,510.7656
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2310.7002,509.063,2310.0068,509.6831,2309.8125,510.7656z"
          />
          <path
            d="M2314.667,511.4341c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2315.6758,514.7422,2314.667,513.2344,2314.667,511.4341z
				 M2317.6338,509.0874c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2319.4336,509.7319,2318.5342,509.0874,2317.6338,509.0874z"
          />
          <path
            d="M2321.8564,512.6382c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L2321.8564,512.6382z"
          />
          <path
            d="M2326.5771,511.4341c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2327.793,514.7422,2326.5771,513.3921,2326.5771,511.4341z M2327.6836,510.7656
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2328.5713,509.063,2327.8779,509.6831,2327.6836,510.7656z"
          />
          <path d="M2334.0703,514.6084h-1.0947v-8.7319h1.0947V514.6084z" />
          <path
            d="M2336.3809,513.6841v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C2337.4629,514.7422,2336.7578,514.3164,2336.3809,513.6841z M2336.3564,511.6655c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V511.6655z"
          />
          <path
            d="M2345.1992,509.3306c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V509.3306z"
          />
          <path
            d="M2348.3135,514.7422c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2351.2686,513.4043,2350.0527,514.7422,2348.3135,514.7422z M2348.3135,513.8057
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2346.4648,512.8813,2347.1943,513.8057,2348.3135,513.8057z"
          />
          <path
            d="M2351.6123,511.4341c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2352.6211,514.7422,2351.6123,513.2344,2351.6123,511.4341z
				 M2354.5791,509.0874c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2356.3789,509.7319,2355.4795,509.0874,2354.5791,509.0874z"
          />
          <path
            d="M2363.7021,514.6084h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0938v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V514.6084z"
          />
          <path
            d="M2367.4736,513.7812c0.875,0,1.3984-0.5591,1.5801-1.3496l0.9736,0.4497c-0.3047,1.0581-1.2285,1.8608-2.5537,1.8608
				c-1.7764,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7661,2.5293,1.8242
				l-0.9492,0.4741c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9102,0.8999-1.9102,2.3589
				C2365.5635,512.8813,2366.3418,513.7812,2367.4736,513.7812z"
          />
          <path
            d="M2370.7939,509.1846h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V509.1846z"
          />
          <path d="M2374.1025,505.8765h1.1924v1.2651h-1.1924V505.8765z M2375.2461,514.6084h-1.0947v-6.3604h1.0947V514.6084z" />
          <path
            d="M2376.0244,511.4341c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2377.2402,514.7422,2376.0244,513.3921,2376.0244,511.4341z M2377.1309,510.7656
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2378.0186,509.063,2377.3252,509.6831,2377.1309,510.7656z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="84">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3306.1721,1670.301c0,25.2689-20.4841,45.75-45.75,45.75c-25.27,0-45.75-20.4811-45.75-45.75
			c0-25.266,20.48-45.75,45.75-45.75C3285.688,1624.551,3306.1721,1645.035,3306.1721,1670.301"
        />
        <g>
          <path d="M3218.2061,1673.7529l-2.8496-7.4941h1.0752l2.3164,6.2412l2.3174-6.2412h1.0225l-2.8281,7.4941H3218.2061z" />
          <path
            d="M3224.6826,1673.8682c-1.4922,0-2.5361-1.1484-2.5361-2.8389c0-1.6914,1.0439-2.8389,2.5361-2.8389
				s2.5361,1.1475,2.5361,2.8389C3227.2188,1672.7197,3226.1748,1673.8682,3224.6826,1673.8682z M3224.6826,1673.0645
				c0.96,0,1.5859-0.7939,1.5859-2.0352c0-1.2529-0.626-2.0459-1.5859-2.0459s-1.5869,0.793-1.5869,2.0459
				C3223.0957,1672.2705,3223.7227,1673.0645,3224.6826,1673.0645z"
          />
          <path
            d="M3227.5068,1671.0293c0-1.6289,1.0332-2.8389,2.5156-2.8389c1.5234,0,2.3271,1.1582,2.3271,2.6719v0.3125h-3.9453
				c0.0518,1.1484,0.71,1.8789,1.6592,1.8789c0.7305,0,1.2529-0.3965,1.4199-1.0439l0.8242,0.292
				c-0.334,0.9707-1.1582,1.5664-2.2441,1.5664C3228.5508,1673.8682,3227.5068,1672.709,3227.5068,1671.0293z M3228.4561,1670.4551
				h2.9434c-0.0205-0.7725-0.4385-1.4609-1.3877-1.4609C3229.2188,1668.9941,3228.623,1669.5264,3228.4561,1670.4551z"
          />
          <path
            d="M3232.6172,1671.0293c0-1.5557,0.8662-2.8389,2.3799-2.8389c0.7617,0,1.3672,0.3652,1.6904,0.8975v-2.8291h0.9395v7.4941
				h-0.9395v-0.793c-0.3232,0.542-0.9287,0.9082-1.6904,0.9082C3233.4834,1673.8682,3232.6172,1672.5732,3232.6172,1671.0293z
				 M3235.1641,1669.0146c-1.0234,0-1.5977,0.835-1.5977,2.0146c0,1.1689,0.5742,2.0137,1.5977,2.0137
				c0.7715,0,1.5439-0.5635,1.5439-1.8154v-0.3867C3236.708,1669.5674,3235.9355,1669.0146,3235.1641,1669.0146z"
          />
          <path
            d="M3238.7812,1672.0625c0.292,0.6367,0.96,1.0225,1.6699,1.0225c0.6367,0,1.2002-0.2715,1.2002-0.8662
				s-0.5215-0.6787-1.4199-0.8662c-0.8975-0.1982-1.8369-0.3965-1.8369-1.5137c0-0.9707,0.8457-1.6484,2.0039-1.6484
				c0.9814,0,1.7852,0.4795,2.1084,1.127l-0.668,0.5322c-0.2812-0.543-0.793-0.8877-1.4814-0.8877
				c-0.627,0-1.0439,0.3135-1.0439,0.8037c0,0.5117,0.4805,0.6055,1.2422,0.7725c0.9395,0.2197,2.0137,0.4072,2.0137,1.5967
				c0,1.0439-0.9492,1.7334-2.1182,1.7334c-0.9912,0-1.9834-0.4287-2.4004-1.2734L3238.7812,1672.0625z"
          />
          <path
            d="M3242.8271,1671.0293c0-1.6289,1.0332-2.8389,2.5156-2.8389c1.5234,0,2.3271,1.1582,2.3271,2.6719v0.3125h-3.9453
				c0.0518,1.1484,0.71,1.8789,1.6592,1.8789c0.7305,0,1.2529-0.3965,1.4199-1.0439l0.8242,0.292
				c-0.334,0.9707-1.1582,1.5664-2.2441,1.5664C3243.8711,1673.8682,3242.8271,1672.709,3242.8271,1671.0293z M3243.7764,1670.4551
				h2.9434c-0.0205-0.7725-0.4385-1.4609-1.3877-1.4609C3244.5391,1668.9941,3243.9434,1669.5264,3243.7764,1670.4551z"
          />
          <path d="M3249.252,1673.7529h-0.9385v-7.4941h0.9385V1673.7529z" />
          <path
            d="M3252.3818,1675.7461c-1.2002,0-2.0771-0.5527-2.3799-1.5547l0.877-0.334c0.1777,0.6992,0.7412,1.1064,1.5029,1.1064
				c0.96,0,1.5967-0.4912,1.5967-1.6279v-0.6162c-0.292,0.5322-0.9082,0.9082-1.6699,0.9082c-1.4922,0-2.3691-1.2314-2.3691-2.7246
				c0-1.4814,0.877-2.7129,2.3691-2.7129c0.752,0,1.3779,0.375,1.6699,0.9072v-0.8037h0.9395v4.9688
				C3254.918,1674.8594,3253.8643,1675.7461,3252.3818,1675.7461z M3252.4756,1669.0146c-0.9912,0-1.5967,0.7412-1.5967,1.8887
				c0,1.1484,0.6055,1.8994,1.5967,1.8994c0.7832,0,1.5137-0.584,1.5137-1.7842v-0.1982
				C3253.9893,1669.5986,3253.2588,1669.0146,3252.4756,1669.0146z"
          />
          <path
            d="M3255.582,1671.0293c0-1.6289,1.0332-2.8389,2.5146-2.8389c1.5244,0,2.3281,1.1582,2.3281,2.6719v0.3125h-3.9453
				c0.0518,1.1484,0.71,1.8789,1.6592,1.8789c0.7305,0,1.2529-0.3965,1.4199-1.0439l0.8242,0.292
				c-0.334,0.9707-1.1582,1.5664-2.2441,1.5664C3256.626,1673.8682,3255.582,1672.709,3255.582,1671.0293z M3256.5312,1670.4551
				h2.9434c-0.0205-0.7725-0.4385-1.4609-1.3877-1.4609C3257.2939,1668.9941,3256.6982,1669.5264,3256.5312,1670.4551z"
          />
          <path d="M3262.0068,1673.7529h-0.9385v-7.4941h0.9385V1673.7529z" />
          <path
            d="M3262.6738,1671.0293c0-1.6289,1.0332-2.8389,2.5146-2.8389c1.5244,0,2.3281,1.1582,2.3281,2.6719v0.3125h-3.9453
				c0.0518,1.1484,0.71,1.8789,1.6592,1.8789c0.7305,0,1.2529-0.3965,1.4199-1.0439l0.8242,0.292
				c-0.334,0.9707-1.1582,1.5664-2.2441,1.5664C3263.7178,1673.8682,3262.6738,1672.709,3262.6738,1671.0293z M3263.623,1670.4551
				h2.9434c-0.0205-0.7725-0.4385-1.4609-1.3877-1.4609C3264.3857,1668.9941,3263.79,1669.5264,3263.623,1670.4551z"
          />
          <path
            d="M3268.3057,1669.0977h-0.8457v-0.8037h0.8457v-1.5967h0.9287v1.5967h1.2422v0.8037h-1.2422v3.2041
				c0,0.5742,0.2822,0.6787,0.7627,0.6787c0.2188,0,0.375-0.0107,0.5947-0.0625v0.8037c-0.2402,0.0518-0.5117,0.083-0.8252,0.083
				c-0.9287,0-1.4609-0.3125-1.4609-1.3564V1669.0977z"
          />
          <path
            d="M3271.3301,1669.0977h-0.8447v-0.8037h0.8447v-1.5967h0.9287v1.5967h1.2422v0.8037h-1.2422v3.2041
				c0,0.5742,0.2822,0.6787,0.7627,0.6787c0.2188,0,0.375-0.0107,0.5947-0.0625v0.8037c-0.2402,0.0518-0.5117,0.083-0.8252,0.083
				c-0.9287,0-1.4609-0.3125-1.4609-1.3564V1669.0977z"
          />
          <path
            d="M3273.833,1671.0293c0-1.6289,1.0332-2.8389,2.5156-2.8389c1.5234,0,2.3271,1.1582,2.3271,2.6719v0.3125h-3.9453
				c0.0518,1.1484,0.71,1.8789,1.6592,1.8789c0.7305,0,1.2529-0.3965,1.4199-1.0439l0.8242,0.292
				c-0.334,0.9707-1.1582,1.5664-2.2441,1.5664C3274.877,1673.8682,3273.833,1672.709,3273.833,1671.0293z M3274.7822,1670.4551
				h2.9434c-0.0205-0.7725-0.4385-1.4609-1.3877-1.4609C3275.5449,1668.9941,3274.9492,1669.5264,3274.7822,1670.4551z"
          />
          <path
            d="M3282.1475,1669.2236c-0.1562-0.0312-0.2822-0.042-0.459-0.042c-0.7939,0-1.4307,0.626-1.4307,1.5859v2.9854h-0.9385
				v-5.459h0.9385v0.9297c0.2402-0.5537,0.7725-0.9707,1.5137-0.9707c0.1465,0,0.2822,0.0098,0.376,0.0205V1669.2236z"
          />
          <path
            d="M3282.2812,1671.0293c0-1.5557,0.8662-2.8389,2.3799-2.8389c0.7617,0,1.3672,0.3652,1.6904,0.8975v-2.8291h0.9395v7.4941
				h-0.9395v-0.793c-0.3232,0.542-0.9287,0.9082-1.6904,0.9082C3283.1475,1673.8682,3282.2812,1672.5732,3282.2812,1671.0293z
				 M3284.8281,1669.0146c-1.0234,0-1.5977,0.835-1.5977,2.0146c0,1.1689,0.5742,2.0137,1.5977,2.0137
				c0.7715,0,1.5439-0.5635,1.5439-1.8154v-0.3867C3286.3721,1669.5674,3285.5996,1669.0146,3284.8281,1669.0146z"
          />
          <path
            d="M3288.3203,1666.2588h0.9385v2.7764c0.3135-0.4482,0.8564-0.8447,1.6289-0.8447c1.1582,0,1.8154,0.8037,1.8154,1.9619
				v3.6006h-0.9395v-3.3506c0-0.7617-0.3232-1.3359-1.1377-1.3359c-0.6777,0-1.3672,0.5225-1.3672,1.4297v3.2568h-0.9385V1666.2588z
				"
          />
          <path
            d="M3293.3047,1671.0293c0-1.6289,1.0332-2.8389,2.5146-2.8389c1.5244,0,2.3281,1.1582,2.3281,2.6719v0.3125h-3.9453
				c0.0518,1.1484,0.71,1.8789,1.6592,1.8789c0.7305,0,1.2529-0.3965,1.4199-1.0439l0.8242,0.292
				c-0.334,0.9707-1.1582,1.5664-2.2441,1.5664C3294.3486,1673.8682,3293.3047,1672.709,3293.3047,1671.0293z M3294.2539,1670.4551
				h2.9434c-0.0205-0.7725-0.4385-1.4609-1.3877-1.4609C3295.0166,1668.9941,3294.4209,1669.5264,3294.2539,1670.4551z"
          />
          <path d="M3298.749,1666.2588h1.0225v1.0859h-1.0225V1666.2588z M3299.7295,1673.7529h-0.9385v-5.459h0.9385V1673.7529z" />
          <path
            d="M3300.3965,1671.0293c0-1.5557,0.8662-2.8389,2.3799-2.8389c0.7617,0,1.3672,0.3652,1.6904,0.8975v-2.8291h0.9395v7.4941
				h-0.9395v-0.793c-0.3232,0.542-0.9287,0.9082-1.6904,0.9082C3301.2627,1673.8682,3300.3965,1672.5732,3300.3965,1671.0293z
				 M3302.9434,1669.0146c-1.0234,0-1.5977,0.835-1.5977,2.0146c0,1.1689,0.5742,2.0137,1.5977,2.0137
				c0.7715,0,1.5439-0.5635,1.5439-1.8154v-0.3867C3304.4873,1669.5674,3303.7148,1669.0146,3302.9434,1669.0146z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="83">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3576.176,1000.152c0,25.266-20.4851,45.75-45.75,45.75c-25.27,0-45.75-20.484-45.75-45.75
			c0-25.2649,20.48-45.75,45.75-45.75C3555.6909,954.402,3576.176,974.8871,3576.176,1000.152"
        />
        <g>
          <path d="M3499.1484,981.4463v8.7319h-1.167v-4.001h-4.6826v4.001h-1.167v-8.7319h1.167v3.7095h4.6826v-3.7095H3499.1484z" />
          <path
            d="M3500.5859,987.0039c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3501.8018,990.312,3500.5859,988.9619,3500.5859,987.0039z M3501.6924,986.3354
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3502.5801,984.6328,3501.8867,985.2529,3501.6924,986.3354z"
          />
          <path
            d="M3507.7627,984.7544h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V984.7544z"
          />
          <path
            d="M3511.291,987.0039c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3512.5068,990.312,3511.291,988.9619,3511.291,987.0039z M3512.3975,986.3354
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3513.2852,984.6328,3512.5918,985.2529,3512.3975,986.3354z"
          />
          <path
            d="M3521.5938,984.9004c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V984.9004z"
          />
          <path
            d="M3525.3164,990.312c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3528.2715,988.9741,3527.0557,990.312,3525.3164,990.312z M3525.3164,989.3755
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3523.4678,988.4512,3524.1973,989.3755,3525.3164,989.3755z"
          />
          <path
            d="M3532.0918,992.501c-1.3994,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6201-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C3535.0469,991.4673,3533.8184,992.501,3532.0918,992.501z M3532.2012,984.6572
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C3533.9639,985.3379,3533.1133,984.6572,3532.2012,984.6572z"
          />
          <path
            d="M3536.4355,987.0039c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3537.6514,990.312,3536.4355,988.9619,3536.4355,987.0039z M3537.542,986.3354
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3538.4297,984.6328,3537.7363,985.2529,3537.542,986.3354z"
          />
          <path
            d="M3543.4424,983.8179h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V983.8179z"
          />
          <path d="M3550.2549,981.4463h1.1924v1.2651h-1.1924V981.4463z M3551.3984,990.1782h-1.0947v-6.3604h1.0947V990.1782z" />
          <path
            d="M3553.3926,984.7544h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V984.7544z"
          />
          <path
            d="M3556.9209,987.0039c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3558.1367,990.312,3556.9209,988.9619,3556.9209,987.0039z M3558.0273,986.3354
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3558.915,984.6328,3558.2217,985.2529,3558.0273,986.3354z"
          />
          <path d="M3563.8789,981.4463h1.1924v1.2651h-1.1924V981.4463z M3565.0225,990.1782h-1.0947v-6.3604h1.0947V990.1782z" />
          <path
            d="M3567.0166,984.7544h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V984.7544z"
          />
          <path d="M3515.2744,1004.1782l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3515.2744z" />
          <path
            d="M3519.3125,1002.4634c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3520.249,1004.2876,3519.3125,1003.6064,3519.3125,1002.4634z M3523.2656,1000.9189l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1000.9189z"
          />
          <path
            d="M3526.21,997.8179h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V997.8179z"
          />
          <path
            d="M3535.2002,1001.0039c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3536.209,1004.312,3535.2002,1002.8042,3535.2002,1001.0039z
				 M3538.167,998.6572c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3539.9668,999.3018,3539.0674,998.6572,3538.167,998.6572z"
          />
          <path
            d="M3542.4141,1001.0039c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3543.6299,1004.312,3542.4141,1002.9619,3542.4141,1001.0039z M3543.5205,1000.3354
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3544.4082,998.6328,3543.7148,999.2529,3543.5205,1000.3354z"
          />
          <path
            d="M3497.1855,1016.208c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L3497.1855,1016.208z"
          />
          <path
            d="M3502.4531,1016.4634c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3503.3896,1018.2876,3502.4531,1017.6064,3502.4531,1016.4634z M3506.4062,1014.9189l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1014.9189z"
          />
          <path
            d="M3510.4453,1018.1782h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0312-1.0825c1.3135,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1018.1782z"
          />
          <path
            d="M3519.5088,1015.0039c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3520.7246,1018.312,3519.5088,1016.9619,3519.5088,1015.0039z M3520.6152,1014.3354
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3521.5029,1012.6328,3520.8096,1013.2529,3520.6152,1014.3354z"
          />
          <path
            d="M3526.5156,1011.8179h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1011.8179z"
          />
          <path d="M3534.4707,1018.1782h-1.0947v-8.7319h1.0947V1018.1782z" />
          <path
            d="M3535.8574,1015.0039c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3537.0732,1018.312,3535.8574,1016.9619,3535.8574,1015.0039z M3536.9639,1014.3354
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3537.8516,1012.6328,3537.1582,1013.2529,3536.9639,1014.3354z"
          />
          <path d="M3544.4941,1018.1782l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3544.4941z" />
          <path d="M3548.9824,1009.4463h1.1924v1.2651h-1.1924V1009.4463z M3550.126,1018.1782h-1.0947v-6.3604h1.0947V1018.1782z" />
          <path
            d="M3551.9502,1011.8179h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1011.8179z"
          />
          <path
            d="M3561.2441,1020.501c-1.3994,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6201-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C3564.1992,1019.4673,3562.9707,1020.501,3561.2441,1020.501z M3561.3535,1012.6572
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C3563.1162,1013.3379,3562.2656,1012.6572,3561.3535,1012.6572z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="82">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1290.66,814.645c0,25.2651-20.484,45.75-45.75,45.75c-25.269,0-45.75-20.4849-45.75-45.75
			c0-25.2659,20.481-45.75,45.75-45.75C1270.176,768.895,1290.66,789.3792,1290.66,814.645"
        />
        <g>
          <path
            d="M1205.6919,788.9395h3.2227c1.8486,0,2.8335,0.8149,2.8335,2.2744c0,1.1187-0.6812,1.6782-1.3501,1.9092
				c0.9854,0.2676,1.6421,1.0337,1.6421,2.1406c0,1.52-1.1069,2.4077-2.8701,2.4077h-3.478V788.9395z M1208.8169,792.6851
				c1.168,0,1.8125-0.4497,1.8125-1.374c0-0.9365-0.6445-1.3862-1.8125-1.3862h-1.9941v2.7603H1208.8169z M1206.8228,796.6865
				h2.2983c1.1675,0,1.7998-0.6084,1.7998-1.5083c0-0.8877-0.6323-1.4956-1.7998-1.4956h-2.2983V796.6865z"
          />
          <path
            d="M1212.248,794.4971c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1213.4644,797.8052,1212.248,796.4551,1212.248,794.4971z M1213.355,793.8286
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1214.2427,792.126,1213.5493,792.7461,1213.355,793.8286z"
          />
          <path
            d="M1218.6709,795.7012c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1218.6709,795.7012z"
          />
          <path
            d="M1226.2734,796.8442c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9731,0.4497c-0.3042,1.0581-1.2285,1.8608-2.5542,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1224.3643,795.9443,1225.1426,796.8442,1226.2734,796.8442z"
          />
          <path
            d="M1229.3032,788.9395h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V788.9395z"
          />
          <path d="M1235.3853,788.9395h1.1919v1.2651h-1.1919V788.9395z M1236.5288,797.6714h-1.0947v-6.3604h1.0947V797.6714z" />
          <path
            d="M1238.7183,794.2783l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V794.2783z"
          />
          <path
            d="M1244.4844,796.7471v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C1245.5664,797.8052,1244.8613,797.3794,1244.4844,796.7471z M1244.46,794.7285c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8608-0.9849,1.8608-2.3472c0-1.374-0.6689-2.3467-1.8608-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V794.7285z"
          />
          <path
            d="M1249.3872,795.9565c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1250.3237,797.7808,1249.3872,797.0996,1249.3872,795.9565z M1253.3394,794.4121l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V794.4121z"
          />
          <path
            d="M1255.0557,795.9565c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1255.9922,797.7808,1255.0557,797.0996,1255.0557,795.9565z M1259.0078,794.4121l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V794.4121z"
          />
          <path
            d="M1264.5186,792.3936c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V792.3936z"
          />
          <path
            d="M1264.9937,788.9395h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V788.9395z"
          />
          <path
            d="M1270.6865,794.4971c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1271.9028,797.8052,1270.6865,796.4551,1270.6865,794.4971z M1271.7935,793.8286
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1272.6812,792.126,1271.9878,792.7461,1271.7935,793.8286z"
          />
          <path d="M1276.915,788.9395h1.1919v1.2651h-1.1919V788.9395z M1278.0586,797.6714h-1.0947v-6.3604h1.0947V797.6714z" />
          <path
            d="M1278.7153,794.4971c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1279.7251,797.8052,1278.7153,796.2974,1278.7153,794.4971z
				 M1281.6826,792.1504c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1283.4829,792.7949,1282.583,792.1504,1281.6826,792.1504z"
          />
          <path d="M1213.1416,811.6714l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1213.1416z" />
          <path
            d="M1216.4512,809.9565c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1217.3877,811.7808,1216.4512,811.0996,1216.4512,809.9565z M1220.4033,808.4121l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V808.4121z"
          />
          <path
            d="M1222.6182,805.311h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V805.311z"
          />
          <path
            d="M1230.1484,808.4971c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1231.1582,811.8052,1230.1484,810.2974,1230.1484,808.4971z
				 M1233.1157,806.1504c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1234.916,806.7949,1234.0161,806.1504,1233.1157,806.1504z"
          />
          <path
            d="M1242.1167,811.6714h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V811.6714z"
          />
          <path
            d="M1248.2476,811.6714h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V811.6714z"
          />
          <path
            d="M1252.6274,806.3936c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V806.3936z"
          />
          <path d="M1257.7002,811.6714h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8394l-3.5996,4.5845h3.6362V811.6714z" />
          <path
            d="M1257.896,809.9565c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1258.8325,811.7808,1257.896,811.0996,1257.896,809.9565z M1261.8481,808.4121l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V808.4121z"
          />
          <path
            d="M1265.1577,811.6714h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V811.6714z"
          />
          <path
            d="M1273.4902,808.4971c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1274.7065,811.8052,1273.4902,810.4551,1273.4902,808.4971z M1274.5972,807.8286
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1275.4849,806.126,1274.7915,806.7461,1274.5972,807.8286z"
          />
          <path
            d="M1221.9131,820.2476h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V820.2476z"
          />
          <path
            d="M1228.4443,820.3936c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V820.3936z"
          />
          <path
            d="M1228.4209,823.9565c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1229.3574,825.7808,1228.4209,825.0996,1228.4209,823.9565z M1232.373,822.4121l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V822.4121z"
          />
          <path
            d="M1234.5884,819.311h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V819.311z"
          />
          <path
            d="M1240.8652,823.7012c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1240.8652,823.7012z"
          />
          <path
            d="M1246.9961,824.7471v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C1248.0781,825.8052,1247.373,825.3794,1246.9961,824.7471z M1246.9717,822.7285c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8608-0.9849,1.8608-2.3472c0-1.374-0.6689-2.3467-1.8608-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V822.7285z"
          />
          <path
            d="M1254.9146,825.8052c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1257.8696,824.4673,1256.6533,825.8052,1254.9146,825.8052z M1254.9146,824.8687
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1253.0659,823.9443,1253.7954,824.8687,1254.9146,824.8687z"
          />
          <path
            d="M1261.8237,820.3936c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V820.3936z"
          />
          <path
            d="M1262.4697,820.2476h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V820.2476z"
          />
          <path d="M1265.4131,821.6338h3.3203v0.9854h-3.3203V821.6338z" />
          <path
            d="M1211.1963,839.6714h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V839.6714z"
          />
          <path
            d="M1222.4844,839.8052c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1225.4395,838.4673,1224.2231,839.8052,1222.4844,839.8052z M1222.4844,838.8687
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1220.6357,837.9443,1221.3652,838.8687,1222.4844,838.8687z"
          />
          <path
            d="M1228.5298,841.9941c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3403,0.6206-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.6055,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C1231.4849,840.9604,1230.2568,841.9941,1228.5298,841.9941z M1228.6392,834.1504
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C1230.4028,834.8311,1229.5513,834.1504,1228.6392,834.1504z"
          />
          <path
            d="M1232.1436,836.4971c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1233.3599,839.8052,1232.1436,838.4551,1232.1436,836.4971z M1233.2505,835.8286
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1234.1382,834.126,1233.4448,834.7461,1233.2505,835.8286z"
          />
          <path d="M1239.5151,839.6714h-1.0947v-8.7319h1.0947V839.6714z" />
          <path d="M1240.561,830.9395h1.1919v1.2651h-1.1919V830.9395z M1241.7046,839.6714h-1.0947v-6.3604h1.0947V839.6714z" />
          <path
            d="M1242.7993,833.311h1.0947v6.8833c0,1.1309-0.7056,1.7026-1.7881,1.7026c-0.2554,0-0.4131-0.0122-0.6079-0.0366v-0.9487
				c0.1338,0.0244,0.2432,0.0366,0.4258,0.0366c0.6323,0,0.8755-0.3037,0.8755-0.9487V833.311z M1242.7505,830.9395h1.1919v1.2651
				h-1.1919V830.9395z"
          />
          <path
            d="M1246.0835,836.2783l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V836.2783z"
          />
          <path
            d="M1250.7554,830.9395h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V830.9395z"
          />
          <path
            d="M1256.4482,836.4971c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1257.6646,839.8052,1256.4482,838.4551,1256.4482,836.4971z M1257.5552,835.8286
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1258.4429,834.126,1257.7495,834.7461,1257.5552,835.8286z"
          />
          <path
            d="M1262.2871,836.4971c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1263.2969,839.8052,1262.2871,838.2974,1262.2871,836.4971z
				 M1265.2544,834.1504c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1267.0547,834.7949,1266.1548,834.1504,1265.2544,834.1504z"
          />
          <path
            d="M1268.7705,836.4971c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1269.9868,839.8052,1268.7705,838.4551,1268.7705,836.4971z M1269.8774,835.8286
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1270.7651,834.126,1270.0718,834.7461,1269.8774,835.8286z"
          />
          <path
            d="M1275.0474,833.311h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V833.311z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="81">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M2307.3589,1435.234c0,25.27-20.4839,45.75-45.75,45.75c-25.2688,0-45.75-20.48-45.75-45.75
			c0-25.265,20.4812-45.75,45.75-45.75C2286.875,1389.484,2307.3589,1409.969,2307.3589,1435.234"
        />
        <g>
          <path
            d="M2232.4248,1417.3604c1.1318,0,1.9463-0.6567,2.2627-1.7632l1.1055,0.3647c-0.5098,1.5078-1.7021,2.4321-3.3682,2.4321
				c-2.2617,0-3.9277-1.8364-3.9277-4.4995c0-2.6636,1.666-4.5,3.9277-4.5c1.666,0,2.8584,0.9243,3.3682,2.4326l-1.1055,0.3647
				c-0.3164-1.1069-1.1309-1.7637-2.2627-1.7637c-1.6172,0-2.7607,1.3989-2.7607,3.4663S2230.8076,1417.3604,2232.4248,1417.3604z"
          />
          <path
            d="M2239.1289,1418.394c-1.7402,0-2.9561-1.3379-2.9561-3.3081c0-1.9697,1.2158-3.3076,2.9561-3.3076
				c1.7383,0,2.9551,1.3379,2.9551,3.3076C2242.084,1417.0562,2240.8672,1418.394,2239.1289,1418.394z M2239.1289,1417.4575
				c1.1182,0,1.8477-0.9243,1.8477-2.3716c0-1.459-0.7295-2.3833-1.8477-2.3833c-1.1191,0-1.8496,0.9243-1.8496,2.3833
				C2237.2793,1416.5332,2238.0098,1417.4575,2239.1289,1417.4575z"
          />
          <path
            d="M2243.1064,1411.8999h1.0947v0.8633c0.3652-0.5229,0.9971-0.9849,1.8965-0.9849c1.3496,0,2.1172,0.9365,2.1172,2.2861
				v4.1958h-1.0957v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1411.8999z"
          />
          <path
            d="M2252.168,1417.4331c0.876,0,1.3984-0.5591,1.582-1.3496l0.9727,0.4497c-0.3047,1.0581-1.2285,1.8608-2.5547,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3262,0,2.2266,0.7661,2.5303,1.8242
				l-0.9482,0.4741c-0.1836-0.7783-0.7061-1.3496-1.582-1.3496c-1.1309,0-1.9082,0.8999-1.9082,2.3589
				C2250.2598,1416.5332,2251.0371,1417.4331,2252.168,1417.4331z"
          />
          <path
            d="M2260.6094,1418.2603h-1.0938v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8848,1.0093
				c-1.3623,0-2.1289-0.9365-2.1289-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3896,1.5083,1.3252,1.5083
				c0.791,0,1.5938-0.5962,1.5938-1.6543v-3.8306h1.0938V1418.2603z"
          />
          <path
            d="M2265.3555,1412.9824c-0.1836-0.0366-0.3291-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0957
				v-6.3604h1.0957v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3281,0.0122,0.4385,0.0244V1412.9824z"
          />
          <path
            d="M2269.4912,1412.9824c-0.1826-0.0366-0.3291-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3281,0.0122,0.4385,0.0244V1412.9824z"
          />
          <path
            d="M2269.8926,1415.0859c0-1.897,1.2051-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6143,1.8242C2271.1094,1418.394,2269.8926,1417.0439,2269.8926,1415.0859z M2271,1414.4175
				h3.4297c-0.0244-0.8999-0.5117-1.7026-1.6172-1.7026C2271.8877,1412.7148,2271.1943,1413.335,2271,1414.4175z"
          />
          <path
            d="M2276.5352,1411.8999h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8975-0.9849c1.3496,0,2.1152,0.9365,2.1152,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.377-1.5566-1.3262-1.5566c-0.791,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1411.8999z"
          />
          <path
            d="M2282.5928,1415.0859c0-1.812,1.0098-3.3076,2.7725-3.3076c0.8887,0,1.5938,0.4258,1.9707,1.0459v-3.2959h1.0938v8.7319
				h-1.0938v-0.9243c-0.377,0.6323-1.082,1.0581-1.9707,1.0581C2283.6025,1418.394,2282.5928,1416.8862,2282.5928,1415.0859z
				 M2285.5605,1412.7393c-1.1914,0-1.8613,0.9727-1.8613,2.3467c0,1.3623,0.6699,2.3472,1.8613,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2287.3604,1413.3838,2286.4609,1412.7393,2285.5605,1412.7393z"
          />
          <path
            d="M2289.4414,1415.0859c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7109,1.3501,2.7109,3.1133v0.3647h-4.5967
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2290.6582,1418.394,2289.4414,1417.0439,2289.4414,1415.0859z M2290.5488,1414.4175
				h3.4287c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C2291.4355,1412.7148,2290.7422,1413.335,2290.5488,1414.4175z"
          />
          <path
            d="M2233.8184,1431.3359v3.1133h-1.0938v-8.5493h1.0938v0.9243c0.377-0.6201,1.082-1.0459,1.9707-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C2234.9004,1432.394,2234.1953,1431.9683,2233.8184,1431.3359z M2233.7949,1429.3174c0,1.459,0.8994,2.1157,1.7988,2.1157
				c1.1924,0,1.8613-0.9849,1.8613-2.3472c0-1.374-0.6689-2.3467-1.8613-2.3467c-0.8994,0-1.7988,0.6445-1.7988,2.1279V1429.3174z"
          />
          <path
            d="M2242.8809,1426.9824c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9238,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.9004-1.1313,1.7637-1.1313c0.1699,0,0.3281,0.0122,0.4375,0.0244V1426.9824z"
          />
          <path d="M2243.6719,1423.5283h1.1914v1.2651h-1.1914V1423.5283z M2244.8154,1432.2603h-1.0947v-6.3604h1.0947V1432.2603z" />
          <path
            d="M2246.2754,1425.8999h1.0947v6.8833c0,1.1309-0.7061,1.7026-1.7881,1.7026c-0.2559,0-0.4141-0.0122-0.6074-0.0366
				v-0.9487c0.1328,0.0244,0.2422,0.0366,0.4258,0.0366c0.6318,0,0.875-0.3037,0.875-0.9487V1425.8999z M2246.2266,1423.5283h1.1914
				v1.2651h-1.1914V1423.5283z"
          />
          <path d="M2253.4268,1432.2603h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9258v0.8394l-3.5996,4.5845h3.6357V1432.2603z" />
          <path
            d="M2254.0488,1429.0859c0-1.897,1.2031-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8281,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C2255.2646,1432.394,2254.0488,1431.0439,2254.0488,1429.0859z M2255.1553,1428.4175
				h3.4287c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026C2256.043,1426.7148,2255.3496,1427.335,2255.1553,1428.4175z"
          />
          <path
            d="M2260.6904,1425.8999h1.0947v0.8633c0.3652-0.5229,0.9971-0.9849,1.8965-0.9849c1.3496,0,2.1172,0.9365,2.1172,2.2861
				v4.1958h-1.0957v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1425.8999z"
          />
          <path d="M2271.0176,1432.2603l-2.3828-6.3604h1.1543l1.7881,5.0225l1.7627-5.0225h1.1445l-2.3848,6.3604H2271.0176z" />
          <path
            d="M2277.708,1432.394c-1.7393,0-2.9561-1.3379-2.9561-3.3081c0-1.9697,1.2168-3.3076,2.9561-3.3076
				s2.9551,1.3379,2.9551,3.3076C2280.6631,1431.0562,2279.4473,1432.394,2277.708,1432.394z M2277.708,1431.4575
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2275.8594,1430.5332,2276.5889,1431.4575,2277.708,1431.4575z"
          />
          <path
            d="M2284.2041,1432.394c-1.7393,0-2.9561-1.3379-2.9561-3.3081c0-1.9697,1.2168-3.3076,2.9561-3.3076
				s2.9551,1.3379,2.9551,3.3076C2287.1592,1431.0562,2285.9434,1432.394,2284.2041,1432.394z M2284.2041,1431.4575
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2282.3555,1430.5332,2283.085,1431.4575,2284.2041,1431.4575z"
          />
          <path
            d="M2291.4785,1426.9824c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9238,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.9004-1.1313,1.7637-1.1313c0.1699,0,0.3281,0.0122,0.4375,0.0244V1426.9824z"
          />
          <path
            d="M2236.0332,1443.0859c0-1.812,1.0098-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2237.043,1446.394,2236.0332,1444.8862,2236.0332,1443.0859z
				 M2239,1440.7393c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8008-0.6567,1.8008-2.1157v-0.4502C2240.8008,1441.3838,2239.9004,1440.7393,2239,1440.7393z"
          />
          <path
            d="M2248.3662,1446.2603h-1.0947v-0.8755c-0.3652,0.5352-0.9844,1.0093-1.8848,1.0093
				c-1.3613,0-2.1289-0.9365-2.1289-2.2861v-4.208h1.0957v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083
				c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1446.2603z"
          />
          <path
            d="M2254.8613,1446.2603h-1.0938v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8848,1.0093
				c-1.3623,0-2.1289-0.9365-2.1289-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3896,1.5083,1.3252,1.5083
				c0.791,0,1.5938-0.5962,1.5938-1.6543v-3.8306h1.0938V1446.2603z"
          />
          <path
            d="M2259.6074,1440.9824c-0.1836-0.0366-0.3291-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0957
				v-6.3604h1.0957v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3281,0.0122,0.4385,0.0244V1440.9824z"
          />
          <path d="M2265.0439,1446.2603h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9258v0.8394l-3.5996,4.5845h3.6357V1446.2603z" />
          <path
            d="M2265.6055,1444.5454c0-1.2646,0.9844-1.7876,2.4072-2.0796l1.5439-0.3159v-0.2676c0-0.73-0.4004-1.1431-1.252-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0342-0.2676c0.3047-1.0337,1.2412-1.7754,2.543-1.7754
				c1.4219,0,2.2979,0.6934,2.2979,2.0552v3.1011c0,0.4136,0.2549,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5312-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.957,0.8267
				C2266.541,1446.3696,2265.6055,1445.6885,2265.6055,1444.5454z M2269.5566,1443.001l-1.2402,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7266-0.5718,1.7266-1.3862
				V1443.001z"
          />
          <path
            d="M2273.2324,1446.2603h-1.0957v-6.3604h1.0957v0.8633c0.3398-0.5229,0.9473-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3516-0.5229,1.0332-1.0825,2.0303-1.0825c1.3135,0,2.1045,0.9365,2.1045,2.2861v4.1958
				h-1.0957v-3.9038c0-0.8877-0.3643-1.5566-1.2637-1.5566c-0.7305,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0957v-3.9038
				c0-0.8877-0.3643-1.5566-1.2637-1.5566c-0.7305,0-1.5078,0.6201-1.5078,1.6538V1446.2603z"
          />
          <path
            d="M2281.9297,1443.0859c0-1.897,1.2031-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8281,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C2283.1455,1446.394,2281.9297,1445.0439,2281.9297,1443.0859z M2283.0361,1442.4175
				h3.4287c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026C2283.9238,1440.7148,2283.2305,1441.335,2283.0361,1442.4175z"
          />
          <path d="M2243.5254,1460.2603l-2.3828-6.3604h1.1553l1.7881,5.0225l1.7637-5.0225h1.1426l-2.3838,6.3604H2243.5254z" />
          <path
            d="M2250.2168,1460.394c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				c1.7383,0,2.9551,1.3379,2.9551,3.3076C2253.1719,1459.0562,2251.9551,1460.394,2250.2168,1460.394z M2250.2168,1459.4575
				c1.1191,0,1.8477-0.9243,1.8477-2.3716c0-1.459-0.7285-2.3833-1.8477-2.3833s-1.8486,0.9243-1.8486,2.3833
				C2248.3682,1458.5332,2249.0977,1459.4575,2250.2168,1459.4575z"
          />
          <path
            d="M2253.7568,1457.0859c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7129,1.3501,2.7129,3.1133v0.3647h-4.5977
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C2254.9727,1460.394,2253.7568,1459.0439,2253.7568,1457.0859z M2254.8633,1456.4175
				h3.4297c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C2255.752,1454.7148,2255.0586,1455.335,2254.8633,1456.4175z"
          />
          <path
            d="M2259.9609,1457.0859c0-1.812,1.0098-3.3076,2.7734-3.3076c0.8867,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.083,1.0581-1.9697,1.0581C2260.9707,1460.394,2259.9609,1458.8862,2259.9609,1457.0859z
				 M2262.9277,1454.7393c-1.1914,0-1.8594,0.9727-1.8594,2.3467c0,1.3623,0.668,2.3472,1.8594,2.3472
				c0.9004,0,1.8008-0.6567,1.8008-2.1157v-0.4502C2264.7285,1455.3838,2263.8281,1454.7393,2262.9277,1454.7393z"
          />
          <path d="M2267.1982,1451.5283h1.1924v1.2651h-1.1924V1451.5283z M2268.3418,1460.2603h-1.0947v-6.3604h1.0947V1460.2603z" />
          <path
            d="M2269.8018,1453.8999h1.0947v0.8633c0.3652-0.5229,0.9971-0.9849,1.8965-0.9849c1.3496,0,2.1172,0.9365,2.1172,2.2861
				v4.1958h-1.0957v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1453.8999z"
          />
          <path
            d="M2278.7305,1462.583c-1.3984,0-2.4199-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8633,1.2891,1.752,1.2891
				c1.1191,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C2281.6855,1461.5493,2280.457,1462.583,2278.7305,1462.583z M2278.8398,1454.7393
				c-1.1562,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7051,2.2134,1.8613,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C2280.6035,1455.4199,2279.752,1454.7393,2278.8398,1454.7393z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="80">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2482.0549,1437.0551c0,25.2649-20.481,45.75-45.75,45.75c-25.2659,0-45.75-20.4851-45.75-45.75
			c0-25.2661,20.4841-45.75,45.75-45.75C2461.574,1391.3051,2482.0549,1411.7889,2482.0549,1437.0551"
        />
        <g>
          <path
            d="M2398.6797,1411.3496h3.2227c1.8486,0,2.833,0.8149,2.833,2.2744c0,1.1187-0.6807,1.6782-1.3496,1.9092
				c0.9854,0.2676,1.6416,1.0337,1.6416,2.1406c0,1.52-1.1064,2.4077-2.8701,2.4077h-3.4775V1411.3496z M2401.8047,1415.0952
				c1.168,0,1.8125-0.4497,1.8125-1.374c0-0.9365-0.6445-1.3862-1.8125-1.3862h-1.9941v2.7603H2401.8047z M2399.8105,1419.0967
				h2.2988c1.167,0,1.7998-0.6084,1.7998-1.5083c0-0.8877-0.6328-1.4956-1.7998-1.4956h-2.2988V1419.0967z"
          />
          <path
            d="M2405.6016,1416.9072c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2406.8174,1420.2153,2405.6016,1418.8652,2405.6016,1416.9072z M2406.708,1416.2388
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2407.5957,1414.5361,2406.9023,1415.1562,2406.708,1416.2388z"
          />
          <path
            d="M2412.4141,1414.6577h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1414.6577z"
          />
          <path
            d="M2415.5156,1418.3667c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2416.4521,1420.1909,2415.5156,1419.5098,2415.5156,1418.3667z M2419.4688,1416.8223l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1416.8223z"
          />
          <path
            d="M2421.5488,1418.3667c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2422.4854,1420.1909,2421.5488,1419.5098,2421.5488,1418.3667z M2425.502,1416.8223l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1416.8223z"
          />
          <path d="M2429.1768,1420.0815h-1.0947v-8.7319h1.0947V1420.0815z" />
          <path
            d="M2431.7305,1419.1572v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C2432.8125,1420.2153,2432.1074,1419.7896,2431.7305,1419.1572z M2431.7061,1417.1387c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1417.1387z"
          />
          <path
            d="M2436.9971,1418.3667c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2437.9336,1420.1909,2436.9971,1419.5098,2436.9971,1418.3667z M2440.9502,1416.8223l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1416.8223z"
          />
          <path
            d="M2443.0303,1418.3667c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2443.9668,1420.1909,2443.0303,1419.5098,2443.0303,1418.3667z M2446.9834,1416.8223l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1416.8223z"
          />
          <path
            d="M2452.8594,1414.8037c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1414.8037z"
          />
          <path
            d="M2453.6992,1411.3496h1.0947v3.2349c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1411.3496z"
          />
          <path
            d="M2459.7568,1416.9072c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2460.9727,1420.2153,2459.7568,1418.8652,2459.7568,1416.9072z
				 M2460.8633,1416.2388h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026
				C2461.751,1414.5361,2461.0576,1415.1562,2460.8633,1416.2388z"
          />
          <path d="M2466.3506,1411.3496h1.1924v1.2651h-1.1924V1411.3496z M2467.4941,1420.0815h-1.0947v-6.3604h1.0947V1420.0815z" />
          <path
            d="M2468.5156,1416.9072c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2469.5244,1420.2153,2468.5156,1418.7075,2468.5156,1416.9072z
				 M2471.4824,1414.5605c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2473.2822,1415.2051,2472.3828,1414.5605,2471.4824,1414.5605z"
          />
          <path d="M2402.6152,1434.0815l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2402.6152z" />
          <path
            d="M2406.2881,1432.3667c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2407.2246,1434.1909,2406.2881,1433.5098,2406.2881,1432.3667z M2410.2412,1430.8223l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1430.8223z"
          />
          <path
            d="M2412.8213,1427.7212h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1427.7212z"
          />
          <path
            d="M2421.0811,1430.9072c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2422.0898,1434.2153,2421.0811,1432.7075,2421.0811,1430.9072z
				 M2424.0479,1428.5605c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2425.8477,1429.2051,2424.9482,1428.5605,2424.0479,1428.5605z"
          />
          <path
            d="M2433.415,1434.0815h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1434.0815z"
          />
          <path
            d="M2439.9102,1434.0815h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093
				c-1.3623,0-2.1279-0.9365-2.1279-2.2861v-4.208h1.0938v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083
				c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1434.0815z"
          />
          <path
            d="M2444.6553,1428.8037c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1428.8037z"
          />
          <path d="M2450.0918,1434.0815h-5.0098v-0.8271l3.6719-4.5967h-3.624v-0.9365h4.9258v0.8394l-3.5996,4.5845h3.6357V1434.0815z" />
          <path
            d="M2450.6533,1432.3667c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2451.5898,1434.1909,2450.6533,1433.5098,2450.6533,1432.3667z M2454.6064,1430.8223l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1430.8223z"
          />
          <path
            d="M2456.6865,1432.3667c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2457.623,1434.1909,2456.6865,1433.5098,2456.6865,1432.3667z M2460.6396,1430.8223l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1430.8223z"
          />
          <path
            d="M2464.3135,1434.0815h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0312-1.0825c1.3135,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1434.0815z"
          />
          <path
            d="M2399.9268,1450.4043c-1.3994,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C2402.8818,1449.3706,2401.6533,1450.4043,2399.9268,1450.4043z M2400.0361,1442.5605
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C2401.7988,1443.2412,2400.9482,1442.5605,2400.0361,1442.5605z"
          />
          <path
            d="M2403.9053,1444.9072c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2405.1211,1448.2153,2403.9053,1446.8652,2403.9053,1444.9072z
				 M2405.0117,1444.2388h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026
				C2405.8994,1442.5361,2405.2061,1443.1562,2405.0117,1444.2388z"
          />
          <path
            d="M2411.6426,1447.1572v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C2412.7246,1448.2153,2412.0195,1447.7896,2411.6426,1447.1572z M2411.6182,1445.1387c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1445.1387z"
          />
          <path
            d="M2420.7041,1442.8037c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1442.8037z"
          />
          <path
            d="M2424.0615,1448.2153c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2427.0166,1446.8774,2425.8008,1448.2153,2424.0615,1448.2153z M2424.0615,1447.2788
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2422.2129,1446.3545,2422.9424,1447.2788,2424.0615,1447.2788z"
          />
          <path
            d="M2427.6035,1444.9072c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2428.6123,1448.2153,2427.6035,1446.7075,2427.6035,1444.9072z
				 M2430.5703,1442.5605c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2432.3701,1443.2051,2431.4707,1442.5605,2430.5703,1442.5605z"
          />
          <path
            d="M2439.9365,1448.0815h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093
				c-1.3623,0-2.1279-0.9365-2.1279-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083
				c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1448.0815z"
          />
          <path
            d="M2443.9512,1447.2544c0.875,0,1.3984-0.5591,1.5801-1.3496l0.9736,0.4497c-0.3047,1.0581-1.2285,1.8608-2.5537,1.8608
				c-1.7764,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7661,2.5293,1.8242
				l-0.9492,0.4741c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9102,0.8999-1.9102,2.3589
				C2442.041,1446.3545,2442.8193,1447.2544,2443.9512,1447.2544z"
          />
          <path
            d="M2446.9072,1444.9072c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2448.123,1448.2153,2446.9072,1446.8652,2446.9072,1444.9072z M2448.0137,1444.2388
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2448.9014,1442.5361,2448.208,1443.1562,2448.0137,1444.2388z"
          />
          <path
            d="M2453.1123,1444.9072c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2454.3281,1448.2153,2453.1123,1446.8652,2453.1123,1444.9072z
				 M2454.2188,1444.2388h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026
				C2455.1064,1442.5361,2454.4131,1443.1562,2454.2188,1444.2388z"
          />
          <path
            d="M2463.0498,1442.8037c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1442.8037z"
          />
          <path
            d="M2463.4521,1444.9072c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2464.4609,1448.2153,2463.4521,1446.7075,2463.4521,1444.9072z
				 M2466.4189,1442.5605c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2468.2188,1443.2051,2467.3193,1442.5605,2466.4189,1442.5605z"
          />
          <path
            d="M2470.3018,1444.9072c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2471.5176,1448.2153,2470.3018,1446.8652,2470.3018,1444.9072z
				 M2471.4082,1444.2388h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026
				C2472.2959,1442.5361,2471.6025,1443.1562,2471.4082,1444.2388z"
          />
          <path d="M2418.2217,1462.0815l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2418.2217z" />
          <path
            d="M2424.9111,1462.2153c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2427.8662,1460.8774,2426.6504,1462.2153,2424.9111,1462.2153z M2424.9111,1461.2788
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2423.0625,1460.3545,2423.792,1461.2788,2424.9111,1461.2788z"
          />
          <path
            d="M2428.4521,1458.9072c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2429.668,1462.2153,2428.4521,1460.8652,2428.4521,1458.9072z M2429.5586,1458.2388
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2430.4463,1456.5361,2429.7529,1457.1562,2429.5586,1458.2388z"
          />
          <path
            d="M2434.6562,1458.9072c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2435.665,1462.2153,2434.6562,1460.7075,2434.6562,1458.9072z
				 M2437.623,1456.5605c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2439.4229,1457.2051,2438.5234,1456.5605,2437.623,1456.5605z"
          />
          <path d="M2441.8936,1453.3496h1.1924v1.2651h-1.1924V1453.3496z M2443.0371,1462.0815h-1.0947v-6.3604h1.0947V1462.0815z" />
          <path
            d="M2444.4971,1455.7212h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1455.7212z"
          />
          <path
            d="M2453.4248,1464.4043c-1.3994,0-2.4209-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C2456.3799,1463.3701,2455.1514,1464.4043,2453.4248,1464.4043z M2453.5342,1456.5605
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C2455.2969,1457.2412,2454.4463,1456.5605,2453.5342,1456.5605z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="79">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M2165.2461,629.0081c0,25.2651-20.4841,45.75-45.75,45.75c-25.269,0-45.75-20.4849-45.75-45.75
			c0-25.2659,20.481-45.75,45.75-45.75C2144.762,583.2581,2165.2461,603.7422,2165.2461,629.0081"
        />
        <g>
          <path
            d="M2087.5156,602.5054c0.5469,1.0454,1.5322,1.6538,2.6631,1.6538s2.0068-0.5596,2.0068-1.5566
				c0-1.0581-1.0098-1.2407-2.335-1.5566c-1.374-0.3286-2.7363-0.7056-2.7363-2.3472c0-1.5322,1.3252-2.5298,2.9795-2.5298
				c1.5205,0,2.6152,0.73,3.1377,1.7148l-0.8877,0.7056c-0.4502-0.8394-1.1436-1.4106-2.2744-1.4106
				c-1.0576,0-1.8242,0.5835-1.8242,1.4229c0,0.9238,0.8154,1.1187,2.043,1.4106c1.4473,0.3403,3.041,0.6445,3.041,2.4932
				c0,1.6294-1.4473,2.6631-3.1748,2.6631c-1.5078,0-2.9551-0.7539-3.5508-1.9336L2087.5156,602.5054z"
          />
          <path
            d="M2096.8086,604.2075c0.876,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3037,1.0581-1.2275,1.8608-2.5537,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3262,0,2.2256,0.7661,2.5303,1.8242
				l-0.9492,0.4741c-0.1826-0.7783-0.7051-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C2094.8994,603.3076,2095.6777,604.2075,2096.8086,604.2075z"
          />
          <path
            d="M2100.2031,596.3027h1.0947v3.2349c0.3652-0.5229,0.9971-0.9849,1.8975-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V596.3027z"
          />
          <path
            d="M2106.2012,603.3198c0-1.2646,0.9844-1.7876,2.4072-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2764,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.542-1.7754
				c1.4229,0,2.2979,0.6934,2.2979,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6816,0.4502v0.8755
				c-0.9736,0.1216-1.5322-0.1094-1.666-0.7173c-0.3896,0.4985-1.0947,0.8267-1.958,0.8267
				C2107.1377,605.144,2106.2012,604.4629,2106.2012,603.3198z M2110.1533,601.7754l-1.2402,0.2676
				c-0.9736,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9355,0,1.7266-0.5718,1.7266-1.3862
				V601.7754z"
          />
          <path
            d="M2113.8271,604.1104v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7734,1.4956,2.7734,3.3076c0,1.8003-1.0098,3.3081-2.7734,3.3081
				C2114.9092,605.1685,2114.2041,604.7427,2113.8271,604.1104z M2113.8027,602.0918c0,1.459,0.9004,2.1157,1.7998,2.1157
				c1.1924,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.668-2.3467-1.8604-2.3467c-0.8994,0-1.7998,0.6445-1.7998,2.1279V602.0918z"
          />
          <path
            d="M2122.8896,599.7568c-0.1826-0.0366-0.3291-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3281,0.0122,0.4385,0.0244V599.7568z"
          />
          <path
            d="M2128.7764,605.0347h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3613,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083c0.791,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V605.0347z"
          />
          <path d="M2130.1768,596.3027h1.1914v1.2651h-1.1914V596.3027z M2131.3203,605.0347h-1.0947v-6.3604h1.0947V605.0347z" />
          <path
            d="M2133.875,605.0347h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0303-1.0825c1.3135,0,2.1045,0.9365,2.1045,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V605.0347z"
          />
          <path
            d="M2143.1807,599.6108h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5957,0.0977-0.9609,0.0977
				c-1.082,0-1.7021-0.3652-1.7021-1.5811V599.6108z"
          />
          <path
            d="M2146.3428,601.8604c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C2147.5596,605.1685,2146.3428,603.8184,2146.3428,601.8604z M2147.4502,601.1919
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C2148.3379,599.4893,2147.6445,600.1094,2147.4502,601.1919z"
          />
          <path
            d="M2089.1582,613.6108h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5957,0.0977-0.9609,0.0977
				c-1.082,0-1.7021-0.3652-1.7021-1.5811V613.6108z"
          />
          <path
            d="M2095.2764,619.1685c-1.7393,0-2.9561-1.3379-2.9561-3.3081c0-1.9697,1.2168-3.3076,2.9561-3.3076
				c1.7383,0,2.9551,1.3379,2.9551,3.3076C2098.2314,617.8306,2097.0146,619.1685,2095.2764,619.1685z M2095.2764,618.2319
				c1.1182,0,1.8477-0.9243,1.8477-2.3716c0-1.459-0.7295-2.3833-1.8477-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2093.4277,617.3076,2094.1572,618.2319,2095.2764,618.2319z"
          />
          <path
            d="M2098.8164,615.8604c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2100.0322,619.1685,2098.8164,617.8184,2098.8164,615.8604z M2099.9229,615.1919
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C2100.8105,613.4893,2100.1172,614.1094,2099.9229,615.1919z"
          />
          <path
            d="M2107.8906,621.3574c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2061,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6201-1.0576,1.0581-1.9453,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.875,0,1.6045,0.438,1.9453,1.0581v-0.9365
				h1.0947v5.7886C2110.8457,620.3237,2109.6172,621.3574,2107.8906,621.3574z M2108,613.5137
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C2109.7637,614.1943,2108.9121,613.5137,2108,613.5137z"
          />
          <path
            d="M2111.8682,615.8604c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C2113.085,619.1685,2111.8682,617.8184,2111.8682,615.8604z M2112.9756,615.1919
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C2113.8633,613.4893,2113.1699,614.1094,2112.9756,615.1919z"
          />
          <path
            d="M2122.4629,612.6743l1.3135,4.7427l1.3252-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3252-4.7188l-1.3262,4.7188h-0.96
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H2122.4629z"
          />
          <path
            d="M2126.4658,615.8604c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C2127.6826,619.1685,2126.4658,617.8184,2126.4658,615.8604z M2127.5732,615.1919
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C2128.4609,613.4893,2127.7676,614.1094,2127.5732,615.1919z"
          />
          <path d="M2137.7051,619.0347h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9248v0.8394l-3.5996,4.5845h3.6367V619.0347z" />
          <path
            d="M2138.3262,615.8604c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C2139.543,619.1685,2138.3262,617.8184,2138.3262,615.8604z M2139.4336,615.1919
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C2140.3213,613.4893,2139.6279,614.1094,2139.4336,615.1919z"
          />
          <path
            d="M2144.9678,612.6743h1.0947v0.8633c0.3652-0.5229,0.9971-0.9849,1.8975-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V612.6743z"
          />
          <path
            d="M2083.2949,631.3198c0-1.2646,0.9844-1.7876,2.4072-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2764,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.542-1.7754
				c1.4229,0,2.2979,0.6934,2.2979,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6816,0.4502v0.8755
				c-0.9736,0.1216-1.5322-0.1094-1.666-0.7173c-0.3896,0.4985-1.0947,0.8267-1.958,0.8267
				C2084.2314,633.144,2083.2949,632.4629,2083.2949,631.3198z M2087.2471,629.7754l-1.2402,0.2676
				c-0.9736,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9355,0,1.7266-0.5718,1.7266-1.3862
				V629.7754z"
          />
          <path
            d="M2089.3281,631.3198c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5439-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.252-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0342-0.2676c0.3047-1.0337,1.2412-1.7754,2.542-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2549,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0947,0.8267-1.958,0.8267
				C2090.2646,633.144,2089.3281,632.4629,2089.3281,631.3198z M2093.2803,629.7754l-1.2402,0.2676
				c-0.9727,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9365,0,1.7266-0.5718,1.7266-1.3862
				V629.7754z"
          />
          <path
            d="M2095.8604,626.6743h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V626.6743z"
          />
          <path
            d="M2104.1201,629.8604c0-1.812,1.0098-3.3076,2.7725-3.3076c0.8877,0,1.5938,0.4258,1.9707,1.0459v-3.2959h1.0938v8.7319
				h-1.0938v-0.9243c-0.377,0.6323-1.083,1.0581-1.9707,1.0581C2105.1299,633.1685,2104.1201,631.6606,2104.1201,629.8604z
				 M2107.0869,627.5137c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8008-0.6567,1.8008-2.1157v-0.4502C2108.8877,628.1582,2107.9873,627.5137,2107.0869,627.5137z"
          />
          <path
            d="M2116.4531,633.0347h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3613,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083c0.791,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V633.0347z"
          />
          <path
            d="M2122.9492,633.0347h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3613,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083c0.791,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V633.0347z"
          />
          <path
            d="M2127.6943,627.7568c-0.1826-0.0366-0.3291-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3281,0.0122,0.4385,0.0244V627.7568z"
          />
          <path d="M2133.1318,633.0347h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9248v0.8394l-3.5996,4.5845h3.6367V633.0347z" />
          <path
            d="M2133.6924,631.3198c0-1.2646,0.9844-1.7876,2.4072-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2764,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.542-1.7754
				c1.4229,0,2.2979,0.6934,2.2979,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6816,0.4502v0.8755
				c-0.9736,0.1216-1.5322-0.1094-1.666-0.7173c-0.3896,0.4985-1.0947,0.8267-1.958,0.8267
				C2134.6289,633.144,2133.6924,632.4629,2133.6924,631.3198z M2137.6445,629.7754l-1.2402,0.2676
				c-0.9736,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9355,0,1.7266-0.5718,1.7266-1.3862
				V629.7754z"
          />
          <path
            d="M2141.3193,633.0347h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0303-1.0825c1.3135,0,2.1045,0.9365,2.1045,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V633.0347z"
          />
          <path
            d="M2150.0166,629.8604c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C2151.2334,633.1685,2150.0166,631.8184,2150.0166,629.8604z M2151.124,629.1919
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C2152.0117,627.4893,2151.3184,628.1094,2151.124,629.1919z"
          />
          <path d="M2095.6045,647.0347l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2095.6045z" />
          <path
            d="M2102.2949,647.1685c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2105.25,645.8306,2104.0342,647.1685,2102.2949,647.1685z M2102.2949,646.2319
				c1.1191,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7295-2.3833-1.8486-2.3833s-1.8486,0.9243-1.8486,2.3833
				C2100.4463,645.3076,2101.1758,646.2319,2102.2949,646.2319z"
          />
          <path
            d="M2105.835,643.8604c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C2107.0518,647.1685,2105.835,645.8184,2105.835,643.8604z M2106.9424,643.1919
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C2107.8301,641.4893,2107.1367,642.1094,2106.9424,643.1919z"
          />
          <path
            d="M2112.0391,643.8604c0-1.812,1.0098-3.3076,2.7734-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2113.0488,647.1685,2112.0391,645.6606,2112.0391,643.8604z
				 M2115.0068,641.5137c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2116.8066,642.1582,2115.9072,641.5137,2115.0068,641.5137z"
          />
          <path d="M2119.2764,638.3027h1.1924v1.2651h-1.1924V638.3027z M2120.4199,647.0347h-1.0947v-6.3604h1.0947V647.0347z" />
          <path
            d="M2121.8799,640.6743h1.0947v0.8633c0.3652-0.5229,0.9971-0.9849,1.8975-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V640.6743z"
          />
          <path
            d="M2130.8086,649.3574c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2061,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6201-1.0576,1.0581-1.9453,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.875,0,1.6045,0.438,1.9453,1.0581v-0.9365
				h1.0947v5.7886C2133.7637,648.3237,2132.5352,649.3574,2130.8086,649.3574z M2130.918,641.5137
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C2132.6816,642.1943,2131.8301,641.5137,2130.918,641.5137z"
          />
          <path d="M2137.3779,638.3027h1.1914v1.2651h-1.1914V638.3027z M2138.5215,647.0347h-1.0947v-6.3604h1.0947V647.0347z" />
          <path
            d="M2139.9805,640.6743h1.0947v0.8633c0.3652-0.5229,0.9971-0.9849,1.8975-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V640.6743z"
          />
          <path
            d="M2084.249,659.0645c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7412,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0801,0.5596,2.457,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9248-1.0337-1.7266-1.0337
				c-0.7305,0-1.2168,0.3647-1.2168,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1074,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7979-1.4839L2084.249,659.0645z"
          />
          <path
            d="M2094.6973,661.0347h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3613,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083c0.791,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V661.0347z"
          />
          <path
            d="M2097.2412,660.1104v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C2098.3232,661.1685,2097.6182,660.7427,2097.2412,660.1104z M2097.2168,658.0918c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V658.0918z"
          />
          <path
            d="M2102.5693,657.8604c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2103.7852,661.1685,2102.5693,659.8184,2102.5693,657.8604z M2103.6758,657.1919
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C2104.5635,655.4893,2103.8701,656.1094,2103.6758,657.1919z"
          />
          <path
            d="M2112.5068,655.7568c-0.1826-0.0366-0.3291-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3281,0.0122,0.4385,0.0244V655.7568z"
          />
          <path
            d="M2114.4414,661.0347h-1.0947v-6.3604h1.0947v0.8633c0.3408-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0342-1.0825,2.0312-1.0825c1.3135,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V661.0347z"
          />
          <path
            d="M2123.0791,659.3198c0-1.2646,0.9844-1.7876,2.4072-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2764,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.542-1.7754
				c1.4229,0,2.2979,0.6934,2.2979,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6816,0.4502v0.8755
				c-0.9736,0.1216-1.5322-0.1094-1.666-0.7173c-0.3896,0.4985-1.0947,0.8267-1.958,0.8267
				C2124.0156,661.144,2123.0791,660.4629,2123.0791,659.3198z M2127.0312,657.7754l-1.2402,0.2676
				c-0.9736,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9355,0,1.7266-0.5718,1.7266-1.3862
				V657.7754z"
          />
          <path
            d="M2132.9062,655.7568c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9238,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.9004-1.1313,1.7637-1.1313c0.1699,0,0.3281,0.0122,0.4375,0.0244V655.7568z"
          />
          <path
            d="M2134.8418,657.6416l2.8213-2.9673h1.3613l-2.4805,2.5054l2.6875,3.855h-1.2764l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V657.6416z"
          />
          <path
            d="M2140.0479,655.6108h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3291,0.7905,0.8877,0.7905c0.2559,0,0.4385-0.0122,0.6934-0.0728v0.936c-0.2793,0.061-0.5957,0.0977-0.96,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V655.6108z"
          />
          <path
            d="M2143.2109,657.8604c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2144.4268,661.1685,2143.2109,659.8184,2143.2109,657.8604z M2144.3174,657.1919
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C2145.2051,655.4893,2144.5117,656.1094,2144.3174,657.1919z"
          />
          <path
            d="M2149.8525,654.6743h1.0947v0.8633c0.3652-0.5229,0.9971-0.9849,1.8975-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V654.6743z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="78">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M2275.3201,329.7031c0,25.2659-20.4841,45.75-45.75,45.75c-25.269,0-45.75-20.4841-45.75-45.75
			c0-25.269,20.481-45.75,45.75-45.75C2254.8359,283.9531,2275.3201,304.4341,2275.3201,329.7031"
        />
        <g>
          <path
            d="M2205.5083,312.7295h-1.1675v-8.7319h3.332c1.8486,0,3.0283,0.8638,3.0283,2.5298c0,1.6416-1.1797,2.5415-3.0283,2.5415
				h-2.1646V312.7295z M2207.6118,308.0596c1.2407,0,1.9331-0.5474,1.9331-1.5322c0-0.9854-0.6924-1.52-1.9331-1.52h-2.1035v3.0522
				H2207.6118z"
          />
          <path
            d="M2211.4199,309.5552c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C2212.6367,312.8633,2211.4199,311.5132,2211.4199,309.5552z M2212.5273,308.8867
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C2213.415,307.1841,2212.7217,307.8042,2212.5273,308.8867z"
          />
          <path
            d="M2221.7227,307.4517c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9238,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.9004-1.1313,1.7637-1.1313c0.1699,0,0.3281,0.0122,0.4375,0.0244V307.4517z"
          />
          <path
            d="M2225.4941,311.9023c0.875,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3037,1.0581-1.2285,1.8608-2.5537,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7661,2.5293,1.8242
				l-0.9482,0.4741c-0.1826-0.7783-0.7061-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C2223.585,311.0024,2224.3633,311.9023,2225.4941,311.9023z"
          />
          <path
            d="M2228.8154,309.5552c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C2230.0322,312.8633,2228.8154,311.5132,2228.8154,309.5552z M2229.9229,308.8867
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C2230.8105,307.1841,2230.1172,307.8042,2229.9229,308.8867z"
          />
          <path
            d="M2236.917,311.8052v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7734,1.4956,2.7734,3.3076c0,1.8003-1.0098,3.3081-2.7734,3.3081
				C2237.999,312.8633,2237.2939,312.4375,2236.917,311.8052z M2236.8926,309.7866c0,1.459,0.9004,2.1157,1.7998,2.1157
				c1.1924,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.668-2.3467-1.8604-2.3467c-0.8994,0-1.7998,0.6445-1.7998,2.1279V309.7866z"
          />
          <path
            d="M2243.2188,307.3057h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5957,0.0977-0.9609,0.0977
				c-1.082,0-1.7021-0.3652-1.7021-1.5811V307.3057z"
          />
          <path d="M2247.1348,303.9976h1.1924v1.2651h-1.1924V303.9976z M2248.2783,312.7295h-1.0947v-6.3604h1.0947V312.7295z" />
          <path
            d="M2249.665,309.5552c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C2250.8818,312.8633,2249.665,311.5132,2249.665,309.5552z M2250.7725,308.8867
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C2251.6602,307.1841,2250.9668,307.8042,2250.7725,308.8867z"
          />
          <path d="M2197.1387,326.7295l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H2197.1387z" />
          <path
            d="M2201.1782,325.0146c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C2202.1147,326.8389,2201.1782,326.1577,2201.1782,325.0146z M2205.1304,323.4702l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V323.4702z"
          />
          <path
            d="M2208.0752,320.3691h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V320.3691z"
          />
          <path
            d="M2221.4551,320.3691l1.3135,4.7427l1.3252-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3252-4.7188l-1.3262,4.7188h-0.96
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H2221.4551z"
          />
          <path d="M2226.2119,317.9976h1.1924v1.2651h-1.1924V317.9976z M2227.3555,326.7295h-1.0947v-6.3604h1.0947V326.7295z" />
          <path
            d="M2229.1807,320.3691h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V320.3691z"
          />
          <path
            d="M2237.1357,323.3364l2.8213-2.9673h1.3623l-2.4814,2.5054l2.6885,3.855h-1.2773l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V323.3364z"
          />
          <path
            d="M2242.0996,323.5552c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2243.3154,326.8633,2242.0996,325.5132,2242.0996,323.5552z M2243.2061,322.8867
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C2244.0938,321.1841,2243.4004,321.8042,2243.2061,322.8867z"
          />
          <path d="M2250.2012,326.7295h-1.0947v-8.7319h1.0947V326.7295z" />
          <path
            d="M2251.5879,323.5552c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2252.8037,326.8633,2251.5879,325.5132,2251.5879,323.5552z M2252.6943,322.8867
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C2253.582,321.1841,2252.8887,321.8042,2252.6943,322.8867z"
          />
          <path
            d="M2258.5947,320.3691h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V320.3691z"
          />
          <path
            d="M2211.0859,339.0146c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5439-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.252-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0342-0.2676c0.3047-1.0337,1.2412-1.7754,2.542-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2549,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0947,0.8267-1.958,0.8267
				C2212.0225,340.8389,2211.0859,340.1577,2211.0859,339.0146z M2215.0381,337.4702l-1.2402,0.2676
				c-0.9727,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9365,0,1.7266-0.5718,1.7266-1.3862
				V337.4702z"
          />
          <path d="M2219.0781,340.7295h-1.0947v-8.7319h1.0947V340.7295z" />
          <path
            d="M2221.0488,338.7593c0.3398,0.7417,1.1182,1.1919,1.9453,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6074-0.7905-1.6533-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0938,0.2554,2.3467,0.4741,2.3467,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L2221.0488,338.7593z"
          />
          <path
            d="M2228.9434,337.5552c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C2230.1602,340.8633,2228.9434,339.5132,2228.9434,337.5552z M2230.0508,336.8867
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C2230.9385,335.1841,2230.2451,335.8042,2230.0508,336.8867z"
          />
          <path
            d="M2235.5127,337.5552c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2236.7285,340.8633,2235.5127,339.5132,2235.5127,337.5552z M2236.6191,336.8867
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C2237.5068,335.1841,2236.8135,335.8042,2236.6191,336.8867z"
          />
          <path
            d="M2242.5195,334.3691h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V334.3691z"
          />
          <path
            d="M2208.355,353.8052v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.0815-1.0459,1.9692-1.0459
				c1.7637,0,2.7734,1.4956,2.7734,3.3076c0,1.8003-1.0098,3.3081-2.7734,3.3081
				C2209.4365,354.8633,2208.7319,354.4375,2208.355,353.8052z M2208.3306,351.7866c0,1.459,0.8999,2.1157,1.7993,2.1157
				c1.1924,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.668-2.3467-1.8604-2.3467c-0.8994,0-1.7993,0.6445-1.7993,2.1279V351.7866z"
          />
          <path
            d="M2214.0479,351.5552c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2215.2637,354.8633,2214.0479,353.5132,2214.0479,351.5552z M2215.1543,350.8867
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C2216.042,349.1841,2215.3486,349.8042,2215.1543,350.8867z"
          />
          <path d="M2222.1494,354.7295h-1.0947v-8.7319h1.0947V354.7295z" />
          <path
            d="M2223.5361,351.5552c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2224.752,354.8633,2223.5361,353.5132,2223.5361,351.5552z M2224.6426,350.8867
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C2225.5303,349.1841,2224.8369,349.8042,2224.6426,350.8867z"
          />
          <path d="M2232.1719,354.7295l-2.3828-6.3604h1.1553l1.7871,5.0225l1.7637-5.0225h1.1436l-2.3838,6.3604H2232.1719z" />
          <path d="M2236.6611,345.9976h1.1924v1.2651h-1.1924V345.9976z M2237.8047,354.7295h-1.0947v-6.3604h1.0947V354.7295z" />
          <path
            d="M2239.6299,348.3691h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V348.3691z"
          />
          <path
            d="M2248.9229,357.0522c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2061,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9453,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.875,0,1.6045,0.438,1.9453,1.0581v-0.9365
				h1.0947v5.7886C2251.8779,356.0186,2250.6494,357.0522,2248.9229,357.0522z M2249.0322,349.2085
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C2250.7959,349.8892,2249.9443,349.2085,2249.0322,349.2085z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="77">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M2167.0349,503.8591c0,25.2659-20.48,45.75-45.75,45.75c-25.2649,0-45.75-20.4841-45.75-45.75
			c0-25.269,20.4851-45.75,45.75-45.75C2146.5549,458.1091,2167.0349,478.5901,2167.0349,503.8591"
        />
        <g>
          <path
            d="M2097.8066,482.5684l4.0615-4.4146h1.3989l-3.5391,3.8066l3.709,4.9253h-1.374l-3.1133-4.0859l-1.1431,1.228v2.8579
				h-1.1675v-8.7319h1.1675V482.5684z"
          />
          <path
            d="M2106.9297,487.0195c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C2109.8848,485.6816,2108.6685,487.0195,2106.9297,487.0195z M2106.9297,486.083
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2105.0811,485.1587,2105.8105,486.083,2106.9297,486.083z"
          />
          <path
            d="M2111.4189,484.9155c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L2111.4189,484.9155z"
          />
          <path
            d="M2117.3555,481.4619h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V481.4619z"
          />
          <path d="M2125.5171,486.8857l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H2125.5171z" />
          <path
            d="M2132.5728,487.0195c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C2135.5278,485.6816,2134.3115,487.0195,2132.5728,487.0195z M2132.5728,486.083
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2130.7241,485.1587,2131.4536,486.083,2132.5728,486.083z"
          />
          <path
            d="M2139.4336,487.0195c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C2142.3887,485.6816,2141.1724,487.0195,2139.4336,487.0195z M2139.4336,486.083
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2137.585,485.1587,2138.3145,486.083,2139.4336,486.083z"
          />
          <path
            d="M2147.0728,481.6079c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V481.6079z"
          />
          <path
            d="M2090.271,497.7114c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2091.2808,501.0195,2090.271,499.5117,2090.271,497.7114z
				 M2093.2383,495.3647c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2095.0386,496.0093,2094.1387,495.3647,2093.2383,495.3647z"
          />
          <path
            d="M2097.4844,497.7114c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2098.7007,501.0195,2097.4844,499.6694,2097.4844,497.7114z M2098.5913,497.043
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2099.479,495.3403,2098.7856,495.9604,2098.5913,497.043z"
          />
          <path
            d="M2106.5596,499.1709c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C2107.4961,500.9951,2106.5596,500.314,2106.5596,499.1709z M2110.5117,497.6265l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V497.6265z"
          />
          <path
            d="M2112.958,499.1709c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C2113.8945,500.9951,2112.958,500.314,2112.958,499.1709z M2116.9102,497.6265l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V497.6265z"
          />
          <path
            d="M2119.855,494.5254h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V494.5254z"
          />
          <path
            d="M2127.8105,497.4927l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V497.4927z"
          />
          <path
            d="M2135.7295,501.0195c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C2138.6846,499.6816,2137.4683,501.0195,2135.7295,501.0195z M2135.7295,500.083
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2133.8809,499.1587,2134.6104,500.083,2135.7295,500.083z"
          />
          <path
            d="M2142.5903,501.0195c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C2145.5454,499.6816,2144.3291,501.0195,2142.5903,501.0195z M2142.5903,500.083
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2140.7417,499.1587,2141.4712,500.083,2142.5903,500.083z"
          />
          <path
            d="M2148.0283,499.9614v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C2149.1104,501.0195,2148.4053,500.5938,2148.0283,499.9614z M2148.0039,497.9429c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8608-0.9849,1.8608-2.3472c0-1.374-0.6689-2.3467-1.8608-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V497.9429z"
          />
          <path d="M2094.5103,506.1538h1.1919v1.2651h-1.1919V506.1538z M2095.6538,514.8857h-1.0947v-6.3604h1.0947V514.8857z" />
          <path
            d="M2097.4785,508.5254h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V508.5254z"
          />
          <path
            d="M2108.001,511.4927l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V511.4927z"
          />
          <path d="M2114.4971,514.8857h-1.0947v-8.7319h1.0947V514.8857z" />
          <path
            d="M2115.8838,511.7114c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2117.1001,515.0195,2115.8838,513.6694,2115.8838,511.7114z M2116.9907,511.043
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2117.8784,509.3403,2117.1851,509.9604,2116.9907,511.043z"
          />
          <path d="M2122.8418,506.1538h1.1919v1.2651h-1.1919V506.1538z M2123.9854,514.8857h-1.0947v-6.3604h1.0947V514.8857z" />
          <path
            d="M2125.8101,508.5254h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V508.5254z"
          />
          <path
            d="M2132.2329,511.7114c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2133.4492,515.0195,2132.2329,513.6694,2132.2329,511.7114z M2133.3398,511.043
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2134.2275,509.3403,2133.5342,509.9604,2133.3398,511.043z"
          />
          <path
            d="M2142.5356,509.6079c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V509.6079z"
          />
          <path
            d="M2143.3027,511.7114c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2144.519,515.0195,2143.3027,513.6694,2143.3027,511.7114z M2144.4097,511.043
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2145.2974,509.3403,2144.604,509.9604,2144.4097,511.043z"
          />
          <path d="M2101.1211,528.8857l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H2101.1211z" />
          <path
            d="M2108.1768,529.0195c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C2111.1318,527.6816,2109.9155,529.0195,2108.1768,529.0195z M2108.1768,528.083
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2106.3281,527.1587,2107.0576,528.083,2108.1768,528.083z"
          />
          <path d="M2113.6147,528.8857h-1.0947v-8.7319h1.0947V528.8857z" />
          <path
            d="M2120.4863,528.8857h-1.0942v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8853,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5933-0.5962,1.5933-1.6538v-3.8311h1.0942V528.8857z"
          />
          <path
            d="M2123.395,528.8857h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V528.8857z"
          />
          <path
            d="M2132.4575,525.7114c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2133.6738,529.0195,2132.4575,527.6694,2132.4575,525.7114z M2133.5645,525.043
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2134.4521,523.3403,2133.7588,523.9604,2133.5645,525.043z"
          />
          <path
            d="M2139.6104,526.9155c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L2139.6104,526.9155z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="76">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3107.105,998.957c0,25.266-20.4839,45.75-45.75,45.75c-25.269,0-45.75-20.484-45.75-45.75
			s20.481-45.75,45.75-45.75C3086.6211,953.207,3107.105,973.691,3107.105,998.957"
        />
        <g>
          <path
            d="M3039.0801,966.2515l1.8486,6.9927l1.8613-6.9927h1.1553l-2.3965,8.7319h-1.1426l-1.8613-6.7983l-1.8477,6.7983h-1.1436
				l-2.4082-8.7319h1.2041l1.8604,6.9927l1.8369-6.9927H3039.0801z"
          />
          <path
            d="M3044.0811,973.2686c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3045.0176,975.0928,3044.0811,974.4116,3044.0811,973.2686z M3048.0342,971.7241l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V971.7241z"
          />
          <path
            d="M3049.9922,973.2686c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3050.9287,975.0928,3049.9922,974.4116,3049.9922,973.2686z M3053.9453,971.7241l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V971.7241z"
          />
          <path
            d="M3059.6992,969.7056c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V969.7056z"
          />
          <path
            d="M3059.9805,971.8091c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3060.9893,975.1172,3059.9805,973.6094,3059.9805,971.8091z
				 M3062.9473,969.4624c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3064.7471,970.1069,3063.8477,969.4624,3062.9473,969.4624z"
          />
          <path
            d="M3066.708,971.8091c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3067.9238,975.1172,3066.708,973.7671,3066.708,971.8091z M3067.8145,971.1406
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3068.7021,969.438,3068.0088,970.0581,3067.8145,971.1406z"
          />
          <path
            d="M3074.8701,971.8091c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3075.8789,975.1172,3074.8701,973.6094,3074.8701,971.8091z
				 M3077.8369,969.4624c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3079.6367,970.1069,3078.7373,969.4624,3077.8369,969.4624z"
          />
          <path d="M3081.9863,966.2515h1.1924v1.2651h-1.1924V966.2515z M3083.1299,974.9834h-1.0947v-6.3604h1.0947V974.9834z" />
          <path
            d="M3084.0303,971.8091c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3085.2461,975.1172,3084.0303,973.7671,3084.0303,971.8091z M3085.1367,971.1406
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3086.0244,969.438,3085.3311,970.0581,3085.1367,971.1406z"
          />
          <path
            d="M3017.916,987.2686c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3018.8525,989.0928,3017.916,988.4116,3017.916,987.2686z M3021.8691,985.7241l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V985.7241z"
          />
          <path
            d="M3023.8281,987.2686c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3024.7646,989.0928,3023.8281,988.4116,3023.8281,987.2686z M3027.7812,985.7241l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V985.7241z"
          />
          <path
            d="M3030.2383,982.623h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V982.623z"
          />
          <path
            d="M3038.2549,985.8091c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3039.2637,989.1172,3038.2549,987.6094,3038.2549,985.8091z
				 M3041.2217,983.4624c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3043.0215,984.1069,3042.1221,983.4624,3041.2217,983.4624z"
          />
          <path
            d="M3044.9824,985.8091c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3046.1982,989.1172,3044.9824,987.7671,3044.9824,985.8091z M3046.0889,985.1406
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3046.9766,983.438,3046.2832,984.0581,3046.0889,985.1406z"
          />
          <path
            d="M3056.0996,989.1172c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3059.0547,987.7793,3057.8389,989.1172,3056.0996,989.1172z M3056.0996,988.1807
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3054.251,987.2563,3054.9805,988.1807,3056.0996,988.1807z"
          />
          <path
            d="M3062.4746,989.1172c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3065.4297,987.7793,3064.2139,989.1172,3062.4746,989.1172z M3062.4746,988.1807
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3060.626,987.2563,3061.3555,988.1807,3062.4746,988.1807z"
          />
          <path
            d="M3069.6279,983.7056c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V983.7056z"
          />
          <path
            d="M3070.4912,987.0132c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L3070.4912,987.0132z"
          />
          <path
            d="M3076.8662,988.0591v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C3077.9482,989.1172,3077.2432,988.6914,3076.8662,988.0591z M3076.8418,986.0405c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V986.0405z"
          />
          <path
            d="M3085.8066,983.7056c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V983.7056z"
          />
          <path
            d="M3089.043,989.1172c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3091.998,987.7793,3090.7822,989.1172,3089.043,989.1172z M3089.043,988.1807
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3087.1943,987.2563,3087.9238,988.1807,3089.043,988.1807z"
          />
          <path
            d="M3092.8984,982.623h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V982.623z"
          />
          <path
            d="M3101.7061,991.3062c-1.3994,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6201-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C3104.6611,990.2725,3103.4326,991.3062,3101.7061,991.3062z M3101.8154,983.4624
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C3103.5781,984.1431,3102.7275,983.4624,3101.8154,983.4624z"
          />
          <path d="M3025.3184,1002.9834l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3025.3184z" />
          <path
            d="M3028.8701,1001.2686c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3029.8066,1003.0928,3028.8701,1002.4116,3028.8701,1001.2686z M3032.8232,999.7241l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V999.7241z"
          />
          <path
            d="M3035.2803,996.623h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V996.623z"
          />
          <path
            d="M3043.7354,994.2515h1.0947v3.2349c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V994.2515z"
          />
          <path
            d="M3049.6729,999.8091c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3050.8887,1003.1172,3049.6729,1001.7671,3049.6729,999.8091z M3050.7793,999.1406
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3051.667,997.438,3050.9736,998.0581,3050.7793,999.1406z"
          />
          <path
            d="M3056.3623,997.5596h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V997.5596z"
          />
          <path d="M3063.5518,1002.9834l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3063.5518z" />
          <path
            d="M3070.1201,1003.1172c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3073.0752,1001.7793,3071.8594,1003.1172,3070.1201,1003.1172z M3070.1201,1002.1807
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3068.2715,1001.2563,3069.001,1002.1807,3070.1201,1002.1807z"
          />
          <path
            d="M3073.5381,999.8091c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3074.7539,1003.1172,3073.5381,1001.7671,3073.5381,999.8091z M3074.6445,999.1406
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3075.5322,997.438,3074.8389,998.0581,3074.6445,999.1406z"
          />
          <path
            d="M3079.6211,999.8091c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3080.6299,1003.1172,3079.6211,1001.6094,3079.6211,999.8091z
				 M3082.5879,997.4624c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3084.3877,998.1069,3083.4883,997.4624,3082.5879,997.4624z"
          />
          <path
            d="M3086.9316,1001.0132c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L3086.9316,1001.0132z"
          />
          <path
            d="M3091.7744,999.8091c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3092.9902,1003.1172,3091.7744,1001.7671,3091.7744,999.8091z M3092.8809,999.1406
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3093.7686,997.438,3093.0752,998.0581,3092.8809,999.1406z"
          />
          <path d="M3099.3887,1002.9834h-1.0947v-8.7319h1.0947V1002.9834z" />
          <path
            d="M3051.6406,1010.623l1.3135,4.7427l1.3262-4.7427h1.0938l-1.9336,6.3604h-0.96l-1.3262-4.7188l-1.3252,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H3051.6406z"
          />
          <path
            d="M3058.4785,1017.1172c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3061.4336,1015.7793,3060.2178,1017.1172,3058.4785,1017.1172z M3058.4785,1016.1807
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3056.6299,1015.2563,3057.3594,1016.1807,3058.4785,1016.1807z"
          />
          <path
            d="M3065.6309,1011.7056c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1011.7056z"
          />
          <path
            d="M3065.9121,1013.8091c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3066.9209,1017.1172,3065.9121,1015.6094,3065.9121,1013.8091z
				 M3068.8789,1011.4624c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3070.6787,1012.1069,3069.7793,1011.4624,3068.8789,1011.4624z"
          />
          <path
            d="M3073.2471,1011.5596h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1011.5596z"
          />
          <path
            d="M3035.3232,1025.5596h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1025.5596z"
          />
          <path
            d="M3041.3203,1031.1172c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3044.2754,1029.7793,3043.0596,1031.1172,3041.3203,1031.1172z M3041.3203,1030.1807
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3039.4717,1029.2563,3040.2012,1030.1807,3041.3203,1030.1807z"
          />
          <path
            d="M3044.7393,1027.8091c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3045.9551,1031.1172,3044.7393,1029.7671,3044.7393,1027.8091z
				 M3045.8457,1027.1406h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026
				C3046.7334,1025.438,3046.04,1026.0581,3045.8457,1027.1406z"
          />
          <path
            d="M3053.6924,1033.3062c-1.3994,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6201-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C3056.6475,1032.2725,3055.4189,1033.3062,3053.6924,1033.3062z M3053.8018,1025.4624
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C3055.5645,1026.1431,3054.7139,1025.4624,3053.8018,1025.4624z"
          />
          <path
            d="M3057.5498,1027.8091c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3058.7656,1031.1172,3057.5498,1029.7671,3057.5498,1027.8091z
				 M3058.6562,1027.1406h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026
				C3059.5439,1025.438,3058.8506,1026.0581,3058.6562,1027.1406z"
          />
          <path
            d="M3065.165,1027.5903l2.8213-2.9673h1.3623l-2.4814,2.5054l2.6875,3.855h-1.2764l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V1027.5903z"
          />
          <path
            d="M3069.6416,1027.8091c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3070.8574,1031.1172,3069.6416,1029.7671,3069.6416,1027.8091z M3070.748,1027.1406
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3071.6357,1025.438,3070.9424,1026.0581,3070.748,1027.1406z"
          />
          <path
            d="M3076.1621,1024.623h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1024.623z"
          />
          <path
            d="M3082.0996,1027.8091c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3083.1084,1031.1172,3082.0996,1029.6094,3082.0996,1027.8091z
				 M3085.0664,1025.4624c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3086.8662,1026.1069,3085.9668,1025.4624,3085.0664,1025.4624z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="75">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M2720.5549,2369.4529c0,25.27-20.481,45.75-45.75,45.75c-25.2659,0-45.75-20.48-45.75-45.75
			c0-25.2649,20.4841-45.75,45.75-45.75C2700.074,2323.7029,2720.5549,2344.188,2720.5549,2369.4529"
        />
        <g>
          <path
            d="M2649.3857,2343.748l1.8486,6.9922l1.8613-6.9922h1.1553l-2.3965,8.7314h-1.1426l-1.8613-6.7979l-1.8477,6.7979h-1.1436
				l-2.4082-8.7314h1.2041l1.8604,6.9922l1.8369-6.9922H2649.3857z"
          />
          <path
            d="M2654.5693,2349.3057c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2655.7852,2352.6133,2654.5693,2351.2637,2654.5693,2349.3057z
				 M2655.6758,2348.6367h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C2656.5635,2346.9336,2655.8701,2347.5537,2655.6758,2348.6367z"
          />
          <path
            d="M2664.5078,2347.2012c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V2347.2012z"
          />
          <path
            d="M2664.9102,2349.3057c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2666.126,2352.6133,2664.9102,2351.2637,2664.9102,2349.3057z M2666.0166,2348.6367
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2666.9043,2346.9336,2666.2109,2347.5537,2666.0166,2348.6367z"
          />
          <path d="M2672.6465,2352.4795h-1.0947v-8.7314h1.0947V2352.4795z" />
          <path
            d="M2673.6689,2349.3057c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2674.6777,2352.6133,2673.6689,2351.1055,2673.6689,2349.3057z
				 M2676.6357,2346.958c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2678.4355,2347.6025,2677.5361,2346.958,2676.6357,2346.958z"
          />
          <path
            d="M2684.9072,2346.1191l1.3135,4.7432l1.3262-4.7432h1.0938l-1.9336,6.3604h-0.96l-1.3262-4.7188l-1.3252,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H2684.9072z"
          />
          <path d="M2689.2998,2343.748h1.1924v1.2646h-1.1924V2343.748z M2690.4434,2352.4795h-1.0947v-6.3604h1.0947V2352.4795z" />
          <path
            d="M2691.9023,2346.1191h1.0947v6.8828c0,1.1318-0.7061,1.7031-1.7881,1.7031c-0.2549,0-0.4131-0.0127-0.6074-0.0361
				v-0.9492c0.1328,0.0244,0.2432,0.0371,0.4248,0.0371c0.6328,0,0.876-0.3047,0.876-0.9492V2346.1191z M2691.8535,2343.748h1.1924
				v1.2646h-1.1924V2343.748z"
          />
          <path
            d="M2694.0195,2349.3057c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2695.0283,2352.6133,2694.0195,2351.1055,2694.0195,2349.3057z
				 M2696.9863,2346.958c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2698.7861,2347.6025,2697.8867,2346.958,2696.9863,2346.958z"
          />
          <path
            d="M2700.8682,2349.3057c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2702.084,2352.6133,2700.8682,2351.2637,2700.8682,2349.3057z M2701.9746,2348.6367
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2702.8623,2346.9336,2702.1689,2347.5537,2701.9746,2348.6367z"
          />
          <path
            d="M2639.668,2365.5557v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4961,2.7725,3.3086c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C2640.75,2366.6133,2640.0449,2366.1875,2639.668,2365.5557z M2639.6436,2363.5361c0,1.46,0.8994,2.1162,1.7998,2.1162
				c1.1914,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6689-2.3477-1.8604-2.3477c-0.9004,0-1.7998,0.6445-1.7998,2.1289V2363.5361z"
          />
          <path
            d="M2644.9961,2363.3057c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2646.2119,2366.6133,2644.9961,2365.2637,2644.9961,2363.3057z
				 M2646.1025,2362.6367h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C2646.9902,2360.9336,2646.2969,2361.5537,2646.1025,2362.6367z"
          />
          <path
            d="M2651.8086,2361.0557h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V2361.0557z"
          />
          <path
            d="M2658.7051,2361.2012c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V2361.2012z"
          />
          <path
            d="M2662.0625,2366.6133c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C2665.0176,2365.2754,2663.8018,2366.6133,2662.0625,2366.6133z M2662.0625,2365.6768
				c1.1182,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C2660.2139,2364.7529,2660.9434,2365.6768,2662.0625,2365.6768z"
          />
          <path
            d="M2667.1357,2363.0869l2.8213-2.9678h1.3623l-2.4814,2.5049l2.6875,3.8555h-1.2764l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V2363.0869z"
          />
          <path
            d="M2673.2666,2363.0869l2.8213-2.9678h1.3623l-2.4814,2.5049l2.6875,3.8555h-1.2764l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V2363.0869z"
          />
          <path
            d="M2677.8652,2363.3057c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2679.0811,2366.6133,2677.8652,2365.2637,2677.8652,2363.3057z
				 M2678.9717,2362.6367h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C2679.8594,2360.9336,2679.166,2361.5537,2678.9717,2362.6367z"
          />
          <path
            d="M2684.5068,2360.1191h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2360.1191z"
          />
          <path
            d="M2691.0029,2357.748h1.0947v3.2344c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2357.748z"
          />
          <path
            d="M2697.0615,2363.3057c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2698.2773,2366.6133,2697.0615,2365.2637,2697.0615,2363.3057z M2698.168,2362.6367
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2699.0557,2360.9336,2698.3623,2361.5537,2698.168,2362.6367z"
          />
          <path d="M2703.6543,2357.748h1.1924v1.2646h-1.1924V2357.748z M2704.7979,2366.4795h-1.0947v-6.3604h1.0947V2366.4795z" />
          <path
            d="M2705.8203,2363.3057c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2706.8291,2366.6133,2705.8203,2365.1055,2705.8203,2363.3057z
				 M2708.7871,2360.958c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2710.5869,2361.6025,2709.6875,2360.958,2708.7871,2360.958z"
          />
          <path
            d="M2635.4775,2379.5557v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4961,2.7725,3.3086c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C2636.5596,2380.6133,2635.8545,2380.1875,2635.4775,2379.5557z M2635.4531,2377.5361c0,1.46,0.8994,2.1162,1.7998,2.1162
				c1.1914,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6689-2.3477-1.8604-2.3477c-0.9004,0-1.7998,0.6445-1.7998,2.1289V2377.5361z"
          />
          <path d="M2641.1943,2371.748h1.1924v1.2646h-1.1924V2371.748z M2642.3379,2380.4795h-1.0947v-6.3604h1.0947V2380.4795z" />
          <path
            d="M2643.7979,2374.1191h1.0947v6.8828c0,1.1318-0.7061,1.7031-1.7881,1.7031c-0.2549,0-0.4131-0.0127-0.6084-0.0361
				v-0.9492c0.1338,0.0244,0.2441,0.0371,0.4258,0.0371c0.6328,0,0.876-0.3047,0.876-0.9492V2374.1191z M2643.749,2371.748h1.1924
				v1.2646h-1.1924V2371.748z"
          />
          <path
            d="M2648.5527,2371.748h1.0947v3.2344c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2371.748z"
          />
          <path
            d="M2654.6113,2377.3057c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2655.8271,2380.6133,2654.6113,2379.2637,2654.6113,2377.3057z
				 M2655.7178,2376.6367h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C2656.6055,2374.9336,2655.9121,2375.5537,2655.7178,2376.6367z"
          />
          <path
            d="M2661.4229,2375.0557h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V2375.0557z"
          />
          <path
            d="M2670.5205,2375.2012c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V2375.2012z"
          />
          <path
            d="M2670.9238,2377.3057c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2672.1396,2380.6133,2670.9238,2379.2637,2670.9238,2377.3057z
				 M2672.0303,2376.6367h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C2672.918,2374.9336,2672.2246,2375.5537,2672.0303,2376.6367z"
          />
          <path
            d="M2677.0664,2378.7646c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2678.0029,2380.5889,2677.0664,2379.9082,2677.0664,2378.7646z M2681.0195,2377.2207l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2377.2207z"
          />
          <path d="M2684.6934,2380.4795h-1.0947v-8.7314h1.0947V2380.4795z" />
          <path d="M2686.1045,2371.748h1.1924v1.2646h-1.1924V2371.748z M2687.248,2380.4795h-1.0947v-6.3604h1.0947V2380.4795z" />
          <path
            d="M2688.8525,2378.5098c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L2688.8525,2378.5098z"
          />
          <path
            d="M2693.8164,2377.3057c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2695.0322,2380.6133,2693.8164,2379.2637,2693.8164,2377.3057z
				 M2694.9229,2376.6367h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C2695.8105,2374.9336,2695.1172,2375.5537,2694.9229,2376.6367z"
          />
          <path
            d="M2703.7549,2375.2012c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V2375.2012z"
          />
          <path
            d="M2704.1572,2377.3057c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2705.373,2380.6133,2704.1572,2379.2637,2704.1572,2377.3057z M2705.2637,2376.6367
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2706.1514,2374.9336,2705.458,2375.5537,2705.2637,2376.6367z"
          />
          <path
            d="M2710.7988,2374.1191h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2374.1191z"
          />
          <path d="M2645.1357,2394.4795l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2645.1357z" />
          <path
            d="M2648.8086,2392.7646c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2649.7451,2394.5889,2648.8086,2393.9082,2648.8086,2392.7646z M2652.7617,2391.2207l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2391.2207z"
          />
          <path
            d="M2655.3418,2388.1191h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2388.1191z"
          />
          <path
            d="M2663.6006,2391.3057c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2664.6094,2394.6133,2663.6006,2393.1055,2663.6006,2391.3057z
				 M2666.5674,2388.958c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2668.3672,2389.6025,2667.4678,2388.958,2666.5674,2388.958z"
          />
          <path
            d="M2670.4502,2391.3057c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2671.666,2394.6133,2670.4502,2393.2637,2670.4502,2391.3057z M2671.5566,2390.6367
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2672.4443,2388.9336,2671.751,2389.5537,2671.5566,2390.6367z"
          />
          <path
            d="M2679.3418,2391.9502c0.5469,1.0459,1.5322,1.6533,2.6631,1.6533s2.0068-0.5586,2.0068-1.5566
				c0-1.0576-1.0098-1.2402-2.335-1.5566c-1.374-0.3281-2.7363-0.7051-2.7363-2.3467c0-1.5322,1.3252-2.5293,2.9795-2.5293
				c1.5195,0,2.6143,0.7295,3.1377,1.7148l-0.8877,0.7051c-0.4502-0.8389-1.1436-1.4111-2.2744-1.4111
				c-1.0576,0-1.8242,0.584-1.8242,1.4229c0,0.9248,0.8145,1.1191,2.043,1.4111c1.4473,0.3408,3.04,0.6445,3.04,2.4932
				c0,1.6289-1.4473,2.6631-3.1738,2.6631c-1.5078,0-2.9551-0.7539-3.5508-1.9336L2679.3418,2391.9502z"
          />
          <path
            d="M2686.1172,2385.748h2.7725c2.6514,0,4.3291,1.7021,4.3291,4.3652c0,2.6641-1.6777,4.3662-4.3291,4.3662h-2.7725
				V2385.748z M2688.8896,2393.4697c1.9824,0,3.1865-1.3252,3.1865-3.3564c0-2.043-1.2041-3.3564-3.1982-3.3564h-1.6182v6.7129
				H2688.8896z"
          />
          <path
            d="M2700.1904,2393.1787c-0.3525,0.8018-1.2891,1.4346-2.542,1.4346c-2.2256,0-3.8789-1.8242-3.8789-4.4756
				c0-2.6631,1.666-4.5234,3.9521-4.5234c1.6787,0,2.8701,0.9121,3.3926,2.4199l-1.1182,0.3643
				c-0.3164-1.1064-1.1191-1.751-2.2627-1.751c-1.6533,0-2.7969,1.4111-2.7969,3.4785c0,2.0547,1.1436,3.4775,2.8096,3.4775
				c1.4102,0,2.3594-1.082,2.3838-2.6387h-2.5059v-1.0215h3.6367v4.5361h-1.0703V2393.1787z"
          />
          <path
            d="M2702.7705,2392.5098c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L2702.7705,2392.5098z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="74">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M1527.973,2231.281c0,25.27-20.485,45.75-45.75,45.75c-25.27,0-45.75-20.48-45.75-45.75
			c0-25.2649,20.48-45.75,45.75-45.75C1507.488,2185.531,1527.973,2206.0161,1527.973,2231.281"
        />
        <g>
          <path d="M1463.1348,2228.3076h-1.0093l-4.7671-7.0293v7.0293h-1.1187v-8.7314h1.228l4.5361,6.749v-6.749h1.1309V2228.3076z" />
          <path d="M1464.353,2219.5762h1.1919v1.2646h-1.1919V2219.5762z M1465.4966,2228.3076h-1.0947v-6.3604h1.0947V2228.3076z" />
          <path d="M1468.3428,2228.3076l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1468.3428z" />
          <path
            d="M1471.8345,2225.1338c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1473.0508,2228.4414,1471.8345,2227.0918,1471.8345,2225.1338z
				 M1472.9414,2224.4648h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1473.8291,2222.7617,1473.1357,2223.3818,1472.9414,2224.4648z"
          />
          <path
            d="M1477.7344,2226.5928c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1478.6709,2228.417,1477.7344,2227.7363,1477.7344,2226.5928z M1481.6865,2225.0488l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2225.0488z"
          />
          <path
            d="M1489.0703,2228.3076h-1.0942v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8853,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5933-0.5957,1.5933-1.6533v-3.8311h1.0942V2228.3076z"
          />
          <path d="M1493.8638,2228.3076l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1493.8638z" />
          <path
            d="M1497.2954,2226.5928c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1498.2319,2228.417,1497.2954,2227.7363,1497.2954,2226.5928z M1501.2476,2225.0488l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2225.0488z"
          />
          <path
            d="M1503.584,2221.9473h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2221.9473z"
          />
          <path
            d="M1437.02,2236.8838h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2559,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V2236.8838z"
          />
          <path
            d="M1440.377,2233.5762h1.0947v3.2344c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2233.5762z"
          />
          <path
            d="M1451.6768,2242.3076h-1.0942v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8853,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5933-0.5957,1.5933-1.6533v-3.8311h1.0942V2242.3076z"
          />
          <path d="M1452.8335,2233.5762h1.1919v1.2646h-1.1919V2233.5762z M1453.9771,2242.3076h-1.0947v-6.3604h1.0947V2242.3076z" />
          <path
            d="M1455.3394,2240.3379c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9243-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L1455.3394,2240.3379z"
          />
          <path
            d="M1463.0635,2241.4805c0.8755,0,1.3984-0.5596,1.5811-1.3496l0.9731,0.4502c-0.3042,1.0576-1.2285,1.8604-2.5542,1.8604
				c-1.7754,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2285-3.3086,3.0039-3.3086c1.3257,0,2.2256,0.7666,2.5298,1.8242
				l-0.9487,0.4746c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8994-1.9092,2.3594
				C1461.1543,2240.5811,1461.9326,2241.4805,1463.0635,2241.4805z"
          />
          <path
            d="M1468.7324,2242.4414c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1471.6875,2241.1035,1470.4712,2242.4414,1468.7324,2242.4414z M1468.7324,2241.5049
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1466.8838,2240.5811,1467.6133,2241.5049,1468.7324,2241.5049z"
          />
          <path
            d="M1473.562,2242.3076h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0337-1.083,2.0308-1.083c1.3135,0,2.104,0.9365,2.104,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V2242.3076z"
          />
          <path
            d="M1483.5493,2241.3838v3.1133h-1.0947v-8.5498h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C1484.6313,2242.4414,1483.9263,2242.0156,1483.5493,2241.3838z M1483.5249,2239.3643c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6685-2.3477-1.8604-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V2239.3643z"
          />
          <path
            d="M1491.5894,2242.4414c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1494.5444,2241.1035,1493.3281,2242.4414,1491.5894,2242.4414z M1491.5894,2241.5049
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1489.7407,2240.5811,1490.4702,2241.5049,1491.5894,2241.5049z"
          />
          <path
            d="M1495.4702,2240.3379c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9243-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L1495.4702,2240.3379z"
          />
          <path
            d="M1500.7988,2236.8838h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2554,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V2236.8838z"
          />
          <path
            d="M1503.7178,2239.1338c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1504.9341,2242.4414,1503.7178,2241.0918,1503.7178,2239.1338z
				 M1504.8247,2238.4648h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1505.7124,2236.7617,1505.019,2237.3818,1504.8247,2238.4648z"
          />
          <path
            d="M1513.4121,2237.0293c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2237.0293z"
          />
          <path d="M1513.9604,2233.5762h1.1919v1.2646h-1.1919V2233.5762z M1515.104,2242.3076h-1.0947v-6.3604h1.0947V2242.3076z" />
          <path
            d="M1516.3203,2235.9473h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2235.9473z"
          />
          <path
            d="M1525.0049,2244.6309c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3408,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C1527.96,2243.5967,1526.7319,2244.6309,1525.0049,2244.6309z M1525.1143,2236.7861
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C1526.8779,2237.4678,1526.0264,2236.7861,1525.1143,2236.7861z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="73">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2977.6641,1319.0229c0,25.266-20.48,45.75-45.75,45.75c-25.2661,0-45.75-20.484-45.75-45.75
			c0-25.2649,20.4839-45.75,45.75-45.75C2957.1841,1273.2729,2977.6641,1293.7581,2977.6641,1319.0229"
        />
        <g>
          <path
            d="M2922.6562,1295.0493h-1.167v-8.7319h3.332c1.8486,0,3.0283,0.8638,3.0283,2.5298c0,1.3984-0.8516,2.2495-2.2383,2.481
				l2.4209,3.7212h-1.3379l-2.3477-3.6606h-1.6904V1295.0493z M2924.7607,1290.3794c1.2402,0,1.9336-0.5474,1.9336-1.5322
				c0-0.9854-0.6934-1.52-1.9336-1.52h-2.1045v3.0522H2924.7607z"
          />
          <path
            d="M2931.4756,1295.1831c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2934.4307,1293.8452,2933.2148,1295.1831,2931.4756,1295.1831z M2931.4756,1294.2466
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2929.627,1293.3223,2930.3564,1294.2466,2931.4756,1294.2466z"
          />
          <path d="M2936.5498,1295.0493h-1.0947v-8.7319h1.0947V1295.0493z" />
          <path
            d="M2937.5713,1291.875c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2938.7871,1295.1831,2937.5713,1293.833,2937.5713,1291.875z M2938.6777,1291.2065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2939.5654,1289.5039,2938.8721,1290.124,2938.6777,1291.2065z"
          />
          <path d="M2906.5264,1309.0493l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2906.5264z" />
          <path
            d="M2910.1992,1307.3345c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2911.1357,1309.1587,2910.1992,1308.4775,2910.1992,1307.3345z M2914.1523,1305.79l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1305.79z"
          />
          <path
            d="M2916.7324,1302.689h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1302.689z"
          />
          <path
            d="M2925.4297,1300.3174h1.0947v3.2349c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1300.3174z"
          />
          <path
            d="M2934.4443,1309.1831c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2937.3994,1307.8452,2936.1836,1309.1831,2934.4443,1309.1831z M2934.4443,1308.2466
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2932.5957,1307.3223,2933.3252,1308.2466,2934.4443,1308.2466z"
          />
          <path
            d="M2941.7188,1303.7715c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1303.7715z"
          />
          <path
            d="M2942.1211,1305.875c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2943.3369,1309.1831,2942.1211,1307.833,2942.1211,1305.875z M2943.2275,1305.2065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2944.1152,1303.5039,2943.4219,1304.124,2943.2275,1305.2065z"
          />
          <path
            d="M2951.3291,1308.2222c0.875,0,1.3984-0.5591,1.5801-1.3496l0.9736,0.4497c-0.3047,1.0581-1.2285,1.8608-2.5537,1.8608
				c-1.7764,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7661,2.5293,1.8242
				l-0.9492,0.4741c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9102,0.8999-1.9102,2.3589
				C2949.4189,1307.3223,2950.1973,1308.2222,2951.3291,1308.2222z"
          />
          <path
            d="M2954.2246,1307.3345c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2955.1611,1309.1587,2954.2246,1308.4775,2954.2246,1307.3345z M2958.1777,1305.79l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1305.79z"
          />
          <path d="M2900.2861,1314.3174h1.1924v1.2651h-1.1924V1314.3174z M2901.4297,1323.0493h-1.0947v-6.3604h1.0947V1323.0493z" />
          <path
            d="M2902.8887,1316.689h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1316.689z"
          />
          <path
            d="M2911.0869,1321.3345c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2912.0234,1323.1587,2911.0869,1322.4775,2911.0869,1321.3345z M2915.04,1319.79l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1319.79z"
          />
          <path
            d="M2917.1201,1321.3345c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2918.0566,1323.1587,2917.1201,1322.4775,2917.1201,1321.3345z M2921.0732,1319.79l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1319.79z"
          />
          <path
            d="M2923.6533,1316.689h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1316.689z"
          />
          <path
            d="M2931.2441,1322.125v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C2932.3262,1323.1831,2931.6211,1322.7573,2931.2441,1322.125z M2931.2197,1320.1064c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1320.1064z"
          />
          <path d="M2936.9609,1314.3174h1.1924v1.2651h-1.1924V1314.3174z M2938.1045,1323.0493h-1.0947v-6.3604h1.0947V1323.0493z" />
          <path
            d="M2939.126,1319.875c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2940.3418,1323.1831,2939.126,1321.833,2939.126,1319.875z M2940.2324,1319.2065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2941.1201,1317.5039,2940.4268,1318.124,2940.2324,1319.2065z"
          />
          <path
            d="M2945.3311,1319.875c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2946.3398,1323.1831,2945.3311,1321.6753,2945.3311,1319.875z
				 M2948.2979,1317.5283c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2950.0977,1318.1729,2949.1982,1317.5283,2948.2979,1317.5283z"
          />
          <path
            d="M2952.1797,1319.875c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2953.3955,1323.1831,2952.1797,1321.833,2952.1797,1319.875z M2953.2861,1319.2065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2954.1738,1317.5039,2953.4805,1318.124,2953.2861,1319.2065z"
          />
          <path
            d="M2958.8213,1316.689h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1316.689z"
          />
          <path
            d="M2906.4238,1333.875c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2907.4326,1337.1831,2906.4238,1335.6753,2906.4238,1333.875z
				 M2909.3906,1331.5283c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2911.1904,1332.1729,2910.291,1331.5283,2909.3906,1331.5283z"
          />
          <path
            d="M2918.7568,1337.0493h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093
				c-1.3623,0-2.1279-0.9365-2.1279-2.2861v-4.208h1.0938v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083
				c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1337.0493z"
          />
          <path
            d="M2925.2539,1337.0493h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093
				c-1.3623,0-2.1279-0.9365-2.1279-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083
				c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1337.0493z"
          />
          <path
            d="M2929.999,1331.7715c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1331.7715z"
          />
          <path d="M2935.4355,1337.0493h-5.0098v-0.8271l3.6719-4.5967h-3.624v-0.9365h4.9258v0.8394l-3.5996,4.5845h3.6357V1337.0493z" />
          <path
            d="M2935.9971,1335.3345c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2936.9336,1337.1587,2935.9971,1336.4775,2935.9971,1335.3345z M2939.9502,1333.79l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1333.79z"
          />
          <path
            d="M2942.0303,1335.3345c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2942.9668,1337.1587,2942.0303,1336.4775,2942.0303,1335.3345z M2945.9834,1333.79l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1333.79z"
          />
          <path
            d="M2949.6572,1337.0493h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0312-1.0825c1.3125,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1337.0493z"
          />
          <path d="M2914.6279,1351.0493l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2914.6279z" />
          <path
            d="M2921.3174,1351.1831c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2924.2725,1349.8452,2923.0566,1351.1831,2921.3174,1351.1831z M2921.3174,1350.2466
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2919.4688,1349.3223,2920.1982,1350.2466,2921.3174,1350.2466z"
          />
          <path
            d="M2924.8594,1347.875c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2926.0752,1351.1831,2924.8594,1349.833,2924.8594,1347.875z M2925.9658,1347.2065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2926.8535,1345.5039,2926.1602,1346.124,2925.9658,1347.2065z"
          />
          <path
            d="M2931.0635,1347.875c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2932.0723,1351.1831,2931.0635,1349.6753,2931.0635,1347.875z
				 M2934.0303,1345.5283c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2935.8301,1346.1729,2934.9307,1345.5283,2934.0303,1345.5283z"
          />
          <path
            d="M2938.4951,1349.0791c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L2938.4951,1349.0791z"
          />
          <path
            d="M2943.46,1347.875c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2944.6758,1351.1831,2943.46,1349.833,2943.46,1347.875z M2944.5664,1347.2065
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2945.4541,1345.5039,2944.7607,1346.124,2944.5664,1347.2065z"
          />
          <path d="M2951.1963,1351.0493h-1.0947v-8.7319h1.0947V1351.0493z" />
        </g>
      </g>
      <g onClick={handleClick} id="72">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M640.918,2118.73c0,25.2661-20.484,45.75-45.75,45.75c-25.27,0-45.75-20.4839-45.75-45.75
			c0-25.2649,20.48-45.75,45.75-45.75C620.434,2072.98,640.918,2093.4651,640.918,2118.73"
        />
        <g>
          <path d="M560.5229,2094.0459v7.71h-1.1675v-7.71h-2.9556v-1.0215h7.0781v1.0215H560.5229z" />
          <path
            d="M566.1436,2101.8896c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C569.0986,2100.5518,567.8823,2101.8896,566.1436,2101.8896z M566.1436,2100.9531
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C564.2949,2100.0293,565.0244,2100.9531,566.1436,2100.9531z"
          />
          <path
            d="M569.0757,2098.582c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C570.292,2101.8896,569.0757,2100.54,569.0757,2098.582z M570.1826,2097.9131h3.4292
				c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C571.0703,2096.21,570.377,2096.8301,570.1826,2097.9131z"
          />
          <path
            d="M577.5415,2104.0791c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3403,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.6055,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C580.4966,2103.0449,579.2686,2104.0791,577.5415,2104.0791z M577.6509,2096.2344
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C579.4146,2096.916,578.563,2096.2344,577.6509,2096.2344z"
          />
          <path
            d="M580.8511,2100.041c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C581.7876,2101.8652,580.8511,2101.1846,580.8511,2100.041z M584.8032,2098.4971l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2098.4971z"
          />
          <path
            d="M586.7749,2095.3955h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2095.3955z"
          />
          <path
            d="M593.7573,2098.3633l2.8213-2.9678h1.3618l-2.481,2.5049l2.688,3.8555h-1.2769l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V2098.3633z"
          />
          <path
            d="M597.7471,2098.582c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C598.9634,2101.8896,597.7471,2100.54,597.7471,2098.582z M598.854,2097.9131h3.4292
				c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C599.7417,2096.21,599.0483,2096.8301,598.854,2097.9131z"
          />
          <path d="M604.8755,2101.7559h-1.0947v-8.7314h1.0947V2101.7559z" />
          <path d="M605.6782,2093.0244h1.1919v1.2646h-1.1919V2093.0244z M606.8218,2101.7559h-1.0947v-6.3604h1.0947V2101.7559z" />
          <path
            d="M607.6733,2095.3955h1.0947v6.8828c0,1.1318-0.7056,1.7031-1.7881,1.7031c-0.2554,0-0.4131-0.0127-0.6079-0.0361v-0.9492
				c0.1338,0.0244,0.2432,0.0371,0.4258,0.0371c0.6323,0,0.8755-0.3047,0.8755-0.9492V2095.3955z M607.6245,2093.0244h1.1919v1.2646
				h-1.1919V2093.0244z"
          />
          <path
            d="M610.7144,2098.3633l2.8213-2.9678h1.3618l-2.481,2.5049l2.688,3.8555h-1.2769l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V2098.3633z"
          />
          <path
            d="M615.1426,2093.0244h1.0947v3.2344c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2093.0244z"
          />
          <path
            d="M620.5923,2098.582c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C621.8086,2101.8896,620.5923,2100.54,620.5923,2098.582z M621.6992,2097.9131
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C622.5869,2096.21,621.8936,2096.8301,621.6992,2097.9131z"
          />
          <path d="M626.5771,2093.0244h1.1919v1.2646h-1.1919V2093.0244z M627.7207,2101.7559h-1.0947v-6.3604h1.0947V2101.7559z" />
          <path
            d="M628.1343,2098.582c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C629.144,2101.8896,628.1343,2100.3818,628.1343,2098.582z
				 M631.1016,2096.2344c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C632.9019,2096.8789,632.002,2096.2344,631.1016,2096.2344z"
          />
          <path d="M554.3921,2115.7559l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H554.3921z" />
          <path
            d="M557.7017,2114.041c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C558.6382,2115.8652,557.7017,2115.1846,557.7017,2114.041z M561.6538,2112.4971l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2112.4971z"
          />
          <path
            d="M563.8687,2109.3955h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2109.3955z"
          />
          <path
            d="M572.0073,2110.332h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2559,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V2110.332z"
          />
          <path
            d="M574.8047,2112.582c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C576.021,2115.8896,574.8047,2114.54,574.8047,2112.582z M575.9116,2111.9131h3.4292
				c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C576.7993,2110.21,576.106,2110.8301,575.9116,2111.9131z"
          />
          <path
            d="M583.6475,2114.9287c0.8755,0,1.3984-0.5596,1.5811-1.3496l0.9731,0.4502c-0.3042,1.0576-1.2285,1.8604-2.5542,1.8604
				c-1.7754,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2285-3.3086,3.0039-3.3086c1.3257,0,2.2256,0.7666,2.5298,1.8242
				l-0.9487,0.4746c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8994-1.9092,2.3594
				C581.7383,2114.0293,582.5166,2114.9287,583.6475,2114.9287z"
          />
          <path
            d="M586.6772,2107.0244h1.0947v3.2344c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2107.0244z"
          />
          <path
            d="M592.8086,2109.3955h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2109.3955z"
          />
          <path
            d="M601.457,2115.8896c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C604.4121,2114.5518,603.1958,2115.8896,601.457,2115.8896z M601.457,2114.9531
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C599.6084,2114.0293,600.3379,2114.9531,601.457,2114.9531z"
          />
          <path d="M606.165,2115.7559h-1.0947v-8.7314h1.0947V2115.7559z" />
          <path
            d="M609.7773,2115.8896c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C612.7324,2114.5518,611.5161,2115.8896,609.7773,2115.8896z M609.7773,2114.9531
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C607.9287,2114.0293,608.6582,2114.9531,609.7773,2114.9531z"
          />
          <path
            d="M615.8228,2118.0791c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3403,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.6055,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C618.7778,2117.0449,617.5498,2118.0791,615.8228,2118.0791z M615.9321,2110.2344
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C617.6958,2110.916,616.8442,2110.2344,615.9321,2110.2344z"
          />
          <path d="M619.8257,2107.0244h1.1919v1.2646h-1.1919V2107.0244z M620.9692,2115.7559h-1.0947v-6.3604h1.0947V2115.7559z" />
          <path
            d="M621.626,2112.582c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C622.8423,2115.8896,621.626,2114.54,621.626,2112.582z M622.7329,2111.9131h3.4292
				c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C623.6206,2110.21,622.9272,2110.8301,622.7329,2111.9131z"
          />
          <path
            d="M628.4746,2113.0078c0.0239,1.4473,0.9482,2.043,2.0186,2.043c0.7661,0,1.2285-0.1338,1.6294-0.3047l0.1826,0.7666
				c-0.377,0.1699-1.0215,0.3652-1.958,0.3652c-1.812,0-2.8945-1.1924-2.8945-2.9678s1.0459-3.1738,2.7607-3.1738
				c1.9214,0,2.4321,1.6904,2.4321,2.7725c0,0.2188-0.0239,0.3896-0.0361,0.499H628.4746z M631.6118,2112.2412
				c0.0122-0.6807-0.2793-1.7393-1.4834-1.7393c-1.0825,0-1.5566,0.9971-1.6421,1.7393H631.6118z M628.5107,2108.2041
				c0-0.3408,0.2798-0.6201,0.6206-0.6201c0.3403,0,0.5957,0.2666,0.5957,0.6201c0,0.3281-0.2432,0.6201-0.5957,0.6201
				C628.7661,2108.8242,628.5107,2108.5322,628.5107,2108.2041z M630.7729,2108.2041c0-0.3408,0.2676-0.6201,0.6079-0.6201
				c0.3408,0,0.5962,0.2666,0.5962,0.6201c0,0.3281-0.2437,0.6201-0.5962,0.6201
				C631.0161,2108.8242,630.7729,2108.5322,630.7729,2108.2041z"
          />
          <path
            d="M633.2671,2109.3955h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2109.3955z"
          />
          <path d="M561.4658,2129.7559l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H561.4658z" />
          <path
            d="M567.7915,2129.8896c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C570.7466,2128.5518,569.5303,2129.8896,567.7915,2129.8896z M567.7915,2128.9531
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C565.9429,2128.0293,566.6724,2128.9531,567.7915,2128.9531z"
          />
          <path
            d="M573.9224,2129.8896c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C576.8774,2128.5518,575.6611,2129.8896,573.9224,2129.8896z M573.9224,2128.9531
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C572.0737,2128.0293,572.8032,2128.9531,573.9224,2128.9531z"
          />
          <path
            d="M580.832,2124.4775c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2124.4775z"
          />
          <path
            d="M582.7061,2126.582c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C583.7158,2129.8896,582.7061,2128.3818,582.7061,2126.582z
				 M585.6733,2124.2344c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C587.4736,2124.8789,586.5737,2124.2344,585.6733,2124.2344z"
          />
          <path
            d="M594.6743,2129.7559h-1.0947v-0.876c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V2129.7559z"
          />
          <path
            d="M600.8052,2129.7559h-1.0947v-0.876c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V2129.7559z"
          />
          <path
            d="M605.1855,2124.4775c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2124.4775z"
          />
          <path d="M610.2578,2129.7559h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8389l-3.5996,4.585h3.6362V2129.7559z" />
          <path
            d="M610.4536,2128.041c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C611.3901,2129.8652,610.4536,2129.1846,610.4536,2128.041z M614.4058,2126.4971l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2126.4971z"
          />
          <path
            d="M617.7153,2129.7559h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0337-1.083,2.0308-1.083c1.3135,0,2.104,0.9365,2.104,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V2129.7559z"
          />
          <path
            d="M626.0479,2126.582c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C627.2642,2129.8896,626.0479,2128.54,626.0479,2126.582z M627.1548,2125.9131
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C628.0425,2124.21,627.3491,2124.8301,627.1548,2125.9131z"
          />
          <path d="M573.5454,2143.7559h-1.0947v-8.7314h1.0947V2143.7559z" />
          <path
            d="M574.1416,2142.041c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C575.0781,2143.8652,574.1416,2143.1846,574.1416,2142.041z M578.0938,2140.4971l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2140.4971z"
          />
          <path
            d="M580.3086,2137.3955h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2137.3955z"
          />
          <path
            d="M586.002,2140.582c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C587.0117,2143.8896,586.002,2142.3818,586.002,2140.582z
				 M588.9692,2138.2344c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C590.7695,2138.8789,589.8696,2138.2344,588.9692,2138.2344z"
          />
          <path
            d="M594.0181,2142.832v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C595.1001,2143.8896,594.395,2143.4639,594.0181,2142.832z M593.9937,2140.8125c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6685-2.3477-1.8604-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V2140.8125z"
          />
          <path
            d="M601.9365,2143.8896c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C604.8916,2142.5518,603.6753,2143.8896,601.9365,2143.8896z M601.9365,2142.9531
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C600.0879,2142.0293,600.8174,2142.9531,601.9365,2142.9531z"
          />
          <path
            d="M610.5967,2143.7559h-1.0942v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8853,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5933-0.5957,1.5933-1.6533v-3.8311h1.0942V2143.7559z"
          />
          <path
            d="M615.6333,2137.3955l1.313,4.7432l1.3257-4.7432h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H615.6333z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="71">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M3229.418,2136.1841c0,25.2649-20.4839,45.75-45.75,45.75c-25.2659,0-45.75-20.4851-45.75-45.75
			c0-25.27,20.4841-45.75,45.75-45.75C3208.9341,2090.4341,3229.418,2110.9141,3229.418,2136.1841"
        />
        <g>
          <path d="M3144.5811,2124.4785v1.0215h-4.5479v2.6875h3.8545v1.0215h-3.8545v2.9795h4.5479v1.0215h-5.7031v-8.7314H3144.5811z" />
          <path
            d="M3148.6338,2127.9316c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V2127.9316z"
          />
          <path d="M3150.7383,2133.21l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3150.7383z" />
          <path
            d="M3154.0469,2131.4951c0-1.2646,0.9854-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3154.9834,2133.3193,3154.0469,2132.6387,3154.0469,2131.4951z M3158,2129.9512l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2129.9512z"
          />
          <path
            d="M3163.5098,2127.9316c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V2127.9316z"
          />
          <path
            d="M3163.5479,2130.0361c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3164.7637,2133.3438,3163.5479,2131.9941,3163.5479,2130.0361z
				 M3164.6543,2129.3672h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C3165.542,2127.6641,3164.8486,2128.2842,3164.6543,2129.3672z"
          />
          <path
            d="M3169.8252,2126.8496h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2126.8496z"
          />
          <path d="M3177.7441,2124.4785h1.1924v1.2646h-1.1924V2124.4785z M3178.8877,2133.21h-1.0947v-6.3604h1.0947V2133.21z" />
          <path
            d="M3181.0762,2133.21h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0332-1.083,2.0312-1.083c1.3135,0,2.1035,0.9365,2.1035,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V2133.21z"
          />
          <path
            d="M3190.9424,2132.2861v3.1133h-1.0947v-8.5498h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4961,2.7725,3.3086c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C3192.0244,2133.3438,3191.3193,2132.918,3190.9424,2132.2861z M3190.918,2130.2666c0,1.46,0.8994,2.1162,1.7998,2.1162
				c1.1914,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6689-2.3477-1.8604-2.3477c-0.9004,0-1.7998,0.6445-1.7998,2.1289V2130.2666z"
          />
          <path
            d="M3195.8447,2131.4951c0-1.2646,0.9854-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3196.7812,2133.3193,3195.8447,2132.6387,3195.8447,2131.4951z M3199.7979,2129.9512l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2129.9512z"
          />
          <path
            d="M3204.5781,2132.3828c0.875,0,1.3984-0.5596,1.5811-1.3496l0.9727,0.4502c-0.3047,1.0576-1.2285,1.8604-2.5537,1.8604
				c-1.7764,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2275-3.3086,3.0039-3.3086c1.3252,0,2.2256,0.7666,2.5293,1.8242
				l-0.9482,0.4746c-0.1826-0.7783-0.7061-1.3496-1.5811-1.3496c-1.1318,0-1.9102,0.8994-1.9102,2.3594
				C3202.668,2131.4834,3203.4463,2132.3828,3204.5781,2132.3828z"
          />
          <path
            d="M3207.7773,2127.7861h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V2127.7861z"
          />
          <path d="M3214.4795,2133.21l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3214.4795z" />
          <path
            d="M3217.7881,2131.4951c0-1.2646,0.9854-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3218.7246,2133.3193,3217.7881,2132.6387,3217.7881,2131.4951z M3221.7412,2129.9512l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2129.9512z"
          />
          <path
            d="M3223.9551,2126.8496h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2126.8496z"
          />
          <path
            d="M3139.3037,2143.8174l2.8213-2.9678h1.3623l-2.4814,2.5049l2.6875,3.8555h-1.2764l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V2143.8174z"
          />
          <path d="M3145.0703,2147.21h-1.0947v-8.7314h1.0947V2147.21z" />
          <path d="M3146.1152,2138.4785h1.1924v1.2646h-1.1924V2138.4785z M3147.2588,2147.21h-1.0947v-6.3604h1.0947V2147.21z" />
          <path
            d="M3149.4482,2147.21h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0332-1.083,2.0312-1.083c1.3125,0,2.1035,0.9365,2.1035,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V2147.21z"
          />
          <path
            d="M3157.7197,2145.4951c0-1.2646,0.9854-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3158.6562,2147.3193,3157.7197,2146.6387,3157.7197,2145.4951z M3161.6729,2143.9512l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2143.9512z"
          />
          <path
            d="M3163.3887,2145.4951c0-1.2646,0.9854-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3164.3252,2147.3193,3163.3887,2146.6387,3163.3887,2145.4951z M3167.3418,2143.9512l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2143.9512z"
          />
          <path
            d="M3169.7266,2141.7861h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V2141.7861z"
          />
          <path d="M3174.5918,2147.21l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3174.5918z" />
          <path
            d="M3177.9619,2144.0361c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3179.1777,2147.3438,3177.9619,2145.9941,3177.9619,2144.0361z
				 M3179.0684,2143.3672h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C3179.9561,2141.6641,3179.2627,2142.2842,3179.0684,2143.3672z"
          />
          <path
            d="M3187.5342,2141.9316c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V2141.9316z"
          />
          <path
            d="M3187.5107,2145.4951c0-1.2646,0.9854-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3188.4473,2147.3193,3187.5107,2146.6387,3187.5107,2145.4951z M3191.4639,2143.9512l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2143.9512z"
          />
          <path
            d="M3193.6787,2140.8496h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2140.8496z"
          />
          <path
            d="M3199.3711,2144.0361c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C3200.3799,2147.3438,3199.3711,2145.8359,3199.3711,2144.0361z
				 M3202.3379,2141.6885c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C3204.1377,2142.333,3203.2383,2141.6885,3202.3379,2141.6885z"
          />
          <path
            d="M3205.8555,2144.0361c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3207.0713,2147.3438,3205.8555,2145.9941,3205.8555,2144.0361z
				 M3206.9619,2143.3672h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C3207.8496,2141.6641,3207.1562,2142.2842,3206.9619,2143.3672z"
          />
          <path
            d="M3215.4277,2141.9316c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V2141.9316z"
          />
          <path d="M3215.8545,2138.4785h1.1924v1.2646h-1.1924V2138.4785z M3216.998,2147.21h-1.0947v-6.3604h1.0947V2147.21z" />
          <path
            d="M3218.0928,2140.8496h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2140.8496z"
          />
          <path
            d="M3226.6562,2149.5332c-1.3994,0-2.4209-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5723,1.8604-1.8975v-0.7178c-0.3408,0.6201-1.0576,1.0586-1.9463,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1748c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.4385,1.9463,1.0586v-0.9365
				h1.0947v5.7891C3229.6113,2148.499,3228.3828,2149.5332,3226.6562,2149.5332z M3226.7656,2141.6885
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012s0.7061,2.2139,1.8613,2.2139c0.9121,0,1.7627-0.6816,1.7627-2.0801v-0.2305
				C3228.5283,2142.3701,3227.6777,2141.6885,3226.7656,2141.6885z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="70">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M3696.688,1797.059c0,25.265-20.4851,45.75-45.75,45.75c-25.27,0-45.75-20.485-45.75-45.75
			c0-25.27,20.48-45.75,45.75-45.75C3676.2029,1751.309,3696.688,1771.7889,3696.688,1797.059"
        />
        <g>
          <path
            d="M3625.5059,1773.085l-2.6621-7.1875v7.1875h-1.0957v-8.7314h1.5938l2.6504,7.1748l2.6641-7.1748h1.5449v8.7314h-1.084
				v-7.1875l-2.6738,7.1875H3625.5059z"
          />
          <path
            d="M3631.1035,1771.3701c0-1.2646,0.9844-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4023-1.1436-1.2539-1.1436
				c-0.8027,0-1.2773,0.377-1.4707,1.083l-1.0332-0.2676c0.3027-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4219,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6797,0.4492v0.876
				c-0.9727,0.1221-1.5312-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.957,0.8271
				C3632.0391,1773.1943,3631.1035,1772.5137,3631.1035,1771.3701z M3635.0566,1769.8262l-1.2422,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4512,0.9854,1.1426,0.9854c0.9375,0,1.7285-0.5713,1.7285-1.3867
				V1769.8262z"
          />
          <path
            d="M3637.6836,1767.6611h-0.9844v-0.9365h0.9844v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8887,0.79c0.2539,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2812,0.0605-0.5977,0.0967-0.9609,0.0967
				c-1.084,0-1.7031-0.3643-1.7031-1.5811V1767.6611z"
          />
          <path
            d="M3640.7246,1769.9111c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652h-4.5957
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3641.9414,1773.2188,3640.7246,1771.8691,3640.7246,1769.9111z M3641.832,1769.2422
				h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031C3642.7188,1767.5391,3642.0254,1768.1592,3641.832,1769.2422z"
          />
          <path
            d="M3653.2773,1766.7246l1.3145,4.7432l1.3262-4.7432h1.0938l-1.9336,6.3604h-0.9609l-1.3262-4.7188l-1.3242,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3125-4.7305H3653.2773z"
          />
          <path
            d="M3657.0977,1771.3701c0-1.2646,0.9863-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4004-1.1436-1.252-1.1436
				c-0.8027,0-1.2773,0.377-1.4727,1.083l-1.0332-0.2676c0.3047-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4238,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6816,0.4492v0.876
				c-0.9727,0.1221-1.5332-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.959,0.8271
				C3658.0352,1773.1943,3657.0977,1772.5137,3657.0977,1771.3701z M3661.0508,1769.8262l-1.2402,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9355,0,1.7266-0.5713,1.7266-1.3867
				V1769.8262z"
          />
          <path
            d="M3663.0098,1771.3701c0-1.2646,0.9863-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4004-1.1436-1.252-1.1436
				c-0.8027,0-1.2773,0.377-1.4727,1.083l-1.0332-0.2676c0.3047-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4238,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6816,0.4492v0.876
				c-0.9727,0.1221-1.5332-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.959,0.8271
				C3663.9473,1773.1943,3663.0098,1772.5137,3663.0098,1771.3701z M3666.9629,1769.8262l-1.2402,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9355,0,1.7266-0.5713,1.7266-1.3867
				V1769.8262z"
          />
          <path
            d="M3672.7168,1767.8066c-0.1816-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9238,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0938
				v-6.3604h1.0938v1.082c0.2793-0.6445,0.9004-1.1309,1.7637-1.1309c0.1699,0,0.3281,0.0127,0.4375,0.0244V1767.8066z"
          />
          <path d="M3673.3867,1764.3535h1.1934v1.2646h-1.1934V1764.3535z M3674.5312,1773.085h-1.0957v-6.3604h1.0957V1773.085z" />
          <path
            d="M3675.8672,1766.7246h1.0957v0.8633c0.3633-0.5225,0.9961-0.9854,1.8965-0.9854c1.3496,0,2.1152,0.9365,2.1152,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.377-1.5566-1.3262-1.5566c-0.7891,0-1.5918,0.6074-1.5918,1.666v3.7939h-1.0957V1766.7246z"
          />
          <path
            d="M3624.2852,1786.1611v0.9238h-1.0957v-8.7314h1.0957v3.2949c0.377-0.6201,1.082-1.0459,1.9688-1.0459
				c1.7637,0,2.7734,1.4961,2.7734,3.3086c0,1.7998-1.0098,3.3076-2.7734,3.3076
				C3625.3672,1787.2188,3624.6621,1786.793,3624.2852,1786.1611z M3624.2598,1784.1416c0,1.46,0.9004,2.1162,1.8008,2.1162
				c1.1914,0,1.8594-0.9854,1.8594-2.3467c0-1.374-0.668-2.3477-1.8594-2.3477c-0.9004,0-1.8008,0.6445-1.8008,2.1289V1784.1416z"
          />
          <path
            d="M3634.9766,1787.085h-1.0957v-0.876c-0.3633,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3613,0-2.127-0.9365-2.127-2.2861
				v-4.208h1.0938v3.9766c0,0.9004,0.3887,1.5078,1.3262,1.5078c0.7891,0,1.5918-0.5957,1.5918-1.6533v-3.8311h1.0957V1787.085z"
          />
          <path
            d="M3639.5996,1781.8066c-0.1836-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9258,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0957
				v-6.3604h1.0957v1.082c0.2793-0.6445,0.8984-1.1309,1.7617-1.1309c0.1719,0,0.3301,0.0127,0.4395,0.0244V1781.8066z"
          />
          <path
            d="M3642.75,1789.4082c-1.3984,0-2.4199-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8652,1.2891,1.752,1.2891
				c1.1191,0,1.8613-0.5723,1.8613-1.8975v-0.7178c-0.3418,0.6201-1.0586,1.0586-1.9473,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1748c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.877,0,1.6055,0.4385,1.9473,1.0586v-0.9365
				h1.0938v5.7891C3645.7051,1788.374,3644.4766,1789.4082,3642.75,1789.4082z M3642.8594,1781.5635
				c-1.1543,0-1.8613,0.8633-1.8613,2.2012s0.707,2.2139,1.8613,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C3644.623,1782.2451,3643.7715,1781.5635,3642.8594,1781.5635z"
          />
          <path
            d="M3646.6074,1783.9111c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652h-4.5957
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3647.8242,1787.2188,3646.6074,1785.8691,3646.6074,1783.9111z
				 M3647.7148,1783.2422h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031
				C3648.6016,1781.5391,3647.9082,1782.1592,3647.7148,1783.2422z"
          />
          <path
            d="M3656.4238,1781.8066c-0.1816-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9238,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0938
				v-6.3604h1.0938v1.082c0.2793-0.6445,0.9004-1.1309,1.7637-1.1309c0.1699,0,0.3281,0.0127,0.4375,0.0244V1781.8066z"
          />
          <path
            d="M3657.2871,1785.1152c0.3418,0.7412,1.1191,1.1914,1.9473,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6094-0.79-1.6543-1.0088c-1.0469-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9844-1.9219,2.3359-1.9219
				c1.1426,0,2.0781,0.5596,2.4551,1.3135l-0.7773,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7305,0-1.2168,0.3652-1.2168,0.9365c0,0.5957,0.5586,0.7051,1.4473,0.8994c1.0938,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1074,2.0186-2.4688,2.0186c-1.1562,0-2.3105-0.499-2.7969-1.4834L3657.2871,1785.1152z"
          />
          <path
            d="M3664.6484,1778.3535h1.0938v3.2344c0.3652-0.5225,0.998-0.9854,1.8965-0.9854c1.3516,0,2.1172,0.9365,2.1172,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6074-1.5938,1.666v3.7939h-1.0938V1778.3535z"
          />
          <path
            d="M3670.5859,1783.9111c0-1.8975,1.2031-3.3086,2.9297-3.3086c1.7754,0,2.7129,1.3506,2.7129,3.1133v0.3652h-4.5977
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8516,0,1.459-0.4629,1.6543-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C3671.8008,1787.2188,3670.5859,1785.8691,3670.5859,1783.9111z
				 M3671.6914,1783.2422h3.4297c-0.0234-0.9004-0.5098-1.7031-1.6172-1.7031
				C3672.5801,1781.5391,3671.8867,1782.1592,3671.6914,1783.2422z"
          />
          <path
            d="M3677.2754,1781.6611h-0.9844v-0.9365h0.9844v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8887,0.79c0.2539,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2812,0.0605-0.5977,0.0967-0.9609,0.0967
				c-1.084,0-1.7031-0.3643-1.7031-1.5811V1781.6611z"
          />
          <path
            d="M3627.4961,1799.3701c0-1.2646,0.9844-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4023-1.1436-1.2539-1.1436
				c-0.8027,0-1.2773,0.377-1.4707,1.083l-1.0332-0.2676c0.3027-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4219,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6797,0.4492v0.876
				c-0.9727,0.1221-1.5312-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.957,0.8271
				C3628.4316,1801.1943,3627.4961,1800.5137,3627.4961,1799.3701z M3631.4492,1797.8262l-1.2422,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4512,0.9854,1.1426,0.9854c0.9375,0,1.7285-0.5713,1.7285-1.3867
				V1797.8262z"
          />
          <path
            d="M3635.002,1800.1611v3.1133h-1.0957v-8.5498h1.0957v0.9238c0.377-0.6201,1.082-1.0459,1.9688-1.0459
				c1.7637,0,2.7734,1.4961,2.7734,3.3086c0,1.7998-1.0098,3.3076-2.7734,3.3076
				C3636.084,1801.2188,3635.3789,1800.793,3635.002,1800.1611z M3634.9766,1798.1416c0,1.46,0.9004,2.1162,1.8008,2.1162
				c1.1914,0,1.8594-0.9854,1.8594-2.3467c0-1.374-0.668-2.3477-1.8594-2.3477c-0.9004,0-1.8008,0.6445-1.8008,2.1289V1798.1416z"
          />
          <path
            d="M3641.7402,1800.1611v3.1133h-1.0957v-8.5498h1.0957v0.9238c0.377-0.6201,1.082-1.0459,1.9688-1.0459
				c1.7637,0,2.7734,1.4961,2.7734,3.3086c0,1.7998-1.0098,3.3076-2.7734,3.3076
				C3642.8223,1801.2188,3642.1172,1800.793,3641.7402,1800.1611z M3641.7148,1798.1416c0,1.46,0.9004,2.1162,1.8008,2.1162
				c1.1914,0,1.8594-0.9854,1.8594-2.3467c0-1.374-0.668-2.3477-1.8594-2.3477c-0.9004,0-1.8008,0.6445-1.8008,2.1289V1798.1416z"
          />
          <path
            d="M3646.9473,1797.9111c0-1.8975,1.2031-3.3086,2.9297-3.3086c1.7754,0,2.7129,1.3506,2.7129,3.1133v0.3652h-4.5977
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8516,0,1.459-0.4629,1.6543-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C3648.1621,1801.2188,3646.9473,1799.8691,3646.9473,1797.9111z
				 M3648.0527,1797.2422h3.4297c-0.0234-0.9004-0.5098-1.7031-1.6172-1.7031
				C3648.9414,1795.5391,3648.248,1796.1592,3648.0527,1797.2422z"
          />
          <path d="M3654.5625,1801.085h-1.0957v-8.7314h1.0957V1801.085z" />
          <path d="M3659.6094,1801.085l-2.3848-6.3604h1.1562l1.7871,5.0225l1.7637-5.0225h1.1426l-2.3828,6.3604H3659.6094z" />
          <path
            d="M3663.1602,1799.3701c0-1.2646,0.9863-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4004-1.1436-1.252-1.1436
				c-0.8027,0-1.2773,0.377-1.4727,1.083l-1.0332-0.2676c0.3047-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4238,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6816,0.4492v0.876
				c-0.9727,0.1221-1.5332-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.959,0.8271
				C3664.0977,1801.1943,3663.1602,1800.5137,3663.1602,1799.3701z M3667.1133,1797.8262l-1.2402,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9355,0,1.7266-0.5713,1.7266-1.3867
				V1797.8262z"
          />
          <path
            d="M3669.5723,1794.7246h1.0938v0.8633c0.3652-0.5225,0.998-0.9854,1.8965-0.9854c1.3516,0,2.1172,0.9365,2.1172,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6074-1.5938,1.666v3.7939h-1.0938V1794.7246z"
          />
          <path
            d="M3615.2461,1811.9111c0-1.8125,1.0098-3.3086,2.7734-3.3086c0.8867,0,1.5918,0.4258,1.9688,1.0459v-3.2949h1.0957v8.7314
				h-1.0957v-0.9238c-0.377,0.6318-1.082,1.0576-1.9688,1.0576C3616.2559,1815.2188,3615.2461,1813.7109,3615.2461,1811.9111z
				 M3618.2129,1809.5635c-1.1914,0-1.8594,0.9736-1.8594,2.3477c0,1.3613,0.668,2.3467,1.8594,2.3467
				c0.9004,0,1.8008-0.6562,1.8008-2.1162v-0.4492C3620.0137,1810.208,3619.1133,1809.5635,3618.2129,1809.5635z"
          />
          <path
            d="M3627.459,1815.085h-1.0957v-0.876c-0.3633,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3613,0-2.127-0.9365-2.127-2.2861
				v-4.208h1.0938v3.9766c0,0.9004,0.3887,1.5078,1.3262,1.5078c0.7891,0,1.5918-0.5957,1.5918-1.6533v-3.8311h1.0957V1815.085z"
          />
          <path
            d="M3633.832,1815.085h-1.0938v-0.876c-0.3652,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3633,0-2.1289-0.9365-2.1289-2.2861
				v-4.208h1.0957v3.9766c0,0.9004,0.3887,1.5078,1.3242,1.5078c0.791,0,1.5938-0.5957,1.5938-1.6533v-3.8311h1.0938V1815.085z"
          />
          <path
            d="M3638.457,1809.8066c-0.1836-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9258,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0957
				v-6.3604h1.0957v1.082c0.2793-0.6445,0.8984-1.1309,1.7617-1.1309c0.1719,0,0.3301,0.0127,0.4395,0.0244V1809.8066z"
          />
          <path d="M3643.7715,1815.085h-5.0098v-0.8271l3.6719-4.5967h-3.623v-0.9365h4.9258v0.8389l-3.5996,4.585h3.6348V1815.085z" />
          <path
            d="M3644.2109,1813.3701c0-1.2646,0.9863-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4004-1.1436-1.252-1.1436
				c-0.8027,0-1.2773,0.377-1.4727,1.083l-1.0332-0.2676c0.3047-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4238,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6816,0.4492v0.876
				c-0.9727,0.1221-1.5332-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.959,0.8271
				C3645.1484,1815.1943,3644.2109,1814.5137,3644.2109,1813.3701z M3648.1641,1811.8262l-1.2402,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9355,0,1.7266-0.5713,1.7266-1.3867
				V1811.8262z"
          />
          <path
            d="M3650.123,1813.3701c0-1.2646,0.9844-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4023-1.1436-1.2539-1.1436
				c-0.8027,0-1.2773,0.377-1.4707,1.083l-1.0332-0.2676c0.3027-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4219,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6797,0.4492v0.876
				c-0.9727,0.1221-1.5312-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.957,0.8271
				C3651.0586,1815.1943,3650.123,1814.5137,3650.123,1813.3701z M3654.0762,1811.8262l-1.2422,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4512,0.9854,1.1426,0.9854c0.9375,0,1.7285-0.5713,1.7285-1.3867
				V1811.8262z"
          />
          <path
            d="M3657.6289,1815.085h-1.0957v-6.3604h1.0957v0.8633c0.3398-0.5225,0.9473-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3516-0.5234,1.0332-1.083,2.0312-1.083c1.3125,0,2.1035,0.9365,2.1035,2.2871v4.1953
				h-1.0957v-3.9033c0-0.8887-0.3633-1.5566-1.2637-1.5566c-0.7305,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0957v-3.9033
				c0-0.8887-0.3652-1.5566-1.2637-1.5566c-0.7305,0-1.5078,0.6201-1.5078,1.6533V1815.085z"
          />
          <path
            d="M3666.6426,1806.3535h1.0957v3.2344c0.3633-0.5225,0.9961-0.9854,1.8965-0.9854c1.3496,0,2.1152,0.9365,2.1152,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.377-1.5566-1.3262-1.5566c-0.7891,0-1.5918,0.6074-1.5918,1.666v3.7939h-1.0957V1806.3535z"
          />
          <path
            d="M3672.5801,1811.9111c0-1.8975,1.2031-3.3086,2.9297-3.3086c1.7754,0,2.7129,1.3506,2.7129,3.1133v0.3652h-4.5977
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8516,0,1.459-0.4629,1.6543-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C3673.7949,1815.2188,3672.5801,1813.8691,3672.5801,1811.9111z
				 M3673.6855,1811.2422h3.4297c-0.0234-0.9004-0.5098-1.7031-1.6172-1.7031
				C3674.5742,1809.5391,3673.8809,1810.1592,3673.6855,1811.2422z"
          />
          <path d="M3679.0508,1806.3535h1.1914v1.2646h-1.1914V1806.3535z M3680.1934,1815.085h-1.0938v-6.3604h1.0938V1815.085z" />
          <path
            d="M3681.0938,1811.9111c0-1.8125,1.0098-3.3086,2.7734-3.3086c0.8867,0,1.5918,0.4258,1.9688,1.0459v-3.2949h1.0957v8.7314
				h-1.0957v-0.9238c-0.377,0.6318-1.082,1.0576-1.9688,1.0576C3682.1035,1815.2188,3681.0938,1813.7109,3681.0938,1811.9111z
				 M3684.0605,1809.5635c-1.1914,0-1.8594,0.9736-1.8594,2.3477c0,1.3613,0.668,2.3467,1.8594,2.3467
				c0.9004,0,1.8008-0.6562,1.8008-2.1162v-0.4492C3685.8613,1810.208,3684.9609,1809.5635,3684.0605,1809.5635z"
          />
          <path
            d="M3632.4531,1825.9111c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652H3633.5
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3633.6699,1829.2188,3632.4531,1827.8691,3632.4531,1825.9111z
				 M3633.5605,1825.2422h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031
				C3634.4473,1823.5391,3633.7539,1824.1592,3633.5605,1825.2422z"
          />
          <path
            d="M3642.2695,1823.8066c-0.1816-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9238,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0938
				v-6.3604h1.0938v1.082c0.2793-0.6445,0.9004-1.1309,1.7637-1.1309c0.1699,0,0.3281,0.0127,0.4375,0.0244V1823.8066z"
          />
          <path d="M3644.6172,1829.085l-2.3828-6.3604h1.1543l1.7891,5.0225l1.7617-5.0225h1.1445l-2.3848,6.3604H3644.6172z" />
          <path
            d="M3648.1699,1827.3701c0-1.2646,0.9844-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4023-1.1436-1.2539-1.1436
				c-0.8027,0-1.2773,0.377-1.4707,1.083l-1.0332-0.2676c0.3027-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4219,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6797,0.4492v0.876
				c-0.9727,0.1221-1.5312-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.957,0.8271
				C3649.1055,1829.1943,3648.1699,1828.5137,3648.1699,1827.3701z M3652.123,1825.8262l-1.2422,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4512,0.9854,1.1426,0.9854c0.9375,0,1.7285-0.5713,1.7285-1.3867
				V1825.8262z"
          />
          <path
            d="M3657.877,1823.8066c-0.1836-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9258,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0957
				v-6.3604h1.0957v1.082c0.2793-0.6445,0.8984-1.1309,1.7617-1.1309c0.1719,0,0.3301,0.0127,0.4395,0.0244V1823.8066z"
          />
          <path
            d="M3658.1582,1825.9111c0-1.8975,1.2031-3.3086,2.9297-3.3086c1.7754,0,2.7129,1.3506,2.7129,3.1133v0.3652h-4.5977
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8516,0,1.459-0.4629,1.6543-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C3659.373,1829.2188,3658.1582,1827.8691,3658.1582,1825.9111z M3659.2637,1825.2422
				h3.4297c-0.0234-0.9004-0.5098-1.7031-1.6172-1.7031C3660.1523,1823.5391,3659.459,1824.1592,3659.2637,1825.2422z"
          />
          <path
            d="M3664.6777,1822.7246h1.0938v0.8633c0.3652-0.5225,0.998-0.9854,1.8965-0.9854c1.3516,0,2.1172,0.9365,2.1172,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6074-1.5938,1.666v3.7939h-1.0938V1822.7246z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="69">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M2391.2339,2433.5549c0,25.269-20.4839,45.75-45.75,45.75c-25.2649,0-45.75-20.481-45.75-45.75
			c0-25.2659,20.4851-45.75,45.75-45.75C2370.75,2387.8049,2391.2339,2408.2891,2391.2339,2433.5549"
        />
        <g>
          <path
            d="M2316.1348,2400.8496l3.3691,8.7314h-1.1797l-0.8887-2.2988h-3.916l-0.875,2.2988h-1.168l3.3691-8.7314H2316.1348z
				 M2313.9102,2406.2607h3.1367l-1.5801-4.0977L2313.9102,2406.2607z"
          />
          <path
            d="M2319.3848,2407.8662c0-1.2646,0.9844-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4023-1.1436-1.2539-1.1436
				c-0.8027,0-1.2773,0.377-1.4707,1.083l-1.0332-0.2676c0.3027-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4219,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6797,0.4492v0.876
				c-0.9727,0.1221-1.5312-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.957,0.8271
				C2320.3203,2409.6904,2319.3848,2409.0098,2319.3848,2407.8662z M2323.3379,2406.3223l-1.2422,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4512,0.9854,1.1426,0.9854c0.9375,0,1.7285-0.5713,1.7285-1.3867
				V2406.3223z"
          />
          <path
            d="M2325.5508,2403.2207h1.0957v0.8633c0.3633-0.5225,0.9961-0.9854,1.8965-0.9854c1.3496,0,2.1152,0.9365,2.1152,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.377-1.5566-1.3262-1.5566c-0.7891,0-1.5918,0.6074-1.5918,1.666v3.7939h-1.0957V2403.2207z"
          />
          <path
            d="M2335.6348,2403.2207l1.3145,4.7432l1.3262-4.7432h1.0938l-1.9336,6.3604h-0.9609l-1.3262-4.7188l-1.3242,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3125-4.7305H2335.6348z"
          />
          <path
            d="M2339.2734,2406.4072c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652h-4.5957
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C2340.4902,2409.7148,2339.2734,2408.3652,2339.2734,2406.4072z
				 M2340.3809,2405.7383h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031
				C2341.2676,2404.0352,2340.5742,2404.6553,2340.3809,2405.7383z"
          />
          <path d="M2350.1484,2409.5811h-5.0098v-0.8271l3.6719-4.5967h-3.625v-0.9365h4.9258v0.8389l-3.5996,4.585h3.6367V2409.5811z" />
          <path d="M2350.7949,2400.8496h1.1914v1.2646h-1.1914V2400.8496z M2351.9375,2409.5811h-1.0938v-6.3604h1.0938V2409.5811z" />
          <path
            d="M2355.4648,2411.9043c-1.3984,0-2.4219-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8633,1.2891,1.752,1.2891
				c1.1172,0,1.8594-0.5723,1.8594-1.8975v-0.7178c-0.3398,0.6201-1.0566,1.0586-1.9453,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1748c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.875,0,1.6055,0.4385,1.9453,1.0586v-0.9365
				h1.0957v5.7891C2358.4199,2410.8701,2357.1914,2411.9043,2355.4648,2411.9043z M2355.5742,2404.0596
				c-1.1562,0-1.8613,0.8633-1.8613,2.2012s0.7051,2.2139,1.8613,2.2139c0.9121,0,1.7617-0.6816,1.7617-2.0801v-0.2305
				C2357.3359,2404.7412,2356.4863,2404.0596,2355.5742,2404.0596z"
          />
          <path
            d="M2359.5156,2400.8496h1.0957v3.2344c0.3633-0.5225,0.9961-0.9854,1.8965-0.9854c1.3496,0,2.1152,0.9365,2.1152,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.377-1.5566-1.3262-1.5566c-0.7891,0-1.5918,0.6074-1.5918,1.666v3.7939h-1.0957V2400.8496z"
          />
          <path
            d="M2365.209,2406.4072c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652h-4.5957
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C2366.4258,2409.7148,2365.209,2408.3652,2365.209,2406.4072z M2366.3164,2405.7383
				h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031C2367.2031,2404.0352,2366.5098,2404.6553,2366.3164,2405.7383z"
          />
          <path d="M2371.4375,2400.8496h1.1934v1.2646h-1.1934V2400.8496z M2372.582,2409.5811h-1.0957v-6.3604h1.0957V2409.5811z" />
          <path
            d="M2373.2383,2406.4072c0-1.8125,1.0098-3.3086,2.7734-3.3086c0.8867,0,1.5918,0.4258,1.9688,1.0459v-3.2949h1.0957v8.7314
				h-1.0957v-0.9238c-0.377,0.6318-1.082,1.0576-1.9688,1.0576C2374.248,2409.7148,2373.2383,2408.207,2373.2383,2406.4072z
				 M2376.2051,2404.0596c-1.1914,0-1.8594,0.9736-1.8594,2.3477c0,1.3613,0.668,2.3467,1.8594,2.3467
				c0.9004,0,1.8008-0.6562,1.8008-2.1162v-0.4492C2378.0059,2404.7041,2377.1055,2404.0596,2376.2051,2404.0596z"
          />
          <path d="M2306.125,2423.5811l-2.3848-6.3604h1.1562l1.7871,5.0225l1.7637-5.0225h1.1426l-2.3828,6.3604H2306.125z" />
          <path
            d="M2309.4336,2421.8662c0-1.2646,0.9844-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4023-1.1436-1.2539-1.1436
				c-0.8027,0-1.2773,0.377-1.4707,1.083l-1.0332-0.2676c0.3027-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4219,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6797,0.4492v0.876
				c-0.9727,0.1221-1.5312-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.957,0.8271
				C2310.3691,2423.6904,2309.4336,2423.0098,2309.4336,2421.8662z M2313.3867,2420.3223l-1.2422,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4512,0.9854,1.1426,0.9854c0.9375,0,1.7285-0.5713,1.7285-1.3867
				V2420.3223z"
          />
          <path
            d="M2315.5996,2417.2207h1.0957v0.8633c0.3633-0.5225,0.9961-0.9854,1.8965-0.9854c1.3496,0,2.1152,0.9365,2.1152,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.377-1.5566-1.3262-1.5566c-0.7891,0-1.5918,0.6074-1.5918,1.666v3.7939h-1.0957V2417.2207z"
          />
          <path d="M2323.5195,2414.8496h1.1914v1.2646h-1.1914V2414.8496z M2324.6621,2423.5811h-1.0938v-6.3604h1.0938V2423.5811z" />
          <path
            d="M2325.7578,2417.2207h1.0938v0.8633c0.3652-0.5225,0.998-0.9854,1.8965-0.9854c1.3516,0,2.1172,0.9365,2.1172,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6074-1.5938,1.666v3.7939h-1.0938V2417.2207z"
          />
          <path
            d="M2334.6602,2415.7129c-0.1816-0.0244-0.291-0.0244-0.4727-0.0244c-0.6445,0-0.998,0.2793-0.998,1.0947v0.4375h1.4102
				v0.9365h-1.4102v5.4238h-1.0938v-5.4238h-0.9609v-0.9365h0.9609v-0.5107c0-1.2402,0.6797-1.9941,1.9824-1.9941
				c0.2188,0,0.3398,0.0117,0.582,0.0244V2415.7129z"
          />
          <path
            d="M2338.1875,2418.3027c-0.1816-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9238,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0938
				v-6.3604h1.0938v1.082c0.2793-0.6445,0.9004-1.1309,1.7637-1.1309c0.1699,0,0.3281,0.0127,0.4375,0.0244V2418.3027z"
          />
          <path
            d="M2338.1641,2421.8662c0-1.2646,0.9863-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4004-1.1436-1.252-1.1436
				c-0.8027,0-1.2773,0.377-1.4727,1.083l-1.0332-0.2676c0.3047-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4238,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6816,0.4492v0.876
				c-0.9727,0.1221-1.5332-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.959,0.8271
				C2339.1016,2423.6904,2338.1641,2423.0098,2338.1641,2421.8662z M2342.1172,2420.3223l-1.2402,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9355,0,1.7266-0.5713,1.7266-1.3867
				V2420.3223z"
          />
          <path
            d="M2344.4766,2421.6113c0.3418,0.7412,1.1191,1.1914,1.9473,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6094-0.79-1.6543-1.0088c-1.0469-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9844-1.9219,2.3359-1.9219
				c1.1426,0,2.0781,0.5596,2.4551,1.3135l-0.7773,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7305,0-1.2168,0.3652-1.2168,0.9365c0,0.5957,0.5586,0.7051,1.4473,0.8994c1.0938,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1074,2.0186-2.4688,2.0186c-1.1562,0-2.3105-0.499-2.7969-1.4834L2344.4766,2421.6113z"
          />
          <path
            d="M2349.6836,2418.1572h-0.9844v-0.9365h0.9844v-1.8604h1.084v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8867,0.79c0.2559,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2793,0.0605-0.5957,0.0967-0.9609,0.0967
				c-1.082,0-1.7031-0.3643-1.7031-1.5811V2418.1572z"
          />
          <path
            d="M2356.2168,2418.3027c-0.1836-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9258,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0957
				v-6.3604h1.0957v1.082c0.2793-0.6445,0.8984-1.1309,1.7617-1.1309c0.1719,0,0.3301,0.0127,0.4395,0.0244V2418.3027z"
          />
          <path
            d="M2361.7383,2423.5811h-1.0957v-0.876c-0.3633,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3613,0-2.127-0.9365-2.127-2.2861
				v-4.208h1.0938v3.9766c0,0.9004,0.3887,1.5078,1.3262,1.5078c0.7891,0,1.5918-0.5957,1.5918-1.6533v-3.8311h1.0957V2423.5811z"
          />
          <path
            d="M2365.3887,2422.7539c0.875,0,1.3984-0.5596,1.5801-1.3496l0.9727,0.4502c-0.3047,1.0576-1.2285,1.8604-2.5527,1.8604
				c-1.7773,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2266-3.3086,3.0039-3.3086c1.3242,0,2.2246,0.7666,2.5293,1.8242
				l-0.9492,0.4746c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1328,0-1.9102,0.8994-1.9102,2.3594
				C2363.4785,2421.8545,2364.2559,2422.7539,2365.3887,2422.7539z"
          />
          <path
            d="M2368.5859,2418.1572h-0.9844v-0.9365h0.9844v-1.8604h1.084v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8867,0.79c0.2559,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2793,0.0605-0.5957,0.0967-0.9609,0.0967
				c-1.082,0-1.7031-0.3643-1.7031-1.5811V2418.1572z"
          />
          <path
            d="M2376.8691,2423.5811h-1.0938v-0.876c-0.3652,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3633,0-2.1289-0.9365-2.1289-2.2861
				v-4.208h1.0957v3.9766c0,0.9004,0.3887,1.5078,1.3242,1.5078c0.791,0,1.5938-0.5957,1.5938-1.6533v-3.8311h1.0938V2423.5811z"
          />
          <path
            d="M2383.002,2423.5811h-1.0957v-0.876c-0.3633,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3613,0-2.127-0.9365-2.127-2.2861
				v-4.208h1.0938v3.9766c0,0.9004,0.3887,1.5078,1.3262,1.5078c0.7891,0,1.5918-0.5957,1.5918-1.6533v-3.8311h1.0957V2423.5811z"
          />
          <path
            d="M2387.3809,2418.3027c-0.1816-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9238,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0938
				v-6.3604h1.0938v1.082c0.2793-0.6445,0.9004-1.1309,1.7637-1.1309c0.1699,0,0.3281,0.0127,0.4375,0.0244V2418.3027z"
          />
          <path d="M2337.0703,2437.5811l-2.3828-6.3604h1.1543l1.7891,5.0225l1.7617-5.0225h1.1445l-2.3848,6.3604H2337.0703z" />
          <path
            d="M2343.3965,2437.7148c-1.7402,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2148-3.3086,2.9551-3.3086
				c1.7383,0,2.9551,1.3379,2.9551,3.3086C2346.3516,2436.377,2345.1348,2437.7148,2343.3965,2437.7148z M2343.3965,2436.7783
				c1.1172,0,1.8477-0.9238,1.8477-2.3711c0-1.46-0.7305-2.3838-1.8477-2.3838c-1.1191,0-1.8496,0.9238-1.8496,2.3838
				C2341.5469,2435.8545,2342.2773,2436.7783,2343.3965,2436.7783z"
          />
          <path
            d="M2349.5254,2437.7148c-1.7383,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2168-3.3086,2.9551-3.3086
				c1.7402,0,2.9551,1.3379,2.9551,3.3086C2352.4805,2436.377,2351.2656,2437.7148,2349.5254,2437.7148z M2349.5254,2436.7783
				c1.1191,0,1.8496-0.9238,1.8496-2.3711c0-1.46-0.7305-2.3838-1.8496-2.3838s-1.8477,0.9238-1.8477,2.3838
				C2347.6777,2435.8545,2348.4062,2436.7783,2349.5254,2436.7783z"
          />
          <path
            d="M2356.4355,2432.3027c-0.1816-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9238,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0938
				v-6.3604h1.0938v1.082c0.2793-0.6445,0.9004-1.1309,1.7637-1.1309c0.1699,0,0.3281,0.0127,0.4375,0.0244V2432.3027z"
          />
          <path
            d="M2314.8887,2446.1572h-0.9844v-0.9365h0.9844v-1.8604h1.084v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8867,0.79c0.2559,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2793,0.0605-0.5957,0.0967-0.9609,0.0967
				c-1.082,0-1.7031-0.3643-1.7031-1.5811V2446.1572z"
          />
          <path
            d="M2317.6875,2448.4072c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652h-4.5957
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C2318.9043,2451.7148,2317.6875,2450.3652,2317.6875,2448.4072z
				 M2318.7949,2447.7383h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031
				C2319.6816,2446.0352,2318.9883,2446.6553,2318.7949,2447.7383z"
          />
          <path
            d="M2327.2598,2446.3027c-0.1816-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9238,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0938
				v-6.3604h1.0938v1.082c0.2793-0.6445,0.9004-1.1309,1.7637-1.1309c0.1699,0,0.3281,0.0127,0.4375,0.0244V2446.3027z"
          />
          <path
            d="M2332.7832,2451.5811h-1.0957v-0.876c-0.3633,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3613,0-2.127-0.9365-2.127-2.2861
				v-4.208h1.0938v3.9766c0,0.9004,0.3887,1.5078,1.3262,1.5078c0.7891,0,1.5918-0.5957,1.5918-1.6533v-3.8311h1.0957V2451.5811z"
          />
          <path
            d="M2336.2988,2453.9043c-1.3984,0-2.4199-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8652,1.2891,1.752,1.2891
				c1.1191,0,1.8613-0.5723,1.8613-1.8975v-0.7178c-0.3418,0.6201-1.0586,1.0586-1.9473,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1748c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.877,0,1.6055,0.4385,1.9473,1.0586v-0.9365
				h1.0938v5.7891C2339.2539,2452.8701,2338.0254,2453.9043,2336.2988,2453.9043z M2336.4082,2446.0596
				c-1.1543,0-1.8613,0.8633-1.8613,2.2012s0.707,2.2139,1.8613,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C2338.1719,2446.7412,2337.3203,2446.0596,2336.4082,2446.0596z"
          />
          <path
            d="M2344.3027,2445.2207l1.3145,4.7432l1.3262-4.7432h1.0938l-1.9336,6.3604h-0.9609l-1.3262-4.7188l-1.3242,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3125-4.7305H2344.3027z"
          />
          <path d="M2348.332,2442.8496h1.1914v1.2646h-1.1914V2442.8496z M2349.4746,2451.5811h-1.0938v-6.3604h1.0938V2451.5811z" />
          <path
            d="M2350.5684,2445.2207h1.0957v0.8633c0.3633-0.5225,0.9961-0.9854,1.8965-0.9854c1.3496,0,2.1152,0.9365,2.1152,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.377-1.5566-1.3262-1.5566c-0.7891,0-1.5918,0.6074-1.5918,1.666v3.7939h-1.0957V2445.2207z"
          />
          <path
            d="M2356.6992,2445.2207h1.0957v0.8633c0.3633-0.5225,0.9961-0.9854,1.8965-0.9854c1.3496,0,2.1152,0.9365,2.1152,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.377-1.5566-1.3262-1.5566c-0.7891,0-1.5918,0.6074-1.5918,1.666v3.7939h-1.0957V2445.2207z"
          />
          <path d="M2362.7812,2442.8496h1.1934v1.2646h-1.1934V2442.8496z M2363.9258,2451.5811h-1.0957v-6.3604h1.0957V2451.5811z" />
          <path
            d="M2365.0195,2445.2207h1.0957v0.8633c0.3633-0.5225,0.9961-0.9854,1.8965-0.9854c1.3496,0,2.1152,0.9365,2.1152,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.377-1.5566-1.3262-1.5566c-0.7891,0-1.5918,0.6074-1.5918,1.666v3.7939h-1.0957V2445.2207z"
          />
          <path
            d="M2373.584,2453.9043c-1.3984,0-2.4219-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8633,1.2891,1.752,1.2891
				c1.1172,0,1.8594-0.5723,1.8594-1.8975v-0.7178c-0.3398,0.6201-1.0566,1.0586-1.9453,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1748c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.875,0,1.6055,0.4385,1.9453,1.0586v-0.9365
				h1.0957v5.7891C2376.5391,2452.8701,2375.3105,2453.9043,2373.584,2453.9043z M2373.6934,2446.0596
				c-1.1562,0-1.8613,0.8633-1.8613,2.2012s0.7051,2.2139,1.8613,2.2139c0.9121,0,1.7617-0.6816,1.7617-2.0801v-0.2305
				C2375.4551,2446.7412,2374.6055,2446.0596,2373.6934,2446.0596z"
          />
          <path d="M2327.2852,2465.5811l-2.3848-6.3604h1.1562l1.7871,5.0225l1.7637-5.0225h1.1426l-2.3828,6.3604H2327.2852z" />
          <path
            d="M2330.5938,2463.8662c0-1.2646,0.9844-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4023-1.1436-1.2539-1.1436
				c-0.8027,0-1.2773,0.377-1.4707,1.083l-1.0332-0.2676c0.3027-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4219,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6797,0.4492v0.876
				c-0.9727,0.1221-1.5312-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.957,0.8271
				C2331.5293,2465.6904,2330.5938,2465.0098,2330.5938,2463.8662z M2334.5469,2462.3223l-1.2422,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4512,0.9854,1.1426,0.9854c0.9375,0,1.7285-0.5713,1.7285-1.3867
				V2462.3223z"
          />
          <path
            d="M2336.7598,2459.2207h1.0957v0.8633c0.3633-0.5225,0.9961-0.9854,1.8965-0.9854c1.3496,0,2.1152,0.9365,2.1152,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.377-1.5566-1.3262-1.5566c-0.7891,0-1.5918,0.6074-1.5918,1.666v3.7939h-1.0957V2459.2207z"
          />
          <path
            d="M2344.2285,2463.8662c0-1.2646,0.9863-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4004-1.1436-1.252-1.1436
				c-0.8027,0-1.2773,0.377-1.4727,1.083l-1.0332-0.2676c0.3047-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4238,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6816,0.4492v0.876
				c-0.9727,0.1221-1.5332-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.959,0.8271
				C2345.166,2465.6904,2344.2285,2465.0098,2344.2285,2463.8662z M2348.1816,2462.3223l-1.2402,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9355,0,1.7266-0.5713,1.7266-1.3867
				V2462.3223z"
          />
          <path
            d="M2353.1699,2457.7129c-0.1816-0.0244-0.293-0.0244-0.4746-0.0244c-0.6445,0-0.998,0.2793-0.998,1.0947v0.4375h1.4121
				v0.9365h-1.4121v5.4238h-1.0938v-5.4238h-0.9609v-0.9365h0.9609v-0.5107c0-1.2402,0.6816-1.9941,1.9824-1.9941
				c0.2188,0,0.3398,0.0117,0.584,0.0244V2457.7129z"
          />
          <path d="M2355.0312,2465.5811l-2.3828-6.3604h1.1543l1.7891,5.0225l1.7617-5.0225h1.1445l-2.3848,6.3604H2355.0312z" />
          <path
            d="M2358.3398,2463.8662c0-1.2646,0.9844-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4023-1.1436-1.2539-1.1436
				c-0.8027,0-1.2773,0.377-1.4707,1.083l-1.0332-0.2676c0.3027-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4219,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6797,0.4492v0.876
				c-0.9727,0.1221-1.5312-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.957,0.8271
				C2359.2754,2465.6904,2358.3398,2465.0098,2358.3398,2463.8662z M2362.293,2462.3223l-1.2422,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4512,0.9854,1.1426,0.9854c0.9375,0,1.7285-0.5713,1.7285-1.3867
				V2462.3223z"
          />
          <path d="M2365.6016,2465.5811h-1.0938v-8.7314h1.0938V2465.5811z" />
        </g>
      </g>
      <g onClick={handleClick} id="68">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M1800.4771,1506.23c0,25.27-20.4811,45.75-45.75,45.75c-25.266,0-45.75-20.48-45.75-45.75
			c0-25.265,20.484-45.75,45.75-45.75C1779.996,1460.48,1800.4771,1480.965,1800.4771,1506.23"
        />
        <g>
          <path
            d="M1720.2153,1508.9551c-0.3525,0.8018-1.2891,1.4346-2.5415,1.4346c-2.2256,0-3.8794-1.8242-3.8794-4.4756
				c0-2.6631,1.666-4.5234,3.9521-4.5234c1.6782,0,2.8701,0.9121,3.3931,2.4199l-1.1187,0.3643
				c-0.3164-1.1064-1.1191-1.751-2.2622-1.751c-1.6538,0-2.7969,1.4111-2.7969,3.4785c0,2.0547,1.1431,3.4775,2.8091,3.4775
				c1.4106,0,2.3594-1.082,2.3833-2.6387h-2.5049v-1.0215h3.6362v4.5361h-1.0703V1508.9551z"
          />
          <path
            d="M1725.8237,1504.9775c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V1504.9775z"
          />
          <path
            d="M1729.0601,1510.3896c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1732.0151,1509.0518,1730.7988,1510.3896,1729.0601,1510.3896z M1729.0601,1509.4531
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1727.2114,1508.5293,1727.9409,1509.4531,1729.0601,1509.4531z"
          />
          <path
            d="M1732.9165,1503.8955h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1503.8955z"
          />
          <path
            d="M1738.853,1507.082c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1739.8628,1510.3896,1738.853,1508.8818,1738.853,1507.082z
				 M1741.8203,1504.7344c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1743.6206,1505.3789,1742.7207,1504.7344,1741.8203,1504.7344z"
          />
          <path
            d="M1746.1636,1508.2861c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L1746.1636,1508.2861z"
          />
          <path
            d="M1752.5381,1509.332v3.1133h-1.0947v-8.5498h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C1753.6201,1510.3896,1752.915,1509.9639,1752.5381,1509.332z M1752.5137,1507.3125c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6685-2.3477-1.8604-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V1507.3125z"
          />
          <path
            d="M1757.7441,1507.082c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1758.9604,1510.3896,1757.7441,1509.04,1757.7441,1507.082z M1758.8511,1506.4131
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1759.7388,1504.71,1759.0454,1505.3301,1758.8511,1506.4131z"
          />
          <path
            d="M1766.8306,1509.4287c0.8755,0,1.3984-0.5596,1.5811-1.3496l0.9731,0.4502c-0.3042,1.0576-1.2285,1.8604-2.5542,1.8604
				c-1.7754,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2285-3.3086,3.0039-3.3086c1.3257,0,2.2256,0.7666,2.5298,1.8242
				l-0.9487,0.4746c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8994-1.9092,2.3594
				C1764.9214,1508.5293,1765.6997,1509.4287,1766.8306,1509.4287z"
          />
          <path
            d="M1775.1504,1510.2559h-1.0947v-0.876c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V1510.2559z"
          />
          <path d="M1777.5728,1510.2559h-1.0947v-8.7314h1.0947V1510.2559z" />
          <path
            d="M1778.4121,1508.541c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1779.3486,1510.3652,1778.4121,1509.6846,1778.4121,1508.541z M1782.3643,1506.9971l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1506.9971z"
          />
          <path
            d="M1784.9932,1504.832h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2559,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V1504.832z"
          />
          <path d="M1788.4229,1501.5244h1.1919v1.2646h-1.1919V1501.5244z M1789.5664,1510.2559h-1.0947v-6.3604h1.0947V1510.2559z" />
          <path
            d="M1790.4663,1507.082c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1791.6826,1510.3896,1790.4663,1509.04,1790.4663,1507.082z M1791.5732,1506.4131
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1792.4609,1504.71,1791.7676,1505.3301,1791.5732,1506.4131z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="67">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M3054.5161,2135.4839c0,25.2661-20.4851,45.75-45.75,45.75c-25.2661,0-45.75-20.4839-45.75-45.75
			c0-25.2688,20.4839-45.75,45.75-45.75C3034.031,2089.7339,3054.5161,2110.2151,3054.5161,2135.4839"
        />
        <g>
          <path d="M2996.0156,2102.7793v1.0215h-4.5479v2.6875h3.8545v1.0215h-3.8545v2.9795h4.5479v1.0215h-5.7031v-8.7314H2996.0156z" />
          <path
            d="M3000.3115,2106.2324c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V2106.2324z"
          />
          <path d="M3002.6592,2111.5107l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3002.6592z" />
          <path
            d="M3006.2109,2109.7959c0-1.2646,0.9854-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3007.1475,2111.6201,3006.2109,2110.9395,3006.2109,2109.7959z M3010.1641,2108.252l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2108.252z"
          />
          <path
            d="M3015.918,2106.2324c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V2106.2324z"
          />
          <path
            d="M3016.1992,2108.3369c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3017.415,2111.6445,3016.1992,2110.2949,3016.1992,2108.3369z M3017.3057,2107.668
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3018.1934,2105.9648,3017.5,2106.585,3017.3057,2107.668z"
          />
          <path
            d="M3022.7188,2105.1504h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2105.1504z"
          />
          <path d="M2985.9209,2125.5107l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2985.9209z" />
          <path
            d="M2989.5342,2122.3369c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2990.75,2125.6445,2989.5342,2124.2949,2989.5342,2122.3369z M2990.6406,2121.668
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2991.5283,2119.9648,2990.835,2120.585,2990.6406,2121.668z"
          />
          <path
            d="M2999.3496,2120.2324c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V2120.2324z"
          />
          <path
            d="M3000.2139,2123.541c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L3000.2139,2123.541z"
          />
          <path
            d="M3005.6641,2120.0869h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V2120.0869z"
          />
          <path
            d="M3011.6611,2125.6445c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C3014.6162,2124.3066,3013.4004,2125.6445,3011.6611,2125.6445z M3011.6611,2124.708
				c1.1182,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C3009.8125,2123.7842,3010.542,2124.708,3011.6611,2124.708z"
          />
          <path
            d="M3018.8135,2120.2324c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V2120.2324z"
          />
          <path d="M3019.4834,2116.7793h1.1924v1.2646h-1.1924V2116.7793z M3020.627,2125.5107h-1.0947v-6.3604h1.0947V2125.5107z" />
          <path
            d="M3021.9648,2119.1504h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2119.1504z"
          />
          <path
            d="M3030.7725,2127.834c-1.3994,0-2.4209-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5723,1.8604-1.8975v-0.7178c-0.3408,0.6201-1.0576,1.0586-1.9463,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1748c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.4385,1.9463,1.0586v-0.9365
				h1.0947v5.7891C3033.7275,2126.7998,3032.499,2127.834,3030.7725,2127.834z M3030.8818,2119.9893
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012s0.7061,2.2139,1.8613,2.2139c0.9121,0,1.7627-0.6816,1.7627-2.0801v-0.2305
				C3032.6445,2120.6709,3031.7939,2119.9893,3030.8818,2119.9893z"
          />
          <path d="M2994.8799,2139.5107l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2994.8799z" />
          <path
            d="M2998.4316,2137.7959c0-1.2646,0.9854-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2999.3682,2139.6201,2998.4316,2138.9395,2998.4316,2137.7959z M3002.3848,2136.252l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2136.252z"
          />
          <path
            d="M3004.8418,2133.1504h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2133.1504z"
          />
          <path
            d="M3012.8584,2136.3369c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C3013.8672,2139.6445,3012.8584,2138.1367,3012.8584,2136.3369z
				 M3015.8252,2133.9893c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C3017.625,2134.6338,3016.7256,2133.9893,3015.8252,2133.9893z"
          />
          <path
            d="M3019.5859,2136.3369c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3020.8018,2139.6445,3019.5859,2138.2949,3019.5859,2136.3369z M3020.6924,2135.668
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3021.5801,2133.9648,3020.8867,2134.585,3020.6924,2135.668z"
          />
          <path d="M2978.6465,2153.5107l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2978.6465z" />
          <path
            d="M2985.2148,2153.6445c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C2988.1699,2152.3066,2986.9541,2153.6445,2985.2148,2153.6445z M2985.2148,2152.708
				c1.1182,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C2983.3662,2151.7842,2984.0957,2152.708,2985.2148,2152.708z"
          />
          <path
            d="M2988.6338,2150.3369c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2989.8496,2153.6445,2988.6338,2152.2949,2988.6338,2150.3369z M2989.7402,2149.668
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2990.6279,2147.9648,2989.9346,2148.585,2989.7402,2149.668z"
          />
          <path
            d="M2994.7168,2150.3369c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2995.7256,2153.6445,2994.7168,2152.1367,2994.7168,2150.3369z
				 M2997.6836,2147.9893c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2999.4834,2148.6338,2998.584,2147.9893,2997.6836,2147.9893z"
          />
          <path
            d="M3002.0273,2151.541c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L3002.0273,2151.541z"
          />
          <path
            d="M3006.8701,2150.3369c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3008.0859,2153.6445,3006.8701,2152.2949,3006.8701,2150.3369z M3007.9766,2149.668
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3008.8643,2147.9648,3008.1709,2148.585,3007.9766,2149.668z"
          />
          <path d="M3014.4844,2153.5107h-1.0947v-8.7314h1.0947V2153.5107z" />
          <path d="M3017.4521,2153.5107l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3017.4521z" />
          <path
            d="M3024.0205,2153.6445c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C3026.9756,2152.3066,3025.7598,2153.6445,3024.0205,2153.6445z M3024.0205,2152.708
				c1.1182,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C3022.1719,2151.7842,3022.9014,2152.708,3024.0205,2152.708z"
          />
          <path
            d="M3030.3945,2153.6445c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C3033.3496,2152.3066,3032.1338,2153.6445,3030.3945,2153.6445z M3030.3945,2152.708
				c1.1182,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C3028.5459,2151.7842,3029.2754,2152.708,3030.3945,2152.708z"
          />
          <path
            d="M3037.5469,2148.2324c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V2148.2324z"
          />
          <path d="M3037.9736,2149.4736h3.3203v0.9844h-3.3203V2149.4736z" />
          <path d="M2979.4844,2167.5107h-5.0098v-0.8271l3.6719-4.5967h-3.624v-0.9365h4.9258v0.8389l-3.5996,4.585h3.6357V2167.5107z" />
          <path d="M2980.374,2158.7793h1.1924v1.2646h-1.1924V2158.7793z M2981.5176,2167.5107h-1.0947v-6.3604h1.0947V2167.5107z" />
          <path
            d="M2982.417,2164.3369c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2983.6328,2167.6445,2982.417,2166.2949,2982.417,2164.3369z M2983.5234,2163.668
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2984.4111,2161.9648,2983.7178,2162.585,2983.5234,2163.668z"
          />
          <path
            d="M2988.9375,2161.1504h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2161.1504z"
          />
          <path d="M2995.2627,2158.7793h1.1924v1.2646h-1.1924V2158.7793z M2996.4062,2167.5107h-1.0947v-6.3604h1.0947V2167.5107z" />
          <path
            d="M2997.7441,2161.1504h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2161.1504z"
          />
          <path
            d="M3006.5498,2169.834c-1.3994,0-2.4209-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5723,1.8604-1.8975v-0.7178c-0.3408,0.6201-1.0576,1.0586-1.9463,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1748c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.4385,1.9463,1.0586v-0.9365
				h1.0947v5.7891C3009.5049,2168.7998,3008.2764,2169.834,3006.5498,2169.834z M3006.6592,2161.9893
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012s0.7061,2.2139,1.8613,2.2139c0.9121,0,1.7627-0.6816,1.7627-2.0801v-0.2305
				C3008.4219,2162.6709,3007.5713,2161.9893,3006.6592,2161.9893z"
          />
          <path
            d="M3010.9902,2165.541c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L3010.9902,2165.541z"
          />
          <path
            d="M3017.3652,2164.1182l2.8213-2.9678h1.3623l-2.4814,2.5049l2.6875,3.8555h-1.2764l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V2164.1182z"
          />
          <path
            d="M3021.8428,2164.3369c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3023.0586,2167.6445,3021.8428,2166.2949,3021.8428,2164.3369z M3022.9492,2163.668
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3023.8369,2161.9648,3023.1436,2162.585,3022.9492,2163.668z"
          />
          <path
            d="M3028.5322,2162.0869h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V2162.0869z"
          />
          <path
            d="M3031.5742,2164.3369c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3032.79,2167.6445,3031.5742,2166.2949,3031.5742,2164.3369z M3032.6807,2163.668
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3033.5684,2161.9648,3032.875,2162.585,3032.6807,2163.668z"
          />
          <path
            d="M3038.0947,2161.1504h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2161.1504z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="66">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M3398.7771,2135.449c0,25.27-20.4841,45.75-45.75,45.75c-25.2651,0-45.75-20.48-45.75-45.75
			c0-25.2649,20.4849-45.75,45.75-45.75C3378.293,2089.699,3398.7771,2110.1841,3398.7771,2135.449"
        />
        <g>
          <path
            d="M3335.0098,2111.4746l-2.6631-7.1875v7.1875h-1.0947v-8.7305h1.5928l2.6514,7.1738l2.6631-7.1738h1.5449v8.7305h-1.083
				v-7.1875l-2.6748,7.1875H3335.0098z"
          />
          <path
            d="M3340.6064,2109.7598c0-1.2637,0.9854-1.7871,2.4082-2.0781l1.5449-0.3164v-0.2676c0-0.7305-0.4014-1.1445-1.2529-1.1445
				c-0.8027,0-1.2773,0.377-1.4717,1.084l-1.0332-0.2676c0.3037-1.0352,1.2402-1.7773,2.541-1.7773
				c1.4229,0,2.2988,0.6934,2.2988,2.0566v3.1016c0,0.4121,0.2559,0.5469,0.6807,0.4492v0.875
				c-0.9727,0.123-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C3341.543,2111.584,3340.6064,2110.9043,3340.6064,2109.7598z M3344.5596,2108.2168l-1.2412,0.2676
				c-0.9727,0.1934-1.6289,0.4609-1.6289,1.2266c0,0.6211,0.4492,0.9863,1.1426,0.9863c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V2108.2168z"
          />
          <path
            d="M3347.1865,2106.0508h-0.9844v-0.9355h0.9844v-1.8613h1.083v1.8613h1.4473v0.9355h-1.4473v3.7344
				c0,0.668,0.3281,0.7891,0.8877,0.7891c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9375c-0.2803,0.0605-0.5967,0.0957-0.9609,0.0957
				c-1.083,0-1.7031-0.3633-1.7031-1.5801V2106.0508z"
          />
          <path
            d="M3350.2285,2108.3008c0-1.8965,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3418
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3351.4443,2111.6094,3350.2285,2110.2598,3350.2285,2108.3008z M3351.335,2107.6328
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3352.2227,2105.9297,3351.5293,2106.5488,3351.335,2107.6328z"
          />
          <path d="M3360.459,2111.4746l-2.3838-6.3594h1.1553l1.7881,5.0215l1.7627-5.0215h1.1436l-2.3838,6.3594H3360.459z" />
          <path
            d="M3364.0098,2109.7598c0-1.2637,0.9854-1.7871,2.4082-2.0781l1.5449-0.3164v-0.2676c0-0.7305-0.4014-1.1445-1.2529-1.1445
				c-0.8027,0-1.2773,0.377-1.4717,1.084l-1.0332-0.2676c0.3037-1.0352,1.2402-1.7773,2.541-1.7773
				c1.4229,0,2.2988,0.6934,2.2988,2.0566v3.1016c0,0.4121,0.2559,0.5469,0.6807,0.4492v0.875
				c-0.9727,0.123-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C3364.9463,2111.584,3364.0098,2110.9043,3364.0098,2109.7598z M3367.9629,2108.2168l-1.2412,0.2676
				c-0.9727,0.1934-1.6289,0.4609-1.6289,1.2266c0,0.6211,0.4502,0.9863,1.1426,0.9863c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V2108.2168z"
          />
          <path
            d="M3370.4209,2105.1152h1.0947v0.8633c0.3643-0.5234,0.9971-0.9863,1.8965-0.9863c1.3506,0,2.1162,0.9375,2.1162,2.2871
				v4.1953h-1.0938v-3.9023c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.793h-1.0947V2105.1152z"
          />
          <path d="M3315.6631,2117.1445l-0.207,2.918h-0.6572l-0.1943-2.918H3315.6631z" />
          <path
            d="M3316.2588,2122.3008c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0469v-3.2949h1.0947v8.7305
				h-1.0947v-0.9238c-0.377,0.6328-1.082,1.0586-1.9697,1.0586C3317.2676,2125.6094,3316.2588,2124.1016,3316.2588,2122.3008z
				 M3319.2256,2119.9531c-1.1914,0-1.8604,0.9746-1.8604,2.3477c0,1.3613,0.6689,2.3477,1.8604,2.3477
				c0.9004,0,1.7998-0.6562,1.7998-2.1172v-0.4492C3321.0254,2120.5977,3320.126,2119.9531,3319.2256,2119.9531z"
          />
          <path
            d="M3322.9863,2122.3008c0-1.8965,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3418
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3324.2021,2125.6094,3322.9863,2124.2598,3322.9863,2122.3008z
				 M3324.0928,2121.6328h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C3324.9805,2119.9297,3324.2871,2120.5488,3324.0928,2121.6328z"
          />
          <path
            d="M3334.5527,2125.4746h-1.0947v-0.875c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9375-2.1279-2.2871
				v-4.207h1.0938v3.9766c0,0.9004,0.3896,1.5078,1.3262,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6543v-3.8301h1.0947V2125.4746z"
          />
          <path
            d="M3338.3125,2127.7988c-1.3984,0-2.4209-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5723,1.8604-1.8984v-0.7168c-0.3408,0.6191-1.0576,1.0586-1.9463,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1758c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.4395,1.9463,1.0586v-0.9355
				h1.0947v5.7891C3341.2676,2126.7637,3340.0391,2127.7988,3338.3125,2127.7988z M3338.4219,2119.9531
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012s0.7061,2.2148,1.8613,2.2148c0.9121,0,1.7627-0.6816,1.7627-2.0801v-0.2305
				C3340.1846,2120.6348,3339.334,2119.9531,3338.4219,2119.9531z"
          />
          <path
            d="M3342.1699,2122.3008c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0469v-3.2949h1.0947v8.7305
				h-1.0947v-0.9238c-0.377,0.6328-1.082,1.0586-1.9697,1.0586C3343.1787,2125.6094,3342.1699,2124.1016,3342.1699,2122.3008z
				 M3345.1367,2119.9531c-1.1914,0-1.8604,0.9746-1.8604,2.3477c0,1.3613,0.6689,2.3477,1.8604,2.3477
				c0.9004,0,1.7998-0.6562,1.7998-2.1172v-0.4492C3346.9365,2120.5977,3346.0371,2119.9531,3345.1367,2119.9531z"
          />
          <path
            d="M3350.4297,2124.5508v3.1133h-1.0947v-8.5488h1.0947v0.9238c0.377-0.6211,1.082-1.0469,1.9697-1.0469
				c1.7637,0,2.7725,1.4961,2.7725,3.3086c0,1.8008-1.0088,3.3086-2.7725,3.3086
				C3351.5117,2125.6094,3350.8066,2125.1836,3350.4297,2124.5508z M3350.4053,2122.5312c0,1.4609,0.8994,2.1172,1.7998,2.1172
				c1.1914,0,1.8604-0.9863,1.8604-2.3477c0-1.373-0.6689-2.3477-1.8604-2.3477c-0.9004,0-1.7998,0.6445-1.7998,2.1289V2122.5312z"
          />
          <path
            d="M3359.3691,2120.1973c-0.1826-0.0371-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7305-1.666,1.8477v3.4785h-1.0947
				v-6.3594h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0117,0.4385,0.0234V2120.1973z"
          />
          <path
            d="M3362.6055,2125.6094c-1.7393,0-2.9551-1.3379-2.9551-3.3086s1.2158-3.3086,2.9551-3.3086s2.9551,1.3379,2.9551,3.3086
				S3364.3447,2125.6094,3362.6055,2125.6094z M3362.6055,2124.6719c1.1182,0,1.8486-0.9238,1.8486-2.3711
				c0-1.459-0.7305-2.3828-1.8486-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C3360.7568,2123.748,3361.4863,2124.6719,3362.6055,2124.6719z"
          />
          <path
            d="M3366.4629,2119.1152h1.0947v0.8633c0.3643-0.5234,0.9971-0.9863,1.8965-0.9863c1.3506,0,2.1162,0.9375,2.1162,2.2871
				v4.1953h-1.0938v-3.9023c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.793h-1.0947V2119.1152z"
          />
          <path
            d="M3373.9326,2122.082l2.8213-2.9668h1.3623l-2.4814,2.5039l2.6875,3.8555h-1.2764l-2.1895-3.0645l-0.9238,0.9375v2.127
				h-1.0947v-8.7305h1.0947V2122.082z"
          />
          <path
            d="M3378.4092,2122.3008c0-1.8965,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3418
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3379.625,2125.6094,3378.4092,2124.2598,3378.4092,2122.3008z M3379.5156,2121.6328
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3380.4033,2119.9297,3379.71,2120.5488,3379.5156,2121.6328z"
          />
          <path
            d="M3384.9297,2119.1152h1.0947v0.8633c0.3643-0.5234,0.9971-0.9863,1.8965-0.9863c1.3506,0,2.1162,0.9375,2.1162,2.2871
				v4.1953h-1.0938v-3.9023c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.793h-1.0947V2119.1152z"
          />
          <path d="M3392.0684,2117.1445l-0.207,2.918h-0.6572l-0.1943-2.918H3392.0684z" />
          <path
            d="M3345.1738,2139.4746h-1.0947v-6.3594h1.0947v0.8633c0.3398-0.5234,0.9482-0.9863,1.8359-0.9863
				c0.8516,0,1.4473,0.4258,1.7637,1.084c0.3525-0.5234,1.0332-1.084,2.0312-1.084c1.3125,0,2.1035,0.9375,2.1035,2.2871v4.1953
				h-1.0947v-3.9023c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6191-1.5078,1.6523v3.8066h-1.0947v-3.9023
				c0-0.8887-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6191-1.5078,1.6523V2139.4746z"
          />
          <path
            d="M3353.751,2136.3008c0-1.8965,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3418
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3354.9668,2139.6094,3353.751,2138.2598,3353.751,2136.3008z M3354.8574,2135.6328
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3355.7451,2133.9297,3355.0518,2134.5488,3354.8574,2135.6328z"
          />
          <path
            d="M3360.4404,2134.0508h-0.9844v-0.9355h0.9844v-1.8613h1.083v1.8613h1.4473v0.9355h-1.4473v3.7344
				c0,0.668,0.3281,0.7891,0.8877,0.7891c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9375c-0.2803,0.0605-0.5967,0.0957-0.9609,0.0957
				c-1.083,0-1.7031-0.3633-1.7031-1.5801V2134.0508z"
          />
          <path
            d="M3318.6006,2150.3008c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0469v-3.2949h1.0947v8.7305
				h-1.0947v-0.9238c-0.377,0.6328-1.082,1.0586-1.9697,1.0586C3319.6094,2153.6094,3318.6006,2152.1016,3318.6006,2150.3008z
				 M3321.5674,2147.9531c-1.1914,0-1.8604,0.9746-1.8604,2.3477c0,1.3613,0.6689,2.3477,1.8604,2.3477
				c0.9004,0,1.7998-0.6562,1.7998-2.1172v-0.4492C3323.3672,2148.5977,3322.4678,2147.9531,3321.5674,2147.9531z"
          />
          <path
            d="M3330.8125,2153.4746h-1.0947v-0.875c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9375-2.1279-2.2871
				v-4.207h1.0938v3.9766c0,0.9004,0.3896,1.5078,1.3262,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6543v-3.8301h1.0947V2153.4746z"
          />
          <path
            d="M3337.1865,2153.4746h-1.0947v-0.875c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9375-2.1279-2.2871
				v-4.207h1.0947v3.9766c0,0.9004,0.3887,1.5078,1.3252,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6543v-3.8301h1.0947V2153.4746z"
          />
          <path
            d="M3341.8105,2148.1973c-0.1826-0.0371-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7305-1.666,1.8477v3.4785h-1.0947
				v-6.3594h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0117,0.4385,0.0234V2148.1973z"
          />
          <path d="M3347.126,2153.4746h-5.0098v-0.8262l3.6719-4.5977h-3.624v-0.9355h4.9258v0.8379l-3.5996,4.5859h3.6357V2153.4746z" />
          <path
            d="M3347.5654,2151.7598c0-1.2637,0.9854-1.7871,2.4082-2.0781l1.5449-0.3164v-0.2676c0-0.7305-0.4014-1.1445-1.2529-1.1445
				c-0.8027,0-1.2773,0.377-1.4717,1.084l-1.0332-0.2676c0.3037-1.0352,1.2402-1.7773,2.541-1.7773
				c1.4229,0,2.2988,0.6934,2.2988,2.0566v3.1016c0,0.4121,0.2559,0.5469,0.6807,0.4492v0.875
				c-0.9727,0.123-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C3348.502,2153.584,3347.5654,2152.9043,3347.5654,2151.7598z M3351.5186,2150.2168l-1.2412,0.2676
				c-0.9727,0.1934-1.6289,0.4609-1.6289,1.2266c0,0.6211,0.4492,0.9863,1.1426,0.9863c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V2150.2168z"
          />
          <path
            d="M3353.4766,2151.7598c0-1.2637,0.9854-1.7871,2.4082-2.0781l1.5449-0.3164v-0.2676c0-0.7305-0.4014-1.1445-1.2529-1.1445
				c-0.8027,0-1.2773,0.377-1.4717,1.084l-1.0332-0.2676c0.3037-1.0352,1.2402-1.7773,2.541-1.7773
				c1.4229,0,2.2988,0.6934,2.2988,2.0566v3.1016c0,0.4121,0.2559,0.5469,0.6807,0.4492v0.875
				c-0.9727,0.123-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C3354.4131,2153.584,3353.4766,2152.9043,3353.4766,2151.7598z M3357.4297,2150.2168l-1.2412,0.2676
				c-0.9727,0.1934-1.6289,0.4609-1.6289,1.2266c0,0.6211,0.4502,0.9863,1.1426,0.9863c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V2150.2168z"
          />
          <path
            d="M3360.9824,2153.4746h-1.0947v-6.3594h1.0947v0.8633c0.3398-0.5234,0.9482-0.9863,1.8359-0.9863
				c0.8516,0,1.4473,0.4258,1.7637,1.084c0.3525-0.5234,1.0332-1.084,2.0312-1.084c1.3125,0,2.1035,0.9375,2.1035,2.2871v4.1953
				h-1.0947v-3.9023c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6191-1.5078,1.6523v3.8066h-1.0947v-3.9023
				c0-0.8887-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6191-1.5078,1.6523V2153.4746z"
          />
          <path
            d="M3369.9971,2144.7441h1.0947v3.2344c0.3643-0.5234,0.9971-0.9863,1.8965-0.9863c1.3506,0,2.1162,0.9375,2.1162,2.2871
				v4.1953h-1.0938v-3.9023c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.793h-1.0947V2144.7441z"
          />
          <path
            d="M3375.9336,2150.3008c0-1.8965,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3418
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3377.1494,2153.6094,3375.9336,2152.2598,3375.9336,2150.3008z M3377.04,2149.6328
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3377.9277,2147.9297,3377.2344,2148.5488,3377.04,2149.6328z"
          />
          <path d="M3382.4043,2144.7441h1.1924v1.2637h-1.1924V2144.7441z M3383.5479,2153.4746h-1.0947v-6.3594h1.0947V2153.4746z" />
          <path d="M3384.5938,2149.4375h3.3203v0.9844h-3.3203V2149.4375z" />
          <path
            d="M3329.3906,2164.3008c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0469v-3.2949h1.0947v8.7305
				h-1.0947v-0.9238c-0.377,0.6328-1.082,1.0586-1.9697,1.0586C3330.3994,2167.6094,3329.3906,2166.1016,3329.3906,2164.3008z
				 M3332.3574,2161.9531c-1.1914,0-1.8604,0.9746-1.8604,2.3477c0,1.3613,0.6689,2.3477,1.8604,2.3477
				c0.9004,0,1.7998-0.6562,1.7998-2.1172v-0.4492C3334.1572,2162.5977,3333.2578,2161.9531,3332.3574,2161.9531z"
          />
          <path
            d="M3336.7012,2165.5059c0.3408,0.7402,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				s-0.6084-0.791-1.6543-1.0098c-1.0459-0.2305-2.1396-0.4609-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5605,2.4561,1.3145l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0352-1.7266-1.0352
				c-0.7295,0-1.2158,0.3652-1.2158,0.9375c0,0.5957,0.5586,0.7051,1.4463,0.8984c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2168-1.1064,2.0195-2.4688,2.0195c-1.1553,0-2.3105-0.5-2.7969-1.4844L3336.7012,2165.5059z"
          />
          <path
            d="M3344.4131,2169.7988c-1.3994,0-2.4209-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5723,1.8604-1.8984v-0.7168c-0.3408,0.6191-1.0576,1.0586-1.9463,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1758c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.4395,1.9463,1.0586v-0.9355
				h1.0947v5.7891C3347.3682,2168.7637,3346.1396,2169.7988,3344.4131,2169.7988z M3344.5225,2161.9531
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012s0.7061,2.2148,1.8613,2.2148c0.9121,0,1.7627-0.6816,1.7627-2.0801v-0.2305
				C3346.2852,2162.6348,3345.4346,2161.9531,3344.5225,2161.9531z"
          />
          <path
            d="M3348.2705,2164.3008c0-1.8965,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3516,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3418
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3349.4863,2167.6094,3348.2705,2166.2598,3348.2705,2164.3008z M3349.377,2163.6328
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3350.2646,2161.9297,3349.5713,2162.5488,3349.377,2163.6328z"
          />
          <path
            d="M3354.3535,2164.3008c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0469v-3.2949h1.0947v8.7305
				h-1.0947v-0.9238c-0.377,0.6328-1.082,1.0586-1.9697,1.0586C3355.3623,2167.6094,3354.3535,2166.1016,3354.3535,2164.3008z
				 M3357.3203,2161.9531c-1.1914,0-1.8604,0.9746-1.8604,2.3477c0,1.3613,0.6689,2.3477,1.8604,2.3477
				c0.9004,0,1.7998-0.6562,1.7998-2.1172v-0.4492C3359.1201,2162.5977,3358.2207,2161.9531,3357.3203,2161.9531z"
          />
          <path
            d="M3364.8145,2162.1973c-0.1826-0.0371-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7305-1.666,1.8477v3.4785h-1.0947
				v-6.3594h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0117,0.4385,0.0234V2162.1973z"
          />
          <path
            d="M3365.0332,2165.7598c0-1.2637,0.9854-1.7871,2.4082-2.0781l1.5449-0.3164v-0.2676c0-0.7305-0.4014-1.1445-1.2529-1.1445
				c-0.8027,0-1.2773,0.377-1.4717,1.084l-1.0332-0.2676c0.3037-1.0352,1.2402-1.7773,2.541-1.7773
				c1.4229,0,2.2988,0.6934,2.2988,2.0566v3.1016c0,0.4121,0.2559,0.5469,0.6807,0.4492v0.875
				c-0.9727,0.123-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C3365.9697,2167.584,3365.0332,2166.9043,3365.0332,2165.7598z M3368.9863,2164.2168l-1.2412,0.2676
				c-0.9727,0.1934-1.6289,0.4609-1.6289,1.2266c0,0.6211,0.4502,0.9863,1.1426,0.9863c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V2164.2168z"
          />
          <path
            d="M3373.877,2169.7988c-1.3984,0-2.4209-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5723,1.8604-1.8984v-0.7168c-0.3408,0.6191-1.0576,1.0586-1.9463,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1758c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.4395,1.9463,1.0586v-0.9355
				h1.0947v5.7891C3376.832,2168.7637,3375.6035,2169.7988,3373.877,2169.7988z M3373.9863,2161.9531
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012s0.7061,2.2148,1.8613,2.2148c0.9121,0,1.7627-0.6816,1.7627-2.0801v-0.2305
				C3375.749,2162.6348,3374.8984,2161.9531,3373.9863,2161.9531z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="65">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M2666.824,331.5862c0,25.269-20.4839,45.75-45.75,45.75c-25.269,0-45.75-20.481-45.75-45.75
			c0-25.2661,20.481-45.75,45.75-45.75C2646.3401,285.8362,2666.824,306.3201,2666.824,331.5862"
        />
        <g>
          <path d="M2600.3457,307.6123l-3.3203-8.7314h1.2529l2.7002,7.2725l2.6992-7.2725h1.1924l-3.2959,8.7314H2600.3457z" />
          <path
            d="M2604.7021,304.4385c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2605.918,307.7461,2604.7021,306.3965,2604.7021,304.4385z M2605.8086,303.7695
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C2606.6963,302.0674,2606.0029,302.6875,2605.8086,303.7695z"
          />
          <path
            d="M2614.1533,302.335c-0.1826-0.0371-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.4775h-1.0947
				v-6.3604h1.0947v1.083c0.2793-0.6445,0.8994-1.1318,1.7627-1.1318c0.1709,0,0.3291,0.0127,0.4385,0.0244V302.335z"
          />
          <path d="M2615.6016,307.6123h-1.0947v-8.7314h1.0947V307.6123z" />
          <path
            d="M2616.0752,305.8975c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2529-1.1426
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0547v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4502v0.875
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C2617.0117,307.7217,2616.0752,307.041,2616.0752,305.8975z M2620.0283,304.3535l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9844,1.1426,0.9844c0.9365,0,1.7275-0.5713,1.7275-1.3857
				V304.3535z"
          />
          <path
            d="M2622.1211,301.252h1.0947v0.8633c0.3643-0.5225,0.9971-0.9844,1.8965-0.9844c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1953h-1.0938v-3.9033c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V301.252z"
          />
          <path
            d="M2630.5635,309.9355c-1.3994,0-2.4209-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.8975v-0.7178c-0.3408,0.6211-1.0576,1.0586-1.9463,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1738c0-1.7275,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.4375,1.9463,1.0576v-0.9365
				h1.0947v5.7891C2633.5186,308.9014,2632.29,309.9355,2630.5635,309.9355z M2630.6729,302.0918
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3369,0.7061,2.2129,1.8613,2.2129c0.9121,0,1.7627-0.6807,1.7627-2.0791v-0.2314
				C2632.4355,302.7725,2631.585,302.0918,2630.6729,302.0918z"
          />
          <path
            d="M2634.0557,304.4385c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2635.2715,307.7461,2634.0557,306.3965,2634.0557,304.4385z M2635.1621,303.7695
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C2636.0498,302.0674,2635.3564,302.6875,2635.1621,303.7695z"
          />
          <path
            d="M2640.2109,301.252h1.0947v0.8633c0.3643-0.5225,0.9971-0.9844,1.8965-0.9844c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1953h-1.0938v-3.9033c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V301.252z"
          />
          <path
            d="M2580.1035,321.7461c-1.7393,0-2.9551-1.3379-2.9551-3.3076s1.2158-3.3076,2.9551-3.3076s2.9551,1.3379,2.9551,3.3076
				S2581.8428,321.7461,2580.1035,321.7461z M2580.1035,320.8096c1.1182,0,1.8486-0.9238,1.8486-2.3711
				c0-1.459-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9248-1.8486,2.3838
				C2578.2549,319.8857,2578.9844,320.8096,2580.1035,320.8096z"
          />
          <path
            d="M2584.6904,321.6123h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5225,0.9482-0.9844,1.8359-0.9844
				c0.8516,0,1.4473,0.4258,1.7637,1.082c0.3525-0.5225,1.0332-1.082,2.0312-1.082c1.3135,0,2.1035,0.9365,2.1035,2.2861v4.1953
				h-1.0947v-3.9033c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V321.6123z"
          />
          <path
            d="M2596.1494,320.6885v0.9238h-1.0947v-8.7314h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4951,2.7725,3.3076c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C2597.2314,321.7461,2596.5264,321.3203,2596.1494,320.6885z M2596.125,318.6699c0,1.459,0.8994,2.1152,1.7998,2.1152
				c1.1914,0,1.8604-0.9844,1.8604-2.3467c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V318.6699z"
          />
          <path
            d="M2603.9463,321.7461c-1.7393,0-2.9551-1.3379-2.9551-3.3076s1.2158-3.3076,2.9551-3.3076s2.9551,1.3379,2.9551,3.3076
				S2605.6855,321.7461,2603.9463,321.7461z M2603.9463,320.8096c1.1182,0,1.8486-0.9238,1.8486-2.3711
				c0-1.459-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9248-1.8486,2.3838
				C2602.0977,319.8857,2602.8271,320.8096,2603.9463,320.8096z"
          />
          <path
            d="M2609.9551,321.7461c-1.7393,0-2.9551-1.3379-2.9551-3.3076s1.2158-3.3076,2.9551-3.3076s2.9551,1.3379,2.9551,3.3076
				S2611.6943,321.7461,2609.9551,321.7461z M2609.9551,320.8096c1.1182,0,1.8486-0.9238,1.8486-2.3711
				c0-1.459-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9248-1.8486,2.3838
				C2608.1064,319.8857,2608.8359,320.8096,2609.9551,320.8096z"
          />
          <path
            d="M2613.0098,318.4385c0-1.8125,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2614.0186,321.7461,2613.0098,320.2383,2613.0098,318.4385z
				 M2615.9766,316.0918c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1152v-0.4502C2617.7764,316.7363,2616.877,316.0918,2615.9766,316.0918z"
          />
          <path
            d="M2619.9551,319.6426c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0088
				c0-0.6934-0.6084-0.791-1.6543-1.0098c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9209,2.335-1.9209
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.9004c1.0947,0.2549,2.3477,0.4736,2.3477,1.8604
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.498-2.7969-1.4834L2619.9551,319.6426z"
          />
          <path
            d="M2627.4365,320.7852c0.875,0,1.3984-0.5586,1.5811-1.3496l0.9727,0.4502c-0.3047,1.0576-1.2285,1.8604-2.5537,1.8604
				c-1.7764,0-3.0039-1.3496-3.0039-3.3076c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7656,2.5293,1.8242
				l-0.9482,0.4736c-0.1826-0.7783-0.7061-1.3496-1.5811-1.3496c-1.1318,0-1.9102,0.9004-1.9102,2.3594
				C2625.5264,319.8857,2626.3047,320.7852,2627.4365,320.7852z"
          />
          <path
            d="M2630.3438,312.8809h1.0947v3.2344c0.3643-0.5225,0.9971-0.9844,1.8965-0.9844c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1953h-1.0938v-3.9033c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V312.8809z"
          />
          <path
            d="M2635.8545,319.8975c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2529-1.1426
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0547v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4502v0.875
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C2636.791,321.7217,2635.8545,321.041,2635.8545,319.8975z M2639.8076,318.3535l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9844,1.1426,0.9844c0.9365,0,1.7275-0.5713,1.7275-1.3857
				V318.3535z"
          />
          <path
            d="M2642.9951,320.6885v3.1133h-1.0947v-8.5498h1.0947v0.9248c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4951,2.7725,3.3076c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C2644.0771,321.7461,2643.3721,321.3203,2642.9951,320.6885z M2642.9707,318.6699c0,1.459,0.8994,2.1152,1.7998,2.1152
				c1.1914,0,1.8604-0.9844,1.8604-2.3467c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V318.6699z"
          />
          <path
            d="M2649.3691,320.6885v3.1133h-1.0947v-8.5498h1.0947v0.9248c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4951,2.7725,3.3076c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C2650.4512,321.7461,2649.7461,321.3203,2649.3691,320.6885z M2649.3447,318.6699c0,1.459,0.8994,2.1152,1.7998,2.1152
				c1.1914,0,1.8604-0.9844,1.8604-2.3467c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V318.6699z"
          />
          <path
            d="M2654.2109,318.4385c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2655.4268,321.7461,2654.2109,320.3965,2654.2109,318.4385z M2655.3174,317.7695
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C2656.2051,316.0674,2655.5117,316.6875,2655.3174,317.7695z"
          />
          <path
            d="M2660.3662,315.252h1.0947v0.8633c0.3643-0.5225,0.9971-0.9844,1.8965-0.9844c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1953h-1.0938v-3.9033c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V315.252z"
          />
          <path
            d="M2588.4062,330.1885h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.791,0.8877,0.791c0.2549,0,0.4375-0.0127,0.6934-0.0732v0.9365c-0.2803,0.0605-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V330.1885z"
          />
          <path
            d="M2591.083,332.4385c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2592.2988,335.7461,2591.083,334.3965,2591.083,332.4385z M2592.1895,331.7695
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C2593.0771,330.0674,2592.3838,330.6875,2592.1895,331.7695z"
          />
          <path
            d="M2601.5195,334.7852c0.875,0,1.3984-0.5586,1.5801-1.3496l0.9736,0.4502c-0.3047,1.0576-1.2285,1.8604-2.5537,1.8604
				c-1.7764,0-3.0039-1.3496-3.0039-3.3076c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7656,2.5293,1.8242
				l-0.9492,0.4736c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9102,0.9004-1.9102,2.3594
				C2599.6094,333.8857,2600.3877,334.7852,2601.5195,334.7852z"
          />
          <path
            d="M2606.9443,335.7461c-1.7393,0-2.9551-1.3379-2.9551-3.3076s1.2158-3.3076,2.9551-3.3076s2.9551,1.3379,2.9551,3.3076
				S2608.6836,335.7461,2606.9443,335.7461z M2606.9443,334.8096c1.1182,0,1.8486-0.9238,1.8486-2.3711
				c0-1.459-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9248-1.8486,2.3838
				C2605.0957,333.8857,2605.8252,334.8096,2606.9443,334.8096z"
          />
          <path
            d="M2611.5303,335.6123h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5225,0.9482-0.9844,1.8359-0.9844
				c0.8516,0,1.4473,0.4258,1.7637,1.082c0.3525-0.5225,1.0332-1.082,2.0312-1.082c1.3135,0,2.1035,0.9365,2.1035,2.2861v4.1953
				h-1.0947v-3.9033c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V335.6123z"
          />
          <path
            d="M2621.2744,334.6885v0.9238h-1.0947v-8.7314h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4951,2.7725,3.3076c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C2622.3564,335.7461,2621.6514,335.3203,2621.2744,334.6885z M2621.25,332.6699c0,1.459,0.8994,2.1152,1.7998,2.1152
				c1.1914,0,1.8604-0.9844,1.8604-2.3467c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V332.6699z"
          />
          <path d="M2626.5049,326.8809h1.1924v1.2646h-1.1924V326.8809z M2627.6484,335.6123h-1.0947v-6.3604h1.0947V335.6123z" />
          <path
            d="M2628.6211,329.252h1.0947v0.8633c0.3643-0.5225,0.9971-0.9844,1.8965-0.9844c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1953h-1.0938v-3.9033c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V329.252z"
          />
          <path
            d="M2634.1934,332.4385c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2635.4092,335.7461,2634.1934,334.3965,2634.1934,332.4385z M2635.2998,331.7695
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C2636.1875,330.0674,2635.4941,330.6875,2635.2998,331.7695z"
          />
          <path
            d="M2643.6445,330.335c-0.1826-0.0371-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.4775h-1.0947
				v-6.3604h1.0947v1.083c0.2793-0.6445,0.8994-1.1318,1.7627-1.1318c0.1709,0,0.3291,0.0127,0.4385,0.0244V330.335z"
          />
          <path
            d="M2643.5605,332.4385c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2644.7764,335.7461,2643.5605,334.3965,2643.5605,332.4385z M2644.667,331.7695
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C2645.5547,330.0674,2644.8613,330.6875,2644.667,331.7695z"
          />
          <path
            d="M2649.7158,329.252h1.0947v0.8633c0.3643-0.5225,0.9971-0.9844,1.8965-0.9844c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1953h-1.0938v-3.9033c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V329.252z"
          />
          <path
            d="M2596.3438,349.6123h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5225,0.9482-0.9844,1.8359-0.9844
				c0.8516,0,1.4473,0.4258,1.7637,1.082c0.3525-0.5225,1.0332-1.082,2.0312-1.082c1.3125,0,2.1035,0.9365,2.1035,2.2861v4.1953
				h-1.0947v-3.9033c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V349.6123z"
          />
          <path
            d="M2604.5557,346.4385c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2605.7715,349.7461,2604.5557,348.3965,2604.5557,346.4385z M2605.6621,345.7695
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C2606.5498,344.0674,2605.8564,344.6875,2605.6621,345.7695z"
          />
          <path
            d="M2610.8809,344.1885h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.791,0.8877,0.791c0.2549,0,0.4375-0.0127,0.6934-0.0732v0.9365c-0.2803,0.0605-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V344.1885z"
          />
          <path
            d="M2615.2109,347.8975c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2529-1.1426
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0547v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4502v0.875
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C2616.1475,349.7217,2615.2109,349.041,2615.2109,347.8975z M2619.1641,346.3535l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9844,1.1426,0.9844c0.9365,0,1.7275-0.5713,1.7275-1.3857
				V346.3535z"
          />
          <path
            d="M2621.2568,343.252h1.0947v0.8633c0.3643-0.5225,0.9971-0.9844,1.8965-0.9844c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1953h-1.0938v-3.9033c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V343.252z"
          />
          <path
            d="M2626.8281,346.4385c0-1.8125,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2627.8369,349.7461,2626.8281,348.2383,2626.8281,346.4385z
				 M2629.7949,344.0918c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1152v-0.4502C2631.5947,344.7363,2630.6953,344.0918,2629.7949,344.0918z"
          />
          <path
            d="M2633.1904,346.4385c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2634.4062,349.7461,2633.1904,348.3965,2633.1904,346.4385z M2634.2969,345.7695
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C2635.1846,344.0674,2634.4912,344.6875,2634.2969,345.7695z"
          />
          <path
            d="M2642.6416,344.335c-0.1826-0.0371-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.4775h-1.0947
				v-6.3604h1.0947v1.083c0.2793-0.6445,0.8994-1.1318,1.7627-1.1318c0.1709,0,0.3291,0.0127,0.4385,0.0244V344.335z"
          />
          <path
            d="M2642.5576,346.4385c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2643.7734,349.7461,2642.5576,348.3965,2642.5576,346.4385z M2643.6641,345.7695
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C2644.5518,344.0674,2643.8584,344.6875,2643.6641,345.7695z"
          />
          <path
            d="M2604.791,363.6123h-1.0947v-0.875c-0.3643,0.5352-0.9844,1.0088-1.8848,1.0088c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0938v3.9766c0,0.9004,0.3896,1.5088,1.3262,1.5088c0.79,0,1.5928-0.5967,1.5928-1.6543v-3.8311h1.0947V363.6123z"
          />
          <path d="M2605.7051,354.8809h1.1924v1.2646h-1.1924V354.8809z M2606.8486,363.6123h-1.0947v-6.3604h1.0947V363.6123z" />
          <path
            d="M2607.9912,358.1885h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.791,0.8877,0.791c0.2549,0,0.4375-0.0127,0.6934-0.0732v0.9365c-0.2803,0.0605-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V358.1885z"
          />
          <path
            d="M2611.251,361.6426c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0088
				c0-0.6934-0.6084-0.791-1.6543-1.0098c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9209,2.335-1.9209
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.9004c1.0947,0.2549,2.3477,0.4736,2.3477,1.8604
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.498-2.7969-1.4834L2611.251,361.6426z"
          />
          <path
            d="M2616.3359,358.1885h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.791,0.8877,0.791c0.2549,0,0.4375-0.0127,0.6934-0.0732v0.9365c-0.2803,0.0605-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V358.1885z"
          />
          <path
            d="M2618.9512,361.8975c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2529-1.1426
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0547v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4502v0.875
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C2619.8877,363.7217,2618.9512,363.041,2618.9512,361.8975z M2622.9043,360.3535l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9844,1.1426,0.9844c0.9365,0,1.7275-0.5713,1.7275-1.3857
				V360.3535z"
          />
          <path
            d="M2626.0918,362.6885v3.1133h-1.0947v-8.5498h1.0947v0.9248c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4951,2.7725,3.3076c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C2627.1738,363.7461,2626.4688,363.3203,2626.0918,362.6885z M2626.0674,360.6699c0,1.459,0.8994,2.1152,1.7998,2.1152
				c1.1914,0,1.8604-0.9844,1.8604-2.3467c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V360.6699z"
          />
          <path
            d="M2631.3711,357.252h1.0947v6.8838c0,1.1309-0.7061,1.7021-1.7881,1.7021c-0.2549,0-0.4131-0.0117-0.6074-0.0361v-0.9492
				c0.1328,0.0244,0.2432,0.0371,0.4248,0.0371c0.6328,0,0.876-0.3037,0.876-0.9492V357.252z M2631.3223,354.8809h1.1924v1.2646
				h-1.1924V354.8809z"
          />
          <path
            d="M2633.001,360.4385c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2634.2168,363.7461,2633.001,362.3965,2633.001,360.4385z M2634.1074,359.7695
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C2634.9951,358.0674,2634.3018,358.6875,2634.1074,359.7695z"
          />
          <path
            d="M2639.3018,361.6426c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0088
				c0-0.6934-0.6084-0.791-1.6543-1.0098c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9209,2.335-1.9209
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.9004c1.0947,0.2549,2.3477,0.4736,2.3477,1.8604
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.498-2.7969-1.4834L2639.3018,361.6426z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="64">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M1817.8521,1230.297c0,25.265-20.4811,45.75-45.75,45.75c-25.266,0-45.75-20.485-45.75-45.75
			c0-25.27,20.484-45.75,45.75-45.75C1797.371,1184.547,1817.8521,1205.027,1817.8521,1230.297"
        />
        <g>
          <path d="M1749.7373,1221.2959h-1.0532v-7.8774h1.0532V1221.2959z" />
          <path
            d="M1750.6602,1215.5581h0.9873v0.7788c0.3291-0.4717,0.8999-0.8887,1.7114-0.8887c1.2183,0,1.9092,0.8447,1.9092,2.0625
				v3.7852h-0.9873v-3.5215c0-0.8013-0.3403-1.4043-1.1958-1.4043c-0.7134,0-1.4375,0.5483-1.4375,1.5029v3.4229h-0.9873V1215.5581z
				"
          />
          <path
            d="M1758.3984,1220.5498c0.79,0,1.2617-0.5044,1.4263-1.2178l0.8774,0.4058c-0.2739,0.9546-1.1079,1.6787-2.3037,1.6787
				c-1.6016,0-2.71-1.2178-2.71-2.9839c0-1.7773,1.1084-2.9844,2.71-2.9844c1.1958,0,2.0078,0.6914,2.2822,1.6455l-0.856,0.4282
				c-0.1646-0.7021-0.6362-1.2178-1.4263-1.2178c-1.0205,0-1.7227,0.8115-1.7227,2.1284
				C1756.6758,1219.7378,1757.3779,1220.5498,1758.3984,1220.5498z"
          />
          <path d="M1762.0112,1221.2959h-0.9873v-7.8774h0.9873V1221.2959z" />
          <path
            d="M1767.4434,1221.2959h-0.9873v-0.79c-0.3296,0.4829-0.8887,0.9106-1.7007,0.9106c-1.229,0-1.9199-0.8447-1.9199-2.0625
				v-3.7959h0.9873v3.5874c0,0.812,0.3511,1.3604,1.1958,1.3604c0.7134,0,1.4375-0.5376,1.4375-1.4922v-3.4556h0.9873V1221.2959z"
          />
          <path
            d="M1768.4453,1219.5186c0.3071,0.6694,1.0093,1.0752,1.7554,1.0752c0.6689,0,1.2617-0.2852,1.2617-0.9106
				s-0.5488-0.7129-1.4922-0.9106c-0.9434-0.2085-1.9312-0.417-1.9312-1.5908c0-1.02,0.8887-1.7334,2.1064-1.7334
				c1.0312,0,1.8765,0.5049,2.2163,1.1851l-0.7021,0.5596c-0.2964-0.5708-0.8335-0.9326-1.5576-0.9326
				c-0.6587,0-1.0972,0.3291-1.0972,0.8447c0,0.5376,0.5044,0.6362,1.3052,0.812c0.9878,0.23,2.1177,0.4277,2.1177,1.6782
				c0,1.0972-0.9985,1.8213-2.2271,1.8213c-1.0425,0-2.0845-0.4497-2.5234-1.3384L1768.4453,1219.5186z"
          />
          <path d="M1772.8369,1213.4185h1.0752v1.1411h-1.0752V1213.4185z M1773.8682,1221.2959h-0.9873v-5.7378h0.9873V1221.2959z" />
          <path
            d="M1774.3521,1218.4326c0-1.7114,1.0864-2.9844,2.644-2.9844c1.6021,0,2.4468,1.2178,2.4468,2.8086v0.3291h-4.147
				c0.0547,1.207,0.7456,1.9751,1.7441,1.9751c0.7681,0,1.3164-0.417,1.4922-1.0972l0.8667,0.3071
				c-0.3511,1.02-1.2178,1.6455-2.3589,1.6455C1775.4492,1221.4165,1774.3521,1220.1987,1774.3521,1218.4326z M1775.3506,1217.8291
				h3.0938c-0.022-0.812-0.4609-1.5361-1.459-1.5361C1776.1514,1216.293,1775.5259,1216.8525,1775.3506,1217.8291z"
          />
          <path d="M1782.9248,1221.2959l-2.1504-5.7378h1.0425l1.6128,4.5308l1.5908-4.5308h1.0312l-2.1504,5.7378H1782.9248z" />
          <path
            d="M1785.8022,1219.749c0-1.1411,0.8887-1.6128,2.1724-1.876l1.3931-0.2852v-0.2417c0-0.6582-0.3618-1.0312-1.1299-1.0312
				c-0.7241,0-1.1519,0.3403-1.3276,0.9766l-0.9326-0.2412c0.2744-0.9326,1.1191-1.6021,2.293-1.6021
				c1.2837,0,2.0737,0.6255,2.0737,1.854v2.7979c0,0.373,0.2305,0.4937,0.6143,0.4058v0.79
				c-0.8774,0.1099-1.3823-0.0986-1.5029-0.6475c-0.3511,0.4502-0.9873,0.7461-1.7661,0.7461
				C1786.647,1221.3945,1785.8022,1220.7803,1785.8022,1219.749z M1789.3677,1218.3555l-1.1191,0.2417
				c-0.8774,0.1753-1.4697,0.4165-1.4697,1.1079c0,0.5596,0.4058,0.8887,1.0312,0.8887c0.8447,0,1.5576-0.5156,1.5576-1.2505
				V1218.3555z"
          />
          <path
            d="M1791.2578,1215.5581h0.9873v0.7788c0.3291-0.4717,0.8999-0.8887,1.7114-0.8887c1.2183,0,1.9092,0.8447,1.9092,2.0625
				v3.7852h-0.9873v-3.5215c0-0.8013-0.3403-1.4043-1.1958-1.4043c-0.7134,0-1.4375,0.5483-1.4375,1.5029v3.4229h-0.9873V1215.5581z
				"
          />
          <path
            d="M1725.0151,1231.0674c0-1.6348,0.9106-2.9844,2.5015-2.9844c0.8008,0,1.4375,0.3843,1.7773,0.9434v-2.9731h0.9873v7.8774
				h-0.9873v-0.834c-0.3398,0.5708-0.9766,0.9546-1.7773,0.9546C1725.9258,1234.0513,1725.0151,1232.6909,1725.0151,1231.0674z
				 M1727.6924,1228.9497c-1.0752,0-1.6787,0.8779-1.6787,2.1177c0,1.2285,0.6035,2.1172,1.6787,2.1172
				c0.8115,0,1.6235-0.5923,1.6235-1.9087v-0.4062C1729.3159,1229.5312,1728.5039,1228.9497,1727.6924,1228.9497z"
          />
          <path
            d="M1735.7051,1233.9307h-0.9873v-0.79c-0.3296,0.4829-0.8887,0.9106-1.7007,0.9106c-1.229,0-1.9199-0.8447-1.9199-2.0625
				v-3.7959h0.9873v3.5874c0,0.812,0.3511,1.3604,1.1958,1.3604c0.7134,0,1.4375-0.5376,1.4375-1.4922v-3.4556h0.9873V1233.9307z"
          />
          <path
            d="M1741.1284,1233.9307h-0.9878v-0.79c-0.3291,0.4829-0.8882,0.9106-1.7002,0.9106c-1.229,0-1.9199-0.8447-1.9199-2.0625
				v-3.7959h0.9873v3.5874c0,0.812,0.3511,1.3604,1.1958,1.3604c0.7134,0,1.437-0.5376,1.437-1.4922v-3.4556h0.9878V1233.9307z"
          />
          <path
            d="M1744.9717,1229.1694c-0.1646-0.0332-0.2959-0.0439-0.4824-0.0439c-0.834,0-1.5034,0.6582-1.5034,1.6675v3.1377h-0.9873
				v-5.7378h0.9873v0.9766c0.2524-0.5815,0.812-1.0205,1.5908-1.0205c0.1538,0,0.2964,0.0107,0.395,0.022V1229.1694z"
          />
          <path d="M1749.439,1233.9307h-4.52v-0.7461l3.3135-4.147h-3.2695v-0.8447h4.4434v0.7568l-3.2476,4.1362h3.2803V1233.9307z" />
          <path
            d="M1749.5083,1232.3838c0-1.1411,0.8887-1.6128,2.1724-1.876l1.3931-0.2852v-0.2417c0-0.6582-0.3618-1.0312-1.1299-1.0312
				c-0.7241,0-1.1519,0.3403-1.3276,0.9766l-0.9326-0.2412c0.2744-0.9326,1.1191-1.6021,2.293-1.6021
				c1.2837,0,2.0737,0.6255,2.0737,1.854v2.7979c0,0.373,0.2305,0.4937,0.6143,0.4058v0.79
				c-0.8774,0.1099-1.3823-0.0986-1.5029-0.6475c-0.3511,0.4502-0.9873,0.7461-1.7661,0.7461
				C1750.353,1234.0293,1749.5083,1233.415,1749.5083,1232.3838z M1753.0737,1230.9902l-1.1191,0.2417
				c-0.8774,0.1753-1.4697,0.4165-1.4697,1.1079c0,0.5596,0.4058,0.8887,1.0312,0.8887c0.8447,0,1.5576-0.5156,1.5576-1.2505
				V1230.9902z"
          />
          <path
            d="M1754.5146,1232.3838c0-1.1411,0.8887-1.6128,2.1724-1.876l1.3931-0.2852v-0.2417c0-0.6582-0.3618-1.0312-1.1299-1.0312
				c-0.7241,0-1.1519,0.3403-1.3276,0.9766l-0.9326-0.2412c0.2744-0.9326,1.1191-1.6021,2.293-1.6021
				c1.2837,0,2.0737,0.6255,2.0737,1.854v2.7979c0,0.373,0.2305,0.4937,0.6143,0.4058v0.79
				c-0.8774,0.1099-1.3823-0.0986-1.5029-0.6475c-0.3511,0.4502-0.9873,0.7461-1.7661,0.7461
				C1755.3594,1234.0293,1754.5146,1233.415,1754.5146,1232.3838z M1758.0801,1230.9902l-1.1191,0.2417
				c-0.8774,0.1753-1.4697,0.4165-1.4697,1.1079c0,0.5596,0.4058,0.8887,1.0312,0.8887c0.8447,0,1.5576-0.5156,1.5576-1.2505
				V1230.9902z"
          />
          <path
            d="M1760.9575,1233.9307h-0.9873v-5.7378h0.9873v0.7788c0.3071-0.4717,0.856-0.8887,1.6567-0.8887
				c0.7681,0,1.3057,0.3843,1.5908,0.9766c0.3184-0.4717,0.9326-0.9766,1.832-0.9766c1.1851,0,1.8979,0.8447,1.8979,2.0625v3.7852
				h-0.9873v-3.5215c0-0.8013-0.3291-1.4043-1.1406-1.4043c-0.6587,0-1.3608,0.5591-1.3608,1.4917v3.4341h-0.9873v-3.5215
				c0-0.8013-0.3291-1.4043-1.1411-1.4043c-0.6582,0-1.3604,0.5591-1.3604,1.4917V1233.9307z"
          />
          <path
            d="M1768.7642,1226.0532h0.9873v2.9185c0.3291-0.4717,0.8999-0.8887,1.7114-0.8887c1.2178,0,1.9092,0.8447,1.9092,2.0625
				v3.7852h-0.9873v-3.5215c0-0.8013-0.3403-1.4043-1.1958-1.4043c-0.7134,0-1.4375,0.5483-1.4375,1.5029v3.4229h-0.9873V1226.0532z
				"
          />
          <path
            d="M1773.7925,1231.0674c0-1.7114,1.0864-2.9844,2.644-2.9844c1.6021,0,2.4468,1.2178,2.4468,2.8086v0.3291h-4.147
				c0.0547,1.207,0.7456,1.9751,1.7441,1.9751c0.7681,0,1.3164-0.417,1.4922-1.0972l0.8667,0.3071
				c-0.3511,1.02-1.2178,1.6455-2.3589,1.6455C1774.8896,1234.0513,1773.7925,1232.8335,1773.7925,1231.0674z M1774.791,1230.4639
				h3.0938c-0.022-0.812-0.4609-1.5361-1.459-1.5361C1775.5918,1228.9277,1774.9663,1229.4873,1774.791,1230.4639z"
          />
          <path d="M1779.3032,1226.0532h1.0752v1.1411h-1.0752V1226.0532z M1780.3345,1233.9307h-0.9873v-5.7378h0.9873V1233.9307z" />
          <path
            d="M1780.8184,1231.0674c0-1.6348,0.9106-2.9844,2.5015-2.9844c0.8008,0,1.4375,0.3843,1.7773,0.9434v-2.9731h0.9873v7.8774
				h-0.9873v-0.834c-0.3398,0.5708-0.9766,0.9546-1.7773,0.9546C1781.729,1234.0513,1780.8184,1232.6909,1780.8184,1231.0674z
				 M1783.4956,1228.9497c-1.0752,0-1.6787,0.8779-1.6787,2.1177c0,1.2285,0.6035,2.1172,1.6787,2.1172
				c0.8115,0,1.6235-0.5923,1.6235-1.9087v-0.4062C1785.1191,1229.5312,1784.3071,1228.9497,1783.4956,1228.9497z"
          />
          <path
            d="M1787.0869,1232.1533c0.3071,0.6694,1.0093,1.0752,1.7554,1.0752c0.6689,0,1.2617-0.2852,1.2617-0.9106
				s-0.5488-0.7129-1.4922-0.9106c-0.9434-0.2085-1.9312-0.417-1.9312-1.5908c0-1.02,0.8887-1.7334,2.1064-1.7334
				c1.0312,0,1.8765,0.5049,2.2163,1.1851l-0.7021,0.5596c-0.2964-0.5708-0.8335-0.9326-1.5576-0.9326
				c-0.6587,0-1.0972,0.3291-1.0972,0.8447c0,0.5376,0.5044,0.6362,1.3052,0.812c0.9878,0.23,2.1177,0.4277,2.1177,1.6782
				c0,1.0972-0.9985,1.8213-2.2271,1.8213c-1.0425,0-2.0845-0.4497-2.5234-1.3384L1787.0869,1232.1533z"
          />
          <path
            d="M1793.8369,1233.1846c0.79,0,1.2617-0.5044,1.4263-1.2178l0.8774,0.4058c-0.2739,0.9546-1.1079,1.6787-2.3037,1.6787
				c-1.6016,0-2.71-1.2178-2.71-2.9839c0-1.7773,1.1084-2.9844,2.71-2.9844c1.1958,0,2.0078,0.6914,2.2822,1.6455l-0.856,0.4282
				c-0.1646-0.7021-0.6362-1.2178-1.4263-1.2178c-1.0205,0-1.7227,0.8115-1.7227,2.1284
				C1792.1143,1232.3726,1792.8164,1233.1846,1793.8369,1233.1846z"
          />
          <path
            d="M1799.4355,1229.1694c-0.1646-0.0332-0.2959-0.0439-0.4824-0.0439c-0.834,0-1.5034,0.6582-1.5034,1.6675v3.1377h-0.9873
				v-5.7378h0.9873v0.9766c0.2524-0.5815,0.812-1.0205,1.5908-1.0205c0.1538,0,0.2964,0.0107,0.395,0.022V1229.1694z"
          />
          <path d="M1799.7119,1226.0532h1.0752v1.1411h-1.0752V1226.0532z M1800.7432,1233.9307h-0.9873v-5.7378h0.9873V1233.9307z" />
          <path
            d="M1801.7759,1229.0376h-0.8887v-0.8447h0.8887v-1.6787h0.9761v1.6787h1.3057v0.8447h-1.3057v3.3682
				c0,0.6035,0.2964,0.7129,0.8013,0.7129c0.23,0,0.395-0.0107,0.625-0.0659v0.8452c-0.252,0.0547-0.5376,0.0874-0.8667,0.0874
				c-0.9761,0-1.5356-0.3291-1.5356-1.4263V1229.0376z"
          />
          <path
            d="M1804.1919,1231.0674c0-1.7114,1.0864-2.9844,2.644-2.9844c1.6021,0,2.4468,1.2178,2.4468,2.8086v0.3291h-4.147
				c0.0547,1.207,0.7456,1.9751,1.7441,1.9751c0.7681,0,1.3164-0.417,1.4922-1.0972l0.8667,0.3071
				c-0.3511,1.02-1.2178,1.6455-2.3589,1.6455C1805.2891,1234.0513,1804.1919,1232.8335,1804.1919,1231.0674z M1805.1904,1230.4639
				h3.0938c-0.022-0.812-0.4609-1.5361-1.459-1.5361C1805.9912,1228.9277,1805.3657,1229.4873,1805.1904,1230.4639z"
          />
          <path
            d="M1812.7197,1229.1694c-0.1646-0.0332-0.2959-0.0439-0.4824-0.0439c-0.834,0-1.5034,0.6582-1.5034,1.6675v3.1377h-0.9873
				v-5.7378h0.9873v0.9766c0.2524-0.5815,0.812-1.0205,1.5908-1.0205c0.1538,0,0.2964,0.0107,0.395,0.022V1229.1694z"
          />
          <path d="M1812.9961,1226.0532h1.0752v1.1411h-1.0752V1226.0532z M1814.0273,1233.9307h-0.9873v-5.7378h0.9873V1233.9307z" />
          <path
            d="M1814.4565,1232.3838c0-1.1411,0.8887-1.6128,2.1724-1.876l1.3931-0.2852v-0.2417c0-0.6582-0.3618-1.0312-1.1299-1.0312
				c-0.7241,0-1.1519,0.3403-1.3276,0.9766l-0.9326-0.2412c0.2744-0.9326,1.1191-1.6021,2.293-1.6021
				c1.2837,0,2.0737,0.6255,2.0737,1.854v2.7979c0,0.373,0.2305,0.4937,0.6143,0.4058v0.79
				c-0.8774,0.1099-1.3823-0.0986-1.5029-0.6475c-0.3511,0.4502-0.9873,0.7461-1.7661,0.7461
				C1815.3013,1234.0293,1814.4565,1233.415,1814.4565,1232.3838z M1818.022,1230.9902l-1.1191,0.2417
				c-0.8774,0.1753-1.4697,0.4165-1.4697,1.1079c0,0.5596,0.4058,0.8887,1.0312,0.8887c0.8447,0,1.5576-0.5156,1.5576-1.2505
				V1230.9902z"
          />
          <path d="M1728.6597,1238.6885h1.0752v1.1411h-1.0752V1238.6885z M1729.6909,1246.5659h-0.9873v-5.7378h0.9873V1246.5659z" />
          <path
            d="M1730.5698,1240.8281h0.9873v0.7788c0.3291-0.4717,0.8999-0.8887,1.7114-0.8887c1.2178,0,1.9092,0.8447,1.9092,2.0625
				v3.7852h-0.9873v-3.5215c0-0.8013-0.3403-1.4043-1.1958-1.4043c-0.7134,0-1.4375,0.5483-1.4375,1.5029v3.4229h-0.9873V1240.8281z
				"
          />
          <path d="M1738.5283,1246.5659h-0.9873v-7.8774h0.9873V1246.5659z" />
          <path
            d="M1738.9575,1245.019c0-1.1411,0.8887-1.6128,2.1724-1.876l1.3931-0.2852v-0.2417c0-0.6582-0.3618-1.0312-1.1299-1.0312
				c-0.7241,0-1.1519,0.3403-1.3276,0.9766l-0.9326-0.2412c0.2744-0.9326,1.1191-1.6021,2.293-1.6021
				c1.2837,0,2.0737,0.6255,2.0737,1.854v2.7979c0,0.373,0.2305,0.4937,0.6143,0.4058v0.79
				c-0.8774,0.1099-1.3823-0.0986-1.5029-0.6475c-0.3511,0.4502-0.9873,0.7461-1.7661,0.7461
				C1739.8022,1246.6646,1738.9575,1246.0503,1738.9575,1245.019z M1742.5229,1243.6255l-1.1191,0.2417
				c-0.8774,0.1753-1.4697,0.417-1.4697,1.1079c0,0.5596,0.4058,0.8887,1.0312,0.8887c0.8447,0,1.5576-0.5156,1.5576-1.2505
				V1243.6255z"
          />
          <path
            d="M1744.4131,1240.8281h0.9873v0.7788c0.3291-0.4717,0.8999-0.8887,1.7114-0.8887c1.2183,0,1.9092,0.8447,1.9092,2.0625
				v3.7852h-0.9873v-3.5215c0-0.8013-0.3403-1.4043-1.1958-1.4043c-0.7134,0-1.4375,0.5483-1.4375,1.5029v3.4229h-0.9873V1240.8281z
				"
          />
          <path
            d="M1749.4419,1243.7026c0-1.6348,0.9106-2.9844,2.5015-2.9844c0.8008,0,1.4375,0.3843,1.7773,0.9434v-2.9731h0.9873v7.8774
				h-0.9873v-0.834c-0.3398,0.5708-0.9766,0.9546-1.7773,0.9546C1750.3525,1246.6865,1749.4419,1245.3262,1749.4419,1243.7026z
				 M1752.1191,1241.585c-1.0752,0-1.6787,0.8779-1.6787,2.1177c0,1.2285,0.6035,2.1172,1.6787,2.1172
				c0.8115,0,1.6235-0.5923,1.6235-1.9087v-0.4062C1753.7427,1242.1665,1752.9307,1241.585,1752.1191,1241.585z"
          />
          <path
            d="M1756.5659,1245.7319v0.834h-0.9873v-7.8774h0.9873v2.9731c0.3403-0.5591,0.9766-0.9434,1.7773-0.9434
				c1.5908,0,2.5015,1.3496,2.5015,2.9844c0,1.6235-0.9106,2.9839-2.5015,2.9839
				C1757.5425,1246.6865,1756.9062,1246.3027,1756.5659,1245.7319z M1756.5439,1243.9111c0,1.3164,0.812,1.9087,1.624,1.9087
				c1.0752,0,1.6787-0.8887,1.6787-2.1172c0-1.2397-0.6035-2.1177-1.6787-2.1177c-0.812,0-1.624,0.5815-1.624,1.9199V1243.9111z"
          />
          <path
            d="M1763.6025,1246.6865c-1.5688,0-2.666-1.2065-2.666-2.9839s1.0972-2.9844,2.666-2.9844s2.666,1.207,2.666,2.9844
				S1765.1714,1246.6865,1763.6025,1246.6865z M1763.6025,1245.8418c1.0093,0,1.6675-0.834,1.6675-2.1392
				c0-1.3169-0.6582-2.1504-1.6675-2.1504s-1.6675,0.8335-1.6675,2.1504
				C1761.9351,1245.0078,1762.5933,1245.8418,1763.6025,1245.8418z"
          />
          <path
            d="M1771.3081,1246.5659h-0.9878v-0.79c-0.3291,0.4829-0.8882,0.9106-1.7002,0.9106c-1.229,0-1.9199-0.8447-1.9199-2.0625
				v-3.7959h0.9873v3.5874c0,0.812,0.3511,1.3604,1.1958,1.3604c0.7134,0,1.437-0.5376,1.437-1.4917v-3.4561h0.9878V1246.5659z"
          />
          <path
            d="M1775.7441,1240.8281l1.1846,4.2788l1.1958-4.2788h0.9873l-1.7441,5.7378h-0.8667l-1.1958-4.2568l-1.1958,4.2568h-0.8667
				l-1.7446-5.7378h1.0205l1.2065,4.2676l1.1851-4.2676H1775.7441z"
          />
          <path d="M1780.7842,1246.5659l-2.1504-5.7378h1.0425l1.6128,4.5308l1.5908-4.5308h1.0312l-2.1504,5.7378H1780.7842z" />
          <path
            d="M1783.7163,1243.7026c0-1.7114,1.0864-2.9844,2.644-2.9844c1.6021,0,2.4468,1.2178,2.4468,2.8086v0.3291h-4.147
				c0.0547,1.207,0.7456,1.9751,1.7441,1.9751c0.7681,0,1.3164-0.417,1.4922-1.0972l0.8667,0.3071
				c-0.3511,1.02-1.2178,1.6455-2.3589,1.6455C1784.8135,1246.6865,1783.7163,1245.4688,1783.7163,1243.7026z M1784.7148,1243.0991
				h3.0938c-0.022-0.812-0.4609-1.5361-1.459-1.5361C1785.5156,1241.563,1784.8901,1242.1226,1784.7148,1243.0991z"
          />
          <path d="M1789.2271,1238.6885h1.0752v1.1411h-1.0752V1238.6885z M1790.2583,1246.5659h-0.9873v-5.7378h0.9873V1246.5659z" />
          <path d="M1792.1245,1246.5659h-0.9873v-7.8774h0.9873V1246.5659z" />
          <path d="M1792.9595,1238.6885h1.0752v1.1411h-1.0752V1238.6885z M1793.9907,1246.5659h-0.9873v-5.7378h0.9873V1246.5659z" />
          <path
            d="M1794.8696,1240.8281h0.9873v0.7788c0.3291-0.4717,0.8999-0.8887,1.7114-0.8887c1.2178,0,1.9092,0.8447,1.9092,2.0625
				v3.7852h-0.9873v-3.5215c0-0.8013-0.3403-1.4043-1.1958-1.4043c-0.7134,0-1.4375,0.5483-1.4375,1.5029v3.4229h-0.9873V1240.8281z
				"
          />
          <path
            d="M1802.4878,1248.6616c-1.2617,0-2.1831-0.5815-2.5015-1.6348l0.9214-0.3511c0.1865,0.7349,0.7793,1.1626,1.5801,1.1626
				c1.0093,0,1.6787-0.5156,1.6787-1.7114v-0.647c-0.3071,0.5591-0.9546,0.9541-1.7554,0.9541
				c-1.5688,0-2.4907-1.2944-2.4907-2.8633c0-1.5581,0.9219-2.8525,2.4907-2.8525c0.7896,0,1.4482,0.395,1.7554,0.9546v-0.8447
				h0.9873v5.2222C1805.1538,1247.729,1804.0454,1248.6616,1802.4878,1248.6616z M1802.5864,1241.585
				c-1.042,0-1.6787,0.7788-1.6787,1.9858s0.6367,1.9966,1.6787,1.9966c0.8228,0,1.5908-0.6143,1.5908-1.876v-0.2085
				C1804.1772,1242.1992,1803.4092,1241.585,1802.5864,1241.585z"
          />
          <path
            d="M1805.6401,1243.7026c0-1.7114,1.0864-2.9844,2.644-2.9844c1.6021,0,2.4468,1.2178,2.4468,2.8086v0.3291h-4.147
				c0.0547,1.207,0.7456,1.9751,1.7441,1.9751c0.7681,0,1.3164-0.417,1.4922-1.0972l0.8667,0.3071
				c-0.3511,1.02-1.2178,1.6455-2.3589,1.6455C1806.7373,1246.6865,1805.6401,1245.4688,1805.6401,1243.7026z M1806.6387,1243.0991
				h3.0938c-0.022-0.812-0.4609-1.5361-1.459-1.5361C1807.4395,1241.563,1806.814,1242.1226,1806.6387,1243.0991z"
          />
          <path
            d="M1811.1948,1240.8281h0.9873v0.7788c0.3291-0.4717,0.8999-0.8887,1.7114-0.8887c1.2178,0,1.9092,0.8447,1.9092,2.0625
				v3.7852h-0.9873v-3.5215c0-0.8013-0.3403-1.4043-1.1958-1.4043c-0.7134,0-1.4375,0.5483-1.4375,1.5029v3.4229h-0.9873V1240.8281z
				"
          />
        </g>
      </g>
      <g onClick={handleClick} id="63">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3627.4221,750.0781c0,25.27-20.4841,45.75-45.75,45.75c-25.27,0-45.75-20.48-45.75-45.75
			c0-25.2661,20.48-45.75,45.75-45.75C3606.938,704.3281,3627.4221,724.812,3627.4221,750.0781"
        />
        <g>
          <path d="M3552.3477,732.394v7.7104h-1.167v-7.7104h-2.9551v-1.0215h7.0771v1.0215H3552.3477z" />
          <path d="M3556.375,731.3726h1.1924v1.2651h-1.1924V731.3726z M3557.5186,740.1045h-1.0947v-6.3604h1.0947V740.1045z" />
          <path
            d="M3559.3428,733.7441h1.0947v6.8833c0,1.1309-0.7061,1.7026-1.7881,1.7026c-0.2549,0-0.4131-0.0122-0.6074-0.0366v-0.9487
				c0.1328,0.0244,0.2432,0.0366,0.4248,0.0366c0.6328,0,0.876-0.3042,0.876-0.9487V733.7441z M3559.2939,731.3726h1.1924v1.2651
				h-1.1924V731.3726z"
          />
          <path
            d="M3561.8242,736.9302c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3562.833,740.2383,3561.8242,738.7305,3561.8242,736.9302z
				 M3564.791,734.5835c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3566.5908,735.228,3565.6914,734.5835,3564.791,734.5835z"
          />
          <path
            d="M3573.1367,739.1802v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C3574.2188,740.2383,3573.5137,739.8125,3573.1367,739.1802z M3573.1123,737.1616c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V737.1616z"
          />
          <path
            d="M3578.8301,736.9302c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3580.0459,740.2383,3578.8301,738.8882,3578.8301,736.9302z M3579.9365,736.2617
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3580.8242,734.5591,3580.1309,735.1792,3579.9365,736.2617z"
          />
          <path
            d="M3585.9824,738.1343c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L3585.9824,738.1343z"
          />
          <path
            d="M3591.9189,734.6807h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V734.6807z"
          />
          <path
            d="M3595.4473,736.9302c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3596.6631,740.2383,3595.4473,738.8882,3595.4473,736.9302z M3596.5537,736.2617
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3597.4414,734.5591,3596.748,735.1792,3596.5537,736.2617z"
          />
          <path
            d="M3602.0166,736.9302c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3603.2324,740.2383,3602.0166,738.8882,3602.0166,736.9302z M3603.123,736.2617
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3604.0107,734.5591,3603.3174,735.1792,3603.123,736.2617z"
          />
          <path
            d="M3608.5859,736.9302c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3609.5947,740.2383,3608.5859,738.7305,3608.5859,736.9302z
				 M3611.5527,734.5835c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3613.3525,735.228,3612.4531,734.5835,3611.5527,734.5835z"
          />
          <path
            d="M3543.5479,752.3896c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3544.4844,754.2139,3543.5479,753.5327,3543.5479,752.3896z M3547.501,750.8452l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V750.8452z"
          />
          <path
            d="M3549.9463,752.3896c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3550.8828,754.2139,3549.9463,753.5327,3549.9463,752.3896z M3553.8994,750.8452l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V750.8452z"
          />
          <path
            d="M3556.8438,747.7441h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V747.7441z"
          />
          <path
            d="M3565.833,750.9302c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3566.8418,754.2383,3565.833,752.7305,3565.833,750.9302z
				 M3568.7998,748.5835c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3570.5996,749.228,3569.7002,748.5835,3568.7998,748.5835z"
          />
          <path
            d="M3578.5312,754.1045h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V754.1045z"
          />
          <path
            d="M3585.3916,754.1045h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0938v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V754.1045z"
          />
          <path
            d="M3590.502,748.8267c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V748.8267z"
          />
          <path d="M3596.3037,754.1045h-5.0098v-0.8271l3.6719-4.5967h-3.624v-0.9365h4.9258v0.8394l-3.5996,4.5845h3.6357V754.1045z" />
          <path
            d="M3597.2295,752.3896c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3598.166,754.2139,3597.2295,753.5327,3597.2295,752.3896z M3601.1826,750.8452l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V750.8452z"
          />
          <path
            d="M3603.6279,752.3896c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3604.5645,754.2139,3603.6279,753.5327,3603.6279,752.3896z M3607.5811,750.8452l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V750.8452z"
          />
          <path
            d="M3611.6201,754.1045h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0312-1.0825c1.3125,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V754.1045z"
          />
          <path d="M3563.1084,768.1045l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3563.1084z" />
          <path
            d="M3570.1631,768.2383c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3573.1182,766.9004,3571.9023,768.2383,3570.1631,768.2383z M3570.1631,767.3018
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3568.3145,766.3774,3569.0439,767.3018,3570.1631,767.3018z"
          />
          <path
            d="M3574.0693,764.9302c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3575.2852,768.2383,3574.0693,766.8882,3574.0693,764.9302z M3575.1758,764.2617
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3576.0635,762.5591,3575.3701,763.1792,3575.1758,764.2617z"
          />
          <path
            d="M3580.6387,764.9302c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3581.6475,768.2383,3580.6387,766.7305,3580.6387,764.9302z
				 M3583.6055,762.5835c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3585.4053,763.228,3584.5059,762.5835,3583.6055,762.5835z"
          />
          <path
            d="M3588.4355,766.1343c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L3588.4355,766.1343z"
          />
          <path
            d="M3593.7646,764.9302c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3594.9805,768.2383,3593.7646,766.8882,3593.7646,764.9302z M3594.8711,764.2617
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3595.7588,762.5591,3595.0654,763.1792,3594.8711,764.2617z"
          />
          <path d="M3601.8662,768.1045h-1.0947v-8.7319h1.0947V768.1045z" />
        </g>
      </g>
      <g onClick={handleClick} id="62">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3149.605,1425.8521c0,25.2649-20.4839,45.75-45.75,45.75c-25.269,0-45.75-20.4851-45.75-45.75
			c0-25.27,20.481-45.75,45.75-45.75C3129.1211,1380.1021,3149.605,1400.582,3149.605,1425.8521"
        />
        <g>
          <path
            d="M3067.623,1407.1465h3.2227c1.8496,0,2.834,0.8149,2.834,2.2744c0,1.1187-0.6816,1.6782-1.3496,1.9092
				c0.9844,0.2676,1.6406,1.0337,1.6406,2.1406c0,1.52-1.1055,2.4077-2.8691,2.4077h-3.4785V1407.1465z M3070.748,1410.8921
				c1.168,0,1.8125-0.4497,1.8125-1.374c0-0.9365-0.6445-1.3862-1.8125-1.3862h-1.9941v2.7603H3070.748z M3068.7539,1414.8936
				h2.2988c1.168,0,1.8008-0.6084,1.8008-1.5083c0-0.8877-0.6328-1.4956-1.8008-1.4956h-2.2988V1414.8936z"
          />
          <path
            d="M3074.5449,1412.7041c0-1.897,1.2051-3.3076,2.9316-3.3076c1.7754,0,2.7109,1.3501,2.7109,3.1133v0.3647h-4.5957
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8496,0,1.459-0.4619,1.6523-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3075.7617,1416.0122,3074.5449,1414.6621,3074.5449,1412.7041z
				 M3075.6523,1412.0356h3.4297c-0.0254-0.8999-0.5117-1.7026-1.6191-1.7026
				C3076.5391,1410.333,3075.8457,1410.9531,3075.6523,1412.0356z"
          />
          <path
            d="M3084.4844,1410.6006c-0.1836-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9258,0-1.666,0.7295-1.666,1.8486v3.478h-1.0957
				v-6.3604h1.0957v1.0825c0.2793-0.6445,0.8984-1.1313,1.7617-1.1313c0.1719,0,0.3301,0.0122,0.4395,0.0244V1410.6006z"
          />
          <path
            d="M3084.8867,1412.7041c0-1.897,1.2031-3.3076,2.9297-3.3076c1.7754,0,2.7129,1.3501,2.7129,3.1133v0.3647h-4.5977
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C3086.1016,1416.0122,3084.8867,1414.6621,3084.8867,1412.7041z
				 M3085.9922,1412.0356h3.4297c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026
				C3086.8809,1410.333,3086.1875,1410.9531,3085.9922,1412.0356z"
          />
          <path d="M3091.4785,1407.1465h1.1934v1.2651h-1.1934V1407.1465z M3092.623,1415.8784h-1.0957v-6.3604h1.0957V1415.8784z" />
          <path
            d="M3093.6445,1412.7041c0-1.812,1.0098-3.3076,2.7734-3.3076c0.8867,0,1.5918,0.4258,1.9688,1.0459v-3.2959h1.0957v8.7319
				h-1.0957v-0.9243c-0.377,0.6323-1.082,1.0581-1.9688,1.0581C3094.6543,1416.0122,3093.6445,1414.5044,3093.6445,1412.7041z
				 M3096.6113,1410.3574c-1.1914,0-1.8594,0.9727-1.8594,2.3467c0,1.3623,0.668,2.3472,1.8594,2.3472
				c0.9004,0,1.8008-0.6567,1.8008-2.1157v-0.4502C3098.4121,1411.002,3097.5117,1410.3574,3096.6113,1410.3574z"
          />
          <path
            d="M3100.9316,1407.1465h1.0938v3.2349c0.3652-0.5229,0.998-0.9849,1.8965-0.9849c1.3516,0,2.1172,0.9365,2.1172,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6079-1.5938,1.666v3.7944h-1.0938V1407.1465z"
          />
          <path
            d="M3106.9883,1412.7041c0-1.897,1.2051-3.3076,2.9316-3.3076c1.7754,0,2.7109,1.3501,2.7109,3.1133v0.3647h-4.5957
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8496,0,1.459-0.4619,1.6523-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3108.2051,1416.0122,3106.9883,1414.6621,3106.9883,1412.7041z
				 M3108.0957,1412.0356h3.4297c-0.0254-0.8999-0.5117-1.7026-1.6191-1.7026
				C3108.9824,1410.333,3108.2891,1410.9531,3108.0957,1412.0356z"
          />
          <path d="M3113.582,1407.1465h1.1914v1.2651h-1.1914V1407.1465z M3114.7246,1415.8784h-1.0938v-6.3604h1.0938V1415.8784z" />
          <path
            d="M3115.748,1412.7041c0-1.812,1.0078-3.3076,2.7715-3.3076c0.8887,0,1.5938,0.4258,1.9707,1.0459v-3.2959h1.0938v8.7319
				h-1.0938v-0.9243c-0.377,0.6323-1.082,1.0581-1.9707,1.0581C3116.7559,1416.0122,3115.748,1414.5044,3115.748,1412.7041z
				 M3118.7148,1410.3574c-1.1914,0-1.8613,0.9727-1.8613,2.3467c0,1.3623,0.6699,2.3472,1.8613,2.3472
				c0.9004,0,1.7988-0.6567,1.7988-2.1157v-0.4502C3120.5137,1411.002,3119.6152,1410.3574,3118.7148,1410.3574z"
          />
          <path
            d="M3127.752,1416.0122c-1.7383,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2168-3.3076,2.9551-3.3076
				c1.7402,0,2.9551,1.3379,2.9551,3.3076C3130.707,1414.6743,3129.4922,1416.0122,3127.752,1416.0122z M3127.752,1415.0757
				c1.1191,0,1.8496-0.9243,1.8496-2.3716c0-1.459-0.7305-2.3833-1.8496-2.3833s-1.8477,0.9243-1.8477,2.3833
				C3125.9043,1414.1514,3126.6328,1415.0757,3127.752,1415.0757z"
          />
          <path
            d="M3132.8262,1415.8784h-1.0957v-6.3604h1.0957v0.8633c0.3398-0.5229,0.9473-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3516-0.5229,1.0332-1.0825,2.0312-1.0825c1.3125,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0957v-3.9038c0-0.8877-0.3633-1.5566-1.2637-1.5566c-0.7305,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0957v-3.9038
				c0-0.8877-0.3652-1.5566-1.2637-1.5566c-0.7305,0-1.5078,0.6201-1.5078,1.6538V1415.8784z"
          />
          <path d="M3059.1621,1429.8784l-2.3828-6.3604h1.1543l1.7891,5.0225l1.7617-5.0225h1.1445l-2.3848,6.3604H3059.1621z" />
          <path
            d="M3065.2441,1430.0122c-1.7402,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2148-3.3076,2.9551-3.3076
				c1.7383,0,2.9551,1.3379,2.9551,3.3076C3068.1992,1428.6743,3066.9824,1430.0122,3065.2441,1430.0122z M3065.2441,1429.0757
				c1.1172,0,1.8477-0.9243,1.8477-2.3716c0-1.459-0.7305-2.3833-1.8477-2.3833c-1.1191,0-1.8496,0.9243-1.8496,2.3833
				C3063.3945,1428.1514,3064.125,1429.0757,3065.2441,1429.0757z"
          />
          <path
            d="M3068.1777,1426.7041c0-1.897,1.2031-3.3076,2.9297-3.3076c1.7754,0,2.7129,1.3501,2.7129,3.1133v0.3647h-4.5977
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C3069.3926,1430.0122,3068.1777,1428.6621,3068.1777,1426.7041z
				 M3069.2832,1426.0356h3.4297c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026
				C3070.1719,1424.333,3069.4785,1424.9531,3069.2832,1426.0356z"
          />
          <path
            d="M3073.7734,1426.7041c0-1.812,1.0078-3.3076,2.7715-3.3076c0.8887,0,1.5938,0.4258,1.9707,1.0459v-3.2959h1.0938v8.7319
				h-1.0938v-0.9243c-0.377,0.6323-1.082,1.0581-1.9707,1.0581C3074.7812,1430.0122,3073.7734,1428.5044,3073.7734,1426.7041z
				 M3076.7402,1424.3574c-1.1914,0-1.8613,0.9727-1.8613,2.3467c0,1.3623,0.6699,2.3472,1.8613,2.3472
				c0.9004,0,1.7988-0.6567,1.7988-2.1157v-0.4502C3078.5391,1425.002,3077.6406,1424.3574,3076.7402,1424.3574z"
          />
          <path d="M3080.4023,1421.1465h1.1934v1.2651h-1.1934V1421.1465z M3081.5469,1429.8784h-1.0957v-6.3604h1.0957V1429.8784z" />
          <path
            d="M3082.3965,1423.5181h1.0957v0.8633c0.3633-0.5229,0.9961-0.9849,1.8965-0.9849c1.3496,0,2.1152,0.9365,2.1152,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.377-1.5566-1.3262-1.5566c-0.7891,0-1.5918,0.6079-1.5918,1.666v3.7944h-1.0957V1423.5181z"
          />
          <path
            d="M3090.7168,1432.2012c-1.3984,0-2.4199-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8652,1.2891,1.752,1.2891
				c1.1191,0,1.8613-0.5713,1.8613-1.897v-0.7178c-0.3418,0.6201-1.0586,1.0581-1.9473,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.877,0,1.6055,0.438,1.9473,1.0581v-0.9365
				h1.0938v5.7886C3093.6719,1431.1675,3092.4434,1432.2012,3090.7168,1432.2012z M3090.8262,1424.3574
				c-1.1543,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.707,2.2134,1.8613,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C3092.5898,1425.0381,3091.7383,1424.3574,3090.8262,1424.3574z"
          />
          <path
            d="M3094.6699,1427.9082c0.3418,0.7417,1.1191,1.1919,1.9473,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6094-0.7905-1.6543-1.0093c-1.0469-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9844-1.9214,2.3359-1.9214
				c1.1426,0,2.0781,0.5596,2.4551,1.3135l-0.7773,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7305,0-1.2168,0.3647-1.2168,0.9365c0,0.5957,0.5586,0.7051,1.4473,0.8999c1.0938,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1074,2.0186-2.4688,2.0186c-1.1562,0-2.3105-0.4985-2.7969-1.4839L3094.6699,1427.9082z"
          />
          <path
            d="M3101.8965,1432.2012c-1.3984,0-2.4199-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8652,1.2891,1.752,1.2891
				c1.1191,0,1.8613-0.5713,1.8613-1.897v-0.7178c-0.3418,0.6201-1.0586,1.0581-1.9473,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.877,0,1.6055,0.438,1.9473,1.0581v-0.9365
				h1.0938v5.7886C3104.8516,1431.1675,3103.623,1432.2012,3101.8965,1432.2012z M3102.0059,1424.3574
				c-1.1543,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.707,2.2134,1.8613,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C3103.7695,1425.0381,3102.918,1424.3574,3102.0059,1424.3574z"
          />
          <path
            d="M3105.2676,1426.7041c0-1.897,1.2031-3.3076,2.9297-3.3076c1.7754,0,2.7129,1.3501,2.7129,3.1133v0.3647h-4.5977
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C3106.4824,1430.0122,3105.2676,1428.6621,3105.2676,1426.7041z M3106.373,1426.0356
				h3.4297c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026C3107.2617,1424.333,3106.5684,1424.9531,3106.373,1426.0356z"
          />
          <path
            d="M3115.2539,1423.5181l1.3125,4.7427l1.3262-4.7427h1.0938l-1.9336,6.3604h-0.959l-1.3262-4.7188l-1.3262,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3145-4.731H3115.2539z"
          />
          <path
            d="M3121.6035,1430.0122c-1.7383,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2168-3.3076,2.9551-3.3076
				c1.7402,0,2.9551,1.3379,2.9551,3.3076C3124.5586,1428.6743,3123.3438,1430.0122,3121.6035,1430.0122z M3121.6035,1429.0757
				c1.1191,0,1.8496-0.9243,1.8496-2.3716c0-1.459-0.7305-2.3833-1.8496-2.3833s-1.8477,0.9243-1.8477,2.3833
				C3119.7559,1428.1514,3120.4844,1429.0757,3121.6035,1429.0757z"
          />
          <path
            d="M3127.4922,1430.0122c-1.7383,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2168-3.3076,2.9551-3.3076
				c1.7402,0,2.9551,1.3379,2.9551,3.3076C3130.4473,1428.6743,3129.2324,1430.0122,3127.4922,1430.0122z M3127.4922,1429.0757
				c1.1191,0,1.8496-0.9243,1.8496-2.3716c0-1.459-0.7305-2.3833-1.8496-2.3833s-1.8477,0.9243-1.8477,2.3833
				C3125.6445,1428.1514,3126.373,1429.0757,3127.4922,1429.0757z"
          />
          <path
            d="M3130.8633,1423.5181h1.0938v0.8633c0.3652-0.5229,0.998-0.9849,1.8965-0.9849c1.3516,0,2.1172,0.9365,2.1172,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6079-1.5938,1.666v3.7944h-1.0938V1423.5181z"
          />
          <path
            d="M3136.9199,1424.4546h-0.9844v-0.9365h0.9844v-1.8608h1.084v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8867,0.7905c0.2559,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2793,0.061-0.5957,0.0977-0.9609,0.0977
				c-1.082,0-1.7031-0.3652-1.7031-1.5811V1424.4546z"
          />
          <path
            d="M3139.4766,1426.7041c0-1.897,1.2031-3.3076,2.9297-3.3076c1.7754,0,2.7129,1.3501,2.7129,3.1133v0.3647h-4.5977
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C3140.6914,1430.0122,3139.4766,1428.6621,3139.4766,1426.7041z M3140.582,1426.0356
				h3.4297c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026C3141.4707,1424.333,3140.7773,1424.9531,3140.582,1426.0356z"
          />
          <path
            d="M3145.5098,1423.5181h1.0938v0.8633c0.3652-0.5229,0.998-0.9849,1.8965-0.9849c1.3516,0,2.1172,0.9365,2.1172,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6079-1.5938,1.666v3.7944h-1.0938V1423.5181z"
          />
          <path
            d="M3069.5684,1438.4546h-0.9844v-0.9365h0.9844v-1.8608h1.084v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8867,0.7905c0.2559,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2793,0.061-0.5957,0.0977-0.9609,0.0977
				c-1.082,0-1.7031-0.3652-1.7031-1.5811V1438.4546z"
          />
          <path
            d="M3072.7324,1440.7041c0-1.897,1.2031-3.3076,2.9297-3.3076c1.7754,0,2.7129,1.3501,2.7129,3.1133v0.3647h-4.5977
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C3073.9473,1444.0122,3072.7324,1442.6621,3072.7324,1440.7041z
				 M3073.8379,1440.0356h3.4297c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026
				C3074.7266,1438.333,3074.0332,1438.9531,3073.8379,1440.0356z"
          />
          <path d="M3083.2051,1443.8784l-2.3828-6.3604h1.1543l1.7891,5.0225l1.7617-5.0225h1.1445l-2.3848,6.3604H3083.2051z" />
          <path
            d="M3086.9395,1440.7041c0-1.897,1.2051-3.3076,2.9316-3.3076c1.7754,0,2.7109,1.3501,2.7109,3.1133v0.3647h-4.5957
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8496,0,1.459-0.4619,1.6523-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3088.1562,1444.0122,3086.9395,1442.6621,3086.9395,1440.7041z
				 M3088.0469,1440.0356h3.4297c-0.0254-0.8999-0.5117-1.7026-1.6191-1.7026
				C3088.9336,1438.333,3088.2402,1438.9531,3088.0469,1440.0356z"
          />
          <path
            d="M3096.8789,1438.6006c-0.1836-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9258,0-1.666,0.7295-1.666,1.8486v3.478h-1.0957
				v-6.3604h1.0957v1.0825c0.2793-0.6445,0.8984-1.1313,1.7617-1.1313c0.1719,0,0.3301,0.0122,0.4395,0.0244V1438.6006z"
          />
          <path
            d="M3097.2188,1442.1636c0-1.2646,0.9863-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4004-1.1431-1.252-1.1431
				c-0.8027,0-1.2773,0.377-1.4727,1.082l-1.0332-0.2676c0.3047-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4238,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6816,0.4502v0.8755
				c-0.9727,0.1216-1.5332-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.959,0.8267
				C3098.1562,1443.9878,3097.2188,1443.3066,3097.2188,1442.1636z M3101.1719,1440.6191l-1.2402,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9355,0,1.7266-0.5718,1.7266-1.3862
				V1440.6191z"
          />
          <path
            d="M3103.752,1437.5181h1.0938v0.8633c0.3652-0.5229,0.998-0.9849,1.8965-0.9849c1.3516,0,2.1172,0.9365,2.1172,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6079-1.5938,1.666v3.7944h-1.0938V1437.5181z"
          />
          <path
            d="M3109.8086,1440.7041c0-1.812,1.0098-3.3076,2.7734-3.3076c0.8867,0,1.5918,0.4258,1.9688,1.0459v-3.2959h1.0957v8.7319
				h-1.0957v-0.9243c-0.377,0.6323-1.082,1.0581-1.9688,1.0581C3110.8184,1444.0122,3109.8086,1442.5044,3109.8086,1440.7041z
				 M3112.7754,1438.3574c-1.1914,0-1.8594,0.9727-1.8594,2.3467c0,1.3623,0.668,2.3472,1.8594,2.3472
				c0.9004,0,1.8008-0.6567,1.8008-2.1157v-0.4502C3114.5762,1439.002,3113.6758,1438.3574,3112.7754,1438.3574z"
          />
          <path
            d="M3116.6582,1440.7041c0-1.897,1.2051-3.3076,2.9316-3.3076c1.7754,0,2.7109,1.3501,2.7109,3.1133v0.3647h-4.5957
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8496,0,1.459-0.4619,1.6523-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3117.875,1444.0122,3116.6582,1442.6621,3116.6582,1440.7041z M3117.7656,1440.0356
				h3.4297c-0.0254-0.8999-0.5117-1.7026-1.6191-1.7026C3118.6523,1438.333,3117.959,1438.9531,3117.7656,1440.0356z"
          />
          <path
            d="M3126.5957,1438.6006c-0.1816-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9238,0-1.666,0.7295-1.666,1.8486v3.478h-1.0938
				v-6.3604h1.0938v1.0825c0.2793-0.6445,0.9004-1.1313,1.7637-1.1313c0.1699,0,0.3281,0.0122,0.4375,0.0244V1438.6006z"
          />
          <path
            d="M3126.998,1440.7041c0-1.897,1.2051-3.3076,2.9316-3.3076c1.7754,0,2.7109,1.3501,2.7109,3.1133v0.3647h-4.5957
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8496,0,1.459-0.4619,1.6523-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3128.2148,1444.0122,3126.998,1442.6621,3126.998,1440.7041z M3128.1055,1440.0356
				h3.4297c-0.0254-0.8999-0.5117-1.7026-1.6191-1.7026C3128.9922,1438.333,3128.2988,1438.9531,3128.1055,1440.0356z"
          />
          <path
            d="M3133.6406,1437.5181h1.0957v0.8633c0.3633-0.5229,0.9961-0.9849,1.8965-0.9849c1.3496,0,2.1152,0.9365,2.1152,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.377-1.5566-1.3262-1.5566c-0.7891,0-1.5918,0.6079-1.5918,1.666v3.7944h-1.0957V1437.5181z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="61">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1027.16,1665.465c0,25.269-20.484,45.75-45.7501,45.75c-25.269,0-45.75-20.481-45.75-45.75
			c0-25.266,20.481-45.75,45.75-45.75C1006.676,1619.715,1027.16,1640.199,1027.16,1665.465"
        />
        <g>
          <path
            d="M955.6641,1653.7598h2.7725c2.6514,0,4.3296,1.7021,4.3296,4.3652c0,2.6641-1.6782,4.3662-4.3296,4.3662h-2.7725
				V1653.7598z M958.4365,1661.4814c1.9824,0,3.1865-1.3252,3.1865-3.3564c0-2.043-1.2041-3.3564-3.1987-3.3564h-1.6172v6.7129
				H958.4365z"
          />
          <path
            d="M968.8247,1662.4912H967.73v-0.876c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V1662.4912z"
          />
          <path
            d="M975.3203,1662.4912h-1.0942v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8853,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5933-0.5957,1.5933-1.6533v-3.8311h1.0942V1662.4912z"
          />
          <path
            d="M980.0654,1657.2129c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V1657.2129z"
          />
          <path d="M985.5024,1662.4912h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8389l-3.5996,4.585h3.6362V1662.4912z" />
          <path
            d="M986.0635,1660.7764c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C987,1662.6006,986.0635,1661.9199,986.0635,1660.7764z M990.0156,1659.2324l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1659.2324z"
          />
          <path
            d="M993.6904,1662.4912h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0337-1.083,2.0308-1.083c1.3135,0,2.104,0.9365,2.104,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V1662.4912z"
          />
          <path
            d="M1002.3877,1659.3174c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1003.604,1662.625,1002.3877,1661.2754,1002.3877,1659.3174z M1003.4946,1658.6484
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1004.3823,1656.9453,1003.689,1657.5654,1003.4946,1658.6484z"
          />
          <path
            d="M950.1221,1678.8145c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3408,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C953.0771,1677.7803,951.8491,1678.8145,950.1221,1678.8145z M950.2314,1670.9697
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C951.9951,1671.6514,951.1436,1670.9697,950.2314,1670.9697z"
          />
          <path
            d="M954.1006,1673.3174c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C955.3169,1676.625,954.1006,1675.2754,954.1006,1673.3174z M955.2075,1672.6484
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C956.0952,1670.9453,955.4019,1671.5654,955.2075,1672.6484z"
          />
          <path
            d="M964.6948,1670.1309l1.313,4.7432l1.3257-4.7432h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H964.6948z"
          />
          <path
            d="M968.6372,1674.7764c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C969.5737,1676.6006,968.6372,1675.9199,968.6372,1674.7764z M972.5894,1673.2324l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1673.2324z"
          />
          <path
            d="M975.3149,1674.5215c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9243-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L975.3149,1674.5215z"
          />
          <path
            d="M981.811,1673.0986l2.8213-2.9678h1.3618l-2.481,2.5049l2.688,3.8555h-1.2769l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V1673.0986z"
          />
          <path
            d="M986.4097,1673.3174c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C987.626,1676.625,986.4097,1675.2754,986.4097,1673.3174z M987.5166,1672.6484
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C988.4043,1670.9453,987.7109,1671.5654,987.5166,1672.6484z"
          />
          <path
            d="M998.0981,1676.4912h-1.0947v-0.876c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V1676.4912z"
          />
          <path d="M1004.1445,1676.4912h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8389l-3.5996,4.585h3.6362V1676.4912z" />
          <path
            d="M1004.7656,1673.3174c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1005.9819,1676.625,1004.7656,1675.2754,1004.7656,1673.3174z M1005.8726,1672.6484
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1006.7603,1670.9453,1006.0669,1671.5654,1005.8726,1672.6484z"
          />
          <path
            d="M1011.5537,1674.5215c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L1011.5537,1674.5215z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="60">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M2875.082,2135.9099c0,25.27-20.4841,45.75-45.75,45.75c-25.2661,0-45.75-20.48-45.75-45.75
			c0-25.2649,20.4839-45.75,45.75-45.75C2854.5979,2090.1599,2875.082,2110.645,2875.082,2135.9099"
        />
        <g>
          <path d="M2809.1494,2125.9365h-1.0098l-4.7676-7.0293v7.0293h-1.1182v-8.7314h1.2285l4.5361,6.749v-6.749h1.1309V2125.9365z" />
          <path d="M2810.6104,2117.2051h1.1924v1.2646h-1.1924V2117.2051z M2811.7539,2125.9365h-1.0947v-6.3604h1.0947V2125.9365z" />
          <path d="M2814.8438,2125.9365l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2814.8438z" />
          <path
            d="M2818.5781,2122.7627c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2819.7939,2126.0703,2818.5781,2124.7207,2818.5781,2122.7627z
				 M2819.6846,2122.0938h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C2820.5723,2120.3906,2819.8789,2121.0107,2819.6846,2122.0938z"
          />
          <path
            d="M2824.7207,2124.2217c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2825.6572,2126.0459,2824.7207,2125.3652,2824.7207,2124.2217z M2828.6738,2122.6777l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2122.6777z"
          />
          <path
            d="M2836.3008,2125.9365h-1.0947v-0.876c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0938v3.9766c0,0.9004,0.3896,1.5078,1.3262,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V2125.9365z"
          />
          <path d="M2841.5811,2125.9365l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2841.5811z" />
          <path
            d="M2845.2539,2124.2217c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2846.1904,2126.0459,2845.2539,2125.3652,2845.2539,2124.2217z M2849.207,2122.6777l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2122.6777z"
          />
          <path
            d="M2851.7871,2119.5762h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2119.5762z"
          />
          <path
            d="M2789.3896,2139.9365h-1.167v-8.7314h3.332c1.8486,0,3.0283,0.8633,3.0283,2.5293c0,1.3984-0.8516,2.25-2.2383,2.4805
				l2.4209,3.7217h-1.3379l-2.3477-3.6602h-1.6904V2139.9365z M2791.4941,2135.2666c1.2402,0,1.9336-0.5469,1.9336-1.5322
				s-0.6934-1.5205-1.9336-1.5205h-2.1045v3.0527H2791.4941z"
          />
          <path
            d="M2800.8359,2139.9365c-0.2314-0.2314-0.4502-0.4619-0.7666-0.8027c-0.7051,0.6572-1.4961,0.9365-2.3955,0.9365
				c-1.5938,0-2.5176-1.0703-2.5176-2.2988c0-1.1182,0.6689-1.8848,1.6055-2.3955v-0.0361
				c-0.4258-0.5352-0.6816-1.1309-0.6816-1.7148c0-0.9971,0.7178-2.0186,2.0801-2.0186c1.0215,0,1.8115,0.6807,1.8115,1.7383
				c0,0.8643-0.498,1.5449-1.7998,2.1895v0.0361c0.6934,0.8027,1.4717,1.7031,1.9951,2.2871c0.377-0.5723,0.6318-1.3623,0.79-2.3965
				h0.9727c-0.2188,1.2891-0.5713,2.3105-1.1797,3.0283c0.4385,0.4619,0.8643,0.9248,1.3506,1.4473H2800.8359z M2799.5469,2138.5254
				c-0.4863-0.5342-1.3984-1.5322-2.3105-2.5781c-0.4385,0.2803-1.0586,0.8027-1.0586,1.666c0,0.9365,0.6934,1.6543,1.6787,1.6543
				C2798.5615,2139.2676,2799.1572,2138.9512,2799.5469,2138.5254z M2797.0537,2133.5273c0,0.584,0.2676,1.0342,0.6201,1.4717
				c0.8271-0.4746,1.3623-0.9121,1.3623-1.6055c0-0.498-0.2803-1.0576-0.9492-1.0576
				C2797.4062,2132.3359,2797.0537,2132.8955,2797.0537,2133.5273z"
          />
          <path
            d="M2802.7344,2131.2051h2.7725c2.6514,0,4.3291,1.7021,4.3291,4.3652c0,2.6641-1.6777,4.3662-4.3291,4.3662h-2.7725
				V2131.2051z M2805.5068,2138.9268c1.9824,0,3.1865-1.3252,3.1865-3.3564c0-2.043-1.2041-3.3564-3.1982-3.3564h-1.6182v6.7129
				H2805.5068z"
          />
          <path d="M2809.9248,2140.4229l3.3926-8.8164h0.8271l-3.4053,8.8164H2809.9248z" />
          <path d="M2814.6064,2131.2051h1.1924v1.2646h-1.1924V2131.2051z M2815.75,2139.9365h-1.0947v-6.3604h1.0947V2139.9365z" />
          <path
            d="M2817.209,2133.5762h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2133.5762z"
          />
          <path
            d="M2823.7061,2133.5762h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2133.5762z"
          />
          <path
            d="M2832.7197,2140.0703c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C2835.6748,2138.7324,2834.459,2140.0703,2832.7197,2140.0703z M2832.7197,2139.1338
				c1.1182,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C2830.8711,2138.21,2831.6006,2139.1338,2832.7197,2139.1338z"
          />
          <path d="M2838.3281,2139.9365l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2838.3281z" />
          <path
            d="M2842.002,2138.2217c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2842.9385,2140.0459,2842.002,2139.3652,2842.002,2138.2217z M2845.9551,2136.6777l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2136.6777z"
          />
          <path
            d="M2848.7041,2134.5127h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V2134.5127z"
          />
          <path d="M2852.2559,2131.2051h1.1924v1.2646h-1.1924V2131.2051z M2853.3994,2139.9365h-1.0947v-6.3604h1.0947V2139.9365z" />
          <path
            d="M2854.4209,2136.7627c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2855.6367,2140.0703,2854.4209,2138.7207,2854.4209,2136.7627z
				 M2855.5273,2136.0938h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C2856.415,2134.3906,2855.7217,2135.0107,2855.5273,2136.0938z"
          />
          <path d="M2863.2158,2131.2051h1.1924v1.2646h-1.1924V2131.2051z M2864.3594,2139.9365h-1.0947v-6.3604h1.0947V2139.9365z" />
          <path
            d="M2865.8184,2133.5762h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2133.5762z"
          />
          <path
            d="M2792.79,2150.7627c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2793.7988,2154.0703,2792.79,2152.5625,2792.79,2150.7627z
				 M2795.7568,2148.415c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2797.5566,2149.0596,2796.6572,2148.415,2795.7568,2148.415z"
          />
          <path
            d="M2805.123,2153.9365h-1.0947v-0.876c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0938v3.9766c0,0.9004,0.3896,1.5078,1.3262,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V2153.9365z"
          />
          <path
            d="M2811.6201,2153.9365h-1.0947v-0.876c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3887,1.5078,1.3252,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V2153.9365z"
          />
          <path
            d="M2816.3652,2148.6582c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V2148.6582z"
          />
          <path d="M2821.8018,2153.9365h-5.0098v-0.8271l3.6719-4.5967h-3.624v-0.9365h4.9258v0.8389l-3.5996,4.585h3.6357V2153.9365z" />
          <path
            d="M2822.3633,2152.2217c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2823.2998,2154.0459,2822.3633,2153.3652,2822.3633,2152.2217z M2826.3164,2150.6777l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2150.6777z"
          />
          <path
            d="M2828.3965,2152.2217c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2829.333,2154.0459,2828.3965,2153.3652,2828.3965,2152.2217z M2832.3496,2150.6777l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2150.6777z"
          />
          <path
            d="M2836.0234,2153.9365h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0332-1.083,2.0312-1.083c1.3125,0,2.1035,0.9365,2.1035,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V2153.9365z"
          />
          <path
            d="M2845.1592,2145.2051h1.0947v3.2344c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2145.2051z"
          />
          <path
            d="M2851.2178,2150.7627c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2852.4336,2154.0703,2851.2178,2152.7207,2851.2178,2150.7627z
				 M2852.3242,2150.0938h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C2853.2119,2148.3906,2852.5186,2149.0107,2852.3242,2150.0938z"
          />
          <path d="M2857.8105,2145.2051h1.1924v1.2646h-1.1924V2145.2051z M2858.9541,2153.9365h-1.0947v-6.3604h1.0947V2153.9365z" />
          <path
            d="M2859.9756,2150.7627c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2860.9844,2154.0703,2859.9756,2152.5625,2859.9756,2150.7627z
				 M2862.9424,2148.415c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2864.7422,2149.0596,2863.8428,2148.415,2862.9424,2148.415z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="59">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M783.41,1288.9771c0,25.2689-20.4839,45.75-45.75,45.75c-25.265,0-45.75-20.4811-45.75-45.75
			c0-25.266,20.485-45.75,45.75-45.75C762.926,1243.2271,783.41,1263.7111,783.41,1288.9771"
        />
        <g>
          <path d="M712.4663,1272.0039l-3.3203-8.7319h1.2529l2.6997,7.2725l2.6997-7.2725h1.1919l-3.2959,8.7319H712.4663z" />
          <path
            d="M717.6733,1268.8296c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133V1269h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C718.8896,1272.1377,717.6733,1270.7876,717.6733,1268.8296z M718.7803,1268.1611
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C719.668,1266.4585,718.9746,1267.0786,718.7803,1268.1611z"
          />
          <path
            d="M727.9761,1266.7261c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1266.7261z"
          />
          <path
            d="M730.2759,1272.0039h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1272.0039z"
          />
          <path
            d="M742.2939,1272.1377c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C745.249,1270.7998,744.0327,1272.1377,742.2939,1272.1377z M742.2939,1271.2012
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C740.4453,1270.2769,741.1748,1271.2012,742.2939,1271.2012z"
          />
          <path
            d="M749.0693,1274.3267c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3408,0.6201-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C752.0244,1273.293,750.7964,1274.3267,749.0693,1274.3267z M749.1787,1266.4829
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C750.9424,1267.1636,750.0908,1266.4829,749.1787,1266.4829z"
          />
          <path
            d="M753.4126,1268.8296c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133V1269h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C754.6289,1272.1377,753.4126,1270.7876,753.4126,1268.8296z M754.5195,1268.1611
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C755.4072,1266.4585,754.7139,1267.0786,754.5195,1268.1611z"
          />
          <path
            d="M760.4194,1265.6436h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1265.6436z"
          />
          <path
            d="M705.959,1286.1377c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C708.9141,1284.7998,707.6978,1286.1377,705.959,1286.1377z M705.959,1285.2012
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C704.1104,1284.2769,704.8398,1285.2012,705.959,1285.2012z"
          />
          <path
            d="M711.397,1286.0039h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1286.0039z"
          />
          <path
            d="M726.0303,1285.1768c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9731,0.4497c-0.3042,1.0581-1.2285,1.8608-2.5542,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C724.1211,1284.2769,724.8994,1285.1768,726.0303,1285.1768z"
          />
          <path d="M729.7412,1277.272h1.1919v1.2651h-1.1919V1277.272z M730.8848,1286.0039H729.79v-6.3604h1.0947V1286.0039z" />
          <path
            d="M736.0054,1280.7261c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1280.7261z"
          />
          <path
            d="M739.7764,1285.1768c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9731,0.4497c-0.3042,1.0581-1.2285,1.8608-2.5542,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C737.8672,1284.2769,738.6455,1285.1768,739.7764,1285.1768z"
          />
          <path
            d="M748.583,1286.0039h-1.0942v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8853,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5933-0.5962,1.5933-1.6538v-3.8311h1.0942V1286.0039z"
          />
          <path d="M751.4917,1286.0039h-1.0947v-8.7319h1.0947V1286.0039z" />
          <path
            d="M752.8179,1284.2891c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C753.7544,1286.1133,752.8179,1285.4321,752.8179,1284.2891z M756.77,1282.7446l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1282.7446z"
          />
          <path d="M759.666,1277.272h1.1919v1.2651h-1.1919V1277.272z M760.8096,1286.0039h-1.0947v-6.3604h1.0947V1286.0039z" />
          <path
            d="M765.9302,1280.7261c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1280.7261z"
          />
          <path
            d="M766.6973,1282.8296c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133V1283h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C767.9136,1286.1377,766.6973,1284.7876,766.6973,1282.8296z M767.8042,1282.1611
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C768.6919,1280.4585,767.9985,1281.0786,767.8042,1282.1611z"
          />
          <path
            d="M695.1509,1299.0796v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C696.2329,1300.1377,695.5278,1299.7119,695.1509,1299.0796z M695.1265,1297.061c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1297.061z"
          />
          <path
            d="M700.8438,1296.8296c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133V1297h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C702.0601,1300.1377,700.8438,1298.7876,700.8438,1296.8296z M701.9507,1296.1611
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C702.8384,1294.4585,702.145,1295.0786,701.9507,1296.1611z"
          />
          <path
            d="M707.8506,1293.6436h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1293.6436z"
          />
          <path
            d="M714.2129,1298.2891c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C715.1494,1300.1133,714.2129,1299.4321,714.2129,1298.2891z M718.165,1296.7446l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1296.7446z"
          />
          <path
            d="M720.6719,1296.8296c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C721.6816,1300.1377,720.6719,1298.6299,720.6719,1296.8296z
				 M723.6392,1294.4829c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C725.4395,1295.1274,724.5396,1294.4829,723.6392,1294.4829z"
          />
          <path
            d="M727.8853,1296.8296c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133V1297h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C729.1016,1300.1377,727.8853,1298.7876,727.8853,1296.8296z M728.9922,1296.1611
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C729.8799,1294.4585,729.1865,1295.0786,728.9922,1296.1611z"
          />
          <path
            d="M738.188,1294.7261c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1294.7261z"
          />
          <path d="M739.3442,1291.272h1.1919v1.2651h-1.1919V1291.272z M740.4878,1300.0039h-1.0947v-6.3604h1.0947V1300.0039z" />
          <path
            d="M742.3125,1293.6436h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1293.6436z"
          />
          <path
            d="M751.6055,1302.3267c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3408,0.6201-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C754.5605,1301.293,753.3325,1302.3267,751.6055,1302.3267z M751.7148,1294.4829
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C753.4785,1295.1636,752.627,1294.4829,751.7148,1294.4829z"
          />
          <path
            d="M755.9487,1296.8296c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133V1297h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C757.165,1300.1377,755.9487,1298.7876,755.9487,1296.8296z M757.0557,1296.1611
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C757.9434,1294.4585,757.25,1295.0786,757.0557,1296.1611z"
          />
          <path
            d="M762.9556,1293.6436h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1293.6436z"
          />
          <path
            d="M772.5537,1294.5801h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1294.5801z"
          />
          <path
            d="M776.0811,1296.8296c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133V1297h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C777.2974,1300.1377,776.0811,1298.7876,776.0811,1296.8296z M777.188,1296.1611
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C778.0757,1294.4585,777.3823,1295.0786,777.188,1296.1611z"
          />
          <path d="M699.2686,1305.272h1.1919v1.2651h-1.1919V1305.272z M700.4121,1314.0039h-1.0947v-6.3604h1.0947V1314.0039z" />
          <path
            d="M702.9668,1314.0039h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1314.0039z"
          />
          <path
            d="M713.1968,1313.0796v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C714.2788,1314.1377,713.5737,1313.7119,713.1968,1313.0796z M713.1724,1311.061c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1311.061z"
          />
          <path d="M720.0576,1314.0039h-1.0947v-8.7319h1.0947V1314.0039z" />
          <path
            d="M721.0791,1310.8296c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133V1311h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C722.2954,1314.1377,721.0791,1312.7876,721.0791,1310.8296z M722.186,1310.1611
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C723.0737,1308.4585,722.3804,1309.0786,722.186,1310.1611z"
          />
          <path
            d="M728.8159,1314.0039h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1314.0039z"
          />
          <path
            d="M737.5137,1310.8296c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133V1311h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C738.73,1314.1377,737.5137,1312.7876,737.5137,1310.8296z M738.6206,1310.1611
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C739.5083,1308.4585,738.8149,1309.0786,738.6206,1310.1611z"
          />
          <path
            d="M744.1553,1307.6436h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1307.6436z"
          />
          <path
            d="M750.8218,1308.5801h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1308.5801z"
          />
          <path
            d="M753.9839,1310.8296c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133V1311h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C755.2002,1314.1377,753.9839,1312.7876,753.9839,1310.8296z M755.0908,1310.1611
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C755.9785,1308.4585,755.2852,1309.0786,755.0908,1310.1611z"
          />
          <path
            d="M763.9219,1308.7261c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1308.7261z"
          />
          <path
            d="M764.3242,1310.8296c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133V1311h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C765.5405,1314.1377,764.3242,1312.7876,764.3242,1310.8296z M765.4312,1310.1611
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C766.3188,1308.4585,765.6255,1309.0786,765.4312,1310.1611z"
          />
          <path
            d="M770.9658,1307.6436h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1307.6436z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="58">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3170.9529,1802.082c0,25.27-20.48,45.75-45.75,45.75c-25.2649,0-45.75-20.48-45.75-45.75
			c0-25.266,20.4851-45.75,45.75-45.75C3150.4729,1756.332,3170.9529,1776.816,3170.9529,1802.082"
        />
        <g>
          <path
            d="M3080.4434,1799.0312h2.6123c1.499,0,2.2969,0.6602,2.2969,1.8428c0,0.9072-0.5518,1.3604-1.0938,1.5479
				c0.7979,0.2168,1.3301,0.8379,1.3301,1.7344c0,1.2324-0.8965,1.9521-2.3262,1.9521h-2.8193V1799.0312z M3082.9766,1802.0674
				c0.9473,0,1.4688-0.3652,1.4688-1.1143c0-0.7588-0.5215-1.124-1.4688-1.124h-1.6162v2.2383H3082.9766z M3081.3604,1805.3096
				h1.8633c0.9463,0,1.459-0.4922,1.459-1.2217c0-0.7197-0.5127-1.2129-1.459-1.2129h-1.8633V1805.3096z"
          />
          <path
            d="M3085.8535,1803.5361c0-1.5381,0.9756-2.6816,2.375-2.6816c1.4395,0,2.1982,1.0938,2.1982,2.5234v0.2959h-3.7256
				c0.0488,1.084,0.6699,1.7744,1.5674,1.7744c0.6895,0,1.1826-0.375,1.3398-0.9863l0.7793,0.2764
				c-0.3154,0.917-1.0947,1.4785-2.1191,1.4785C3086.8389,1806.2168,3085.8535,1805.123,3085.8535,1803.5361z M3086.75,1802.9932
				h2.7803c-0.0205-0.7295-0.4141-1.3799-1.3115-1.3799C3087.4697,1801.6133,3086.9082,1802.1162,3086.75,1802.9932z"
          />
          <path d="M3092.3564,1806.1084l-1.9326-5.1553h0.9365l1.4492,4.0713l1.4287-4.0713h0.9268l-1.9316,5.1553H3092.3564z" />
          <path
            d="M3097.5781,1806.2168c-1.4092,0-2.3945-1.084-2.3945-2.6807c0-1.5977,0.9854-2.6816,2.3945-2.6816
				c1.4102,0,2.3955,1.084,2.3955,2.6816C3099.9736,1805.1328,3098.9883,1806.2168,3097.5781,1806.2168z M3097.5781,1805.458
				c0.9072,0,1.499-0.749,1.499-1.9219c0-1.1836-0.5918-1.9326-1.499-1.9326c-0.9062,0-1.498,0.749-1.498,1.9326
				C3096.0801,1804.709,3096.6719,1805.458,3097.5781,1805.458z"
          />
          <path d="M3101.4893,1806.1084h-0.8867v-7.0771h0.8867V1806.1084z" />
          <path
            d="M3103.3613,1803.3584l2.2871-2.4053h1.1045l-2.0107,2.0303l2.1777,3.125h-1.0352l-1.7734-2.4844l-0.75,0.7598v1.7246
				h-0.8867v-7.0771h0.8867V1803.3584z"
          />
          <path d="M3107.2041,1799.0312h0.9658v1.0244h-0.9658V1799.0312z M3108.1299,1806.1084h-0.8867v-5.1553h0.8867V1806.1084z" />
          <path
            d="M3109.1152,1800.9531h0.8867v0.7002c0.2959-0.4238,0.8086-0.7988,1.5381-0.7988c1.0947,0,1.7148,0.7588,1.7148,1.8535
				v3.4004h-0.8867v-3.1641c0-0.7197-0.3057-1.2617-1.0742-1.2617c-0.6406,0-1.292,0.4932-1.292,1.3506v3.0752h-0.8867V1800.9531z"
          />
          <path
            d="M3116.1514,1807.9912c-1.1338,0-1.9619-0.5225-2.248-1.4688l0.8281-0.3154c0.168,0.6602,0.7002,1.0449,1.4199,1.0449
				c0.9062,0,1.5078-0.4639,1.5078-1.5381v-0.5811c-0.2764,0.502-0.8574,0.8574-1.5771,0.8574
				c-1.4092,0-2.2373-1.1631-2.2373-2.5732c0-1.3994,0.8281-2.5625,2.2373-2.5625c0.71,0,1.3008,0.3545,1.5771,0.8574v-0.7588
				h0.8877v4.6924C3118.5469,1807.1533,3117.5508,1807.9912,3116.1514,1807.9912z M3116.2402,1801.6328
				c-0.9365,0-1.5088,0.7002-1.5088,1.7842c0,1.085,0.5723,1.7939,1.5088,1.7939c0.7393,0,1.4287-0.5518,1.4287-1.6855v-0.1865
				C3117.6689,1802.1855,3116.9795,1801.6328,3116.2402,1801.6328z"
          />
          <path
            d="M3119.6484,1804.5117c0.2764,0.6016,0.9072,0.9658,1.5771,0.9658c0.6016,0,1.1338-0.2559,1.1338-0.8184
				c0-0.5615-0.4932-0.6406-1.3408-0.8184c-0.8477-0.1865-1.7344-0.374-1.7344-1.4287c0-0.917,0.7979-1.5576,1.8926-1.5576
				c0.9268,0,1.6855,0.4531,1.9912,1.0645l-0.6309,0.5029c-0.2666-0.5127-0.75-0.8379-1.4004-0.8379
				c-0.5908,0-0.9854,0.2959-0.9854,0.7588c0,0.4834,0.4531,0.5723,1.1729,0.7295c0.8877,0.207,1.9023,0.3848,1.9023,1.5078
				c0,0.9863-0.8965,1.6367-2.001,1.6367c-0.9365,0-1.873-0.4043-2.2666-1.2021L3119.6484,1804.5117z"
          />
          <path
            d="M3125.7979,1807.9912c-1.1338,0-1.9619-0.5225-2.248-1.4688l0.8281-0.3154c0.168,0.6602,0.7002,1.0449,1.4199,1.0449
				c0.9062,0,1.5078-0.4639,1.5078-1.5381v-0.5811c-0.2764,0.502-0.8574,0.8574-1.5771,0.8574
				c-1.4092,0-2.2373-1.1631-2.2373-2.5732c0-1.3994,0.8281-2.5625,2.2373-2.5625c0.71,0,1.3008,0.3545,1.5771,0.8574v-0.7588
				h0.8877v4.6924C3128.1934,1807.1533,3127.1973,1807.9912,3125.7979,1807.9912z M3125.8867,1801.6328
				c-0.9365,0-1.5088,0.7002-1.5088,1.7842c0,1.085,0.5723,1.7939,1.5088,1.7939c0.7393,0,1.4287-0.5518,1.4287-1.6855v-0.1865
				C3127.3154,1802.1855,3126.626,1801.6328,3125.8867,1801.6328z"
          />
          <path
            d="M3128.8223,1803.5361c0-1.5381,0.9756-2.6816,2.375-2.6816c1.4395,0,2.1982,1.0938,2.1982,2.5234v0.2959h-3.7256
				c0.0488,1.084,0.6699,1.7744,1.5674,1.7744c0.6895,0,1.1826-0.375,1.3398-0.9863l0.7793,0.2764
				c-0.3154,0.917-1.0947,1.4785-2.1191,1.4785C3129.8076,1806.2168,3128.8223,1805.123,3128.8223,1803.5361z M3129.7188,1802.9932
				h2.7803c-0.0205-0.7295-0.4141-1.3799-1.3115-1.3799C3130.4385,1801.6133,3129.877,1802.1162,3129.7188,1802.9932z"
          />
          <path d="M3137.7295,1806.1084h-4.0605v-0.6699l2.9766-3.7266h-2.9375v-0.7588h3.9922v0.6797l-2.918,3.7168h2.9473V1806.1084z" />
          <path
            d="M3140.4287,1806.2168c-1.4092,0-2.3945-1.084-2.3945-2.6807c0-1.5977,0.9854-2.6816,2.3945-2.6816
				c1.4102,0,2.3955,1.084,2.3955,2.6816C3142.8242,1805.1328,3141.8389,1806.2168,3140.4287,1806.2168z M3140.4287,1805.458
				c0.9072,0,1.499-0.749,1.499-1.9219c0-1.1836-0.5918-1.9326-1.499-1.9326c-0.9062,0-1.498,0.749-1.498,1.9326
				C3138.9307,1804.709,3139.5225,1805.458,3140.4287,1805.458z"
          />
          <path
            d="M3143.4531,1800.9531h0.8867v0.7002c0.2959-0.4238,0.8086-0.7988,1.5381-0.7988c1.0947,0,1.7148,0.7588,1.7148,1.8535
				v3.4004h-0.8867v-3.1641c0-0.7197-0.3057-1.2617-1.0742-1.2617c-0.6406,0-1.292,0.4932-1.292,1.3506v3.0752h-0.8867V1800.9531z"
          />
          <path
            d="M3148.1641,1803.5361c0-1.4688,0.8174-2.6816,2.2471-2.6816c0.7197,0,1.291,0.3447,1.5967,0.8477v-2.6709h0.8877v7.0771
				h-0.8877v-0.749c-0.3057,0.5127-0.877,0.8574-1.5967,0.8574C3148.9814,1806.2168,3148.1641,1804.9941,3148.1641,1803.5361z
				 M3150.5693,1801.6328c-0.9668,0-1.5088,0.7891-1.5088,1.9033c0,1.1035,0.542,1.9023,1.5088,1.9023
				c0.7285,0,1.458-0.5322,1.458-1.7158v-0.3643C3152.0273,1802.1553,3151.2979,1801.6328,3150.5693,1801.6328z"
          />
          <path
            d="M3153.8682,1799.0312h0.8867v2.6221c0.2959-0.4238,0.8086-0.7988,1.5381-0.7988c1.0938,0,1.7148,0.7588,1.7148,1.8535
				v3.4004h-0.8867v-3.1641c0-0.7197-0.3057-1.2617-1.0742-1.2617c-0.6406,0-1.292,0.4932-1.292,1.3506v3.0752h-0.8867V1799.0312z"
          />
          <path
            d="M3158.5791,1803.5361c0-1.5381,0.9756-2.6816,2.375-2.6816c1.4395,0,2.1982,1.0938,2.1982,2.5234v0.2959h-3.7256
				c0.0488,1.084,0.6699,1.7744,1.5674,1.7744c0.6895,0,1.1826-0.375,1.3398-0.9863l0.7793,0.2764
				c-0.3154,0.917-1.0947,1.4785-2.1191,1.4785C3159.5645,1806.2168,3158.5791,1805.123,3158.5791,1803.5361z M3159.4756,1802.9932
				h2.7803c-0.0205-0.7295-0.4141-1.3799-1.3115-1.3799C3160.1953,1801.6133,3159.6338,1802.1162,3159.4756,1802.9932z"
          />
          <path d="M3163.7217,1799.0312h0.9658v1.0244h-0.9658V1799.0312z M3164.6475,1806.1084h-0.8867v-5.1553h0.8867V1806.1084z" />
          <path
            d="M3165.2783,1803.5361c0-1.4688,0.8174-2.6816,2.2471-2.6816c0.7197,0,1.291,0.3447,1.5967,0.8477v-2.6709h0.8877v7.0771
				h-0.8877v-0.749c-0.3057,0.5127-0.877,0.8574-1.5967,0.8574C3166.0957,1806.2168,3165.2783,1804.9941,3165.2783,1803.5361z
				 M3167.6836,1801.6328c-0.9668,0-1.5088,0.7891-1.5088,1.9033c0,1.1035,0.542,1.9023,1.5088,1.9023
				c0.7285,0,1.458-0.5322,1.458-1.7158v-0.3643C3169.1416,1802.1553,3168.4121,1801.6328,3167.6836,1801.6328z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="57">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M1867.309,2540.781c0,25.2661-20.485,45.75-45.75,45.75c-25.266,0-45.75-20.4839-45.75-45.75
			c0-25.269,20.484-45.75,45.75-45.75C1846.824,2495.031,1867.309,2515.512,1867.309,2540.781"
        />
        <g>
          <path d="M1795.4575,2529.0762v8.7314h-1.167v-4.001h-4.6821v4.001h-1.1675v-8.7314h1.1675v3.709h4.6821v-3.709H1795.4575z" />
          <path
            d="M1799.8506,2537.9414c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1802.8057,2536.6035,1801.5894,2537.9414,1799.8506,2537.9414z M1799.8506,2537.0049
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1798.002,2536.0811,1798.7314,2537.0049,1799.8506,2537.0049z"
          />
          <path
            d="M1803.7559,2534.6338c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1804.9722,2537.9414,1803.7559,2536.5918,1803.7559,2534.6338z
				 M1804.8628,2533.9648h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1805.7505,2532.2617,1805.0571,2532.8818,1804.8628,2533.9648z"
          />
          <path d="M1812.3921,2537.8076l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1812.3921z" />
          <path
            d="M1816.4922,2534.6338c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1817.7085,2537.9414,1816.4922,2536.5918,1816.4922,2534.6338z
				 M1817.5991,2533.9648h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1818.4868,2532.2617,1817.7935,2532.8818,1817.5991,2533.9648z"
          />
          <path
            d="M1823.061,2534.6338c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1824.2773,2537.9414,1823.061,2536.5918,1823.061,2534.6338z M1824.168,2533.9648
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1825.0557,2532.2617,1824.3623,2532.8818,1824.168,2533.9648z"
          />
          <path d="M1831.1626,2537.8076h-1.0947v-8.7314h1.0947V2537.8076z" />
          <path
            d="M1832.9873,2529.0762h1.0947v3.2344c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2529.0762z"
          />
          <path
            d="M1839.4102,2534.6338c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1840.6265,2537.9414,1839.4102,2536.5918,1839.4102,2534.6338z
				 M1840.5171,2533.9648h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1841.4048,2532.2617,1840.7114,2532.8818,1840.5171,2533.9648z"
          />
          <path d="M1846.3682,2529.0762h1.1919v1.2646h-1.1919V2529.0762z M1847.5117,2537.8076h-1.0947v-6.3604h1.0947V2537.8076z" />
          <path
            d="M1848.8984,2534.6338c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1849.9082,2537.9414,1848.8984,2536.4336,1848.8984,2534.6338z
				 M1851.8657,2532.2861c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1853.666,2532.9307,1852.7661,2532.2861,1851.8657,2532.2861z"
          />
          <path
            d="M1809.1636,2550.0928c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1810.1001,2551.917,1809.1636,2551.2363,1809.1636,2550.0928z M1813.1157,2548.5488l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2548.5488z"
          />
          <path
            d="M1818.8335,2543.9395c-0.1826-0.0244-0.292-0.0244-0.4746-0.0244c-0.6445,0-0.9971,0.2793-0.9971,1.0947v0.4375h1.4106
				v0.9365h-1.4106v5.4238h-1.0947v-5.4238h-0.9604v-0.9365h0.9604v-0.5107c0-1.2402,0.6812-1.9941,1.9824-1.9941
				c0.2188,0,0.3403,0.0117,0.584,0.0244V2543.9395z"
          />
          <path d="M1821.4243,2551.8076l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1821.4243z" />
          <path
            d="M1825.4639,2550.0928c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1826.4004,2551.917,1825.4639,2551.2363,1825.4639,2550.0928z M1829.416,2548.5488l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2548.5488z"
          />
          <path d="M1833.4556,2551.8076h-1.0947v-8.7314h1.0947V2551.8076z" />
        </g>
      </g>
      <g onClick={handleClick} id="56">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M806.039,1667.418c0,25.266-20.484,45.75-45.75,45.75s-45.75-20.484-45.75-45.75
			c0-25.27,20.484-45.75,45.75-45.75S806.039,1642.1479,806.039,1667.418"
        />
        <g>
          <path
            d="M740.3442,1664.4443l-2.6636-7.1875v7.1875h-1.0942v-8.7314h1.5928l2.6514,7.1748l2.6631-7.1748h1.5444v8.7314h-1.082
				v-7.1875l-2.6758,7.1875H740.3442z"
          />
          <path
            d="M746.4277,1662.7295c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C747.3643,1664.5537,746.4277,1663.873,746.4277,1662.7295z M750.3799,1661.1855l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1661.1855z"
          />
          <path
            d="M753.4951,1659.0205h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2554,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V1659.0205z"
          />
          <path
            d="M757.0225,1661.2705c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C758.2388,1664.5781,757.0225,1663.2285,757.0225,1661.2705z M758.1294,1660.6016
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C759.0171,1658.8984,758.3237,1659.5186,758.1294,1660.6016z"
          />
          <path d="M768.2256,1664.4443l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H768.2256z" />
          <path
            d="M772.2651,1662.7295c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C773.2017,1664.5537,772.2651,1663.873,772.2651,1662.7295z M776.2173,1661.1855l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1661.1855z"
          />
          <path
            d="M779.1621,1658.084h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1658.084z"
          />
          <path
            d="M724.2393,1675.2705c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C725.249,1678.5781,724.2393,1677.0703,724.2393,1675.2705z
				 M727.2065,1672.9229c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C729.0068,1673.5674,728.1069,1672.9229,727.2065,1672.9229z"
          />
          <path
            d="M731.3921,1676.7295c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C732.3286,1678.5537,731.3921,1677.873,731.3921,1676.7295z M735.3442,1675.1855l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1675.1855z"
          />
          <path
            d="M739.3838,1675.0518l2.8213-2.9678h1.3618l-2.481,2.5049l2.688,3.8555h-1.2769l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V1675.0518z"
          />
          <path d="M745.8799,1678.4443h-1.0947v-8.7314h1.0947V1678.4443z" />
          <path
            d="M747.2061,1676.7295c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C748.1426,1678.5537,747.2061,1677.873,747.2061,1676.7295z M751.1582,1675.1855l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1675.1855z"
          />
          <path
            d="M754.103,1672.084h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1672.084z"
          />
          <path
            d="M760.5259,1675.2705c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C761.5356,1678.5781,760.5259,1677.0703,760.5259,1675.2705z
				 M763.4932,1672.9229c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C765.2935,1673.5674,764.3936,1672.9229,763.4932,1672.9229z"
          />
          <path
            d="M769.272,1677.5205v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C770.354,1678.5781,769.6489,1678.1523,769.272,1677.5205z M769.2476,1675.501c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6685-2.3477-1.8604-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V1675.501z"
          />
          <path
            d="M777.9204,1678.5781c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C780.8755,1677.2402,779.6592,1678.5781,777.9204,1678.5781z M777.9204,1677.6416
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C776.0718,1676.7178,776.8013,1677.6416,777.9204,1677.6416z"
          />
          <path
            d="M787.3105,1678.4443h-1.0942v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8853,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5933-0.5957,1.5933-1.6533v-3.8311h1.0942V1678.4443z"
          />
          <path
            d="M793.0767,1672.084l1.3135,4.7432l1.3257-4.7432h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H793.0767z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="55">
        <path
          style={{ fill: '#33CC61' }}
          className="node"
          d="M2218.23,2231.3911c0,25.2649-20.48,45.75-45.75,45.75c-25.2649,0-45.75-20.4851-45.75-45.75
			c0-25.2661,20.4851-45.75,45.75-45.75C2197.75,2185.6411,2218.23,2206.125,2218.23,2231.3911"
        />
        <g>
          <path
            d="M2130.8955,2212.6855h3.2227c1.8481,0,2.8335,0.8145,2.8335,2.2734c0,1.1191-0.6812,1.6797-1.3501,1.9102
				c0.9854,0.2676,1.6421,1.0332,1.6421,2.1406c0,1.5195-1.1069,2.4082-2.8701,2.4082h-3.478V2212.6855z M2134.021,2216.4316
				c1.167,0,1.812-0.4512,1.812-1.375c0-0.9355-0.645-1.3867-1.812-1.3867h-1.9946v2.7617H2134.021z M2132.0264,2220.4316h2.2983
				c1.1675,0,1.7998-0.6074,1.7998-1.5078c0-0.8867-0.6323-1.4961-1.7998-1.4961h-2.2983V2220.4316z"
          />
          <path
            d="M2137.6948,2218.2441c0-1.8984,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7114,1.3496,2.7114,3.1133v0.3652h-4.5967
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9609,0.3398
				c-0.3896,1.1309-1.3501,1.8242-2.6152,1.8242C2138.9111,2221.5508,2137.6948,2220.2012,2137.6948,2218.2441z
				 M2138.8018,2217.5742h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C2139.6895,2215.8711,2138.9961,2216.4922,2138.8018,2217.5742z"
          />
          <path d="M2145.3101,2221.418h-1.0947v-8.7324h1.0947V2221.418z" />
          <path
            d="M2146.2104,2218.2441c0-1.8984,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2147.4263,2221.5508,2146.2104,2220.2012,2146.2104,2218.2441z
				 M2147.3169,2217.5742h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C2148.2046,2215.8711,2147.5112,2216.4922,2147.3169,2217.5742z"
          />
          <path d="M2152.6821,2212.6855h1.1924v1.2656h-1.1924V2212.6855z M2153.8257,2221.418h-1.0947v-6.3613h1.0947V2221.418z" />
          <path
            d="M2154.7261,2218.2441c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0449v-3.2949h1.0947v8.7324
				h-1.0947v-0.9238c-0.377,0.6309-1.082,1.0566-1.9697,1.0566C2155.7349,2221.5508,2154.7261,2220.043,2154.7261,2218.2441z
				 M2157.6929,2215.8965c-1.1914,0-1.8604,0.9727-1.8604,2.3477c0,1.3613,0.6689,2.3457,1.8604,2.3457
				c0.9004,0,1.7998-0.6562,1.7998-2.1152v-0.4492C2159.4927,2216.541,2158.5933,2215.8965,2157.6929,2215.8965z"
          />
          <path
            d="M2162.0356,2219.4473c0.3408,0.7422,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6914-0.6084-0.7891-1.6543-1.0078c-1.0459-0.2324-2.1396-0.4629-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5586,2.4561,1.3125l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0332-1.7266-1.0332
				c-0.7295,0-1.2158,0.3652-1.2158,0.9355c0,0.5957,0.5586,0.7051,1.4463,0.9004c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2148-1.1064,2.0176-2.4688,2.0176c-1.1553,0-2.3105-0.498-2.7969-1.4824L2162.0356,2219.4473z"
          />
          <path
            d="M2167.4614,2219.4473c0.3408,0.7422,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6914-0.6084-0.7891-1.6543-1.0078c-1.0459-0.2324-2.1396-0.4629-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5586,2.4561,1.3125l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0332-1.7266-1.0332
				c-0.7295,0-1.2158,0.3652-1.2158,0.9355c0,0.5957,0.5586,0.7051,1.4463,0.9004c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2148-1.1064,2.0176-2.4688,2.0176c-1.1553,0-2.3105-0.498-2.7969-1.4824L2167.4614,2219.4473z"
          />
          <path
            d="M2172.9116,2215.9941h-0.9844v-0.9375h0.9844v-1.8594h1.083v1.8594h1.4473v0.9375h-1.4473v3.7324
				c0,0.6699,0.3281,0.791,0.8877,0.791c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9355c-0.2803,0.0605-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.582V2215.9941z"
          />
          <path d="M2176.3423,2212.6855h1.1924v1.2656h-1.1924V2212.6855z M2177.4858,2221.418h-1.0947v-6.3613h1.0947V2221.418z" />
          <path
            d="M2179.9175,2221.418h-1.0947v-6.3613h1.0947v0.8633c0.3398-0.5215,0.9482-0.9844,1.8359-0.9844
				c0.8516,0,1.4473,0.4258,1.7637,1.082c0.3525-0.5234,1.0332-1.082,2.0312-1.082c1.3125,0,2.1035,0.9355,2.1035,2.2871v4.1953
				h-1.0947v-3.9043c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6211-1.5078,1.6543v3.8066h-1.0947v-3.9043
				c0-0.8887-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6211-1.5078,1.6543V2221.418z"
          />
          <path
            d="M2193.979,2221.418h-1.0947v-0.877c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9355-2.1279-2.2852
				v-4.209h1.0947v3.9766c0,0.9004,0.3887,1.5078,1.3252,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6523v-3.832h1.0947V2221.418z"
          />
          <path d="M2196.4019,2221.418h-1.0947v-8.7324h1.0947V2221.418z" />
          <path
            d="M2197.2407,2219.7031c0-1.2656,0.9854-1.7891,2.4082-2.0801l1.5449-0.3164v-0.2676c0-0.7285-0.4014-1.1426-1.2529-1.1426
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0332,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0547v3.1016c0,0.4141,0.2559,0.5469,0.6807,0.4492v0.877
				c-0.9727,0.1211-1.5322-0.1094-1.666-0.7188c-0.3887,0.5-1.0938,0.8281-1.958,0.8281
				C2198.1772,2221.5273,2197.2407,2220.8457,2197.2407,2219.7031z M2201.1938,2218.1582l-1.2412,0.2676
				c-0.9727,0.1953-1.6289,0.4629-1.6289,1.2285c0,0.6191,0.4492,0.9844,1.1426,0.9844c0.9365,0,1.7275-0.5703,1.7275-1.3867
				V2218.1582z"
          />
          <path
            d="M2203.6509,2215.0566h1.0947v0.8633c0.3643-0.5215,0.9971-0.9844,1.8965-0.9844c1.3506,0,2.1162,0.9355,2.1162,2.2871
				v4.1953h-1.0938v-3.9043c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7949h-1.0947V2215.0566z"
          />
          <path
            d="M2210.1699,2219.4473c0.3418,0.7422,1.1191,1.1914,1.9473,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6914-0.6094-0.7891-1.6543-1.0078c-1.0469-0.2324-2.1406-0.4629-2.1406-1.7637c0-1.1309,0.9844-1.9219,2.3359-1.9219
				c1.1426,0,2.0781,0.5586,2.4551,1.3125l-0.7773,0.6211c-0.3281-0.6328-0.9238-1.0332-1.7266-1.0332
				c-0.7305,0-1.2168,0.3652-1.2168,0.9355c0,0.5957,0.5586,0.7051,1.4473,0.9004c1.0938,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2148-1.1074,2.0176-2.4688,2.0176c-1.1562,0-2.3105-0.498-2.7969-1.4824L2210.1699,2219.4473z"
          />
          <path d="M2136.9526,2235.418l-2.3833-6.3613h1.1553l1.7876,5.0234l1.7637-5.0234h1.1431l-2.3838,6.3613H2136.9526z" />
          <path
            d="M2143.521,2235.5508c-1.7388,0-2.9551-1.3379-2.9551-3.3066c0-1.9707,1.2163-3.3086,2.9551-3.3086
				c1.7393,0,2.9551,1.3379,2.9551,3.3086C2146.4761,2234.2129,2145.2603,2235.5508,2143.521,2235.5508z M2143.521,2234.6152
				c1.1191,0,1.8486-0.9238,1.8486-2.3711c0-1.4609-0.7295-2.3848-1.8486-2.3848c-1.1187,0-1.8481,0.9238-1.8481,2.3848
				C2141.6729,2233.6914,2142.4023,2234.6152,2143.521,2234.6152z"
          />
          <path
            d="M2149.895,2235.5508c-1.7393,0-2.9551-1.3379-2.9551-3.3066c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C2152.8501,2234.2129,2151.6343,2235.5508,2149.895,2235.5508z M2149.895,2234.6152
				c1.1182,0,1.8486-0.9238,1.8486-2.3711c0-1.4609-0.7305-2.3848-1.8486-2.3848c-1.1191,0-1.8486,0.9238-1.8486,2.3848
				C2148.0464,2233.6914,2148.7759,2234.6152,2149.895,2234.6152z"
          />
          <path
            d="M2157.0483,2230.1387c-0.1826-0.0352-0.3281-0.0469-0.5352-0.0469c-0.9248,0-1.666,0.7285-1.666,1.8477v3.4785h-1.0947
				v-6.3613h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0137,0.4385,0.0254V2230.1387z"
          />
          <path
            d="M2159.4097,2232.2441c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0449v-3.2949h1.0947v8.7324
				h-1.0947v-0.9238c-0.377,0.6309-1.082,1.0566-1.9697,1.0566C2160.4185,2235.5508,2159.4097,2234.043,2159.4097,2232.2441z
				 M2162.3765,2229.8965c-1.1914,0-1.8604,0.9727-1.8604,2.3477c0,1.3613,0.6689,2.3457,1.8604,2.3457
				c0.9004,0,1.7998-0.6562,1.7998-2.1152v-0.4492C2164.1763,2230.541,2163.2769,2229.8965,2162.3765,2229.8965z"
          />
          <path
            d="M2171.6206,2235.418h-1.0947v-0.877c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9355-2.1279-2.2852
				v-4.209h1.0938v3.9766c0,0.9004,0.3896,1.5078,1.3262,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6523v-3.832h1.0947V2235.418z"
          />
          <path
            d="M2177.9956,2235.418h-1.0947v-0.877c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9355-2.1279-2.2852
				v-4.209h1.0938v3.9766c0,0.9004,0.3896,1.5078,1.3262,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6523v-3.832h1.0947V2235.418z"
          />
          <path
            d="M2182.6196,2230.1387c-0.1826-0.0352-0.3281-0.0469-0.5352-0.0469c-0.9248,0-1.666,0.7285-1.666,1.8477v3.4785h-1.0947
				v-6.3613h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0137,0.4385,0.0254V2230.1387z"
          />
          <path d="M2187.9351,2235.418h-5.0098v-0.8281l3.6719-4.5957h-3.624v-0.9375h4.9258v0.8398l-3.5996,4.584h3.6357V2235.418z" />
          <path
            d="M2188.3735,2233.7031c0-1.2656,0.9854-1.7891,2.4082-2.0801l1.5449-0.3164v-0.2676c0-0.7285-0.4014-1.1426-1.2529-1.1426
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0332,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0547v3.1016c0,0.4141,0.2559,0.5469,0.6807,0.4492v0.877
				c-0.9727,0.1211-1.5322-0.1094-1.666-0.7188c-0.3887,0.5-1.0938,0.8281-1.958,0.8281
				C2189.3101,2235.5273,2188.3735,2234.8457,2188.3735,2233.7031z M2192.3267,2232.1582l-1.2412,0.2676
				c-0.9727,0.1953-1.6289,0.4629-1.6289,1.2285c0,0.6191,0.4492,0.9844,1.1426,0.9844c0.9365,0,1.7275-0.5703,1.7275-1.3867
				V2232.1582z"
          />
          <path
            d="M2195.8794,2235.418h-1.0947v-6.3613h1.0947v0.8633c0.3398-0.5215,0.9482-0.9844,1.8359-0.9844
				c0.8516,0,1.4473,0.4258,1.7637,1.082c0.3525-0.5234,1.0332-1.082,2.0312-1.082c1.3135,0,2.1035,0.9355,2.1035,2.2871v4.1953
				h-1.0947v-3.9043c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6211-1.5078,1.6543v3.8066h-1.0947v-3.9043
				c0-0.8887-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6211-1.5078,1.6543V2235.418z"
          />
          <path
            d="M2204.4565,2232.2441c0-1.8984,1.2041-3.3086,2.9307-3.3086c1.7749,0,2.7124,1.3496,2.7124,3.1133v0.3652h-4.5972
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6538-1.2168l0.9609,0.3398
				c-0.3887,1.1309-1.3501,1.8242-2.6147,1.8242C2205.6724,2235.5508,2204.4565,2234.2012,2204.4565,2232.2441z M2205.563,2231.5742
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2206.4507,2229.8711,2205.7573,2230.4922,2205.563,2231.5742z"
          />
          <path d="M2130.9614,2249.418l-2.3833-6.3613h1.1553l1.7876,5.0234l1.7637-5.0234h1.1431l-2.3838,6.3613H2130.9614z" />
          <path
            d="M2137.5303,2249.5508c-1.7393,0-2.9556-1.3379-2.9556-3.3066c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C2140.4854,2248.2129,2139.269,2249.5508,2137.5303,2249.5508z M2137.5303,2248.6152
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.4609-0.7295-2.3848-1.8481-2.3848c-1.1191,0-1.8486,0.9238-1.8486,2.3848
				C2135.6816,2247.6914,2136.4111,2248.6152,2137.5303,2248.6152z"
          />
          <path
            d="M2140.9492,2246.2441c0-1.8984,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9341,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2142.1655,2249.5508,2140.9492,2248.2012,2140.9492,2246.2441z
				 M2142.0562,2245.5742h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C2142.9438,2243.8711,2142.2505,2244.4922,2142.0562,2245.5742z"
          />
          <path
            d="M2147.0317,2246.2441c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0449v-3.2949h1.0947v8.7324
				h-1.0947v-0.9238c-0.377,0.6309-1.082,1.0566-1.9697,1.0566C2148.0405,2249.5508,2147.0317,2248.043,2147.0317,2246.2441z
				 M2149.9985,2243.8965c-1.1914,0-1.8604,0.9727-1.8604,2.3477c0,1.3613,0.6689,2.3457,1.8604,2.3457
				c0.9004,0,1.7998-0.6562,1.7998-2.1152v-0.4492C2151.7983,2244.541,2150.8989,2243.8965,2149.9985,2243.8965z"
          />
          <path
            d="M2154.3423,2247.4473c0.3408,0.7422,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6914-0.6084-0.7891-1.6543-1.0078c-1.0459-0.2324-2.1396-0.4629-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5586,2.4561,1.3125l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0332-1.7266-1.0332
				c-0.7295,0-1.2158,0.3652-1.2158,0.9355c0,0.5957,0.5586,0.7051,1.4463,0.9004c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2148-1.1064,2.0176-2.4688,2.0176c-1.1553,0-2.3105-0.498-2.7969-1.4824L2154.3423,2247.4473z"
          />
          <path
            d="M2159.1851,2246.2441c0-1.8984,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2160.4009,2249.5508,2159.1851,2248.2012,2159.1851,2246.2441z
				 M2160.2915,2245.5742h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C2161.1792,2243.8711,2160.4858,2244.4922,2160.2915,2245.5742z"
          />
          <path d="M2166.7993,2249.418h-1.0947v-8.7324h1.0947V2249.418z" />
          <path
            d="M2168.2827,2247.4473c0.3408,0.7422,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6914-0.6084-0.7891-1.6543-1.0078c-1.0459-0.2324-2.1396-0.4629-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5586,2.4561,1.3125l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0332-1.7266-1.0332
				c-0.7295,0-1.2158,0.3652-1.2158,0.9355c0,0.5957,0.5586,0.7051,1.4463,0.9004c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2148-1.1064,2.0176-2.4688,2.0176c-1.1553,0-2.3105-0.498-2.7969-1.4824L2168.2827,2247.4473z"
          />
          <path
            d="M2173.9517,2251.6797c-0.2666,0-0.4004-0.0117-0.5957-0.0488v-0.9492c0.207,0.0488,0.3408,0.0605,0.5234,0.0605
				c0.4736,0,0.7295-0.1699,0.9844-0.8027l0.3047-0.7539l-2.3594-6.1289h1.1553l1.7881,4.8652l1.7627-4.8652h1.1436l-2.7363,7.0547
				C2175.5571,2251.0723,2175.0591,2251.6797,2173.9517,2251.6797z"
          />
          <path
            d="M2179.3892,2247.4473c0.3408,0.7422,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6914-0.6084-0.7891-1.6543-1.0078c-1.0459-0.2324-2.1396-0.4629-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5586,2.4561,1.3125l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0332-1.7266-1.0332
				c-0.7295,0-1.2158,0.3652-1.2158,0.9355c0,0.5957,0.5586,0.7051,1.4463,0.9004c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2148-1.1064,2.0176-2.4688,2.0176c-1.1553,0-2.3105-0.498-2.7969-1.4824L2179.3892,2247.4473z"
          />
          <path
            d="M2184.8384,2243.9941h-0.9844v-0.9375h0.9844v-1.8594h1.083v1.8594h1.4473v0.9375h-1.4473v3.7324
				c0,0.6699,0.3281,0.791,0.8877,0.791c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9355c-0.2803,0.0605-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.582V2243.9941z"
          />
          <path
            d="M2187.8804,2246.2441c0-1.8984,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2189.0962,2249.5508,2187.8804,2248.2012,2187.8804,2246.2441z
				 M2188.9868,2245.5742h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C2189.8745,2243.8711,2189.1812,2244.4922,2188.9868,2245.5742z"
          />
          <path
            d="M2195.4956,2249.418h-1.0947v-6.3613h1.0947v0.8633c0.3398-0.5215,0.9482-0.9844,1.8359-0.9844
				c0.8516,0,1.4473,0.4258,1.7637,1.082c0.3525-0.5234,1.0332-1.082,2.0312-1.082c1.3125,0,2.1035,0.9355,2.1035,2.2871v4.1953
				h-1.0947v-3.9043c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6211-1.5078,1.6543v3.8066h-1.0947v-3.9043
				c0-0.8887-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6211-1.5078,1.6543V2249.418z"
          />
          <path
            d="M2204.0728,2246.2441c0-1.8984,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7114,1.3496,2.7114,3.1133v0.3652h-4.5962
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9604,0.3398
				c-0.3887,1.1309-1.3491,1.8242-2.6138,1.8242C2205.2886,2249.5508,2204.0728,2248.2012,2204.0728,2246.2441z
				 M2205.1792,2245.5742h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C2206.0669,2243.8711,2205.3735,2244.4922,2205.1792,2245.5742z"
          />
          <path
            d="M2210.5918,2243.0566h1.0957v0.8633c0.3633-0.5215,0.9961-0.9844,1.8965-0.9844c1.3496,0,2.1152,0.9355,2.1152,2.2871
				v4.1953h-1.0938v-3.9043c0-0.8887-0.377-1.5566-1.3262-1.5566c-0.7891,0-1.5918,0.6074-1.5918,1.666v3.7949h-1.0957V2243.0566z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="54">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1026.441,1440.629c0,25.266-20.48,45.75-45.7501,45.75c-25.265,0-45.75-20.484-45.75-45.75
			c0-25.27,20.485-45.75,45.75-45.75C1005.961,1394.879,1026.441,1415.359,1026.441,1440.629"
        />
        <g>
          <path d="M945.4858,1423.6553l-3.3198-8.7319h1.2524l2.6997,7.2725l2.6997-7.2725h1.1919l-3.2959,8.7319H945.4858z" />
          <path
            d="M950.6929,1420.481c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C951.9092,1423.7891,950.6929,1422.439,950.6929,1420.481z M951.7998,1419.8125
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C952.6875,1418.1099,951.9941,1418.73,951.7998,1419.8125z"
          />
          <path
            d="M960.9956,1418.3774c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1418.3774z"
          />
          <path
            d="M963.2954,1423.6553h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1423.6553z"
          />
          <path
            d="M975.3135,1423.7891c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C978.2686,1422.4512,977.0522,1423.7891,975.3135,1423.7891z M975.3135,1422.8525
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C973.4648,1421.9282,974.1943,1422.8525,975.3135,1422.8525z"
          />
          <path
            d="M982.0889,1425.978c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3403,0.6201-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.6055,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C985.0439,1424.9443,983.8159,1425.978,982.0889,1425.978z M982.1982,1418.1343
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C983.9619,1418.8149,983.1104,1418.1343,982.1982,1418.1343z"
          />
          <path
            d="M986.4321,1420.481c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C987.6484,1423.7891,986.4321,1422.439,986.4321,1420.481z M987.5391,1419.8125
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C988.4268,1418.1099,987.7334,1418.73,987.5391,1419.8125z"
          />
          <path
            d="M993.439,1417.2949h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1417.2949z"
          />
          <path
            d="M1005.3843,1423.7891c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1008.3394,1422.4512,1007.123,1423.7891,1005.3843,1423.7891z M1005.3843,1422.8525
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1003.5356,1421.9282,1004.2651,1422.8525,1005.3843,1422.8525z"
          />
          <path
            d="M1010.8223,1423.6553h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1423.6553z"
          />
          <path
            d="M942.1172,1432.2314h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1432.2314z"
          />
          <path
            d="M945.6445,1434.481c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C946.8608,1437.7891,945.6445,1436.439,945.6445,1434.481z M946.7515,1433.8125
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C947.6392,1432.1099,946.9458,1432.73,946.7515,1433.8125z"
          />
          <path
            d="M957.7842,1436.8281c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3037,1.0581-1.228,1.8608-2.5537,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C955.875,1435.9282,956.6533,1436.8281,957.7842,1436.8281z"
          />
          <path
            d="M964.0615,1437.7891c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C967.0166,1436.4512,965.8003,1437.7891,964.0615,1437.7891z M964.0615,1436.8525
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C962.2129,1435.9282,962.9424,1436.8525,964.0615,1436.8525z"
          />
          <path
            d="M970.9224,1437.7891c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C973.8774,1436.4512,972.6611,1437.7891,970.9224,1437.7891z M970.9224,1436.8525
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C969.0737,1435.9282,969.8032,1436.8525,970.9224,1436.8525z M969.2681,1428.9722h1.2163v1.2646h-1.2163V1428.9722z
				 M971.3599,1428.9722h1.2163v1.2646h-1.2163V1428.9722z"
          />
          <path
            d="M978.5615,1432.3774c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1432.3774z"
          />
          <path
            d="M979.3286,1434.481c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C980.3384,1437.7891,979.3286,1436.2812,979.3286,1434.481z
				 M982.2959,1432.1343c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C984.0962,1432.7788,983.1963,1432.1343,982.2959,1432.1343z"
          />
          <path d="M986.9312,1428.9233h1.1919v1.2651h-1.1919V1428.9233z M988.0747,1437.6553H986.98v-6.3604h1.0947V1437.6553z" />
          <path
            d="M989.8994,1431.2949h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1431.2949z"
          />
          <path
            d="M996.3223,1434.481c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C997.5386,1437.7891,996.3223,1436.439,996.3223,1434.481z M997.4292,1433.8125
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C998.3169,1432.1099,997.6235,1432.73,997.4292,1433.8125z"
          />
          <path
            d="M1006.625,1432.3774c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1432.3774z"
          />
          <path
            d="M1007.3921,1434.481c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1008.6084,1437.7891,1007.3921,1436.439,1007.3921,1434.481z M1008.499,1433.8125
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1009.3867,1432.1099,1008.6934,1432.73,1008.499,1433.8125z"
          />
          <path
            d="M1014.3989,1431.2949h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1431.2949z"
          />
          <path
            d="M939.5503,1446.2314h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1446.2314z"
          />
          <path
            d="M948.5625,1451.6553h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V1451.6553z"
          />
          <path
            d="M950.5225,1449.6851c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9243-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L950.5225,1449.6851z"
          />
          <path
            d="M956.4346,1449.6851c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9243-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L956.4346,1449.6851z"
          />
          <path
            d="M961.7627,1448.481c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C962.979,1451.7891,961.7627,1450.439,961.7627,1448.481z M962.8696,1447.8125
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C963.7573,1446.1099,963.064,1446.73,962.8696,1447.8125z"
          />
          <path
            d="M968.7695,1445.2949h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1445.2949z"
          />
          <path
            d="M979.292,1450.731v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C980.374,1451.7891,979.6689,1451.3633,979.292,1450.731z M979.2676,1448.7124c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1448.7124z"
          />
          <path
            d="M988.7188,1446.3774c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1446.3774z"
          />
          <path d="M989.875,1442.9233h1.1919v1.2651h-1.1919V1442.9233z M991.0186,1451.6553h-1.0947v-6.3604h1.0947V1451.6553z" />
          <path
            d="M993.938,1451.6553h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1451.6553z"
          />
          <path
            d="M1002.9399,1449.9404c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1003.8765,1451.7646,1002.9399,1451.0835,1002.9399,1449.9404z M1006.8921,1448.396l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1448.396z"
          />
          <path d="M1009.7881,1442.9233h1.1919v1.2651h-1.1919V1442.9233z M1010.9316,1451.6553h-1.0947v-6.3604h1.0947V1451.6553z" />
          <path
            d="M1016.0522,1446.3774c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1446.3774z"
          />
          <path
            d="M1016.8193,1448.481c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1018.0356,1451.7891,1016.8193,1450.439,1016.8193,1448.481z M1017.9263,1447.8125
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1018.814,1446.1099,1018.1206,1446.73,1017.9263,1447.8125z"
          />
          <path
            d="M947.7065,1464.7314v3.1133h-1.0947v-8.5498h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3081c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C948.7886,1465.7891,948.0835,1465.3633,947.7065,1464.7314z M947.6821,1462.7129c0,1.459,0.8999,2.1152,1.7998,2.1152
				c1.1919,0,1.8608-0.9844,1.8608-2.3467c0-1.3745-0.6689-2.3472-1.8608-2.3472c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1462.7129z"
          />
          <path
            d="M957.1333,1460.3774c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1460.3774z"
          />
          <path
            d="M960.856,1465.7891c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9702,1.2163-3.3081,2.9556-3.3081
				c1.7388,0,2.9551,1.3379,2.9551,3.3081C963.811,1464.4512,962.5947,1465.7891,960.856,1465.7891z M960.856,1464.8525
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.4595-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9243-1.8486,2.3838
				C959.0073,1463.9287,959.7368,1464.8525,960.856,1464.8525z"
          />
          <path
            d="M964.7612,1462.4814c0-1.8125,1.0098-3.3081,2.7729-3.3081c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C965.771,1465.7891,964.7612,1464.2812,964.7612,1462.4814z
				 M967.7285,1460.1343c-1.1914,0-1.8604,0.9727-1.8604,2.3472c0,1.3623,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1152v-0.4507C969.5288,1460.7788,968.6289,1460.1343,967.7285,1460.1343z"
          />
          <path
            d="M977.4595,1465.6553h-1.0942v-0.875c-0.3652,0.5352-0.9854,1.0088-1.8853,1.0088c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5088,1.3257,1.5088c0.7905,0,1.5933-0.5967,1.5933-1.6543v-3.8311h1.0942V1465.6553z"
          />
          <path
            d="M981.8394,1464.8281c0.8755,0,1.3984-0.5586,1.5811-1.3496l0.9731,0.4502c-0.3042,1.0576-1.2285,1.8604-2.5542,1.8604
				c-1.7754,0-3.0039-1.3496-3.0039-3.3076c0-1.9702,1.2285-3.3081,3.0039-3.3081c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3594
				C979.9302,1463.9287,980.7085,1464.8281,981.8394,1464.8281z"
          />
          <path
            d="M985.1611,1462.4814c0-1.8975,1.2041-3.3081,2.9312-3.3081c1.7754,0,2.7119,1.3501,2.7119,3.1138v0.3643h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3398
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C986.3774,1465.7891,985.1611,1464.4395,985.1611,1462.4814z M986.2681,1461.8125
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C987.1558,1460.1099,986.4624,1460.73,986.2681,1461.8125z"
          />
          <path
            d="M992.168,1459.2949h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1459.2949z"
          />
          <path
            d="M999.1992,1460.2314h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.791,0.8877,0.791c0.2559,0,0.438-0.0127,0.6934-0.0732v0.9365c-0.2798,0.0605-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1460.2314z"
          />
          <path
            d="M1002.7266,1462.4814c0-1.8975,1.2041-3.3081,2.9312-3.3081c1.7754,0,2.7119,1.3501,2.7119,3.1138v0.3643h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3398
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1003.9429,1465.7891,1002.7266,1464.4395,1002.7266,1462.4814z
				 M1003.8335,1461.8125h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1004.7212,1460.1099,1004.0278,1460.73,1003.8335,1461.8125z"
          />
          <path
            d="M1009.7334,1459.2949h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1459.2949z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="53">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M977.344,1170.035c0,25.266-20.485,45.75-45.75,45.75c-25.27,0-45.75-20.484-45.75-45.75
			c0-25.269,20.48-45.75,45.75-45.75C956.859,1124.285,977.344,1144.766,977.344,1170.035"
        />
        <g>
          <path d="M903.791,1167.0615l-3.3203-8.7319h1.2529l2.6997,7.2725l2.6997-7.2725h1.1919l-3.2959,8.7319H903.791z" />
          <path
            d="M908.998,1163.8872c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C910.2144,1167.1953,908.998,1165.8452,908.998,1163.8872z M910.105,1163.2188
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C910.9927,1161.5161,910.2993,1162.1362,910.105,1163.2188z"
          />
          <path
            d="M919.3008,1161.7837c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1161.7837z"
          />
          <path
            d="M924.458,1160.7012l1.3135,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H924.458z"
          />
          <path
            d="M928.8262,1163.8872c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C930.0425,1167.1953,928.8262,1165.8452,928.8262,1163.8872z M929.9331,1163.2188
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C930.8208,1161.5161,930.1274,1162.1362,929.9331,1163.2188z"
          />
          <path
            d="M939.1289,1161.7837c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1161.7837z"
          />
          <path
            d="M941.4287,1163.6685l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V1163.6685z"
          />
          <path d="M946.7812,1158.3296h1.1919v1.2651h-1.1919V1158.3296z M947.9248,1167.0615h-1.0947v-6.3604h1.0947V1167.0615z" />
          <path
            d="M949.7495,1160.7012h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1160.7012z"
          />
          <path
            d="M959.0425,1169.3843c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3408,0.6206-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C961.9976,1168.3506,960.7695,1169.3843,959.0425,1169.3843z M959.1519,1161.5405
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C960.9155,1162.2212,960.064,1161.5405,959.1519,1161.5405z"
          />
          <path d="M909.5327,1181.0615l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H909.5327z" />
          <path
            d="M913.5723,1179.3467c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C914.5088,1181.1709,913.5723,1180.4897,913.5723,1179.3467z M917.5244,1177.8022l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1177.8022z"
          />
          <path
            d="M920.4692,1174.7012h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1174.7012z"
          />
          <path
            d="M930.9917,1181.0615h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1181.0615z"
          />
          <path
            d="M940.0542,1177.8872c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C941.2705,1181.1953,940.0542,1179.8452,940.0542,1177.8872z M941.1611,1177.2188
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C942.0488,1175.5161,941.3555,1176.1362,941.1611,1177.2188z"
          />
          <path
            d="M947.207,1179.0913c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9243-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L947.207,1179.0913z"
          />
          <path
            d="M953.1436,1175.6377h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1175.6377z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="52">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M2041.113,794.1682c0,25.2698-20.48,45.75-45.75,45.75c-25.265,0-45.75-20.4802-45.75-45.75
			c0-25.2661,20.485-45.75,45.75-45.75C2020.6331,748.4182,2041.113,768.9021,2041.113,794.1682"
        />
        <g>
          <path d="M1962.853,777.1948h-1.1675v-8.7319h1.1675V777.1948z" />
          <path
            d="M1964.3618,770.8345h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V770.8345z"
          />
          <path
            d="M1971.0278,771.771h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V771.771z"
          />
          <path
            d="M1974.1904,774.0205c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1975.4067,777.3286,1974.1904,775.9785,1974.1904,774.0205z M1975.2974,773.3521
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1976.1851,771.6494,1975.4917,772.2695,1975.2974,773.3521z"
          />
          <path
            d="M1983.2642,779.5176c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3403,0.6206-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.6055,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C1986.2192,778.4839,1984.9912,779.5176,1983.2642,779.5176z M1983.3735,771.6738
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C1985.1372,772.3545,1984.2856,771.6738,1983.3735,771.6738z"
          />
          <path
            d="M1990.9766,771.917c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V771.917z"
          />
          <path
            d="M1991.3184,775.48c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1992.2549,777.3042,1991.3184,776.623,1991.3184,775.48z M1995.2705,773.9355l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V773.9355z"
          />
          <path
            d="M1998.0205,771.771h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V771.771z"
          />
          <path d="M2001.5723,768.4629h1.1919v1.2651h-1.1919V768.4629z M2002.7158,777.1948h-1.0947v-6.3604h1.0947V777.1948z" />
          <path
            d="M2003.7373,774.0205c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2004.9536,777.3286,2003.7373,775.9785,2003.7373,774.0205z M2004.8442,773.3521
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2005.7319,771.6494,2005.0386,772.2695,2004.8442,773.3521z"
          />
          <path d="M2014.2109,777.1948l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H2014.2109z" />
          <path
            d="M2017.8853,775.48c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C2018.8218,777.3042,2017.8853,776.623,2017.8853,775.48z M2021.8374,773.9355l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V773.9355z"
          />
          <path
            d="M2024.4175,770.8345h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V770.8345z"
          />
          <path
            d="M1953.9487,788.0205c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1954.9585,791.3286,1953.9487,789.8208,1953.9487,788.0205z
				 M1956.916,785.6738c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1958.7163,786.3184,1957.8164,785.6738,1956.916,785.6738z"
          />
          <path
            d="M1966.2822,791.1948h-1.0942v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8853,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5933-0.5962,1.5933-1.6543v-3.8306h1.0942V791.1948z"
          />
          <path
            d="M1972.7778,791.1948h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V791.1948z"
          />
          <path
            d="M1977.5229,785.917c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V785.917z"
          />
          <path d="M1982.96,791.1948h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8394l-3.5996,4.5845h3.6362V791.1948z" />
          <path
            d="M1983.521,789.48c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1984.4575,791.3042,1983.521,790.623,1983.521,789.48z M1987.4731,787.9355l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V787.9355z"
          />
          <path
            d="M1989.5547,789.48c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1990.4912,791.3042,1989.5547,790.623,1989.5547,789.48z M1993.5068,787.9355l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V787.9355z"
          />
          <path
            d="M1997.1812,791.1948h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V791.1948z"
          />
          <path
            d="M2006.3169,782.4629h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V782.4629z"
          />
          <path
            d="M2012.3745,788.0205c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2013.5908,791.3286,2012.3745,789.9785,2012.3745,788.0205z M2013.4814,787.3521
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2014.3691,785.6494,2013.6758,786.2695,2013.4814,787.3521z"
          />
          <path d="M2018.9678,782.4629h1.1919v1.2651h-1.1919V782.4629z M2020.1113,791.1948h-1.0947v-6.3604h1.0947V791.1948z" />
          <path
            d="M2021.1333,788.0205c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2022.1431,791.3286,2021.1333,789.8208,2021.1333,788.0205z
				 M2024.1006,785.6738c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2025.9009,786.3184,2025.001,785.6738,2024.1006,785.6738z"
          />
          <path
            d="M2028.5654,789.2246c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L2028.5654,789.2246z"
          />
          <path d="M2033.6748,787.1572h3.3203v0.9854h-3.3203V787.1572z" />
          <path
            d="M1967.9126,804.3677c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3037,1.0581-1.228,1.8608-2.5537,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1966.0034,803.4678,1966.7817,804.3677,1967.9126,804.3677z"
          />
          <path
            d="M1974.6035,799.917c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V799.917z"
          />
          <path d="M1975.3945,796.4629h1.1919v1.2651h-1.1919V796.4629z M1976.5381,805.1948h-1.0947v-6.3604h1.0947V805.1948z" />
          <path
            d="M1978.1685,799.771h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V799.771z"
          />
          <path
            d="M1981.3306,802.0205c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1982.5469,805.3286,1981.3306,803.9785,1981.3306,802.0205z M1982.4375,801.3521
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1983.3252,799.6494,1982.6318,800.2695,1982.4375,801.3521z"
          />
          <path
            d="M1991.2686,799.917c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V799.917z"
          />
          <path d="M1992.0601,796.4629h1.1919v1.2651h-1.1919V796.4629z M1993.2036,805.1948h-1.0947v-6.3604h1.0947V805.1948z" />
          <path
            d="M1994.1646,803.48c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1995.1011,805.3042,1994.1646,804.623,1994.1646,803.48z M1998.1167,801.9355l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V801.9355z"
          />
          <path d="M2002.8496,796.4629h1.1919v1.2651h-1.1919V796.4629z M2003.9932,805.1948h-1.0947v-6.3604h1.0947V805.1948z" />
          <path
            d="M2005.4531,798.8345h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V798.8345z"
          />
          <path
            d="M2013.7129,802.0205c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2014.7227,805.3286,2013.7129,803.8208,2013.7129,802.0205z
				 M2016.6802,799.6738c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2018.4805,800.3184,2017.5806,799.6738,2016.6802,799.6738z"
          />
          <path
            d="M2020.5615,802.0205c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2021.7778,805.3286,2020.5615,803.9785,2020.5615,802.0205z M2021.6685,801.3521
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2022.5562,799.6494,2021.8628,800.2695,2021.6685,801.3521z"
          />
          <path d="M1955.4624,819.1948l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1955.4624z" />
          <path
            d="M1961.4233,819.3286c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1964.3784,817.9907,1963.1621,819.3286,1961.4233,819.3286z M1961.4233,818.3921
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1959.5747,817.4678,1960.3042,818.3921,1961.4233,818.3921z"
          />
          <path
            d="M1964.2339,816.0205c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1965.4502,819.3286,1964.2339,817.9785,1964.2339,816.0205z M1965.3408,815.3521
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1966.2285,813.6494,1965.5352,814.2695,1965.3408,815.3521z"
          />
          <path
            d="M1969.7075,816.0205c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1970.7173,819.3286,1969.7075,817.8208,1969.7075,816.0205z
				 M1972.6748,813.6738c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1974.4751,814.3184,1973.5752,813.6738,1972.6748,813.6738z"
          />
          <path
            d="M1976.4102,817.2246c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1976.4102,817.2246z"
          />
          <path
            d="M1980.6436,816.0205c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1981.8599,819.3286,1980.6436,817.9785,1980.6436,816.0205z M1981.7505,815.3521
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1982.6382,813.6494,1981.9448,814.2695,1981.7505,815.3521z"
          />
          <path d="M1987.6504,819.1948h-1.0947v-8.7319h1.0947V819.1948z" />
          <path d="M1990.0098,819.1948l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1990.0098z" />
          <path
            d="M1993.0146,816.0205c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1994.231,819.3286,1993.0146,817.9785,1993.0146,816.0205z M1994.1216,815.3521
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1995.0093,813.6494,1994.3159,814.2695,1994.1216,815.3521z"
          />
          <path
            d="M2002.2227,813.917c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V813.917z"
          />
          <path
            d="M2006.2856,812.8345l1.313,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H2006.2856z"
          />
          <path
            d="M2009.5586,816.0205c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2010.7749,819.3286,2009.5586,817.9785,2009.5586,816.0205z M2010.6655,815.3521
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2011.5532,813.6494,2010.8599,814.2695,2010.6655,815.3521z"
          />
          <path
            d="M2018.7661,813.917c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V813.917z"
          />
          <path
            d="M2019.9712,815.8018l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V815.8018z"
          />
          <path d="M2024.229,810.4629h1.1919v1.2651h-1.1919V810.4629z M2025.3726,819.1948h-1.0947v-6.3604h1.0947V819.1948z" />
          <path
            d="M2026.1025,812.8345h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V812.8345z"
          />
          <path
            d="M2034.3008,821.5176c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3408,0.6206-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C2037.2559,820.4839,2036.0278,821.5176,2034.3008,821.5176z M2034.4102,813.6738
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C2036.1738,814.3545,2035.3223,813.6738,2034.4102,813.6738z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="51">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1135.9771,1019.625c0,25.27-20.4851,45.75-45.75,45.75c-25.27,0-45.75-20.48-45.75-45.75
			c0-25.266,20.48-45.75,45.75-45.75C1115.4919,973.875,1135.9771,994.359,1135.9771,1019.625"
        />
        <g>
          <path
            d="M1073.7798,1000.9194l3.3687,8.7319h-1.1797l-0.8877-2.2983h-3.916l-0.8755,2.2983h-1.168l3.3691-8.7319H1073.7798z
				 M1071.5542,1006.3315h3.1377l-1.5811-4.0986L1071.5542,1006.3315z"
          />
          <path
            d="M1077.394,1007.9365c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1078.3306,1009.7607,1077.394,1009.0796,1077.394,1007.9365z M1081.3462,1006.3921l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1006.3921z"
          />
          <path
            d="M1083.9258,1003.291h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1003.291z"
          />
          <path
            d="M1089.9839,1006.4771c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1090.9937,1009.7852,1089.9839,1008.2773,1089.9839,1006.4771z
				 M1092.9512,1004.1304c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1094.7515,1004.7749,1093.8516,1004.1304,1092.9512,1004.1304z"
          />
          <path
            d="M1096.832,1006.4771c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1098.0483,1009.7852,1096.832,1008.4351,1096.832,1006.4771z M1097.939,1005.8086
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1098.8267,1004.106,1098.1333,1004.7261,1097.939,1005.8086z"
          />
          <path
            d="M1103.0361,1006.4771c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1104.2524,1009.7852,1103.0361,1008.4351,1103.0361,1006.4771z
				 M1104.1431,1005.8086h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1105.0308,1004.106,1104.3374,1004.7261,1104.1431,1005.8086z"
          />
          <path d="M1110.7729,1009.6514h-1.0947v-8.7319h1.0947V1009.6514z" />
          <path d="M1062.6675,1023.6514l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1062.6675z" />
          <path
            d="M1066.4028,1020.4771c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1067.6191,1023.7852,1066.4028,1022.4351,1066.4028,1020.4771z
				 M1067.5098,1019.8086h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1068.3975,1018.106,1067.7041,1018.7261,1067.5098,1019.8086z"
          />
          <path
            d="M1072.6064,1020.4771c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1073.8228,1023.7852,1072.6064,1022.4351,1072.6064,1020.4771z
				 M1073.7134,1019.8086h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1074.6011,1018.106,1073.9077,1018.7261,1073.7134,1019.8086z"
          />
          <path
            d="M1079.2485,1014.9194h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1014.9194z"
          />
          <path
            d="M1088.2617,1023.7852c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1091.2168,1022.4473,1090.0005,1023.7852,1088.2617,1023.7852z M1088.2617,1022.8486
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1086.4131,1021.9243,1087.1426,1022.8486,1088.2617,1022.8486z"
          />
          <path
            d="M1097.2871,1023.6514h-1.0942v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8853,1.0093
				c-1.3618,0-2.1284-0.9365-2.1284-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083
				c0.7905,0,1.5933-0.5962,1.5933-1.6543v-3.8306h1.0942V1023.6514z"
          />
          <path
            d="M1098.2983,1020.4771c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1099.3081,1023.7852,1098.2983,1022.2773,1098.2983,1020.4771z
				 M1101.2656,1018.1304c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1103.0659,1018.7749,1102.166,1018.1304,1101.2656,1018.1304z"
          />
          <path
            d="M1105.1465,1020.4771c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1106.3628,1023.7852,1105.1465,1022.4351,1105.1465,1020.4771z
				 M1106.2534,1019.8086h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1107.1411,1018.106,1106.4478,1018.7261,1106.2534,1019.8086z"
          />
          <path
            d="M1115.0845,1018.3735c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1018.3735z"
          />
          <path d="M1115.8755,1014.9194h1.1919v1.2651h-1.1919V1014.9194z M1117.019,1023.6514h-1.0947v-6.3604h1.0947V1023.6514z" />
          <path
            d="M1118.479,1017.291h1.0947v6.8833c0,1.1309-0.7056,1.7026-1.7881,1.7026c-0.2554,0-0.4131-0.0122-0.6079-0.0366v-0.9487
				c0.1338,0.0244,0.2432,0.0366,0.4258,0.0366c0.6323,0,0.8755-0.3037,0.8755-0.9487V1017.291z M1118.4302,1014.9194h1.1919v1.2651
				h-1.1919V1014.9194z"
          />
          <path d="M1052.6445,1028.9194h1.1919v1.2651h-1.1919V1028.9194z M1053.7881,1037.6514h-1.0947v-6.3604h1.0947V1037.6514z" />
          <path
            d="M1055.248,1031.291h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1031.291z"
          />
          <path
            d="M1063.5078,1034.4771c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1064.5176,1037.7852,1063.5078,1036.2773,1063.5078,1034.4771z
				 M1066.4751,1032.1304c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1068.2754,1032.7749,1067.3755,1032.1304,1066.4751,1032.1304z"
          />
          <path
            d="M1070.356,1034.4771c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1071.5723,1037.7852,1070.356,1036.4351,1070.356,1034.4771z M1071.4629,1033.8086
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1072.3506,1032.106,1071.6572,1032.7261,1071.4629,1033.8086z"
          />
          <path d="M1080.2949,1037.6514h-1.0947v-8.7319h1.0947V1037.6514z" />
          <path
            d="M1081.2559,1035.9365c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1082.1924,1037.7607,1081.2559,1037.0796,1081.2559,1035.9365z M1085.208,1034.3921l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1034.3921z"
          />
          <path
            d="M1087.7881,1031.291h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1031.291z"
          />
          <path
            d="M1093.8457,1034.4771c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1094.8555,1037.7852,1093.8457,1036.2773,1093.8457,1034.4771z
				 M1096.813,1032.1304c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1098.6133,1032.7749,1097.7134,1032.1304,1096.813,1032.1304z"
          />
          <path
            d="M1102.2271,1036.7271v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C1103.3091,1037.7852,1102.604,1037.3594,1102.2271,1036.7271z M1102.2026,1034.7085c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1034.7085z"
          />
          <path
            d="M1110.5107,1037.7852c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1113.4658,1036.4473,1112.2495,1037.7852,1110.5107,1037.7852z M1110.5107,1036.8486
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1108.6621,1035.9243,1109.3916,1036.8486,1110.5107,1036.8486z"
          />
          <path
            d="M1119.5356,1037.6514h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093
				c-1.3618,0-2.1284-0.9365-2.1284-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083
				c0.7905,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1037.6514z"
          />
          <path
            d="M1124.937,1031.291l1.3135,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H1124.937z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="50">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3909.5271,890.504c0,25.269-20.4841,45.75-45.75,45.75c-25.269,0-45.75-20.481-45.75-45.75
			c0-25.266,20.481-45.7499,45.75-45.7499C3889.043,844.7542,3909.5271,865.238,3909.5271,890.504"
        />
        <g>
          <path
            d="M3834.0264,879.229c-0.3525,0.8027-1.2891,1.4351-2.542,1.4351c-2.2256,0-3.8789-1.8242-3.8789-4.4756
				c0-2.6631,1.666-4.5239,3.9521-4.5239c1.6787,0,2.8701,0.9121,3.3926,2.4204l-1.1182,0.3647
				c-0.3164-1.1069-1.1191-1.7515-2.2627-1.7515c-1.6533,0-2.7969,1.4106-2.7969,3.4785c0,2.0552,1.1436,3.478,2.8096,3.478
				c1.4111,0,2.3594-1.0825,2.3838-2.6392h-2.5059v-1.0215h3.6367v4.5361h-1.0703V879.229z"
          />
          <path
            d="M3836.0234,877.356c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3837.2393,880.6641,3836.0234,879.314,3836.0234,877.356z M3837.1299,876.6875
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3838.0176,874.9849,3837.3242,875.605,3837.1299,876.6875z"
          />
          <path
            d="M3843.7607,879.606v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C3844.8428,880.6641,3844.1377,880.2383,3843.7607,879.606z M3843.7363,877.5874c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V877.5874z"
          />
          <path
            d="M3849.0889,877.356c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3850.3047,880.6641,3849.0889,879.314,3849.0889,877.356z M3850.1953,876.6875
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3851.083,874.9849,3850.3896,875.605,3850.1953,876.6875z"
          />
          <path
            d="M3859.0264,875.2524c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V875.2524z"
          />
          <path
            d="M3862.4336,879.7031c0.875,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3047,1.0581-1.2285,1.8608-2.5537,1.8608
				c-1.7764,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7661,2.5293,1.8242
				l-0.9482,0.4741c-0.1826-0.7783-0.7061-1.3496-1.5811-1.3496c-1.1318,0-1.9102,0.8999-1.9102,2.3589
				C3860.5234,878.8032,3861.3018,879.7031,3862.4336,879.7031z"
          />
          <path d="M3865.7783,871.7983h1.1924v1.2651h-1.1924V871.7983z M3866.9219,880.5303h-1.0947v-6.3604h1.0947V880.5303z" />
          <path
            d="M3869.4756,879.606v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C3870.5576,880.6641,3869.8525,880.2383,3869.4756,879.606z M3869.4512,877.5874c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V877.5874z"
          />
          <path d="M3875.1924,871.7983h1.1924v1.2651h-1.1924V871.7983z M3876.3359,880.5303h-1.0947v-6.3604h1.0947V880.5303z" />
          <path
            d="M3877.3584,877.356c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3878.5742,880.6641,3877.3584,879.314,3877.3584,877.356z M3878.4648,876.6875
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3879.3525,874.9849,3878.6592,875.605,3878.4648,876.6875z"
          />
          <path
            d="M3883.5625,877.356c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3884.7783,880.6641,3883.5625,879.314,3883.5625,877.356z M3884.6689,876.6875
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3885.5566,874.9849,3884.8633,875.605,3884.6689,876.6875z"
          />
          <path
            d="M3893.5,875.2524c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V875.2524z"
          />
          <path
            d="M3893.9033,877.356c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3894.9121,880.6641,3893.9033,879.1562,3893.9033,877.356z
				 M3896.8701,875.0093c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3898.6699,875.6538,3897.7705,875.0093,3896.8701,875.0093z"
          />
          <path
            d="M3837.5684,888.1699h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V888.1699z"
          />
          <path d="M3844.0146,885.7983h1.1924v1.2651h-1.1924V885.7983z M3845.1582,894.5303h-1.0947v-6.3604h1.0947V894.5303z" />
          <path d="M3848.248,894.5303l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3848.248z" />
          <path
            d="M3851.9824,891.356c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3853.1982,894.6641,3851.9824,893.314,3851.9824,891.356z M3853.0889,890.6875
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3853.9766,888.9849,3853.2832,889.605,3853.0889,890.6875z"
          />
          <path
            d="M3858.125,892.8154c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3859.0615,894.6396,3858.125,893.9585,3858.125,892.8154z M3862.0781,891.271l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V891.271z"
          />
          <path
            d="M3869.7051,894.5303h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V894.5303z"
          />
          <path d="M3874.9844,894.5303l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3874.9844z" />
          <path
            d="M3878.6572,892.8154c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3879.5938,894.6396,3878.6572,893.9585,3878.6572,892.8154z M3882.6104,891.271l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V891.271z"
          />
          <path
            d="M3885.1904,888.1699h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V888.1699z"
          />
          <path d="M3822.1191,908.5303l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3822.1191z" />
          <path
            d="M3828.8086,908.6641c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3831.7637,907.3262,3830.5479,908.6641,3828.8086,908.6641z M3828.8086,907.7275
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3826.96,906.8032,3827.6895,907.7275,3828.8086,907.7275z"
          />
          <path
            d="M3832.3496,905.356c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3833.5654,908.6641,3832.3496,907.314,3832.3496,905.356z M3833.4561,904.6875
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3834.3438,902.9849,3833.6504,903.605,3833.4561,904.6875z"
          />
          <path
            d="M3838.5537,905.356c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3839.5625,908.6641,3838.5537,907.1562,3838.5537,905.356z
				 M3841.5205,903.0093c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3843.3203,903.6538,3842.4209,903.0093,3841.5205,903.0093z"
          />
          <path
            d="M3845.9854,906.5601c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L3845.9854,906.5601z"
          />
          <path
            d="M3850.9502,905.356c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3852.166,908.6641,3850.9502,907.314,3850.9502,905.356z M3852.0566,904.6875
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3852.9443,902.9849,3852.251,903.605,3852.0566,904.6875z"
          />
          <path d="M3858.6865,908.5303h-1.0947v-8.7319h1.0947V908.5303z" />
          <path d="M3861.7754,908.5303l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3861.7754z" />
          <path
            d="M3865.5098,905.356c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3866.7256,908.6641,3865.5098,907.314,3865.5098,905.356z M3866.6162,904.6875
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3867.5039,902.9849,3866.8105,903.605,3866.6162,904.6875z"
          />
          <path d="M3872.1035,899.7983h1.1924v1.2651h-1.1924V899.7983z M3873.2471,908.5303h-1.0947v-6.3604h1.0947V908.5303z" />
          <path d="M3875.8008,908.5303h-1.0947v-8.7319h1.0947V908.5303z" />
          <path d="M3877.2109,899.7983h1.1924v1.2651h-1.1924V899.7983z M3878.3545,908.5303h-1.0947v-6.3604h1.0947V908.5303z" />
          <path
            d="M3882.2471,910.853c-1.3984,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C3885.2021,909.8193,3883.9736,910.853,3882.2471,910.853z M3882.3564,903.0093
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C3884.1191,903.6899,3883.2686,903.0093,3882.3564,903.0093z"
          />
          <path
            d="M3886.6631,899.7983h1.0947v3.2349c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V899.7983z"
          />
          <path
            d="M3892.7217,905.356c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3893.9375,908.6641,3892.7217,907.314,3892.7217,905.356z M3893.8281,904.6875
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3894.7158,902.9849,3894.0225,903.605,3893.8281,904.6875z"
          />
          <path d="M3899.3154,899.7983h1.1924v1.2651h-1.1924V899.7983z M3900.459,908.5303h-1.0947v-6.3604h1.0947V908.5303z" />
          <path
            d="M3901.4805,905.356c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3902.4893,908.6641,3901.4805,907.1562,3901.4805,905.356z
				 M3904.4473,903.0093c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3906.2471,903.6538,3905.3477,903.0093,3904.4473,903.0093z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="49">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3764.594,889.52c0,25.265-20.481,45.75-45.75,45.75c-25.2661,0-45.75-20.485-45.75-45.75
			c0-25.2659,20.4839-45.75,45.75-45.75C3744.113,843.77,3764.594,864.2542,3764.594,889.52"
        />
        <g>
          <path
            d="M3686.5098,870.8145h3.2227c1.8486,0,2.833,0.8149,2.833,2.2744c0,1.1187-0.6807,1.6782-1.3496,1.9092
				c0.9854,0.2676,1.6416,1.0337,1.6416,2.1406c0,1.52-1.1064,2.4077-2.8701,2.4077h-3.4775V870.8145z M3689.6348,874.5601
				c1.168,0,1.8125-0.4497,1.8125-1.374c0-0.9365-0.6445-1.3862-1.8125-1.3862h-1.9941v2.7603H3689.6348z M3687.6406,878.5615
				h2.2988c1.167,0,1.7998-0.6084,1.7998-1.5083c0-0.8877-0.6328-1.4956-1.7998-1.4956h-2.2988V878.5615z"
          />
          <path
            d="M3693.5537,876.3721c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3694.7695,879.6802,3693.5537,878.3301,3693.5537,876.3721z M3694.6602,875.7036
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3695.5479,874.001,3694.8545,874.6211,3694.6602,875.7036z"
          />
          <path
            d="M3700.4629,877.5762c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L3700.4629,877.5762z"
          />
          <path
            d="M3706.1562,874.1226h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V874.1226z"
          />
          <path
            d="M3709.4404,876.3721c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3710.6562,879.6802,3709.4404,878.3301,3709.4404,876.3721z M3710.5469,875.7036
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3711.4346,874.001,3710.7412,874.6211,3710.5469,875.7036z"
          />
          <path
            d="M3715.7666,876.3721c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3716.9824,879.6802,3715.7666,878.3301,3715.7666,876.3721z M3716.873,875.7036
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3717.7607,874.001,3717.0674,874.6211,3716.873,875.7036z"
          />
          <path
            d="M3722.0928,876.3721c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3723.1016,879.6802,3722.0928,878.1724,3722.0928,876.3721z
				 M3725.0596,874.0254c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3726.8594,874.6699,3725.96,874.0254,3725.0596,874.0254z"
          />
          <path
            d="M3730.5957,878.6221v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C3731.6777,879.6802,3730.9727,879.2544,3730.5957,878.6221z M3730.5713,876.6035c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V876.6035z"
          />
          <path
            d="M3735.9844,877.8315c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3736.9209,879.6558,3735.9844,878.9746,3735.9844,877.8315z M3739.9375,876.2871l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V876.2871z"
          />
          <path
            d="M3742.1396,877.8315c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3743.0762,879.6558,3742.1396,878.9746,3742.1396,877.8315z M3746.0928,876.2871l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V876.2871z"
          />
          <path
            d="M3752.0898,874.2686c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V874.2686z"
          />
          <path d="M3696.9766,884.8145h1.1924v1.2651h-1.1924V884.8145z M3698.1201,893.5464h-1.0947v-6.3604h1.0947V893.5464z" />
          <path
            d="M3699.7012,887.186h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V887.186z"
          />
          <path
            d="M3707.4141,890.1533l2.8213-2.9673h1.3623l-2.4814,2.5054l2.6875,3.855h-1.2764l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V890.1533z"
          />
          <path
            d="M3715.0898,893.6802c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3718.0449,892.3423,3716.8291,893.6802,3715.0898,893.6802z M3715.0898,892.7437
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3713.2412,891.8193,3713.9707,892.7437,3715.0898,892.7437z"
          />
          <path
            d="M3720.2842,893.5464h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0312-1.0825c1.3125,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V893.5464z"
          />
          <path
            d="M3729.1045,890.3721c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3730.3203,893.6802,3729.1045,892.3301,3729.1045,890.3721z M3730.2109,889.7036
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3731.0986,888.001,3730.4053,888.6211,3730.2109,889.7036z"
          />
          <path
            d="M3735.8682,887.186h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V887.186z"
          />
          <path d="M3687.4648,907.5464l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3687.4648z" />
          <path
            d="M3694.2764,907.6802c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3697.2314,906.3423,3696.0156,907.6802,3694.2764,907.6802z M3694.2764,906.7437
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3692.4277,905.8193,3693.1572,906.7437,3694.2764,906.7437z"
          />
          <path
            d="M3700.8936,907.6802c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3703.8486,906.3423,3702.6328,907.6802,3700.8936,907.6802z M3700.8936,906.7437
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3699.0449,905.8193,3699.7744,906.7437,3700.8936,906.7437z"
          />
          <path
            d="M3708.29,902.2686c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V902.2686z"
          />
          <path d="M3713.2041,907.5464l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3713.2041z" />
          <path
            d="M3720.0156,907.6802c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3722.9707,906.3423,3721.7549,907.6802,3720.0156,907.6802z M3720.0156,906.7437
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3718.167,905.8193,3718.8965,906.7437,3720.0156,906.7437z"
          />
          <path
            d="M3723.6787,904.3721c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3724.8945,907.6802,3723.6787,906.3301,3723.6787,904.3721z M3724.7852,903.7036
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3725.6729,902.001,3724.9795,902.6211,3724.7852,903.7036z"
          />
          <path
            d="M3730.0049,904.3721c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3731.0137,907.6802,3730.0049,906.1724,3730.0049,904.3721z
				 M3732.9717,902.0254c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3734.7715,902.6699,3733.8721,902.0254,3732.9717,902.0254z"
          />
          <path d="M3737.3643,898.8145h1.1924v1.2651h-1.1924V898.8145z M3738.5078,907.5464h-1.0947v-6.3604h1.0947V907.5464z" />
          <path
            d="M3740.0889,901.186h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V901.186z"
          />
          <path
            d="M3749.1387,909.8691c-1.3994,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6201-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C3752.0938,908.8354,3750.8652,909.8691,3749.1387,909.8691z M3749.248,902.0254
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C3751.0107,902.7061,3750.1602,902.0254,3749.248,902.0254z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="48">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3473.7729,1798.434c0,25.265-20.48,45.75-45.75,45.75c-25.2649,0-45.75-20.485-45.75-45.75
			c0-25.27,20.4851-45.75,45.75-45.75C3453.293,1752.684,3473.7729,1773.1639,3473.7729,1798.434"
        />
        <g>
          <path
            d="M3408.9727,1788.4609l-2.6631-7.1875v7.1875h-1.0947v-8.7314h1.5928l2.6514,7.1748l2.6631-7.1748h1.5449v8.7314h-1.083
				v-7.1875l-2.6748,7.1875H3408.9727z"
          />
          <path
            d="M3414.8125,1786.7461c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3415.749,1788.5703,3414.8125,1787.8896,3414.8125,1786.7461z M3418.7656,1785.2021l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V1785.2021z"
          />
          <path
            d="M3421.6367,1783.0371h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V1783.0371z"
          />
          <path
            d="M3424.9219,1785.2871c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3426.1377,1788.5947,3424.9219,1787.2451,3424.9219,1785.2871z
				 M3426.0283,1784.6182h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C3426.916,1782.915,3426.2227,1783.5352,3426.0283,1784.6182z"
          />
          <path d="M3435.6377,1788.4609l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3435.6377z" />
          <path
            d="M3439.4326,1786.7461c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3440.3691,1788.5703,3439.4326,1787.8896,3439.4326,1786.7461z M3443.3857,1785.2021l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V1785.2021z"
          />
          <path
            d="M3446.0869,1782.1006h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1782.1006z"
          />
          <path d="M3392.5205,1794.1309l-0.207,2.918h-0.6572l-0.1943-2.918H3392.5205z" />
          <path
            d="M3394.8926,1801.5371v3.1133h-1.0947v-8.5498h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4961,2.7725,3.3086c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C3395.9746,1802.5947,3395.2695,1802.1689,3394.8926,1801.5371z M3394.8682,1799.5176c0,1.46,0.8994,2.1162,1.7998,2.1162
				c1.1914,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6689-2.3477-1.8604-2.3477c-0.9004,0-1.7998,0.6445-1.7998,2.1289V1799.5176z"
          />
          <path
            d="M3404.0762,1797.1826c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V1797.1826z"
          />
          <path
            d="M3407.5557,1802.5947c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C3410.5107,1801.2568,3409.2949,1802.5947,3407.5557,1802.5947z M3407.5557,1801.6582
				c1.1182,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C3405.707,1800.7344,3406.4365,1801.6582,3407.5557,1801.6582z"
          />
          <path
            d="M3411.8018,1800.4912c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L3411.8018,1800.4912z"
          />
          <path
            d="M3422.3711,1802.4609h-1.0947v-0.876c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3887,1.5078,1.3252,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V1802.4609z"
          />
          <path
            d="M3425.0371,1802.4609h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0332-1.083,2.0312-1.083c1.3135,0,2.1035,0.9365,2.1035,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V1802.4609z"
          />
          <path
            d="M3433.8574,1799.2871c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3435.0732,1802.5947,3433.8574,1801.2451,3433.8574,1799.2871z
				 M3434.9639,1798.6182h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C3435.8516,1796.915,3435.1582,1797.5352,3434.9639,1798.6182z"
          />
          <path
            d="M3440.6211,1796.1006h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1796.1006z"
          />
          <path
            d="M3447.4082,1797.0371h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V1797.0371z"
          />
          <path
            d="M3450.6934,1799.2871c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3451.9092,1802.5947,3450.6934,1801.2451,3450.6934,1799.2871z
				 M3451.7998,1798.6182h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C3452.6875,1796.915,3451.9941,1797.5352,3451.7998,1798.6182z"
          />
          <path
            d="M3457.457,1796.1006h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1796.1006z"
          />
          <path d="M3464.8389,1794.1309l-0.207,2.918h-0.6572l-0.1943-2.918H3464.8389z" />
          <path d="M3410.7305,1812.4238h3.3203v0.9844h-3.3203V1812.4238z" />
          <path
            d="M3415.4863,1814.4912c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L3415.4863,1814.4912z"
          />
          <path
            d="M3423.5762,1815.6338c0.875,0,1.3984-0.5596,1.5811-1.3496l0.9727,0.4502c-0.3047,1.0576-1.2285,1.8604-2.5537,1.8604
				c-1.7764,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2275-3.3086,3.0039-3.3086c1.3252,0,2.2256,0.7666,2.5293,1.8242
				l-0.9482,0.4746c-0.1826-0.7783-0.7061-1.3496-1.5811-1.3496c-1.1318,0-1.9102,0.8994-1.9102,2.3594
				C3421.666,1814.7344,3422.4443,1815.6338,3423.5762,1815.6338z"
          />
          <path
            d="M3427.0918,1807.7295h1.0947v3.2344c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1807.7295z"
          />
          <path
            d="M3433.21,1814.7461c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3434.1465,1816.5703,3433.21,1815.8896,3433.21,1814.7461z M3437.1631,1813.2021l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V1813.2021z"
          />
          <path
            d="M3440.959,1815.5371v3.1133h-1.0947v-8.5498h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4961,2.7725,3.3086c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C3442.041,1816.5947,3441.3359,1816.1689,3440.959,1815.5371z M3440.9346,1813.5176c0,1.46,0.8994,2.1162,1.7998,2.1162
				c1.1914,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6689-2.3477-1.8604-2.3477c-0.9004,0-1.7998,0.6445-1.7998,2.1289V1813.5176z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="47">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M2969.5391,1670.2581c0,25.2649-20.48,45.75-45.75,45.75c-25.2661,0-45.75-20.4851-45.75-45.75
			c0-25.27,20.4839-45.75,45.75-45.75C2949.0591,1624.5081,2969.5391,1644.988,2969.5391,1670.2581"
        />
        <g>
          <path d="M2901.7822,1667.2842h-1.0098l-4.7676-7.0293v7.0293h-1.1182v-8.7314h1.2285l4.5361,6.749v-6.749h1.1309V1667.2842z" />
          <path d="M2903.6084,1658.5527h1.1924v1.2646h-1.1924V1658.5527z M2904.752,1667.2842h-1.0947v-6.3604h1.0947V1667.2842z" />
          <path d="M2908.2061,1667.2842l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2908.2061z" />
          <path
            d="M2912.3057,1664.1104c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2913.5215,1667.418,2912.3057,1666.0684,2912.3057,1664.1104z M2913.4121,1663.4414
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2914.2998,1661.7383,2913.6064,1662.3584,2913.4121,1663.4414z"
          />
          <path
            d="M2918.8135,1665.5693c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2919.75,1667.3936,2918.8135,1666.7129,2918.8135,1665.5693z M2922.7666,1664.0254l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V1664.0254z"
          />
          <path
            d="M2930.7578,1667.2842h-1.0947v-0.876c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3887,1.5078,1.3252,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V1667.2842z"
          />
          <path d="M2936.7676,1667.2842l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2936.7676z" />
          <path
            d="M2940.8057,1665.5693c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2941.7422,1667.3936,2940.8057,1666.7129,2940.8057,1665.5693z M2944.7588,1664.0254l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V1664.0254z"
          />
          <path
            d="M2947.7031,1660.9238h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1660.9238z"
          />
          <path d="M2876.9443,1681.2842l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2876.9443z" />
          <path
            d="M2882.7822,1681.418c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C2885.7373,1680.0801,2884.5215,1681.418,2882.7822,1681.418z M2882.7822,1680.4814
				c1.1182,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C2880.9336,1679.5576,2881.6631,1680.4814,2882.7822,1680.4814z"
          />
          <path
            d="M2885.4727,1678.1104c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2886.6885,1681.418,2885.4727,1680.0684,2885.4727,1678.1104z M2886.5791,1677.4414
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2887.4668,1675.7383,2886.7734,1676.3584,2886.5791,1677.4414z"
          />
          <path
            d="M2890.8252,1678.1104c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2891.834,1681.418,2890.8252,1679.9102,2890.8252,1678.1104z
				 M2893.792,1675.7627c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2895.5918,1676.4072,2894.6924,1675.7627,2893.792,1675.7627z"
          />
          <path d="M2897.2109,1672.5527h1.1924v1.2646h-1.1924V1672.5527z M2898.3545,1681.2842h-1.0947v-6.3604h1.0947V1681.2842z" />
          <path
            d="M2898.9629,1674.9238h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1674.9238z"
          />
          <path
            d="M2907.0391,1683.6074c-1.3994,0-2.4209-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5723,1.8604-1.8975v-0.7178c-0.3408,0.6201-1.0576,1.0586-1.9463,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1748c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.4385,1.9463,1.0586v-0.9365
				h1.0947v5.7891C2909.9941,1682.5732,2908.7656,1683.6074,2907.0391,1683.6074z M2907.1484,1675.7627
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012s0.7061,2.2139,1.8613,2.2139c0.9121,0,1.7627-0.6816,1.7627-2.0801v-0.2305
				C2908.9111,1676.4443,2908.0605,1675.7627,2907.1484,1675.7627z"
          />
          <path
            d="M2910.749,1679.3145c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L2910.749,1679.3145z"
          />
          <path
            d="M2917.7324,1683.6074c-1.3994,0-2.4209-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5723,1.8604-1.8975v-0.7178c-0.3408,0.6201-1.0576,1.0586-1.9463,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1748c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.4385,1.9463,1.0586v-0.9365
				h1.0947v5.7891C2920.6875,1682.5732,2919.459,1683.6074,2917.7324,1683.6074z M2917.8418,1675.7627
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012s0.7061,2.2139,1.8613,2.2139c0.9121,0,1.7627-0.6816,1.7627-2.0801v-0.2305
				C2919.6045,1676.4443,2918.7539,1675.7627,2917.8418,1675.7627z"
          />
          <path
            d="M2920.8594,1678.1104c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2922.0752,1681.418,2920.8594,1680.0684,2920.8594,1678.1104z M2921.9658,1677.4414
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2922.8535,1675.7383,2922.1602,1676.3584,2921.9658,1677.4414z"
          />
          <path d="M2927.7441,1681.2842h-1.0947v-8.7314h1.0947V1681.2842z" />
          <path
            d="M2927.915,1678.1104c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2929.1309,1681.418,2927.915,1680.0684,2927.915,1678.1104z M2929.0215,1677.4414
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2929.9092,1675.7383,2929.2158,1676.3584,2929.0215,1677.4414z"
          />
          <path
            d="M2933.875,1675.8604h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V1675.8604z"
          />
          <path
            d="M2936.7939,1675.8604h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V1675.8604z"
          />
          <path
            d="M2939.1064,1678.1104c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2940.3223,1681.418,2939.1064,1680.0684,2939.1064,1678.1104z M2940.2129,1677.4414
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2941.1006,1675.7383,2940.4072,1676.3584,2940.2129,1677.4414z"
          />
          <path
            d="M2948.1924,1676.0059c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V1676.0059z"
          />
          <path
            d="M2947.7432,1678.1104c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2948.752,1681.418,2947.7432,1679.9102,2947.7432,1678.1104z
				 M2950.71,1675.7627c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2952.5098,1676.4072,2951.6104,1675.7627,2950.71,1675.7627z"
          />
          <path
            d="M2954.1787,1672.5527h1.0947v3.2344c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1672.5527z"
          />
          <path
            d="M2959.3857,1678.1104c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2960.6016,1681.418,2959.3857,1680.0684,2959.3857,1678.1104z M2960.4922,1677.4414
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2961.3799,1675.7383,2960.6865,1676.3584,2960.4922,1677.4414z"
          />
          <path d="M2965.127,1672.5527h1.1924v1.2646h-1.1924V1672.5527z M2966.2705,1681.2842h-1.0947v-6.3604h1.0947V1681.2842z" />
          <path
            d="M2966.4414,1678.1104c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2967.4502,1681.418,2966.4414,1679.9102,2966.4414,1678.1104z
				 M2969.4082,1675.7627c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2971.208,1676.4072,2970.3086,1675.7627,2969.4082,1675.7627z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="46">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3414.0859,1222.504c0,25.266-20.4839,45.75-45.75,45.75c-25.27,0-45.75-20.484-45.75-45.75
			s20.48-45.75,45.75-45.75C3393.6021,1176.754,3414.0859,1197.238,3414.0859,1222.504"
        />
        <g>
          <path d="M3344.5586,1211.8198v7.7104h-1.167v-7.7104h-2.9551v-1.0215h7.0771v1.0215H3344.5586z" />
          <path
            d="M3351.9307,1214.2524c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1214.2524z"
          />
          <path
            d="M3355.6533,1219.6641c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3358.6084,1218.3262,3357.3926,1219.6641,3355.6533,1219.6641z M3355.6533,1218.7275
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3353.8047,1217.8032,3354.5342,1218.7275,3355.6533,1218.7275z"
          />
          <path
            d="M3365.0439,1219.5303h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093
				c-1.3623,0-2.1279-0.9365-2.1279-2.2861v-4.208h1.0938v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083
				c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1219.5303z"
          />
          <path
            d="M3370.8105,1213.1699l1.3135,4.7427l1.3262-4.7427h1.0938l-1.9336,6.3604h-0.96l-1.3262-4.7188l-1.3252,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H3370.8105z"
          />
          <path
            d="M3377.6846,1217.8154c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3378.6211,1219.6396,3377.6846,1218.9585,3377.6846,1217.8154z M3381.6377,1216.271l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1216.271z"
          />
          <path
            d="M3384.083,1217.8154c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3385.0195,1219.6396,3384.083,1218.9585,3384.083,1217.8154z M3388.0361,1216.271l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1216.271z"
          />
          <path
            d="M3390.9805,1213.1699h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1213.1699z"
          />
          <path
            d="M3330.0723,1230.356c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3331.2881,1233.6641,3330.0723,1232.314,3330.0723,1230.356z M3331.1787,1229.6875
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3332.0664,1227.9849,3331.373,1228.605,3331.1787,1229.6875z"
          />
          <path
            d="M3336.6416,1230.356c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3337.8574,1233.6641,3336.6416,1232.314,3336.6416,1230.356z M3337.748,1229.6875
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3338.6357,1227.9849,3337.9424,1228.605,3337.748,1229.6875z"
          />
          <path
            d="M3343.8184,1228.1064h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1228.1064z"
          />
          <path
            d="M3350.2168,1235.853c-1.3994,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C3353.1719,1234.8193,3351.9434,1235.853,3350.2168,1235.853z M3350.3262,1228.0093
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C3352.0889,1228.6899,3351.2383,1228.0093,3350.3262,1228.0093z"
          />
          <path
            d="M3354.5605,1230.356c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3355.7764,1233.6641,3354.5605,1232.314,3354.5605,1230.356z M3355.667,1229.6875
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3356.5547,1227.9849,3355.8613,1228.605,3355.667,1229.6875z"
          />
          <path
            d="M3365.5195,1227.1699l1.3135,4.7427l1.3262-4.7427h1.0938l-1.9336,6.3604h-0.96l-1.3262-4.7188l-1.3252,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H3365.5195z"
          />
          <path
            d="M3372.8438,1233.6641c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3375.7988,1232.3262,3374.583,1233.6641,3372.8438,1233.6641z M3372.8438,1232.7275
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3370.9951,1231.8032,3371.7246,1232.7275,3372.8438,1232.7275z"
          />
          <path
            d="M3379.7041,1233.6641c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3382.6592,1232.3262,3381.4434,1233.6641,3379.7041,1233.6641z M3379.7041,1232.7275
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3377.8555,1231.8032,3378.585,1232.7275,3379.7041,1232.7275z"
          />
          <path
            d="M3384.0479,1227.1699h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1227.1699z"
          />
          <path
            d="M3391.0791,1228.1064h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1228.1064z"
          />
          <path
            d="M3394.6074,1230.356c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3395.8232,1233.6641,3394.6074,1232.314,3394.6074,1230.356z M3395.7139,1229.6875
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3396.6016,1227.9849,3395.9082,1228.605,3395.7139,1229.6875z"
          />
          <path
            d="M3401.6143,1227.1699h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1227.1699z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="45">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M1865.391,330.75c0,25.2661-20.485,45.75-45.75,45.75c-25.266,0-45.75-20.4839-45.75-45.75
			c0-25.2659,20.484-45.75,45.75-45.75C1844.906,285,1865.391,305.4841,1865.391,330.75"
        />
        <g>
          <path
            d="M1791.6187,305.8765c1.1313,0,1.9458-0.6567,2.2622-1.7632l1.1064,0.3647c-0.5107,1.5078-1.7026,2.4321-3.3687,2.4321
				c-2.2617,0-3.9277-1.8364-3.9277-4.4995c0-2.6636,1.666-4.5,3.9277-4.5c1.666,0,2.8579,0.9243,3.3687,2.4326l-1.1064,0.3647
				c-0.3164-1.1069-1.1309-1.7637-2.2622-1.7637c-1.6172,0-2.7603,1.3989-2.7603,3.4663S1790.0015,305.8765,1791.6187,305.8765z"
          />
          <path
            d="M1798.2002,306.9102c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1801.1553,305.5723,1799.939,306.9102,1798.2002,306.9102z M1798.2002,305.9736
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1796.3516,305.0493,1797.0811,305.9736,1798.2002,305.9736z"
          />
          <path
            d="M1802.0571,300.416h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V300.416z"
          />
          <path
            d="M1810.9971,305.9492c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9731,0.4497c-0.3042,1.0581-1.2285,1.8608-2.5542,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1809.0879,305.0493,1809.8662,305.9492,1810.9971,305.9492z"
          />
          <path
            d="M1813.8325,303.6021c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1815.0488,306.9102,1813.8325,305.5601,1813.8325,303.6021z M1814.9395,302.9336
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1815.8271,301.231,1815.1338,301.8511,1814.9395,302.9336z"
          />
          <path
            d="M1820.3525,300.416h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V300.416z"
          />
          <path
            d="M1826.8975,301.3525h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V301.3525z"
          />
          <path
            d="M1833.6719,301.4985c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V301.4985z"
          />
          <path
            d="M1833.8921,305.0615c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1834.8286,306.8857,1833.8921,306.2046,1833.8921,305.0615z M1837.8442,303.5171l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V303.5171z"
          />
          <path
            d="M1840.4727,301.3525h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V301.3525z"
          />
          <path d="M1843.9028,298.0444h1.1919v1.2651h-1.1919V298.0444z M1845.0464,306.7764h-1.0947v-6.3604h1.0947V306.7764z" />
          <path
            d="M1845.9463,303.6021c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1847.1626,306.9102,1845.9463,305.5601,1845.9463,303.6021z M1847.0532,302.9336
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1847.9409,301.231,1847.2476,301.8511,1847.0532,302.9336z"
          />
          <path d="M1784.4424,320.7764l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1784.4424z" />
          <path
            d="M1787.9951,319.0615c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1788.9316,320.8857,1787.9951,320.2046,1787.9951,319.0615z M1791.9473,317.5171l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V317.5171z"
          />
          <path
            d="M1794.4058,314.416h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V314.416z"
          />
          <path
            d="M1806.813,314.416l1.313,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H1806.813z"
          />
          <path d="M1811.083,312.0444h1.1919v1.2651h-1.1919V312.0444z M1812.2266,320.7764h-1.0947v-6.3604h1.0947V320.7764z" />
          <path
            d="M1813.5649,314.416h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V314.416z"
          />
          <path
            d="M1821.0337,317.3833l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V317.3833z"
          />
          <path
            d="M1825.5107,317.6021c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1826.7271,320.9102,1825.5107,319.5601,1825.5107,317.6021z M1826.6177,316.9336
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1827.5054,315.231,1826.812,315.8511,1826.6177,316.9336z"
          />
          <path d="M1833.1255,320.7764h-1.0947v-8.7319h1.0947V320.7764z" />
          <path
            d="M1834.0259,317.6021c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1835.2422,320.9102,1834.0259,319.5601,1834.0259,317.6021z M1835.1328,316.9336
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1836.0205,315.231,1835.3271,315.8511,1835.1328,316.9336z"
          />
          <path
            d="M1840.5459,314.416h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V314.416z"
          />
          <path d="M1848.9517,312.0444h1.1919v1.2651h-1.1919V312.0444z M1850.0952,320.7764h-1.0947v-6.3604h1.0947V320.7764z" />
          <path
            d="M1851.4331,314.416h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V314.416z"
          />
          <path
            d="M1775.2524,334.7764h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V334.7764z"
          />
          <path
            d="M1783.8281,331.6021c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1785.0444,334.9102,1783.8281,333.5601,1783.8281,331.6021z M1784.9351,330.9336
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1785.8228,329.231,1785.1294,329.8511,1784.9351,330.9336z"
          />
          <path
            d="M1792.7808,337.0991c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3403,0.6206-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.6055,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C1795.7358,336.0654,1794.5078,337.0991,1792.7808,337.0991z M1792.8901,329.2554
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C1794.6538,329.936,1793.8022,329.2554,1792.8901,329.2554z"
          />
          <path
            d="M1796.5767,333.0615c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1797.5132,334.8857,1796.5767,334.2046,1796.5767,333.0615z M1800.5288,331.5171l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V331.5171z"
          />
          <path
            d="M1806.9399,328.416l1.313,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H1806.9399z"
          />
          <path d="M1811.21,326.0444h1.1919v1.2651h-1.1919V326.0444z M1812.3535,334.7764h-1.0947v-6.3604h1.0947V334.7764z" />
          <path
            d="M1813.6919,328.416h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V328.416z"
          />
          <path
            d="M1821.1606,331.3833l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V331.3833z"
          />
          <path
            d="M1825.6377,331.6021c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1826.854,334.9102,1825.6377,333.5601,1825.6377,331.6021z M1826.7446,330.9336
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1827.6323,329.231,1826.939,329.8511,1826.7446,330.9336z"
          />
          <path d="M1833.2524,334.7764h-1.0947v-8.7319h1.0947V334.7764z" />
          <path
            d="M1837.1567,333.9492c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3037,1.0581-1.228,1.8608-2.5537,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1835.2476,333.0493,1836.0259,333.9492,1837.1567,333.9492z"
          />
          <path
            d="M1839.9917,331.6021c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1841.208,334.9102,1839.9917,333.5601,1839.9917,331.6021z M1841.0986,330.9336
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1841.9863,329.231,1841.293,329.8511,1841.0986,330.9336z"
          />
          <path
            d="M1846.5122,328.416h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V328.416z"
          />
          <path
            d="M1853.0566,329.3525h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V329.3525z"
          />
          <path
            d="M1859.8311,329.4985c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V329.4985z"
          />
          <path
            d="M1860.0513,333.0615c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1860.9878,334.8857,1860.0513,334.2046,1860.0513,333.0615z M1864.0034,331.5171l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V331.5171z"
          />
          <path d="M1783.6948,340.0444h1.1919v1.2651h-1.1919V340.0444z M1784.8384,348.7764h-1.0947v-6.3604h1.0947V348.7764z" />
          <path
            d="M1786.1763,342.416h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V342.416z"
          />
          <path
            d="M1794.1929,345.6021c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1795.2026,348.9102,1794.1929,347.4023,1794.1929,345.6021z
				 M1797.1602,343.2554c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1798.9604,343.8999,1798.0605,343.2554,1797.1602,343.2554z"
          />
          <path
            d="M1800.9199,345.6021c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1802.1362,348.9102,1800.9199,347.5601,1800.9199,345.6021z M1802.0269,344.9336
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1802.9146,343.231,1802.2212,343.8511,1802.0269,344.9336z"
          />
          <path
            d="M1809.6665,346.8062c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9243-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1809.6665,346.8062z"
          />
          <path
            d="M1815.1162,343.3525h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V343.3525z"
          />
          <path
            d="M1818.1572,345.6021c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1819.3735,348.9102,1818.1572,347.5601,1818.1572,345.6021z M1819.2642,344.9336
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1820.1519,343.231,1819.4585,343.8511,1819.2642,344.9336z"
          />
          <path
            d="M1824.2393,345.6021c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1825.249,348.9102,1824.2393,347.4023,1824.2393,345.6021z
				 M1827.2065,343.2554c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1829.0068,343.8999,1828.1069,343.2554,1827.2065,343.2554z"
          />
          <path
            d="M1830.9663,345.6021c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1832.1826,348.9102,1830.9663,347.5601,1830.9663,345.6021z M1832.0732,344.9336
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1832.9609,343.231,1832.2676,343.8511,1832.0732,344.9336z"
          />
          <path d="M1838.5811,348.7764h-1.0947v-8.7319h1.0947V348.7764z" />
          <path d="M1839.8706,340.0444h1.1919v1.2651h-1.1919V340.0444z M1841.0142,348.7764h-1.0947v-6.3604h1.0947V348.7764z" />
          <path
            d="M1842.3521,342.416h1.0947v6.8833c0,1.1309-0.7056,1.7026-1.7881,1.7026c-0.2554,0-0.4131-0.0122-0.6079-0.0366v-0.9487
				c0.1338,0.0244,0.2432,0.0366,0.4258,0.0366c0.6323,0,0.8755-0.3037,0.8755-0.9487V342.416z M1842.3032,340.0444h1.1919v1.2651
				h-1.1919V340.0444z"
          />
          <path
            d="M1845.8794,345.3833l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V345.3833z"
          />
          <path
            d="M1850.3564,345.6021c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1851.5728,348.9102,1850.3564,347.5601,1850.3564,345.6021z M1851.4634,344.9336
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1852.3511,343.231,1851.6577,343.8511,1851.4634,344.9336z"
          />
          <path
            d="M1800.8408,361.8521v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C1801.9229,362.9102,1801.2178,362.4844,1800.8408,361.8521z M1800.8164,359.8335c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8608-0.9849,1.8608-2.3472c0-1.374-0.6689-2.3467-1.8608-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V359.8335z"
          />
          <path
            d="M1806.0469,359.6021c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1807.2632,362.9102,1806.0469,361.5601,1806.0469,359.6021z M1807.1538,358.9336
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1808.0415,357.231,1807.3481,357.8511,1807.1538,358.9336z"
          />
          <path
            d="M1815.8633,357.4985c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V357.4985z"
          />
          <path d="M1816.5327,354.0444h1.1919v1.2651h-1.1919V354.0444z M1817.6763,362.7764h-1.0947v-6.3604h1.0947V362.7764z" />
          <path
            d="M1821.7876,354.9082c-0.1826-0.0244-0.292-0.0244-0.4746-0.0244c-0.6445,0-0.9971,0.2798-0.9971,1.0942v0.438h1.4106
				v0.9365h-1.4106v5.4238h-1.0947v-5.4238h-0.9604v-0.9365h0.9604v-0.5107c0-1.2402,0.6812-1.9946,1.9824-1.9946
				c0.2188,0,0.3403,0.0122,0.584,0.0244V354.9082z"
          />
          <path
            d="M1821.8242,359.6021c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1823.0405,362.9102,1821.8242,361.5601,1821.8242,359.6021z M1822.9312,358.9336
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1823.8188,357.231,1823.1255,357.8511,1822.9312,358.9336z"
          />
          <path
            d="M1831.6406,357.4985c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V357.4985z"
          />
          <path d="M1832.3101,354.0444h1.1919v1.2651h-1.1919V354.0444z M1833.4536,362.7764h-1.0947v-6.3604h1.0947V362.7764z" />
          <path
            d="M1834.354,359.6021c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1835.5703,362.9102,1834.354,361.5601,1834.354,359.6021z M1835.4609,358.9336
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1836.3486,357.231,1835.6553,357.8511,1835.4609,358.9336z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="44">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2457.0471,1260.6169c0,25.27-20.4851,45.75-45.75,45.75c-25.27,0-45.75-20.48-45.75-45.75
			c0-25.2649,20.48-45.75,45.75-45.75C2436.562,1214.8669,2457.0471,1235.3521,2457.0471,1260.6169"
        />
        <g>
          <path d="M2391.748,1235.9326v7.7104h-1.167v-7.7104h-2.9551v-1.0215h7.0771v1.0215H2391.748z" />
          <path
            d="M2398.3418,1243.7769c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2401.2969,1242.439,2400.0811,1243.7769,2398.3418,1243.7769z M2398.3418,1242.8403
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2396.4932,1241.916,2397.2227,1242.8403,2398.3418,1242.8403z"
          />
          <path
            d="M2402.2471,1240.4688c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2403.4629,1243.7769,2402.2471,1242.4268,2402.2471,1240.4688z
				 M2403.3535,1239.8003h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026
				C2404.2412,1238.0977,2403.5479,1238.7178,2403.3535,1239.8003z"
          />
          <path
            d="M2411.6865,1245.9658c-1.3984,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C2414.6416,1244.9321,2413.4131,1245.9658,2411.6865,1245.9658z M2411.7959,1238.1221
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C2413.5586,1238.8027,2412.708,1238.1221,2411.7959,1238.1221z"
          />
          <path
            d="M2415.9688,1241.9282c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2416.9053,1243.7524,2415.9688,1243.0713,2415.9688,1241.9282z M2419.9219,1240.3838l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1240.3838z"
          />
          <path
            d="M2422.8662,1237.2827h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1237.2827z"
          />
          <path
            d="M2432.1602,1245.9658c-1.3994,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C2435.1152,1244.9321,2433.8867,1245.9658,2432.1602,1245.9658z M2432.2695,1238.1221
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C2434.0322,1238.8027,2433.1816,1238.1221,2432.2695,1238.1221z"
          />
          <path
            d="M2376.5312,1252.2192h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1252.2192z"
          />
          <path
            d="M2383.0146,1257.7769c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2385.9697,1256.439,2384.7539,1257.7769,2383.0146,1257.7769z M2383.0146,1256.8403
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2381.166,1255.916,2381.8955,1256.8403,2383.0146,1256.8403z"
          />
          <path
            d="M2387.5283,1252.2192h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1252.2192z"
          />
          <path
            d="M2393.623,1254.4688c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2394.6318,1257.7769,2393.623,1256.269,2393.623,1254.4688z
				 M2396.5898,1252.1221c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2398.3896,1252.7666,2397.4902,1252.1221,2396.5898,1252.1221z"
          />
          <path
            d="M2406.3213,1257.6431h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093
				c-1.3623,0-2.1279-0.9365-2.1279-2.2861v-4.208h1.0938v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083
				c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1257.6431z"
          />
          <path
            d="M2413.1826,1257.6431h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093
				c-1.3623,0-2.1279-0.9365-2.1279-2.2861v-4.208h1.0938v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083
				c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1257.6431z"
          />
          <path
            d="M2418.293,1252.3652c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1252.3652z"
          />
          <path d="M2424.0947,1257.6431h-5.0098v-0.8271l3.6719-4.5967h-3.624v-0.9365h4.9258v0.8394l-3.5996,4.5845h3.6357V1257.6431z" />
          <path
            d="M2425.0205,1255.9282c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2425.957,1257.7524,2425.0205,1257.0713,2425.0205,1255.9282z M2428.9736,1254.3838l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1254.3838z"
          />
          <path
            d="M2431.4189,1255.9282c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C2432.3555,1257.7524,2431.4189,1257.0713,2431.4189,1255.9282z M2435.3721,1254.3838l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1254.3838z"
          />
          <path
            d="M2439.4111,1257.6431h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0312-1.0825c1.3125,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1257.6431z"
          />
          <path
            d="M2376.2578,1273.9658c-1.3994,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C2379.2129,1272.9321,2377.9844,1273.9658,2376.2578,1273.9658z M2376.3672,1266.1221
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C2378.1299,1266.8027,2377.2793,1266.1221,2376.3672,1266.1221z"
          />
          <path
            d="M2380.6016,1268.4688c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2381.8174,1271.7769,2380.6016,1270.4268,2380.6016,1268.4688z M2381.708,1267.8003
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2382.5957,1266.0977,2381.9023,1266.7178,2381.708,1267.8003z"
          />
          <path
            d="M2388.7031,1270.7188v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C2389.7852,1271.7769,2389.0801,1271.3511,2388.7031,1270.7188z M2388.6787,1268.7002c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1268.7002z"
          />
          <path
            d="M2398.1299,1266.3652c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1266.3652z"
          />
          <path
            d="M2401.8525,1271.7769c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2404.8076,1270.439,2403.5918,1271.7769,2401.8525,1271.7769z M2401.8525,1270.8403
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2400.0039,1269.916,2400.7334,1270.8403,2401.8525,1270.8403z"
          />
          <path
            d="M2405.7588,1268.4688c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2406.7676,1271.7769,2405.7588,1270.269,2405.7588,1268.4688z
				 M2408.7256,1266.1221c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2410.5254,1266.7666,2409.626,1266.1221,2408.7256,1266.1221z"
          />
          <path
            d="M2418.457,1271.6431h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1271.6431z"
          />
          <path
            d="M2422.8369,1270.8159c0.875,0,1.3984-0.5591,1.5801-1.3496l0.9736,0.4497c-0.3047,1.0581-1.2285,1.8608-2.5537,1.8608
				c-1.7764,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7661,2.5293,1.8242
				l-0.9492,0.4741c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9102,0.8999-1.9102,2.3589
				C2420.9268,1269.916,2421.7051,1270.8159,2422.8369,1270.8159z"
          />
          <path
            d="M2426.1582,1268.4688c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2427.374,1271.7769,2426.1582,1270.4268,2426.1582,1268.4688z M2427.2646,1267.8003
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2428.1523,1266.0977,2427.459,1266.7178,2427.2646,1267.8003z"
          />
          <path
            d="M2432.7275,1268.4688c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2433.9434,1271.7769,2432.7275,1270.4268,2432.7275,1268.4688z M2433.834,1267.8003
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C2434.7217,1266.0977,2434.0283,1266.7178,2433.834,1267.8003z"
          />
          <path
            d="M2443.0303,1266.3652c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1266.3652z"
          />
          <path
            d="M2443.7979,1268.4688c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2444.8066,1271.7769,2443.7979,1270.269,2443.7979,1268.4688z
				 M2446.7646,1266.1221c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2448.5645,1266.7666,2447.665,1266.1221,2446.7646,1266.1221z"
          />
          <path d="M2393.1602,1285.6431l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2393.1602z" />
          <path
            d="M2400.2148,1285.7769c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C2403.1699,1284.439,2401.9541,1285.7769,2400.2148,1285.7769z M2400.2148,1284.8403
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2398.3662,1283.916,2399.0957,1284.8403,2400.2148,1284.8403z"
          />
          <path
            d="M2404.1201,1282.4688c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2405.3359,1285.7769,2404.1201,1284.4268,2404.1201,1282.4688z
				 M2405.2266,1281.8003h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026
				C2406.1143,1280.0977,2405.4209,1280.7178,2405.2266,1281.8003z"
          />
          <path
            d="M2410.6895,1282.4688c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C2411.6982,1285.7769,2410.6895,1284.269,2410.6895,1282.4688z
				 M2413.6562,1280.1221c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C2415.4561,1280.7666,2414.5566,1280.1221,2413.6562,1280.1221z"
          />
          <path
            d="M2418.4863,1283.6729c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L2418.4863,1283.6729z"
          />
          <path
            d="M2423.8154,1282.4688c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2425.0312,1285.7769,2423.8154,1284.4268,2423.8154,1282.4688z
				 M2424.9219,1281.8003h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026
				C2425.8096,1280.0977,2425.1162,1280.7178,2424.9219,1281.8003z"
          />
          <path d="M2431.917,1285.6431h-1.0947v-8.7319h1.0947V1285.6431z" />
        </g>
      </g>
      <g onClick={handleClick} id="43">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3339.094,997.895c0,25.265-20.481,45.75-45.75,45.75c-25.2661,0-45.75-20.485-45.75-45.75
			c0-25.266,20.4839-45.75,45.75-45.75C3318.613,952.145,3339.094,972.629,3339.094,997.895"
        />
        <g>
          <path
            d="M3275.3271,976.3188l-2.3994-6.4751v6.4751h-0.9863v-7.8662h1.4355l2.3887,6.4639l2.3994-6.4639h1.3906v7.8662h-0.9746
				v-6.4751l-2.4102,6.4751H3275.3271z"
          />
          <path
            d="M3280.7979,974.7739c0-1.1392,0.8877-1.6104,2.1699-1.873l1.3916-0.2852v-0.2412c0-0.6572-0.3623-1.0298-1.1289-1.0298
				c-0.7227,0-1.1504,0.3398-1.3252,0.9751l-0.9316-0.2407c0.2734-0.9312,1.1172-1.5996,2.29-1.5996
				c1.2812,0,2.0703,0.6245,2.0703,1.8516v2.7935c0,0.3726,0.2305,0.4932,0.6133,0.4053v0.7891
				c-0.876,0.1094-1.3799-0.0986-1.501-0.6465c-0.3506,0.4492-0.9854,0.7451-1.7637,0.7451
				C3281.6416,976.4175,3280.7979,975.8037,3280.7979,974.7739z M3284.3594,973.3828l-1.1182,0.2407
				c-0.876,0.1753-1.4678,0.4165-1.4678,1.1069c0,0.5586,0.4053,0.8872,1.0293,0.8872c0.8438,0,1.5566-0.5151,1.5566-1.249V973.3828
				z"
          />
          <path
            d="M3287.1592,971.4326h-0.8867v-0.8438h0.8867v-1.6763h0.9756v1.6763h1.3037v0.8438h-1.3037v3.3633
				c0,0.6025,0.2959,0.7124,0.7998,0.7124c0.2305,0,0.3945-0.0112,0.624-0.0659v0.8438c-0.252,0.0547-0.5361,0.0874-0.8652,0.0874
				c-0.9746,0-1.5342-0.3286-1.5342-1.4243V971.4326z"
          />
          <path
            d="M3290.335,973.4595c0-1.709,1.084-2.98,2.6396-2.98c1.5996,0,2.4434,1.2158,2.4434,2.8047v0.3286h-4.1416
				c0.0547,1.2051,0.7451,1.9722,1.7422,1.9722c0.7666,0,1.3145-0.4165,1.4902-1.0957l0.8652,0.3066
				c-0.3506,1.019-1.2158,1.6436-2.3555,1.6436C3291.4297,976.4395,3290.335,975.2231,3290.335,973.4595z M3291.3311,972.8569
				h3.0898c-0.0215-0.811-0.46-1.5342-1.457-1.5342C3292.1309,971.3228,3291.5068,971.8818,3291.3311,972.8569z"
          />
          <path d="M3300.4199,976.3188l-2.1475-5.73h1.041l1.6104,4.5249l1.5889-4.5249h1.0293l-2.1475,5.73H3300.4199z" />
          <path
            d="M3304.0527,974.7739c0-1.1392,0.8877-1.6104,2.1699-1.873l1.3916-0.2852v-0.2412c0-0.6572-0.3623-1.0298-1.1289-1.0298
				c-0.7227,0-1.1504,0.3398-1.3252,0.9751l-0.9316-0.2407c0.2734-0.9312,1.1172-1.5996,2.29-1.5996
				c1.2812,0,2.0703,0.6245,2.0703,1.8516v2.7935c0,0.3726,0.2305,0.4932,0.6133,0.4053v0.7891
				c-0.876,0.1094-1.3799-0.0986-1.501-0.6465c-0.3506,0.4492-0.9854,0.7451-1.7637,0.7451
				C3304.8965,976.4175,3304.0527,975.8037,3304.0527,974.7739z M3307.6143,973.3828l-1.1182,0.2407
				c-0.876,0.1753-1.4678,0.4165-1.4678,1.1069c0,0.5586,0.4053,0.8872,1.0293,0.8872c0.8438,0,1.5566-0.5151,1.5566-1.249V973.3828
				z"
          />
          <path
            d="M3310.2607,970.5889h0.9863v0.7778c0.3291-0.4712,0.8984-0.8872,1.709-0.8872c1.2168,0,1.9062,0.8433,1.9062,2.0596
				v3.7798h-0.9854v-3.5166c0-0.7998-0.3398-1.4023-1.1943-1.4023c-0.7119,0-1.4355,0.5474-1.4355,1.501v3.418h-0.9863V970.5889z"
          />
          <path
            d="M3252.0117,988.9189h-0.9863v-5.73h0.9863v0.7778c0.3066-0.4712,0.8545-0.8872,1.6543-0.8872
				c0.7666,0,1.3037,0.3833,1.5889,0.9751c0.3174-0.4712,0.9307-0.9751,1.8291-0.9751c1.1836,0,1.8955,0.8438,1.8955,2.0596v3.7798
				h-0.9854v-3.5166c0-0.7998-0.3291-1.4023-1.1396-1.4023c-0.6572,0-1.3584,0.5586-1.3584,1.4897v3.4292h-0.9863v-3.5166
				c0-0.7998-0.3291-1.4023-1.1396-1.4023c-0.6572,0-1.3584,0.5586-1.3584,1.4897V988.9189z"
          />
          <path
            d="M3260.1123,987.374c0-1.1392,0.8877-1.6104,2.1699-1.8735l1.3916-0.2847v-0.2407c0-0.6577-0.3623-1.0303-1.1289-1.0303
				c-0.7227,0-1.1504,0.3398-1.3252,0.9751l-0.9316-0.2407c0.2734-0.9316,1.1172-1.5996,2.29-1.5996
				c1.2812,0,2.0703,0.6245,2.0703,1.8516v2.7935c0,0.3726,0.2305,0.4932,0.6133,0.4058v0.7886
				c-0.876,0.1094-1.3799-0.0986-1.501-0.6465c-0.3506,0.4492-0.9854,0.7451-1.7637,0.7451
				C3260.9561,989.0176,3260.1123,988.4038,3260.1123,987.374z M3263.6738,985.9829l-1.1182,0.2407
				c-0.876,0.1753-1.4678,0.4165-1.4678,1.1069c0,0.5586,0.4053,0.8872,1.0293,0.8872c0.8438,0,1.5566-0.5151,1.5566-1.249V985.9829
				z"
          />
          <path
            d="M3265.8711,987.374c0-1.1392,0.8877-1.6104,2.1699-1.8735l1.3916-0.2847v-0.2407c0-0.6577-0.3623-1.0303-1.1289-1.0303
				c-0.7227,0-1.1504,0.3398-1.3252,0.9751l-0.9316-0.2407c0.2734-0.9316,1.1172-1.5996,2.29-1.5996
				c1.2812,0,2.0703,0.6245,2.0703,1.8516v2.7935c0,0.3726,0.2305,0.4932,0.6133,0.4058v0.7886
				c-0.876,0.1094-1.3799-0.0986-1.501-0.6465c-0.3506,0.4492-0.9854,0.7451-1.7637,0.7451
				C3266.7148,989.0176,3265.8711,988.4038,3265.8711,987.374z M3269.4326,985.9829l-1.1182,0.2407
				c-0.876,0.1753-1.4678,0.4165-1.4678,1.1069c0,0.5586,0.4053,0.8872,1.0293,0.8872c0.8438,0,1.5566-0.5151,1.5566-1.249V985.9829
				z"
          />
          <path
            d="M3272.2324,984.0327h-0.8867v-0.8438h0.8867v-1.6763h0.9756v1.6763h1.3037v0.8438h-1.3037v3.3633
				c0,0.6025,0.2959,0.7124,0.7998,0.7124c0.2295,0,0.3945-0.0112,0.624-0.0659v0.8438c-0.252,0.0547-0.5361,0.0874-0.8652,0.0874
				c-0.9746,0-1.5342-0.3286-1.5342-1.4243V984.0327z"
          />
          <path
            d="M3275.9336,987.144c0.3066,0.6685,1.0078,1.0737,1.7529,1.0737c0.668,0,1.2598-0.2847,1.2598-0.9092
				s-0.5479-0.7124-1.4902-0.9097c-0.9414-0.208-1.9277-0.416-1.9277-1.5884c0-1.019,0.8877-1.731,2.1035-1.731
				c1.0293,0,1.873,0.5039,2.2129,1.1831l-0.7012,0.5586c-0.2959-0.5693-0.832-0.9312-1.5557-0.9312
				c-0.6572,0-1.0957,0.3286-1.0957,0.8438c0,0.5366,0.5039,0.6353,1.3037,0.8105c0.9863,0.23,2.1143,0.4272,2.1143,1.6763
				c0,1.0957-0.9961,1.8188-2.2236,1.8188c-1.041,0-2.082-0.4492-2.5195-1.3369L3275.9336,987.144z"
          />
          <path
            d="M3283.4346,988.1738c0.7881,0,1.2598-0.5039,1.4238-1.2158l0.877,0.4053c-0.2744,0.9531-1.1074,1.6763-2.3008,1.6763
				c-1.5996,0-2.7061-1.2163-2.7061-2.98c0-1.7749,1.1064-2.98,2.7061-2.98c1.1934,0,2.0049,0.6899,2.2783,1.6431l-0.8545,0.4272
				c-0.1641-0.7012-0.6357-1.2158-1.4238-1.2158c-1.0195,0-1.7207,0.8105-1.7207,2.1255
				C3281.7139,987.3633,3282.415,988.1738,3283.4346,988.1738z"
          />
          <path
            d="M3286.8154,981.0527h0.9863v2.9141c0.3291-0.4712,0.8984-0.8872,1.709-0.8872c1.2168,0,1.9062,0.8438,1.9062,2.0596
				v3.7798h-0.9854v-3.5166c0-0.7998-0.3398-1.4023-1.1943-1.4023c-0.7119,0-1.4355,0.5474-1.4355,1.5005v3.4185h-0.9863V981.0527z"
          />
          <path
            d="M3292.541,987.374c0-1.1392,0.8877-1.6104,2.1699-1.8735l1.3916-0.2847v-0.2407c0-0.6577-0.3623-1.0303-1.1289-1.0303
				c-0.7227,0-1.1504,0.3398-1.3252,0.9751l-0.9316-0.2407c0.2734-0.9316,1.1172-1.5996,2.29-1.5996
				c1.2812,0,2.0703,0.6245,2.0703,1.8516v2.7935c0,0.3726,0.2305,0.4932,0.6133,0.4058v0.7886
				c-0.876,0.1094-1.3799-0.0986-1.501-0.6465c-0.3506,0.4492-0.9854,0.7451-1.7637,0.7451
				C3293.3848,989.0176,3292.541,988.4038,3292.541,987.374z M3296.1025,985.9829l-1.1182,0.2407
				c-0.876,0.1753-1.4678,0.4165-1.4678,1.1069c0,0.5586,0.4053,0.8872,1.0293,0.8872c0.8438,0,1.5566-0.5151,1.5566-1.249V985.9829
				z"
          />
          <path
            d="M3299.7354,988.0864v2.8047h-0.9863v-7.7021h0.9863v0.8325c0.3398-0.5586,0.9756-0.9419,1.7754-0.9419
				c1.5879,0,2.4971,1.3477,2.4971,2.98c0,1.6216-0.9092,2.98-2.4971,2.98
				C3300.7109,989.0396,3300.0752,988.6558,3299.7354,988.0864z M3299.7139,986.2676c0,1.3149,0.8105,1.9062,1.6211,1.9062
				c1.0732,0,1.6758-0.8872,1.6758-2.1143c0-1.2383-0.6025-2.1147-1.6758-2.1147c-0.8105,0-1.6211,0.5811-1.6211,1.9175V986.2676z"
          />
          <path
            d="M3306.2383,988.0864v2.8047h-0.9863v-7.7021h0.9863v0.8325c0.3398-0.5586,0.9756-0.9419,1.7754-0.9419
				c1.5879,0,2.4971,1.3477,2.4971,2.98c0,1.6216-0.9092,2.98-2.4971,2.98
				C3307.2139,989.0396,3306.5781,988.6558,3306.2383,988.0864z M3306.2168,986.2676c0,1.3149,0.8105,1.9062,1.6211,1.9062
				c1.0732,0,1.6768-0.8872,1.6768-2.1143c0-1.2383-0.6035-2.1147-1.6768-2.1147c-0.8105,0-1.6211,0.5811-1.6211,1.9175V986.2676z"
          />
          <path
            d="M3311.3613,986.0596c0-1.709,1.084-2.98,2.6396-2.98c1.5996,0,2.4434,1.2158,2.4434,2.8047v0.3286h-4.1416
				c0.0547,1.2051,0.7451,1.9722,1.7422,1.9722c0.7666,0,1.3145-0.4165,1.4902-1.0957l0.8652,0.3066
				c-0.3506,1.019-1.2158,1.6436-2.3555,1.6436C3312.4561,989.0396,3311.3613,987.8232,3311.3613,986.0596z M3312.3574,985.457
				h3.0898c-0.0215-0.811-0.46-1.5337-1.457-1.5337C3313.1572,983.9233,3312.5332,984.4819,3312.3574,985.457z"
          />
          <path d="M3318.6533,988.9189h-0.9863v-7.8662h0.9863V988.9189z" />
          <path d="M3320.252,981.0527h1.0732v1.1392h-1.0732V981.0527z M3321.2812,988.9189h-0.9863v-5.73h0.9863V988.9189z" />
          <path
            d="M3322.9229,983.189h0.9863v6.2012c0,1.0186-0.6357,1.5337-1.6104,1.5337c-0.2305,0-0.3721-0.0107-0.5479-0.0327v-0.8545
				c0.1201,0.0215,0.2188,0.0327,0.3838,0.0327c0.5693,0,0.7881-0.2739,0.7881-0.8545V983.189z M3322.8799,981.0527h1.0732v1.1392
				h-1.0732V981.0527z"
          />
          <path
            d="M3326.5371,985.8623l2.542-2.6733h1.2266l-2.2344,2.2568l2.4209,3.4731h-1.1504l-1.9717-2.7607l-0.833,0.8433v1.9175
				h-0.9863v-7.8662h0.9863V985.8623z"
          />
          <path
            d="M3331.0039,986.0596c0-1.709,1.084-2.98,2.6396-2.98c1.5996,0,2.4434,1.2158,2.4434,2.8047v0.3286h-4.1416
				c0.0547,1.2051,0.7451,1.9722,1.7422,1.9722c0.7666,0,1.3145-0.4165,1.4902-1.0957l0.8652,0.3066
				c-0.3506,1.019-1.2158,1.6436-2.3555,1.6436C3332.0986,989.0396,3331.0039,987.8232,3331.0039,986.0596z M3332,985.457h3.0898
				c-0.0215-0.811-0.46-1.5337-1.457-1.5337C3332.7998,983.9233,3332.1758,984.4819,3332,985.457z"
          />
          <path
            d="M3258.3467,1000.7739c0.7881,0,1.2598-0.5039,1.4238-1.2158l0.877,0.4053c-0.2744,0.9531-1.1074,1.6763-2.3008,1.6763
				c-1.5996,0-2.7061-1.2163-2.7061-2.98c0-1.7749,1.1064-2.98,2.7061-2.98c1.1934,0,2.0049,0.6899,2.2783,1.6431l-0.8545,0.4277
				c-0.1641-0.7012-0.6357-1.2163-1.4238-1.2163c-1.0195,0-1.7207,0.8105-1.7207,2.1255
				C3256.626,999.9634,3257.3271,1000.7739,3258.3467,1000.7739z"
          />
          <path
            d="M3263.9961,1001.6396c-1.5674,0-2.6621-1.2051-2.6621-2.98s1.0947-2.98,2.6621-2.98c1.5664,0,2.6621,1.2051,2.6621,2.98
				S3265.5625,1001.6396,3263.9961,1001.6396z M3263.9961,1000.7959c1.0078,0,1.665-0.8325,1.665-2.1362
				c0-1.3149-0.6572-2.1475-1.665-2.1475s-1.666,0.8325-1.666,2.1475C3262.3301,999.9634,3262.9883,1000.7959,3263.9961,1000.7959z"
          />
          <path
            d="M3267.9023,995.7891h0.9863v0.7778c0.3291-0.4712,0.8984-0.8872,1.709-0.8872c1.2158,0,1.9062,0.8433,1.9062,2.0596
				v3.7798h-0.9854v-3.5166c0-0.7998-0.3398-1.4023-1.1943-1.4023c-0.7119,0-1.4355,0.5474-1.4355,1.501v3.418h-0.9863V995.7891z"
          />
          <path
            d="M3274.209,999.7441c0.3066,0.6685,1.0078,1.0737,1.7529,1.0737c0.668,0,1.2598-0.2847,1.2598-0.9092
				s-0.5479-0.7124-1.4902-0.9097c-0.9414-0.208-1.9277-0.416-1.9277-1.5884c0-1.019,0.8877-1.731,2.1035-1.731
				c1.0293,0,1.873,0.5039,2.2129,1.1831l-0.7012,0.5586c-0.2959-0.5693-0.832-0.9312-1.5557-0.9312
				c-0.6572,0-1.0957,0.3286-1.0957,0.8438c0,0.5366,0.5039,0.6353,1.3037,0.8105c0.9863,0.23,2.1143,0.4272,2.1143,1.6763
				c0,1.0957-0.9961,1.8188-2.2236,1.8188c-1.041,0-2.082-0.4492-2.5195-1.3369L3274.209,999.7441z"
          />
          <path
            d="M3279.0039,998.6597c0-1.709,1.084-2.98,2.6396-2.98c1.5996,0,2.4434,1.2158,2.4434,2.8047v0.3286h-4.1416
				c0.0547,1.2051,0.7451,1.9722,1.7422,1.9722c0.7666,0,1.3145-0.4165,1.4902-1.0957l0.8652,0.3066
				c-0.3506,1.019-1.2158,1.6436-2.3555,1.6436C3280.0986,1001.6396,3279.0039,1000.4233,3279.0039,998.6597z M3280,998.0571h3.0898
				c-0.0215-0.811-0.46-1.5342-1.457-1.5342C3280.7998,996.5229,3280.1758,997.082,3280,998.0571z"
          />
          <path
            d="M3285.3096,995.7891h0.9863v0.7778c0.3291-0.4712,0.8984-0.8872,1.709-0.8872c1.2168,0,1.9062,0.8433,1.9062,2.0596
				v3.7798h-0.9854v-3.5166c0-0.7998-0.3398-1.4023-1.1943-1.4023c-0.7119,0-1.4355,0.5474-1.4355,1.501v3.418h-0.9863V995.7891z"
          />
          <path
            d="M3291.6162,999.7441c0.3066,0.6685,1.0078,1.0737,1.7529,1.0737c0.668,0,1.2598-0.2847,1.2598-0.9092
				s-0.5479-0.7124-1.4902-0.9097c-0.9414-0.208-1.9277-0.416-1.9277-1.5884c0-1.019,0.8877-1.731,2.1035-1.731
				c1.0303,0,1.873,0.5039,2.2129,1.1831l-0.7012,0.5586c-0.2959-0.5693-0.833-0.9312-1.5557-0.9312
				c-0.6572,0-1.0957,0.3286-1.0957,0.8438c0,0.5366,0.5039,0.6353,1.3037,0.8105c0.9863,0.23,2.1143,0.4272,2.1143,1.6763
				c0,1.0957-0.9961,1.8188-2.2236,1.8188c-1.041,0-2.082-0.4492-2.5195-1.3369L3291.6162,999.7441z"
          />
          <path
            d="M3301.3516,1001.519h-0.9854v-0.7891c-0.3291,0.4824-0.8877,0.9097-1.6982,0.9097c-1.2275,0-1.918-0.8438-1.918-2.0596
				v-3.791h0.9863v3.5825c0,0.811,0.3506,1.3584,1.1943,1.3584c0.7119,0,1.4355-0.5366,1.4355-1.4897v-3.4512h0.9854V1001.519z"
          />
          <path
            d="M3303.1113,999.7441c0.3066,0.6685,1.0078,1.0737,1.7529,1.0737c0.668,0,1.2598-0.2847,1.2598-0.9092
				s-0.5479-0.7124-1.4902-0.9097c-0.9414-0.208-1.9277-0.416-1.9277-1.5884c0-1.019,0.8877-1.731,2.1035-1.731
				c1.0293,0,1.873,0.5039,2.2129,1.1831l-0.7012,0.5586c-0.2959-0.5693-0.832-0.9312-1.5557-0.9312
				c-0.6572,0-1.0957,0.3286-1.0957,0.8438c0,0.5366,0.5039,0.6353,1.3037,0.8105c0.9863,0.23,2.1143,0.4272,2.1143,1.6763
				c0,1.0957-0.9961,1.8188-2.2236,1.8188c-1.041,0-2.082-0.4492-2.5195-1.3369L3303.1113,999.7441z"
          />
          <path
            d="M3312.8779,1001.6396c-1.5674,0-2.6621-1.2051-2.6621-2.98s1.0947-2.98,2.6621-2.98c1.5664,0,2.6621,1.2051,2.6621,2.98
				S3314.4443,1001.6396,3312.8779,1001.6396z M3312.8779,1000.7959c1.0078,0,1.665-0.8325,1.665-2.1362
				c0-1.3149-0.6572-2.1475-1.665-2.1475s-1.666,0.8325-1.666,2.1475C3311.2119,999.9634,3311.8701,1000.7959,3312.8779,1000.7959z"
          />
          <path d="M3318.2529,1001.519l-2.1475-5.73h1.041l1.6104,4.5249l1.5879-4.5249h1.0303l-2.1475,5.73H3318.2529z" />
          <path
            d="M3321.9414,998.6597c0-1.709,1.084-2.98,2.6396-2.98c1.5996,0,2.4434,1.2158,2.4434,2.8047v0.3286h-4.1416
				c0.0547,1.2051,0.7451,1.9722,1.7422,1.9722c0.7666,0,1.3145-0.4165,1.4902-1.0957l0.8652,0.3066
				c-0.3506,1.019-1.2158,1.6436-2.3555,1.6436C3323.0361,1001.6396,3321.9414,1000.4233,3321.9414,998.6597z M3322.9375,998.0571
				h3.0898c-0.0215-0.811-0.46-1.5342-1.457-1.5342C3323.7373,996.5229,3323.1133,997.082,3322.9375,998.0571z"
          />
          <path
            d="M3331.2168,996.7642c-0.165-0.0327-0.2959-0.0439-0.4824-0.0439c-0.833,0-1.501,0.6577-1.501,1.6655v3.1333h-0.9863
				v-5.73h0.9863v0.9751c0.252-0.5806,0.8105-1.019,1.5889-1.019c0.1533,0,0.2959,0.0112,0.3945,0.022V996.7642z"
          />
          <path
            d="M3269.0137,1011.2593c0-1.6323,0.9092-2.98,2.4971-2.98c0.7998,0,1.4355,0.3833,1.7754,0.9419v-2.9688h0.9863v7.8662
				h-0.9863v-0.8325c-0.3398,0.5698-0.9756,0.9531-1.7754,0.9531C3269.9229,1014.2393,3269.0137,1012.8809,3269.0137,1011.2593z
				 M3271.6865,1009.1445c-1.0742,0-1.6768,0.8765-1.6768,2.1147c0,1.2271,0.6025,2.1143,1.6768,2.1143
				c0.8105,0,1.6211-0.5913,1.6211-1.9062v-0.4053C3273.3076,1009.7256,3272.4971,1009.1445,3271.6865,1009.1445z"
          />
          <path
            d="M3280.4463,1014.1187h-0.9863v-0.7891c-0.3281,0.4824-0.8867,0.9097-1.6973,0.9097c-1.2275,0-1.918-0.8438-1.918-2.0596
				v-3.791h0.9863v3.5825c0,0.811,0.3506,1.3584,1.1943,1.3584c0.7119,0,1.4346-0.5366,1.4346-1.4897v-3.4512h0.9863V1014.1187z"
          />
          <path
            d="M3286.6211,1014.1187h-0.9854v-0.7891c-0.3291,0.4824-0.8877,0.9097-1.6982,0.9097c-1.2275,0-1.918-0.8438-1.918-2.0596
				v-3.791h0.9863v3.5825c0,0.811,0.3506,1.3584,1.1943,1.3584c0.7119,0,1.4355-0.5366,1.4355-1.4897v-3.4512h0.9854V1014.1187z"
          />
          <path
            d="M3291.2188,1009.3638c-0.165-0.0327-0.2959-0.0439-0.4824-0.0439c-0.833,0-1.501,0.6577-1.501,1.6655v3.1333h-0.9863
				v-5.73h0.9863v0.9751c0.252-0.5806,0.8105-1.019,1.5889-1.019c0.1533,0,0.2959,0.0112,0.3945,0.022V1009.3638z"
          />
          <path d="M3296.4414,1014.1187h-4.5137v-0.7451l3.3086-4.1411h-3.2646v-0.8438h4.4365v0.7559l-3.2422,4.1304h3.2754V1014.1187z" />
          <path
            d="M3297.2695,1012.5737c0-1.1392,0.8877-1.6104,2.1699-1.873l1.3916-0.2852v-0.2412c0-0.6572-0.3623-1.0298-1.1289-1.0298
				c-0.7227,0-1.1504,0.3398-1.3252,0.9751l-0.9316-0.2407c0.2734-0.9312,1.1172-1.5996,2.29-1.5996
				c1.2812,0,2.0703,0.6245,2.0703,1.8516v2.7935c0,0.3726,0.2305,0.4932,0.6133,0.4053v0.7891
				c-0.876,0.1094-1.3799-0.0986-1.501-0.6465c-0.3506,0.4492-0.9854,0.7451-1.7637,0.7451
				C3298.1133,1014.2173,3297.2695,1013.6035,3297.2695,1012.5737z M3300.8311,1011.1826l-1.1182,0.2407
				c-0.876,0.1753-1.4678,0.4165-1.4678,1.1069c0,0.5586,0.4053,0.8872,1.0293,0.8872c0.8438,0,1.5566-0.5151,1.5566-1.249
				V1011.1826z"
          />
          <path
            d="M3304.4639,1014.1187h-0.9863v-5.73h0.9863v0.7778c0.3066-0.4712,0.8545-0.8872,1.6543-0.8872
				c0.7666,0,1.3037,0.3833,1.5889,0.9751c0.3174-0.4712,0.9307-0.9751,1.8291-0.9751c1.1836,0,1.8955,0.8433,1.8955,2.0596v3.7798
				h-0.9854v-3.5166c0-0.7998-0.3291-1.4023-1.1396-1.4023c-0.6572,0-1.3584,0.5586-1.3584,1.4897v3.4292h-0.9863v-3.5166
				c0-0.7998-0.3291-1.4023-1.1396-1.4023c-0.6572,0-1.3584,0.5586-1.3584,1.4897V1014.1187z"
          />
          <path
            d="M3312.6201,1011.2593c0-1.709,1.084-2.98,2.6396-2.98c1.5996,0,2.4434,1.2158,2.4434,2.8047v0.3286h-4.1416
				c0.0547,1.2051,0.7451,1.9722,1.7422,1.9722c0.7666,0,1.3145-0.4165,1.4902-1.0957l0.8652,0.3066
				c-0.3506,1.019-1.2158,1.6436-2.3555,1.6436C3313.7148,1014.2393,3312.6201,1013.0229,3312.6201,1011.2593z M3313.6162,1010.6567
				h3.0898c-0.0215-0.811-0.46-1.5342-1.457-1.5342C3314.416,1009.1226,3313.792,1009.6816,3313.6162,1010.6567z"
          />
          <path d="M3253.8516,1026.7188l-2.1475-5.73h1.041l1.6104,4.5249l1.5889-4.5249h1.0293l-2.1475,5.73H3253.8516z" />
          <path
            d="M3259.4355,1026.8394c-1.5674,0-2.6621-1.2051-2.6621-2.98s1.0947-2.98,2.6621-2.98c1.5664,0,2.6621,1.2051,2.6621,2.98
				S3261.002,1026.8394,3259.4355,1026.8394z M3259.4355,1025.9956c1.0078,0,1.665-0.8325,1.665-2.1362
				c0-1.3149-0.6572-2.1475-1.665-2.1475s-1.666,0.8325-1.666,2.1475C3257.7695,1025.1631,3258.4277,1025.9956,3259.4355,1025.9956z
				"
          />
          <path
            d="M3262.1816,1023.8594c0-1.709,1.084-2.98,2.6396-2.98c1.5996,0,2.4434,1.2158,2.4434,2.8047v0.3286h-4.1416
				c0.0547,1.2051,0.7451,1.9722,1.7422,1.9722c0.7666,0,1.3145-0.4165,1.4902-1.0957l0.8652,0.3066
				c-0.3506,1.019-1.2158,1.6436-2.3555,1.6436C3263.2764,1026.8394,3262.1816,1025.623,3262.1816,1023.8594z M3263.1777,1023.2568
				h3.0898c-0.0215-0.811-0.46-1.5337-1.457-1.5337C3263.9775,1021.7231,3263.3535,1022.2817,3263.1777,1023.2568z"
          />
          <path
            d="M3267.3281,1023.8594c0-1.6323,0.9092-2.98,2.4971-2.98c0.7998,0,1.4355,0.3833,1.7754,0.9419v-2.9688h0.9863v7.8662
				h-0.9863v-0.8325c-0.3398,0.5693-0.9756,0.9531-1.7754,0.9531C3268.2373,1026.8394,3267.3281,1025.481,3267.3281,1023.8594z
				 M3270.001,1021.7446c-1.0742,0-1.6768,0.8765-1.6768,2.1147c0,1.2271,0.6025,2.1143,1.6768,2.1143
				c0.8105,0,1.6211-0.5913,1.6211-1.9062v-0.4053C3271.6221,1022.3257,3270.8115,1021.7446,3270.001,1021.7446z"
          />
          <path
            d="M3273.5791,1024.9438c0.3066,0.6685,1.0078,1.0737,1.7529,1.0737c0.668,0,1.2598-0.2847,1.2598-0.9092
				s-0.5479-0.7124-1.4902-0.9097c-0.9414-0.208-1.9277-0.416-1.9277-1.5884c0-1.019,0.8877-1.731,2.1035-1.731
				c1.0303,0,1.873,0.5039,2.2129,1.1831l-0.7012,0.5586c-0.2959-0.5693-0.833-0.9312-1.5557-0.9312
				c-0.6572,0-1.0957,0.3286-1.0957,0.8438c0,0.5366,0.5039,0.6353,1.3037,0.8105c0.9863,0.23,2.1143,0.4272,2.1143,1.6763
				c0,1.0957-0.9961,1.8188-2.2236,1.8188c-1.041,0-2.082-0.4492-2.5195-1.3369L3273.5791,1024.9438z"
          />
          <path
            d="M3277.6074,1023.8594c0-1.709,1.084-2.98,2.6396-2.98c1.5996,0,2.4434,1.2158,2.4434,2.8047v0.3286h-4.1416
				c0.0547,1.2051,0.7451,1.9722,1.7422,1.9722c0.7666,0,1.3145-0.4165,1.4902-1.0957l0.8652,0.3066
				c-0.3506,1.019-1.2158,1.6436-2.3555,1.6436C3278.7021,1026.8394,3277.6074,1025.623,3277.6074,1023.8594z M3278.6035,1023.2568
				h3.0898c-0.0215-0.811-0.46-1.5337-1.457-1.5337C3279.4033,1021.7231,3278.7793,1022.2817,3278.6035,1023.2568z"
          />
          <path d="M3284.1328,1026.7188h-0.9863v-7.8662h0.9863V1026.7188z" />
          <path
            d="M3287.3213,1025.9736c0.7881,0,1.2598-0.5039,1.4238-1.2158l0.877,0.4053c-0.2744,0.9531-1.1074,1.6763-2.3008,1.6763
				c-1.5996,0-2.7061-1.2163-2.7061-2.98c0-1.7749,1.1064-2.98,2.7061-2.98c1.1934,0,2.0049,0.6899,2.2783,1.6431l-0.8545,0.4272
				c-0.1641-0.7012-0.6357-1.2158-1.4238-1.2158c-1.0195,0-1.7207,0.8105-1.7207,2.1255
				C3285.6006,1025.1631,3286.3018,1025.9736,3287.3213,1025.9736z"
          />
          <path
            d="M3292.2041,1026.8394c-1.5674,0-2.6621-1.2051-2.6621-2.98s1.0947-2.98,2.6621-2.98c1.5664,0,2.6621,1.2051,2.6621,2.98
				S3293.7705,1026.8394,3292.2041,1026.8394z M3292.2041,1025.9956c1.0078,0,1.665-0.8325,1.665-2.1362
				c0-1.3149-0.6572-2.1475-1.665-2.1475s-1.666,0.8325-1.666,2.1475C3290.5381,1025.1631,3291.1963,1025.9956,3292.2041,1025.9956z
				"
          />
          <path
            d="M3295.3438,1020.9888h0.9863v0.7778c0.3291-0.4712,0.8984-0.8872,1.709-0.8872c1.2158,0,1.9062,0.8438,1.9062,2.0596
				v3.7798h-0.9854v-3.5166c0-0.7998-0.3398-1.4023-1.1943-1.4023c-0.7119,0-1.4355,0.5474-1.4355,1.5005v3.4185h-0.9863V1020.9888z
				"
          />
          <path
            d="M3300.8838,1024.9438c0.3066,0.6685,1.0078,1.0737,1.7529,1.0737c0.668,0,1.2598-0.2847,1.2598-0.9092
				s-0.5479-0.7124-1.4902-0.9097c-0.9414-0.208-1.9277-0.416-1.9277-1.5884c0-1.019,0.8877-1.731,2.1035-1.731
				c1.0303,0,1.873,0.5039,2.2129,1.1831l-0.7012,0.5586c-0.2959-0.5693-0.833-0.9312-1.5557-0.9312
				c-0.6572,0-1.0957,0.3286-1.0957,0.8438c0,0.5366,0.5039,0.6353,1.3037,0.8105c0.9863,0.23,2.1143,0.4272,2.1143,1.6763
				c0,1.0957-0.9961,1.8188-2.2236,1.8188c-1.041,0-2.082-0.4492-2.5195-1.3369L3300.8838,1024.9438z"
          />
          <path
            d="M3309.8525,1026.7188h-0.9863v-0.7886c-0.3281,0.4819-0.8867,0.9092-1.6973,0.9092c-1.2275,0-1.918-0.8438-1.918-2.0596
				v-3.791h0.9863v3.5825c0,0.811,0.3506,1.3589,1.1943,1.3589c0.7119,0,1.4346-0.5371,1.4346-1.4902v-3.4512h0.9863V1026.7188z"
          />
          <path
            d="M3311.7012,1026.7188h-0.9863v-5.73h0.9863v0.7778c0.3066-0.4712,0.8545-0.8872,1.6543-0.8872
				c0.7666,0,1.3037,0.3833,1.5889,0.9751c0.3174-0.4712,0.9307-0.9751,1.8291-0.9751c1.1836,0,1.8955,0.8438,1.8955,2.0596v3.7798
				h-0.9854v-3.5166c0-0.7998-0.3291-1.4023-1.1396-1.4023c-0.6572,0-1.3584,0.5586-1.3584,1.4897v3.4292h-0.9863v-3.5166
				c0-0.7998-0.3291-1.4023-1.1396-1.4023c-0.6572,0-1.3584,0.5586-1.3584,1.4897V1026.7188z"
          />
          <path
            d="M3320.4707,1025.8862v2.8047h-0.9863v-7.7021h0.9863v0.8325c0.3398-0.5586,0.9756-0.9419,1.7754-0.9419
				c1.5879,0,2.4971,1.3477,2.4971,2.98c0,1.6216-0.9092,2.98-2.4971,2.98
				C3321.4463,1026.8394,3320.8105,1026.4556,3320.4707,1025.8862z M3320.4492,1024.0674c0,1.3149,0.8105,1.9062,1.6211,1.9062
				c1.0732,0,1.6768-0.8872,1.6768-2.1143c0-1.2383-0.6035-2.1147-1.6768-2.1147c-0.8105,0-1.6211,0.5811-1.6211,1.9175V1024.0674z"
          />
          <path
            d="M3325.374,1021.8325h-0.8867v-0.8438h0.8867v-1.6763h0.9756v1.6763h1.3037v0.8438h-1.3037v3.3633
				c0,0.6025,0.2959,0.7124,0.7998,0.7124c0.2305,0,0.3945-0.0112,0.624-0.0659v0.8438c-0.252,0.0547-0.5361,0.0874-0.8652,0.0874
				c-0.9746,0-1.5342-0.3286-1.5342-1.4243V1021.8325z"
          />
          <path d="M3328.1338,1018.8525h1.0732v1.1392h-1.0732V1018.8525z M3329.1631,1026.7188h-0.9863v-5.73h0.9863V1026.7188z" />
          <path
            d="M3329.6455,1023.8594c0-1.709,1.084-2.98,2.6396-2.98c1.5996,0,2.4434,1.2158,2.4434,2.8047v0.3286h-4.1416
				c0.0547,1.2051,0.7451,1.9722,1.7422,1.9722c0.7666,0,1.3145-0.4165,1.4902-1.0957l0.8652,0.3066
				c-0.3506,1.019-1.2158,1.6436-2.3555,1.6436C3330.7402,1026.8394,3329.6455,1025.623,3329.6455,1023.8594z M3330.6416,1023.2568
				h3.0898c-0.0215-0.811-0.46-1.5337-1.457-1.5337C3331.4414,1021.7231,3330.8174,1022.2817,3330.6416,1023.2568z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="42">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M790.191,813.707c0,25.27-20.48,45.75-45.75,45.75c-25.265,0-45.75-20.48-45.75-45.75
			c0-25.2659,20.485-45.75,45.75-45.75C769.711,767.957,790.191,788.4412,790.191,813.707"
        />
        <g>
          <path d="M711.4678,796.0234v7.71h-1.1675v-7.71h-2.9556v-1.0215h7.0781v1.0215H711.4678z" />
          <path
            d="M715.106,800.5596c0-1.8975,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3398
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C716.3223,803.8672,715.106,802.5176,715.106,800.5596z M716.2129,799.8906h3.4292
				c-0.0239-0.8994-0.5107-1.7021-1.6172-1.7021C717.1006,798.1885,716.4072,798.8086,716.2129,799.8906z"
          />
          <path
            d="M725.4087,798.4561c-0.1826-0.0371-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.4775h-1.0947
				v-6.3604h1.0947v1.083c0.2793-0.6445,0.8999-1.1318,1.7632-1.1318c0.1704,0,0.3281,0.0127,0.438,0.0244V798.4561z"
          />
          <path
            d="M731.6602,803.7334h-1.0942v-0.875c-0.3652,0.5352-0.9854,1.0088-1.8853,1.0088c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5088,1.3257,1.5088c0.7905,0,1.5933-0.5967,1.5933-1.6543v-3.8311h1.0942V803.7334z"
          />
          <path
            d="M735.9062,806.0566c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.8975v-0.7178c-0.3408,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1738c0-1.7275,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.4375,1.9458,1.0576v-0.9365
				h1.0942v5.7891C738.8613,805.0225,737.6333,806.0566,735.9062,806.0566z M736.0156,798.2129
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3369,0.7051,2.2129,1.8604,2.2129c0.9121,0,1.7637-0.6807,1.7637-2.0791v-0.2314
				C737.7793,798.8936,736.9277,798.2129,736.0156,798.2129z"
          />
          <path
            d="M744.6401,797.373l1.3135,4.7432l1.3257-4.7432h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.7314l1.3135-4.7314H744.6401z"
          />
          <path d="M749.3975,795.002h1.1919v1.2646h-1.1919V795.002z M750.541,803.7334h-1.0947v-6.3604h1.0947V803.7334z" />
          <path
            d="M752.3657,797.373h1.0947v0.8633c0.3647-0.5225,0.9971-0.9844,1.897-0.9844c1.3501,0,2.1162,0.9365,2.1162,2.2861v4.1953
				h-1.0947v-3.9033c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V797.373z"
          />
          <path
            d="M759.2266,797.373h1.0947v0.8633c0.3647-0.5225,0.9971-0.9844,1.897-0.9844c1.3496,0,2.1162,0.9365,2.1162,2.2861v4.1953
				h-1.0947v-3.9033c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V797.373z"
          />
          <path d="M766.0386,795.002h1.1919v1.2646h-1.1919V795.002z M767.1821,803.7334h-1.0947v-6.3604h1.0947V803.7334z" />
          <path
            d="M769.0068,797.373h1.0947v0.8633c0.3647-0.5225,0.9971-0.9844,1.897-0.9844c1.3496,0,2.1162,0.9365,2.1162,2.2861v4.1953
				H773.02v-3.9033c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V797.373z"
          />
          <path
            d="M778.2998,806.0566c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.8975v-0.7178c-0.3408,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1738c0-1.7275,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.4375,1.9458,1.0576v-0.9365
				h1.0942v5.7891C781.2549,805.0225,780.0269,806.0566,778.2998,806.0566z M778.4092,798.2129
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3369,0.7051,2.2129,1.8604,2.2129c0.9121,0,1.7637-0.6807,1.7637-2.0791v-0.2314
				C780.1729,798.8936,779.3213,798.2129,778.4092,798.2129z"
          />
          <path d="M715.5981,817.7334l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H715.5981z" />
          <path
            d="M719.6377,816.0186c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2524-1.1426
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0547v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4502v0.875
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7168c-0.3892,0.498-1.0947,0.8262-1.958,0.8262
				C720.5742,817.8428,719.6377,817.1621,719.6377,816.0186z M723.5898,814.4746l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9844,1.1436,0.9844c0.936,0,1.7266-0.5713,1.7266-1.3857
				V814.4746z"
          />
          <path
            d="M726.5347,811.373h1.0947v0.8633c0.3647-0.5225,0.9971-0.9844,1.897-0.9844c1.3501,0,2.1162,0.9365,2.1162,2.2861v4.1953
				h-1.0947v-3.9033c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V811.373z"
          />
          <path
            d="M735.5244,814.5596c0-1.8975,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3398
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C736.7407,817.8672,735.5244,816.5176,735.5244,814.5596z M736.6313,813.8906h3.4292
				c-0.0239-0.8994-0.5107-1.7021-1.6172-1.7021C737.519,812.1885,736.8257,812.8086,736.6313,813.8906z"
          />
          <path
            d="M742.5312,811.373h1.0947v0.8633c0.3647-0.5225,0.9971-0.9844,1.897-0.9844c1.3496,0,2.1162,0.9365,2.1162,2.2861v4.1953
				h-1.0947v-3.9033c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V811.373z"
          />
          <path
            d="M748.9541,814.5596c0-1.8975,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643H750
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3398
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C750.1704,817.8672,748.9541,816.5176,748.9541,814.5596z M750.061,813.8906h3.4292
				c-0.0239-0.8994-0.5107-1.7021-1.6172-1.7021C750.9487,812.1885,750.2554,812.8086,750.061,813.8906z"
          />
          <path
            d="M759.2568,812.4561c-0.1826-0.0371-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.4775h-1.0947
				v-6.3604h1.0947v1.083c0.2793-0.6445,0.8999-1.1318,1.7632-1.1318c0.1704,0,0.3281,0.0127,0.438,0.0244V812.4561z"
          />
          <path
            d="M762.8936,820.0566c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.8975v-0.7178c-0.3408,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1738c0-1.7275,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.4375,1.9458,1.0576v-0.9365
				h1.0942v5.7891C765.8486,819.0225,764.6206,820.0566,762.8936,820.0566z M763.0029,812.2129
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3369,0.7051,2.2129,1.8604,2.2129c0.9121,0,1.7637-0.6807,1.7637-2.0791v-0.2314
				C764.7666,812.8936,763.915,812.2129,763.0029,812.2129z"
          />
          <path d="M767.6265,809.002h1.1919v1.2646h-1.1919V809.002z M768.77,817.7334h-1.0947v-6.3604h1.0947V817.7334z" />
          <path
            d="M770.1567,814.5596c0-1.8975,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3398
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C771.373,817.8672,770.1567,816.5176,770.1567,814.5596z M771.2637,813.8906h3.4292
				c-0.0239-0.8994-0.5107-1.7021-1.6172-1.7021C772.1514,812.1885,771.458,812.8086,771.2637,813.8906z"
          />
          <path
            d="M716.2603,831.7334h-1.0947v-0.875c-0.3647,0.5352-0.9849,1.0088-1.8848,1.0088c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5088,1.3257,1.5088c0.7905,0,1.5928-0.5967,1.5928-1.6543v-3.8311h1.0947V831.7334z"
          />
          <path d="M718.0254,823.002h1.1919v1.2646h-1.1919V823.002z M719.1689,831.7334h-1.0947v-6.3604h1.0947V831.7334z" />
          <path
            d="M721.1641,826.3096h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.791,0.8877,0.791c0.2554,0,0.438-0.0127,0.6934-0.0732v0.9365c-0.2798,0.0605-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V826.3096z"
          />
          <path
            d="M728.791,830.8096v0.9238h-1.0947v-8.7314h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4951,2.7729,3.3076c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C729.873,831.8672,729.168,831.4414,728.791,830.8096z M728.7666,828.791c0,1.459,0.8999,2.1152,1.7998,2.1152
				c1.1919,0,1.8608-0.9844,1.8608-2.3467c0-1.374-0.6689-2.3467-1.8608-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V828.791z"
          />
          <path d="M734.873,823.002h1.1919v1.2646h-1.1919V823.002z M736.0166,831.7334h-1.0947v-6.3604h1.0947V831.7334z" />
          <path
            d="M740.3589,831.8672c-1.7393,0-2.9556-1.3379-2.9556-3.3076s1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076S742.0977,831.8672,740.3589,831.8672z M740.3589,830.9307
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.459-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9248-1.8486,2.3838
				C738.5103,830.0068,739.2397,830.9307,740.3589,830.9307z"
          />
          <path
            d="M745.7969,831.7334h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5225,0.9482-0.9844,1.8359-0.9844
				c0.8516,0,1.4473,0.4258,1.7637,1.082c0.3525-0.5225,1.0337-1.082,2.0308-1.082c1.3135,0,2.104,0.9365,2.104,2.2861v4.1953
				h-1.0947v-3.9033c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V831.7334z"
          />
          <path
            d="M754.7993,830.0186c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2524-1.1426
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0547v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4502v0.875
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7168c-0.3892,0.498-1.0947,0.8262-1.958,0.8262
				C755.7358,831.8428,754.7993,831.1621,754.7993,830.0186z M758.7515,828.4746l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9844,1.1436,0.9844c0.936,0,1.7266-0.5713,1.7266-1.3857
				V828.4746z"
          />
          <path
            d="M761.8423,829.7637c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0088
				c0-0.6934-0.6079-0.791-1.6538-1.0098c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9209,2.335-1.9209
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6328-0.9243-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.9004c1.0942,0.2549,2.3472,0.4736,2.3472,1.8604
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.498-2.7974-1.4834L761.8423,829.7637z"
          />
          <path
            d="M767.7539,829.7637c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0088
				c0-0.6934-0.6079-0.791-1.6538-1.0098c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9209,2.335-1.9209
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.9004c1.0942,0.2549,2.3472,0.4736,2.3472,1.8604
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.498-2.7974-1.4834L767.7539,829.7637z"
          />
          <path
            d="M773.0215,830.0186c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2524-1.1426
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0547v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4502v0.875
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7168c-0.3892,0.498-1.0947,0.8262-1.958,0.8262
				C773.958,831.8428,773.0215,831.1621,773.0215,830.0186z M776.9736,828.4746l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9844,1.1436,0.9844c0.936,0,1.7266-0.5713,1.7266-1.3857
				V828.4746z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="41">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M2385.3711,205.7732c0,25.2659-20.48,45.75-45.75,45.75c-25.2661,0-45.75-20.4841-45.75-45.75
			c0-25.2651,20.4839-45.75,45.75-45.75C2364.8911,160.0232,2385.3711,180.5081,2385.3711,205.7732"
        />
        <g>
          <path
            d="M2318.6562,173.5151h2.5078c2.3984,0,3.917,1.54,3.917,3.9492c0,2.4097-1.5186,3.9497-3.917,3.9497h-2.5078V173.5151z
				 M2321.1641,180.501c1.793,0,2.8828-1.1992,2.8828-3.0366c0-1.8481-1.0898-3.0361-2.8936-3.0361h-1.4629v6.0728H2321.1641z"
          />
          <path d="M2325.7285,173.5151h1.0781v1.144h-1.0781V173.5151z M2326.7627,181.4141h-0.9902v-5.7539h0.9902V181.4141z" />
          <path d="M2329.3369,181.4141l-2.1572-5.7539h1.0459l1.6172,4.5439l1.5947-4.5439h1.0342l-2.1562,5.7539H2329.3369z" />
          <path
            d="M2332.4922,178.5425c0-1.7163,1.0898-2.9922,2.6514-2.9922c1.6064,0,2.4541,1.2212,2.4541,2.8164v0.3301h-4.1592
				c0.0557,1.21,0.748,1.98,1.749,1.98c0.7705,0,1.3203-0.418,1.4971-1.1001l0.8691,0.3081
				c-0.3525,1.0234-1.2217,1.6504-2.3662,1.6504C2333.5928,181.5352,2332.4922,180.314,2332.4922,178.5425z M2333.4941,177.9375
				h3.1016c-0.0215-0.814-0.4619-1.54-1.4629-1.54C2334.2969,176.3975,2333.6699,176.9585,2333.4941,177.9375z"
          />
          <path
            d="M2341.2598,176.6392c-0.165-0.0327-0.2969-0.0439-0.4844-0.0439c-0.8359,0-1.5068,0.6602-1.5068,1.6724v3.1465h-0.9902
				v-5.7539h0.9902v0.979c0.2529-0.583,0.8135-1.0229,1.5947-1.0229c0.1543,0,0.2979,0.0112,0.3965,0.022V176.6392z"
          />
          <path
            d="M2341.9307,179.6318c0.3086,0.6709,1.0127,1.0781,1.7607,1.0781c0.6709,0,1.2646-0.2861,1.2646-0.9131
				s-0.5498-0.7153-1.4961-0.9131c-0.9463-0.209-1.9365-0.418-1.9365-1.5952c0-1.0234,0.8916-1.7383,2.1123-1.7383
				c1.0342,0,1.8818,0.5059,2.2227,1.188l-0.7041,0.561c-0.2969-0.5718-0.8359-0.9351-1.5625-0.9351
				c-0.6602,0-1.0996,0.3301-1.0996,0.8472c0,0.5391,0.5059,0.6382,1.3086,0.814c0.9902,0.2314,2.124,0.4292,2.124,1.6836
				c0,1.1001-1.002,1.8262-2.2334,1.8262c-1.0459,0-2.0908-0.4512-2.5303-1.3423L2341.9307,179.6318z"
          />
          <path d="M2346.5508,173.5151h1.0781v1.144h-1.0781V173.5151z M2347.585,181.4141h-0.9902v-5.7539h0.9902V181.4141z" />
          <path
            d="M2348.8389,176.5073h-0.8916v-0.8472h0.8916v-1.6831h0.9785v1.6831h1.3096v0.8472h-1.3096v3.3774
				c0,0.605,0.2969,0.7153,0.8037,0.7153c0.2305,0,0.3955-0.0112,0.627-0.0659v0.8467c-0.2529,0.0552-0.5391,0.0884-0.8691,0.0884
				c-0.9795,0-1.54-0.3301-1.54-1.4302V176.5073z"
          />
          <path
            d="M2351.4775,178.5425c0-1.7163,1.0898-2.9922,2.6514-2.9922c1.6064,0,2.4541,1.2212,2.4541,2.8164v0.3301h-4.1592
				c0.0557,1.21,0.748,1.98,1.749,1.98c0.7705,0,1.3203-0.418,1.4971-1.1001l0.8691,0.3081
				c-0.3525,1.0234-1.2217,1.6504-2.3662,1.6504C2352.5781,181.5352,2351.4775,180.314,2351.4775,178.5425z M2352.4795,177.9375
				h3.1016c-0.0215-0.814-0.4619-1.54-1.4629-1.54C2353.2822,176.3975,2352.6553,176.9585,2352.4795,177.9375z"
          />
          <path d="M2357.2197,173.5151h1.0781v1.144h-1.0781V173.5151z M2358.2539,181.4141h-0.9902v-5.7539h0.9902V181.4141z" />
          <path
            d="M2359.5078,176.5073h-0.8916v-0.8472h0.8916v-1.6831h0.9785v1.6831h1.3096v0.8472h-1.3096v3.3774
				c0,0.605,0.2969,0.7153,0.8037,0.7153c0.2305,0,0.3955-0.0112,0.627-0.0659v0.8467c-0.2529,0.0552-0.5391,0.0884-0.8691,0.0884
				c-0.9795,0-1.54-0.3301-1.54-1.4302V176.5073z"
          />
          <path d="M2326.0928,195.4141l-2.1572-5.7539h1.0459l1.6172,4.5439l1.5947-4.5439h1.0342l-2.1562,5.7539H2326.0928z" />
          <path
            d="M2329.1943,193.8628c0-1.144,0.8916-1.6172,2.1787-1.8813l1.3965-0.2861v-0.2417c0-0.6602-0.3623-1.0342-1.1328-1.0342
				c-0.7266,0-1.1553,0.3408-1.3311,0.979l-0.9355-0.2422c0.2754-0.9351,1.1221-1.606,2.2998-1.606
				c1.2871,0,2.0791,0.627,2.0791,1.8594v2.8052c0,0.374,0.2314,0.4951,0.6162,0.4072v0.792
				c-0.8799,0.1099-1.3867-0.0991-1.5078-0.6489c-0.3516,0.4507-0.9893,0.748-1.7705,0.748
				C2330.041,195.5132,2329.1943,194.897,2329.1943,193.8628z M2332.7695,192.4658l-1.1221,0.2417
				c-0.8799,0.1763-1.4736,0.4185-1.4736,1.1113c0,0.561,0.4072,0.8911,1.0342,0.8911c0.8467,0,1.5615-0.5171,1.5615-1.2544
				V192.4658z"
          />
          <path
            d="M2334.8818,189.6602h0.9902v0.7812c0.3301-0.4731,0.9023-0.8911,1.7158-0.8911c1.2217,0,1.9141,0.8472,1.9141,2.0684
				v3.7954h-0.9893v-3.5317c0-0.8027-0.3418-1.4082-1.1992-1.4082c-0.7158,0-1.4414,0.5503-1.4414,1.5073v3.4326h-0.9902V189.6602z"
          />
          <path
            d="M2342.3066,187.5151h0.9902v2.9263c0.3301-0.4731,0.9023-0.8911,1.7158-0.8911c1.2217,0,1.9141,0.8472,1.9141,2.0684
				v3.7954h-0.9893v-3.5317c0-0.8027-0.3418-1.4082-1.1992-1.4082c-0.7158,0-1.4414,0.5503-1.4414,1.5073v3.4326h-0.9902V187.5151z"
          />
          <path
            d="M2347.5645,192.5425c0-1.7163,1.0898-2.9922,2.6514-2.9922c1.6064,0,2.4541,1.2212,2.4541,2.8164v0.3301h-4.1592
				c0.0557,1.21,0.748,1.98,1.749,1.98c0.7705,0,1.3203-0.418,1.4971-1.1001l0.8691,0.3081
				c-0.3525,1.0234-1.2217,1.6504-2.3662,1.6504C2348.665,195.5352,2347.5645,194.314,2347.5645,192.5425z M2348.5664,191.9375
				h3.1016c-0.0215-0.814-0.4619-1.54-1.4629-1.54C2349.3691,190.3975,2348.7422,190.9585,2348.5664,191.9375z"
          />
          <path
            d="M2353.5049,190.5073h-0.8916v-0.8472h0.8916v-1.6831h0.9785v1.6831h1.3096v0.8472h-1.3096v3.3774
				c0,0.605,0.2969,0.7153,0.8037,0.7153c0.2305,0,0.3955-0.0112,0.627-0.0659v0.8467c-0.2529,0.0552-0.5391,0.0884-0.8691,0.0884
				c-0.9795,0-1.54-0.3301-1.54-1.4302V190.5073z"
          />
          <path d="M2294.957,209.4141h-0.9902v-7.8989h0.9902V209.4141z" />
          <path
            d="M2295.1104,206.5425c0-1.7163,1.0889-2.9922,2.6514-2.9922c1.6064,0,2.4531,1.2212,2.4531,2.8164v0.3301h-4.1582
				c0.0547,1.21,0.748,1.98,1.749,1.98c0.7705,0,1.3203-0.418,1.4961-1.1001l0.8691,0.3081
				c-0.3516,1.0234-1.2207,1.6504-2.3652,1.6504C2296.2109,209.5352,2295.1104,208.314,2295.1104,206.5425z M2296.1113,205.9375
				h3.1025c-0.0215-0.814-0.4619-1.54-1.4629-1.54C2296.915,204.3975,2296.2881,204.9585,2296.1113,205.9375z"
          />
          <path d="M2301.8203,209.4141l-2.1562-5.7539h1.0449l1.6172,4.5439l1.5957-4.5439h1.0342l-2.1562,5.7539H2301.8203z" />
          <path
            d="M2304.4277,206.5425c0-1.7163,1.0889-2.9922,2.6514-2.9922c1.6055,0,2.4531,1.2212,2.4531,2.8164v0.3301h-4.1592
				c0.0557,1.21,0.749,1.98,1.75,1.98c0.7695,0,1.3203-0.418,1.4961-1.1001l0.8691,0.3081
				c-0.3525,1.0234-1.2217,1.6504-2.3652,1.6504C2305.5273,209.5352,2304.4277,208.314,2304.4277,206.5425z M2305.4287,205.9375
				h3.1025c-0.0225-0.814-0.4619-1.54-1.4639-1.54C2306.2314,204.3975,2305.6045,204.9585,2305.4287,205.9375z"
          />
          <path
            d="M2309.6631,203.6602h0.9902v0.7812c0.3301-0.4731,0.9023-0.8911,1.7168-0.8911c1.2207,0,1.9141,0.8472,1.9141,2.0684
				v3.7954h-0.9902v-3.5317c0-0.8027-0.3408-1.4082-1.1992-1.4082c-0.7148,0-1.4414,0.5503-1.4414,1.5073v3.4326h-0.9902V203.6602z"
          />
          <path
            d="M2314.8994,207.6318c0.3076,0.6709,1.0117,1.0781,1.7598,1.0781c0.6719,0,1.2656-0.2861,1.2656-0.9131
				s-0.5498-0.7153-1.4961-0.9131c-0.9463-0.209-1.9365-0.418-1.9365-1.5952c0-1.0234,0.8916-1.7383,2.1123-1.7383
				c1.0342,0,1.8809,0.5059,2.2227,1.188l-0.7041,0.561c-0.2969-0.5718-0.8369-0.9351-1.5625-0.9351
				c-0.6602,0-1.1006,0.3301-1.1006,0.8472c0,0.5391,0.5068,0.6382,1.3096,0.814c0.9902,0.2314,2.123,0.4292,2.123,1.6836
				c0,1.1001-1.001,1.8262-2.2334,1.8262c-1.0449,0-2.0898-0.4512-2.5303-1.3423L2314.8994,207.6318z"
          />
          <path
            d="M2320.0039,209.4141h-0.9902v-5.7539h0.9902v0.7812c0.3076-0.4731,0.8574-0.8911,1.6602-0.8911
				c0.7705,0,1.3096,0.3848,1.5957,0.979c0.3193-0.4731,0.9355-0.979,1.8369-0.979c1.1885,0,1.9033,0.8472,1.9033,2.0684v3.7954
				h-0.9902v-3.5317c0-0.8027-0.3301-1.4082-1.1436-1.4082c-0.6602,0-1.3643,0.5615-1.3643,1.4966v3.4434h-0.9902v-3.5317
				c0-0.8027-0.3301-1.4082-1.1445-1.4082c-0.6592,0-1.3633,0.5615-1.3633,1.4966V209.4141z"
          />
          <path d="M2327.4502,201.5151h1.0781v1.144h-1.0781V201.5151z M2328.4844,209.4141h-0.9902v-5.7539h0.9902V209.4141z" />
          <path
            d="M2328.6377,206.5425c0-1.6392,0.9131-2.9922,2.5088-2.9922c0.8027,0,1.4414,0.3848,1.7822,0.9463v-2.9814h0.9902v7.8989
				h-0.9902v-0.8359c-0.3408,0.5718-0.9795,0.957-1.7822,0.957C2329.5508,209.5352,2328.6377,208.1709,2328.6377,206.5425z
				 M2331.3223,204.4194c-1.0781,0-1.6826,0.8799-1.6826,2.123c0,1.2324,0.6045,2.1235,1.6826,2.1235
				c0.8145,0,1.6279-0.5942,1.6279-1.9146v-0.4067C2332.9502,205.0024,2332.1367,204.4194,2331.3223,204.4194z"
          />
          <path
            d="M2334.0596,206.5425c0-1.6392,0.9131-2.9922,2.5088-2.9922c0.8027,0,1.4414,0.3848,1.7822,0.9463v-2.9814h0.9902v7.8989
				h-0.9902v-0.8359c-0.3408,0.5718-0.9795,0.957-1.7822,0.957C2334.9727,209.5352,2334.0596,208.1709,2334.0596,206.5425z
				 M2336.7441,204.4194c-1.0781,0-1.6826,0.8799-1.6826,2.123c0,1.2324,0.6045,2.1235,1.6826,2.1235
				c0.8145,0,1.6279-0.5942,1.6279-1.9146v-0.4067C2338.3721,205.0024,2337.5586,204.4194,2336.7441,204.4194z"
          />
          <path
            d="M2339.4824,206.5425c0-1.7163,1.0898-2.9922,2.6514-2.9922c1.6064,0,2.4541,1.2212,2.4541,2.8164v0.3301h-4.1592
				c0.0557,1.21,0.748,1.98,1.749,1.98c0.7705,0,1.3203-0.418,1.4971-1.1001l0.8691,0.3081
				c-0.3525,1.0234-1.2217,1.6504-2.3662,1.6504C2340.583,209.5352,2339.4824,208.314,2339.4824,206.5425z M2340.4844,205.9375
				h3.1016c-0.0215-0.814-0.4619-1.54-1.4629-1.54C2341.2871,204.3975,2340.6602,204.9585,2340.4844,205.9375z"
          />
          <path d="M2345.709,209.4141h-0.9902v-7.8989h0.9902V209.4141z" />
          <path
            d="M2345.8613,206.5425c0-1.7163,1.0898-2.9922,2.6514-2.9922c1.6064,0,2.4541,1.2212,2.4541,2.8164v0.3301h-4.1592
				c0.0557,1.21,0.748,1.98,1.749,1.98c0.7705,0,1.3203-0.418,1.4971-1.1001l0.8691,0.3081
				c-0.3525,1.0234-1.2217,1.6504-2.3662,1.6504C2346.9619,209.5352,2345.8613,208.314,2345.8613,206.5425z M2346.8633,205.9375
				h3.1016c-0.0215-0.814-0.4619-1.54-1.4629-1.54C2347.666,204.3975,2347.0391,204.9585,2346.8633,205.9375z"
          />
          <path
            d="M2351.0977,203.6602h0.9902v0.7812c0.3301-0.4731,0.9023-0.8911,1.7158-0.8911c1.2217,0,1.9141,0.8472,1.9141,2.0684
				v3.7954h-0.9893v-3.5317c0-0.8027-0.3418-1.4082-1.1992-1.4082c-0.7158,0-1.4414,0.5503-1.4414,1.5073v3.4326h-0.9902V203.6602z"
          />
          <path
            d="M2355.75,207.8628c0-1.144,0.8916-1.6172,2.1787-1.8813l1.3965-0.2861v-0.2417c0-0.6602-0.3623-1.0342-1.1328-1.0342
				c-0.7266,0-1.1553,0.3408-1.3311,0.979l-0.9355-0.2422c0.2754-0.9351,1.1221-1.606,2.2998-1.606
				c1.2871,0,2.0791,0.627,2.0791,1.8594v2.8052c0,0.374,0.2314,0.4951,0.6162,0.4072v0.792
				c-0.8799,0.1099-1.3867-0.0991-1.5078-0.6489c-0.3516,0.4507-0.9893,0.748-1.7705,0.748
				C2356.5967,209.5132,2355.75,208.897,2355.75,207.8628z M2359.3252,206.4658l-1.1221,0.2417
				c-0.8799,0.1763-1.4736,0.4185-1.4736,1.1113c0,0.561,0.4062,0.8911,1.0342,0.8911c0.8467,0,1.5615-0.5171,1.5615-1.2544
				V206.4658z"
          />
          <path
            d="M2360.4365,207.8628c0-1.144,0.8916-1.6172,2.1787-1.8813l1.3965-0.2861v-0.2417c0-0.6602-0.3623-1.0342-1.1328-1.0342
				c-0.7266,0-1.1553,0.3408-1.3311,0.979l-0.9355-0.2422c0.2754-0.9351,1.1221-1.606,2.2998-1.606
				c1.2871,0,2.0791,0.627,2.0791,1.8594v2.8052c0,0.374,0.2314,0.4951,0.6162,0.4072v0.792
				c-0.8799,0.1099-1.3867-0.0991-1.5078-0.6489c-0.3516,0.4507-0.9893,0.748-1.7705,0.748
				C2361.2832,209.5132,2360.4365,208.897,2360.4365,207.8628z M2364.0117,206.4658l-1.1221,0.2417
				c-0.8799,0.1763-1.4736,0.4185-1.4736,1.1113c0,0.561,0.4072,0.8911,1.0342,0.8911c0.8467,0,1.5615-0.5171,1.5615-1.2544
				V206.4658z"
          />
          <path
            d="M2365.5742,203.6602h0.9902v0.7812c0.3301-0.4731,0.9023-0.8911,1.7158-0.8911c1.2217,0,1.9141,0.8472,1.9141,2.0684
				v3.7954h-0.9893v-3.5317c0-0.8027-0.3418-1.4082-1.1992-1.4082c-0.7158,0-1.4414,0.5503-1.4414,1.5073v3.4326h-0.9902V203.6602z"
          />
          <path
            d="M2371.668,208.5781v0.8359h-0.9902v-7.8989h0.9902v2.9814c0.3408-0.5615,0.9785-0.9463,1.7822-0.9463
				c1.5947,0,2.5078,1.353,2.5078,2.9922c0,1.6284-0.9131,2.9927-2.5078,2.9927
				C2372.6465,209.5352,2372.0088,209.1499,2371.668,208.5781z M2371.6455,206.7515c0,1.3203,0.8145,1.9146,1.6289,1.9146
				c1.0781,0,1.6826-0.8911,1.6826-2.1235c0-1.2432-0.6045-2.123-1.6826-2.123c-0.8145,0-1.6289,0.583-1.6289,1.9253V206.7515z"
          />
          <path
            d="M2378.3896,209.5352c-1.5732,0-2.6738-1.2104-2.6738-2.9927s1.1006-2.9922,2.6738-2.9922s2.6729,1.21,2.6729,2.9922
				S2379.9629,209.5352,2378.3896,209.5352z M2378.3896,208.688c1.0117,0,1.6719-0.8359,1.6719-2.1455
				c0-1.3198-0.6602-2.1562-1.6719-2.1562c-1.0127,0-1.6719,0.8364-1.6719,2.1562
				C2376.7178,207.8521,2377.377,208.688,2378.3896,208.688z"
          />
          <path
            d="M2380.8193,206.5425c0-1.6392,0.9131-2.9922,2.5088-2.9922c0.8027,0,1.4414,0.3848,1.7822,0.9463v-2.9814h0.9902v7.8989
				h-0.9902v-0.8359c-0.3408,0.5718-0.9795,0.957-1.7822,0.957C2381.7324,209.5352,2380.8193,208.1709,2380.8193,206.5425z
				 M2383.5039,204.4194c-1.0781,0-1.6826,0.8799-1.6826,2.123c0,1.2324,0.6045,2.1235,1.6826,2.1235
				c0.8145,0,1.6279-0.5942,1.6279-1.9146v-0.4067C2385.1318,205.0024,2384.3184,204.4194,2383.5039,204.4194z"
          />
          <path d="M2317.3477,215.5151h1.0781v1.144h-1.0781V215.5151z M2318.3818,223.4141h-0.9902v-5.7539h0.9902V223.4141z" />
          <path
            d="M2319.4814,217.6602h0.9902v0.7812c0.3301-0.4731,0.9023-0.8911,1.7158-0.8911c1.2217,0,1.9141,0.8472,1.9141,2.0684
				v3.7954h-0.9893v-3.5317c0-0.8027-0.3418-1.4082-1.1992-1.4082c-0.7158,0-1.4414,0.5503-1.4414,1.5073v3.4326h-0.9902V217.6602z"
          />
          <path
            d="M2326.5098,220.5425c0-1.6392,0.9131-2.9922,2.5088-2.9922c0.8027,0,1.4414,0.3848,1.7822,0.9463v-2.9814h0.9902v7.8989
				h-0.9902v-0.8359c-0.3408,0.5718-0.9795,0.957-1.7822,0.957C2327.4229,223.5352,2326.5098,222.1709,2326.5098,220.5425z
				 M2329.1943,218.4194c-1.0781,0-1.6826,0.8799-1.6826,2.123c0,1.2324,0.6045,2.1235,1.6826,2.1235
				c0.8145,0,1.6279-0.5942,1.6279-1.9146v-0.4067C2330.8223,219.0024,2330.0088,218.4194,2329.1943,218.4194z"
          />
          <path
            d="M2332.4824,220.5425c0-1.7163,1.0898-2.9922,2.6514-2.9922c1.6064,0,2.4541,1.2212,2.4541,2.8164v0.3301h-4.1592
				c0.0557,1.21,0.748,1.98,1.749,1.98c0.7705,0,1.3203-0.418,1.4971-1.1001l0.8691,0.3081
				c-0.3525,1.0234-1.2217,1.6504-2.3662,1.6504C2333.583,223.5352,2332.4824,222.314,2332.4824,220.5425z M2333.4844,219.9375
				h3.1016c-0.0215-0.814-0.4619-1.54-1.4629-1.54C2334.2871,218.3975,2333.6602,218.9585,2333.4844,219.9375z"
          />
          <path
            d="M2342.2402,225.5151c-1.2646,0-2.1895-0.583-2.5088-1.6392l0.9248-0.3521c0.1865,0.7373,0.7812,1.1665,1.584,1.1665
				c1.0117,0,1.6836-0.5171,1.6836-1.7163v-0.6494c-0.3086,0.561-0.957,0.9575-1.7607,0.9575c-1.5732,0-2.4971-1.2983-2.4971-2.8716
				c0-1.562,0.9238-2.8604,2.4971-2.8604c0.792,0,1.4521,0.396,1.7607,0.957v-0.8472h0.9902v5.2368
				C2344.9141,224.5801,2343.8027,225.5151,2342.2402,225.5151z M2342.3389,218.4194c-1.0449,0-1.6826,0.7812-1.6826,1.9912
				s0.6377,2.0024,1.6826,2.0024c0.8252,0,1.5957-0.6162,1.5957-1.8813v-0.209
				C2343.9346,219.0356,2343.1641,218.4194,2342.3389,218.4194z"
          />
          <path
            d="M2348.9941,218.6392c-0.165-0.0327-0.2969-0.0439-0.4844-0.0439c-0.8359,0-1.5068,0.6602-1.5068,1.6724v3.1465h-0.9902
				v-5.7539h0.9902v0.979c0.2529-0.583,0.8135-1.0229,1.5947-1.0229c0.1543,0,0.2979,0.0112,0.3965,0.022V218.6392z"
          />
          <path
            d="M2351.8096,223.5352c-1.5732,0-2.6738-1.2104-2.6738-2.9927s1.1006-2.9922,2.6738-2.9922s2.6729,1.21,2.6729,2.9922
				S2353.3828,223.5352,2351.8096,223.5352z M2351.8096,222.688c1.0117,0,1.6719-0.8359,1.6719-2.1455
				c0-1.3198-0.6602-2.1562-1.6719-2.1562c-1.0127,0-1.6719,0.8364-1.6719,2.1562
				C2350.1377,221.8521,2350.7969,222.688,2351.8096,222.688z"
          />
          <path
            d="M2355.3408,218.5073h-0.8916v-0.8472h0.8916v-1.6831h0.9785v1.6831h1.3096v0.8472h-1.3096v3.3774
				c0,0.605,0.2969,0.7153,0.8037,0.7153c0.2305,0,0.3955-0.0112,0.627-0.0659v0.8467c-0.2529,0.0552-0.5391,0.0884-0.8691,0.0884
				c-0.9795,0-1.54-0.3301-1.54-1.4302V218.5073z"
          />
          <path
            d="M2357.9805,220.5425c0-1.7163,1.0898-2.9922,2.6514-2.9922c1.6064,0,2.4541,1.2212,2.4541,2.8164v0.3301h-4.1592
				c0.0557,1.21,0.748,1.98,1.749,1.98c0.7705,0,1.3203-0.418,1.4971-1.1001l0.8691,0.3081
				c-0.3525,1.0234-1.2217,1.6504-2.3662,1.6504C2359.0811,223.5352,2357.9805,222.314,2357.9805,220.5425z M2358.9824,219.9375
				h3.1016c-0.0215-0.814-0.4619-1.54-1.4629-1.54C2359.7852,218.3975,2359.1582,218.9585,2358.9824,219.9375z"
          />
          <path
            d="M2313.2002,234.5425c0-1.6392,0.9131-2.9922,2.5088-2.9922c0.8027,0,1.4404,0.3848,1.7822,0.9463v-2.9814h0.9902v7.8989
				h-0.9902v-0.8359c-0.3418,0.5718-0.9795,0.957-1.7822,0.957C2314.1133,237.5352,2313.2002,236.1709,2313.2002,234.5425z
				 M2315.8848,232.4194c-1.0781,0-1.6836,0.8799-1.6836,2.123c0,1.2324,0.6055,2.1235,1.6836,2.1235
				c0.8145,0,1.6279-0.5942,1.6279-1.9146v-0.4067C2317.5127,233.0024,2316.6992,232.4194,2315.8848,232.4194z"
          />
          <path
            d="M2319.1738,234.5425c0-1.7163,1.0889-2.9922,2.6504-2.9922c1.6064,0,2.4541,1.2212,2.4541,2.8164v0.3301h-4.1592
				c0.0557,1.21,0.749,1.98,1.749,1.98c0.7705,0,1.3203-0.418,1.4971-1.1001l0.8691,0.3081
				c-0.3525,1.0234-1.2217,1.6504-2.3662,1.6504C2320.2734,237.5352,2319.1738,236.314,2319.1738,234.5425z M2320.1748,233.9375
				h3.1016c-0.0215-0.814-0.4619-1.54-1.4629-1.54C2320.9775,232.3975,2320.3506,232.9585,2320.1748,233.9375z"
          />
          <path
            d="M2325.1133,232.5073h-0.8916v-0.8472h0.8916v-1.6831h0.9785v1.6831h1.3096v0.8472h-1.3096v3.3774
				c0,0.605,0.2969,0.7153,0.8037,0.7153c0.2305,0,0.3955-0.0112,0.627-0.0659v0.8467c-0.2529,0.0552-0.5391,0.0884-0.8691,0.0884
				c-0.9795,0-1.54-0.3301-1.54-1.4302V232.5073z"
          />
          <path
            d="M2327.6973,235.8628c0-1.144,0.8916-1.6172,2.1787-1.8813l1.3965-0.2861v-0.2417c0-0.6602-0.3623-1.0342-1.1328-1.0342
				c-0.7266,0-1.1553,0.3408-1.3311,0.979l-0.9355-0.2422c0.2754-0.9351,1.1221-1.606,2.2998-1.606
				c1.2871,0,2.0791,0.627,2.0791,1.8594v2.8052c0,0.374,0.2314,0.4951,0.6162,0.4072v0.792
				c-0.8799,0.1099-1.3867-0.0991-1.5078-0.6489c-0.3516,0.4507-0.9893,0.748-1.7705,0.748
				C2328.5439,237.5132,2327.6973,236.897,2327.6973,235.8628z M2331.2725,234.4658l-1.1221,0.2417
				c-0.8799,0.1763-1.4736,0.4185-1.4736,1.1113c0,0.561,0.4062,0.8911,1.0342,0.8911c0.8467,0,1.5615-0.5171,1.5615-1.2544
				V234.4658z"
          />
          <path d="M2333.3408,229.5151h1.0781v1.144h-1.0781V229.5151z M2334.375,237.4141h-0.9902v-5.7539h0.9902V237.4141z" />
          <path d="M2336.4648,237.4141h-0.9902v-7.8989h0.9902V237.4141z" />
          <path
            d="M2337.5645,229.5151h0.9902v2.9263c0.3301-0.4731,0.9023-0.8911,1.7158-0.8911c1.2217,0,1.9141,0.8472,1.9141,2.0684
				v3.7954h-0.9893v-3.5317c0-0.8027-0.3418-1.4082-1.1992-1.4082c-0.7158,0-1.4414,0.5503-1.4414,1.5073v3.4326h-0.9902V229.5151z"
          />
          <path
            d="M2342.7676,235.8628c0-1.144,0.8916-1.6172,2.1787-1.8813l1.3965-0.2861v-0.2417c0-0.6602-0.3623-1.0342-1.1328-1.0342
				c-0.7266,0-1.1553,0.3408-1.3311,0.979l-0.9355-0.2422c0.2754-0.9351,1.1221-1.606,2.2998-1.606
				c1.2871,0,2.0791,0.627,2.0791,1.8594v2.8052c0,0.374,0.2314,0.4951,0.6162,0.4072v0.792
				c-0.8799,0.1099-1.3867-0.0991-1.5078-0.6489c-0.3516,0.4507-0.9893,0.748-1.7705,0.748
				C2343.6143,237.5132,2342.7676,236.897,2342.7676,235.8628z M2346.3428,234.4658l-1.1221,0.2417
				c-0.8799,0.1763-1.4736,0.4185-1.4736,1.1113c0,0.561,0.4062,0.8911,1.0342,0.8911c0.8467,0,1.5615-0.5171,1.5615-1.2544
				V234.4658z"
          />
          <path
            d="M2348.4541,231.6602h0.9902v0.7812c0.3301-0.4731,0.9023-0.8911,1.7158-0.8911c1.2217,0,1.9141,0.8472,1.9141,2.0684
				v3.7954h-0.9893v-3.5317c0-0.8027-0.3418-1.4082-1.1992-1.4082c-0.7158,0-1.4414,0.5503-1.4414,1.5073v3.4326h-0.9902V231.6602z"
          />
          <path
            d="M2353.7119,234.5425c0-1.6392,0.9131-2.9922,2.5088-2.9922c0.8027,0,1.4414,0.3848,1.7822,0.9463v-2.9814h0.9902v7.8989
				h-0.9902v-0.8359c-0.3408,0.5718-0.9795,0.957-1.7822,0.957C2354.625,237.5352,2353.7119,236.1709,2353.7119,234.5425z
				 M2356.3965,232.4194c-1.0781,0-1.6826,0.8799-1.6826,2.123c0,1.2324,0.6045,2.1235,1.6826,2.1235
				c0.8145,0,1.6279-0.5942,1.6279-1.9146v-0.4067C2358.0244,233.0024,2357.2109,232.4194,2356.3965,232.4194z"
          />
          <path
            d="M2359.6846,234.5425c0-1.7163,1.0898-2.9922,2.6514-2.9922c1.6064,0,2.4541,1.2212,2.4541,2.8164v0.3301h-4.1592
				c0.0557,1.21,0.748,1.98,1.749,1.98c0.7705,0,1.3203-0.418,1.4971-1.1001l0.8691,0.3081
				c-0.3525,1.0234-1.2217,1.6504-2.3662,1.6504C2360.7852,237.5352,2359.6846,236.314,2359.6846,234.5425z M2360.6865,233.9375
				h3.1016c-0.0215-0.814-0.4619-1.54-1.4629-1.54C2361.4893,232.3975,2360.8623,232.9585,2360.6865,233.9375z"
          />
          <path d="M2366.4609,237.4141h-0.9902v-7.8989h0.9902V237.4141z" />
        </g>
      </g>
      <g onClick={handleClick} id="40">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1354.09,674.824c0,25.2661-20.481,45.75-45.75,45.75c-25.266,0-45.75-20.4839-45.75-45.75
			c0-25.2688,20.484-45.75,45.75-45.75C1333.609,629.074,1354.09,649.5552,1354.09,674.824"
        />
        <g>
          <path d="M1297.3774,657.8506h-1.0093l-4.7671-7.0293v7.0293h-1.1187v-8.7324h1.228l4.5361,6.75v-6.75h1.1309V657.8506z" />
          <path d="M1299.2041,649.1182h1.1919v1.2656h-1.1919V649.1182z M1300.3477,657.8506h-1.0947v-6.3604h1.0947V657.8506z" />
          <path d="M1303.8018,657.8506l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1303.8018z" />
          <path
            d="M1307.9019,654.6758c0-1.8965,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1885,1.9336,2.1885c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1309.1182,657.9844,1307.9019,656.6338,1307.9019,654.6758z M1309.0088,654.0078
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1309.8965,652.3047,1309.2031,652.9248,1309.0088,654.0078z"
          />
          <path
            d="M1314.4102,656.1357c0-1.2646,0.9849-1.7881,2.4077-2.0801l1.5444-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0332,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1006c0,0.4141,0.2554,0.5479,0.6812,0.4502v0.876
				c-0.9731,0.1211-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1315.3467,657.96,1314.4102,657.2783,1314.4102,656.1357z M1318.3623,654.5908l-1.2402,0.2676
				c-0.9731,0.1953-1.6299,0.4629-1.6299,1.2285c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5723,1.7266-1.3867
				V654.5908z"
          />
          <path
            d="M1326.354,657.8506h-1.0942v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8853,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8994,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5933-0.5957,1.5933-1.6533v-3.8311h1.0942V657.8506z"
          />
          <path d="M1279.041,671.8506l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1279.041z" />
          <path
            d="M1283.0806,670.1357c0-1.2646,0.9849-1.7881,2.4077-2.0801l1.5444-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0332,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1006c0,0.4141,0.2554,0.5479,0.6812,0.4502v0.876
				c-0.9731,0.1211-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1284.0171,671.96,1283.0806,671.2783,1283.0806,670.1357z M1287.0327,668.5908l-1.2402,0.2676
				c-0.9731,0.1953-1.6299,0.4629-1.6299,1.2285c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5723,1.7266-1.3867
				V668.5908z"
          />
          <path
            d="M1289.9775,665.4902h1.0947v0.8633c0.3647-0.5234,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1963h-1.0947v-3.9043c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6084-1.5928,1.666v3.7949h-1.0947V665.4902z"
          />
          <path
            d="M1301.8374,674.1729c-1.3984,0-2.4199-0.6445-2.7725-1.8115l1.0215-0.3896c0.2065,0.8154,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.8965v-0.7178c-0.3408,0.6201-1.0581,1.0576-1.9458,1.0576
				c-1.7393,0-2.7607-1.4346-2.7607-3.1738c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7881C1304.7925,673.1396,1303.5645,674.1729,1301.8374,674.1729z M1301.9468,666.3291
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2314
				C1303.7104,667.0098,1302.8589,666.3291,1301.9468,666.3291z"
          />
          <path
            d="M1306.1807,668.6758c0-1.8965,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1885,1.9336,2.1885c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1307.397,671.9844,1306.1807,670.6338,1306.1807,668.6758z M1307.2876,668.0078
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1308.1753,666.3047,1307.4819,666.9248,1307.2876,668.0078z"
          />
          <path
            d="M1314.2822,670.9258v0.9248h-1.0947v-8.7324h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3076c0,1.8008-1.0093,3.3086-2.7729,3.3086
				C1315.3643,671.9844,1314.6592,671.5586,1314.2822,670.9258z M1314.2578,668.9072c0,1.459,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8604-0.9854,1.8604-2.3477c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V668.9072z"
          />
          <path
            d="M1323.709,666.5723c-0.1826-0.0361-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0117,0.438,0.0244V666.5723z"
          />
          <path
            d="M1329.9609,671.8506h-1.0947v-0.876c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8994,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V671.8506z"
          />
          <path d="M1331.7261,663.1182h1.1919v1.2656h-1.1919V663.1182z M1332.8696,671.8506h-1.0947v-6.3604h1.0947V671.8506z" />
          <path
            d="M1335.7891,668.457l2.8213-2.9668h1.3618l-2.481,2.5049l2.688,3.8555h-1.2769l-2.1895-3.0645l-0.9238,0.9355v2.1289
				h-1.0947v-8.7324h1.0947V668.457z"
          />
          <path
            d="M1291.376,677.9824c-0.1826-0.0244-0.292-0.0244-0.4746-0.0244c-0.6445,0-0.9971,0.2793-0.9971,1.0938v0.4385h1.4106
				v0.9365h-1.4106v5.4238h-1.0947v-5.4238h-0.9604v-0.9365h0.9604v-0.5107c0-1.2402,0.6812-1.9951,1.9824-1.9951
				c0.2188,0,0.3403,0.0127,0.584,0.0244V677.9824z"
          />
          <path
            d="M1294.855,685.9844c-1.7393,0-2.9556-1.3379-2.9556-3.3086c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1297.8101,684.6465,1296.5938,685.9844,1294.855,685.9844z M1294.855,685.0479
				c1.1187,0,1.8481-0.9248,1.8481-2.3721c0-1.459-0.7295-2.3828-1.8481-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C1293.0063,684.123,1293.7358,685.0479,1294.855,685.0479z"
          />
          <path
            d="M1299.3442,683.8799c0.3403,0.7422,1.1187,1.1924,1.9458,1.1924c0.7417,0,1.3984-0.3164,1.3984-1.0098
				s-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4629-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6318-0.9238-1.0332-1.7266-1.0332
				c-0.73,0-1.2163,0.3643-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4844L1299.3442,683.8799z"
          />
          <path
            d="M1305.2563,683.8799c0.3403,0.7422,1.1187,1.1924,1.9458,1.1924c0.7417,0,1.3984-0.3164,1.3984-1.0098
				s-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4629-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6318-0.9238-1.0332-1.7266-1.0332
				c-0.73,0-1.2163,0.3643-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4844L1305.2563,683.8799z"
          />
          <path d="M1310.9736,677.1182h1.1919v1.2656h-1.1919V677.1182z M1312.1172,685.8506h-1.0947v-6.3604h1.0947V685.8506z" />
          <path
            d="M1313.5039,682.6758c0-1.8965,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1885,1.9336,2.1885c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1314.7202,685.9844,1313.5039,684.6338,1313.5039,682.6758z M1314.6108,682.0078
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1315.4985,680.3047,1314.8052,680.9248,1314.6108,682.0078z"
          />
          <path d="M1321.6055,685.8506h-1.0947v-8.7324h1.0947V685.8506z" />
          <path
            d="M1322.9922,682.6758c0-1.8965,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1885,1.9336,2.1885c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1324.2085,685.9844,1322.9922,684.6338,1322.9922,682.6758z M1324.0991,682.0078
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1324.9868,680.3047,1324.2935,680.9248,1324.0991,682.0078z"
          />
          <path
            d="M1288.2622,696.6758c0-1.8965,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1885,1.9336,2.1885c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1289.4785,699.9844,1288.2622,698.6338,1288.2622,696.6758z M1289.3691,696.0078
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1290.2568,694.3047,1289.5635,694.9248,1289.3691,696.0078z"
          />
          <path
            d="M1295.269,693.4902h1.0947v0.8633c0.3647-0.5234,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1963h-1.0947v-3.9043c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6084-1.5928,1.666v3.7949h-1.0947V693.4902z"
          />
          <path
            d="M1301.6919,696.6758c0-1.8965,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1885,1.9336,2.1885c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1302.9082,699.9844,1301.6919,698.6338,1301.6919,696.6758z M1302.7988,696.0078
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1303.6865,694.3047,1302.9932,694.9248,1302.7988,696.0078z"
          />
          <path
            d="M1311.9946,694.5723c-0.1826-0.0361-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0117,0.438,0.0244V694.5723z"
          />
          <path
            d="M1315.6318,702.1729c-1.3984,0-2.4199-0.6445-2.7725-1.8115l1.0215-0.3896c0.2065,0.8154,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.8965v-0.7178c-0.3403,0.6201-1.0581,1.0576-1.9458,1.0576
				c-1.7393,0-2.7607-1.4346-2.7607-3.1738c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.6055,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7881C1318.5869,701.1396,1317.3589,702.1729,1315.6318,702.1729z M1315.7412,694.3291
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2314
				C1317.5049,695.0098,1316.6533,694.3291,1315.7412,694.3291z"
          />
          <path d="M1320.3643,691.1182h1.1919v1.2656h-1.1919V691.1182z M1321.5078,699.8506h-1.0947v-6.3604h1.0947V699.8506z" />
          <path
            d="M1322.8945,696.6758c0-1.8965,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1885,1.9336,2.1885c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1324.1108,699.9844,1322.8945,698.6338,1322.8945,696.6758z M1324.0015,696.0078
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1324.8892,694.3047,1324.1958,694.9248,1324.0015,696.0078z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="39">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M2248.3589,2735.105c0,25.27-20.4839,45.75-45.75,45.75c-25.2688,0-45.75-20.48-45.75-45.75
			c0-25.2649,20.4812-45.75,45.75-45.75C2227.875,2689.355,2248.3589,2709.8401,2248.3589,2735.105"
        />
        <g>
          <path
            d="M2172.9932,2716.2666c2.2871,0,3.9531,1.8359,3.9531,4.499c0,2.6641-1.666,4.5-3.9531,4.5
				c-2.2861,0-3.9639-1.8359-3.9639-4.5C2169.0293,2718.1025,2170.707,2716.2666,2172.9932,2716.2666z M2172.9932,2717.2998
				c-1.6416,0-2.7969,1.3984-2.7969,3.4658s1.1553,3.4658,2.7969,3.4658c1.6426,0,2.7979-1.3984,2.7979-3.4658
				S2174.6357,2717.2998,2172.9932,2717.2998z"
          />
          <path
            d="M2179.418,2725.1318h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0332-1.083,2.0312-1.083c1.3125,0,2.1035,0.9365,2.1035,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V2725.1318z"
          />
          <path d="M2190.5488,2725.1318l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2190.5488z" />
          <path
            d="M2194.5869,2723.417c0-1.2646,0.9854-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2195.5234,2725.2412,2194.5869,2724.5605,2194.5869,2723.417z M2198.54,2721.873l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2721.873z"
          />
          <path
            d="M2201.4844,2718.7715h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2718.7715z"
          />
          <path
            d="M2210.7783,2727.4551c-1.3994,0-2.4209-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5723,1.8604-1.8975v-0.7178c-0.3408,0.6201-1.0576,1.0586-1.9463,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1748c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.4385,1.9463,1.0586v-0.9365
				h1.0947v5.7891C2213.7334,2726.4209,2212.5049,2727.4551,2210.7783,2727.4551z M2210.8877,2719.6104
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012s0.7061,2.2139,1.8613,2.2139c0.9121,0,1.7627-0.6816,1.7627-2.0801v-0.2305
				C2212.6504,2720.292,2211.7998,2719.6104,2210.8877,2719.6104z"
          />
          <path d="M2219.7559,2725.1318l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2219.7559z" />
          <path
            d="M2223.7939,2723.417c0-1.2646,0.9854-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2224.7305,2725.2412,2223.7939,2724.5605,2223.7939,2723.417z M2227.7471,2721.873l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2721.873z"
          />
          <path
            d="M2230.6914,2718.7715h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2718.7715z"
          />
          <path
            d="M2170.6289,2730.4004h1.0947v3.2344c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2730.4004z"
          />
          <path
            d="M2177.0527,2735.958c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2178.2686,2739.2656,2177.0527,2737.916,2177.0527,2735.958z M2178.1592,2735.2891
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2179.0469,2733.5859,2178.3535,2734.2061,2178.1592,2735.2891z"
          />
          <path
            d="M2184.2295,2733.708h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V2733.708z"
          />
          <path
            d="M2190.9072,2737.1621c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L2190.9072,2737.1621z"
          />
          <path
            d="M2196.8438,2733.708h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V2733.708z"
          />
          <path
            d="M2200.3721,2735.958c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2201.5879,2739.2656,2200.3721,2737.916,2200.3721,2735.958z M2201.4785,2735.2891
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2202.3662,2733.5859,2201.6729,2734.2061,2201.4785,2735.2891z"
          />
          <path
            d="M2206.9414,2735.958c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2207.9502,2739.2656,2206.9414,2737.7578,2206.9414,2735.958z
				 M2209.9082,2733.6104c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2211.708,2734.2549,2210.8086,2733.6104,2209.9082,2733.6104z"
          />
          <path
            d="M2214.1553,2735.958c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2215.3711,2739.2656,2214.1553,2737.916,2214.1553,2735.958z M2215.2617,2735.2891
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2216.1494,2733.5859,2215.4561,2734.2061,2215.2617,2735.2891z"
          />
          <path d="M2222.2568,2739.1318h-1.0947v-8.7314h1.0947V2739.1318z" />
          <path d="M2224.0322,2730.4004h1.1924v1.2646h-1.1924V2730.4004z M2225.1758,2739.1318h-1.0947v-6.3604h1.0947V2739.1318z" />
          <path
            d="M2227,2732.7715h1.0947v6.8828c0,1.1318-0.7061,1.7031-1.7881,1.7031c-0.2549,0-0.4131-0.0127-0.6074-0.0361v-0.9492
				c0.1328,0.0244,0.2432,0.0371,0.4248,0.0371c0.6328,0,0.876-0.3047,0.876-0.9492V2732.7715z M2226.9512,2730.4004h1.1924v1.2646
				h-1.1924V2730.4004z"
          />
          <path
            d="M2231.0137,2735.7393l2.8213-2.9678h1.3623l-2.4814,2.5049l2.6875,3.8555h-1.2764l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V2735.7393z"
          />
          <path
            d="M2161.0918,2744.4004h1.0947v3.2344c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2744.4004z"
          />
          <path d="M2167.418,2744.4004h1.1924v1.2646h-1.1924V2744.4004z M2168.5615,2753.1318h-1.0947v-6.3604h1.0947V2753.1318z" />
          <path
            d="M2170.0684,2747.708h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V2747.708z"
          />
          <path
            d="M2173.7178,2747.708h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V2747.708z"
          />
          <path
            d="M2176.7598,2749.958c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2177.9756,2753.2656,2176.7598,2751.916,2176.7598,2749.958z M2177.8662,2749.2891
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2178.7539,2747.5859,2178.0605,2748.2061,2177.8662,2749.2891z"
          />
          <path d="M2182.9883,2749.0947h3.3203v0.9844h-3.3203V2749.0947z" />
          <path
            d="M2186.917,2749.958c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2188.1328,2753.2656,2186.917,2751.916,2186.917,2749.958z M2188.0234,2749.2891
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2188.9111,2747.5859,2188.2178,2748.2061,2188.0234,2749.2891z"
          />
          <path d="M2193.3887,2744.4004h1.1924v1.2646h-1.1924V2744.4004z M2194.5322,2753.1318h-1.0947v-6.3604h1.0947V2753.1318z" />
          <path d="M2196.9648,2753.1318h-1.0947v-8.7314h1.0947V2753.1318z" />
          <path
            d="M2197.8037,2751.417c0-1.2646,0.9854-1.7881,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2198.7402,2753.2412,2197.8037,2752.5605,2197.8037,2751.417z M2201.7568,2749.873l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V2749.873z"
          />
          <path
            d="M2204.2139,2746.7715h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2746.7715z"
          />
          <path
            d="M2210.1514,2749.958c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2211.1602,2753.2656,2210.1514,2751.7578,2210.1514,2749.958z
				 M2213.1182,2747.6104c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2214.918,2748.2549,2214.0186,2747.6104,2213.1182,2747.6104z"
          />
          <path
            d="M2216.8789,2749.958c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2218.0947,2753.2656,2216.8789,2751.916,2216.8789,2749.958z M2217.9854,2749.2891
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2218.873,2747.5859,2218.1797,2748.2061,2217.9854,2749.2891z"
          />
          <path
            d="M2226.1719,2745.2637c-0.1816-0.0244-0.292-0.0244-0.4736-0.0244c-0.6445,0-0.998,0.2793-0.998,1.0947v0.4375h1.4111
				v0.9365h-1.4111v5.4238h-1.0938v-5.4238h-0.9609v-0.9365h0.9609v-0.5107c0-1.2402,0.6807-1.9941,1.9824-1.9941
				c0.2188,0,0.3398,0.0117,0.583,0.0244V2745.2637z"
          />
          <path
            d="M2229.4189,2745.2637c-0.1826-0.0244-0.292-0.0244-0.4736-0.0244c-0.6445,0-0.998,0.2793-0.998,1.0947v0.4375h1.4111
				v0.9365h-1.4111v5.4238h-1.0938v-5.4238h-0.9609v-0.9365h0.9609v-0.5107c0-1.2402,0.6807-1.9941,1.9824-1.9941
				c0.2188,0,0.3398,0.0117,0.583,0.0244V2745.2637z"
          />
          <path
            d="M2229.457,2749.958c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2230.6729,2753.2656,2229.457,2751.916,2229.457,2749.958z M2230.5635,2749.2891
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2231.4512,2747.5859,2230.7578,2748.2061,2230.5635,2749.2891z"
          />
          <path
            d="M2238.5439,2752.3047c0.875,0,1.3984-0.5596,1.5811-1.3496l0.9727,0.4502c-0.3047,1.0576-1.2285,1.8604-2.5537,1.8604
				c-1.7764,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2275-3.3086,3.0039-3.3086c1.3252,0,2.2256,0.7666,2.5293,1.8242
				l-0.9482,0.4746c-0.1826-0.7783-0.7061-1.3496-1.5811-1.3496c-1.1318,0-1.9102,0.8994-1.9102,2.3594
				C2236.6338,2751.4053,2237.4121,2752.3047,2238.5439,2752.3047z"
          />
          <path
            d="M2241.9854,2747.708h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V2747.708z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="38">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3845.9609,751.457c0,25.27-20.4839,45.75-45.75,45.75c-25.2659,0-45.75-20.48-45.75-45.75
			c0-25.2659,20.4841-45.75,45.75-45.75C3825.4771,705.707,3845.9609,726.1912,3845.9609,751.457"
        />
        <g>
          <path d="M3757.8291,755.4834h-1.167v-8.7319h1.167V755.4834z" />
          <path
            d="M3759.7031,749.123h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V749.123z"
          />
          <path
            d="M3767.6592,752.0903l2.8213-2.9673h1.3623l-2.4814,2.5054l2.6875,3.855h-1.2764l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V752.0903z"
          />
          <path
            d="M3775.5781,755.6172c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3778.5332,754.2793,3777.3174,755.6172,3775.5781,755.6172z M3775.5781,754.6807
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3773.7295,753.7563,3774.459,754.6807,3775.5781,754.6807z"
          />
          <path
            d="M3781.0166,755.4834h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0312-1.0825c1.3135,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V755.4834z"
          />
          <path
            d="M3790.0801,752.3091c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3791.2959,755.6172,3790.0801,754.2671,3790.0801,752.3091z M3791.1865,751.6406
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3792.0742,749.938,3791.3809,750.5581,3791.1865,751.6406z"
          />
          <path
            d="M3797.0869,749.123h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V749.123z"
          />
          <path
            d="M3804.0928,753.5132c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L3804.0928,753.5132z"
          />
          <path
            d="M3809.8594,749.123h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V749.123z"
          />
          <path d="M3816.6719,746.7515h1.1924v1.2651h-1.1924V746.7515z M3817.8154,755.4834h-1.0947v-6.3604h1.0947V755.4834z" />
          <path d="M3821.2695,755.4834l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3821.2695z" />
          <path
            d="M3825.3691,752.3091c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3826.585,755.6172,3825.3691,754.2671,3825.3691,752.3091z M3826.4756,751.6406
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3827.3633,749.938,3826.6699,750.5581,3826.4756,751.6406z"
          />
          <path
            d="M3831.877,753.7686c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3832.8135,755.5928,3831.877,754.9116,3831.877,753.7686z M3835.8301,752.2241l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V752.2241z"
          />
          <path
            d="M3843.8213,755.4834h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V755.4834z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="37">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M1829.2581,1055.0389c0,25.2661-20.4851,45.75-45.75,45.75c-25.2661,0-45.75-20.4839-45.75-45.75
			c0-25.266,20.4839-45.7499,45.75-45.7499C1808.7729,1009.2891,1829.2581,1029.7729,1829.2581,1055.0389"
        />
        <g>
          <path
            d="M1750,1029.3335l3.3687,8.7319h-1.1797l-0.8877-2.2983h-3.916l-0.8755,2.2983h-1.168l3.3691-8.7319H1750z
				 M1747.7744,1034.7456h3.1377l-1.5811-4.0986L1747.7744,1034.7456z"
          />
          <path
            d="M1753.979,1036.3506c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1754.9155,1038.1748,1753.979,1037.4937,1753.979,1036.3506z M1757.9312,1034.8062l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1034.8062z"
          />
          <path
            d="M1760.876,1031.7051h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1031.7051z"
          />
          <path
            d="M1771.6895,1031.7051l1.313,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H1771.6895z"
          />
          <path
            d="M1776.0571,1034.8911c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1777.2734,1038.1992,1776.0571,1036.8491,1776.0571,1034.8911z
				 M1777.1641,1034.2227h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1778.0518,1032.52,1777.3584,1033.1401,1777.1641,1034.2227z"
          />
          <path d="M1787.6611,1038.0654h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8394l-3.5996,4.5845h3.6362V1038.0654z" />
          <path d="M1789.0366,1029.3335h1.1919v1.2651h-1.1919V1029.3335z M1790.1802,1038.0654h-1.0947v-6.3604h1.0947V1038.0654z" />
          <path
            d="M1794.437,1040.3882c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3408,0.6206-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C1797.3921,1039.3545,1796.1641,1040.3882,1794.437,1040.3882z M1794.5464,1032.5444
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C1796.3101,1033.2251,1795.4585,1032.5444,1794.5464,1032.5444z"
          />
          <path
            d="M1799.2183,1029.3335h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1029.3335z"
          />
          <path
            d="M1805.6411,1034.8911c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1806.8574,1038.1992,1805.6411,1036.8491,1805.6411,1034.8911z M1806.748,1034.2227
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1807.6357,1032.52,1806.9424,1033.1401,1806.748,1034.2227z"
          />
          <path d="M1812.5991,1029.3335h1.1919v1.2651h-1.1919V1029.3335z M1813.7427,1038.0654h-1.0947v-6.3604h1.0947V1038.0654z" />
          <path
            d="M1815.1294,1034.8911c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1816.1392,1038.1992,1815.1294,1036.6914,1815.1294,1034.8911z
				 M1818.0967,1032.5444c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1819.897,1033.189,1818.9971,1032.5444,1818.0967,1032.5444z"
          />
          <path d="M1758.9717,1052.0654l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1758.9717z" />
          <path
            d="M1763.0112,1050.3506c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1763.9478,1052.1748,1763.0112,1051.4937,1763.0112,1050.3506z M1766.9634,1048.8062l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1048.8062z"
          />
          <path
            d="M1769.9082,1045.7051h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1045.7051z"
          />
          <path d="M1780.4307,1052.0654h-1.0947v-8.7319h1.0947V1052.0654z" />
          <path
            d="M1784.7729,1052.1992c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1787.728,1050.8613,1786.5117,1052.1992,1784.7729,1052.1992z M1784.7729,1051.2627
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1782.9243,1050.3384,1783.6538,1051.2627,1784.7729,1051.2627z"
          />
          <path
            d="M1790.2109,1048.6724l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V1048.6724z"
          />
          <path
            d="M1795.1138,1050.3506c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1796.0503,1052.1748,1795.1138,1051.4937,1795.1138,1050.3506z M1799.0659,1048.8062l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1048.8062z"
          />
          <path d="M1803.1055,1052.0654h-1.0947v-8.7319h1.0947V1052.0654z" />
          <path
            d="M1804.4922,1048.8911c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1805.7085,1052.1992,1804.4922,1050.8491,1804.4922,1048.8911z
				 M1805.5991,1048.2227h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1806.4868,1046.52,1805.7935,1047.1401,1805.5991,1048.2227z"
          />
          <path d="M1750.5356,1066.0654l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1750.5356z" />
          <path
            d="M1754.6357,1062.8911c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1755.8521,1066.1992,1754.6357,1064.8491,1754.6357,1062.8911z
				 M1755.7427,1062.2227h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1756.6304,1060.52,1755.937,1061.1401,1755.7427,1062.2227z"
          />
          <path
            d="M1764.9385,1060.7876c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1060.7876z"
          />
          <path
            d="M1770.0957,1059.7051l1.3135,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H1770.0957z"
          />
          <path
            d="M1774.4639,1062.8911c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1775.6802,1066.1992,1774.4639,1064.8491,1774.4639,1062.8911z
				 M1775.5708,1062.2227H1779c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1776.4585,1060.52,1775.7651,1061.1401,1775.5708,1062.2227z"
          />
          <path
            d="M1784.7666,1060.7876c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1060.7876z"
          />
          <path
            d="M1787.0664,1062.6724l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V1062.6724z"
          />
          <path d="M1792.4189,1057.3335h1.1919v1.2651h-1.1919V1057.3335z M1793.5625,1066.0654h-1.0947v-6.3604h1.0947V1066.0654z" />
          <path
            d="M1795.3872,1059.7051h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1059.7051z"
          />
          <path
            d="M1804.6802,1068.3882c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3408,0.6206-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C1807.6353,1067.3545,1806.4072,1068.3882,1804.6802,1068.3882z M1804.7896,1060.5444
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C1806.5532,1061.2251,1805.7017,1060.5444,1804.7896,1060.5444z"
          />
          <path
            d="M1809.6074,1064.0952c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9243-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1809.6074,1064.0952z"
          />
          <path d="M1815.0815,1062.0278h3.3203v0.9854h-3.3203V1062.0278z" />
          <path d="M1747.1177,1071.3335h1.1919v1.2651h-1.1919V1071.3335z M1748.2612,1080.0654h-1.0947v-6.3604h1.0947V1080.0654z" />
          <path
            d="M1750.0859,1073.7051h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1073.7051z"
          />
          <path
            d="M1759.7197,1072.1973c-0.1826-0.0244-0.292-0.0244-0.4746-0.0244c-0.6445,0-0.9971,0.2798-0.9971,1.0942v0.438h1.4106
				v0.9365h-1.4106v5.4238h-1.0947v-5.4238h-0.9604v-0.9365h0.9604v-0.5107c0-1.2402,0.6812-1.9946,1.9824-1.9946
				c0.2188,0,0.3403,0.0122,0.584,0.0244V1072.1973z"
          />
          <path
            d="M1763.9771,1074.7876c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1074.7876z"
          />
          <path
            d="M1764.6836,1078.3506c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1765.6201,1080.1748,1764.6836,1079.4937,1764.6836,1078.3506z M1768.6357,1076.8062l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1076.8062z"
          />
          <path
            d="M1771.7266,1078.0952c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9243-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1771.7266,1078.0952z"
          />
          <path
            d="M1777.6631,1074.6416h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1074.6416z"
          />
          <path
            d="M1784.9243,1074.7876c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1074.7876z"
          />
          <path
            d="M1791.1763,1080.0654h-1.0942v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8853,1.0093
				c-1.3618,0-2.1284-0.9365-2.1284-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083
				c0.7905,0,1.5933-0.5962,1.5933-1.6543v-3.8306h1.0942V1080.0654z"
          />
          <path
            d="M1795.5562,1079.2383c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9731,0.4497c-0.3042,1.0581-1.2285,1.8608-2.5542,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1793.647,1078.3384,1794.4253,1079.2383,1795.5562,1079.2383z"
          />
          <path
            d="M1799.4863,1074.6416h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1074.6416z"
          />
          <path
            d="M1808.4985,1080.0654h-1.0942v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8853,1.0093
				c-1.3618,0-2.1284-0.9365-2.1284-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083
				c0.7905,0,1.5933-0.5962,1.5933-1.6543v-3.8306h1.0942V1080.0654z"
          />
          <path
            d="M1815.3594,1080.0654h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093
				c-1.3618,0-2.1284-0.9365-2.1284-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083
				c0.7905,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1080.0654z"
          />
          <path
            d="M1820.4692,1074.7876c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1074.7876z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="36">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M1526.527,2433.551c0,25.2649-20.48,45.75-45.75,45.75c-25.265,0-45.75-20.4851-45.75-45.75
			c0-25.2661,20.485-45.75,45.75-45.75C1506.047,2387.801,1526.527,2408.2849,1526.527,2433.551"
        />
        <g>
          <path
            d="M1446.3193,2422.6768c1.1313,0,1.9458-0.6562,2.2622-1.7627l1.1064,0.3643c-0.5107,1.5088-1.7026,2.4326-3.3687,2.4326
				c-2.2617,0-3.9277-1.8359-3.9277-4.5c0-2.6631,1.666-4.499,3.9277-4.499c1.666,0,2.8579,0.9238,3.3687,2.4316l-1.1064,0.3652
				c-0.3164-1.1064-1.1309-1.7637-2.2622-1.7637c-1.6172,0-2.7603,1.3984-2.7603,3.4658S1444.7021,2422.6768,1446.3193,2422.6768z"
          />
          <path
            d="M1453.3877,2423.7109c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1456.3428,2422.373,1455.1265,2423.7109,1453.3877,2423.7109z M1453.3877,2422.7744
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1451.5391,2421.8506,1452.2686,2422.7744,1453.3877,2422.7744z"
          />
          <path
            d="M1458.8257,2423.5771h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0337-1.083,2.0308-1.083c1.3135,0,2.104,0.9365,2.104,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V2423.5771z"
          />
          <path
            d="M1469.4209,2422.6533v3.1133h-1.0947v-8.5498h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C1470.5029,2423.7109,1469.7979,2423.2852,1469.4209,2422.6533z M1469.3965,2420.6338c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8608-0.9854,1.8608-2.3467c0-1.374-0.6689-2.3477-1.8608-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V2420.6338z"
          />
          <path
            d="M1478.0693,2423.7109c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1481.0244,2422.373,1479.8081,2423.7109,1478.0693,2423.7109z M1478.0693,2422.7744
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1476.2207,2421.8506,1476.9502,2422.7744,1478.0693,2422.7744z"
          />
          <path
            d="M1482.5586,2421.6074c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L1482.5586,2421.6074z"
          />
          <path
            d="M1488.4951,2418.1533h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2554,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V2418.1533z"
          />
          <path
            d="M1492.0225,2420.4033c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1493.2388,2423.7109,1492.0225,2422.3613,1492.0225,2420.4033z
				 M1493.1294,2419.7344h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1494.0171,2418.0312,1493.3237,2418.6514,1493.1294,2419.7344z"
          />
          <path
            d="M1502.3252,2418.2988c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2418.2988z"
          />
          <path d="M1503.4814,2414.8457h1.1919v1.2646h-1.1919V2414.8457z M1504.625,2423.5771h-1.0947v-6.3604h1.0947V2423.5771z" />
          <path
            d="M1506.4497,2417.2168h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2417.2168z"
          />
          <path
            d="M1515.7427,2425.9004c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3403,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.6055,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C1518.6978,2424.8662,1517.4697,2425.9004,1515.7427,2425.9004z M1515.8521,2418.0557
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C1517.6157,2418.7373,1516.7642,2418.0557,1515.8521,2418.0557z"
          />
          <path d="M1449.4951,2437.5771l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1449.4951z" />
          <path
            d="M1453.5347,2435.8623c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1454.4712,2437.6865,1453.5347,2437.0059,1453.5347,2435.8623z M1457.4868,2434.3184l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2434.3184z"
          />
          <path
            d="M1460.4316,2431.2168h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2431.2168z"
          />
          <path
            d="M1470.0054,2435.6074c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9243-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L1470.0054,2435.6074z"
          />
          <path
            d="M1475.9419,2432.1533h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2559,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V2432.1533z"
          />
          <path
            d="M1479.4692,2434.4033c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1480.6855,2437.7109,1479.4692,2436.3613,1479.4692,2434.4033z
				 M1480.5762,2433.7344h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1481.4639,2432.0312,1480.7705,2432.6514,1480.5762,2433.7344z"
          />
          <path
            d="M1486.0381,2434.4033c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1487.0479,2437.7109,1486.0381,2436.2031,1486.0381,2434.4033z
				 M1489.0054,2432.0557c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1490.8057,2432.7002,1489.9058,2432.0557,1489.0054,2432.0557z"
          />
          <path
            d="M1493.2515,2434.4033c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1494.4678,2437.7109,1493.2515,2436.3613,1493.2515,2434.4033z
				 M1494.3584,2433.7344h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1495.2461,2432.0312,1494.5527,2432.6514,1494.3584,2433.7344z"
          />
          <path d="M1501.353,2437.5771h-1.0947v-8.7314h1.0947V2437.5771z" />
          <path d="M1503.1289,2428.8457h1.1919v1.2646h-1.1919V2428.8457z M1504.2725,2437.5771h-1.0947v-6.3604h1.0947V2437.5771z" />
          <path
            d="M1506.0972,2431.2168h1.0947v6.8828c0,1.1318-0.7056,1.7031-1.7881,1.7031c-0.2554,0-0.4131-0.0127-0.6079-0.0361
				v-0.9492c0.1338,0.0244,0.2432,0.0371,0.4258,0.0371c0.6323,0,0.8755-0.3047,0.8755-0.9492V2431.2168z M1506.0483,2428.8457
				h1.1919v1.2646h-1.1919V2428.8457z"
          />
          <path
            d="M1510.1113,2434.1846l2.8213-2.9678h1.3618l-2.481,2.5049l2.688,3.8555h-1.2769l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V2434.1846z"
          />
          <path d="M1449.4038,2451.5771l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1449.4038z" />
          <path
            d="M1456.4595,2451.7109c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1459.4146,2450.373,1458.1982,2451.7109,1456.4595,2451.7109z M1456.4595,2450.7744
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1454.6108,2449.8506,1455.3403,2450.7744,1456.4595,2450.7744z"
          />
          <path
            d="M1460.3647,2448.4033c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1461.5811,2451.7109,1460.3647,2450.3613,1460.3647,2448.4033z
				 M1461.4717,2447.7344h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1462.3594,2446.0312,1461.666,2446.6514,1461.4717,2447.7344z"
          />
          <path
            d="M1466.9336,2448.4033c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1467.9434,2451.7109,1466.9336,2450.2031,1466.9336,2448.4033z
				 M1469.9009,2446.0557c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1471.7012,2446.7002,1470.8013,2446.0557,1469.9009,2446.0557z"
          />
          <path
            d="M1474.731,2449.6074c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9243-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L1474.731,2449.6074z"
          />
          <path
            d="M1480.0591,2448.4033c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1481.2754,2451.7109,1480.0591,2450.3613,1480.0591,2448.4033z M1481.166,2447.7344
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1482.0537,2446.0312,1481.3604,2446.6514,1481.166,2447.7344z"
          />
          <path d="M1488.1606,2451.5771h-1.0947v-8.7314h1.0947V2451.5771z" />
          <path
            d="M1489.4868,2449.8623c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1490.4233,2451.6865,1489.4868,2451.0059,1489.4868,2449.8623z M1493.439,2448.3184l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2448.3184z"
          />
          <path
            d="M1499.1567,2443.709c-0.1826-0.0244-0.292-0.0244-0.4746-0.0244c-0.6445,0-0.9971,0.2793-0.9971,1.0947v0.4375h1.4106
				v0.9365h-1.4106v5.4238h-1.0947v-5.4238h-0.9604v-0.9365h0.9604v-0.5107c0-1.2402,0.6812-1.9941,1.9824-1.9941
				c0.2188,0,0.3403,0.0117,0.584,0.0244V2443.709z"
          />
          <path d="M1501.7476,2451.5771l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1501.7476z" />
          <path
            d="M1505.7871,2449.8623c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1506.7236,2451.6865,1505.7871,2451.0059,1505.7871,2449.8623z M1509.7393,2448.3184l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2448.3184z"
          />
          <path d="M1513.7788,2451.5771h-1.0947v-8.7314h1.0947V2451.5771z" />
        </g>
      </g>
      <g onClick={handleClick} id="35">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M1865.1639,506.6052c0,25.2698-20.48,45.7498-45.75,45.7498c-25.266,0-45.75-20.48-45.75-45.7498
			c0-25.2651,20.484-45.75,45.75-45.75C1844.684,460.8552,1865.1639,481.3401,1865.1639,506.6052"
        />
        <g>
          <path
            d="M1786.356,487.8994l3.3687,8.7319h-1.1797l-0.8877-2.2983h-3.916l-0.8755,2.2983h-1.168l3.3691-8.7319H1786.356z
				 M1784.1304,493.3115h3.1377l-1.5811-4.0986L1784.1304,493.3115z"
          />
          <path
            d="M1790.335,494.9165c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1791.2715,496.7407,1790.335,496.0596,1790.335,494.9165z M1794.2871,493.3721l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V493.3721z"
          />
          <path
            d="M1797.2319,490.271h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V490.271z"
          />
          <path
            d="M1808.0454,490.271l1.313,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H1808.0454z"
          />
          <path
            d="M1812.4131,493.457c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1813.6294,496.7651,1812.4131,495.415,1812.4131,493.457z M1813.52,492.7886h3.4292
				c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1814.4077,491.0859,1813.7144,491.7061,1813.52,492.7886z"
          />
          <path d="M1824.0171,496.6313h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8394l-3.5996,4.5845h3.6362V496.6313z" />
          <path d="M1825.3926,487.8994h1.1919v1.2651h-1.1919V487.8994z M1826.5361,496.6313h-1.0947v-6.3604h1.0947V496.6313z" />
          <path
            d="M1830.793,498.9541c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3408,0.6206-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C1833.748,497.9204,1832.52,498.9541,1830.793,498.9541z M1830.9023,491.1104
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C1832.666,491.791,1831.8145,491.1104,1830.9023,491.1104z"
          />
          <path
            d="M1835.5742,487.8994h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V487.8994z"
          />
          <path
            d="M1841.9971,493.457c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1843.2134,496.7651,1841.9971,495.415,1841.9971,493.457z M1843.104,492.7886
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1843.9917,491.0859,1843.2983,491.7061,1843.104,492.7886z"
          />
          <path d="M1848.9551,487.8994h1.1919v1.2651h-1.1919V487.8994z M1850.0986,496.6313h-1.0947v-6.3604h1.0947V496.6313z" />
          <path
            d="M1851.4854,493.457c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1852.4951,496.7651,1851.4854,495.2573,1851.4854,493.457z
				 M1854.4526,491.1104c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1856.2529,491.7549,1855.353,491.1104,1854.4526,491.1104z"
          />
          <path d="M1795.3276,510.6313l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1795.3276z" />
          <path
            d="M1799.3672,508.9165c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1800.3037,510.7407,1799.3672,510.0596,1799.3672,508.9165z M1803.3193,507.3721l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V507.3721z"
          />
          <path
            d="M1806.2642,504.271h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V504.271z"
          />
          <path d="M1816.7866,510.6313h-1.0947v-8.7319h1.0947V510.6313z" />
          <path
            d="M1821.1289,510.7651c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1824.084,509.4272,1822.8677,510.7651,1821.1289,510.7651z M1821.1289,509.8286
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1819.2803,508.9043,1820.0098,509.8286,1821.1289,509.8286z"
          />
          <path
            d="M1826.5669,507.2383l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V507.2383z"
          />
          <path
            d="M1831.4697,508.9165c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1832.4062,510.7407,1831.4697,510.0596,1831.4697,508.9165z M1835.4219,507.3721l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V507.3721z"
          />
          <path d="M1839.4614,510.6313h-1.0947v-8.7319h1.0947V510.6313z" />
          <path
            d="M1840.8481,507.457c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1842.0645,510.7651,1840.8481,509.415,1840.8481,507.457z M1841.9551,506.7886
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1842.8428,505.0859,1842.1494,505.7061,1841.9551,506.7886z"
          />
          <path
            d="M1804.5112,518.271l1.3135,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H1804.5112z"
          />
          <path d="M1809.2686,515.8994h1.1919v1.2651h-1.1919V515.8994z M1810.4121,524.6313h-1.0947v-6.3604h1.0947V524.6313z" />
          <path
            d="M1812.2368,518.271h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V518.271z"
          />
          <path
            d="M1820.1924,521.2383l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V521.2383z"
          />
          <path
            d="M1825.1558,521.457c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1826.3721,524.7651,1825.1558,523.415,1825.1558,521.457z M1826.2627,520.7886
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1827.1504,519.0859,1826.457,519.7061,1826.2627,520.7886z"
          />
          <path d="M1833.2573,524.6313h-1.0947v-8.7319h1.0947V524.6313z" />
          <path
            d="M1835.228,522.6611c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9243-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1835.228,522.6611z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="34">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1137.129,1170.887c0,25.269-20.484,45.75-45.75,45.75c-25.27,0-45.75-20.481-45.75-45.75
			c0-25.266,20.48-45.75,45.75-45.75C1116.645,1125.137,1137.129,1145.621,1137.129,1170.887"
        />
        <g>
          <path d="M1072.8804,1167.9131h-1.0093l-4.7671-7.0293v7.0293h-1.1187v-8.7319h1.228l4.5361,6.7495v-6.7495h1.1309V1167.9131z" />
          <path d="M1073.9771,1159.1812h1.1919v1.2651h-1.1919V1159.1812z M1075.1206,1167.9131h-1.0947v-6.3604h1.0947V1167.9131z" />
          <path d="M1077.8447,1167.9131l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1077.8447z" />
          <path
            d="M1081.2153,1164.7388c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1082.4316,1168.0469,1081.2153,1166.6968,1081.2153,1164.7388z
				 M1082.3223,1164.0703h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1083.21,1162.3677,1082.5166,1162.9878,1082.3223,1164.0703z"
          />
          <path
            d="M1086.9937,1166.1982c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1087.9302,1168.0225,1086.9937,1167.3413,1086.9937,1166.1982z M1090.9458,1164.6538l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1164.6538z"
          />
          <path
            d="M1098.2075,1167.9131h-1.0942v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8853,1.0093
				c-1.3618,0-2.1284-0.9365-2.1284-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083
				c0.7905,0,1.5933-0.5962,1.5933-1.6543v-3.8306h1.0942V1167.9131z"
          />
          <path d="M1102.7578,1167.9131l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1102.7578z" />
          <path
            d="M1106.0679,1166.1982c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1107.0044,1168.0225,1106.0679,1167.3413,1106.0679,1166.1982z M1110.02,1164.6538l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1164.6538z"
          />
          <path
            d="M1112.2349,1161.5527h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1161.5527z"
          />
          <path
            d="M1046.0845,1178.7388c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1047.0942,1182.0469,1046.0845,1180.5391,1046.0845,1178.7388z
				 M1049.0518,1176.3921c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1050.8521,1177.0366,1049.9521,1176.3921,1049.0518,1176.3921z"
          />
          <path d="M1052.5923,1173.1812h1.1919v1.2651h-1.1919V1173.1812z M1053.7358,1181.9131h-1.0947v-6.3604h1.0947V1181.9131z" />
          <path
            d="M1054.0278,1178.7388c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1055.2441,1182.0469,1054.0278,1180.6968,1054.0278,1178.7388z
				 M1055.1348,1178.0703h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1056.0225,1176.3677,1055.3291,1176.9878,1055.1348,1178.0703z"
          />
          <path
            d="M1063.2354,1176.6353c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1176.6353z"
          />
          <path d="M1064.9751,1181.9131l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1064.9751z" />
          <path
            d="M1070.936,1182.0469c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1073.8911,1180.709,1072.6748,1182.0469,1070.936,1182.0469z M1070.936,1181.1104
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1069.0874,1180.186,1069.8169,1181.1104,1070.936,1181.1104z"
          />
          <path
            d="M1073.7466,1178.7388c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1074.9629,1182.0469,1073.7466,1180.6968,1073.7466,1178.7388z
				 M1074.8535,1178.0703h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1075.7412,1176.3677,1075.0479,1176.9878,1074.8535,1178.0703z"
          />
          <path
            d="M1079.2207,1178.7388c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1080.2305,1182.0469,1079.2207,1180.5391,1079.2207,1178.7388z
				 M1082.188,1176.3921c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1083.9883,1177.0366,1083.0884,1176.3921,1082.188,1176.3921z"
          />
          <path
            d="M1085.3389,1178.7388c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1086.5552,1182.0469,1085.3389,1180.6968,1085.3389,1178.7388z
				 M1086.4458,1178.0703h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1087.3335,1176.3677,1086.6401,1176.9878,1086.4458,1178.0703z"
          />
          <path
            d="M1094.5469,1176.6353c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1176.6353z"
          />
          <path
            d="M1095.752,1180.9888v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C1096.834,1182.0469,1096.1289,1181.6211,1095.752,1180.9888z M1095.7275,1178.9702c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1178.9702z"
          />
          <path
            d="M1104.084,1176.6353c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1176.6353z"
          />
          <path
            d="M1106.7114,1182.0469c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1109.6665,1180.709,1108.4502,1182.0469,1106.7114,1182.0469z M1106.7114,1181.1104
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1104.8628,1180.186,1105.5923,1181.1104,1106.7114,1181.1104z"
          />
          <path
            d="M1109.522,1178.7388c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1110.5317,1182.0469,1109.522,1180.5391,1109.522,1178.7388z
				 M1112.4893,1176.3921c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1114.2896,1177.0366,1113.3896,1176.3921,1112.4893,1176.3921z"
          />
          <path
            d="M1121.1255,1181.9131h-1.0942v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8853,1.0093
				c-1.3618,0-2.1284-0.9365-2.1284-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083
				c0.7905,0,1.5933-0.5962,1.5933-1.6543v-3.8306h1.0942V1181.9131z"
          />
          <path
            d="M1124.4106,1181.0859c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9731,0.4497c-0.3042,1.0581-1.2285,1.8608-2.5542,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1122.5015,1180.186,1123.2798,1181.0859,1124.4106,1181.0859z"
          />
          <path
            d="M1127.2461,1176.4893h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1176.4893z"
          />
          <path d="M1130.0674,1173.1812h1.1919v1.2651h-1.1919V1173.1812z M1131.2109,1181.9131h-1.0947v-6.3604h1.0947V1181.9131z" />
          <path
            d="M1131.5029,1178.7388c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1132.7192,1182.0469,1131.5029,1180.6968,1131.5029,1178.7388z
				 M1132.6099,1178.0703h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1133.4976,1176.3677,1132.8042,1176.9878,1132.6099,1178.0703z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="33">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3417.074,1426.2729c0,25.27-20.4839,45.75-45.75,45.75c-25.269,0-45.75-20.48-45.75-45.75
			c0-25.2649,20.481-45.75,45.75-45.75C3396.5901,1380.5229,3417.074,1401.0081,3417.074,1426.2729"
        />
        <g>
          <path
            d="M3350.1016,1407.998c-0.3525,0.8027-1.2891,1.4351-2.542,1.4351c-2.2256,0-3.8789-1.8242-3.8789-4.4756
				c0-2.6631,1.666-4.5239,3.9521-4.5239c1.6787,0,2.8701,0.9121,3.3926,2.4204l-1.1182,0.3647
				c-0.3164-1.1069-1.1191-1.7515-2.2627-1.7515c-1.6533,0-2.7969,1.4106-2.7969,3.4785c0,2.0552,1.1436,3.478,2.8096,3.478
				c1.4111,0,2.3594-1.0825,2.3838-2.6392h-2.5059v-1.0215h3.6367v4.5361h-1.0703V1407.998z"
          />
          <path
            d="M3352.0986,1406.125c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3353.3145,1409.4331,3352.0986,1408.083,3352.0986,1406.125z M3353.2051,1405.4565
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3354.0928,1403.7539,3353.3994,1404.374,3353.2051,1405.4565z"
          />
          <path d="M3360.3711,1409.2993l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3360.3711z" />
          <path
            d="M3367.0605,1409.4331c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3370.0156,1408.0952,3368.7998,1409.4331,3367.0605,1409.4331z M3367.0605,1408.4966
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3365.2119,1407.5723,3365.9414,1408.4966,3367.0605,1408.4966z"
          />
          <path
            d="M3370.6006,1406.125c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3371.8164,1409.4331,3370.6006,1408.083,3370.6006,1406.125z M3371.707,1405.4565
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3372.5947,1403.7539,3371.9014,1404.374,3371.707,1405.4565z"
          />
          <path d="M3378.3379,1409.2993h-1.0947v-8.7319h1.0947V1409.2993z" />
          <path d="M3383.6279,1409.2993l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3383.6279z" />
          <path
            d="M3387.3008,1407.5845c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3388.2373,1409.4087,3387.3008,1408.7275,3387.3008,1407.5845z M3391.2539,1406.04l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1406.04z"
          />
          <path
            d="M3393.834,1402.939h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1402.939z"
          />
          <path
            d="M3346.4648,1423.2993h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093
				c-1.3623,0-2.1279-0.9365-2.1279-2.2861v-4.208h1.0938v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083
				c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1423.2993z"
          />
          <path
            d="M3351.21,1418.0215c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1418.0215z"
          />
          <path
            d="M3354.4834,1425.6221c-1.3994,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C3357.4385,1424.5884,3356.21,1425.6221,3354.4834,1425.6221z M3354.5928,1417.7783
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C3356.3555,1418.459,3355.5049,1417.7783,3354.5928,1417.7783z"
          />
          <path
            d="M3358.4619,1420.125c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3359.6777,1423.4331,3358.4619,1422.083,3358.4619,1420.125z M3359.5684,1419.4565
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3360.4561,1417.7539,3359.7627,1418.374,3359.5684,1419.4565z"
          />
          <path
            d="M3365.1035,1416.939h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1416.939z"
          />
          <path
            d="M3371.7695,1417.8755h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1417.8755z"
          />
          <path d="M3375.3213,1414.5674h1.1924v1.2651h-1.1924V1414.5674z M3376.4648,1423.2993h-1.0947v-6.3604h1.0947V1423.2993z" />
          <path
            d="M3377.4863,1420.125c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3378.7021,1423.4331,3377.4863,1422.083,3377.4863,1420.125z M3378.5928,1419.4565
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3379.4805,1417.7539,3378.7871,1418.374,3378.5928,1419.4565z"
          />
          <path
            d="M3388.8477,1423.4331c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3391.8027,1422.0952,3390.5869,1423.4331,3388.8477,1423.4331z M3388.8477,1422.4966
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3386.999,1421.5723,3387.7285,1422.4966,3388.8477,1422.4966z"
          />
          <path
            d="M3393.9199,1423.2993h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0312-1.0825c1.3125,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1423.2993z"
          />
          <path
            d="M3328.4863,1436.4722c0.875,0,1.3984-0.5591,1.5801-1.3496l0.9736,0.4497c-0.3047,1.0581-1.2285,1.8608-2.5537,1.8608
				c-1.7764,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7661,2.5293,1.8242
				l-0.9492,0.4741c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9102,0.8999-1.9102,2.3589
				C3326.5762,1435.5723,3327.3545,1436.4722,3328.4863,1436.4722z"
          />
          <path
            d="M3333.5459,1437.4331c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3336.501,1436.0952,3335.2852,1437.4331,3333.5459,1437.4331z M3333.5459,1436.4966
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3331.6973,1435.5723,3332.4268,1436.4966,3333.5459,1436.4966z"
          />
          <path
            d="M3336.6729,1430.939h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1430.939z"
          />
          <path
            d="M3342.4629,1435.3291c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L3342.4629,1435.3291z"
          />
          <path
            d="M3352.0596,1437.2993h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093
				c-1.3623,0-2.1279-0.9365-2.1279-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083
				c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1437.2993z"
          />
          <path
            d="M3353.7529,1437.2993h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0312-1.0825c1.3135,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1437.2993z"
          />
          <path
            d="M3363.1318,1436.375v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C3364.2139,1437.4331,3363.5088,1437.0073,3363.1318,1436.375z M3363.1074,1434.3564c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1434.3564z"
          />
          <path
            d="M3368.2158,1431.8755h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1431.8755z"
          />
          <path d="M3370.917,1428.5674h1.1924v1.2651h-1.1924V1428.5674z M3372.0605,1437.2993h-1.0947v-6.3604h1.0947V1437.2993z" />
          <path
            d="M3372.2305,1434.125c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3373.4463,1437.4331,3372.2305,1436.083,3372.2305,1434.125z M3373.3369,1433.4565
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3374.2246,1431.7539,3373.5312,1432.374,3373.3369,1433.4565z"
          />
          <path
            d="M3379.1152,1436.375v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C3380.1973,1437.4331,3379.4922,1437.0073,3379.1152,1436.375z M3379.0908,1434.3564c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1434.3564z"
          />
          <path
            d="M3383.5312,1435.5845c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3384.4678,1437.4087,3383.5312,1436.7275,3383.5312,1435.5845z M3387.4844,1434.04l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1434.04z"
          />
          <path
            d="M3389.3818,1431.8755h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1431.8755z"
          />
          <path
            d="M3395.4268,1432.0215c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1432.0215z"
          />
          <path
            d="M3397.9336,1437.4331c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3400.8887,1436.0952,3399.6729,1437.4331,3397.9336,1437.4331z M3397.9336,1436.4966
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3396.085,1435.5723,3396.8145,1436.4966,3397.9336,1436.4966z"
          />
          <path
            d="M3401.0596,1430.939h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1430.939z"
          />
          <path
            d="M3406.2666,1434.125c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3407.4824,1437.4331,3406.2666,1436.083,3406.2666,1434.125z M3407.373,1433.4565
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3408.2607,1431.7539,3407.5674,1432.374,3407.373,1433.4565z"
          />
          <path
            d="M3412.0576,1430.939h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1430.939z"
          />
          <path
            d="M3336.9229,1449.5845c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3337.8594,1451.4087,3336.9229,1450.7275,3336.9229,1449.5845z M3340.876,1448.04l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1448.04z"
          />
          <path
            d="M3342.9561,1449.5845c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3343.8926,1451.4087,3342.9561,1450.7275,3342.9561,1449.5845z M3346.9092,1448.04l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1448.04z"
          />
          <path
            d="M3349.4893,1444.939h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1444.939z"
          />
          <path
            d="M3358.3564,1445.8755h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1445.8755z"
          />
          <path
            d="M3361.5205,1448.125c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3362.7363,1451.4331,3361.5205,1450.083,3361.5205,1448.125z M3362.627,1447.4565
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3363.5146,1445.7539,3362.8213,1446.374,3362.627,1447.4565z"
          />
          <path
            d="M3371.458,1450.375v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C3372.54,1451.4331,3371.835,1451.0073,3371.458,1450.375z M3371.4336,1448.3564c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1448.3564z"
          />
          <path
            d="M3376.7246,1449.5845c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3377.6611,1451.4087,3376.7246,1450.7275,3376.7246,1449.5845z M3380.6777,1448.04l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1448.04z"
          />
          <path
            d="M3383.4033,1449.3291c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L3383.4033,1449.3291z"
          />
          <path
            d="M3388.9502,1449.3291c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L3388.9502,1449.3291z"
          />
          <path
            d="M3393.9141,1448.125c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3395.1299,1451.4331,3393.9141,1450.083,3393.9141,1448.125z M3395.0205,1447.4565
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3395.9082,1445.7539,3395.2148,1446.374,3395.0205,1447.4565z"
          />
          <path
            d="M3400.5566,1444.939h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1444.939z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="32">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3579.6089,1311.074c0,25.27-20.4839,45.75-45.75,45.75c-25.2649,0-45.75-20.48-45.75-45.75
			c0-25.265,20.4851-45.75,45.75-45.75C3559.125,1265.324,3579.6089,1285.809,3579.6089,1311.074"
        />
        <g>
          <path
            d="M3495.9053,1308.1006h-1.167v-8.7324h3.332c1.8486,0,3.0283,0.8643,3.0283,2.5303c0,1.6416-1.1797,2.541-3.0283,2.541
				h-2.165V1308.1006z M3498.0098,1303.4307c1.2402,0,1.9336-0.5479,1.9336-1.5322c0-0.9854-0.6934-1.5205-1.9336-1.5205h-2.1045
				v3.0527H3498.0098z"
          />
          <path
            d="M3502.0361,1306.1299c0.3408,0.7422,1.1191,1.1924,1.9463,1.1924c0.7422,0,1.3984-0.3164,1.3984-1.0098
				s-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4629-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6318-0.9238-1.0332-1.7266-1.0332
				c-0.7295,0-1.2158,0.3643-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4844L3502.0361,1306.1299z"
          />
          <path
            d="M3507.8271,1310.3623c-0.2666,0-0.4004-0.0117-0.5957-0.0488v-0.9482c0.207,0.0488,0.3408,0.0605,0.5234,0.0605
				c0.4736,0,0.7295-0.1699,0.9844-0.8027l0.3047-0.7539l-2.3594-6.1289h1.1553l1.7881,4.8643l1.7627-4.8643h1.1436l-2.7363,7.0537
				C3509.4326,1309.7539,3508.9346,1310.3623,3507.8271,1310.3623z"
          />
          <path
            d="M3515.8066,1307.2734c0.875,0,1.3984-0.5596,1.5801-1.3496l0.9736,0.4492c-0.3047,1.0586-1.2285,1.8613-2.5537,1.8613
				c-1.7764,0-3.0039-1.3506-3.0039-3.3086c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7666,2.5293,1.8242
				l-0.9492,0.4746c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9102,0.8994-1.9102,2.3584
				C3513.8965,1306.373,3514.6748,1307.2734,3515.8066,1307.2734z"
          />
          <path
            d="M3519.2002,1299.3682h1.0947v3.2354c0.3643-0.5234,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1963h-1.0938v-3.9043c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6084-1.5928,1.666v3.7949h-1.0947V1299.3682z"
          />
          <path
            d="M3528.2148,1308.2344c-1.7393,0-2.9551-1.3379-2.9551-3.3086c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3531.1699,1306.8965,3529.9541,1308.2344,3528.2148,1308.2344z M3528.2148,1307.2979
				c1.1182,0,1.8486-0.9248,1.8486-2.3721c0-1.459-0.7305-2.3828-1.8486-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C3526.3662,1306.373,3527.0957,1307.2979,3528.2148,1307.2979z"
          />
          <path d="M3533.2881,1308.1006h-1.0947v-8.7324h1.0947V1308.1006z" />
          <path
            d="M3537.2646,1308.2344c-1.7393,0-2.9551-1.3379-2.9551-3.3086c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3540.2197,1306.8965,3539.0039,1308.2344,3537.2646,1308.2344z M3537.2646,1307.2979
				c1.1182,0,1.8486-0.9248,1.8486-2.3721c0-1.459-0.7305-2.3828-1.8486-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C3535.416,1306.373,3536.1455,1307.2979,3537.2646,1307.2979z"
          />
          <path
            d="M3543.6748,1310.4229c-1.3994,0-2.4209-0.6445-2.7734-1.8115l1.0215-0.3896c0.207,0.8154,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.8965v-0.7178c-0.3408,0.6201-1.0576,1.0576-1.9463,1.0576
				c-1.7383,0-2.7598-1.4346-2.7598-3.1738c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.4385,1.9463,1.0586v-0.9365
				h1.0947v5.7881C3546.6299,1309.3896,3545.4014,1310.4229,3543.6748,1310.4229z M3543.7842,1302.5791
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012s0.7061,2.2139,1.8613,2.2139c0.9121,0,1.7627-0.6816,1.7627-2.0801v-0.2314
				C3545.5469,1303.2598,3544.6963,1302.5791,3543.7842,1302.5791z"
          />
          <path d="M3548.043,1299.3682h1.1924v1.2656h-1.1924V1299.3682z M3549.1865,1308.1006h-1.0947v-6.3604h1.0947V1308.1006z" />
          <path
            d="M3550.791,1306.1299c0.3408,0.7422,1.1191,1.1924,1.9463,1.1924c0.7422,0,1.3984-0.3164,1.3984-1.0098
				s-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4629-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6318-0.9238-1.0332-1.7266-1.0332
				c-0.7295,0-1.2158,0.3643-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4844L3550.791,1306.1299z"
          />
          <path
            d="M3558.7588,1307.2734c0.875,0,1.3984-0.5596,1.5811-1.3496l0.9727,0.4492c-0.3047,1.0586-1.2285,1.8613-2.5537,1.8613
				c-1.7764,0-3.0039-1.3506-3.0039-3.3086c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7666,2.5293,1.8242
				l-0.9482,0.4746c-0.1826-0.7783-0.7061-1.3496-1.5811-1.3496c-1.1318,0-1.9102,0.8994-1.9102,2.3584
				C3556.8486,1306.373,3557.627,1307.2734,3558.7588,1307.2734z"
          />
          <path
            d="M3562.1533,1299.3682h1.0947v3.2354c0.3643-0.5234,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1963h-1.0938v-3.9043c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6084-1.5928,1.666v3.7949h-1.0947V1299.3682z"
          />
          <path
            d="M3568.2109,1304.9258c0-1.8965,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1885,1.9336,2.1885c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3569.4268,1308.2344,3568.2109,1306.8838,3568.2109,1304.9258z
				 M3569.3174,1304.2578h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C3570.2051,1302.5547,3569.5117,1303.1748,3569.3174,1304.2578z"
          />
          <path
            d="M3501.623,1320.3857c0-1.2646,0.9854-1.7881,2.4082-2.0801l1.5449-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0332,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1006c0,0.4141,0.2559,0.5479,0.6807,0.4502v0.876
				c-0.9727,0.1211-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3502.5596,1322.21,3501.623,1321.5283,3501.623,1320.3857z M3505.5762,1318.8408l-1.2412,0.2676
				c-0.9727,0.1953-1.6289,0.4629-1.6289,1.2285c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V1318.8408z"
          />
          <path
            d="M3509.25,1322.1006h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5234,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0332-1.083,2.0312-1.083c1.3125,0,2.1035,0.9365,2.1035,2.2861v4.1963
				h-1.0947v-3.9043c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6543v3.8066h-1.0947v-3.9043
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6543V1322.1006z"
          />
          <path
            d="M3519.4814,1321.1758v0.9248h-1.0947v-8.7324h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4961,2.7725,3.3076c0,1.8008-1.0088,3.3086-2.7725,3.3086
				C3520.5635,1322.2344,3519.8584,1321.8086,3519.4814,1321.1758z M3519.457,1319.1572c0,1.459,0.8994,2.1162,1.7998,2.1162
				c1.1914,0,1.8604-0.9854,1.8604-2.3477c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1319.1572z"
          />
          <path d="M3525.1982,1313.3682h1.1924v1.2656h-1.1924V1313.3682z M3526.3418,1322.1006h-1.0947v-6.3604h1.0947V1322.1006z" />
          <path d="M3529.4307,1322.1006l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3529.4307z" />
          <path
            d="M3533.1045,1320.3857c0-1.2646,0.9854-1.7881,2.4082-2.0801l1.5449-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0332,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1006c0,0.4141,0.2559,0.5479,0.6807,0.4502v0.876
				c-0.9727,0.1211-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3534.041,1322.21,3533.1045,1321.5283,3533.1045,1320.3857z M3537.0576,1318.8408l-1.2412,0.2676
				c-0.9727,0.1953-1.6289,0.4629-1.6289,1.2285c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V1318.8408z"
          />
          <path d="M3540.7314,1322.1006h-1.0947v-8.7324h1.0947V1322.1006z" />
          <path
            d="M3541.7529,1318.9258c0-1.8965,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1885,1.9336,2.1885c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3542.9688,1322.2344,3541.7529,1320.8838,3541.7529,1318.9258z
				 M3542.8594,1318.2578h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C3543.7471,1316.5547,3543.0537,1317.1748,3542.8594,1318.2578z"
          />
          <path
            d="M3548.3945,1315.7402h1.0947v0.8633c0.3643-0.5234,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1963h-1.0938v-3.9043c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6084-1.5928,1.666v3.7949h-1.0947V1315.7402z"
          />
          <path
            d="M3555.0615,1316.6768h-0.9844v-0.9365h0.9844v-1.8613h1.083v1.8613h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9355c-0.2803,0.0615-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1316.6768z"
          />
          <path d="M3558.6133,1313.3682h1.1924v1.2656h-1.1924V1313.3682z M3559.7568,1322.1006h-1.0947v-6.3604h1.0947V1322.1006z" />
          <path
            d="M3560.7783,1318.9258c0-1.8965,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1885,1.9336,2.1885c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3561.9941,1322.2344,3560.7783,1320.8838,3560.7783,1318.9258z
				 M3561.8848,1318.2578h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C3562.7725,1316.5547,3562.0791,1317.1748,3561.8848,1318.2578z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="31">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1139.3521,815.73c0,25.2661-20.4811,45.75-45.75,45.75c-25.266,0-45.75-20.4839-45.75-45.75
			c0-25.2649,20.484-45.75,45.75-45.75C1118.871,769.98,1139.3521,790.4651,1139.3521,815.73"
        />
        <g>
          <path
            d="M1069.7153,804.4551c-0.3525,0.8027-1.2891,1.4351-2.5415,1.4351c-2.2256,0-3.8794-1.8242-3.8794-4.4756
				c0-2.6631,1.666-4.5239,3.9521-4.5239c1.6782,0,2.8701,0.9121,3.3931,2.4204l-1.1187,0.3647
				c-0.3164-1.1069-1.1191-1.7515-2.2622-1.7515c-1.6538,0-2.7969,1.4111-2.7969,3.4785c0,2.0552,1.1431,3.478,2.8091,3.478
				c1.4106,0,2.3594-1.0825,2.3838-2.6392h-2.5054v-1.0215h3.6362v4.5361h-1.0703V804.4551z"
          />
          <path
            d="M1071.7119,802.582c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1072.9282,805.8901,1071.7119,804.54,1071.7119,802.582z M1072.8188,801.9136
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1073.7065,800.2109,1073.0132,800.8311,1072.8188,801.9136z"
          />
          <path
            d="M1079.4482,804.832v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C1080.5303,805.8901,1079.8252,805.4644,1079.4482,804.832z M1079.4238,802.8135c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8608-0.9849,1.8608-2.3472c0-1.374-0.6689-2.3467-1.8608-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V802.8135z"
          />
          <path
            d="M1088.5103,800.4785c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V800.4785z"
          />
          <path
            d="M1094.397,805.7563h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V805.7563z"
          />
          <path d="M1095.7974,797.0244h1.1919v1.2651h-1.1919V797.0244z M1096.9409,805.7563h-1.0947v-6.3604h1.0947V805.7563z" />
          <path
            d="M1099.4956,802.3633l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V802.3633z"
          />
          <path d="M1108.3633,805.7563l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1108.3633z" />
          <path
            d="M1112.0376,804.0415c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1112.9741,805.8657,1112.0376,805.1846,1112.0376,804.0415z M1115.9897,802.4971l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V802.4971z"
          />
          <path
            d="M1118.5698,799.396h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V799.396z"
          />
          <path
            d="M1066.5898,818.9292c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9731,0.4497c-0.3042,1.0581-1.2285,1.8608-2.5542,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1064.6807,818.0293,1065.459,818.9292,1066.5898,818.9292z"
          />
          <path
            d="M1069.9849,811.0244h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V811.0244z"
          />
          <path
            d="M1076.0425,816.582c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1077.2588,819.8901,1076.0425,818.54,1076.0425,816.582z M1077.1494,815.9136
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1078.0371,814.2109,1077.3438,814.8311,1077.1494,815.9136z"
          />
          <path
            d="M1083.7793,819.7563h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V819.7563z"
          />
          <path d="M1092.8657,811.0244h1.1919v1.2651h-1.1919V811.0244z M1094.0093,819.7563h-1.0947v-6.3604h1.0947V819.7563z" />
          <path
            d="M1098.0352,818.9292c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9731,0.4497c-0.3042,1.0581-1.2285,1.8608-2.5542,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1096.126,818.0293,1096.9043,818.9292,1098.0352,818.9292z"
          />
          <path
            d="M1100.9316,818.0415c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1101.8682,819.8657,1100.9316,819.1846,1100.9316,818.0415z M1104.8838,816.4971l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V816.4971z"
          />
          <path d="M1108.5581,819.7563h-1.0947v-8.7319h1.0947V819.7563z" />
          <path d="M1109.9692,811.0244h1.1919v1.2651h-1.1919V811.0244z M1111.1128,819.7563h-1.0947v-6.3604h1.0947V819.7563z" />
          <path
            d="M1113.144,817.0078c0.0239,1.4473,0.9482,2.043,2.0186,2.043c0.7661,0,1.2285-0.1338,1.6294-0.3037l0.1826,0.7661
				c-0.377,0.1704-1.0215,0.3647-1.958,0.3647c-1.812,0-2.8945-1.1919-2.8945-2.9673s1.0459-3.1743,2.7607-3.1743
				c1.9214,0,2.4321,1.6904,2.4321,2.7729c0,0.2188-0.0239,0.3892-0.0361,0.4985H1113.144z M1116.2812,816.2417
				c0.0122-0.6812-0.2793-1.7393-1.4834-1.7393c-1.0825,0-1.5566,0.9976-1.6421,1.7393H1116.2812z M1113.1802,812.2041
				c0-0.3403,0.2798-0.6201,0.6201-0.6201c0.3408,0,0.5962,0.2676,0.5962,0.6201c0,0.3286-0.2432,0.6201-0.5962,0.6201
				C1113.4355,812.8242,1113.1802,812.5327,1113.1802,812.2041z M1115.4424,812.2041c0-0.3403,0.2676-0.6201,0.6079-0.6201
				c0.3408,0,0.5962,0.2676,0.5962,0.6201c0,0.3286-0.2437,0.6201-0.5962,0.6201
				C1115.6855,812.8242,1115.4424,812.5327,1115.4424,812.2041z"
          />
          <path
            d="M1118.3018,813.396h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V813.396z"
          />
          <path d="M1056.0195,825.0244h1.1919v1.2651h-1.1919V825.0244z M1057.1631,833.7563h-1.0947v-6.3604h1.0947V833.7563z" />
          <path
            d="M1058.623,827.396h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V827.396z"
          />
          <path
            d="M1066.8828,830.582c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1067.8926,833.8901,1066.8828,832.3823,1066.8828,830.582z
				 M1069.8501,828.2354c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1071.6504,828.8799,1070.7505,828.2354,1069.8501,828.2354z"
          />
          <path
            d="M1073.731,830.582c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1074.9473,833.8901,1073.731,832.54,1073.731,830.582z M1074.8379,829.9136h3.4292
				c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1075.7256,828.2109,1075.0322,828.8311,1074.8379,829.9136z"
          />
          <path d="M1083.6699,833.7563h-1.0947v-8.7319h1.0947V833.7563z" />
          <path
            d="M1084.6309,832.0415c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1085.5674,833.8657,1084.6309,833.1846,1084.6309,832.0415z M1088.583,830.4971l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V830.4971z"
          />
          <path
            d="M1091.1631,827.396h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V827.396z"
          />
          <path
            d="M1097.2207,830.582c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1098.2305,833.8901,1097.2207,832.3823,1097.2207,830.582z
				 M1100.188,828.2354c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1101.9883,828.8799,1101.0884,828.2354,1100.188,828.2354z"
          />
          <path
            d="M1105.6021,832.832v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C1106.6841,833.8901,1105.979,833.4644,1105.6021,832.832z M1105.5776,830.8135c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V830.8135z"
          />
          <path
            d="M1113.8857,833.8901c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1116.8408,832.5522,1115.6245,833.8901,1113.8857,833.8901z M1113.8857,832.9536
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1112.0371,832.0293,1112.7666,832.9536,1113.8857,832.9536z"
          />
          <path
            d="M1122.9106,833.7563h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V833.7563z"
          />
          <path
            d="M1128.312,827.396l1.3135,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H1128.312z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="30">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M867.402,2119.5271c0,25.2659-20.48,45.75-45.75,45.75c-25.265,0-45.75-20.4841-45.75-45.75
			c0-25.2651,20.485-45.75,45.75-45.75C846.922,2073.7771,867.402,2094.262,867.402,2119.5271"
        />
        <g>
          <path
            d="M796.5625,2086.8223h3.2227c1.8481,0,2.8335,0.8145,2.8335,2.2734c0,1.1191-0.6812,1.6787-1.3501,1.9102
				c0.9854,0.2676,1.6421,1.0332,1.6421,2.1396c0,1.5205-1.1069,2.4082-2.8701,2.4082h-3.478V2086.8223z M799.688,2090.5674
				c1.167,0,1.812-0.4502,1.812-1.374c0-0.9365-0.645-1.3867-1.812-1.3867h-1.9946v2.7607H799.688z M797.6934,2094.5684h2.2983
				c1.1675,0,1.7998-0.6074,1.7998-1.5078c0-0.8877-0.6323-1.4961-1.7998-1.4961h-2.2983V2094.5684z"
          />
          <path
            d="M803.1187,2092.3799c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C804.335,2095.6875,803.1187,2094.3379,803.1187,2092.3799z M804.2256,2091.7109
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C805.1133,2090.0078,804.4199,2090.6279,804.2256,2091.7109z"
          />
          <path
            d="M812.6914,2090.2754c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2090.2754z"
          />
          <path
            d="M812.729,2092.3799c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C813.9453,2095.6875,812.729,2094.3379,812.729,2092.3799z M813.8359,2091.7109
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C814.7236,2090.0078,814.0303,2090.6279,813.8359,2091.7109z"
          />
          <path d="M818.957,2086.8223h1.1919v1.2646h-1.1919V2086.8223z M820.1006,2095.5537h-1.0947v-6.3604h1.0947V2095.5537z" />
          <path
            d="M820.7573,2092.3799c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C821.7671,2095.6875,820.7573,2094.1797,820.7573,2092.3799z
				 M823.7246,2090.0322c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C825.5249,2090.6768,824.625,2090.0322,823.7246,2090.0322z"
          />
          <path
            d="M827.6787,2086.8223h1.0947v3.2344c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2086.8223z"
          />
          <path
            d="M833.3716,2092.3799c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C834.5879,2095.6875,833.3716,2094.3379,833.3716,2092.3799z M834.4785,2091.7109
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C835.3662,2090.0078,834.6729,2090.6279,834.4785,2091.7109z"
          />
          <path d="M839.6001,2086.8223h1.1919v1.2646h-1.1919V2086.8223z M840.7437,2095.5537h-1.0947v-6.3604h1.0947V2095.5537z" />
          <path
            d="M841.4004,2092.3799c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C842.4102,2095.6875,841.4004,2094.1797,841.4004,2092.3799z
				 M844.3677,2090.0322c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C846.168,2090.6768,845.2681,2090.0322,844.3677,2090.0322z"
          />
          <path d="M783.4849,2109.5537l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H783.4849z" />
          <path
            d="M786.7944,2107.8389c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C787.731,2109.6631,786.7944,2108.9824,786.7944,2107.8389z M790.7466,2106.2949l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2106.2949z"
          />
          <path
            d="M792.9614,2103.1934h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2103.1934z"
          />
          <path d="M802.0244,2109.5537h-1.0947v-8.7314h1.0947V2109.5537z" />
          <path
            d="M802.6206,2107.8389c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C803.5571,2109.6631,802.6206,2108.9824,802.6206,2107.8389z M806.5728,2106.2949l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2106.2949z"
          />
          <path
            d="M808.7876,2103.1934h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2103.1934z"
          />
          <path
            d="M814.4805,2106.3799c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C815.4902,2109.6875,814.4805,2108.1797,814.4805,2106.3799z
				 M817.4478,2104.0322c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C819.248,2104.6768,818.3481,2104.0322,817.4478,2104.0322z"
          />
          <path
            d="M822.4971,2108.6299v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C823.5791,2109.6875,822.874,2109.2617,822.4971,2108.6299z M822.4727,2106.6104c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8608-0.9854,1.8608-2.3467c0-1.374-0.6689-2.3477-1.8608-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V2106.6104z"
          />
          <path
            d="M830.4155,2109.6875c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C833.3706,2108.3496,832.1543,2109.6875,830.4155,2109.6875z M830.4155,2108.751
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C828.5669,2107.8271,829.2964,2108.751,830.4155,2108.751z"
          />
          <path
            d="M839.0757,2109.5537h-1.0947v-0.876c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V2109.5537z"
          />
          <path
            d="M844.1118,2103.1934l1.3135,4.7432l1.3257-4.7432h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H844.1118z"
          />
          <path
            d="M847.75,2106.3799c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C848.9663,2109.6875,847.75,2108.3379,847.75,2106.3799z M848.8569,2105.7109h3.4292
				c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C849.7446,2104.0078,849.0513,2104.6279,848.8569,2105.7109z"
          />
          <path
            d="M857.3232,2104.2754c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2104.2754z"
          />
          <path
            d="M857.9443,2107.584c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L857.9443,2107.584z"
          />
          <path
            d="M796.7622,2123.6875c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C799.7173,2122.3496,798.501,2123.6875,796.7622,2123.6875z M796.7622,2122.751
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C794.9136,2121.8271,795.6431,2122.751,796.7622,2122.751z"
          />
          <path
            d="M801.4702,2123.5537h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0337-1.083,2.0308-1.083c1.3135,0,2.104,0.9365,2.104,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V2123.5537z"
          />
          <path d="M816.6753,2123.5537h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8389l-3.5996,4.585h3.6362V2123.5537z" />
          <path d="M817.3208,2114.8223h1.1919v1.2646h-1.1919V2114.8223z M818.4644,2123.5537h-1.0947v-6.3604h1.0947V2123.5537z" />
          <path
            d="M822.125,2122.7266c0.8755,0,1.3984-0.5596,1.5811-1.3496l0.9731,0.4502c-0.3042,1.0576-1.2285,1.8604-2.5542,1.8604
				c-1.7754,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2285-3.3086,3.0039-3.3086c1.3257,0,2.2256,0.7666,2.5298,1.8242
				l-0.9487,0.4746c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8994-1.9092,2.3594
				C820.2158,2121.8271,820.9941,2122.7266,822.125,2122.7266z"
          />
          <path
            d="M825.1548,2114.8223h1.0947v3.2344c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2114.8223z"
          />
          <path
            d="M832.6245,2121.8389c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C833.561,2123.6631,832.6245,2122.9824,832.6245,2121.8389z M836.5767,2120.2949l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2120.2949z"
          />
          <path
            d="M838.293,2121.8389c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C839.2295,2123.6631,838.293,2122.9824,838.293,2121.8389z M842.2451,2120.2949l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2120.2949z"
          />
          <path
            d="M844.46,2117.1934h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939H844.46V2117.1934z"
          />
          <path
            d="M793.4541,2132.1299h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2554,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V2132.1299z"
          />
          <path
            d="M796.2515,2134.3799c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C797.4678,2137.6875,796.2515,2136.3379,796.2515,2134.3799z M797.3584,2133.7109
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C798.2461,2132.0078,797.5527,2132.6279,797.3584,2133.7109z"
          />
          <path
            d="M805.4604,2136.6299v3.1133h-1.0947v-8.5498h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C806.5425,2137.6875,805.8374,2137.2617,805.4604,2136.6299z M805.436,2134.6104c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6685-2.3477-1.8604-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V2134.6104z"
          />
          <path
            d="M810.3628,2135.8389c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C811.2993,2137.6631,810.3628,2136.9824,810.3628,2135.8389z M814.3149,2134.2949l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2134.2949z"
          />
          <path
            d="M816.6758,2135.584c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L816.6758,2135.584z"
          />
          <path
            d="M821.8579,2135.584c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9243-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L821.8579,2135.584z"
          />
          <path
            d="M826.4561,2134.3799c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C827.6724,2137.6875,826.4561,2136.3379,826.4561,2134.3799z M827.563,2133.7109
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C828.4507,2132.0078,827.7573,2132.6279,827.563,2133.7109z"
          />
          <path
            d="M832.7334,2131.1934h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2131.1934z"
          />
          <path d="M837.9399,2138.04l3.3931-8.8164h0.8271l-3.4053,8.8164H837.9399z" />
          <path
            d="M842.4771,2132.1299h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2559,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V2132.1299z"
          />
          <path
            d="M845.2744,2134.3799c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C846.4907,2137.6875,845.2744,2136.3379,845.2744,2134.3799z M846.3813,2133.7109
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C847.269,2132.0078,846.5757,2132.6279,846.3813,2133.7109z"
          />
          <path d="M798.5688,2142.8223h1.1919v1.2646h-1.1919V2142.8223z M799.7124,2151.5537h-1.0947v-6.3604h1.0947V2151.5537z" />
          <path
            d="M800.8071,2145.1934h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2145.1934z"
          />
          <path
            d="M806.938,2145.1934h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2145.1934z"
          />
          <path
            d="M815.5869,2151.6875c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C818.542,2150.3496,817.3257,2151.6875,815.5869,2151.6875z M815.5869,2150.751
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C813.7383,2149.8271,814.4678,2150.751,815.5869,2150.751z"
          />
          <path d="M820.8296,2151.5537l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H820.8296z" />
          <path
            d="M824.1997,2148.3799c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C825.416,2151.6875,824.1997,2150.3379,824.1997,2148.3799z M825.3066,2147.7109
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C826.1943,2146.0078,825.501,2146.6279,825.3066,2147.7109z"
          />
          <path
            d="M833.7725,2146.2754c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2146.2754z"
          />
          <path
            d="M833.8096,2148.3799c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C835.0259,2151.6875,833.8096,2150.3379,833.8096,2148.3799z M834.9165,2147.7109
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C835.8042,2146.0078,835.1108,2146.6279,834.9165,2147.7109z"
          />
          <path
            d="M840.0869,2145.1934h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2145.1934z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="29">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M1944.379,1229.254c0,25.2689-20.4811,45.75-45.75,45.75c-25.266,0-45.75-20.4811-45.75-45.75
			c0-25.266,20.484-45.75,45.75-45.75C1923.8979,1183.504,1944.379,1203.988,1944.379,1229.254"
        />
        <g>
          <path
            d="M1859.5928,1210.5483h3.2227c1.8486,0,2.8335,0.8149,2.8335,2.2744c0,1.1187-0.6812,1.6782-1.3501,1.9092
				c0.9854,0.2676,1.6421,1.0337,1.6421,2.1406c0,1.52-1.1069,2.4077-2.8701,2.4077h-3.478V1210.5483z M1862.7178,1214.2939
				c1.168,0,1.8125-0.4497,1.8125-1.374c0-0.9365-0.6445-1.3862-1.8125-1.3862h-1.9941v2.7603H1862.7178z M1860.7236,1218.2954
				h2.2983c1.1675,0,1.7998-0.6084,1.7998-1.5083c0-0.8877-0.6323-1.4956-1.7998-1.4956h-2.2983V1218.2954z"
          />
          <path
            d="M1866.1489,1216.106c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1867.3652,1219.4141,1866.1489,1218.064,1866.1489,1216.106z M1867.2559,1215.4375
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1868.1436,1213.7349,1867.4502,1214.355,1867.2559,1215.4375z"
          />
          <path
            d="M1872.5718,1217.3101c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1872.5718,1217.3101z"
          />
          <path
            d="M1880.1743,1218.4531c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9731,0.4497c-0.3042,1.0581-1.2285,1.8608-2.5542,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1878.2651,1217.5532,1879.0435,1218.4531,1880.1743,1218.4531z"
          />
          <path
            d="M1883.2041,1210.5483h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1210.5483z"
          />
          <path d="M1889.2861,1210.5483h1.1919v1.2651h-1.1919V1210.5483z M1890.4297,1219.2803h-1.0947v-6.3604h1.0947V1219.2803z" />
          <path
            d="M1892.6191,1215.8872l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V1215.8872z"
          />
          <path
            d="M1898.3853,1218.356v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C1899.4673,1219.4141,1898.7622,1218.9883,1898.3853,1218.356z M1898.3608,1216.3374c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8608-0.9849,1.8608-2.3472c0-1.374-0.6689-2.3467-1.8608-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1216.3374z"
          />
          <path
            d="M1903.2881,1217.5654c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1904.2246,1219.3896,1903.2881,1218.7085,1903.2881,1217.5654z M1907.2402,1216.021l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1216.021z"
          />
          <path
            d="M1908.9565,1217.5654c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1909.8931,1219.3896,1908.9565,1218.7085,1908.9565,1217.5654z M1912.9087,1216.021l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1216.021z"
          />
          <path
            d="M1918.4194,1214.0024c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1214.0024z"
          />
          <path
            d="M1918.8945,1210.5483h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1210.5483z"
          />
          <path
            d="M1924.5874,1216.106c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1925.8037,1219.4141,1924.5874,1218.064,1924.5874,1216.106z M1925.6943,1215.4375
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1926.582,1213.7349,1925.8887,1214.355,1925.6943,1215.4375z"
          />
          <path d="M1930.8159,1210.5483h1.1919v1.2651h-1.1919V1210.5483z M1931.9595,1219.2803h-1.0947v-6.3604h1.0947V1219.2803z" />
          <path
            d="M1932.6162,1216.106c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1933.626,1219.4141,1932.6162,1217.9062,1932.6162,1216.106z
				 M1935.5835,1213.7593c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1937.3838,1214.4038,1936.4839,1213.7593,1935.5835,1213.7593z"
          />
          <path d="M1857.1899,1233.2803l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1857.1899z" />
          <path
            d="M1860.4995,1231.5654c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1861.436,1233.3896,1860.4995,1232.7085,1860.4995,1231.5654z M1864.4517,1230.021l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1230.021z"
          />
          <path
            d="M1866.6665,1226.9199h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1226.9199z"
          />
          <path
            d="M1877.0669,1235.603c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3408,0.6201-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C1880.022,1234.5693,1878.7939,1235.603,1877.0669,1235.603z M1877.1763,1227.7593
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C1878.9399,1228.4399,1878.0884,1227.7593,1877.1763,1227.7593z"
          />
          <path
            d="M1880.6802,1230.106c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1881.8965,1233.4141,1880.6802,1232.064,1880.6802,1230.106z M1881.7871,1229.4375
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1882.6748,1227.7349,1881.9814,1228.355,1881.7871,1229.4375z"
          />
          <path
            d="M1888.0518,1229.8872l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V1229.8872z"
          />
          <path
            d="M1896.6758,1226.9199l1.313,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H1896.6758z"
          />
          <path
            d="M1900.2534,1231.5654c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1901.1899,1233.3896,1900.2534,1232.7085,1900.2534,1231.5654z M1904.2056,1230.021l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1230.021z"
          />
          <path d="M1907.5151,1233.2803h-1.0947v-8.7319h1.0947V1233.2803z" />
          <path d="M1908.561,1224.5483h1.1919v1.2651h-1.1919V1224.5483z M1909.7046,1233.2803h-1.0947v-6.3604h1.0947V1233.2803z" />
          <path
            d="M1913.5723,1225.4121c-0.1826-0.0244-0.292-0.0244-0.4746-0.0244c-0.6445,0-0.9971,0.2798-0.9971,1.0942v0.438h1.4106
				v0.9365h-1.4106v5.4238h-1.0947v-5.4238h-0.9604v-0.9365h0.9604v-0.5107c0-1.2402,0.6812-1.9946,1.9824-1.9946
				c0.2188,0,0.3403,0.0122,0.584,0.0244V1225.4121z"
          />
          <path d="M1913.7549,1224.5483h1.1919v1.2651h-1.1919V1224.5483z M1914.8984,1233.2803h-1.0947v-6.3604h1.0947V1233.2803z" />
          <path
            d="M1918.5596,1232.4531c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3037,1.0581-1.228,1.8608-2.5537,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1916.6504,1231.5532,1917.4287,1232.4531,1918.5596,1232.4531z"
          />
          <path
            d="M1921.1514,1230.106c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1922.3677,1233.4141,1921.1514,1232.064,1921.1514,1230.106z M1922.2583,1229.4375
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1923.146,1227.7349,1922.4526,1228.355,1922.2583,1229.4375z"
          />
          <path
            d="M1926.9902,1230.106c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1928.2065,1233.4141,1926.9902,1232.064,1926.9902,1230.106z M1928.0972,1229.4375
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1928.9849,1227.7349,1928.2915,1228.355,1928.0972,1229.4375z"
          />
          <path
            d="M1936.563,1228.0024c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1228.0024z"
          />
          <path
            d="M1936.6006,1230.106c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1937.6104,1233.4141,1936.6006,1231.9062,1936.6006,1230.106z
				 M1939.5679,1227.7593c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1941.3682,1228.4038,1940.4683,1227.7593,1939.5679,1227.7593z"
          />
          <path
            d="M1876.9268,1246.356v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C1878.0088,1247.4141,1877.3037,1246.9883,1876.9268,1246.356z M1876.9023,1244.3374c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1244.3374z"
          />
          <path
            d="M1881.8896,1244.106c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1883.106,1247.4141,1881.8896,1246.064,1881.8896,1244.106z M1882.9966,1243.4375
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1883.8843,1241.7349,1883.1909,1242.355,1882.9966,1243.4375z"
          />
          <path
            d="M1891.4624,1242.0024c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1242.0024z"
          />
          <path
            d="M1892.084,1245.3101c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9243-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1892.084,1245.3101z"
          />
          <path
            d="M1899.6377,1247.4141c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1902.5928,1246.0762,1901.3765,1247.4141,1899.6377,1247.4141z M1899.6377,1246.4775
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1897.7891,1245.5532,1898.5186,1246.4775,1899.6377,1246.4775z"
          />
          <path
            d="M1903.251,1240.9199h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1240.9199z"
          />
          <path
            d="M1908.9438,1244.106c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1910.1602,1247.4141,1908.9438,1246.064,1908.9438,1244.106z M1910.0508,1243.4375
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1910.9385,1241.7349,1910.2451,1242.355,1910.0508,1243.4375z"
          />
          <path
            d="M1914.7827,1244.106c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1915.999,1247.4141,1914.7827,1246.064,1914.7827,1244.106z M1915.8896,1243.4375
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1916.7773,1241.7349,1916.084,1242.355,1915.8896,1243.4375z"
          />
          <path d="M1922.1548,1247.2803h-1.0947v-8.7319h1.0947V1247.2803z" />
        </g>
      </g>
      <g onClick={handleClick} id="28">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M4063.2661,890.449c0,25.2661-20.4851,45.75-45.75,45.75c-25.2661,0-45.75-20.4839-45.75-45.75
			c0-25.2688,20.4839-45.75,45.75-45.75C4042.781,844.699,4063.2661,865.1802,4063.2661,890.449"
        />
        <g>
          <path
            d="M3987.7686,857.7432h2.7725c2.6514,0,4.3291,1.7026,4.3291,4.3662c0,2.6631-1.6777,4.3657-4.3291,4.3657h-2.7725
				V857.7432z M3990.541,865.4658c1.9824,0,3.1865-1.3257,3.1865-3.3564c0-2.0435-1.2041-3.3564-3.1982-3.3564h-1.6182v6.7129
				H3990.541z"
          />
          <path
            d="M4000.5645,866.4751h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0938v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V866.4751z"
          />
          <path d="M4001.5986,857.7432h1.1924v1.2651h-1.1924V857.7432z M4002.7422,866.4751h-1.0947v-6.3604h1.0947V866.4751z" />
          <path
            d="M4003.3994,863.3008c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C4004.4082,866.6089,4003.3994,865.1011,4003.3994,863.3008z
				 M4006.3662,860.9541c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C4008.166,861.5986,4007.2666,860.9541,4006.3662,860.9541z"
          />
          <path
            d="M4009.8828,863.3008c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C4011.0986,866.6089,4009.8828,865.2588,4009.8828,863.3008z M4010.9893,862.6323
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C4011.877,860.9297,4011.1836,861.5498,4010.9893,862.6323z"
          />
          <path d="M4017.2549,866.4751h-1.0947v-8.7319h1.0947V866.4751z" />
          <path d="M4018.3008,857.7432h1.1924v1.2651h-1.1924V857.7432z M4019.4443,866.4751h-1.0947v-6.3604h1.0947V866.4751z" />
          <path
            d="M4020.5381,860.1147h1.0947v6.8833c0,1.1309-0.7061,1.7026-1.7881,1.7026c-0.2549,0-0.4131-0.0122-0.6084-0.0366v-0.9487
				c0.1338,0.0244,0.2441,0.0366,0.4258,0.0366c0.6328,0,0.876-0.3037,0.876-0.9487V860.1147z M4020.4893,857.7432h1.1924v1.2651
				h-1.1924V857.7432z"
          />
          <path
            d="M4023.8223,863.082l2.8213-2.9673h1.3623l-2.4814,2.5054l2.6875,3.855h-1.2764l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V863.082z"
          />
          <path
            d="M4028.4932,857.7432h1.0947v3.2349c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V857.7432z"
          />
          <path
            d="M4034.1875,863.3008c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C4035.4033,866.6089,4034.1875,865.2588,4034.1875,863.3008z M4035.2939,862.6323
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C4036.1816,860.9297,4035.4883,861.5498,4035.2939,862.6323z"
          />
          <path d="M4040.416,857.7432h1.1924v1.2651h-1.1924V857.7432z M4041.5596,866.4751h-1.0947v-6.3604h1.0947V866.4751z" />
          <path
            d="M4042.2158,863.3008c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C4043.2246,866.6089,4042.2158,865.1011,4042.2158,863.3008z
				 M4045.1826,860.9541c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C4046.9824,861.5986,4046.083,860.9541,4045.1826,860.9541z"
          />
          <path d="M4002.8398,880.4751l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H4002.8398z" />
          <path
            d="M4006.7559,878.7603c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C4007.6924,880.5845,4006.7559,879.9033,4006.7559,878.7603z M4010.709,877.2158l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V877.2158z"
          />
          <path
            d="M4013.5322,874.1147h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V874.1147z"
          />
          <path
            d="M4022.2793,877.3008c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C4023.2881,880.6089,4022.2793,879.1011,4022.2793,877.3008z
				 M4025.2461,874.9541c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C4027.0459,875.5986,4026.1465,874.9541,4025.2461,874.9541z"
          />
          <path
            d="M4029.3711,877.3008c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C4030.5869,880.6089,4029.3711,879.2588,4029.3711,877.3008z M4030.4775,876.6323
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C4031.3652,874.9297,4030.6719,875.5498,4030.4775,876.6323z"
          />
          <path d="M3974.375,894.4751l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3974.375z" />
          <path
            d="M3980.7002,894.6089c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3983.6553,893.271,3982.4395,894.6089,3980.7002,894.6089z M3980.7002,893.6724
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3978.8516,892.748,3979.5811,893.6724,3980.7002,893.6724z"
          />
          <path
            d="M3983.876,891.3008c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3985.0918,894.6089,3983.876,893.2588,3983.876,891.3008z M3984.9824,890.6323
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3985.8701,888.9297,3985.1768,889.5498,3984.9824,890.6323z"
          />
          <path
            d="M3989.7158,891.3008c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3990.7246,894.6089,3989.7158,893.1011,3989.7158,891.3008z
				 M3992.6826,888.9541c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3994.4824,889.5986,3993.583,888.9541,3992.6826,888.9541z"
          />
          <path
            d="M3996.7822,892.5049c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L3996.7822,892.5049z"
          />
          <path
            d="M4001.3818,891.3008c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C4002.5977,894.6089,4001.3818,893.2588,4001.3818,891.3008z M4002.4883,890.6323
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C4003.376,888.9297,4002.6826,889.5498,4002.4883,890.6323z"
          />
          <path d="M4008.7539,894.4751h-1.0947v-8.7319h1.0947V894.4751z" />
          <path
            d="M4009.4102,891.3008c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C4010.626,894.6089,4009.4102,893.2588,4009.4102,891.3008z M4010.5166,890.6323
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C4011.4043,888.9297,4010.7109,889.5498,4010.5166,890.6323z"
          />
          <path
            d="M4015.8574,889.0513h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V889.0513z"
          />
          <path d="M4019.0439,885.7432h1.1924v1.2651h-1.1924V885.7432z M4020.1875,894.4751h-1.0947v-6.3604h1.0947V894.4751z" />
          <path
            d="M4022.377,891.082l2.8213-2.9673h1.3623l-2.4814,2.5054l2.6875,3.855h-1.2764l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V891.082z"
          />
          <path
            d="M4026.6113,891.3008c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C4027.8271,894.6089,4026.6113,893.2588,4026.6113,891.3008z M4027.7178,890.6323
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C4028.6055,888.9297,4027.9121,889.5498,4027.7178,890.6323z"
          />
          <path
            d="M4033.0576,889.0513h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V889.0513z"
          />
          <path
            d="M4036.4639,889.0513h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V889.0513z"
          />
          <path
            d="M4039.2617,891.3008c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C4040.4775,894.6089,4039.2617,893.2588,4039.2617,891.3008z M4040.3682,890.6323
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C4041.2559,888.9297,4040.5625,889.5498,4040.3682,890.6323z"
          />
          <path
            d="M4048.835,889.1973c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V889.1973z"
          />
          <path d="M4049.2617,885.7432h1.1924v1.2651h-1.1924V885.7432z M4050.4053,894.4751h-1.0947v-6.3604h1.0947V894.4751z" />
          <path
            d="M4051.499,888.1147h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V888.1147z"
          />
          <path
            d="M4060.0625,896.7979c-1.3984,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C4063.0176,895.7642,4061.7891,896.7979,4060.0625,896.7979z M4060.1719,888.9541
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C4061.9346,889.6348,4061.084,888.9541,4060.1719,888.9541z"
          />
          <path
            d="M4009.4453,908.6089c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C4012.4004,907.271,4011.1846,908.6089,4009.4453,908.6089z M4009.4453,907.6724
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C4007.5967,906.748,4008.3262,907.6724,4009.4453,907.6724z"
          />
          <path d="M4015.2969,908.4751l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H4015.2969z" />
          <path
            d="M4019.2754,905.3008c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C4020.4912,908.6089,4019.2754,907.2588,4019.2754,905.3008z M4020.3818,904.6323
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C4021.2695,902.9297,4020.5762,903.5498,4020.3818,904.6323z"
          />
          <path
            d="M4029.4561,903.1973c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V903.1973z"
          />
          <path
            d="M3983.165,919.3008c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3984.1738,922.6089,3983.165,921.1011,3983.165,919.3008z
				 M3986.1318,916.9541c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3987.9316,917.5986,3987.0322,916.9541,3986.1318,916.9541z"
          />
          <path
            d="M3995.1338,922.4751h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V922.4751z"
          />
          <path
            d="M4001.2646,922.4751h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V922.4751z"
          />
          <path
            d="M4005.6455,917.1973c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V917.1973z"
          />
          <path d="M4010.7168,922.4751h-5.0098v-0.8271l3.6719-4.5967h-3.624v-0.9365h4.9258v0.8394l-3.5996,4.5845h3.6357V922.4751z" />
          <path
            d="M4010.9131,920.7603c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C4011.8496,922.5845,4010.9131,921.9033,4010.9131,920.7603z M4014.8662,919.2158l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V919.2158z"
          />
          <path
            d="M4016.582,920.7603c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C4017.5186,922.5845,4016.582,921.9033,4016.582,920.7603z M4020.5352,919.2158l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V919.2158z"
          />
          <path
            d="M4023.8438,922.4751h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0312-1.0825c1.3125,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V922.4751z"
          />
          <path
            d="M4032.6152,913.7432h1.0947v3.2349c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V913.7432z"
          />
          <path
            d="M4038.3076,919.3008c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C4039.5234,922.6089,4038.3076,921.2588,4038.3076,919.3008z M4039.4141,918.6323
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C4040.3018,916.9297,4039.6084,917.5498,4039.4141,918.6323z"
          />
          <path d="M4044.5361,913.7432h1.1924v1.2651h-1.1924V913.7432z M4045.6797,922.4751h-1.0947v-6.3604h1.0947V922.4751z" />
          <path
            d="M4046.3369,919.3008c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C4047.3457,922.6089,4046.3369,921.1011,4046.3369,919.3008z
				 M4049.3037,916.9541c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C4051.1035,917.5986,4050.2041,916.9541,4049.3037,916.9541z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="27">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M461.789,1435.1479c0,25.27-20.484,45.75-45.75,45.75s-45.75-20.48-45.75-45.75
			c0-25.2649,20.484-45.75,45.75-45.75S461.789,1409.8831,461.789,1435.1479"
        />
        <g>
          <path
            d="M396.5015,1432.1743l-2.6636-7.1875v7.1875h-1.0942v-8.7319h1.5928l2.6514,7.1753l2.6631-7.1753h1.5444v8.7319h-1.082
				v-7.1875l-2.6758,7.1875H396.5015z"
          />
          <path
            d="M402.4634,1430.4595c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C403.3999,1432.2837,402.4634,1431.6025,402.4634,1430.4595z M406.4155,1428.915l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1428.915z"
          />
          <path
            d="M409.4092,1426.7505h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1426.7505z"
          />
          <path
            d="M412.8149,1429c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C414.0312,1432.3081,412.8149,1430.958,412.8149,1429z M413.9219,1428.3315h3.4292
				c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C414.8096,1426.6289,414.1162,1427.249,413.9219,1428.3315z"
          />
          <path d="M423.7744,1432.1743l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H423.7744z" />
          <path
            d="M427.6924,1430.4595c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C428.6289,1432.2837,427.6924,1431.6025,427.6924,1430.4595z M431.6445,1428.915l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1428.915z"
          />
          <path
            d="M434.4678,1425.814h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1425.814z"
          />
          <path
            d="M383.4136,1445.25v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C384.4956,1446.3081,383.7905,1445.8823,383.4136,1445.25z M383.3892,1443.2314c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8608-0.9849,1.8608-2.3472c0-1.374-0.6689-2.3467-1.8608-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1443.2314z"
          />
          <path d="M389.374,1437.4424h1.1919v1.2651h-1.1919V1437.4424z M390.5176,1446.1743h-1.0947v-6.3604h1.0947V1446.1743z" />
          <path
            d="M394.7383,1446.3081c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C397.6934,1444.9702,396.4771,1446.3081,394.7383,1446.3081z M394.7383,1445.3716
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C392.8896,1444.4473,393.6191,1445.3716,394.7383,1445.3716z"
          />
          <path
            d="M398.522,1443c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319h-1.0942
				v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C399.5317,1446.3081,398.522,1444.8003,398.522,1443z M401.4893,1440.6533
				c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502
				C403.2896,1441.2979,402.3896,1440.6533,401.4893,1440.6533z"
          />
          <path d="M406.0024,1437.4424h1.1919v1.2651h-1.1919V1437.4424z M407.146,1446.1743h-1.0947v-6.3604h1.0947V1446.1743z" />
          <path d="M410.4785,1446.1743l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H410.4785z" />
          <path
            d="M414.457,1443c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C415.6733,1446.3081,414.457,1444.958,414.457,1443z M415.564,1442.3315h3.4292
				c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C416.4517,1440.6289,415.7583,1441.249,415.564,1442.3315z"
          />
          <path
            d="M424.6382,1440.8965c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1440.8965z"
          />
          <path
            d="M425.8677,1444.2041c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L425.8677,1444.2041z"
          />
          <path d="M431.4634,1437.4424h1.1919v1.2651h-1.1919V1437.4424z M432.6069,1446.1743h-1.0947v-6.3604h1.0947V1446.1743z" />
          <path
            d="M434.4805,1440.7505h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1440.7505z"
          />
          <path
            d="M437.8857,1443c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C439.1021,1446.3081,437.8857,1444.958,437.8857,1443z M438.9927,1442.3315h3.4292
				c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C439.8804,1440.6289,439.187,1441.249,438.9927,1442.3315z"
          />
          <path d="M444.7222,1437.4424h1.1919v1.2651h-1.1919V1437.4424z M445.8657,1446.1743h-1.0947v-6.3604h1.0947V1446.1743z" />
          <path
            d="M447.7393,1440.7505h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1440.7505z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="26">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1122.535,2119.3159c0,25.27-20.484,45.75-45.75,45.75c-25.265,0-45.75-20.48-45.75-45.75
			c0-25.2649,20.485-45.75,45.75-45.75C1102.051,2073.5659,1122.535,2094.051,1122.535,2119.3159"
        />
        <g>
          <path
            d="M1045.3135,2108.042c-0.3525,0.8018-1.2891,1.4346-2.5415,1.4346c-2.2256,0-3.8794-1.8242-3.8794-4.4756
				c0-2.6631,1.666-4.5234,3.9521-4.5234c1.6782,0,2.8701,0.9121,3.3931,2.4199l-1.1187,0.3643
				c-0.3164-1.1064-1.1191-1.751-2.2622-1.751c-1.6538,0-2.7969,1.4111-2.7969,3.4785c0,2.0547,1.1431,3.4775,2.8091,3.4775
				c1.4106,0,2.3594-1.082,2.3833-2.6387h-2.5049v-1.0215h3.6362v4.5361h-1.0703V2108.042z"
          />
          <path
            d="M1051.2871,2104.0645c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2104.0645z"
          />
          <path
            d="M1054.8882,2109.4766c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1057.8433,2108.1387,1056.627,2109.4766,1054.8882,2109.4766z M1054.8882,2108.54
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1053.0396,2107.6162,1053.769,2108.54,1054.8882,2108.54z"
          />
          <path
            d="M1058.6719,2106.1689c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1059.8882,2109.4766,1058.6719,2108.127,1058.6719,2106.1689z M1059.7788,2105.5
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1060.6665,2103.7969,1059.9731,2104.417,1059.7788,2105.5z"
          />
          <path
            d="M1065.5566,2102.9824h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2102.9824z"
          />
          <path
            d="M1071.8579,2106.1689c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1073.0742,2109.4766,1071.8579,2108.127,1071.8579,2106.1689z M1072.9648,2105.5
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1073.8525,2103.7969,1073.1592,2104.417,1072.9648,2105.5z"
          />
          <path
            d="M1084.4844,2104.0645c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2104.0645z"
          />
          <path
            d="M1090.6147,2109.3428h-1.0947v-0.876c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V2109.3428z"
          />
          <path d="M1092.2583,2100.6113h1.1919v1.2646h-1.1919V2100.6113z M1093.4019,2109.3428h-1.0947v-6.3604h1.0947V2109.3428z" />
          <path
            d="M1096.1997,2109.3428h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0337-1.083,2.0308-1.083c1.3135,0,2.104,0.9365,2.104,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V2109.3428z"
          />
          <path
            d="M1105.7485,2103.9189h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2559,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V2103.9189z"
          />
          <path
            d="M1109.1543,2106.1689c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1110.3706,2109.4766,1109.1543,2108.127,1109.1543,2106.1689z M1110.2612,2105.5
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1111.1489,2103.7969,1110.4556,2104.417,1110.2612,2105.5z"
          />
          <path d="M1051.3667,2114.6113h1.1919v1.2646h-1.1919V2114.6113z M1052.5103,2123.3428h-1.0947v-6.3604h1.0947V2123.3428z" />
          <path
            d="M1054.2134,2116.9824h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2116.9824z"
          />
          <path
            d="M1062.96,2120.1689c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1064.1763,2123.4766,1062.96,2122.127,1062.96,2120.1689z M1064.0669,2119.5h3.4292
				c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1064.9546,2117.7969,1064.2612,2118.417,1064.0669,2119.5z"
          />
          <path
            d="M1069.8447,2116.9824h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2116.9824z"
          />
          <path
            d="M1082.3252,2118.0645c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2118.0645z"
          />
          <path
            d="M1085.9263,2123.4766c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1088.8813,2122.1387,1087.665,2123.4766,1085.9263,2123.4766z M1085.9263,2122.54
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1084.0776,2121.6162,1084.8071,2122.54,1085.9263,2122.54z"
          />
          <path
            d="M1090.1479,2116.9824h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2116.9824z"
          />
          <path
            d="M1096.4492,2120.1689c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1097.459,2123.4766,1096.4492,2121.9688,1096.4492,2120.1689z
				 M1099.4165,2117.8213c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1101.2168,2118.4658,1100.3169,2117.8213,1099.4165,2117.8213z"
          />
          <path
            d="M1042.1392,2130.9824l1.313,4.7432l1.3257-4.7432h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H1042.1392z"
          />
          <path
            d="M1049.3408,2137.4766c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1052.2959,2136.1387,1051.0796,2137.4766,1049.3408,2137.4766z M1049.3408,2136.54
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1047.4922,2135.6162,1048.2217,2136.54,1049.3408,2136.54z"
          />
          <path
            d="M1056.0801,2137.4766c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1059.0352,2136.1387,1057.8188,2137.4766,1056.0801,2137.4766z M1056.0801,2136.54
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1054.2314,2135.6162,1054.9609,2136.54,1056.0801,2136.54z"
          />
          <path
            d="M1060.3018,2130.9824h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2130.9824z"
          />
          <path
            d="M1069.4727,2139.666c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3408,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C1072.4277,2138.6318,1071.1997,2139.666,1069.4727,2139.666z M1069.582,2131.8213
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C1071.3457,2132.5029,1070.4941,2131.8213,1069.582,2131.8213z"
          />
          <path
            d="M1073.6943,2134.1689c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1074.9106,2137.4766,1073.6943,2136.127,1073.6943,2134.1689z M1074.8013,2133.5
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1075.689,2131.7969,1074.9956,2132.417,1074.8013,2133.5z"
          />
          <path
            d="M1081.6743,2136.4189v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C1082.7563,2137.4766,1082.0513,2137.0508,1081.6743,2136.4189z M1081.6499,2134.3994c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6685-2.3477-1.8604-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V2134.3994z"
          />
          <path d="M1087.6348,2128.6113h1.1919v1.2646h-1.1919V2128.6113z M1088.7783,2137.3428h-1.0947v-6.3604h1.0947V2137.3428z" />
          <path
            d="M1090.0435,2134.1689c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1091.2598,2137.4766,1090.0435,2136.127,1090.0435,2134.1689z M1091.1504,2133.5
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1092.0381,2131.7969,1091.3447,2132.417,1091.1504,2133.5z"
          />
          <path
            d="M1096.4907,2134.1689c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1097.5005,2137.4766,1096.4907,2135.9688,1096.4907,2134.1689z
				 M1099.458,2131.8213c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1101.2583,2132.4658,1100.3584,2131.8213,1099.458,2131.8213z"
          />
          <path
            d="M1103.5825,2134.1689c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1104.7988,2137.4766,1103.5825,2136.127,1103.5825,2134.1689z M1104.6895,2133.5
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1105.5771,2131.7969,1104.8838,2132.417,1104.6895,2133.5z"
          />
          <path
            d="M1110.4673,2130.9824h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2130.9824z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="25">
        <path
          style={{ fill: '#6B97FF' }}
          className="node"
          d="M2485.395,331.2581c0,25.2651-20.4851,45.75-45.75,45.75c-25.27,0-45.75-20.4849-45.75-45.75
			c0-25.27,20.48-45.75,45.75-45.75C2464.9099,285.5081,2485.395,305.988,2485.395,331.2581"
        />
        <g>
          <path
            d="M2409.3105,326.5527l1.8486,6.9922l1.8613-6.9922h1.1553l-2.3965,8.7314h-1.1426l-1.8613-6.7979l-1.8477,6.7979h-1.1436
				l-2.4082-8.7314h1.2041l1.8604,6.9922l1.8369-6.9922H2409.3105z"
          />
          <path d="M2415.126,326.5527h1.1924v1.2646h-1.1924V326.5527z M2416.2695,335.2842h-1.0947v-6.3604h1.0947V335.2842z" />
          <path
            d="M2417.9727,328.9238h1.0947v0.8633c0.3643-0.5225,0.9971-0.9844,1.8965-0.9844c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1953h-1.0938v-3.9033c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V328.9238z"
          />
          <path
            d="M2425.8066,331.8916l2.8213-2.9678h1.3623l-2.4814,2.5059l2.6875,3.8545h-1.2764l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V331.8916z"
          />
          <path
            d="M2430.6494,332.1104c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2431.8652,335.418,2430.6494,334.0684,2430.6494,332.1104z M2431.7559,331.4414
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C2432.6436,329.7393,2431.9502,330.3594,2431.7559,331.4414z"
          />
          <path d="M2438.6289,335.2842h-1.0947v-8.7314h1.0947V335.2842z" />
          <path
            d="M2442.7646,337.6074c-1.3984,0-2.4209-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.8975v-0.7178c-0.3408,0.6201-1.0576,1.0586-1.9463,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1738c0-1.7275,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.4375,1.9463,1.0576v-0.9365
				h1.0947v5.7891C2445.7197,336.5732,2444.4912,337.6074,2442.7646,337.6074z M2442.874,329.7637
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3369,0.7061,2.2129,1.8613,2.2129c0.9121,0,1.7627-0.6807,1.7627-2.0791v-0.2314
				C2444.6367,330.4443,2443.7861,329.7637,2442.874,329.7637z"
          />
          <path
            d="M2446.9863,332.1104c0-1.8975,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3496,2.7119,3.1133v0.3643h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3398
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2448.2021,335.418,2446.9863,334.0684,2446.9863,332.1104z M2448.0928,331.4414
				h3.4297c-0.0244-0.8994-0.5107-1.7021-1.6182-1.7021C2448.9805,329.7393,2448.2871,330.3594,2448.0928,331.4414z"
          />
          <path
            d="M2454.9658,335.2842h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5225,0.9482-0.9844,1.8359-0.9844
				c0.8516,0,1.4473,0.4258,1.7637,1.082c0.3525-0.5225,1.0332-1.082,2.0312-1.082c1.3125,0,2.1035,0.9365,2.1035,2.2861v4.1953
				h-1.0947v-3.9033c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V335.2842z"
          />
          <path
            d="M2463.8467,333.5693c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1426-1.2529-1.1426
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0547v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4502v0.875
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7168c-0.3887,0.498-1.0938,0.8262-1.958,0.8262
				C2464.7832,335.3936,2463.8467,334.7129,2463.8467,333.5693z M2467.7998,332.0254l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9844,1.1426,0.9844c0.9365,0,1.7275-0.5713,1.7275-1.3857
				V332.0254z"
          />
          <path
            d="M2471.7168,331.8916l2.8213-2.9678h1.3623l-2.4814,2.5059l2.6875,3.8545h-1.2764l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V331.8916z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="24">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2802.8789,1229.047c0,25.269-20.4839,45.75-45.75,45.75c-25.27,0-45.75-20.481-45.75-45.75
			c0-25.266,20.48-45.75,45.75-45.75C2782.395,1183.297,2802.8789,1203.781,2802.8789,1229.047"
        />
        <g>
          <path d="M2732.9629,1219.0732l-3.3203-8.7319h1.2539l2.6992,7.2725l2.6992-7.2725h1.1934l-3.2969,8.7319H2732.9629z" />
          <path
            d="M2741.4785,1213.7954c-0.1816-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9238,0-1.666,0.7295-1.666,1.8486v3.478h-1.0938
				v-6.3604h1.0938v1.0825c0.2793-0.6445,0.9004-1.1313,1.7637-1.1313c0.1699,0,0.3281,0.0122,0.4375,0.0244V1213.7954z"
          />
          <path
            d="M2741.7598,1217.3584c0-1.2646,0.9844-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4023-1.1431-1.2539-1.1431
				c-0.8027,0-1.2773,0.377-1.4707,1.082l-1.0332-0.2676c0.3027-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4219,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6797,0.4502v0.8755
				c-0.9727,0.1216-1.5312-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.957,0.8267
				C2742.6953,1219.1826,2741.7598,1218.5015,2741.7598,1217.3584z M2745.7129,1215.814l-1.2422,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4512,0.9849,1.1426,0.9849c0.9375,0,1.7285-0.5718,1.7285-1.3862
				V1215.814z"
          />
          <path
            d="M2747.7324,1217.3584c0-1.2646,0.9844-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4023-1.1431-1.2539-1.1431
				c-0.8027,0-1.2773,0.377-1.4707,1.082l-1.0332-0.2676c0.3027-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4219,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6797,0.4502v0.8755
				c-0.9727,0.1216-1.5312-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.957,0.8267
				C2748.668,1219.1826,2747.7324,1218.5015,2747.7324,1217.3584z M2751.6855,1215.814l-1.2422,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4512,0.9849,1.1426,0.9849c0.9375,0,1.7285-0.5718,1.7285-1.3862
				V1215.814z"
          />
          <path
            d="M2756.6367,1221.396c-1.3984,0-2.4219-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8633,1.2891,1.752,1.2891
				c1.1172,0,1.8594-0.5713,1.8594-1.897v-0.7178c-0.3398,0.6206-1.0566,1.0581-1.9453,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.875,0,1.6055,0.438,1.9453,1.0581v-0.9365
				h1.0957v5.7886C2759.5918,1220.3623,2758.3633,1221.396,2756.6367,1221.396z M2756.7461,1213.5522
				c-1.1562,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7051,2.2134,1.8613,2.2134c0.9121,0,1.7617-0.6812,1.7617-2.0796v-0.2314
				C2758.5078,1214.2329,2757.6582,1213.5522,2756.7461,1213.5522z"
          />
          <path
            d="M2763.1328,1212.7129h1.0938v0.8633c0.3652-0.5229,0.998-0.9849,1.8965-0.9849c1.3516,0,2.1172,0.9365,2.1172,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6079-1.5938,1.666v3.7944h-1.0938V1212.7129z"
          />
          <path
            d="M2769.0684,1217.3584c0-1.2646,0.9863-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4004-1.1431-1.252-1.1431
				c-0.8027,0-1.2773,0.377-1.4727,1.082l-1.0332-0.2676c0.3047-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4238,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6816,0.4502v0.8755
				c-0.9727,0.1216-1.5332-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.959,0.8267
				C2770.0059,1219.1826,2769.0684,1218.5015,2769.0684,1217.3584z M2773.0215,1215.814l-1.2402,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9355,0,1.7266-0.5718,1.7266-1.3862
				V1215.814z"
          />
          <path
            d="M2775.041,1217.3584c0-1.2646,0.9863-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4004-1.1431-1.252-1.1431
				c-0.8027,0-1.2773,0.377-1.4727,1.082l-1.0332-0.2676c0.3047-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4238,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6816,0.4502v0.8755
				c-0.9727,0.1216-1.5332-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.959,0.8267
				C2775.9785,1219.1826,2775.041,1218.5015,2775.041,1217.3584z M2778.9941,1215.814l-1.2402,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9355,0,1.7266-0.5718,1.7266-1.3862
				V1215.814z"
          />
          <path
            d="M2784.8086,1213.7954c-0.1816-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9238,0-1.666,0.7295-1.666,1.8486v3.478h-1.0938
				v-6.3604h1.0938v1.0825c0.2793-0.6445,0.9004-1.1313,1.7637-1.1313c0.1699,0,0.3281,0.0122,0.4375,0.0244V1213.7954z"
          />
          <path
            d="M2731.8145,1229.8989c0-1.812,1.0098-3.3076,2.7734-3.3076c0.8867,0,1.5918,0.4258,1.9688,1.0459v-3.2959h1.0957v8.7319
				h-1.0957v-0.9243c-0.377,0.6323-1.082,1.0581-1.9688,1.0581C2732.8242,1233.207,2731.8145,1231.6992,2731.8145,1229.8989z
				 M2734.7812,1227.5522c-1.1914,0-1.8594,0.9727-1.8594,2.3467c0,1.3623,0.668,2.3472,1.8594,2.3472
				c0.9004,0,1.8008-0.6567,1.8008-2.1157v-0.4502C2736.582,1228.1968,2735.6816,1227.5522,2734.7812,1227.5522z"
          />
          <path
            d="M2744.0879,1233.0732h-1.0957v-0.8755c-0.3633,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3613,0-2.127-0.9365-2.127-2.2861
				v-4.208h1.0938v3.9766c0,0.8999,0.3887,1.5083,1.3262,1.5083c0.7891,0,1.5918-0.5962,1.5918-1.6543v-3.8306h1.0957V1233.0732z"
          />
          <path
            d="M2750.5234,1233.0732h-1.0957v-0.8755c-0.3633,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3613,0-2.127-0.9365-2.127-2.2861
				v-4.208h1.0938v3.9766c0,0.8999,0.3887,1.5083,1.3262,1.5083c0.7891,0,1.5918-0.5962,1.5918-1.6543v-3.8306h1.0957V1233.0732z"
          />
          <path
            d="M2755.207,1227.7954c-0.1816-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9238,0-1.666,0.7295-1.666,1.8486v3.478h-1.0938
				v-6.3604h1.0938v1.0825c0.2793-0.6445,0.9004-1.1313,1.7637-1.1313c0.1699,0,0.3281,0.0122,0.4375,0.0244V1227.7954z"
          />
          <path d="M2760.584,1233.0732h-5.0098v-0.8271l3.6719-4.5967h-3.625v-0.9365h4.9258v0.8394l-3.5996,4.5845h3.6367V1233.0732z" />
          <path
            d="M2761.084,1231.3584c0-1.2646,0.9844-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4023-1.1431-1.2539-1.1431
				c-0.8027,0-1.2773,0.377-1.4707,1.082l-1.0332-0.2676c0.3027-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4219,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6797,0.4502v0.8755
				c-0.9727,0.1216-1.5312-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.957,0.8267
				C2762.0195,1233.1826,2761.084,1232.5015,2761.084,1231.3584z M2765.0371,1229.814l-1.2422,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4512,0.9849,1.1426,0.9849c0.9375,0,1.7285-0.5718,1.7285-1.3862
				V1229.814z"
          />
          <path
            d="M2767.0566,1231.3584c0-1.2646,0.9844-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4023-1.1431-1.2539-1.1431
				c-0.8027,0-1.2773,0.377-1.4707,1.082l-1.0332-0.2676c0.3027-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4219,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6797,0.4502v0.8755
				c-0.9727,0.1216-1.5312-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.957,0.8267
				C2767.9922,1233.1826,2767.0566,1232.5015,2767.0566,1231.3584z M2771.0098,1229.814l-1.2422,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4512,0.9849,1.1426,0.9849c0.9375,0,1.7285-0.5718,1.7285-1.3862
				V1229.814z"
          />
          <path
            d="M2774.623,1233.0732h-1.0957v-6.3604h1.0957v0.8633c0.3398-0.5229,0.9473-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3516-0.5229,1.0332-1.0825,2.0312-1.0825c1.3125,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0957v-3.9038c0-0.8877-0.3633-1.5566-1.2637-1.5566c-0.7305,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0957v-3.9038
				c0-0.8877-0.3652-1.5566-1.2637-1.5566c-0.7305,0-1.5078,0.6201-1.5078,1.6538V1233.0732z"
          />
          <path d="M2739.9883,1247.0732l-2.3828-6.3604h1.1543l1.7891,5.0225l1.7617-5.0225h1.1445l-2.3848,6.3604H2739.9883z" />
          <path
            d="M2746.6172,1247.207c-1.7383,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2168-3.3076,2.9551-3.3076
				c1.7402,0,2.9551,1.3379,2.9551,3.3076C2749.5723,1245.8691,2748.3574,1247.207,2746.6172,1247.207z M2746.6172,1246.2705
				c1.1191,0,1.8496-0.9243,1.8496-2.3716c0-1.459-0.7305-2.3833-1.8496-2.3833s-1.8477,0.9243-1.8477,2.3833
				C2744.7695,1245.3462,2745.498,1246.2705,2746.6172,1246.2705z"
          />
          <path
            d="M2750.0977,1243.8989c0-1.897,1.2031-3.3076,2.9297-3.3076c1.7754,0,2.7129,1.3501,2.7129,3.1133v0.3647h-4.5977
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C2751.3125,1247.207,2750.0977,1245.8569,2750.0977,1243.8989z M2751.2031,1243.2305
				h3.4297c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026C2752.0918,1241.5278,2751.3984,1242.1479,2751.2031,1243.2305z"
          />
          <path
            d="M2756.2402,1243.8989c0-1.812,1.0098-3.3076,2.7734-3.3076c0.8867,0,1.5918,0.4258,1.9688,1.0459v-3.2959h1.0957v8.7319
				h-1.0957v-0.9243c-0.377,0.6323-1.082,1.0581-1.9688,1.0581C2757.25,1247.207,2756.2402,1245.6992,2756.2402,1243.8989z
				 M2759.207,1241.5522c-1.1914,0-1.8594,0.9727-1.8594,2.3467c0,1.3623,0.668,2.3472,1.8594,2.3472
				c0.9004,0,1.8008-0.6567,1.8008-2.1157v-0.4502C2761.0078,1242.1968,2760.1074,1241.5522,2759.207,1241.5522z"
          />
          <path
            d="M2763.6113,1245.103c0.3418,0.7417,1.1191,1.1919,1.9473,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6094-0.7905-1.6543-1.0093c-1.0469-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9844-1.9214,2.3359-1.9214
				c1.1426,0,2.0781,0.5596,2.4551,1.3135l-0.7773,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7305,0-1.2168,0.3647-1.2168,0.9365c0,0.5957,0.5586,0.7051,1.4473,0.8999c1.0938,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1074,2.0186-2.4688,2.0186c-1.1562,0-2.3105-0.4985-2.7969-1.4839L2763.6113,1245.103z"
          />
          <path
            d="M2768.5156,1243.8989c0-1.897,1.2031-3.3076,2.9297-3.3076c1.7754,0,2.7129,1.3501,2.7129,3.1133v0.3647h-4.5977
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C2769.7305,1247.207,2768.5156,1245.8569,2768.5156,1243.8989z M2769.6211,1243.2305
				h3.4297c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026C2770.5098,1241.5278,2769.8164,1242.1479,2769.6211,1243.2305z"
          />
          <path d="M2776.1914,1247.0732h-1.0957v-8.7319h1.0957V1247.0732z" />
        </g>
      </g>
      <g onClick={handleClick} id="23">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M2141.5081,2435.082c0,25.2659-20.4851,45.75-45.75,45.75c-25.2661,0-45.75-20.4841-45.75-45.75
			c0-25.2661,20.4839-45.75,45.75-45.75C2121.0229,2389.332,2141.5081,2409.8159,2141.5081,2435.082"
        />
        <g>
          <path
            d="M2066.9453,2430.377l1.8486,6.9922l1.8604-6.9922h1.1553l-2.3955,8.7314h-1.1431l-1.8608-6.7979l-1.8486,6.7979h-1.1431
				l-2.4077-8.7314h1.2036l1.8608,6.9922l1.8364-6.9922H2066.9453z"
          />
          <path d="M2072.457,2430.377h1.1919v1.2646h-1.1919V2430.377z M2073.6006,2439.1084h-1.0947v-6.3604h1.0947V2439.1084z" />
          <path
            d="M2074.9995,2432.748h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2432.748z"
          />
          <path
            d="M2082.5293,2435.7158l2.8213-2.9678h1.3618l-2.481,2.5049l2.688,3.8555h-1.2769l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V2435.7158z"
          />
          <path
            d="M2087.0669,2435.9346c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2088.2832,2439.2422,2087.0669,2437.8926,2087.0669,2435.9346z
				 M2088.1738,2435.2656h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C2089.0615,2433.5625,2088.3682,2434.1826,2088.1738,2435.2656z"
          />
          <path d="M2094.7427,2439.1084h-1.0947v-8.7314h1.0947V2439.1084z" />
          <path
            d="M2098.5737,2441.4316c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3408,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C2101.5288,2440.3975,2100.3008,2441.4316,2098.5737,2441.4316z M2098.6831,2433.5869
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C2100.4468,2434.2686,2099.5952,2433.5869,2098.6831,2433.5869z"
          />
          <path
            d="M2102.4912,2435.9346c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2103.7075,2439.2422,2102.4912,2437.8926,2102.4912,2435.9346z
				 M2103.5981,2435.2656h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C2104.4858,2433.5625,2103.7925,2434.1826,2103.5981,2435.2656z"
          />
          <path
            d="M2110.167,2439.1084h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0337-1.083,2.0308-1.083c1.3135,0,2.104,0.9365,2.104,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V2439.1084z"
          />
          <path
            d="M2118.7432,2437.3936c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C2119.6797,2439.2178,2118.7432,2438.5371,2118.7432,2437.3936z M2122.6953,2435.8496l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2435.8496z"
          />
          <path
            d="M2126.3091,2435.7158l2.8213-2.9678h1.3618l-2.481,2.5049l2.688,3.8555h-1.2769l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V2435.7158z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="22">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M2070.887,1228.621c0,25.266-20.481,45.75-45.75,45.75c-25.266,0-45.75-20.484-45.75-45.75
			c0-25.2689,20.484-45.75,45.75-45.75C2050.406,1182.871,2070.887,1203.3521,2070.887,1228.621"
        />
        <g>
          <path
            d="M1994.1831,1202.915l3.3687,8.7319h-1.1797l-0.8877-2.2983h-3.916l-0.8755,2.2983h-1.168l3.3691-8.7319H1994.1831z
				 M1991.9575,1208.3271h3.1377l-1.5811-4.0986L1991.9575,1208.3271z"
          />
          <path
            d="M1997.7363,1209.9321c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1998.6729,1211.7563,1997.7363,1211.0752,1997.7363,1209.9321z M2001.6885,1208.3877l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1208.3877z"
          />
          <path
            d="M2004.2075,1205.2866h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1205.2866z"
          />
          <path
            d="M2014.5952,1205.2866l1.313,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H2014.5952z"
          />
          <path
            d="M2018.5371,1208.4727c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2019.7534,1211.7808,2018.5371,1210.4307,2018.5371,1208.4727z M2019.644,1207.8042
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2020.5317,1206.1016,2019.8384,1206.7217,2019.644,1207.8042z"
          />
          <path d="M2029.7153,1211.647h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8394l-3.5996,4.5845h3.6362V1211.647z" />
          <path d="M2030.665,1202.915h1.1919v1.2651h-1.1919V1202.915z M2031.8086,1211.647h-1.0947v-6.3604h1.0947V1211.647z" />
          <path
            d="M2035.6396,1213.9697c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3408,0.6201-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C2038.5947,1212.936,2037.3667,1213.9697,2035.6396,1213.9697z M2035.749,1206.126
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C2037.5127,1206.8066,2036.6611,1206.126,2035.749,1206.126z"
          />
          <path
            d="M2039.9951,1202.915h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1202.915z"
          />
          <path
            d="M2045.9927,1208.4727c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2047.209,1211.7808,2045.9927,1210.4307,2045.9927,1208.4727z M2047.0996,1207.8042
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2047.9873,1206.1016,2047.2939,1206.7217,2047.0996,1207.8042z"
          />
          <path d="M2052.5249,1202.915h1.1919v1.2651h-1.1919V1202.915z M2053.6685,1211.647h-1.0947v-6.3604h1.0947V1211.647z" />
          <path
            d="M2054.6294,1208.4727c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2055.6392,1211.7808,2054.6294,1210.2729,2054.6294,1208.4727z
				 M2057.5967,1206.126c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2059.397,1206.7705,2058.4971,1206.126,2057.5967,1206.126z"
          />
          <path d="M1993.9888,1225.647l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1993.9888z" />
          <path
            d="M1997.6025,1223.9321c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1998.5391,1225.7563,1997.6025,1225.0752,1997.6025,1223.9321z M2001.5547,1222.3877l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1222.3877z"
          />
          <path
            d="M2004.0737,1219.2866h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1219.2866z"
          />
          <path d="M2014.2793,1225.647l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H2014.2793z" />
          <path
            d="M2017.9531,1222.4727c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2019.1694,1225.7808,2017.9531,1224.4307,2017.9531,1222.4727z
				 M2019.0601,1221.8042h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C2019.9478,1220.1016,2019.2544,1220.7217,2019.0601,1221.8042z"
          />
          <path
            d="M2027.8306,1220.3691c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1220.3691z"
          />
          <path
            d="M2028.1714,1222.4727c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2029.1812,1225.7808,2028.1714,1224.2729,2028.1714,1222.4727z
				 M2031.1387,1220.126c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2032.939,1220.7705,2032.0391,1220.126,2031.1387,1220.126z"
          />
          <path
            d="M2034.9595,1222.4727c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2036.1758,1225.7808,2034.9595,1224.4307,2034.9595,1222.4727z
				 M2036.0664,1221.8042h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C2036.9541,1220.1016,2036.2607,1220.7217,2036.0664,1221.8042z"
          />
          <path d="M2042.6353,1225.647h-1.0947v-8.7319h1.0947V1225.647z" />
          <path
            d="M2043.5962,1222.4727c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2044.8125,1225.7808,2043.5962,1224.4307,2043.5962,1222.4727z
				 M2044.7031,1221.8042h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C2045.5908,1220.1016,2044.8975,1220.7217,2044.7031,1221.8042z"
          />
          <path
            d="M2053.4731,1220.3691c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1220.3691z"
          />
          <path
            d="M2054.3984,1223.6768c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L2054.3984,1223.6768z"
          />
          <path d="M1990.9658,1239.647l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1990.9658z" />
          <path d="M1995.0293,1230.915h1.1919v1.2651h-1.1919V1230.915z M1996.1729,1239.647h-1.0947v-6.3604h1.0947V1239.647z" />
          <path
            d="M1997.0732,1237.9321c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1998.0098,1239.7563,1997.0732,1239.0752,1997.0732,1237.9321z M2001.0254,1236.3877l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1236.3877z"
          />
          <path
            d="M2006.7803,1236.2539l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V1236.2539z"
          />
          <path
            d="M2014.2729,1239.7808c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C2017.228,1238.4429,2016.0117,1239.7808,2014.2729,1239.7808z M2014.2729,1238.8442
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2012.4243,1237.9199,2013.1538,1238.8442,2014.2729,1238.8442z"
          />
          <path
            d="M2021.4863,1234.3691c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1234.3691z"
          />
          <path
            d="M2022.436,1234.2231h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1234.2231z"
          />
          <path
            d="M2025.5381,1236.4727c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2026.7544,1239.7808,2025.5381,1238.4307,2025.5381,1236.4727z M2026.645,1235.8042
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2027.5327,1234.1016,2026.8394,1234.7217,2026.645,1235.8042z"
          />
          <path
            d="M2035.3545,1236.2539l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V1236.2539z"
          />
          <path
            d="M2039.8921,1236.4727c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2041.1084,1239.7808,2039.8921,1238.4307,2039.8921,1236.4727z M2040.999,1235.8042
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2041.8867,1234.1016,2041.1934,1234.7217,2040.999,1235.8042z"
          />
          <path
            d="M2046.6436,1234.2231h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1234.2231z"
          />
          <path
            d="M2049.7456,1236.4727c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2050.9619,1239.7808,2049.7456,1238.4307,2049.7456,1236.4727z
				 M2050.8525,1235.8042h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C2051.7402,1234.1016,2051.0469,1234.7217,2050.8525,1235.8042z"
          />
          <path
            d="M2056.3267,1233.2866h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1233.2866z"
          />
          <path
            d="M2000.2964,1250.4727c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2001.3062,1253.7808,2000.2964,1252.2729,2000.2964,1250.4727z
				 M2003.2637,1248.126c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2005.064,1248.7705,2004.1641,1248.126,2003.2637,1248.126z"
          />
          <path d="M2007.4731,1244.915h1.1919v1.2651h-1.1919V1244.915z M2008.6167,1253.647h-1.0947v-6.3604h1.0947V1253.647z" />
          <path
            d="M2010.1616,1251.6768c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9243-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L2010.1616,1251.6768z"
          />
          <path
            d="M2015.6724,1248.2231h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1248.2231z"
          />
          <path
            d="M2022.5078,1248.3691c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1248.3691z"
          />
          <path d="M2023.2383,1244.915h1.1919v1.2651h-1.1919V1244.915z M2024.3818,1253.647h-1.0947v-6.3604h1.0947V1253.647z" />
          <path
            d="M2026.8755,1252.7227v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C2027.9575,1253.7808,2027.2524,1253.355,2026.8755,1252.7227z M2026.8511,1250.7041c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1250.7041z"
          />
          <path
            d="M2037.6279,1253.647h-1.0942v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8853,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5933-0.5962,1.5933-1.6538v-3.8311h1.0942V1253.647z"
          />
          <path
            d="M2039.1865,1248.2231h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1248.2231z"
          />
          <path d="M2042.6772,1244.915h1.1919v1.2651h-1.1919V1244.915z M2043.8208,1253.647h-1.0947v-6.3604h1.0947V1253.647z" />
          <path
            d="M2044.7817,1250.4727c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2045.998,1253.7808,2044.7817,1252.4307,2044.7817,1250.4727z M2045.8887,1249.8042
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2046.7764,1248.1016,2046.083,1248.7217,2045.8887,1249.8042z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="21">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M4009.105,1249.609c0,25.27-20.48,45.75-45.75,45.75c-25.2649,0-45.75-20.48-45.75-45.75
			c0-25.265,20.4851-45.75,45.75-45.75C3988.625,1203.859,4009.105,1224.344,4009.105,1249.609"
        />
        <g>
          <path
            d="M3917.1309,1245.8594h2.2783c2.1787,0,3.5576,1.3994,3.5576,3.5884c0,2.1885-1.3789,3.5879-3.5576,3.5879h-2.2783
				V1245.8594z M3919.4092,1252.2061c1.6289,0,2.6182-1.0894,2.6182-2.7583c0-1.6792-0.9893-2.7588-2.6279-2.7588h-1.3291v5.5171
				H3919.4092z"
          />
          <path
            d="M3927.4482,1253.0356h-0.8994v-0.7197c-0.2998,0.4399-0.8096,0.8296-1.5498,0.8296c-1.1191,0-1.749-0.7695-1.749-1.8789
				v-3.458h0.9004v3.2681c0,0.7397,0.3193,1.2393,1.0889,1.2393c0.6494,0,1.3096-0.4897,1.3096-1.3589v-3.1484h0.8994V1253.0356z"
          />
          <path
            d="M3932.2881,1253.0356h-0.8994v-0.7197c-0.2998,0.4399-0.8096,0.8296-1.5498,0.8296c-1.1191,0-1.749-0.7695-1.749-1.8789
				v-3.458h0.9004v3.2681c0,0.7397,0.3193,1.2393,1.0889,1.2393c0.6494,0,1.3096-0.4897,1.3096-1.3589v-3.1484h0.8994V1253.0356z"
          />
          <path
            d="M3935.6875,1248.6982c-0.1494-0.0303-0.2695-0.04-0.4395-0.04c-0.7598,0-1.3691,0.5996-1.3691,1.519v2.8584h-0.8994
				v-5.2271h0.8994v0.8896c0.2295-0.5298,0.7393-0.9297,1.4492-0.9297c0.1396,0,0.2695,0.0098,0.3594,0.02V1248.6982z"
          />
          <path d="M3939.6572,1253.0356h-4.1172v-0.6797l3.0176-3.7778h-2.9775v-0.7695h4.0469v0.6895l-2.958,3.7681h2.9883V1253.0356z" />
          <path
            d="M3939.6201,1251.6265c0-1.0396,0.8096-1.4692,1.9785-1.709l1.2695-0.2598v-0.2202c0-0.5996-0.3301-0.9395-1.0293-0.9395
				c-0.6592,0-1.0498,0.3101-1.209,0.8896l-0.8496-0.2197c0.25-0.8496,1.0195-1.4595,2.0889-1.4595
				c1.1689,0,1.8887,0.5698,1.8887,1.689v2.5488c0,0.3398,0.21,0.4497,0.5596,0.3696v0.7197
				c-0.7998,0.1001-1.2588-0.0898-1.3691-0.5898c-0.3193,0.4102-0.8994,0.6797-1.6094,0.6797
				C3940.3896,1253.1255,3939.6201,1252.5659,3939.6201,1251.6265z M3942.8682,1250.3569l-1.0195,0.2202
				c-0.7998,0.1597-1.3389,0.3799-1.3389,1.0093c0,0.5098,0.3691,0.8096,0.9395,0.8096c0.7695,0,1.4189-0.4697,1.4189-1.1392
				V1250.3569z"
          />
          <path
            d="M3944.0801,1251.6265c0-1.0396,0.8096-1.4692,1.9785-1.709l1.2695-0.2598v-0.2202c0-0.5996-0.3301-0.9395-1.0293-0.9395
				c-0.6592,0-1.0498,0.3101-1.209,0.8896l-0.8496-0.2197c0.25-0.8496,1.0195-1.4595,2.0889-1.4595
				c1.1689,0,1.8887,0.5698,1.8887,1.689v2.5488c0,0.3398,0.21,0.4497,0.5596,0.3696v0.7197
				c-0.7998,0.1001-1.2588-0.0898-1.3691-0.5898c-0.3193,0.4102-0.8994,0.6797-1.6094,0.6797
				C3944.8496,1253.1255,3944.0801,1252.5659,3944.0801,1251.6265z M3947.3281,1250.3569l-1.0195,0.2202
				c-0.7998,0.1597-1.3389,0.3799-1.3389,1.0093c0,0.5098,0.3701,0.8096,0.9395,0.8096c0.7695,0,1.4189-0.4697,1.4189-1.1392
				V1250.3569z"
          />
          <path
            d="M3949.8496,1253.0356h-0.8994v-5.2271h0.8994v0.7095c0.2793-0.4297,0.7793-0.8096,1.5088-0.8096
				c0.6992,0,1.1895,0.3501,1.4492,0.8896c0.29-0.4297,0.8496-0.8896,1.6689-0.8896c1.0801,0,1.7295,0.7695,1.7295,1.8789v3.4482
				h-0.8994v-3.208c0-0.73-0.2998-1.2793-1.04-1.2793c-0.5996,0-1.2393,0.5093-1.2393,1.3589v3.1284h-0.8994v-3.208
				c0-0.73-0.2998-1.2793-1.0391-1.2793c-0.5996,0-1.2393,0.5093-1.2393,1.3589V1253.0356z"
          />
          <path
            d="M3956.8594,1245.8594h0.8994v2.6587c0.2998-0.4297,0.8193-0.8096,1.5586-0.8096c1.1094,0,1.7393,0.7695,1.7393,1.8789
				v3.4482h-0.8994v-3.208c0-0.73-0.3096-1.2793-1.0898-1.2793c-0.6494,0-1.3086,0.4995-1.3086,1.3691v3.1182h-0.8994V1245.8594z"
          />
          <path
            d="M3961.3389,1250.4272c0-1.5591,0.9893-2.7188,2.4092-2.7188c1.459,0,2.2285,1.1094,2.2285,2.5586v0.2998h-3.7783
				c0.0498,1.0996,0.6797,1.7993,1.5898,1.7993c0.6992,0,1.1992-0.3799,1.3584-0.9995l0.79,0.2798
				c-0.3203,0.9292-1.1094,1.499-2.1484,1.499C3962.3389,1253.1455,3961.3389,1252.0361,3961.3389,1250.4272z M3962.248,1249.8774
				h2.8193c-0.0205-0.7397-0.4199-1.3994-1.3301-1.3994C3962.9785,1248.478,3962.4082,1248.9878,3962.248,1249.8774z"
          />
          <path d="M3966.2598,1245.8594h0.9795v1.0396h-0.9795V1245.8594z M3967.1992,1253.0356h-0.8994v-5.2271h0.8994V1253.0356z" />
          <path
            d="M3967.5391,1250.4272c0-1.4893,0.8301-2.7188,2.2793-2.7188c0.7295,0,1.3086,0.3501,1.6191,0.8594v-2.7085h0.8994v7.1763
				h-0.8994v-0.7598c-0.3105,0.52-0.8896,0.8696-1.6191,0.8696C3968.3691,1253.1455,3967.5391,1251.9062,3967.5391,1250.4272z
				 M3969.9775,1248.498c-0.9795,0-1.5293,0.7998-1.5293,1.9292c0,1.1191,0.5498,1.9287,1.5293,1.9287
				c0.7402,0,1.4795-0.5396,1.4795-1.7388v-0.3701C3971.457,1249.0278,3970.7178,1248.498,3969.9775,1248.498z"
          />
          <path
            d="M3973.1484,1251.4165c0.2803,0.6099,0.9199,0.9795,1.5996,0.9795c0.6094,0,1.1494-0.2598,1.1494-0.8296
				s-0.5-0.6494-1.3594-0.8296c-0.8594-0.1899-1.7588-0.3799-1.7588-1.4492c0-0.9292,0.8096-1.5791,1.9189-1.5791
				c0.9395,0,1.709,0.46,2.0186,1.0796l-0.6396,0.5098c-0.2695-0.52-0.7598-0.8496-1.4189-0.8496c-0.5996,0-1,0.2998-1,0.7695
				c0,0.4897,0.46,0.5796,1.1895,0.7397c0.8994,0.2095,1.9287,0.3896,1.9287,1.5288c0,0.9995-0.9092,1.6592-2.0283,1.6592
				c-0.9492,0-1.8994-0.4097-2.2988-1.2192L3973.1484,1251.4165z"
          />
          <path
            d="M3977.9883,1253.0356h-0.8994v-5.2271h0.8994v0.7095c0.2793-0.4297,0.7793-0.8096,1.5088-0.8096
				c0.6992,0,1.1895,0.3501,1.4492,0.8896c0.29-0.4297,0.8496-0.8896,1.6689-0.8896c1.0801,0,1.7295,0.7695,1.7295,1.8789v3.4482
				h-0.8994v-3.208c0-0.73-0.2998-1.2793-1.04-1.2793c-0.5996,0-1.2393,0.5093-1.2393,1.3589v3.1284h-0.8994v-3.208
				c0-0.73-0.2998-1.2793-1.0391-1.2793c-0.5996,0-1.2393,0.5093-1.2393,1.3589V1253.0356z"
          />
          <path
            d="M3987.0674,1253.1455c-1.4287,0-2.4287-1.0991-2.4287-2.7183s1-2.7188,2.4287-2.7188
				c1.4297,0,2.4287,1.0996,2.4287,2.7188S3988.4971,1253.1455,3987.0674,1253.1455z M3987.0674,1252.376
				c0.9199,0,1.5195-0.7598,1.5195-1.9487c0-1.1997-0.5996-1.959-1.5195-1.959c-0.9189,0-1.5195,0.7593-1.5195,1.959
				C3985.5479,1251.6162,3986.1484,1252.376,3987.0674,1252.376z"
          />
          <path
            d="M3989.4785,1250.4272c0-1.5591,0.9893-2.7188,2.4092-2.7188c1.459,0,2.2285,1.1094,2.2285,2.5586v0.2998h-3.7783
				c0.0498,1.0996,0.6797,1.7993,1.5898,1.7993c0.6992,0,1.1992-0.3799,1.3584-0.9995l0.79,0.2798
				c-0.3203,0.9292-1.1094,1.499-2.1484,1.499C3990.4785,1253.1455,3989.4785,1252.0361,3989.4785,1250.4272z M3990.3877,1249.8774
				h2.8193c-0.0205-0.7397-0.4199-1.3994-1.3301-1.3994C3991.1182,1248.478,3990.5479,1248.9878,3990.3877,1249.8774z"
          />
          <path
            d="M3994.4395,1245.8594h0.8994v2.6587c0.2998-0.4297,0.8193-0.8096,1.5586-0.8096c1.1094,0,1.7393,0.7695,1.7393,1.8789
				v3.4482h-0.8994v-3.208c0-0.73-0.3096-1.2793-1.0898-1.2793c-0.6494,0-1.3086,0.4995-1.3086,1.3691v3.1182h-0.8994V1245.8594z"
          />
          <path
            d="M3998.9189,1250.4272c0-1.5591,0.9893-2.7188,2.4092-2.7188c1.459,0,2.2285,1.1094,2.2285,2.5586v0.2998h-3.7783
				c0.0498,1.0996,0.6797,1.7993,1.5898,1.7993c0.6992,0,1.1992-0.3799,1.3584-0.9995l0.79,0.2798
				c-0.3203,0.9292-1.1094,1.499-2.1484,1.499C3999.9189,1253.1455,3998.9189,1252.0361,3998.9189,1250.4272z M3999.8281,1249.8774
				h2.8193c-0.0205-0.7397-0.4199-1.3994-1.3301-1.3994C4000.5586,1248.478,3999.9883,1248.9878,3999.8281,1249.8774z"
          />
          <path d="M4003.8389,1245.8594h0.9795v1.0396h-0.9795V1245.8594z M4004.7783,1253.0356h-0.8994v-5.2271h0.8994V1253.0356z" />
          <path
            d="M4005.1191,1250.4272c0-1.4893,0.8301-2.7188,2.2793-2.7188c0.7295,0,1.3086,0.3501,1.6191,0.8594v-2.7085h0.8994v7.1763
				h-0.8994v-0.7598c-0.3105,0.52-0.8896,0.8696-1.6191,0.8696C4005.9492,1253.1455,4005.1191,1251.9062,4005.1191,1250.4272z
				 M4007.5576,1248.498c-0.9795,0-1.5293,0.7998-1.5293,1.9292c0,1.1191,0.5498,1.9287,1.5293,1.9287
				c0.7402,0,1.4795-0.5396,1.4795-1.7388v-0.3701C4009.0371,1249.0278,4008.2979,1248.498,4007.5576,1248.498z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="20">
        <path
          style={{ fill: '#F6FF92' }}
          className="node"
          d="M976.438,1018.7111c0,25.2689-20.485,45.75-45.75,45.75c-25.266,0-45.75-20.4811-45.75-45.75
			c0-25.266,20.484-45.75,45.75-45.75C955.953,972.9611,976.438,993.4451,976.438,1018.7111"
        />
        <g>
          <path d="M909.8965,1000.0054v8.7319h-1.167v-4.001h-4.6816v4.001h-1.168v-8.7319h1.168v3.7095h4.6816v-3.7095H909.8965z" />
          <path
            d="M910.9082,1005.563c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C912.125,1008.8711,910.9082,1007.521,910.9082,1005.563z M912.0156,1004.8945
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C912.9033,1003.1919,912.21,1003.812,912.0156,1004.8945z"
          />
          <path
            d="M920.7852,1003.4595c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9238,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.9004-1.1313,1.7637-1.1313c0.1699,0,0.3281,0.0122,0.4375,0.0244V1003.4595z"
          />
          <path
            d="M923.9971,1011.0601c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2061,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9453,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.875,0,1.6045,0.438,1.9453,1.0581v-0.9365
				h1.0947v5.7886C926.9521,1010.0264,925.7236,1011.0601,923.9971,1011.0601z M924.1064,1003.2163
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C925.8701,1003.897,925.0186,1003.2163,924.1064,1003.2163z"
          />
          <path
            d="M927.9141,1005.563c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647H928.96
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C929.1309,1008.8711,927.9141,1007.521,927.9141,1005.563z M929.0215,1004.8945
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C929.9092,1003.1919,929.2158,1003.812,929.0215,1004.8945z"
          />
          <path
            d="M935.5898,1007.813v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7734,1.4956,2.7734,3.3076c0,1.8003-1.0098,3.3081-2.7734,3.3081
				C936.6719,1008.8711,935.9668,1008.4453,935.5898,1007.813z M935.5654,1005.7944c0,1.459,0.9004,2.1157,1.7998,2.1157
				c1.1924,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.668-2.3467-1.8604-2.3467c-0.8994,0-1.7998,0.6445-1.7998,2.1279V1005.7944z"
          />
          <path
            d="M944.5908,1003.4595c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9238,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.9004-1.1313,1.7637-1.1313c0.1699,0,0.3281,0.0122,0.4375,0.0244V1003.4595z"
          />
          <path
            d="M950.417,1008.7373h-1.0947v-0.8755c-0.3643,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3613,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3887,1.5083,1.3252,1.5083c0.791,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1008.7373z"
          />
          <path d="M951.7568,1000.0054h1.1914v1.2651h-1.1914V1000.0054z M952.9004,1008.7373h-1.0947v-6.3604h1.0947V1008.7373z" />
          <path
            d="M955.3936,1005.3442l2.8213-2.9673h1.3623l-2.4814,2.5054l2.6885,3.855h-1.2773l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V1005.3442z"
          />
          <path d="M924.1553,1022.7373l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H924.1553z" />
          <path
            d="M927.7686,1021.0225c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5439-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.252-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0342-0.2676c0.3047-1.0337,1.2412-1.7754,2.542-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2549,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0947,0.8267-1.958,0.8267
				C928.7051,1022.8467,927.7686,1022.1655,927.7686,1021.0225z M931.7207,1019.478l-1.2402,0.2676
				c-0.9727,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9365,0,1.7266-0.5718,1.7266-1.3862
				V1019.478z"
          />
          <path
            d="M934.2402,1016.377h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1016.377z"
          />
          <path d="M896.1045,1036.7373h-1.0947v-8.7319h1.0947V1036.7373z" />
          <path
            d="M897.0049,1035.0225c0-1.2646,0.9844-1.7876,2.4072-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2764,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.542-1.7754
				c1.4229,0,2.2979,0.6934,2.2979,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6816,0.4502v0.8755
				c-0.9736,0.1216-1.5322-0.1094-1.666-0.7173c-0.3896,0.4985-1.0947,0.8267-1.958,0.8267
				C897.9414,1036.8467,897.0049,1036.1655,897.0049,1035.0225z M900.957,1033.478l-1.2402,0.2676
				c-0.9736,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9355,0,1.7266-0.5718,1.7266-1.3862
				V1033.478z"
          />
          <path
            d="M903.4756,1030.377h1.0947v0.8633c0.3652-0.5229,0.9971-0.9849,1.8975-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1030.377z"
          />
          <path
            d="M909.4727,1033.563c0-1.812,1.0098-3.3076,2.7734-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C910.4824,1036.8711,909.4727,1035.3633,909.4727,1033.563z
				 M912.4404,1031.2163c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C914.2402,1031.8608,913.3408,1031.2163,912.4404,1031.2163z"
          />
          <path
            d="M917.793,1035.813v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7734,1.4956,2.7734,3.3076c0,1.8003-1.0098,3.3081-2.7734,3.3081
				C918.875,1036.8711,918.1699,1036.4453,917.793,1035.813z M917.7686,1033.7944c0,1.459,0.9004,2.1157,1.7998,2.1157
				c1.1924,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.668-2.3467-1.8604-2.3467c-0.8994,0-1.7998,0.6445-1.7998,2.1279V1033.7944z"
          />
          <path
            d="M926.0156,1036.8711c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C928.9707,1035.5332,927.7549,1036.8711,926.0156,1036.8711z M926.0156,1035.9346
				c1.1191,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7295-2.3833-1.8486-2.3833s-1.8486,0.9243-1.8486,2.3833
				C924.167,1035.0103,924.8965,1035.9346,926.0156,1035.9346z"
          />
          <path
            d="M934.9805,1036.7373h-1.0947v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8848,1.0093c-1.3623,0-2.1289-0.9365-2.1289-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1036.7373z"
          />
          <path
            d="M940.3203,1030.377l1.3135,4.7427l1.3262-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3262-4.7188l-1.3252,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H940.3203z"
          />
          <path
            d="M944.2021,1035.0225c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5439-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.252-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0342-0.2676c0.3047-1.0337,1.2412-1.7754,2.542-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2549,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0947,0.8267-1.958,0.8267
				C945.1387,1036.8467,944.2021,1036.1655,944.2021,1035.0225z M948.1543,1033.478l-1.2402,0.2676
				c-0.9727,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9365,0,1.7266-0.5718,1.7266-1.3862
				V1033.478z"
          />
          <path
            d="M953.4463,1028.8691c-0.1826-0.0244-0.292-0.0244-0.4746-0.0244c-0.6445,0-0.9971,0.2798-0.9971,1.0942v0.438h1.4111
				v0.9365h-1.4111v5.4238h-1.0947v-5.4238h-0.96v-0.9365h0.96v-0.5107c0-1.2402,0.6816-1.9946,1.9824-1.9946
				c0.2188,0,0.3408,0.0122,0.584,0.0244V1028.8691z"
          />
          <path d="M955.6113,1036.7373l-2.3828-6.3604h1.1553l1.7871,5.0225l1.7637-5.0225h1.1436l-2.3838,6.3604H955.6113z" />
          <path
            d="M959.2256,1035.0225c0-1.2646,0.9844-1.7876,2.4072-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2764,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.542-1.7754
				c1.4229,0,2.2979,0.6934,2.2979,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6816,0.4502v0.8755
				c-0.9736,0.1216-1.5322-0.1094-1.666-0.7173c-0.3896,0.4985-1.0947,0.8267-1.958,0.8267
				C960.1621,1036.8467,959.2256,1036.1655,959.2256,1035.0225z M963.1777,1033.478l-1.2402,0.2676
				c-0.9736,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9355,0,1.7266-0.5718,1.7266-1.3862
				V1033.478z"
          />
          <path d="M966.791,1036.7373h-1.0947v-8.7319h1.0947V1036.7373z" />
        </g>
      </g>
      <g onClick={handleClick} id="19">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M1274.363,1670.301c0,25.265-20.48,45.75-45.75,45.75c-25.265,0-45.75-20.485-45.75-45.75
			c0-25.266,20.485-45.75,45.75-45.75C1253.8831,1624.551,1274.363,1645.035,1274.363,1670.301"
        />
        <g>
          <path
            d="M1187.2964,1651.5957h3.2227c1.8486,0,2.8335,0.8145,2.8335,2.2734c0,1.1191-0.6812,1.6787-1.3501,1.9102
				c0.9854,0.2676,1.6421,1.0332,1.6421,2.1396c0,1.5205-1.1069,2.4082-2.8701,2.4082h-3.478V1651.5957z M1190.4214,1655.3408
				c1.168,0,1.8125-0.4502,1.8125-1.374c0-0.9365-0.6445-1.3867-1.8125-1.3867h-1.9941v2.7607H1190.4214z M1188.4272,1659.3418
				h2.2983c1.1675,0,1.7998-0.6074,1.7998-1.5078c0-0.8877-0.6323-1.4961-1.7998-1.4961h-2.2983V1659.3418z"
          />
          <path
            d="M1194.1567,1657.1533c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1195.373,1660.4609,1194.1567,1659.1113,1194.1567,1657.1533z M1195.2637,1656.4844
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1196.1514,1654.7812,1195.458,1655.4014,1195.2637,1656.4844z"
          />
          <path
            d="M1200.8838,1658.3574c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L1200.8838,1658.3574z"
          />
          <path
            d="M1208.79,1659.5c0.8755,0,1.3984-0.5596,1.5811-1.3496l0.9731,0.4502c-0.3042,1.0576-1.2285,1.8604-2.5542,1.8604
				c-1.7754,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2285-3.3086,3.0039-3.3086c1.3257,0,2.2256,0.7666,2.5298,1.8242
				l-0.9487,0.4746c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8994-1.9092,2.3594
				C1206.8809,1658.6006,1207.6592,1659.5,1208.79,1659.5z"
          />
          <path
            d="M1212.124,1651.5957h1.0947v3.2344c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1651.5957z"
          />
          <path d="M1218.5103,1651.5957h1.1919v1.2646h-1.1919V1651.5957z M1219.6538,1660.3271h-1.0947v-6.3604h1.0947V1660.3271z" />
          <path
            d="M1222.1475,1656.9346l2.8213-2.9678h1.3618l-2.481,2.5049l2.688,3.8555h-1.2769l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V1656.9346z"
          />
          <path
            d="M1228.2178,1659.4033v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C1229.2998,1660.4609,1228.5947,1660.0352,1228.2178,1659.4033z M1228.1934,1657.3838c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8608-0.9854,1.8608-2.3467c0-1.374-0.6689-2.3477-1.8608-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V1657.3838z"
          />
          <path
            d="M1233.4243,1658.6123c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1234.3608,1660.4365,1233.4243,1659.7559,1233.4243,1658.6123z M1237.3765,1657.0684l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1657.0684z"
          />
          <path
            d="M1239.397,1658.6123c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1240.3335,1660.4365,1239.397,1659.7559,1239.397,1658.6123z M1243.3491,1657.0684l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1657.0684z"
          />
          <path
            d="M1249.1641,1655.0488c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V1655.0488z"
          />
          <path
            d="M1249.9434,1651.5957h1.0947v3.2344c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1651.5957z"
          />
          <path
            d="M1255.9404,1657.1533c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1257.1567,1660.4609,1255.9404,1659.1113,1255.9404,1657.1533z
				 M1257.0474,1656.4844h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031
				C1257.9351,1654.7812,1257.2417,1655.4014,1257.0474,1656.4844z"
          />
          <path d="M1262.4727,1651.5957h1.1919v1.2646h-1.1919V1651.5957z M1263.6162,1660.3271h-1.0947v-6.3604h1.0947V1660.3271z" />
          <path
            d="M1264.5771,1657.1533c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1265.5869,1660.4609,1264.5771,1658.9531,1264.5771,1657.1533z
				 M1267.5444,1654.8057c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1269.3447,1655.4502,1268.4448,1654.8057,1267.5444,1654.8057z"
          />
          <path d="M1222.0801,1674.3271l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1222.0801z" />
          <path
            d="M1225.6938,1672.6123c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1226.6304,1674.4365,1225.6938,1673.7559,1225.6938,1672.6123z M1229.646,1671.0684l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1671.0684z"
          />
          <path
            d="M1232.165,1667.9668h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1667.9668z"
          />
          <path d="M1190.5137,1688.3271h-1.0947v-8.7314h1.0947V1688.3271z" />
          <path
            d="M1191.4141,1686.6123c0-1.2646,0.9849-1.7881,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1192.3506,1688.4365,1191.4141,1687.7559,1191.4141,1686.6123z M1195.3662,1685.0684l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V1685.0684z"
          />
          <path
            d="M1197.8853,1681.9668h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1681.9668z"
          />
          <path
            d="M1203.8823,1685.1533c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1204.8921,1688.4609,1203.8823,1686.9531,1203.8823,1685.1533z
				 M1206.8496,1682.8057c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1208.6499,1683.4502,1207.75,1682.8057,1206.8496,1682.8057z"
          />
          <path
            d="M1212.2026,1687.4033v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C1213.2847,1688.4609,1212.5796,1688.0352,1212.2026,1687.4033z M1212.1782,1685.3838c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6685-2.3477-1.8604-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V1685.3838z"
          />
          <path
            d="M1220.4253,1688.4609c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1223.3804,1687.123,1222.1641,1688.4609,1220.4253,1688.4609z M1220.4253,1687.5244
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1218.5767,1686.6006,1219.3062,1687.5244,1220.4253,1687.5244z"
          />
          <path
            d="M1229.3896,1688.3271h-1.0942v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8853,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5933-0.5957,1.5933-1.6533v-3.8311h1.0942V1688.3271z"
          />
          <path
            d="M1234.73,1681.9668l1.3135,4.7432l1.3257-4.7432h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H1234.73z"
          />
          <path
            d="M1241.5425,1690.6504c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3403,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.6055,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C1244.4976,1689.6162,1243.2695,1690.6504,1241.5425,1690.6504z M1241.6519,1682.8057
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C1243.4155,1683.4873,1242.564,1682.8057,1241.6519,1682.8057z"
          />
          <path
            d="M1249.1938,1683.0488c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V1683.0488z"
          />
          <path
            d="M1252.4907,1688.4609c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1255.4458,1687.123,1254.2295,1688.4609,1252.4907,1688.4609z M1252.4907,1687.5244
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1250.6421,1686.6006,1251.3716,1687.5244,1252.4907,1687.5244z"
          />
          <path
            d="M1256.4082,1681.9668h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1681.9668z"
          />
          <path
            d="M1262.4053,1685.1533c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1263.415,1688.4609,1262.4053,1686.9531,1262.4053,1685.1533z
				 M1265.3726,1682.8057c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1267.1729,1683.4502,1266.2729,1682.8057,1265.3726,1682.8057z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="18">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M859.469,1443.8669c0,25.27-20.481,45.75-45.75,45.75c-25.266,0-45.75-20.48-45.75-45.75
			c0-25.2649,20.484-45.75,45.75-45.75C838.988,1398.1169,859.469,1418.6021,859.469,1443.8669"
        />
        <g>
          <path
            d="M787.5957,1425.1616h2.7725c2.6514,0,4.3291,1.7026,4.3291,4.3662c0,2.6631-1.6777,4.3657-4.3291,4.3657h-2.7725
				V1425.1616z M790.3682,1432.8843c1.9824,0,3.1865-1.3257,3.1865-3.3564c0-2.0435-1.2041-3.3564-3.1992-3.3564h-1.6172v6.7129
				H790.3682z"
          />
          <path
            d="M800.8779,1433.8936h-1.0947v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8848,1.0093c-1.3623,0-2.1289-0.9365-2.1289-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V1433.8936z"
          />
          <path
            d="M807.4951,1433.8936h-1.0947v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8848,1.0093c-1.3623,0-2.1289-0.9365-2.1289-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V1433.8936z"
          />
          <path
            d="M812.3613,1428.6157c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9238,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.9004-1.1313,1.7637-1.1313c0.1699,0,0.3281,0.0122,0.4375,0.0244V1428.6157z"
          />
          <path d="M817.9209,1433.8936h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9248v0.8394l-3.5996,4.5845h3.6367V1433.8936z" />
          <path
            d="M818.6035,1432.1787c0-1.2646,0.9844-1.7876,2.4072-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2764,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.542-1.7754
				c1.4229,0,2.2979,0.6934,2.2979,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6816,0.4502v0.8755
				c-0.9736,0.1216-1.5322-0.1094-1.666-0.7173c-0.3896,0.4985-1.0947,0.8267-1.958,0.8267
				C819.54,1434.0029,818.6035,1433.3218,818.6035,1432.1787z M822.5557,1430.6343l-1.2402,0.2676
				c-0.9736,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9355,0,1.7266-0.5718,1.7266-1.3862
				V1430.6343z"
          />
          <path
            d="M824.7578,1432.1787c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5439-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.252-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0342-0.2676c0.3047-1.0337,1.2412-1.7754,2.542-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2549,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0947,0.8267-1.958,0.8267
				C825.6943,1434.0029,824.7578,1433.3218,824.7578,1432.1787z M828.71,1430.6343l-1.2402,0.2676
				c-0.9727,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9365,0,1.7266-0.5718,1.7266-1.3862
				V1430.6343z"
          />
          <path
            d="M832.5068,1433.8936h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0303-1.0825c1.3135,0,2.1045,0.9365,2.1045,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1433.8936z"
          />
          <path
            d="M787.334,1446.9692v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C788.416,1448.0273,787.7109,1447.6016,787.334,1446.9692z M787.3096,1444.9507c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1444.9507z"
          />
          <path
            d="M795.7393,1448.0273c-1.7393,0-2.9561-1.3379-2.9561-3.3081c0-1.9697,1.2168-3.3076,2.9561-3.3076
				c1.7383,0,2.9551,1.3379,2.9551,3.3076C798.6943,1446.6895,797.4775,1448.0273,795.7393,1448.0273z M795.7393,1447.0908
				c1.1182,0,1.8477-0.9243,1.8477-2.3716c0-1.459-0.7295-2.3833-1.8477-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C793.8906,1446.1665,794.6201,1447.0908,795.7393,1447.0908z"
          />
          <path
            d="M799.4004,1444.7192c0-1.812,1.0098-3.3076,2.7734-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C800.4102,1448.0273,799.4004,1446.5195,799.4004,1444.7192z
				 M802.3682,1442.3726c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C804.168,1443.0171,803.2686,1442.3726,802.3682,1442.3726z"
          />
          <path
            d="M806.3711,1444.7192c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C807.5869,1448.0273,806.3711,1446.6772,806.3711,1444.7192z M807.4775,1444.0508
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C808.3652,1442.3481,807.6719,1442.9683,807.4775,1444.0508z"
          />
          <path
            d="M814.2295,1447.8936h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0303-1.0825c1.3135,0,2.1045,0.9365,2.1045,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1447.8936z"
          />
          <path d="M823.1943,1443.856h3.3203v0.9854h-3.3203V1443.856z" />
          <path
            d="M829.6904,1444.7192c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C830.9062,1448.0273,829.6904,1446.6772,829.6904,1444.7192z M830.7969,1444.0508
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C831.6846,1442.3481,830.9912,1442.9683,830.7969,1444.0508z"
          />
          <path
            d="M836.4541,1441.5332h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1441.5332z"
          />
          <path
            d="M785.6182,1455.5332l1.3125,4.7427l1.3262-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3262-4.7188l-1.3252,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H785.6182z"
          />
          <path
            d="M789.6816,1460.1787c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5439-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.252-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0342-0.2676c0.3047-1.0337,1.2412-1.7754,2.542-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2549,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0947,0.8267-1.958,0.8267
				C790.6182,1462.0029,789.6816,1461.3218,789.6816,1460.1787z M793.6338,1458.6343l-1.2402,0.2676
				c-0.9727,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9365,0,1.7266-0.5718,1.7266-1.3862
				V1458.6343z"
          />
          <path
            d="M796.5059,1456.4697h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2559,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5957,0.0977-0.9609,0.0977
				c-1.082,0-1.7021-0.3652-1.7021-1.5811V1456.4697z"
          />
          <path
            d="M799.79,1458.7192c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C801.0059,1462.0273,799.79,1460.6772,799.79,1458.7192z M800.8965,1458.0508h3.4297
				c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C801.7842,1456.3481,801.0908,1456.9683,800.8965,1458.0508z"
          />
          <path
            d="M809.8496,1456.6157c-0.1826-0.0366-0.3291-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3281,0.0122,0.4385,0.0244V1456.6157z"
          />
          <path
            d="M811.9062,1460.9692v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C812.9883,1462.0273,812.2832,1461.6016,811.9062,1460.9692z M811.8818,1458.9507c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1458.9507z"
          />
          <path
            d="M817.3555,1458.7192c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C818.5713,1462.0273,817.3555,1460.6772,817.3555,1458.7192z M818.4619,1458.0508
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C819.3496,1456.3481,818.6562,1456.9683,818.4619,1458.0508z"
          />
          <path
            d="M824.1191,1453.1616h1.0947v3.2349c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1453.1616z"
          />
          <path
            d="M830.2988,1458.7192c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C831.5146,1462.0273,830.2988,1460.6772,830.2988,1458.7192z M831.4053,1458.0508
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C832.293,1456.3481,831.5996,1456.9683,831.4053,1458.0508z"
          />
          <path
            d="M836.624,1458.7192c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C837.8408,1462.0273,836.624,1460.6772,836.624,1458.7192z M837.7314,1458.0508
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C838.6191,1456.3481,837.9258,1456.9683,837.7314,1458.0508z"
          />
          <path
            d="M846.6836,1456.6157c-0.1826-0.0366-0.3291-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3281,0.0122,0.4385,0.0244V1456.6157z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="17">
        <path
          style={{ fill: '#BAD3FF' }}
          className="node"
          d="M3304.3091,1524.012c0,25.265-20.4851,45.75-45.75,45.75c-25.2661,0-45.75-20.485-45.75-45.75
			c0-25.27,20.4839-45.75,45.75-45.75C3283.824,1478.262,3304.3091,1498.7419,3304.3091,1524.012"
        />
        <g>
          <path d="M3230.6621,1498.3066v8.7314h-1.166v-4.001h-4.6836v4.001h-1.166v-8.7314h1.166v3.709h4.6836v-3.709H3230.6621z" />
          <path
            d="M3231.6133,1503.8643c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652h-4.5957
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3232.8301,1507.1719,3231.6133,1505.8223,3231.6133,1503.8643z
				 M3232.7207,1503.1953h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031
				C3233.6074,1501.4922,3232.9141,1502.1123,3232.7207,1503.1953z"
          />
          <path
            d="M3238.3027,1501.6143h-0.9844v-0.9365h0.9844v-1.8604h1.084v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8867,0.79c0.2559,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2793,0.0605-0.5957,0.0967-0.9609,0.0967
				c-1.082,0-1.7031-0.3643-1.7031-1.5811V1501.6143z"
          />
          <path d="M3245.4922,1507.0381l-2.3828-6.3604h1.1543l1.7891,5.0225l1.7617-5.0225h1.1445l-2.3848,6.3604H3245.4922z" />
          <path
            d="M3249.1055,1503.8643c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652h-4.5957
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3250.3223,1507.1719,3249.1055,1505.8223,3249.1055,1503.8643z
				 M3250.2129,1503.1953h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031
				C3251.0996,1501.4922,3250.4062,1502.1123,3250.2129,1503.1953z"
          />
          <path
            d="M3258.9219,1501.7598c-0.1836-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9258,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0957
				v-6.3604h1.0957v1.082c0.2793-0.6445,0.8984-1.1309,1.7617-1.1309c0.1719,0,0.3301,0.0127,0.4395,0.0244V1501.7598z"
          />
          <path
            d="M3260.7344,1507.0381h-1.0938v-6.3604h1.0938v0.8633c0.3398-0.5225,0.9492-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3535-0.5234,1.0332-1.083,2.0312-1.083c1.3125,0,2.1035,0.9365,2.1035,2.2871v4.1953
				h-1.0938v-3.9033c0-0.8887-0.3652-1.5566-1.2656-1.5566c-0.7285,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0938v-3.9033
				c0-0.8887-0.3652-1.5566-1.2656-1.5566c-0.7285,0-1.5078,0.6201-1.5078,1.6533V1507.0381z"
          />
          <path
            d="M3272.2676,1507.1719c-1.7402,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2148-3.3086,2.9551-3.3086
				c1.7383,0,2.9551,1.3379,2.9551,3.3086C3275.2227,1505.834,3274.0059,1507.1719,3272.2676,1507.1719z M3272.2676,1506.2354
				c1.1172,0,1.8477-0.9238,1.8477-2.3711c0-1.46-0.7305-2.3838-1.8477-2.3838c-1.1191,0-1.8496,0.9238-1.8496,2.3838
				C3270.418,1505.3115,3271.1484,1506.2354,3272.2676,1506.2354z"
          />
          <path
            d="M3278.5566,1509.3613c-1.4004,0-2.4219-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8633,1.2891,1.752,1.2891
				c1.1172,0,1.8594-0.5723,1.8594-1.8975v-0.7178c-0.3398,0.6201-1.0566,1.0586-1.9453,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1748c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.875,0,1.6055,0.4385,1.9453,1.0586v-0.9365
				h1.0957v5.7891C3281.5117,1508.3271,3280.2832,1509.3613,3278.5566,1509.3613z M3278.666,1501.5166
				c-1.1562,0-1.8613,0.8633-1.8613,2.2012s0.7051,2.2139,1.8613,2.2139c0.9121,0,1.7617-0.6816,1.7617-2.0801v-0.2305
				C3280.4277,1502.1982,3279.5781,1501.5166,3278.666,1501.5166z"
          />
          <path
            d="M3282.4121,1503.8643c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652h-4.5957
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3283.6289,1507.1719,3282.4121,1505.8223,3282.4121,1503.8643z
				 M3283.5195,1503.1953h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031
				C3284.4062,1501.4922,3283.7129,1502.1123,3283.5195,1503.1953z"
          />
          <path
            d="M3288.9336,1500.6777h1.0938v0.8633c0.3652-0.5225,0.998-0.9854,1.8965-0.9854c1.3516,0,2.1172,0.9365,2.1172,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6074-1.5938,1.666v3.7939h-1.0938V1500.6777z"
          />
          <path
            d="M3228.377,1521.1719c-1.7383,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2168-3.3086,2.9551-3.3086
				c1.7402,0,2.9551,1.3379,2.9551,3.3086C3231.332,1519.834,3230.1172,1521.1719,3228.377,1521.1719z M3228.377,1520.2354
				c1.1191,0,1.8496-0.9238,1.8496-2.3711c0-1.46-0.7305-2.3838-1.8496-2.3838s-1.8477,0.9238-1.8477,2.3838
				C3226.5293,1519.3115,3227.2578,1520.2354,3228.377,1520.2354z"
          />
          <path
            d="M3233.3301,1521.0381h-1.0957v-6.3604h1.0957v0.8633c0.3398-0.5225,0.9473-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3516-0.5234,1.0332-1.083,2.0312-1.083c1.3125,0,2.1035,0.9365,2.1035,2.2871v4.1953
				h-1.0957v-3.9033c0-0.8887-0.3633-1.5566-1.2637-1.5566c-0.7305,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0957v-3.9033
				c0-0.8887-0.3652-1.5566-1.2637-1.5566c-0.7305,0-1.5078,0.6201-1.5078,1.6533V1521.0381z"
          />
          <path
            d="M3246.8555,1523.3613c-1.3984,0-2.4199-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8652,1.2891,1.752,1.2891
				c1.1191,0,1.8613-0.5723,1.8613-1.8975v-0.7178c-0.3418,0.6201-1.0586,1.0586-1.9473,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1748c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.877,0,1.6055,0.4385,1.9473,1.0586v-0.9365
				h1.0938v5.7891C3249.8105,1522.3271,3248.582,1523.3613,3246.8555,1523.3613z M3246.9648,1515.5166
				c-1.1543,0-1.8613,0.8633-1.8613,2.2012s0.707,2.2139,1.8613,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C3248.7285,1516.1982,3247.877,1515.5166,3246.9648,1515.5166z"
          />
          <path
            d="M3250.7129,1517.8643c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652h-4.5957
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3251.9297,1521.1719,3250.7129,1519.8223,3250.7129,1517.8643z
				 M3251.8203,1517.1953h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031
				C3252.707,1515.4922,3252.0137,1516.1123,3251.8203,1517.1953z"
          />
          <path
            d="M3257.3789,1519.0684c0.3418,0.7412,1.1191,1.1914,1.9473,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6094-0.79-1.6543-1.0088c-1.0469-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9844-1.9219,2.3359-1.9219
				c1.1426,0,2.0781,0.5596,2.4551,1.3135l-0.7773,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7305,0-1.2168,0.3652-1.2168,0.9365c0,0.5957,0.5586,0.7051,1.4473,0.8994c1.0938,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1074,2.0186-2.4688,2.0186c-1.1562,0-2.3105-0.499-2.7969-1.4834L3257.3789,1519.0684z"
          />
          <path
            d="M3265.2246,1520.2109c0.875,0,1.3984-0.5596,1.5801-1.3496l0.9746,0.4502c-0.3047,1.0576-1.2285,1.8604-2.5547,1.8604
				c-1.7754,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2285-3.3086,3.0039-3.3086c1.3262,0,2.2266,0.7666,2.5293,1.8242
				l-0.9492,0.4746c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1309,0-1.9102,0.8994-1.9102,2.3594
				C3263.3145,1519.3115,3264.0938,1520.2109,3265.2246,1520.2109z"
          />
          <path
            d="M3268.498,1512.3066h1.0938v3.2344c0.3652-0.5225,0.998-0.9854,1.8965-0.9854c1.3516,0,2.1172,0.9365,2.1172,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6074-1.5938,1.666v3.7939h-1.0938V1512.3066z"
          />
          <path d="M3274.8223,1512.3066h1.1934v1.2646h-1.1934V1512.3066z M3275.9668,1521.0381h-1.0957v-6.3604h1.0957V1521.0381z" />
          <path
            d="M3278.3984,1517.6455l2.8223-2.9678h1.3613l-2.4805,2.5049l2.6875,3.8555h-1.2773l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0938v-8.7314h1.0938V1517.6455z"
          />
          <path
            d="M3283.4824,1515.6143h-0.9844v-0.9365h0.9844v-1.8604h1.084v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8867,0.79c0.2559,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2793,0.0605-0.5957,0.0967-0.9609,0.0967
				c-1.082,0-1.7031-0.3643-1.7031-1.5811V1515.6143z"
          />
          <path
            d="M3286.5254,1517.8643c0-1.8975,1.2031-3.3086,2.9297-3.3086c1.7754,0,2.7129,1.3506,2.7129,3.1133v0.3652h-4.5977
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8516,0,1.459-0.4629,1.6543-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C3287.7402,1521.1719,3286.5254,1519.8223,3286.5254,1517.8643z
				 M3287.6309,1517.1953h3.4297c-0.0234-0.9004-0.5098-1.7031-1.6172-1.7031
				C3288.5195,1515.4922,3287.8262,1516.1123,3287.6309,1517.1953z"
          />
          <path d="M3218.0195,1535.0381l-2.3828-6.3604h1.1543l1.7891,5.0225l1.7617-5.0225h1.1445l-2.3848,6.3604H3218.0195z" />
          <path
            d="M3224.5879,1535.1719c-1.7383,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2168-3.3086,2.9551-3.3086
				c1.7402,0,2.9551,1.3379,2.9551,3.3086C3227.543,1533.834,3226.3281,1535.1719,3224.5879,1535.1719z M3224.5879,1534.2354
				c1.1191,0,1.8496-0.9238,1.8496-2.3711c0-1.46-0.7305-2.3838-1.8496-2.3838s-1.8477,0.9238-1.8477,2.3838
				C3222.7402,1533.3115,3223.4688,1534.2354,3224.5879,1534.2354z"
          />
          <path
            d="M3228.0078,1531.8643c0-1.8975,1.2031-3.3086,2.9297-3.3086c1.7754,0,2.7129,1.3506,2.7129,3.1133v0.3652h-4.5977
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8516,0,1.459-0.4629,1.6543-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C3229.2227,1535.1719,3228.0078,1533.8223,3228.0078,1531.8643z
				 M3229.1133,1531.1953h3.4297c-0.0234-0.9004-0.5098-1.7031-1.6172-1.7031
				C3230.002,1529.4922,3229.3086,1530.1123,3229.1133,1531.1953z"
          />
          <path
            d="M3234.0898,1531.8643c0-1.8125,1.0098-3.3086,2.7734-3.3086c0.8867,0,1.5918,0.4258,1.9688,1.0459v-3.2949h1.0957v8.7314
				h-1.0957v-0.9238c-0.377,0.6318-1.082,1.0576-1.9688,1.0576C3235.0996,1535.1719,3234.0898,1533.6641,3234.0898,1531.8643z
				 M3237.0566,1529.5166c-1.1914,0-1.8594,0.9736-1.8594,2.3477c0,1.3613,0.668,2.3467,1.8594,2.3467
				c0.9004,0,1.8008-0.6562,1.8008-2.1162v-0.4492C3238.8574,1530.1611,3237.957,1529.5166,3237.0566,1529.5166z"
          />
          <path
            d="M3241.4004,1533.0684c0.3418,0.7412,1.1191,1.1914,1.9473,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6094-0.79-1.6543-1.0088c-1.0469-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9844-1.9219,2.3359-1.9219
				c1.1426,0,2.0781,0.5596,2.4551,1.3135l-0.7773,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7305,0-1.2168,0.3652-1.2168,0.9365c0,0.5957,0.5586,0.7051,1.4473,0.8994c1.0938,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1074,2.0186-2.4688,2.0186c-1.1562,0-2.3105-0.499-2.7969-1.4834L3241.4004,1533.0684z"
          />
          <path
            d="M3246.2441,1531.8643c0-1.8975,1.2031-3.3086,2.9297-3.3086c1.7754,0,2.7129,1.3506,2.7129,3.1133v0.3652h-4.5977
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8516,0,1.459-0.4629,1.6543-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C3247.459,1535.1719,3246.2441,1533.8223,3246.2441,1531.8643z M3247.3496,1531.1953
				h3.4297c-0.0234-0.9004-0.5098-1.7031-1.6172-1.7031C3248.2383,1529.4922,3247.5449,1530.1123,3247.3496,1531.1953z"
          />
          <path d="M3253.8574,1535.0381h-1.0938v-8.7314h1.0938V1535.0381z" />
          <path
            d="M3256.291,1531.6455l2.8203-2.9678h1.3633l-2.4824,2.5049l2.6875,3.8555h-1.2754l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0957v-8.7314h1.0957V1531.6455z"
          />
          <path
            d="M3260.7676,1531.8643c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652h-4.5957
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3261.9844,1535.1719,3260.7676,1533.8223,3260.7676,1531.8643z M3261.875,1531.1953
				h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031C3262.7617,1529.4922,3262.0684,1530.1123,3261.875,1531.1953z"
          />
          <path
            d="M3272.3359,1535.0381h-1.0957v-0.876c-0.3633,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3613,0-2.127-0.9365-2.127-2.2861
				v-4.208h1.0938v3.9766c0,0.9004,0.3887,1.5078,1.3262,1.5078c0.7891,0,1.5918-0.5957,1.5918-1.6533v-3.8311h1.0957V1535.0381z"
          />
          <path d="M3278.2578,1535.0381h-5.0098v-0.8271l3.6719-4.5967h-3.623v-0.9365h4.9258v0.8389l-3.5996,4.585h3.6348V1535.0381z" />
          <path
            d="M3278.7598,1531.8643c0-1.8975,1.2031-3.3086,2.9297-3.3086c1.7754,0,2.7129,1.3506,2.7129,3.1133v0.3652h-4.5977
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8516,0,1.459-0.4629,1.6543-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C3279.9746,1535.1719,3278.7598,1533.8223,3278.7598,1531.8643z
				 M3279.8652,1531.1953h3.4297c-0.0234-0.9004-0.5098-1.7031-1.6172-1.7031
				C3280.7539,1529.4922,3280.0605,1530.1123,3279.8652,1531.1953z"
          />
          <path
            d="M3285.4258,1533.0684c0.3398,0.7412,1.1191,1.1914,1.9453,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6074-0.79-1.6543-1.0088c-1.0449-0.2314-2.1387-0.4619-2.1387-1.7637c0-1.1309,0.9844-1.9219,2.334-1.9219
				c1.1426,0,2.0801,0.5596,2.457,1.3135l-0.7793,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7285,0-1.2148,0.3652-1.2148,0.9365c0,0.5957,0.5586,0.7051,1.4453,0.8994c1.0957,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1055,2.0186-2.4688,2.0186c-1.1543,0-2.3105-0.499-2.7969-1.4834L3285.4258,1533.0684z"
          />
          <path
            d="M3292.9551,1529.6143h-0.9844v-0.9365h0.9844v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8887,0.79c0.2539,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2812,0.0605-0.5977,0.0967-0.9609,0.0967
				c-1.084,0-1.7031-0.3643-1.7031-1.5811V1529.6143z"
          />
          <path
            d="M3295.9961,1531.8643c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652h-4.5957
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3297.2129,1535.1719,3295.9961,1533.8223,3295.9961,1531.8643z
				 M3297.1035,1531.1953h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031
				C3297.9902,1529.4922,3297.2969,1530.1123,3297.1035,1531.1953z"
          />
          <path d="M3221.1211,1540.3066h1.1934v1.2646h-1.1934V1540.3066z M3222.2656,1549.0381h-1.0957v-6.3604h1.0957V1549.0381z" />
          <path
            d="M3224.6973,1549.0381h-1.0938v-6.3604h1.0938v0.8633c0.3398-0.5225,0.9492-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3535-0.5234,1.0332-1.083,2.0312-1.083c1.3125,0,2.1035,0.9365,2.1035,2.2871v4.1953
				h-1.0938v-3.9033c0-0.8887-0.3652-1.5566-1.2656-1.5566c-0.7285,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0938v-3.9033
				c0-0.8887-0.3652-1.5566-1.2656-1.5566c-0.7285,0-1.5078,0.6201-1.5078,1.6533V1549.0381z"
          />
          <path
            d="M3234.8066,1548.1143v3.1133h-1.0957v-8.5498h1.0957v0.9238c0.377-0.6201,1.082-1.0459,1.9688-1.0459
				c1.7637,0,2.7734,1.4961,2.7734,3.3086c0,1.7998-1.0098,3.3076-2.7734,3.3076
				C3235.8887,1549.1719,3235.1836,1548.7461,3234.8066,1548.1143z M3234.7812,1546.0947c0,1.46,0.9004,2.1162,1.8008,2.1162
				c1.1914,0,1.8594-0.9854,1.8594-2.3467c0-1.374-0.668-2.3477-1.8594-2.3477c-0.9004,0-1.8008,0.6445-1.8008,2.1289V1546.0947z"
          />
          <path d="M3241.5449,1549.0381h-1.0938v-8.7314h1.0938V1549.0381z" />
          <path
            d="M3242.4453,1545.8643c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652h-4.5957
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3243.6621,1549.1719,3242.4453,1547.8223,3242.4453,1545.8643z
				 M3243.5527,1545.1953h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031
				C3244.4395,1543.4922,3243.7461,1544.1123,3243.5527,1545.1953z"
          />
          <path
            d="M3250.0605,1549.0381h-1.0938v-6.3604h1.0938v0.8633c0.3398-0.5225,0.9492-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3535-0.5234,1.0332-1.083,2.0312-1.083c1.3125,0,2.1035,0.9365,2.1035,2.2871v4.1953
				h-1.0938v-3.9033c0-0.8887-0.3652-1.5566-1.2656-1.5566c-0.7285,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0938v-3.9033
				c0-0.8887-0.3652-1.5566-1.2656-1.5566c-0.7285,0-1.5078,0.6201-1.5078,1.6533V1549.0381z"
          />
          <path
            d="M3258.6367,1545.8643c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652h-4.5957
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3259.8535,1549.1719,3258.6367,1547.8223,3258.6367,1545.8643z
				 M3259.7441,1545.1953h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031
				C3260.6309,1543.4922,3259.9375,1544.1123,3259.7441,1545.1953z"
          />
          <path
            d="M3265.1582,1542.6777h1.0938v0.8633c0.3652-0.5225,0.998-0.9854,1.8965-0.9854c1.3516,0,2.1172,0.9365,2.1172,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6074-1.5938,1.666v3.7939h-1.0938V1542.6777z"
          />
          <path
            d="M3271.7012,1543.6143h-0.9844v-0.9365h0.9844v-1.8604h1.084v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8867,0.79c0.2559,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2793,0.0605-0.5957,0.0967-0.9609,0.0967
				c-1.082,0-1.7031-0.3643-1.7031-1.5811V1543.6143z"
          />
          <path
            d="M3274.7441,1545.8643c0-1.8975,1.2031-3.3086,2.9297-3.3086c1.7754,0,2.7129,1.3506,2.7129,3.1133v0.3652h-4.5977
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8516,0,1.459-0.4629,1.6543-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C3275.959,1549.1719,3274.7441,1547.8223,3274.7441,1545.8643z M3275.8496,1545.1953
				h3.4297c-0.0234-0.9004-0.5098-1.7031-1.6172-1.7031C3276.7383,1543.4922,3276.0449,1544.1123,3275.8496,1545.1953z"
          />
          <path
            d="M3284.5586,1543.7598c-0.1816-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9238,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0938
				v-6.3604h1.0938v1.082c0.2793-0.6445,0.9004-1.1309,1.7637-1.1309c0.1699,0,0.3281,0.0127,0.4375,0.0244V1543.7598z"
          />
          <path
            d="M3284.8398,1545.8643c0-1.8975,1.2051-3.3086,2.9316-3.3086c1.7754,0,2.7109,1.3506,2.7109,3.1133v0.3652h-4.5957
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8496,0,1.459-0.4629,1.6523-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C3286.0566,1549.1719,3284.8398,1547.8223,3284.8398,1545.8643z
				 M3285.9473,1545.1953h3.4297c-0.0254-0.9004-0.5117-1.7031-1.6191-1.7031
				C3286.834,1543.4922,3286.1406,1544.1123,3285.9473,1545.1953z"
          />
          <path
            d="M3291.3613,1542.6777h1.0938v0.8633c0.3652-0.5225,0.998-0.9854,1.8965-0.9854c1.3516,0,2.1172,0.9365,2.1172,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3789-1.5566-1.3262-1.5566c-0.791,0-1.5938,0.6074-1.5938,1.666v3.7939h-1.0938V1542.6777z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="16">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M750.711,2491.156c0,25.2661-20.484,45.75-45.75,45.75s-45.75-20.4839-45.75-45.75
			c0-25.269,20.484-45.75,45.75-45.75S750.711,2465.887,750.711,2491.156"
        />
        <g>
          <path d="M667.9614,2481.1826l-3.3198-8.7314h1.2524l2.6997,7.2725l2.6997-7.2725h1.1919l-3.2959,8.7314H667.9614z" />
          <path
            d="M675.6372,2481.3164c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C678.5923,2479.9785,677.376,2481.3164,675.6372,2481.3164z M675.6372,2480.3799
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C673.7886,2479.4561,674.5181,2480.3799,675.6372,2480.3799z"
          />
          <path
            d="M682.0117,2481.3164c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C684.9668,2479.9785,683.7505,2481.3164,682.0117,2481.3164z M682.0117,2480.3799
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C680.1631,2479.4561,680.8926,2480.3799,682.0117,2480.3799z"
          />
          <path
            d="M689.1641,2475.9043c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2475.9043z"
          />
          <path d="M690.9775,2481.1826h-1.0947v-8.7314h1.0947V2481.1826z" />
          <path d="M692.2666,2472.4512h1.1919v1.2646h-1.1919V2472.4512z M693.4102,2481.1826h-1.0947v-6.3604h1.0947V2481.1826z" />
          <path
            d="M697.3145,2480.3555c0.8755,0,1.3984-0.5596,1.5811-1.3496l0.9727,0.4502c-0.3037,1.0576-1.228,1.8604-2.5537,1.8604
				c-1.7754,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2285-3.3086,3.0039-3.3086c1.3257,0,2.2256,0.7666,2.5298,1.8242
				l-0.9487,0.4746c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8994-1.9092,2.3594
				C695.4053,2479.4561,696.1836,2480.3555,697.3145,2480.3555z"
          />
          <path
            d="M700.5874,2472.4512h1.0947v3.2344c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2472.4512z"
          />
          <path
            d="M707.1323,2475.7588h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2554,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V2475.7588z"
          />
          <path d="M710.562,2472.4512h1.1919v1.2646h-1.1919V2472.4512z M711.7056,2481.1826h-1.0947v-6.3604h1.0947V2481.1826z" />
          <path
            d="M713.0439,2474.8223h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2474.8223z"
          />
          <path
            d="M721.8501,2483.5059c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3408,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C724.8052,2482.4717,723.5771,2483.5059,721.8501,2483.5059z M721.9595,2475.6611
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C723.7231,2476.3428,722.8716,2475.6611,721.9595,2475.6611z"
          />
          <path d="M729.8545,2481.1826l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H729.8545z" />
          <path
            d="M733.4072,2479.4678c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C734.3438,2481.292,733.4072,2480.6113,733.4072,2479.4678z M737.3594,2477.9238l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2477.9238z"
          />
          <path
            d="M739.8179,2474.8223h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2474.8223z"
          />
          <path d="M662.3906,2495.1826h-1.0947v-8.7314h1.0947V2495.1826z" />
          <path
            d="M663.23,2493.4678c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C664.1665,2495.292,663.23,2494.6113,663.23,2493.4678z M667.1821,2491.9238l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2491.9238z"
          />
          <path
            d="M669.6406,2488.8223h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3501,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2488.8223z"
          />
          <path
            d="M675.5767,2492.0088c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C676.5864,2495.3164,675.5767,2493.8086,675.5767,2492.0088z
				 M678.5439,2489.6611c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C680.3442,2490.3057,679.4443,2489.6611,678.5439,2489.6611z"
          />
          <path
            d="M683.8364,2494.2588v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C684.9185,2495.3164,684.2134,2494.8906,683.8364,2494.2588z M683.812,2492.2393c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8608-0.9854,1.8608-2.3467c0-1.374-0.6689-2.3477-1.8608-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V2492.2393z"
          />
          <path
            d="M691.998,2495.3164c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C694.9531,2493.9785,693.7368,2495.3164,691.998,2495.3164z M691.998,2494.3799
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C690.1494,2493.4561,690.8789,2494.3799,691.998,2494.3799z"
          />
          <path
            d="M700.9019,2495.1826h-1.0942v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8853,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5933-0.5957,1.5933-1.6533v-3.8311h1.0942V2495.1826z"
          />
          <path
            d="M706.1816,2488.8223l1.313,4.7432l1.3257-4.7432h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H706.1816z"
          />
          <path
            d="M710.063,2492.0088c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C711.2793,2495.3164,710.063,2493.9668,710.063,2492.0088z M711.1699,2491.3398
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C712.0576,2489.6367,711.3643,2490.2568,711.1699,2491.3398z"
          />
          <path
            d="M719.8789,2489.9043c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2489.9043z"
          />
          <path
            d="M720.7437,2493.2129c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L720.7437,2493.2129z"
          />
          <path
            d="M730.6211,2495.3164c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C733.5762,2493.9785,732.3599,2495.3164,730.6211,2495.3164z M730.6211,2494.3799
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C728.7725,2493.4561,729.502,2494.3799,730.6211,2494.3799z"
          />
          <path d="M736.1069,2495.1826l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H736.1069z" />
          <path
            d="M739.7202,2492.0088c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C740.9365,2495.3164,739.7202,2493.9668,739.7202,2492.0088z M740.8271,2491.3398
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C741.7148,2489.6367,741.0215,2490.2568,740.8271,2491.3398z"
          />
          <path
            d="M749.5366,2489.9043c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2489.9043z"
          />
          <path
            d="M669.0508,2506.0088c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C670.0605,2509.3164,669.0508,2507.8086,669.0508,2506.0088z
				 M672.0181,2503.6611c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C673.8184,2504.3057,672.9185,2503.6611,672.0181,2503.6611z"
          />
          <path
            d="M681.2622,2509.1826h-1.0942v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8853,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5933-0.5957,1.5933-1.6533v-3.8311h1.0942V2509.1826z"
          />
          <path
            d="M687.6367,2509.1826h-1.0947v-0.876c-0.3647,0.5352-0.9849,1.0098-1.8848,1.0098c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3892,1.5078,1.3257,1.5078c0.7905,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V2509.1826z"
          />
          <path
            d="M692.2598,2503.9043c-0.1826-0.0361-0.3286-0.0479-0.5352-0.0479c-0.9243,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8999-1.1309,1.7632-1.1309c0.1704,0,0.3281,0.0127,0.438,0.0244V2503.9043z"
          />
          <path d="M697.5757,2509.1826h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8389l-3.5996,4.585h3.6362V2509.1826z" />
          <path
            d="M698.0146,2507.4678c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C698.9512,2509.292,698.0146,2508.6113,698.0146,2507.4678z M701.9668,2505.9238l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2505.9238z"
          />
          <path
            d="M703.9268,2507.4678c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C704.8633,2509.292,703.9268,2508.6113,703.9268,2507.4678z M707.8789,2505.9238l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V2505.9238z"
          />
          <path
            d="M711.4316,2509.1826h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0337-1.083,2.0308-1.083c1.3135,0,2.104,0.9365,2.104,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V2509.1826z"
          />
          <path
            d="M720.4458,2500.4512h1.0947v3.2344c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V2500.4512z"
          />
          <path
            d="M726.3818,2506.0088c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C727.5981,2509.3164,726.3818,2507.9668,726.3818,2506.0088z M727.4888,2505.3398
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C728.3765,2503.6367,727.6831,2504.2568,727.4888,2505.3398z"
          />
          <path d="M732.8535,2500.4512h1.1919v1.2646h-1.1919V2500.4512z M733.9971,2509.1826h-1.0947v-6.3604h1.0947V2509.1826z" />
          <path
            d="M734.897,2506.0088c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C735.9067,2509.3164,734.897,2507.8086,734.897,2506.0088z
				 M737.8643,2503.6611c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C739.6646,2504.3057,738.7646,2503.6611,737.8643,2503.6611z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="15">
        <path
          style={{ fill: '#9C7AFF' }}
          className="node"
          d="M3735.543,1121.7111c0,25.2689-20.4839,45.75-45.75,45.75c-25.27,0-45.75-20.4811-45.75-45.75
			c0-25.2661,20.48-45.75,45.75-45.75C3715.0591,1075.9611,3735.543,1096.4449,3735.543,1121.7111"
        />
        <g>
          <path
            d="M3672.6816,1097.7373l-2.6631-7.1875v7.1875h-1.0947v-8.7319h1.5928l2.6514,7.1753l2.6631-7.1753h1.5449v8.7319h-1.083
				v-7.1875l-2.6748,7.1875H3672.6816z"
          />
          <path
            d="M3677.9736,1096.0225c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3678.9102,1097.8467,3677.9736,1097.1655,3677.9736,1096.0225z M3681.9268,1094.478l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1094.478z"
          />
          <path
            d="M3684.251,1092.3135h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1092.3135z"
          />
          <path
            d="M3686.9883,1094.563c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3688.2041,1097.8711,3686.9883,1096.521,3686.9883,1094.563z M3688.0947,1093.8945
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3688.9824,1092.1919,3688.2891,1092.812,3688.0947,1093.8945z"
          />
          <path d="M3696.6104,1097.7373l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3696.6104z" />
          <path
            d="M3699.8574,1096.0225c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3700.7939,1097.8467,3699.8574,1097.1655,3699.8574,1096.0225z M3703.8105,1094.478l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4502,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1094.478z"
          />
          <path
            d="M3705.9639,1091.377h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1091.377z"
          />
          <path
            d="M3655.9141,1110.9102c0.875,0,1.3984-0.5591,1.5801-1.3496l0.9736,0.4497c-0.3047,1.0581-1.2285,1.8608-2.5537,1.8608
				c-1.7764,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7661,2.5293,1.8242
				l-0.9492,0.4741c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9102,0.8999-1.9102,2.3589
				C3654.0039,1110.0103,3654.7822,1110.9102,3655.9141,1110.9102z"
          />
          <path
            d="M3661.3994,1111.8711c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3664.3545,1110.5332,3663.1387,1111.8711,3661.3994,1111.8711z M3661.3994,1110.9346
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3659.5508,1110.0103,3660.2803,1110.9346,3661.3994,1110.9346z"
          />
          <path
            d="M3664.9521,1103.0054h1.0947v3.2349c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1103.0054z"
          />
          <path
            d="M3670.585,1108.563c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3671.8008,1111.8711,3670.585,1110.521,3670.585,1108.563z M3671.6914,1107.8945
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3672.5791,1106.1919,3671.8857,1106.812,3671.6914,1107.8945z"
          />
          <path
            d="M3680.0967,1106.4595c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1106.4595z"
          />
          <path
            d="M3680.0742,1108.563c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3681.29,1111.8711,3680.0742,1110.521,3680.0742,1108.563z M3681.1807,1107.8945
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3682.0684,1106.1919,3681.375,1106.812,3681.1807,1107.8945z"
          />
          <path
            d="M3686.29,1105.377h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1105.377z"
          />
          <path
            d="M3692.5293,1106.3135h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1106.3135z"
          />
          <path d="M3695.6562,1103.0054h1.1924v1.2651h-1.1924V1103.0054z M3696.7998,1111.7373h-1.0947v-6.3604h1.0947V1111.7373z" />
          <path
            d="M3697.3955,1108.563c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3698.6113,1111.8711,3697.3955,1110.521,3697.3955,1108.563z M3698.502,1107.8945
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3699.3896,1106.1919,3698.6963,1106.812,3698.502,1107.8945z"
          />
          <path d="M3705.3379,1103.0054h1.1924v1.2651h-1.1924V1103.0054z M3706.4814,1111.7373h-1.0947v-6.3604h1.0947V1111.7373z" />
          <path
            d="M3707.5156,1105.377h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1105.377z"
          />
          <path
            d="M3714.9238,1108.563c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3715.9326,1111.8711,3714.9238,1110.3633,3714.9238,1108.563z
				 M3717.8906,1106.2163c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3719.6904,1106.8608,3718.791,1106.2163,3717.8906,1106.2163z"
          />
          <path
            d="M3721.3477,1108.563c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3722.5635,1111.8711,3721.3477,1110.521,3721.3477,1108.563z M3722.4541,1107.8945
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3723.3418,1106.1919,3722.6484,1106.812,3722.4541,1107.8945z"
          />
          <path
            d="M3647.5938,1124.813v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C3648.6758,1125.8711,3647.9707,1125.4453,3647.5938,1124.813z M3647.5693,1122.7944c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V1122.7944z"
          />
          <path
            d="M3652.4961,1122.563c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3653.7119,1125.8711,3652.4961,1124.521,3652.4961,1122.563z M3653.6025,1121.8945
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3654.4902,1120.1919,3653.7969,1120.812,3653.6025,1121.8945z"
          />
          <path
            d="M3662.0088,1120.4595c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1120.4595z"
          />
          <path d="M3662.374,1117.0054h1.1924v1.2651h-1.1924V1117.0054z M3663.5176,1125.7373h-1.0947v-6.3604h1.0947V1125.7373z" />
          <path
            d="M3667.1172,1124.9102c0.875,0,1.3984-0.5591,1.5801-1.3496l0.9736,0.4497c-0.3047,1.0581-1.2285,1.8608-2.5537,1.8608
				c-1.7764,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7661,2.5293,1.8242
				l-0.9492,0.4741c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9102,0.8999-1.9102,2.3589
				C3665.207,1124.0103,3665.9854,1124.9102,3667.1172,1124.9102z"
          />
          <path
            d="M3670.0859,1117.0054h1.0947v3.2349c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1117.0054z"
          />
          <path
            d="M3676.3262,1120.3135h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0732v0.9365c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V1120.3135z"
          />
          <path
            d="M3681.9336,1128.0601c-1.3984,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C3684.8887,1127.0264,3683.6602,1128.0601,3681.9336,1128.0601z M3682.043,1120.2163
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C3683.8057,1120.897,3682.9551,1120.2163,3682.043,1120.2163z"
          />
          <path
            d="M3685.4873,1122.563c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3686.7031,1125.8711,3685.4873,1124.521,3685.4873,1122.563z M3686.5938,1121.8945
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3687.4814,1120.1919,3686.7881,1120.812,3686.5938,1121.8945z"
          />
          <path d="M3693.333,1125.7373l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3693.333z" />
          <path d="M3697.0303,1117.0054h1.1924v1.2651h-1.1924V1117.0054z M3698.1738,1125.7373h-1.0947v-6.3604h1.0947V1125.7373z" />
          <path
            d="M3699.207,1119.377h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1119.377z"
          />
          <path
            d="M3707.71,1128.0601c-1.3994,0-2.4209-0.6445-2.7734-1.812l1.0215-0.3892c0.207,0.8149,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5713,1.8604-1.897v-0.7178c-0.3408,0.6206-1.0576,1.0581-1.9463,1.0581
				c-1.7383,0-2.7598-1.4351-2.7598-3.1738c0-1.7271,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.438,1.9463,1.0581v-0.9365
				h1.0947v5.7886C3710.665,1127.0264,3709.4365,1128.0601,3707.71,1128.0601z M3707.8193,1120.2163
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012c0,1.3374,0.7061,2.2134,1.8613,2.2134c0.9121,0,1.7627-0.6812,1.7627-2.0796v-0.2314
				C3709.582,1120.897,3708.7314,1120.2163,3707.8193,1120.2163z"
          />
          <path
            d="M3715.9932,1125.8711c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3718.9482,1124.5332,3717.7324,1125.8711,3715.9932,1125.8711z M3715.9932,1124.9346
				c1.1182,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7305-2.3833-1.8486-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C3714.1445,1124.0103,3714.874,1124.9346,3715.9932,1124.9346z"
          />
          <path d="M3721.1758,1125.7373l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3721.1758z" />
          <path
            d="M3724.4844,1122.563c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3725.7002,1125.8711,3724.4844,1124.521,3724.4844,1122.563z M3725.5908,1121.8945
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3726.4785,1120.1919,3725.7852,1120.812,3725.5908,1121.8945z"
          />
          <path
            d="M3733.9961,1120.4595c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3291,0.0122,0.4385,0.0244V1120.4595z"
          />
          <path d="M3654.1562,1139.7373h-1.0947v-8.7319h1.0947V1139.7373z" />
          <path
            d="M3654.752,1136.563c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3655.9678,1139.8711,3654.752,1138.521,3654.752,1136.563z M3655.8584,1135.8945
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3656.7461,1134.1919,3656.0527,1134.812,3655.8584,1135.8945z"
          />
          <path d="M3662.5986,1139.7373l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3662.5986z" />
          <path
            d="M3665.9072,1136.563c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3667.123,1139.8711,3665.9072,1138.521,3665.9072,1136.563z M3667.0137,1135.8945
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3667.9014,1134.1919,3667.208,1134.812,3667.0137,1135.8945z"
          />
          <path
            d="M3672.123,1133.377h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1133.377z"
          />
          <path
            d="M3678.3389,1137.7671c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1396-0.4624-2.1396-1.7637c0-1.1309,0.9844-1.9214,2.335-1.9214
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7295,0-1.2158,0.3647-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7969-1.4839L3678.3389,1137.7671z"
          />
          <path
            d="M3684.4092,1139.7373h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0312-1.0825c1.3135,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1139.7373z"
          />
          <path d="M3693.0703,1131.0054h1.1924v1.2651h-1.1924V1131.0054z M3694.2139,1139.7373h-1.0947v-6.3604h1.0947V1139.7373z" />
          <path
            d="M3694.8105,1136.563c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3695.8193,1139.8711,3694.8105,1138.3633,3694.8105,1136.563z
				 M3697.7773,1134.2163c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3699.5771,1134.8608,3698.6777,1134.2163,3697.7773,1134.2163z"
          />
          <path
            d="M3701.2334,1136.563c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3702.2422,1139.8711,3701.2334,1138.3633,3701.2334,1136.563z
				 M3704.2002,1134.2163c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3706,1134.8608,3705.1006,1134.2163,3704.2002,1134.2163z"
          />
          <path
            d="M3707.6562,1136.563c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3708.8721,1139.8711,3707.6562,1138.521,3707.6562,1136.563z M3708.7627,1135.8945
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3709.6504,1134.1919,3708.957,1134.812,3708.7627,1135.8945z"
          />
          <path d="M3714.9668,1139.7373h-1.0947v-8.7319h1.0947V1139.7373z" />
          <path
            d="M3715.5635,1136.563c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3716.7793,1139.8711,3715.5635,1138.521,3715.5635,1136.563z M3716.6699,1135.8945
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3717.5576,1134.1919,3716.8643,1134.812,3716.6699,1135.8945z"
          />
          <path
            d="M3721.7793,1133.377h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1133.377z"
          />
          <path d="M3663.5596,1145.0054h1.1924v1.2651h-1.1924V1145.0054z M3664.7031,1153.7373h-1.0947v-6.3604h1.0947V1153.7373z" />
          <path
            d="M3665.7363,1147.377h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1147.377z"
          />
          <path
            d="M3673.1455,1150.563c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3674.1543,1153.8711,3673.1455,1152.3633,3673.1455,1150.563z
				 M3676.1123,1148.2163c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3677.9121,1148.8608,3677.0127,1148.2163,3676.1123,1148.2163z"
          />
          <path
            d="M3679.5684,1150.563c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3680.7842,1153.8711,3679.5684,1152.521,3679.5684,1150.563z M3680.6748,1149.8945
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3681.5625,1148.1919,3680.8691,1148.812,3680.6748,1149.8945z"
          />
          <path
            d="M3688.6553,1153.7373h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0312-1.0825c1.3135,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1153.7373z"
          />
          <path
            d="M3696.9277,1150.563c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3698.1436,1153.8711,3696.9277,1152.521,3696.9277,1150.563z M3698.0342,1149.8945
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C3698.9219,1148.1919,3698.2285,1148.812,3698.0342,1149.8945z"
          />
          <path
            d="M3702.707,1150.563c0-1.812,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947v-0.9243c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C3703.7158,1153.8711,3702.707,1152.3633,3702.707,1150.563z
				 M3705.6738,1148.2163c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C3707.4736,1148.8608,3706.5742,1148.2163,3705.6738,1148.2163z"
          />
          <path d="M3709.5186,1145.0054h1.1924v1.2651h-1.1924V1145.0054z M3710.6621,1153.7373h-1.0947v-6.3604h1.0947V1153.7373z" />
          <path
            d="M3711.1963,1152.0225c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.958,0.8267
				C3712.1328,1153.8467,3711.1963,1153.1655,3711.1963,1152.0225z M3715.1494,1150.478l-1.2412,0.2676
				c-0.9727,0.1948-1.6289,0.4619-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9365,0,1.7275-0.5718,1.7275-1.3862
				V1150.478z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="14">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2805.6289,1425.699c0,25.266-20.4839,45.75-45.75,45.75c-25.2659,0-45.75-20.484-45.75-45.75
			c0-25.265,20.4841-45.75,45.75-45.75C2785.145,1379.949,2805.6289,1400.434,2805.6289,1425.699"
        />
        <g>
          <path
            d="M2717.6133,1422.7251l-2.6641-7.1875v7.1875h-1.0938v-8.7319h1.5918l2.6523,7.1753l2.6621-7.1753h1.5449v8.7319h-1.082
				v-7.1875l-2.6758,7.1875H2717.6133z"
          />
          <path
            d="M2722.9043,1421.0103c0-1.2646,0.9863-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4004-1.1431-1.252-1.1431
				c-0.8027,0-1.2773,0.377-1.4727,1.082l-1.0332-0.2676c0.3047-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4238,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6816,0.4502v0.8755
				c-0.9727,0.1216-1.5332-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.959,0.8267
				C2723.8418,1422.8345,2722.9043,1422.1533,2722.9043,1421.0103z M2726.8574,1419.4658l-1.2402,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4492,0.9849,1.1426,0.9849c0.9355,0,1.7266-0.5718,1.7266-1.3862
				V1419.4658z"
          />
          <path
            d="M2729.1816,1417.3013h-0.9844v-0.9365h0.9844v-1.8608h1.084v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8867,0.7905c0.2559,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2793,0.061-0.5957,0.0977-0.9609,0.0977
				c-1.082,0-1.7031-0.3652-1.7031-1.5811V1417.3013z"
          />
          <path
            d="M2731.9199,1419.5508c0-1.897,1.2031-3.3076,2.9297-3.3076c1.7754,0,2.7129,1.3501,2.7129,3.1133v0.3647h-4.5977
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C2733.1348,1422.8589,2731.9199,1421.5088,2731.9199,1419.5508z
				 M2733.0254,1418.8823h3.4297c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026
				C2733.9141,1417.1797,2733.2207,1417.7998,2733.0254,1418.8823z"
          />
          <path d="M2741.541,1422.7251l-2.3828-6.3604h1.1543l1.7891,5.0225l1.7617-5.0225h1.1445l-2.3848,6.3604H2741.541z" />
          <path
            d="M2744.7891,1421.0103c0-1.2646,0.9844-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4023-1.1431-1.2539-1.1431
				c-0.8027,0-1.2773,0.377-1.4707,1.082l-1.0332-0.2676c0.3027-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4219,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6797,0.4502v0.8755
				c-0.9727,0.1216-1.5312-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.957,0.8267
				C2745.7246,1422.8345,2744.7891,1422.1533,2744.7891,1421.0103z M2748.7422,1419.4658l-1.2422,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4512,0.9849,1.1426,0.9849c0.9375,0,1.7285-0.5718,1.7285-1.3862
				V1419.4658z"
          />
          <path
            d="M2750.8945,1416.3647h1.0957v0.8633c0.3633-0.5229,0.9961-0.9849,1.8965-0.9849c1.3496,0,2.1152,0.9365,2.1152,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.377-1.5566-1.3262-1.5566c-0.7891,0-1.5918,0.6079-1.5918,1.666v3.7944h-1.0957V1416.3647z"
          />
          <path
            d="M2758.3027,1419.5508c0-1.812,1.0098-3.3076,2.7734-3.3076c0.8867,0,1.5918,0.4258,1.9688,1.0459v-3.2959h1.0957v8.7319
				h-1.0957v-0.9243c-0.377,0.6323-1.082,1.0581-1.9688,1.0581C2759.3125,1422.8589,2758.3027,1421.3511,2758.3027,1419.5508z
				 M2761.2695,1417.2041c-1.1914,0-1.8594,0.9727-1.8594,2.3467c0,1.3623,0.668,2.3472,1.8594,2.3472
				c0.9004,0,1.8008-0.6567,1.8008-2.1157v-0.4502C2763.0703,1417.8486,2762.1699,1417.2041,2761.2695,1417.2041z"
          />
          <path
            d="M2770.2109,1422.7251h-1.0957v-0.8755c-0.3633,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3613,0-2.127-0.9365-2.127-2.2861
				v-4.208h1.0938v3.9766c0,0.8999,0.3887,1.5083,1.3262,1.5083c0.7891,0,1.5918-0.5962,1.5918-1.6538v-3.8311h1.0957V1422.7251z"
          />
          <path
            d="M2776.2793,1422.7251h-1.0938v-0.8755c-0.3652,0.5352-0.9844,1.0093-1.8848,1.0093
				c-1.3633,0-2.1289-0.9365-2.1289-2.2861v-4.208h1.0957v3.9766c0,0.8999,0.3887,1.5083,1.3242,1.5083
				c0.791,0,1.5938-0.5962,1.5938-1.6538v-3.8311h1.0938V1422.7251z"
          />
          <path
            d="M2780.5996,1417.4473c-0.1816-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9238,0-1.666,0.7295-1.666,1.8486v3.478h-1.0938
				v-6.3604h1.0938v1.0825c0.2793-0.6445,0.9004-1.1313,1.7637-1.1313c0.1699,0,0.3281,0.0122,0.4375,0.0244V1417.4473z"
          />
          <path d="M2785.6113,1422.7251h-5.0098v-0.8271l3.6719-4.5967h-3.625v-0.9365h4.9258v0.8394l-3.5996,4.5845h3.6367V1422.7251z" />
          <path
            d="M2785.7461,1421.0103c0-1.2646,0.9844-1.7876,2.4082-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4023-1.1431-1.2539-1.1431
				c-0.8027,0-1.2773,0.377-1.4707,1.082l-1.0332-0.2676c0.3027-1.0337,1.2402-1.7754,2.541-1.7754
				c1.4219,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6797,0.4502v0.8755
				c-0.9727,0.1216-1.5312-0.1094-1.666-0.7173c-0.3887,0.4985-1.0938,0.8267-1.957,0.8267
				C2786.6816,1422.8345,2785.7461,1422.1533,2785.7461,1421.0103z M2789.6992,1419.4658l-1.2422,0.2676
				c-0.9727,0.1948-1.6289,0.4624-1.6289,1.2285c0,0.6201,0.4512,0.9849,1.1426,0.9849c0.9375,0,1.7285-0.5718,1.7285-1.3862
				V1419.4658z"
          />
          <path
            d="M2792.9473,1422.7251h-1.0938v-6.3604h1.0938v0.8633c0.3398-0.5229,0.9492-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3535-0.5229,1.0332-1.0825,2.0312-1.0825c1.3145,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0938v-3.9038c0-0.8877-0.3652-1.5566-1.2656-1.5566c-0.7285,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0938v-3.9038
				c0-0.8877-0.3652-1.5566-1.2656-1.5566c-0.7285,0-1.5078,0.6201-1.5078,1.6538V1422.7251z"
          />
          <path
            d="M2801.2207,1419.5508c0-1.897,1.2031-3.3076,2.9297-3.3076c1.7754,0,2.7129,1.3501,2.7129,3.1133v0.3647h-4.5977
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C2802.4355,1422.8589,2801.2207,1421.5088,2801.2207,1419.5508z
				 M2802.3262,1418.8823h3.4297c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026
				C2803.2148,1417.1797,2802.5215,1417.7998,2802.3262,1418.8823z"
          />
          <path d="M2715.7344,1436.7251l-2.3848-6.3604h1.1562l1.7871,5.0225l1.7637-5.0225h1.1426l-2.3828,6.3604H2715.7344z" />
          <path
            d="M2721.998,1436.8589c-1.7402,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2148-3.3076,2.9551-3.3076
				c1.7383,0,2.9551,1.3379,2.9551,3.3076C2724.9531,1435.521,2723.7363,1436.8589,2721.998,1436.8589z M2721.998,1435.9224
				c1.1172,0,1.8477-0.9243,1.8477-2.3716c0-1.459-0.7305-2.3833-1.8477-2.3833c-1.1191,0-1.8496,0.9243-1.8496,2.3833
				C2720.1484,1434.998,2720.8789,1435.9224,2721.998,1435.9224z"
          />
          <path
            d="M2725.1133,1433.5508c0-1.897,1.2051-3.3076,2.9316-3.3076c1.7754,0,2.7109,1.3501,2.7109,3.1133v0.3647h-4.5957
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8496,0,1.459-0.4619,1.6523-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6133,1.8242C2726.3301,1436.8589,2725.1133,1435.5088,2725.1133,1433.5508z
				 M2726.2207,1432.8823h3.4297c-0.0254-0.8999-0.5117-1.7026-1.6191-1.7026
				C2727.1074,1431.1797,2726.4141,1431.7998,2726.2207,1432.8823z"
          />
          <path
            d="M2730.8926,1433.5508c0-1.812,1.0078-3.3076,2.7715-3.3076c0.8887,0,1.5938,0.4258,1.9707,1.0459v-3.2959h1.0938v8.7319
				h-1.0938v-0.9243c-0.377,0.6323-1.082,1.0581-1.9707,1.0581C2731.9004,1436.8589,2730.8926,1435.3511,2730.8926,1433.5508z
				 M2733.8594,1431.2041c-1.1914,0-1.8613,0.9727-1.8613,2.3467c0,1.3623,0.6699,2.3472,1.8613,2.3472
				c0.9004,0,1.7988-0.6567,1.7988-2.1157v-0.4502C2735.6582,1431.8486,2734.7598,1431.2041,2733.8594,1431.2041z"
          />
          <path
            d="M2737.8984,1434.7549c0.3398,0.7417,1.1191,1.1919,1.9453,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6074-0.7905-1.6543-1.0093c-1.0449-0.2314-2.1387-0.4624-2.1387-1.7637c0-1.1309,0.9844-1.9214,2.334-1.9214
				c1.1426,0,2.0801,0.5596,2.457,1.3135l-0.7793,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7285,0-1.2148,0.3647-1.2148,0.9365c0,0.5957,0.5586,0.7051,1.4453,0.8999c1.0957,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1055,2.0186-2.4688,2.0186c-1.1543,0-2.3105-0.4985-2.7969-1.4839L2737.8984,1434.7549z"
          />
          <path
            d="M2742.4375,1433.5508c0-1.897,1.2031-3.3076,2.9297-3.3076c1.7754,0,2.7129,1.3501,2.7129,3.1133v0.3647h-4.5977
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C2743.6523,1436.8589,2742.4375,1435.5088,2742.4375,1433.5508z M2743.543,1432.8823
				h3.4297c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026C2744.4316,1431.1797,2743.7383,1431.7998,2743.543,1432.8823z"
          />
          <path d="M2749.748,1436.7251h-1.0957v-8.7319h1.0957V1436.7251z" />
          <path
            d="M2753.3477,1435.8979c0.875,0,1.3984-0.5591,1.5801-1.3496l0.9727,0.4497c-0.3047,1.0581-1.2285,1.8608-2.5527,1.8608
				c-1.7773,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2266-3.3076,3.0039-3.3076c1.3242,0,2.2246,0.7661,2.5293,1.8242
				l-0.9492,0.4741c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1328,0-1.9102,0.8999-1.9102,2.3589
				C2751.4375,1434.998,2752.2148,1435.8979,2753.3477,1435.8979z"
          />
          <path
            d="M2758.834,1436.8589c-1.7402,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2148-3.3076,2.9551-3.3076
				c1.7383,0,2.9551,1.3379,2.9551,3.3076C2761.7891,1435.521,2760.5723,1436.8589,2758.834,1436.8589z M2758.834,1435.9224
				c1.1172,0,1.8477-0.9243,1.8477-2.3716c0-1.459-0.7305-2.3833-1.8477-2.3833c-1.1191,0-1.8496,0.9243-1.8496,2.3833
				C2756.9844,1434.998,2757.7148,1435.9224,2758.834,1435.9224z"
          />
          <path
            d="M2762.3848,1430.3647h1.0957v0.8633c0.3633-0.5229,0.9961-0.9849,1.8965-0.9849c1.3496,0,2.1152,0.9365,2.1152,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.377-1.5566-1.3262-1.5566c-0.7891,0-1.5918,0.6079-1.5918,1.666v3.7944h-1.0957V1430.3647z"
          />
          <path
            d="M2768.6016,1434.7549c0.3398,0.7417,1.1191,1.1919,1.9453,1.1919c0.7422,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6074-0.7905-1.6543-1.0093c-1.0449-0.2314-2.1387-0.4624-2.1387-1.7637c0-1.1309,0.9844-1.9214,2.334-1.9214
				c1.1426,0,2.0801,0.5596,2.457,1.3135l-0.7793,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.7285,0-1.2148,0.3647-1.2148,0.9365c0,0.5957,0.5586,0.7051,1.4453,0.8999c1.0957,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1055,2.0186-2.4688,2.0186c-1.1543,0-2.3105-0.4985-2.7969-1.4839L2768.6016,1434.7549z"
          />
          <path
            d="M2778.623,1436.7251h-1.0938v-0.8755c-0.3652,0.5352-0.9844,1.0093-1.8848,1.0093c-1.3633,0-2.1289-0.9365-2.1289-2.2861
				v-4.208h1.0957v3.9766c0,0.8999,0.3887,1.5083,1.3242,1.5083c0.791,0,1.5938-0.5962,1.5938-1.6538v-3.8311h1.0938V1436.7251z"
          />
          <path
            d="M2780.7422,1436.7251h-1.0938v-6.3604h1.0938v0.8633c0.3398-0.5229,0.9492-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3535-0.5229,1.0332-1.0825,2.0312-1.0825c1.3145,0,2.1035,0.9365,2.1035,2.2861v4.1958
				h-1.0938v-3.9038c0-0.8877-0.3652-1.5566-1.2656-1.5566c-0.7285,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0938v-3.9038
				c0-0.8877-0.3652-1.5566-1.2656-1.5566c-0.7285,0-1.5078,0.6201-1.5078,1.6538V1436.7251z"
          />
          <path
            d="M2790.5469,1435.8008v3.1133h-1.0938v-8.5493h1.0938v0.9243c0.377-0.6201,1.082-1.0459,1.9707-1.0459
				c1.7637,0,2.7715,1.4956,2.7715,3.3076c0,1.8003-1.0078,3.3081-2.7715,3.3081
				C2791.6289,1436.8589,2790.9238,1436.4331,2790.5469,1435.8008z M2790.5234,1433.7822c0,1.459,0.8984,2.1157,1.7988,2.1157
				c1.1914,0,1.8613-0.9849,1.8613-2.3472c0-1.374-0.6699-2.3467-1.8613-2.3467c-0.9004,0-1.7988,0.6445-1.7988,2.1279V1433.7822z"
          />
          <path
            d="M2796.0566,1431.3013h-0.9844v-0.9365h0.9844v-1.8608h1.084v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8867,0.7905c0.2559,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2793,0.061-0.5957,0.0977-0.9609,0.0977
				c-1.082,0-1.7031-0.3652-1.7031-1.5811V1431.3013z"
          />
          <path d="M2799.1836,1427.9932h1.1934v1.2651h-1.1934V1427.9932z M2800.3281,1436.7251h-1.0957v-6.3604h1.0957V1436.7251z" />
          <path
            d="M2800.9238,1433.5508c0-1.897,1.2031-3.3076,2.9297-3.3076c1.7754,0,2.7129,1.3501,2.7129,3.1133v0.3647h-4.5977
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6152,1.8242C2802.1387,1436.8589,2800.9238,1435.5088,2800.9238,1433.5508z
				 M2802.0293,1432.8823h3.4297c-0.0234-0.8999-0.5098-1.7026-1.6172-1.7026
				C2802.918,1431.1797,2802.2246,1431.7998,2802.0293,1432.8823z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="13">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M3847.5122,1564.1887c0,25.27-20.4841,45.75-45.75,45.75c-25.269,0-45.75-20.48-45.75-45.75
			c0-25.265,20.481-45.75,45.75-45.75C3827.0281,1518.4387,3847.5122,1538.9237,3847.5122,1564.1887"
        />
        <g>
          <path
            d="M3782.4688,1554.2148l-2.6631-7.1875v7.1875h-1.0947v-8.7314h1.5928l2.6514,7.1748l2.6631-7.1748h1.5449v8.7314h-1.083
				v-7.1875l-2.6748,7.1875H3782.4688z"
          />
          <path
            d="M3788.3691,1552.5c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3789.3057,1554.3242,3788.3691,1553.6436,3788.3691,1552.5z M3792.3223,1550.9561l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V1550.9561z"
          />
          <path
            d="M3795.2539,1548.791h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V1548.791z"
          />
          <path
            d="M3798.5996,1551.041c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3799.8154,1554.3486,3798.5996,1552.999,3798.5996,1551.041z M3799.7061,1550.3721
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3800.5938,1548.6689,3799.9004,1549.2891,3799.7061,1550.3721z"
          />
          <path d="M3809.4385,1554.2148l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3809.4385z" />
          <path
            d="M3813.2939,1552.5c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3814.2305,1554.3242,3813.2939,1553.6436,3813.2939,1552.5z M3817.2471,1550.9561l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V1550.9561z"
          />
          <path
            d="M3820.0088,1547.8545h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1547.8545z"
          />
          <path
            d="M3760.4805,1561.8545l1.3135,4.7432l1.3262-4.7432h1.0938l-1.9336,6.3604h-0.96l-1.3262-4.7188l-1.3252,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H3760.4805z"
          />
          <path
            d="M3764.3018,1565.041c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3765.5176,1568.3486,3764.3018,1566.999,3764.3018,1565.041z M3765.4082,1564.3721
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3766.2959,1562.6689,3765.6025,1563.2891,3765.4082,1564.3721z"
          />
          <path
            d="M3770.9316,1562.791h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V1562.791z"
          />
          <path
            d="M3773.9121,1565.041c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3775.1279,1568.3486,3773.9121,1566.999,3773.9121,1565.041z M3775.0186,1564.3721
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3775.9062,1562.6689,3775.2129,1563.2891,3775.0186,1564.3721z"
          />
          <path
            d="M3780.3721,1561.8545h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1561.8545z"
          />
          <path
            d="M3786.8301,1566.2451c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L3786.8301,1566.2451z"
          />
          <path
            d="M3794.6162,1567.3877c0.875,0,1.3984-0.5596,1.5811-1.3496l0.9727,0.4502c-0.3047,1.0576-1.2285,1.8604-2.5537,1.8604
				c-1.7764,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2275-3.3086,3.0039-3.3086c1.3252,0,2.2256,0.7666,2.5293,1.8242
				l-0.9482,0.4746c-0.1826-0.7783-0.7061-1.3496-1.5811-1.3496c-1.1318,0-1.9102,0.8994-1.9102,2.3594
				C3792.7061,1566.4883,3793.4844,1567.3877,3794.6162,1567.3877z"
          />
          <path
            d="M3797.8271,1559.4834h1.0947v3.2344c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1559.4834z"
          />
          <path
            d="M3803.6416,1566.5c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3804.5781,1568.3242,3803.6416,1567.6436,3803.6416,1566.5z M3807.5947,1564.9561l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V1564.9561z"
          />
          <path
            d="M3811.0859,1567.291v3.1133h-1.0947v-8.5498h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4961,2.7725,3.3086c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C3812.168,1568.3486,3811.4629,1567.9229,3811.0859,1567.291z M3811.0615,1565.2715c0,1.46,0.8994,2.1162,1.7998,2.1162
				c1.1914,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6689-2.3477-1.8604-2.3477c-0.9004,0-1.7998,0.6445-1.7998,2.1289V1565.2715z"
          />
          <path
            d="M3817.7646,1567.291v3.1133h-1.0947v-8.5498h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4961,2.7725,3.3086c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C3818.8467,1568.3486,3818.1416,1567.9229,3817.7646,1567.291z M3817.7402,1565.2715c0,1.46,0.8994,2.1162,1.7998,2.1162
				c1.1914,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6689-2.3477-1.8604-2.3477c-0.9004,0-1.7998,0.6445-1.7998,2.1289V1565.2715z"
          />
          <path
            d="M3822.9102,1565.041c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3824.126,1568.3486,3822.9102,1566.999,3822.9102,1565.041z M3824.0166,1564.3721
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3824.9043,1562.6689,3824.2109,1563.2891,3824.0166,1564.3721z"
          />
          <path d="M3830.4639,1568.2148h-1.0947v-8.7314h1.0947V1568.2148z" />
          <path d="M3831.6924,1559.4834h1.1924v1.2646h-1.1924V1559.4834z M3832.8359,1568.2148h-1.0947v-6.3604h1.0947V1568.2148z" />
          <path
            d="M3834.1123,1561.8545h1.0947v6.8828c0,1.1318-0.7061,1.7031-1.7881,1.7031c-0.2549,0-0.4131-0.0127-0.6084-0.0361
				v-0.9492c0.1338,0.0244,0.2441,0.0371,0.4258,0.0371c0.6328,0,0.876-0.3047,0.876-0.9492V1561.8545z M3834.0635,1559.4834h1.1924
				v1.2646h-1.1924V1559.4834z"
          />
          <path
            d="M3837.5791,1564.8223l2.8213-2.9678h1.3623l-2.4814,2.5049l2.6875,3.8555h-1.2764l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V1564.8223z"
          />
          <path
            d="M3841.9951,1565.041c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3843.2109,1568.3486,3841.9951,1566.999,3841.9951,1565.041z M3843.1016,1564.3721
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3843.9893,1562.6689,3843.2959,1563.2891,3843.1016,1564.3721z"
          />
          <path
            d="M3766.9346,1582.3486c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C3769.8896,1581.0107,3768.6738,1582.3486,3766.9346,1582.3486z M3766.9346,1581.4121
				c1.1182,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C3765.0859,1580.4883,3765.8154,1581.4121,3766.9346,1581.4121z"
          />
          <path
            d="M3771.0947,1575.8545h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1575.8545z"
          />
          <path
            d="M3777.3359,1579.041c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C3778.3447,1582.3486,3777.3359,1580.8408,3777.3359,1579.041z
				 M3780.3027,1576.6934c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C3782.1025,1577.3379,3781.2031,1576.6934,3780.3027,1576.6934z"
          />
          <path
            d="M3784.3672,1579.041c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3785.583,1582.3486,3784.3672,1580.999,3784.3672,1579.041z M3785.4736,1578.3721
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3786.3613,1576.6689,3785.668,1577.2891,3785.4736,1578.3721z"
          />
          <path
            d="M3794.4883,1576.9365c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V1576.9365z"
          />
          <path
            d="M3796.6055,1581.291v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4961,2.7725,3.3086c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C3797.6875,1582.3486,3796.9824,1581.9229,3796.6055,1581.291z M3796.5811,1579.2715c0,1.46,0.8994,2.1162,1.7998,2.1162
				c1.1914,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6689-2.3477-1.8604-2.3477c-0.9004,0-1.7998,0.6445-1.7998,2.1289V1579.2715z"
          />
          <path
            d="M3805.0713,1582.3486c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C3808.0264,1581.0107,3806.8105,1582.3486,3805.0713,1582.3486z M3805.0713,1581.4121
				c1.1182,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C3803.2227,1580.4883,3803.9521,1581.4121,3805.0713,1581.4121z"
          />
          <path
            d="M3814.2793,1582.2148h-1.0947v-0.876c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0938v3.9766c0,0.9004,0.3896,1.5078,1.3262,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V1582.2148z"
          />
          <path
            d="M3819.8633,1575.8545l1.3135,4.7432l1.3262-4.7432h1.0938l-1.9336,6.3604h-0.96l-1.3262-4.7188l-1.3252,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H3819.8633z"
          />
          <path d="M3824.4385,1573.4834h1.1924v1.2646h-1.1924V1573.4834z M3825.582,1582.2148h-1.0947v-6.3604h1.0947V1582.2148z" />
          <path
            d="M3827.2246,1575.8545h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1575.8545z"
          />
          <path
            d="M3836.3359,1584.5381c-1.3984,0-2.4209-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5723,1.8604-1.8975v-0.7178c-0.3408,0.6201-1.0576,1.0586-1.9463,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1748c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.4385,1.9463,1.0586v-0.9365
				h1.0947v5.7891C3839.291,1583.5039,3838.0625,1584.5381,3836.3359,1584.5381z M3836.4453,1576.6934
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012s0.7061,2.2139,1.8613,2.2139c0.9121,0,1.7627-0.6816,1.7627-2.0801v-0.2305
				C3838.208,1577.375,3837.3574,1576.6934,3836.4453,1576.6934z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="12">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1437.625,815.645c0,25.2651-20.48,45.75-45.75,45.75c-25.266,0-45.75-20.4849-45.75-45.75
			c0-25.27,20.484-45.75,45.75-45.75C1417.145,769.895,1437.625,790.375,1437.625,815.645"
        />
        <g>
          <path
            d="M1371.3721,801.354l4.0615-4.4146h1.3984l-3.5386,3.8066l3.709,4.9253h-1.374l-3.1133-4.0859l-1.1431,1.228v2.8579
				h-1.1675v-8.7319h1.1675V801.354z"
          />
          <path
            d="M1380.3125,805.8052c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1383.2676,804.4673,1382.0513,805.8052,1380.3125,805.8052z M1380.3125,804.8687
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1378.4639,803.9443,1379.1934,804.8687,1380.3125,804.8687z"
          />
          <path
            d="M1384.6196,803.7012c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1384.6196,803.7012z"
          />
          <path
            d="M1390.3735,800.2476h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V800.2476z"
          />
          <path d="M1398.1704,805.6714l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1398.1704z" />
          <path
            d="M1402.0273,803.9565c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1402.9639,805.7808,1402.0273,805.0996,1402.0273,803.9565z M1405.9795,802.4121l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V802.4121z"
          />
          <path
            d="M1408.7417,799.311h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V799.311z"
          />
          <path
            d="M1356.1958,821.9941c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3408,0.6201-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C1359.1509,820.9604,1357.9229,821.9941,1356.1958,821.9941z M1356.3052,814.1504
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C1358.0688,814.8311,1357.2173,814.1504,1356.3052,814.1504z"
          />
          <path
            d="M1360.3564,816.4971c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1361.5728,819.8052,1360.3564,818.4551,1360.3564,816.4971z M1361.4634,815.8286
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1362.3511,814.126,1361.6577,814.7461,1361.4634,815.8286z"
          />
          <path
            d="M1368.2759,819.6714h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V819.6714z"
          />
          <path
            d="M1380.1113,819.8052c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1383.0664,818.4673,1381.8501,819.8052,1380.1113,819.8052z M1380.1113,818.8687
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1378.2627,817.9443,1378.9922,818.8687,1380.1113,818.8687z"
          />
          <path
            d="M1384.4424,814.2476h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V814.2476z"
          />
          <path
            d="M1390.7432,819.8052c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1393.6982,818.4673,1392.4819,819.8052,1390.7432,819.8052z M1390.7432,818.8687
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1388.8945,817.9443,1389.624,818.8687,1390.7432,818.8687z"
          />
          <path
            d="M1398.1997,814.3936c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V814.3936z"
          />
          <path d="M1399.1733,810.9395h1.1919v1.2651h-1.1919V810.9395z M1400.3169,819.6714h-1.0947v-6.3604h1.0947V819.6714z" />
          <path
            d="M1402.105,817.7012c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1402.105,817.7012z"
          />
          <path
            d="M1407.251,816.4971c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1408.4673,819.8052,1407.251,818.4551,1407.251,816.4971z M1408.3579,815.8286
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1409.2456,814.126,1408.5522,814.7461,1408.3579,815.8286z"
          />
          <path
            d="M1413.6372,816.4971c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1414.8535,819.8052,1413.6372,818.4551,1413.6372,816.4971z M1414.7441,815.8286
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1415.6318,814.126,1414.9385,814.7461,1414.7441,815.8286z"
          />
          <path
            d="M1423.7573,814.3936c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V814.3936z"
          />
          <path
            d="M1424.3423,816.4971c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1425.3521,819.8052,1424.3423,818.2974,1424.3423,816.4971z
				 M1427.3096,814.1504c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1429.1099,814.7949,1428.21,814.1504,1427.3096,814.1504z"
          />
          <path
            d="M1368.5132,828.2476h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V828.2476z"
          />
          <path
            d="M1375.5918,828.3936c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V828.3936z"
          />
          <path
            d="M1376.1162,831.9565c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1377.0527,833.7808,1376.1162,833.0996,1376.1162,831.9565z M1380.0684,830.4121l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V830.4121z"
          />
          <path
            d="M1382.8306,827.311h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V827.311z"
          />
          <path
            d="M1389.6548,831.7012c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1389.6548,831.7012z"
          />
          <path
            d="M1396.3335,832.7471v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C1397.4155,833.8052,1396.7104,833.3794,1396.3335,832.7471z M1396.3091,830.7285c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8608-0.9849,1.8608-2.3472c0-1.374-0.6689-2.3467-1.8608-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V830.7285z"
          />
          <path
            d="M1404.7993,833.8052c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1407.7544,832.4673,1406.5381,833.8052,1404.7993,833.8052z M1404.7993,832.8687
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1402.9507,831.9443,1403.6802,832.8687,1404.7993,832.8687z"
          />
          <path
            d="M1412.2559,828.3936c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V828.3936z"
          />
          <path
            d="M1413.4487,828.2476h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V828.2476z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="11">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1517.426,675.6021c0,25.2651-20.4811,45.75-45.75,45.75c-25.266,0-45.75-20.4849-45.75-45.75
			c0-25.27,20.484-45.75,45.75-45.75C1496.9449,629.8521,1517.426,650.332,1517.426,675.6021"
        />
        <g>
          <path
            d="M1459.4805,656.8965l3.3687,8.7319h-1.1797l-0.8877-2.2983h-3.916l-0.876,2.2983h-1.167l3.3687-8.7319H1459.4805z
				 M1457.2549,662.3086h3.1377l-1.5811-4.0986L1457.2549,662.3086z"
          />
          <path
            d="M1463.2769,663.9136c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1464.2134,665.7378,1463.2769,665.0566,1463.2769,663.9136z M1467.229,662.3691l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V662.3691z"
          />
          <path
            d="M1469.9917,659.2681h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V659.2681z"
          />
          <path
            d="M1476.8403,660.2046h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V660.2046z"
          />
          <path
            d="M1480.1245,663.9136c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1481.061,665.7378,1480.1245,665.0566,1480.1245,663.9136z M1484.0767,662.3691l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V662.3691z"
          />
          <path d="M1487.9341,665.6284h-1.0947v-8.7319h1.0947V665.6284z" />
          <path
            d="M1435.937,677.6582c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9243-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1435.937,677.6582z"
          />
          <path
            d="M1444.0864,678.8013c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3037,1.0581-1.228,1.8608-2.5537,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1442.1772,677.9014,1442.9556,678.8013,1444.0864,678.8013z"
          />
          <path
            d="M1447.6641,670.8965h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V670.8965z"
          />
          <path
            d="M1453.8438,677.9136c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1454.7803,679.7378,1453.8438,679.0566,1453.8438,677.9136z M1457.7959,676.3691l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V676.3691z"
          />
          <path
            d="M1461.6528,676.2354l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V676.2354z"
          />
          <path
            d="M1466.4341,676.4541c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1467.6504,679.7622,1466.4341,678.4121,1466.4341,676.4541z M1467.541,675.7856
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1468.4287,674.083,1467.7354,674.7031,1467.541,675.7856z"
          />
          <path d="M1474.353,679.6284h-1.0947v-8.7319h1.0947V679.6284z" />
          <path
            d="M1476.1411,677.6582c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9243-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1476.1411,677.6582z"
          />
          <path d="M1484.0605,670.8965h1.1919v1.2651h-1.1919V670.8965z M1485.2041,679.6284h-1.0947v-6.3604h1.0947V679.6284z" />
          <path
            d="M1486.8462,673.2681h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V673.2681z"
          />
          <path
            d="M1495.4712,676.4541c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1496.481,679.7622,1495.4712,678.2544,1495.4712,676.4541z
				 M1498.4385,674.1074c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1500.2388,674.752,1499.3389,674.1074,1498.4385,674.1074z"
          />
          <path
            d="M1502.502,676.4541c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1503.7183,679.7622,1502.502,678.4121,1502.502,676.4541z M1503.6089,675.7856
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1504.4966,674.083,1503.8032,674.7031,1503.6089,675.7856z"
          />
          <path
            d="M1430.6089,690.4541c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1431.6187,693.7622,1430.6089,692.2544,1430.6089,690.4541z
				 M1433.5762,688.1074c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1435.3765,688.752,1434.4766,688.1074,1433.5762,688.1074z"
          />
          <path d="M1438.0288,684.8965h1.1919v1.2651h-1.1919V684.8965z M1439.1724,693.6284h-1.0947v-6.3604h1.0947V693.6284z" />
          <path
            d="M1440.9609,691.6582c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1440.9609,691.6582z"
          />
          <path
            d="M1446.7148,688.2046h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V688.2046z"
          />
          <path
            d="M1453.7935,688.3506c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V688.3506z"
          />
          <path d="M1454.7676,684.8965h1.1919v1.2651h-1.1919V684.8965z M1455.9111,693.6284h-1.0947v-6.3604h1.0947V693.6284z" />
          <path
            d="M1458.6479,692.7041v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C1459.73,693.7622,1459.0249,693.3364,1458.6479,692.7041z M1458.6235,690.6855c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V690.6855z"
          />
          <path
            d="M1469.6431,693.6284h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V693.6284z"
          />
          <path
            d="M1471.4448,688.2046h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V688.2046z"
          />
          <path d="M1475.1792,684.8965h1.1919v1.2651h-1.1919V684.8965z M1476.3228,693.6284h-1.0947v-6.3604h1.0947V693.6284z" />
          <path
            d="M1477.5269,690.4541c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1478.7432,693.7622,1477.5269,692.4121,1477.5269,690.4541z M1478.6338,689.7856
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1479.5215,688.083,1478.8281,688.7031,1478.6338,689.7856z"
          />
          <path
            d="M1485.4458,690.2354l2.8213-2.9673h1.3618l-2.481,2.5054l2.688,3.855h-1.2769l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V690.2354z"
          />
          <path
            d="M1490.2271,690.4541c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1491.4434,693.7622,1490.2271,692.4121,1490.2271,690.4541z M1491.334,689.7856
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1492.2217,688.083,1491.5283,688.7031,1491.334,689.7856z"
          />
          <path
            d="M1497.2217,688.2046h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V688.2046z"
          />
          <path
            d="M1500.5664,690.4541c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1501.7827,693.7622,1500.5664,692.4121,1500.5664,690.4541z M1501.6733,689.7856
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1502.561,688.083,1501.8677,688.7031,1501.6733,689.7856z"
          />
          <path
            d="M1507.3911,687.2681h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V687.2681z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="10">
        <path
          style={{ fill: '#FF6E69' }}
          className="node"
          d="M634.859,1245.7581c0,25.2649-20.484,45.75-45.75,45.75c-25.265,0-45.75-20.4851-45.75-45.75
			c0-25.2661,20.485-45.75,45.75-45.75C614.375,1200.0081,634.859,1220.4919,634.859,1245.7581"
        />
        <g>
          <path d="M567.7095,1228.7842h-1.0093l-4.7671-7.0293v7.0293h-1.1187v-8.7319h1.228l4.5361,6.7495v-6.7495h1.1309V1228.7842z" />
          <path d="M569.4146,1220.0522h1.1919v1.2651h-1.1919V1220.0522z M570.5581,1228.7842h-1.0947v-6.3604h1.0947V1228.7842z" />
          <path d="M573.8906,1228.7842l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H573.8906z" />
          <path
            d="M577.8691,1225.6099c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C579.0854,1228.918,577.8691,1227.5679,577.8691,1225.6099z M578.9761,1224.9414
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C579.8638,1223.2388,579.1704,1223.8589,578.9761,1224.9414z"
          />
          <path
            d="M584.2554,1227.0693c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C585.1919,1228.8936,584.2554,1228.2124,584.2554,1227.0693z M588.2075,1225.5249l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1225.5249z"
          />
          <path
            d="M596.0776,1228.7842h-1.0942v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8853,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5933-0.5962,1.5933-1.6543v-3.8306h1.0942V1228.7842z"
          />
          <path d="M601.8447,1228.7842l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H601.8447z" />
          <path
            d="M605.7627,1227.0693c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C606.6992,1228.8936,605.7627,1228.2124,605.7627,1227.0693z M609.7148,1225.5249l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1225.5249z"
          />
          <path
            d="M612.5381,1222.4238h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1222.4238z"
          />
          <path
            d="M551.1069,1239.6099c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C552.1167,1242.918,551.1069,1241.4102,551.1069,1239.6099z
				 M554.0742,1237.2632c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C555.8745,1237.9077,554.9746,1237.2632,554.0742,1237.2632z"
          />
          <path
            d="M563.6836,1242.7842h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1242.7842z"
          />
          <path
            d="M570.4229,1242.7842h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5928-0.5962,1.5928-1.6543v-3.8306h1.0947V1242.7842z"
          />
          <path
            d="M575.4111,1237.5063c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1237.5063z"
          />
          <path d="M581.0913,1242.7842h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8394l-3.5996,4.5845h3.6362V1242.7842z" />
          <path
            d="M581.8955,1241.0693c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C582.832,1242.8936,581.8955,1242.2124,581.8955,1241.0693z M585.8477,1239.5249l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1239.5249z"
          />
          <path
            d="M588.1724,1241.0693c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C589.1089,1242.8936,588.1724,1242.2124,588.1724,1241.0693z M592.1245,1239.5249l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1239.5249z"
          />
          <path
            d="M596.0425,1242.7842h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1242.7842z"
          />
          <path
            d="M605.4214,1234.0522h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1234.0522z"
          />
          <path
            d="M611.7227,1239.6099c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C612.939,1242.918,611.7227,1241.5679,611.7227,1239.6099z M612.8296,1238.9414
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C613.7173,1237.2388,613.0239,1237.8589,612.8296,1238.9414z"
          />
          <path d="M618.5591,1234.0522h1.1919v1.2651h-1.1919V1234.0522z M619.7026,1242.7842h-1.0947v-6.3604h1.0947V1242.7842z" />
          <path
            d="M620.9673,1239.6099c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C621.9771,1242.918,620.9673,1241.4102,620.9673,1239.6099z
				 M623.9346,1237.2632c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C625.7349,1237.9077,624.835,1237.2632,623.9346,1237.2632z"
          />
          <path d="M551.3076,1248.0522h1.1919v1.2651h-1.1919V1248.0522z M552.4512,1256.7842h-1.0947v-6.3604h1.0947V1256.7842z" />
          <path
            d="M554.1543,1250.4238h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1250.4238z"
          />
          <path
            d="M562.9009,1253.6099c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C563.9106,1256.918,562.9009,1255.4102,562.9009,1253.6099z
				 M565.8682,1251.2632c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C567.6685,1251.9077,566.7686,1251.2632,565.8682,1251.2632z"
          />
          <path
            d="M569.9922,1253.6099c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C571.2085,1256.918,569.9922,1255.5679,569.9922,1253.6099z M571.0991,1252.9414
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C571.9868,1251.2388,571.2935,1251.8589,571.0991,1252.9414z"
          />
          <path
            d="M580.4175,1256.7842h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1256.7842z"
          />
          <path
            d="M592.314,1256.918c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C595.269,1255.5801,594.0527,1256.918,592.314,1256.918z M592.314,1255.9814
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C590.4653,1255.0571,591.1948,1255.9814,592.314,1255.9814z"
          />
          <path
            d="M596.5356,1250.4238h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1250.4238z"
          />
          <path
            d="M602.8369,1253.6099c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C603.8467,1256.918,602.8369,1255.4102,602.8369,1253.6099z
				 M605.8042,1251.2632c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C607.6045,1251.9077,606.7046,1251.2632,605.8042,1251.2632z"
          />
          <path d="M610.3179,1248.0522h1.1919v1.2651h-1.1919V1248.0522z M611.4614,1256.7842h-1.0947v-6.3604h1.0947V1256.7842z" />
          <path
            d="M612.6655,1255.0693c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C613.6021,1256.8936,612.6655,1256.2124,612.6655,1255.0693z M616.6177,1253.5249l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1253.5249z"
          />
          <path d="M620.5356,1256.7842h-1.0947v-8.7319h1.0947V1256.7842z" />
          <path
            d="M621.8008,1253.6099c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C623.0171,1256.918,621.8008,1255.5679,621.8008,1253.6099z M622.9077,1252.9414
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C623.7954,1251.2388,623.1021,1251.8589,622.9077,1252.9414z"
          />
          <path d="M565.0537,1270.7842h-1.0947v-8.7319h1.0947V1270.7842z" />
          <path
            d="M566.2583,1269.0693c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C567.1948,1270.8936,566.2583,1270.2124,566.2583,1269.0693z M570.2104,1267.5249l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1267.5249z"
          />
          <path
            d="M573.0337,1264.4238h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1264.4238z"
          />
          <path
            d="M579.335,1267.6099c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C580.3447,1270.918,579.335,1269.4102,579.335,1267.6099z
				 M582.3022,1265.2632c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C584.1025,1265.9077,583.2026,1265.2632,582.3022,1265.2632z"
          />
          <path
            d="M587.959,1269.8599v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C589.041,1270.918,588.3359,1270.4922,587.959,1269.8599z M587.9346,1267.8413c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1267.8413z"
          />
          <path
            d="M596.4858,1270.918c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C599.4409,1269.5801,598.2246,1270.918,596.4858,1270.918z M596.4858,1269.9814
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C594.6372,1269.0571,595.3667,1269.9814,596.4858,1269.9814z"
          />
          <path
            d="M605.7544,1270.7842h-1.0942v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8853,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5933-0.5962,1.5933-1.6543v-3.8306h1.0942V1270.7842z"
          />
          <path
            d="M611.3994,1264.4238l1.313,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H611.3994z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="9">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1682.7889,672.688c0,25.2651-20.48,45.75-45.75,45.75c-25.266,0-45.75-20.4849-45.75-45.75
			c0-25.2659,20.484-45.75,45.75-45.75C1662.309,626.938,1682.7889,647.4221,1682.7889,672.688"
        />
        <g>
          <path d="M1619.5195,648.7144h-1.0088l-4.7676-7.0293v7.0293h-1.1182v-8.7319h1.2275l4.5361,6.7495v-6.7495h1.1309V648.7144z" />
          <path
            d="M1620.7754,646.9995c0-1.2646,0.9844-1.7876,2.4072-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2764,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.542-1.7754
				c1.4229,0,2.2979,0.6934,2.2979,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6816,0.4502v0.8755
				c-0.9736,0.1216-1.5322-0.1094-1.666-0.7173c-0.3896,0.4985-1.0947,0.8267-1.958,0.8267
				C1621.7119,648.8237,1620.7754,648.1426,1620.7754,646.9995z M1624.7275,645.4551l-1.2402,0.2676
				c-0.9736,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9355,0,1.7266-0.5718,1.7266-1.3862
				V645.4551z"
          />
          <path
            d="M1628.6455,647.79v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C1629.7275,648.8481,1629.0225,648.4224,1628.6455,647.79z M1628.6211,645.7715c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V645.7715z"
          />
          <path d="M1634.6055,639.9824h1.1924v1.2651h-1.1924V639.9824z M1635.749,648.7144h-1.0947v-6.3604h1.0947V648.7144z" />
          <path
            d="M1637.4521,642.354h1.0947v6.8833c0,1.1309-0.7061,1.7026-1.7881,1.7026c-0.2559,0-0.4141-0.0122-0.6084-0.0366v-0.9487
				c0.1338,0.0244,0.2432,0.0366,0.4258,0.0366c0.6328,0,0.876-0.3042,0.876-0.9487V642.354z M1637.4033,639.9824h1.1914v1.2651
				h-1.1914V639.9824z"
          />
          <path
            d="M1640.25,639.9824h1.0947v3.2349c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V639.9824z"
          />
          <path
            d="M1646.5508,645.54c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C1647.7676,648.8481,1646.5508,647.498,1646.5508,645.54z M1647.6582,644.8716
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C1648.5459,643.1689,1647.8525,643.7891,1647.6582,644.8716z"
          />
          <path d="M1653.3877,639.9824h1.1914v1.2651h-1.1914V639.9824z M1654.5312,648.7144h-1.0947v-6.3604h1.0947V648.7144z" />
          <path
            d="M1655.7959,645.54c0-1.812,1.0098-3.3076,2.7734-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947V647.79c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C1656.8057,648.8481,1655.7959,647.3403,1655.7959,645.54z
				 M1658.7637,643.1934c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C1660.5635,643.8379,1659.6641,643.1934,1658.7637,643.1934z"
          />
          <path d="M1598.5068,662.7144l-2.3828-6.3604h1.1553l1.7871,5.0225l1.7637-5.0225h1.1436l-2.3838,6.3604H1598.5068z" />
          <path
            d="M1602.4248,660.9995c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5439-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.252-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0342-0.2676c0.3047-1.0337,1.2412-1.7754,2.542-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2549,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0947,0.8267-1.958,0.8267
				C1603.3613,662.8237,1602.4248,662.1426,1602.4248,660.9995z M1606.377,659.4551l-1.2402,0.2676
				c-0.9727,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9365,0,1.7266-0.5718,1.7266-1.3862
				V659.4551z"
          />
          <path
            d="M1609.2002,656.354h1.0947v0.8633c0.3652-0.5229,0.9971-0.9849,1.8975-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V656.354z"
          />
          <path
            d="M1617.9463,659.54c0-1.812,1.0098-3.3076,2.7734-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7319
				h-1.0947V661.79c-0.377,0.6323-1.082,1.0581-1.9697,1.0581C1618.9561,662.8481,1617.9463,661.3403,1617.9463,659.54z
				 M1620.9141,657.1934c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.7998-0.6567,1.7998-2.1157v-0.4502C1622.7139,657.8379,1621.8145,657.1934,1620.9141,657.1934z"
          />
          <path
            d="M1625.0381,659.54c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C1626.2549,662.8481,1625.0381,661.498,1625.0381,659.54z M1626.1455,658.8716
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C1627.0332,657.1689,1626.3398,657.7891,1626.1455,658.8716z"
          />
          <path
            d="M1635.4639,661.79v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C1636.5459,662.8481,1635.8408,662.4224,1635.4639,661.79z M1635.4395,659.7715c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V659.7715z"
          />
          <path
            d="M1644.7686,657.4365c-0.1826-0.0366-0.3281-0.0488-0.5352-0.0488c-0.9238,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.9004-1.1313,1.7637-1.1313c0.1699,0,0.3281,0.0122,0.4375,0.0244V657.4365z"
          />
          <path d="M1645.8037,653.9824h1.1914v1.2651h-1.1914V653.9824z M1646.9473,662.7144h-1.0947v-6.3604h1.0947V662.7144z" />
          <path
            d="M1649.7451,662.7144h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0303-1.0825c1.3135,0,2.1045,0.9365,2.1045,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V662.7144z"
          />
          <path
            d="M1658.625,660.9995c0-1.2646,0.9844-1.7876,2.4072-2.0796l1.5449-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2529-1.1431
				c-0.8027,0-1.2764,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0337,1.2402-1.7754,2.542-1.7754
				c1.4229,0,2.2979,0.6934,2.2979,2.0552v3.1011c0,0.4136,0.2559,0.5474,0.6816,0.4502v0.8755
				c-0.9736,0.1216-1.5322-0.1094-1.666-0.7173c-0.3896,0.4985-1.0947,0.8267-1.958,0.8267
				C1659.5615,662.8237,1658.625,662.1426,1658.625,660.9995z M1662.5771,659.4551l-1.2402,0.2676
				c-0.9736,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9355,0,1.7266-0.5718,1.7266-1.3862
				V659.4551z"
          />
          <path d="M1665.3516,653.9824h1.1914v1.2651h-1.1914V653.9824z M1666.4951,662.7144h-1.0947v-6.3604h1.0947V662.7144z" />
          <path
            d="M1671.4941,657.4365c-0.1826-0.0366-0.3291-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3281,0.0122,0.4385,0.0244V657.4365z"
          />
          <path
            d="M1672.1396,659.54c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C1673.3555,662.8481,1672.1396,661.498,1672.1396,659.54z M1673.2461,658.8716
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C1674.1338,657.1689,1673.4404,657.7891,1673.2461,658.8716z"
          />
          <path
            d="M1603.501,675.79v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7734,1.4956,2.7734,3.3076c0,1.8003-1.0098,3.3081-2.7734,3.3081
				C1604.583,676.8481,1603.8779,676.4224,1603.501,675.79z M1603.4766,673.7715c0,1.459,0.9004,2.1157,1.7998,2.1157
				c1.1924,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.668-2.3467-1.8604-2.3467c-0.8994,0-1.7998,0.6445-1.7998,2.1279V673.7715z"
          />
          <path
            d="M1612.8066,671.4365c-0.1826-0.0366-0.3291-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8994-1.1313,1.7627-1.1313c0.1709,0,0.3281,0.0122,0.4385,0.0244V671.4365z"
          />
          <path
            d="M1616.4072,676.8481c-1.7393,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C1619.3623,675.5103,1618.1465,676.8481,1616.4072,676.8481z M1616.4072,675.9116
				c1.1191,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7295-2.3833-1.8486-2.3833s-1.8486,0.9243-1.8486,2.3833
				C1614.5586,674.9873,1615.2881,675.9116,1616.4072,675.9116z"
          />
          <path
            d="M1620.1914,673.54c0-1.812,1.0098-3.3076,2.7725-3.3076c0.8877,0,1.5938,0.4258,1.9707,1.0459v-3.2959h1.0938v8.7319
				h-1.0938V675.79c-0.377,0.6323-1.083,1.0581-1.9707,1.0581C1621.2012,676.8481,1620.1914,675.3403,1620.1914,673.54z
				 M1623.1582,671.1934c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8008-0.6567,1.8008-2.1157v-0.4502C1624.959,671.8379,1624.0586,671.1934,1623.1582,671.1934z"
          />
          <path
            d="M1632.7676,676.7144h-1.0947v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8848,1.0093c-1.3623,0-2.1289-0.9365-2.1289-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V676.7144z"
          />
          <path
            d="M1637.0254,675.8872c0.876,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3037,1.0581-1.2275,1.8608-2.5537,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3262,0,2.2256,0.7661,2.5303,1.8242
				l-0.9492,0.4741c-0.1826-0.7783-0.7051-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1635.1162,674.9873,1635.8945,675.8872,1637.0254,675.8872z"
          />
          <path
            d="M1640.2256,673.54c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C1641.4424,676.8481,1640.2256,675.498,1640.2256,673.54z M1641.333,672.8716h3.4287
				c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C1642.2207,671.1689,1641.5273,671.7891,1641.333,672.8716z"
          />
          <path
            d="M1647.1113,670.354h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V670.354z"
          />
          <path
            d="M1654.0205,671.2905h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3291,0.7905,0.8877,0.7905c0.2559,0,0.4385-0.0122,0.6934-0.0728v0.936c-0.2793,0.061-0.5957,0.0977-0.96,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V671.2905z"
          />
          <path
            d="M1659.8721,673.54c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C1661.0879,676.8481,1659.8721,675.498,1659.8721,673.54z M1660.9785,672.8716
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C1661.8662,671.1689,1661.1729,671.7891,1660.9785,672.8716z"
          />
          <path
            d="M1666.7568,670.354h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V670.354z"
          />
          <path
            d="M1607.4062,687.54c0-1.812,1.0098-3.3076,2.7725-3.3076c0.8877,0,1.5938,0.4258,1.9707,1.0459v-3.2959h1.0938v8.7319
				h-1.0938V689.79c-0.377,0.6323-1.083,1.0581-1.9707,1.0581C1608.416,690.8481,1607.4062,689.3403,1607.4062,687.54z
				 M1610.373,685.1934c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8008-0.6567,1.8008-2.1157v-0.4502C1612.1738,685.8379,1611.2734,685.1934,1610.373,685.1934z"
          />
          <path
            d="M1614.498,687.54c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C1615.7139,690.8481,1614.498,689.498,1614.498,687.54z M1615.6045,686.8716h3.4297
				c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C1616.4922,685.1689,1615.7988,685.7891,1615.6045,686.8716z"
          />
          <path
            d="M1624.9229,689.79v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7734,1.4956,2.7734,3.3076c0,1.8003-1.0098,3.3081-2.7734,3.3081
				C1626.0049,690.8481,1625.2998,690.4224,1624.9229,689.79z M1624.8984,687.7715c0,1.459,0.9004,2.1157,1.7998,2.1157
				c1.1924,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.668-2.3467-1.8604-2.3467c-0.8994,0-1.7998,0.6445-1.7998,2.1279V687.7715z"
          />
          <path d="M1632.0264,690.7144h-1.0947v-8.7319h1.0947V690.7144z" />
          <path
            d="M1633.292,687.54c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.459-0.4619,1.6543-1.2158l0.96,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C1634.5078,690.8481,1633.292,689.498,1633.292,687.54z M1634.3984,686.8716h3.4297
				c-0.0244-0.8999-0.5107-1.7026-1.6172-1.7026C1635.2861,685.1689,1634.5928,685.7891,1634.3984,686.8716z"
          />
          <path
            d="M1641.2715,687.3213l2.8213-2.9673h1.3623l-2.4814,2.5054l2.6885,3.855h-1.2773l-2.1895-3.0645l-0.9238,0.936v2.1284
				h-1.0947v-8.7319h1.0947V687.3213z"
          />
          <path d="M1650.626,690.7144l-2.3828-6.3604h1.1553l1.7871,5.0225l1.7637-5.0225h1.1436l-2.3838,6.3604H1650.626z" />
          <path
            d="M1654.5439,688.9995c0-1.2646,0.9854-1.7876,2.4082-2.0796l1.5439-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.252-1.1431
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0342-0.2676c0.3047-1.0337,1.2412-1.7754,2.542-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0552v3.1011c0,0.4136,0.2549,0.5474,0.6807,0.4502v0.8755
				c-0.9727,0.1216-1.5322-0.1094-1.666-0.7173c-0.3887,0.4985-1.0947,0.8267-1.958,0.8267
				C1655.4805,690.8237,1654.5439,690.1426,1654.5439,688.9995z M1658.4961,687.4551l-1.2402,0.2676
				c-0.9727,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.9365,0,1.7266-0.5718,1.7266-1.3862
				V687.4551z"
          />
          <path
            d="M1661.3193,684.354h1.0947v0.8633c0.3652-0.5229,0.9971-0.9849,1.8975-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V684.354z"
          />
          <path
            d="M1608.9922,703.8872c0.876,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3037,1.0581-1.2275,1.8608-2.5537,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3262,0,2.2256,0.7661,2.5303,1.8242
				l-0.9492,0.4741c-0.1826-0.7783-0.7051-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1607.083,702.9873,1607.8613,703.8872,1608.9922,703.8872z"
          />
          <path
            d="M1615.1484,704.8481c-1.7393,0-2.9561-1.3379-2.9561-3.3081c0-1.9697,1.2168-3.3076,2.9561-3.3076
				c1.7383,0,2.9551,1.3379,2.9551,3.3076C1618.1035,703.5103,1616.8867,704.8481,1615.1484,704.8481z M1615.1484,703.9116
				c1.1182,0,1.8477-0.9243,1.8477-2.3716c0-1.459-0.7295-2.3833-1.8477-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1613.2998,702.9873,1614.0293,703.9116,1615.1484,703.9116z"
          />
          <path
            d="M1619.3701,698.354h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V698.354z"
          />
          <path
            d="M1626.2549,702.7441c0.3408,0.7417,1.1191,1.1919,1.9463,1.1919c0.7412,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6084-0.7905-1.6543-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0801,0.5596,2.457,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9248-1.0337-1.7266-1.0337
				c-0.7305,0-1.2168,0.3647-1.2168,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0947,0.2554,2.3477,0.4741,2.3477,1.8608
				c0,1.2158-1.1074,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7979-1.4839L1626.2549,702.7441z"
          />
          <path
            d="M1636.9463,704.7144h-1.0947v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8848,1.0093c-1.3623,0-2.1289-0.9365-2.1289-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3896,1.5083,1.3262,1.5083c0.79,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V704.7144z"
          />
          <path
            d="M1639.7334,704.7144h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0332-1.0825,2.0303-1.0825c1.3135,0,2.1045,0.9365,2.1045,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V704.7144z"
          />
          <path
            d="M1650.207,703.79v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4956,2.7725,3.3076c0,1.8003-1.0088,3.3081-2.7725,3.3081
				C1651.2891,704.8481,1650.584,704.4224,1650.207,703.79z M1650.1826,701.7715c0,1.459,0.8994,2.1157,1.7998,2.1157
				c1.1914,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V701.7715z"
          />
          <path
            d="M1656.3867,699.2905h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5957,0.0977-0.9609,0.0977
				c-1.082,0-1.7021-0.3652-1.7021-1.5811V699.2905z"
          />
          <path d="M1660.1816,695.9824h1.1914v1.2651h-1.1914V695.9824z M1661.3252,704.7144h-1.0947v-6.3604h1.0947V704.7144z" />
          <path
            d="M1662.5898,701.54c0-1.897,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5977
				c0.0615,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3403
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C1663.8066,704.8481,1662.5898,703.498,1662.5898,701.54z M1663.6973,700.8716
				h3.4287c-0.0234-0.8999-0.5107-1.7026-1.6172-1.7026C1664.585,699.1689,1663.8916,699.7891,1663.6973,700.8716z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="8">
        <path
          style={{ fill: '#67F5E2' }}
          className="node"
          d="M3952.043,1759.8199c0,25.2661-20.4839,45.75-45.75,45.75c-25.2659,0-45.75-20.4839-45.75-45.75
			c0-25.2689,20.4841-45.75,45.75-45.75C3931.5591,1714.0699,3952.043,1734.551,3952.043,1759.8199"
        />
        <g>
          <path d="M3885.3018,1749.8467h-1.0098l-4.7666-7.0293v7.0293h-1.1191v-8.7324h1.2285l4.5361,6.75v-6.75h1.1309V1749.8467z" />
          <path d="M3886.9453,1741.1143h1.1924v1.2656h-1.1924V1741.1143z M3888.0889,1749.8467h-1.0947v-6.3604h1.0947V1749.8467z" />
          <path d="M3891.3613,1749.8467l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7637-5.0225h1.1426l-2.3838,6.3604H3891.3613z" />
          <path
            d="M3895.2783,1746.6719c0-1.8965,1.2041-3.3076,2.9316-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5977
				c0.0615,1.3379,0.8271,2.1885,1.9336,2.1885c0.8516,0,1.46-0.4619,1.6543-1.2158l0.9609,0.3408
				c-0.3896,1.1309-1.3496,1.8242-2.6152,1.8242C3896.4951,1749.9805,3895.2783,1748.6299,3895.2783,1746.6719z
				 M3896.3857,1746.0039h3.4287c-0.0234-0.9004-0.5107-1.7031-1.6172-1.7031
				C3897.2734,1744.3008,3896.5801,1744.9209,3896.3857,1746.0039z"
          />
          <path
            d="M3901.6045,1748.1318c0-1.2646,0.9844-1.7881,2.4072-2.0801l1.5449-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2764,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0332,1.2402-1.7754,2.542-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1006c0,0.4141,0.2549,0.5479,0.6807,0.4502v0.876
				c-0.9736,0.1211-1.5322-0.1094-1.666-0.7178c-0.3896,0.499-1.0947,0.8271-1.958,0.8271
				C3902.541,1749.9561,3901.6045,1749.2744,3901.6045,1748.1318z M3905.5566,1746.5869l-1.2402,0.2676
				c-0.9736,0.1953-1.6299,0.4629-1.6299,1.2285c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.9355,0,1.7266-0.5723,1.7266-1.3867
				V1746.5869z"
          />
          <path
            d="M3913.3662,1749.8467h-1.0947v-0.876c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0938v3.9766c0,0.8994,0.3896,1.5078,1.3262,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V1749.8467z"
          />
          <path d="M3919.0117,1749.8467l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3919.0117z" />
          <path
            d="M3922.8672,1748.1318c0-1.2646,0.9854-1.7881,2.4082-2.0801l1.5449-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0332,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1006c0,0.4141,0.2559,0.5479,0.6807,0.4502v0.876
				c-0.9727,0.1211-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3923.8037,1749.9561,3922.8672,1749.2744,3922.8672,1748.1318z M3926.8203,1746.5869l-1.2412,0.2676
				c-0.9727,0.1953-1.6289,0.4629-1.6289,1.2285c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V1746.5869z"
          />
          <path
            d="M3929.582,1743.4863h1.0947v0.8633c0.3643-0.5234,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1963h-1.0938v-3.9043c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6084-1.5928,1.666v3.7949h-1.0947V1743.4863z"
          />
          <path
            d="M3865.6572,1763.9805c-1.7393,0-2.9561-1.3379-2.9561-3.3076c0-1.9707,1.2168-3.3086,2.9561-3.3086
				c1.7383,0,2.9551,1.3379,2.9551,3.3086C3868.6123,1762.6426,3867.3955,1763.9805,3865.6572,1763.9805z M3865.6572,1763.0439
				c1.1182,0,1.8477-0.9238,1.8477-2.3711c0-1.46-0.7295-2.3838-1.8477-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C3863.8086,1762.1201,3864.5381,1763.0439,3865.6572,1763.0439z"
          />
          <path
            d="M3873.1133,1758.5684c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9238,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.9004-1.1309,1.7637-1.1309c0.1699,0,0.3281,0.0127,0.4375,0.0244V1758.5684z"
          />
          <path
            d="M3874.3066,1758.4229h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2559,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5957,0.0967-0.9609,0.0967
				c-1.082,0-1.7021-0.3643-1.7021-1.5811V1758.4229z"
          />
          <path
            d="M3878.0898,1755.1152h1.0947v3.2344c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1755.1152z"
          />
          <path
            d="M3887.2852,1763.9805c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C3890.2402,1762.6426,3889.0244,1763.9805,3887.2852,1763.9805z M3887.2852,1763.0439
				c1.1191,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7295-2.3838-1.8486-2.3838s-1.8486,0.9238-1.8486,2.3838
				C3885.4365,1762.1201,3886.166,1763.0439,3887.2852,1763.0439z"
          />
          <path
            d="M3891.0088,1760.6729c0-1.8125,1.0098-3.3086,2.7725-3.3086c0.8877,0,1.5938,0.4258,1.9707,1.0459v-3.2949h1.0938v8.7314
				h-1.0938v-0.9238c-0.377,0.6318-1.083,1.0576-1.9707,1.0576C3892.0186,1763.9805,3891.0088,1762.4727,3891.0088,1760.6729z
				 M3893.9756,1758.3252c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8008-0.6562,1.8008-2.1162v-0.4492C3895.7764,1758.9697,3894.876,1758.3252,3893.9756,1758.3252z"
          />
          <path
            d="M3900.9951,1763.9805c-1.7393,0-2.9561-1.3379-2.9561-3.3076c0-1.9707,1.2168-3.3086,2.9561-3.3086
				c1.7383,0,2.9551,1.3379,2.9551,3.3086C3903.9502,1762.6426,3902.7334,1763.9805,3900.9951,1763.9805z M3900.9951,1763.0439
				c1.1182,0,1.8477-0.9238,1.8477-2.3711c0-1.46-0.7295-2.3838-1.8477-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C3899.1465,1762.1201,3899.876,1763.0439,3900.9951,1763.0439z"
          />
          <path
            d="M3907.3086,1761.3418l-1.751,2.5049h-1.1924l2.3232-3.3203l-2.1279-3.04h1.252l1.5332,2.2256l1.5566-2.2256h1.2031
				l-2.1279,3.04l2.335,3.3203h-1.2402L3907.3086,1761.3418z"
          />
          <path d="M3911.1045,1755.1152h1.1924v1.2646h-1.1924V1755.1152z M3912.248,1763.8467h-1.0947v-6.3604h1.0947V1763.8467z" />
          <path
            d="M3913.4531,1760.6729c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3914.6689,1763.9805,3913.4531,1762.6309,3913.4531,1760.6729z
				 M3914.5596,1760.0039h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C3915.4473,1758.3008,3914.7539,1758.9209,3914.5596,1760.0039z"
          />
          <path d="M3922.6123,1755.1152h1.1924v1.2646h-1.1924V1755.1152z M3923.7559,1763.8467h-1.0947v-6.3604h1.0947V1763.8467z" />
          <path
            d="M3925.3975,1757.4863h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1757.4863z"
          />
          <path
            d="M3934.46,1755.1152h1.0947v3.2344c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1755.1152z"
          />
          <path
            d="M3940.7012,1760.6729c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3941.917,1763.9805,3940.7012,1762.6309,3940.7012,1760.6729z M3941.8076,1760.0039
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3942.6953,1758.3008,3942.002,1758.9209,3941.8076,1760.0039z"
          />
          <path
            d="M3947.6953,1758.4229h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V1758.4229z"
          />
          <path
            d="M3857.3008,1774.6729c0-1.8125,1.0098-3.3086,2.7725-3.3086c0.8877,0,1.5938,0.4258,1.9707,1.0459v-3.2949h1.0938v8.7314
				h-1.0938v-0.9238c-0.377,0.6318-1.083,1.0576-1.9707,1.0576C3858.3105,1777.9805,3857.3008,1776.4727,3857.3008,1774.6729z
				 M3860.2676,1772.3252c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8008-0.6562,1.8008-2.1162v-0.4492C3862.0684,1772.9697,3861.168,1772.3252,3860.2676,1772.3252z"
          />
          <path
            d="M3869.0254,1777.8467h-1.0938v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8857,1.0098c-1.3613,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3887,1.5078,1.3252,1.5078c0.791,0,1.5938-0.5957,1.5938-1.6533v-3.8311h1.0938V1777.8467z"
          />
          <path
            d="M3874.9131,1777.8467h-1.0938v-0.876c-0.3652,0.5352-0.9854,1.0098-1.8857,1.0098c-1.3613,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3887,1.5078,1.3252,1.5078c0.791,0,1.5938-0.5957,1.5938-1.6533v-3.8311h1.0938V1777.8467z"
          />
          <path
            d="M3879.0498,1772.5684c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9238,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.9004-1.1309,1.7637-1.1309c0.1699,0,0.3281,0.0127,0.4375,0.0244V1772.5684z"
          />
          <path d="M3883.8789,1777.8467h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9258v0.8389l-3.5996,4.585h3.6357V1777.8467z" />
          <path
            d="M3883.832,1776.1318c0-1.2646,0.9844-1.7871,2.4072-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2764,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.542-1.7764
				c1.4229,0,2.2979,0.6934,2.2979,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6816,0.4492v0.876
				c-0.9736,0.1221-1.5322-0.1094-1.666-0.7178c-0.3896,0.499-1.0947,0.8271-1.958,0.8271
				C3884.7686,1777.9561,3883.832,1777.2754,3883.832,1776.1318z M3887.7842,1774.5879l-1.2402,0.2676
				c-0.9736,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.9355,0,1.7266-0.5713,1.7266-1.3867
				V1774.5879z"
          />
          <path
            d="M3889.2568,1776.1318c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5439-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.252-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0342-0.2676c0.3047-1.0342,1.2412-1.7764,2.542-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2549,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0947,0.8271-1.958,0.8271
				C3890.1934,1777.9561,3889.2568,1777.2754,3889.2568,1776.1318z M3893.209,1774.5879l-1.2402,0.2676
				c-0.9727,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.9365,0,1.7266-0.5713,1.7266-1.3867
				V1774.5879z"
          />
          <path
            d="M3896.2754,1777.8467h-1.0947v-6.3604h1.0947v0.8633c0.3408-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0342-1.083,2.0312-1.083c1.3135,0,2.1035,0.9365,2.1035,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V1777.8467z"
          />
          <path
            d="M3904.8027,1769.1152h1.0947v3.2344c0.3652-0.5225,0.9971-0.9854,1.8975-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3252-1.5566c-0.791,0-1.5938,0.6074-1.5938,1.666v3.7939h-1.0947V1769.1152z"
          />
          <path
            d="M3910.2529,1774.6729c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3911.4688,1777.9805,3910.2529,1776.6309,3910.2529,1774.6729z
				 M3911.3594,1774.0039h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C3912.2471,1772.3008,3911.5537,1772.9209,3911.3594,1774.0039z"
          />
          <path d="M3916.2373,1769.1152h1.1924v1.2646h-1.1924V1769.1152z M3917.3809,1777.8467h-1.0947v-6.3604h1.0947V1777.8467z" />
          <path
            d="M3917.7949,1774.6729c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C3918.8037,1777.9805,3917.7949,1776.4727,3917.7949,1774.6729z
				 M3920.7617,1772.3252c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C3922.5615,1772.9697,3921.6621,1772.3252,3920.7617,1772.3252z"
          />
          <path
            d="M3924.6182,1775.877c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L3924.6182,1775.877z"
          />
          <path
            d="M3928.9746,1774.6729c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C3929.9834,1777.9805,3928.9746,1776.4727,3928.9746,1774.6729z
				 M3931.9414,1772.3252c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C3933.7412,1772.9697,3932.8418,1772.3252,3931.9414,1772.3252z"
          />
          <path
            d="M3935.2148,1774.6729c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3936.4307,1777.9805,3935.2148,1776.6309,3935.2148,1774.6729z
				 M3936.3213,1774.0039h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031
				C3937.209,1772.3008,3936.5156,1772.9209,3936.3213,1774.0039z"
          />
          <path
            d="M3942.3428,1776.9229v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4961,2.7725,3.3086c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C3943.4248,1777.9805,3942.7197,1777.5547,3942.3428,1776.9229z M3942.3184,1774.9033c0,1.46,0.8994,2.1162,1.7998,2.1162
				c1.1914,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6689-2.3477-1.8604-2.3477c-0.9004,0-1.7998,0.6445-1.7998,2.1289V1774.9033z"
          />
          <path
            d="M3947.001,1776.1318c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3947.9375,1777.9561,3947.001,1777.2754,3947.001,1776.1318z M3950.9541,1774.5879l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V1774.5879z"
          />
          <path
            d="M3953.0957,1772.4229h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V1772.4229z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="7">
        <path
          style={{ fill: '#FFA3C8' }}
          className="node"
          d="M2784.1599,1624.715c0,25.269-20.48,45.75-45.75,45.75c-25.2649,0-45.75-20.481-45.75-45.75
			c0-25.266,20.4851-45.75,45.75-45.75C2763.6799,1578.965,2784.1599,1599.449,2784.1599,1624.715"
        />
        <g>
          <path
            d="M2695.1777,1599.0098h3.2227c1.8486,0,2.833,0.8145,2.833,2.2734c0,1.1191-0.6807,1.6787-1.3496,1.9102
				c0.9854,0.2666,1.6416,1.0332,1.6416,2.1396c0,1.5205-1.1064,2.4082-2.8701,2.4082h-3.4775V1599.0098z M2698.3027,1602.7549
				c1.168,0,1.8125-0.4502,1.8125-1.374c0-0.9365-0.6445-1.3867-1.8125-1.3867h-1.9941v2.7607H2698.3027z M2696.3086,1606.7559
				h2.2988c1.167,0,1.7998-0.6074,1.7998-1.5078c0-0.8877-0.6328-1.4961-1.7998-1.4961h-2.2988V1606.7559z"
          />
          <path
            d="M2702.2822,1604.5674c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2703.498,1607.875,2702.2822,1606.5254,2702.2822,1604.5674z M2703.3887,1603.8984
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2704.2764,1602.1953,2703.583,1602.8154,2703.3887,1603.8984z"
          />
          <path
            d="M2709.252,1605.7715c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L2709.252,1605.7715z"
          />
          <path
            d="M2717.4023,1606.9141c0.875,0,1.3984-0.5596,1.5811-1.3496l0.9727,0.4502c-0.3047,1.0576-1.2285,1.8604-2.5537,1.8604
				c-1.7764,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2275-3.3086,3.0039-3.3086c1.3252,0,2.2256,0.7666,2.5293,1.8242
				l-0.9482,0.4746c-0.1826-0.7783-0.7061-1.3496-1.5811-1.3496c-1.1318,0-1.9102,0.8994-1.9102,2.3594
				C2715.4922,1606.0146,2716.2705,1606.9141,2717.4023,1606.9141z"
          />
          <path
            d="M2720.9795,1599.0098h1.0947v3.2344c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1599.0098z"
          />
          <path d="M2727.6084,1599.0098h1.1924v1.2646h-1.1924V1599.0098z M2728.752,1607.7412h-1.0947v-6.3604h1.0947V1607.7412z" />
          <path
            d="M2731.4883,1604.3486l2.8213-2.9678h1.3623l-2.4814,2.5049l2.6875,3.8555h-1.2764l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V1604.3486z"
          />
          <path
            d="M2737.8018,1606.8174v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4961,2.7725,3.3086c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C2738.8838,1607.875,2738.1787,1607.4492,2737.8018,1606.8174z M2737.7773,1604.7979c0,1.46,0.8994,2.1162,1.7998,2.1162
				c1.1914,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6689-2.3477-1.8604-2.3477c-0.9004,0-1.7998,0.6445-1.7998,2.1289V1604.7979z"
          />
          <path
            d="M2743.251,1606.0264c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2744.1875,1607.8506,2743.251,1607.1699,2743.251,1606.0264z M2747.2041,1604.4824l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V1604.4824z"
          />
          <path
            d="M2749.4668,1606.0264c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2750.4033,1607.8506,2749.4668,1607.1699,2749.4668,1606.0264z M2753.4199,1604.4824l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V1604.4824z"
          />
          <path
            d="M2759.4785,1602.4629c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V1602.4629z"
          />
          <path
            d="M2760.501,1599.0098h1.0947v3.2344c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1599.0098z"
          />
          <path
            d="M2766.7412,1604.5674c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2767.957,1607.875,2766.7412,1606.5254,2766.7412,1604.5674z M2767.8477,1603.8984
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2768.7354,1602.1953,2768.042,1602.8154,2767.8477,1603.8984z"
          />
          <path d="M2773.5166,1599.0098h1.1924v1.2646h-1.1924V1599.0098z M2774.6602,1607.7412h-1.0947v-6.3604h1.0947V1607.7412z" />
          <path
            d="M2775.8643,1604.5674c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2776.873,1607.875,2775.8643,1606.3672,2775.8643,1604.5674z
				 M2778.8311,1602.2197c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2780.6309,1602.8643,2779.7314,1602.2197,2778.8311,1602.2197z"
          />
          <path d="M2703.5342,1621.7412l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225H2707l-2.3838,6.3604H2703.5342z" />
          <path
            d="M2707.3896,1620.0264c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2708.3262,1621.8506,2707.3896,1621.1699,2707.3896,1620.0264z M2711.3428,1618.4824l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V1618.4824z"
          />
          <path
            d="M2714.1045,1615.3809h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V1615.3809z"
          />
          <path
            d="M2722.7305,1618.5674c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2723.7393,1621.875,2722.7305,1620.3672,2722.7305,1618.5674z
				 M2725.6973,1616.2197c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2727.4971,1616.8643,2726.5977,1616.2197,2725.6973,1616.2197z"
          />
          <path
            d="M2735.2461,1621.7412h-1.0947v-0.876c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3887,1.5078,1.3252,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V1621.7412z"
          />
          <path
            d="M2741.9238,1621.7412h-1.0947v-0.876c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.9004,0.3887,1.5078,1.3252,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V1621.7412z"
          />
          <path
            d="M2746.8516,1616.4629c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V1616.4629z"
          />
          <path d="M2752.4707,1621.7412h-5.0098v-0.8271l3.6719-4.5967h-3.624v-0.9365h4.9258v0.8389l-3.5996,4.585h3.6357V1621.7412z" />
          <path
            d="M2753.2139,1620.0264c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2754.1504,1621.8506,2753.2139,1621.1699,2753.2139,1620.0264z M2757.167,1618.4824l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V1618.4824z"
          />
          <path
            d="M2759.4307,1620.0264c0-1.2646,0.9854-1.7871,2.4082-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.083l-1.0332-0.2676c0.3037-1.0342,1.2402-1.7764,2.541-1.7764
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C2760.3672,1621.8506,2759.4307,1621.1699,2759.4307,1620.0264z M2763.3838,1618.4824l-1.2412,0.2676
				c-0.9727,0.1943-1.6289,0.4619-1.6289,1.2275c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5713,1.7275-1.3867
				V1618.4824z"
          />
          <path
            d="M2767.2402,1621.7412h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0332-1.083,2.0312-1.083c1.3135,0,2.1035,0.9365,2.1035,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V1621.7412z"
          />
          <path
            d="M2703.9473,1638.0645c-1.3994,0-2.4209-0.6445-2.7734-1.8125l1.0215-0.3887c0.207,0.8145,0.8643,1.2891,1.752,1.2891
				c1.1182,0,1.8604-0.5723,1.8604-1.8975v-0.7178c-0.3408,0.6201-1.0576,1.0586-1.9463,1.0586
				c-1.7383,0-2.7598-1.4355-2.7598-3.1748c0-1.7266,1.0215-3.1621,2.7598-3.1621c0.876,0,1.6055,0.4385,1.9463,1.0586v-0.9365
				h1.0947v5.7891C2706.9023,1637.0303,2705.6738,1638.0645,2703.9473,1638.0645z M2704.0566,1630.2197
				c-1.1553,0-1.8613,0.8633-1.8613,2.2012s0.7061,2.2139,1.8613,2.2139c0.9121,0,1.7627-0.6816,1.7627-2.0801v-0.2305
				C2705.8193,1630.9014,2704.9688,1630.2197,2704.0566,1630.2197z"
          />
          <path
            d="M2708.1084,1632.5674c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2709.3242,1635.875,2708.1084,1634.5254,2708.1084,1632.5674z M2709.2148,1631.8984
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2710.1025,1630.1953,2709.4092,1630.8154,2709.2148,1631.8984z"
          />
          <path
            d="M2716.0273,1634.8174v3.1133h-1.0947v-8.5498h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4961,2.7725,3.3086c0,1.7998-1.0088,3.3076-2.7725,3.3076
				C2717.1094,1635.875,2716.4043,1635.4492,2716.0273,1634.8174z M2716.0029,1632.7979c0,1.46,0.8994,2.1162,1.7998,2.1162
				c1.1914,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6689-2.3477-1.8604-2.3477c-0.9004,0-1.7998,0.6445-1.7998,2.1289V1632.7979z"
          />
          <path
            d="M2725.2715,1630.4629c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V1630.4629z"
          />
          <path
            d="M2728.8125,1635.875c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C2731.7676,1634.5371,2730.5518,1635.875,2728.8125,1635.875z M2728.8125,1634.9385
				c1.1182,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C2726.9639,1634.0146,2727.6934,1634.9385,2728.8125,1634.9385z"
          />
          <path
            d="M2732.5361,1632.5674c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2733.5449,1635.875,2732.5361,1634.3672,2732.5361,1632.5674z
				 M2735.5029,1630.2197c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2737.3027,1630.8643,2736.4033,1630.2197,2735.5029,1630.2197z"
          />
          <path
            d="M2745.0518,1635.7412h-1.0947v-0.876c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0938v3.9766c0,0.9004,0.3896,1.5078,1.3262,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V1635.7412z"
          />
          <path
            d="M2749.249,1634.9141c0.875,0,1.3984-0.5596,1.5801-1.3496l0.9736,0.4502c-0.3047,1.0576-1.2285,1.8604-2.5537,1.8604
				c-1.7764,0-3.0039-1.3496-3.0039-3.3076c0-1.9707,1.2275-3.3086,3.0039-3.3086c1.3252,0,2.2256,0.7666,2.5293,1.8242
				l-0.9492,0.4746c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9102,0.8994-1.9102,2.3594
				C2747.3389,1634.0146,2748.1172,1634.9141,2749.249,1634.9141z"
          />
          <path
            d="M2752.3877,1632.5674c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2753.6035,1635.875,2752.3877,1634.5254,2752.3877,1632.5674z M2753.4941,1631.8984
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2754.3818,1630.1953,2753.6885,1630.8154,2753.4941,1631.8984z"
          />
          <path
            d="M2758.7744,1632.5674c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2759.9902,1635.875,2758.7744,1634.5254,2758.7744,1632.5674z M2759.8809,1631.8984
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2760.7686,1630.1953,2760.0752,1630.8154,2759.8809,1631.8984z"
          />
          <path
            d="M2768.8955,1630.4629c-0.1826-0.0361-0.3281-0.0479-0.5352-0.0479c-0.9248,0-1.666,0.7295-1.666,1.8477v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0127,0.4385,0.0244V1630.4629z"
          />
          <path
            d="M2769.4805,1632.5674c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2770.4893,1635.875,2769.4805,1634.3672,2769.4805,1632.5674z
				 M2772.4473,1630.2197c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2774.2471,1630.8643,2773.3477,1630.2197,2772.4473,1630.2197z"
          />
          <path d="M2720.3945,1649.7412l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H2720.3945z" />
          <path
            d="M2727.2666,1649.875c-1.7393,0-2.9551-1.3379-2.9551-3.3076c0-1.9707,1.2158-3.3086,2.9551-3.3086
				s2.9551,1.3379,2.9551,3.3086C2730.2217,1648.5371,2729.0059,1649.875,2727.2666,1649.875z M2727.2666,1648.9385
				c1.1182,0,1.8486-0.9238,1.8486-2.3711c0-1.46-0.7305-2.3838-1.8486-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C2725.418,1648.0146,2726.1475,1648.9385,2727.2666,1648.9385z"
          />
          <path
            d="M2730.9893,1646.5674c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2732.2051,1649.875,2730.9893,1648.5254,2730.9893,1646.5674z M2732.0957,1645.8984
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2732.9834,1644.1953,2732.29,1644.8154,2732.0957,1645.8984z"
          />
          <path
            d="M2737.376,1646.5674c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C2738.3848,1649.875,2737.376,1648.3672,2737.376,1646.5674z
				 M2740.3428,1644.2197c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C2742.1426,1644.8643,2741.2432,1644.2197,2740.3428,1644.2197z"
          />
          <path
            d="M2744.9912,1647.7715c0.3408,0.7412,1.1191,1.1914,1.9463,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4619-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.7295,0-1.2158,0.3652-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4834L2744.9912,1647.7715z"
          />
          <path
            d="M2750.1377,1646.5674c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C2751.3535,1649.875,2750.1377,1648.5254,2750.1377,1646.5674z M2751.2441,1645.8984
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C2752.1318,1644.1953,2751.4385,1644.8154,2751.2441,1645.8984z"
          />
          <path d="M2758.0566,1649.7412h-1.0947v-8.7314h1.0947V1649.7412z" />
        </g>
      </g>
      <g onClick={handleClick} id="6">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1521.5081,380.0391c0,25.27-20.4811,45.75-45.75,45.75c-25.2661,0-45.75-20.48-45.75-45.75
			c0-25.2659,20.4839-45.75,45.75-45.75C1501.027,334.2891,1521.5081,354.7732,1521.5081,380.0391"
        />
        <g>
          <path d="M1455.6978,347.3335v1.0215h-4.5483v2.688h3.855v1.0215h-3.855v2.9795h4.5483v1.0215h-5.7036v-8.7319H1455.6978z" />
          <path
            d="M1459.9561,348.1973c-0.1826-0.0244-0.292-0.0244-0.4746-0.0244c-0.6445,0-0.9971,0.2798-0.9971,1.0942v0.438h1.4106
				v0.9365h-1.4106v5.4238h-1.0947v-5.4238h-0.9604v-0.9365h0.9604v-0.5107c0-1.2402,0.6812-1.9946,1.9824-1.9946
				c0.2188,0,0.3403,0.0122,0.584,0.0244V348.1973z"
          />
          <path
            d="M1463.6904,348.1973c-0.1826-0.0244-0.292-0.0244-0.4746-0.0244c-0.6445,0-0.9971,0.2798-0.9971,1.0942v0.438h1.4106
				v0.9365h-1.4106v5.4238h-1.0947v-5.4238h-0.9604v-0.9365h0.9604v-0.5107c0-1.2402,0.6812-1.9946,1.9824-1.9946
				c0.2188,0,0.3403,0.0122,0.584,0.0244V348.1973z"
          />
          <path d="M1464.603,347.3335h1.1919v1.2651h-1.1919V347.3335z M1465.7466,356.0654h-1.0947v-6.3604h1.0947V356.0654z" />
          <path
            d="M1470.1372,355.2383c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3037,1.0581-1.228,1.8608-2.5537,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1468.228,354.3384,1469.0063,355.2383,1470.1372,355.2383z"
          />
          <path d="M1473.8481,347.3335h1.1919v1.2651h-1.1919V347.3335z M1474.9917,356.0654h-1.0947v-6.3604h1.0947V356.0654z" />
          <path
            d="M1477.3872,353.3169c0.0244,1.4473,0.9492,2.043,2.0186,2.043c0.7666,0,1.2285-0.1338,1.6299-0.3037l0.1826,0.7661
				c-0.377,0.1704-1.0215,0.3647-1.958,0.3647c-1.8115,0-2.8945-1.1919-2.8945-2.9673s1.0459-3.1743,2.7607-3.1743
				c1.9219,0,2.4326,1.6904,2.4326,2.7729c0,0.2188-0.0244,0.3892-0.0371,0.4985H1477.3872z M1480.5249,352.5508
				c0.0127-0.6812-0.2793-1.7393-1.4834-1.7393c-1.082,0-1.5566,0.9976-1.6416,1.7393H1480.5249z M1477.4243,348.5132
				c0-0.3403,0.2793-0.6201,0.6201-0.6201c0.3398,0,0.5957,0.2676,0.5957,0.6201c0,0.3286-0.2432,0.6201-0.5957,0.6201
				C1477.6792,349.1333,1477.4243,348.8418,1477.4243,348.5132z M1479.686,348.5132c0-0.3403,0.2676-0.6201,0.6084-0.6201
				c0.3398,0,0.5957,0.2676,0.5957,0.6201c0,0.3286-0.2432,0.6201-0.5957,0.6201
				C1479.9292,349.1333,1479.686,348.8418,1479.686,348.5132z"
          />
          <path
            d="M1482.9106,349.7051h1.0947v0.8633c0.3643-0.5229,0.9971-0.9849,1.8965-0.9849c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0938v-3.9038c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V349.7051z"
          />
          <path
            d="M1489.9419,350.6416h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V350.6416z"
          />
          <path d="M1493.8589,347.3335h1.1924v1.2651h-1.1924V347.3335z M1495.0024,356.0654h-1.0947v-6.3604h1.0947V356.0654z" />
          <path
            d="M1496.3892,352.8911c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C1497.605,356.1992,1496.3892,354.8491,1496.3892,352.8911z M1497.4956,352.2227
				h3.4297c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C1498.3833,350.52,1497.6899,351.1401,1497.4956,352.2227z"
          />
          <path d="M1443.8379,370.0654l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1443.8379z" />
          <path
            d="M1447.8774,368.3506c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1448.814,370.1748,1447.8774,369.4941,1447.8774,368.3506z M1451.8296,366.8066l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V366.8066z"
          />
          <path
            d="M1454.7744,363.7051h1.0947v0.8633c0.3647-0.5225,0.9971-0.9854,1.897-0.9854c1.3496,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0947v-3.9033c0-0.8887-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V363.7051z"
          />
          <path d="M1465.2964,370.0654h-1.0947v-8.7314h1.0947V370.0654z" />
          <path
            d="M1466.6226,368.3506c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C1467.5591,370.1748,1466.6226,369.4941,1466.6226,368.3506z M1470.5747,366.8066l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V366.8066z"
          />
          <path
            d="M1473.666,368.0957c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7422,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6084-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9238-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L1473.666,368.0957z"
          />
          <path
            d="M1479.6021,364.6416h-0.9844v-0.9365h0.9844v-1.8604h1.083v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9365c-0.2803,0.0605-0.5967,0.0967-0.9609,0.0967
				c-1.083,0-1.7031-0.3643-1.7031-1.5811V364.6416z"
          />
          <path d="M1483.2759,366.0283h3.3203v0.9844h-3.3203V366.0283z" />
          <path
            d="M1489.2241,370.0654h-1.0947v-6.3604h1.0947v0.8633c0.3398-0.5225,0.9482-0.9854,1.8359-0.9854
				c0.8516,0,1.4473,0.4258,1.7637,1.083c0.3525-0.5234,1.0332-1.083,2.0312-1.083c1.3125,0,2.1035,0.9365,2.1035,2.2871v4.1953
				h-1.0947v-3.9033c0-0.8887-0.3643-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533v3.8066h-1.0947v-3.9033
				c0-0.8887-0.3652-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6533V370.0654z"
          />
          <path d="M1498.6763,361.334h1.1924v1.2646h-1.1924V361.334z M1499.8198,370.0654h-1.0947v-6.3604h1.0947V370.0654z" />
          <path d="M1502.7388,370.0654h-1.0947v-8.7314h1.0947V370.0654z" />
          <path
            d="M1504.1265,366.8916c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C1505.3423,370.1992,1504.1265,368.8496,1504.1265,366.8916z M1505.2329,366.2227
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C1506.1206,364.5195,1505.4272,365.1396,1505.2329,366.2227z"
          />
          <path d="M1448.6191,384.0654h-1.0947v-8.7314h1.0947V384.0654z" />
          <path
            d="M1452.9614,384.1992c-1.7393,0-2.9556-1.3379-2.9556-3.3076c0-1.9707,1.2163-3.3086,2.9556-3.3086
				c1.7388,0,2.9551,1.3379,2.9551,3.3086C1455.9165,382.8613,1454.7002,384.1992,1452.9614,384.1992z M1452.9614,383.2627
				c1.1187,0,1.8481-0.9238,1.8481-2.3711c0-1.46-0.7295-2.3838-1.8481-2.3838c-1.1191,0-1.8486,0.9238-1.8486,2.3838
				C1451.1128,382.3389,1451.8423,383.2627,1452.9614,383.2627z"
          />
          <path
            d="M1459.7368,386.3887c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3403,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.6055,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C1462.6919,385.3545,1461.4639,386.3887,1459.7368,386.3887z M1459.8462,378.5439
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C1461.6099,379.2256,1460.7583,378.5439,1459.8462,378.5439z"
          />
          <path d="M1464.4692,375.334h1.1919v1.2646h-1.1919V375.334z M1465.6128,384.0654h-1.0947v-6.3604h1.0947V384.0654z" />
          <path
            d="M1467.5835,382.0957c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9243-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L1467.5835,382.0957z"
          />
          <path
            d="M1473.52,378.6416h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2559,0,0.4385-0.0117,0.6934-0.0723v0.9365c-0.2793,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V378.6416z"
          />
          <path d="M1477.437,375.334h1.1924v1.2646h-1.1924V375.334z M1478.5806,384.0654h-1.0947v-6.3604h1.0947V384.0654z" />
          <path
            d="M1479.9673,380.8916c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C1481.1831,384.1992,1479.9673,382.8496,1479.9673,380.8916z M1481.0737,380.2227
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C1481.9614,378.5195,1481.2681,379.1396,1481.0737,380.2227z"
          />
          <path
            d="M1488.0688,380.6729l2.8213-2.9678h1.3623l-2.4814,2.5049l2.6875,3.8555h-1.2764l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V380.6729z"
          />
          <path d="M1495.9878,375.334h1.1924v1.2646h-1.1924V375.334z M1497.1313,384.0654h-1.0947v-6.3604h1.0947V384.0654z" />
          <path
            d="M1498.9556,377.7051h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V377.7051z"
          />
          <path
            d="M1450.7056,396.0957c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9243-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L1450.7056,396.0957z"
          />
          <path
            d="M1456.6421,392.6416h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2559,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V392.6416z"
          />
          <path
            d="M1460.1694,394.8916c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1461.3857,398.1992,1460.1694,396.8496,1460.1694,394.8916z M1461.2764,394.2227
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1462.1641,392.5195,1461.4707,393.1396,1461.2764,394.2227z"
          />
          <path
            d="M1466.7383,394.8916c0-1.8125,1.0098-3.3086,2.7729-3.3086c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2949h1.0942v8.7314
				h-1.0942v-0.9238c-0.377,0.6318-1.0825,1.0576-1.9702,1.0576C1467.748,398.1992,1466.7383,396.6914,1466.7383,394.8916z
				 M1469.7056,392.5439c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.8003-0.6562,1.8003-2.1162v-0.4492C1471.5059,393.1885,1470.606,392.5439,1469.7056,392.5439z"
          />
          <path
            d="M1473.9517,394.8916c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7764,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.46-0.4629,1.6543-1.2168l0.9609,0.3408
				c-0.3896,1.1309-1.3506,1.8242-2.6152,1.8242C1475.168,398.1992,1473.9517,396.8496,1473.9517,394.8916z M1475.0586,394.2227
				h3.4292c-0.0244-0.9004-0.5107-1.7031-1.6172-1.7031C1475.9458,392.5195,1475.2529,393.1396,1475.0586,394.2227z"
          />
          <path d="M1482.0532,398.0654h-1.0947v-8.7314h1.0947V398.0654z" />
          <path d="M1483.8296,389.334h1.1924v1.2646h-1.1924V389.334z M1484.9731,398.0654h-1.0947v-6.3604h1.0947V398.0654z" />
          <path
            d="M1486.7974,391.7051h1.0947v6.8828c0,1.1318-0.7061,1.7031-1.7881,1.7031c-0.2549,0-0.4131-0.0127-0.6084-0.0361v-0.9492
				c0.1338,0.0244,0.2441,0.0371,0.4258,0.0371c0.6328,0,0.876-0.3047,0.876-0.9492V391.7051z M1486.7485,389.334h1.1924v1.2646
				h-1.1924V389.334z"
          />
          <path
            d="M1490.812,394.6729l2.8213-2.9678h1.3623l-2.4814,2.5049l2.6875,3.8555h-1.2764l-2.1895-3.0645l-0.9238,0.9365v2.1279
				h-1.0947v-8.7314h1.0947V394.6729z"
          />
          <path
            d="M1495.7749,394.8916c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C1496.9907,398.1992,1495.7749,396.8496,1495.7749,394.8916z M1496.8813,394.2227
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C1497.769,392.5195,1497.0757,393.1396,1496.8813,394.2227z"
          />
          <path
            d="M1453.5327,414.3887c-1.3984,0-2.4199-0.6445-2.7725-1.8125l1.0215-0.3887c0.2065,0.8145,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5723,1.8608-1.8975v-0.7178c-0.3403,0.6201-1.0581,1.0586-1.9458,1.0586
				c-1.7393,0-2.7607-1.4355-2.7607-3.1748c0-1.7266,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.6055,0.4385,1.9458,1.0586v-0.9365
				h1.0942v5.7891C1456.4878,413.3545,1455.2598,414.3887,1453.5327,414.3887z M1453.6421,406.5439
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012s0.7051,2.2139,1.8604,2.2139c0.9121,0,1.7637-0.6816,1.7637-2.0801v-0.2305
				C1455.4058,407.2256,1454.5542,406.5439,1453.6421,406.5439z"
          />
          <path
            d="M1457.876,408.8916c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1459.0923,412.1992,1457.876,410.8496,1457.876,408.8916z M1458.9829,408.2227
				h3.4292c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C1459.8706,406.5195,1459.1772,407.1396,1458.9829,408.2227z"
          />
          <path
            d="M1465.9775,411.1416v0.9238h-1.0947v-8.7314h1.0947v3.2949c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C1467.0596,412.1992,1466.3545,411.7734,1465.9775,411.1416z M1465.9531,409.1221c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8608-0.9854,1.8608-2.3467c0-1.374-0.6689-2.3477-1.8608-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V409.1221z"
          />
          <path d="M1472.0601,403.334h1.1919v1.2646h-1.1919V403.334z M1473.2036,412.0654h-1.0947v-6.3604h1.0947V412.0654z" />
          <path
            d="M1474.5903,408.8916c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7764,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.46-0.4629,1.6543-1.2168l0.9609,0.3408
				c-0.3896,1.1309-1.3506,1.8242-2.6152,1.8242C1475.8062,412.1992,1474.5903,410.8496,1474.5903,408.8916z M1475.6973,408.2227
				h3.4292c-0.0244-0.9004-0.5107-1.7031-1.6172-1.7031C1476.5845,406.5195,1475.8921,407.1396,1475.6973,408.2227z"
          />
          <path
            d="M1481.1597,408.8916c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C1482.1685,412.1992,1481.1597,410.6914,1481.1597,408.8916z
				 M1484.1265,406.5439c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C1485.9263,407.1885,1485.0269,406.5439,1484.1265,406.5439z"
          />
          <path
            d="M1488.3726,408.8916c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C1489.5884,412.1992,1488.3726,410.8496,1488.3726,408.8916z M1489.479,408.2227
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C1490.3667,406.5195,1489.6733,407.1396,1489.479,408.2227z"
          />
          <path
            d="M1495.3794,405.7051h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V405.7051z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="5">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M980.242,815.5c0,25.2661-20.484,45.75-45.75,45.75c-25.265,0-45.75-20.4839-45.75-45.75
			c0-25.2659,20.485-45.75,45.75-45.75C959.758,769.75,980.242,790.2341,980.242,815.5"
        />
        <g>
          <path
            d="M910.917,798.7944h3.2227c1.8486,0,2.8335,0.8149,2.8335,2.2744c0,1.1187-0.6812,1.6782-1.3501,1.9092
				c0.9854,0.2676,1.6421,1.0337,1.6421,2.1406c0,1.52-1.1069,2.4077-2.8701,2.4077h-3.478V798.7944z M914.042,802.54
				c1.168,0,1.8125-0.4497,1.8125-1.374c0-0.9365-0.6445-1.3862-1.8125-1.3862h-1.9941v2.7603H914.042z M912.0479,806.5415h2.2983
				c1.1675,0,1.7998-0.6084,1.7998-1.5083c0-0.8877-0.6323-1.4956-1.7998-1.4956h-2.2983V806.5415z"
          />
          <path
            d="M918.2031,804.3521c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C919.4194,807.6602,918.2031,806.3101,918.2031,804.3521z M919.3101,803.6836h3.4292
				c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C920.1978,801.981,919.5044,802.6011,919.3101,803.6836z"
          />
          <path
            d="M925.21,798.7944h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944H925.21V798.7944z"
          />
          <path
            d="M934.5879,807.6602c-1.7388,0-2.9551-1.3379-2.9551-3.3081c0-1.9697,1.2163-3.3076,2.9551-3.3076
				c1.7393,0,2.9551,1.3379,2.9551,3.3076C937.543,806.3223,936.3271,807.6602,934.5879,807.6602z M934.5879,806.7236
				c1.1191,0,1.8486-0.9243,1.8486-2.3716c0-1.459-0.7295-2.3833-1.8486-2.3833c-1.1187,0-1.8481,0.9243-1.8481,2.3833
				C932.7397,805.7993,933.4692,806.7236,934.5879,806.7236z"
          />
          <path
            d="M938.4941,804.3521c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647H939.54
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C939.71,807.6602,938.4941,806.3101,938.4941,804.3521z M939.6006,803.6836h3.4297
				c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C940.4883,801.981,939.7949,802.6011,939.6006,803.6836z"
          />
          <path
            d="M948.2734,799.6582c-0.1826-0.0244-0.292-0.0244-0.4736-0.0244c-0.6445,0-0.998,0.2798-0.998,1.0942v0.438h1.4111v0.9365
				h-1.4111v5.4238h-1.0938v-5.4238h-0.9609v-0.9365h0.9609v-0.5107c0-1.2402,0.6807-1.9946,1.9824-1.9946
				c0.2188,0,0.3398,0.0122,0.583,0.0244V799.6582z"
          />
          <path
            d="M949.4053,802.1025h-0.9844v-0.9365h0.9844v-1.8608h1.083v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.7905,0.8877,0.7905c0.2549,0,0.4375-0.0122,0.6934-0.0728v0.936c-0.2803,0.061-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V802.1025z"
          />
          <path
            d="M952.9336,804.3521c0-1.897,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5967
				c0.0605,1.3379,0.8262,2.189,1.9336,2.189c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3403
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C954.1494,807.6602,952.9336,806.3101,952.9336,804.3521z M954.04,803.6836h3.4297
				c-0.0244-0.8999-0.5107-1.7026-1.6182-1.7026C954.9277,801.981,954.2344,802.6011,954.04,803.6836z"
          />
          <path
            d="M925.0767,817.8115c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5444-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2524-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4229,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2554,0.5469,0.6812,0.4492v0.876
				c-0.9731,0.1221-1.5322-0.1094-1.666-0.7178c-0.3892,0.499-1.0947,0.8271-1.958,0.8271
				C926.0132,819.6357,925.0767,818.9551,925.0767,817.8115z M929.0288,816.2676l-1.2402,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7266-0.5713,1.7266-1.3867
				V816.2676z"
          />
          <path
            d="M931.4751,817.8115c0-1.2646,0.9849-1.7871,2.4077-2.0791l1.5449-0.3164v-0.2676c0-0.7295-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2769,0.377-1.4717,1.083l-1.0337-0.2676c0.3042-1.0342,1.2407-1.7764,2.542-1.7764
				c1.4224,0,2.2983,0.6934,2.2983,2.0557v3.1016c0,0.4131,0.2559,0.5469,0.6807,0.4492v0.876
				c-0.9727,0.1221-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0942,0.8271-1.9575,0.8271
				C932.4116,819.6357,931.4751,818.9551,931.4751,817.8115z M935.4277,816.2676l-1.2407,0.2676
				c-0.9731,0.1943-1.6299,0.4619-1.6299,1.2275c0,0.6201,0.4502,0.9854,1.1436,0.9854c0.936,0,1.7271-0.5713,1.7271-1.3867
				V816.2676z"
          />
          <path
            d="M938.3721,813.166h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V813.166z"
          />
          <path
            d="M908.1738,830.6025v3.1133h-1.0947v-8.5498h1.0947v0.9238c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4961,2.7729,3.3086c0,1.7998-1.0093,3.3076-2.7729,3.3076
				C909.2559,831.6602,908.5508,831.2344,908.1738,830.6025z M908.1494,828.583c0,1.46,0.8999,2.1162,1.7998,2.1162
				c1.1919,0,1.8604-0.9854,1.8604-2.3467c0-1.374-0.6685-2.3477-1.8604-2.3477c-0.8999,0-1.7998,0.6445-1.7998,2.1289V828.583z"
          />
          <path
            d="M913.8667,828.3525c0-1.8975,1.2041-3.3086,2.9312-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5972
				c0.061,1.3379,0.8271,2.1895,1.9336,2.1895c0.8516,0,1.4595-0.4629,1.6543-1.2168l0.9604,0.3408
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C915.083,831.6602,913.8667,830.3105,913.8667,828.3525z M914.9736,827.6836h3.4292
				c-0.0239-0.9004-0.5107-1.7031-1.6172-1.7031C915.8613,825.9805,915.168,826.6006,914.9736,827.6836z"
          />
          <path
            d="M921.0195,829.5566c0.3403,0.7412,1.1187,1.1914,1.9458,1.1914c0.7417,0,1.3984-0.3164,1.3984-1.0098
				c0-0.6924-0.6079-0.79-1.6538-1.0088c-1.0459-0.2314-2.1406-0.4619-2.1406-1.7637c0-1.1309,0.9854-1.9219,2.335-1.9219
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6211c-0.3281-0.6328-0.9243-1.0342-1.7266-1.0342
				c-0.73,0-1.2163,0.3652-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8994c1.0942,0.2559,2.3472,0.4746,2.3472,1.8613
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7974-1.4834L921.0195,829.5566z"
          />
          <path
            d="M926.9561,826.1025h-0.9854v-0.9365h0.9854v-1.8604h1.082v1.8604h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.79,0.8877,0.79c0.2559,0,0.438-0.0117,0.6934-0.0723v0.9365c-0.2798,0.0605-0.5957,0.0967-0.9604,0.0967
				c-1.0825,0-1.7026-0.3643-1.7026-1.5811V826.1025z"
          />
          <path d="M930.873,822.7949h1.1919v1.2646h-1.1919V822.7949z M932.0166,831.5264h-1.0947v-6.3604h1.0947V831.5264z" />
          <path
            d="M936.4072,830.6992c0.875,0,1.3984-0.5596,1.5801-1.3496l0.9736,0.4502c-0.3047,1.0576-1.2285,1.8604-2.5537,1.8604
				c-1.7764,0-3.0044-1.3496-3.0044-3.3076c0-1.9707,1.228-3.3086,3.0044-3.3086c1.3252,0,2.2256,0.7666,2.5293,1.8242
				l-0.9492,0.4746c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9097,0.8994-1.9097,2.3594
				C934.4976,829.7998,935.2754,830.6992,936.4072,830.6992z"
          />
          <path d="M940.1182,822.7949h1.1924v1.2646h-1.1924V822.7949z M941.2617,831.5264h-1.0947v-6.3604h1.0947V831.5264z" />
          <path
            d="M942.6484,828.3525c0-1.8125,1.0088-3.3086,2.7725-3.3086c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2949h1.0947v8.7314
				h-1.0947v-0.9238c-0.377,0.6318-1.082,1.0576-1.9697,1.0576C943.6572,831.6602,942.6484,830.1523,942.6484,828.3525z
				 M945.6152,826.0049c-1.1914,0-1.8604,0.9736-1.8604,2.3477c0,1.3613,0.6689,2.3467,1.8604,2.3467
				c0.9004,0,1.7998-0.6562,1.7998-2.1162v-0.4492C947.415,826.6494,946.5156,826.0049,945.6152,826.0049z"
          />
          <path
            d="M949.8623,828.3525c0-1.8975,1.2041-3.3086,2.9307-3.3086c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1895,1.9336,2.1895c0.8506,0,1.459-0.4629,1.6533-1.2168l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C951.0781,831.6602,949.8623,830.3105,949.8623,828.3525z M950.9688,827.6836h3.4297
				c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C951.8564,825.9805,951.1631,826.6006,950.9688,827.6836z"
          />
          <path
            d="M956.8682,825.166h1.0947v0.8633c0.3643-0.5225,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2871
				v4.1953h-1.0938v-3.9033c0-0.8887-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6074-1.5928,1.666v3.7939h-1.0947V825.166z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="4">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M3256.637,747.7422c0,25.2659-20.481,45.75-45.75,45.75c-25.2659,0-45.75-20.4841-45.75-45.75
			c0-25.2651,20.4841-45.75,45.75-45.75C3236.156,701.9922,3256.637,722.4771,3256.637,747.7422"
        />
        <g>
          <path
            d="M3168.7002,731.0361h3.2227c1.8486,0,2.833,0.8154,2.833,2.2744c0,1.1191-0.6807,1.6787-1.3496,1.9092
				c0.9854,0.2676,1.6416,1.0342,1.6416,2.1406c0,1.5205-1.1064,2.4082-2.8701,2.4082h-3.4775V731.0361z M3171.8252,734.7822
				c1.168,0,1.8125-0.4502,1.8125-1.374c0-0.9365-0.6445-1.3867-1.8125-1.3867h-1.9941v2.7607H3171.8252z M3169.8311,738.7832
				h2.2988c1.167,0,1.7998-0.6084,1.7998-1.5078c0-0.8877-0.6328-1.4961-1.7998-1.4961h-2.2988V738.7832z"
          />
          <path
            d="M3175.501,736.5938c0-1.8965,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1885,1.9336,2.1885c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3176.7168,739.9023,3175.501,738.5518,3175.501,736.5938z M3176.6074,735.9258
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3177.4951,734.2227,3176.8018,734.8428,3176.6074,735.9258z"
          />
          <path
            d="M3182.1904,734.3447h-0.9844v-0.9365h0.9844v-1.8613h1.083v1.8613h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9355c-0.2803,0.0615-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V734.3447z"
          />
          <path
            d="M3188.9658,734.4902c-0.1826-0.0361-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0117,0.4385,0.0244V734.4902z"
          />
          <path
            d="M3192.2021,739.9023c-1.7393,0-2.9551-1.3379-2.9551-3.3086c0-1.9697,1.2158-3.3076,2.9551-3.3076
				s2.9551,1.3379,2.9551,3.3076C3195.1572,738.5645,3193.9414,739.9023,3192.2021,739.9023z M3192.2021,738.9658
				c1.1182,0,1.8486-0.9248,1.8486-2.3721c0-1.459-0.7305-2.3828-1.8486-2.3828c-1.1191,0-1.8486,0.9238-1.8486,2.3828
				C3190.3535,738.041,3191.083,738.9658,3192.2021,738.9658z"
          />
          <path
            d="M3201.1064,739.7686h-1.0947v-0.876c-0.3643,0.5352-0.9844,1.0098-1.8848,1.0098c-1.3623,0-2.1279-0.9365-2.1279-2.2861
				v-4.208h1.0947v3.9766c0,0.8994,0.3887,1.5078,1.3252,1.5078c0.79,0,1.5928-0.5957,1.5928-1.6533v-3.8311h1.0947V739.7686z"
          />
          <path
            d="M3206.3857,733.4082l1.3135,4.7422l1.3262-4.7422h1.0938l-1.9336,6.3604h-0.96l-1.3262-4.7188l-1.3252,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H3206.3857z"
          />
          <path
            d="M3211.8008,738.8438v0.9248h-1.0947v-8.7324h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4961,2.7725,3.3076c0,1.8008-1.0088,3.3086-2.7725,3.3086
				C3212.8828,739.9023,3212.1777,739.4766,3211.8008,738.8438z M3211.7764,736.8252c0,1.459,0.8994,2.1162,1.7998,2.1162
				c1.1914,0,1.8604-0.9854,1.8604-2.3477c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V736.8252z"
          />
          <path
            d="M3216.9463,738.0537c0-1.2646,0.9854-1.7881,2.4082-2.0801l1.5449-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0332,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1006c0,0.4141,0.2559,0.5479,0.6807,0.4502v0.876
				c-0.9727,0.1211-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3217.8828,739.8779,3216.9463,739.1963,3216.9463,738.0537z M3220.8994,736.5088l-1.2412,0.2676
				c-0.9727,0.1953-1.6289,0.4629-1.6289,1.2285c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V736.5088z"
          />
          <path
            d="M3222.8584,738.0537c0-1.2646,0.9854-1.7881,2.4082-2.0801l1.5449-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0332,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1006c0,0.4141,0.2559,0.5479,0.6807,0.4502v0.876
				c-0.9727,0.1211-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3223.7949,739.8779,3222.8584,739.1963,3222.8584,738.0537z M3226.8115,736.5088l-1.2412,0.2676
				c-0.9727,0.1953-1.6289,0.4629-1.6289,1.2285c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V736.5088z"
          />
          <path
            d="M3232.5645,734.4902c-0.1826-0.0361-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0117,0.4385,0.0244V734.4902z"
          />
          <path
            d="M3233.2832,731.0361h1.0947v3.2354c0.3643-0.5234,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1963h-1.0938v-3.9043c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6084-1.5928,1.666v3.7949h-1.0947V731.0361z"
          />
          <path
            d="M3239.2197,736.5938c0-1.8965,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1885,1.9336,2.1885c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3240.4355,739.9023,3239.2197,738.5518,3239.2197,736.5938z M3240.3262,735.9258
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3241.2139,734.2227,3240.5205,734.8428,3240.3262,735.9258z"
          />
          <path d="M3245.6914,731.0361h1.1924v1.2656h-1.1924V731.0361z M3246.835,739.7686h-1.0947v-6.3604h1.0947V739.7686z" />
          <path
            d="M3247.7344,736.5938c0-1.8115,1.0088-3.3076,2.7725-3.3076c0.8877,0,1.5928,0.4258,1.9697,1.0459v-3.2959h1.0947v8.7324
				h-1.0947v-0.9248c-0.377,0.6328-1.082,1.0586-1.9697,1.0586C3248.7432,739.9023,3247.7344,738.3945,3247.7344,736.5938z
				 M3250.7012,734.2471c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3477,1.8604,2.3477
				c0.9004,0,1.7998-0.6572,1.7998-2.1162v-0.4502C3252.501,734.8916,3251.6016,734.2471,3250.7012,734.2471z"
          />
          <path d="M3165.7197,751.7686l-2.3838-6.3604h1.1553l1.7881,5.0225l1.7627-5.0225h1.1436l-2.3838,6.3604H3165.7197z" />
          <path
            d="M3169.2715,750.0537c0-1.2646,0.9854-1.7881,2.4082-2.0801l1.5449-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0332,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1006c0,0.4141,0.2559,0.5479,0.6807,0.4502v0.876
				c-0.9727,0.1211-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3170.208,751.8779,3169.2715,751.1963,3169.2715,750.0537z M3173.2246,748.5088l-1.2412,0.2676
				c-0.9727,0.1953-1.6289,0.4629-1.6289,1.2285c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V748.5088z"
          />
          <path
            d="M3175.6816,745.4082h1.0947v0.8633c0.3643-0.5234,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1963h-1.0938v-3.9043c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6084-1.5928,1.666v3.7949h-1.0947V745.4082z"
          />
          <path
            d="M3185.2305,748.375l2.8213-2.9668h1.3623l-2.4814,2.5049l2.6875,3.8555h-1.2764l-2.1895-3.0645l-0.9238,0.9355v2.1289
				h-1.0947v-8.7324h1.0947V748.375z"
          />
          <path
            d="M3194.0977,745.4082l1.3135,4.7422l1.3262-4.7422h1.0938l-1.9336,6.3604h-0.96l-1.3262-4.7188l-1.3252,4.7188h-0.9609
				l-1.9336-6.3604h1.1309l1.3379,4.7305l1.3135-4.7305H3194.0977z"
          />
          <path
            d="M3197.918,750.0537c0-1.2646,0.9854-1.7881,2.4082-2.0801l1.5449-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0332,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1006c0,0.4141,0.2559,0.5479,0.6807,0.4502v0.876
				c-0.9727,0.1211-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3198.8545,751.8779,3197.918,751.1963,3197.918,750.0537z M3201.8711,748.5088l-1.2412,0.2676
				c-0.9727,0.1953-1.6289,0.4629-1.6289,1.2285c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V748.5088z"
          />
          <path d="M3205.4238,751.7686h-1.0947v-8.7324h1.0947V751.7686z" />
          <path d="M3206.7129,743.0361h1.1924v1.2656h-1.1924V743.0361z M3207.8564,751.7686h-1.0947v-6.3604h1.0947V751.7686z" />
          <path
            d="M3209.3643,746.3447h-0.9844v-0.9365h0.9844v-1.8613h1.083v1.8613h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9355c-0.2803,0.0615-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V746.3447z"
          />
          <path
            d="M3212.4053,748.5938c0-1.8965,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1885,1.9336,2.1885c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3213.6211,751.9023,3212.4053,750.5518,3212.4053,748.5938z M3213.5117,747.9258
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3214.3994,746.2227,3213.7061,746.8428,3213.5117,747.9258z"
          />
          <path d="M3218.877,743.0361h1.1924v1.2656h-1.1924V743.0361z M3220.0205,751.7686h-1.0947v-6.3604h1.0947V751.7686z" />
          <path
            d="M3221.5283,746.3447h-0.9844v-0.9365h0.9844v-1.8613h1.083v1.8613h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9355c-0.2803,0.0615-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V746.3447z"
          />
          <path
            d="M3225.1533,749.7979c0.3408,0.7422,1.1191,1.1924,1.9463,1.1924c0.7422,0,1.3984-0.3164,1.3984-1.0098
				s-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4629-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6318-0.9238-1.0332-1.7266-1.0332
				c-0.7295,0-1.2158,0.3643-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4844L3225.1533,749.7979z"
          />
          <path d="M3231.5273,751.7686h-1.0947v-8.7324h1.0947V751.7686z" />
          <path
            d="M3232.3662,750.0537c0-1.2646,0.9854-1.7881,2.4082-2.0801l1.5449-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0332,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1006c0,0.4141,0.2559,0.5479,0.6807,0.4502v0.876
				c-0.9727,0.1211-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3233.3027,751.8779,3232.3662,751.1963,3232.3662,750.0537z M3236.3193,748.5088l-1.2412,0.2676
				c-0.9727,0.1953-1.6289,0.4629-1.6289,1.2285c0,0.6201,0.4492,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V748.5088z"
          />
          <path
            d="M3239.8721,750.8438v0.9248h-1.0947v-8.7324h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7725,1.4961,2.7725,3.3076c0,1.8008-1.0088,3.3086-2.7725,3.3086
				C3240.9541,751.9023,3240.249,751.4766,3239.8721,750.8438z M3239.8477,748.8252c0,1.459,0.8994,2.1162,1.7998,2.1162
				c1.1914,0,1.8604-0.9854,1.8604-2.3477c0-1.374-0.6689-2.3467-1.8604-2.3467c-0.9004,0-1.7998,0.6445-1.7998,2.1279V748.8252z"
          />
          <path
            d="M3245.0781,748.5938c0-1.8965,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1885,1.9336,2.1885c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3246.2939,751.9023,3245.0781,750.5518,3245.0781,748.5938z M3246.1846,747.9258
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3247.0723,746.2227,3246.3789,746.8428,3246.1846,747.9258z"
          />
          <path d="M3252.6934,751.7686h-1.0947v-8.7324h1.0947V751.7686z" />
          <path
            d="M3254.1768,749.7979c0.3408,0.7422,1.1191,1.1924,1.9463,1.1924c0.7422,0,1.3984-0.3164,1.3984-1.0098
				s-0.6084-0.79-1.6543-1.0088c-1.0459-0.2314-2.1396-0.4629-2.1396-1.7637c0-1.1309,0.9844-1.9219,2.335-1.9219
				c1.1426,0,2.0791,0.5596,2.4561,1.3135l-0.7783,0.6201c-0.3281-0.6318-0.9238-1.0332-1.7266-1.0332
				c-0.7295,0-1.2158,0.3643-1.2158,0.9365c0,0.5957,0.5586,0.7051,1.4463,0.8994c1.0947,0.2559,2.3477,0.4746,2.3477,1.8613
				c0,1.2158-1.1064,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.499-2.7969-1.4844L3254.1768,749.7979z"
          />
          <path
            d="M3184.8428,760.5938c0-1.8965,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1885,1.9336,2.1885c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3186.0586,763.9023,3184.8428,762.5518,3184.8428,760.5938z M3185.9492,759.9258
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3186.8369,758.2227,3186.1436,758.8428,3185.9492,759.9258z"
          />
          <path
            d="M3191.3633,757.4082h1.0947v0.8633c0.3643-0.5234,0.9971-0.9854,1.8965-0.9854c1.3506,0,2.1162,0.9365,2.1162,2.2861
				v4.1963h-1.0938v-3.9043c0-0.8877-0.3779-1.5566-1.3262-1.5566c-0.79,0-1.5928,0.6084-1.5928,1.666v3.7949h-1.0947V757.4082z"
          />
          <path d="M3199.5254,759.7305h3.3203v0.9854h-3.3203V759.7305z" />
          <path
            d="M3206.459,762.9414c0.875,0,1.3984-0.5596,1.5801-1.3496l0.9736,0.4492c-0.3047,1.0586-1.2285,1.8613-2.5537,1.8613
				c-1.7764,0-3.0039-1.3506-3.0039-3.3086c0-1.9697,1.2275-3.3076,3.0039-3.3076c1.3252,0,2.2256,0.7666,2.5293,1.8242
				l-0.9492,0.4746c-0.1816-0.7783-0.7051-1.3496-1.5801-1.3496c-1.1318,0-1.9102,0.8994-1.9102,2.3584
				C3204.5488,762.041,3205.3271,762.9414,3206.459,762.9414z"
          />
          <path
            d="M3213.0273,758.4902c-0.1826-0.0361-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0117,0.4385,0.0244V758.4902z"
          />
          <path d="M3213.6963,755.0361h1.1924v1.2656h-1.1924V755.0361z M3214.8398,763.7686h-1.0947v-6.3604h1.0947V763.7686z" />
          <path
            d="M3216.3477,758.3447h-0.9844v-0.9365h0.9844v-1.8613h1.083v1.8613h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3281,0.79,0.8877,0.79c0.2549,0,0.4375-0.0117,0.6934-0.0723v0.9355c-0.2803,0.0615-0.5967,0.0977-0.9609,0.0977
				c-1.083,0-1.7031-0.3652-1.7031-1.5811V758.3447z"
          />
          <path
            d="M3219.3896,760.5938c0-1.8965,1.2041-3.3076,2.9307-3.3076c1.7754,0,2.7119,1.3506,2.7119,3.1133v0.3652h-4.5967
				c0.0605,1.3379,0.8262,2.1885,1.9336,2.1885c0.8506,0,1.459-0.4619,1.6533-1.2158l0.9609,0.3408
				c-0.3887,1.1309-1.3496,1.8242-2.6143,1.8242C3220.6055,763.9023,3219.3896,762.5518,3219.3896,760.5938z M3220.4961,759.9258
				h3.4297c-0.0244-0.9004-0.5107-1.7031-1.6182-1.7031C3221.3838,758.2227,3220.6904,758.8428,3220.4961,759.9258z"
          />
          <path
            d="M3229.2061,758.4902c-0.1826-0.0361-0.3281-0.0488-0.5352-0.0488c-0.9248,0-1.666,0.7295-1.666,1.8486v3.4785h-1.0947
				v-6.3604h1.0947v1.082c0.2793-0.6445,0.8994-1.1309,1.7627-1.1309c0.1709,0,0.3291,0.0117,0.4385,0.0244V758.4902z"
          />
          <path d="M3229.875,755.0361h1.1924v1.2656h-1.1924V755.0361z M3231.0186,763.7686h-1.0947v-6.3604h1.0947V763.7686z" />
          <path
            d="M3231.8574,762.0537c0-1.2646,0.9854-1.7881,2.4082-2.0801l1.5449-0.3154v-0.2676c0-0.7305-0.4014-1.1436-1.2529-1.1436
				c-0.8027,0-1.2773,0.377-1.4717,1.082l-1.0332-0.2676c0.3037-1.0332,1.2402-1.7754,2.541-1.7754
				c1.4229,0,2.2988,0.6934,2.2988,2.0557v3.1006c0,0.4141,0.2559,0.5479,0.6807,0.4502v0.876
				c-0.9727,0.1211-1.5322-0.1094-1.666-0.7178c-0.3887,0.499-1.0938,0.8271-1.958,0.8271
				C3232.7939,763.8779,3231.8574,763.1963,3231.8574,762.0537z M3235.8105,760.5088l-1.2412,0.2676
				c-0.9727,0.1953-1.6289,0.4629-1.6289,1.2285c0,0.6201,0.4502,0.9854,1.1426,0.9854c0.9365,0,1.7275-0.5723,1.7275-1.3867
				V760.5088z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="3">
        <path
          style={{ fill: '#FFAF8A' }}
          className="node"
          d="M2025.3199,1435.285c0,25.266-20.48,45.75-45.75,45.75c-25.2649,0-45.75-20.484-45.75-45.75
			c0-25.269,20.4851-45.75,45.75-45.75C2004.84,1389.535,2025.3199,1410.016,2025.3199,1435.285"
        />
        <g>
          <path d="M1952.0034,1412.5796v1.0215h-4.5483v2.688h3.855v1.0215h-3.855v2.9795h4.5483v1.0215h-5.7036v-8.7319H1952.0034z" />
          <path
            d="M1955.5679,1420.4844c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3037,1.0581-1.228,1.8608-2.5537,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1953.6587,1419.5845,1954.437,1420.4844,1955.5679,1420.4844z"
          />
          <path
            d="M1961.3589,1421.4453c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1964.314,1420.1074,1963.0977,1421.4453,1961.3589,1421.4453z M1961.3589,1420.5088
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1959.5103,1419.5845,1960.2397,1420.5088,1961.3589,1420.5088z"
          />
          <path
            d="M1965.2153,1414.9512h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1414.9512z"
          />
          <path
            d="M1974.1074,1421.4453c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1977.0625,1420.1074,1975.8462,1421.4453,1974.1074,1421.4453z M1974.1074,1420.5088
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1972.2588,1419.5845,1972.9883,1420.5088,1974.1074,1420.5088z"
          />
          <path
            d="M1979.0586,1421.3115h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1421.3115z"
          />
          <path d="M1988.0239,1412.5796h1.1919v1.2651h-1.1919V1412.5796z M1989.1675,1421.3115h-1.0947v-6.3604h1.0947V1421.3115z" />
          <path
            d="M1990.6514,1419.3413c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1990.6514,1419.3413z"
          />
          <path
            d="M1998.4971,1420.4844c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9731,0.4497c-0.3042,1.0581-1.2285,1.8608-2.5542,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C1996.5879,1419.5845,1997.3662,1420.4844,1998.4971,1420.4844z"
          />
          <path
            d="M2001.77,1412.5796h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1412.5796z"
          />
          <path
            d="M2007.7065,1418.1372c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2008.9229,1421.4453,2007.7065,1420.0952,2007.7065,1418.1372z
				 M2008.8135,1417.4688h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C2009.7012,1415.7661,2009.0078,1416.3862,2008.8135,1417.4688z"
          />
          <path d="M1935.5767,1433.3115h-1.0947v-8.7319h1.0947V1433.3115z" />
          <path
            d="M1936.4766,1430.1372c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1937.6929,1433.4453,1936.4766,1432.0952,1936.4766,1430.1372z
				 M1937.5835,1429.4688h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1938.4712,1427.7661,1937.7778,1428.3862,1937.5835,1429.4688z"
          />
          <path d="M1944.6265,1433.3115l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1944.6265z" />
          <path
            d="M1948.2397,1430.1372c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1949.4561,1433.4453,1948.2397,1432.0952,1948.2397,1430.1372z
				 M1949.3467,1429.4688h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C1950.2344,1427.7661,1949.541,1428.3862,1949.3467,1429.4688z"
          />
          <path
            d="M1954.7603,1426.9512h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1426.9512z"
          />
          <path
            d="M1961.2803,1431.3413c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1431,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9238-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1961.2803,1431.3413z"
          />
          <path d="M1968.1895,1433.3115l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1968.1895z" />
          <path
            d="M1971.7422,1431.5967c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1972.6787,1433.4209,1971.7422,1432.7397,1971.7422,1431.5967z M1975.6943,1430.0522l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1430.0522z"
          />
          <path
            d="M1978.3232,1427.8877h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0728v0.936c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V1427.8877z"
          />
          <path
            d="M1982.8965,1432.3872v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C1983.9785,1433.4453,1983.2734,1433.0195,1982.8965,1432.3872z M1982.8721,1430.3687c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8608-0.9849,1.8608-2.3472c0-1.374-0.6689-2.3467-1.8608-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1430.3687z"
          />
          <path
            d="M1988.0425,1431.5967c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1988.979,1433.4209,1988.0425,1432.7397,1988.0425,1431.5967z M1991.9946,1430.0522l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1430.0522z"
          />
          <path
            d="M1993.9541,1431.5967c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1994.8906,1433.4209,1993.9541,1432.7397,1993.9541,1431.5967z M1997.9062,1430.0522l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1430.0522z"
          />
          <path
            d="M2003.6606,1428.0337c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1428.0337z"
          />
          <path
            d="M2004.3789,1424.5796h1.0947v3.2349c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1424.5796z"
          />
          <path
            d="M2010.3149,1430.1372c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2011.5312,1433.4453,2010.3149,1432.0952,2010.3149,1430.1372z
				 M2011.4219,1429.4688h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026
				C2012.3096,1427.7661,2011.6162,1428.3862,2011.4219,1429.4688z"
          />
          <path d="M2016.7866,1424.5796h1.1919v1.2651h-1.1919V1424.5796z M2017.9302,1433.3115h-1.0947v-6.3604h1.0947V1433.3115z" />
          <path
            d="M2018.8301,1430.1372c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2019.8398,1433.4453,2018.8301,1431.9375,2018.8301,1430.1372z
				 M2021.7974,1427.7905c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2023.5977,1428.4351,2022.6978,1427.7905,2021.7974,1427.7905z"
          />
          <path d="M1946.2808,1445.3115l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1946.2808z" />
          <path
            d="M1949.8335,1443.5967c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1950.77,1445.4209,1949.8335,1444.7397,1949.8335,1443.5967z M1953.7856,1442.0522l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1442.0522z"
          />
          <path
            d="M1956.2441,1438.9512h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1438.9512z"
          />
          <path
            d="M1964.2607,1442.1372c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1965.2705,1445.4453,1964.2607,1443.9375,1964.2607,1442.1372z
				 M1967.228,1439.7905c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1969.0283,1440.4351,1968.1284,1439.7905,1967.228,1439.7905z"
          />
          <path
            d="M1976.4722,1445.3115h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093
				c-1.3618,0-2.1284-0.9365-2.1284-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083
				c0.7905,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V1445.3115z"
          />
          <path
            d="M1982.8467,1445.3115h-1.0942v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8853,1.0093
				c-1.3618,0-2.1284-0.9365-2.1284-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083
				c0.7905,0,1.5933-0.5962,1.5933-1.6538v-3.8311h1.0942V1445.3115z"
          />
          <path
            d="M1987.4697,1440.0337c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V1440.0337z"
          />
          <path d="M1992.7856,1445.3115h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8394l-3.5996,4.5845h3.6362V1445.3115z" />
          <path
            d="M1993.2246,1443.5967c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1994.1611,1445.4209,1993.2246,1444.7397,1993.2246,1443.5967z M1997.1768,1442.0522l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1442.0522z"
          />
          <path
            d="M2000.73,1445.3115h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V1445.3115z"
          />
          <path
            d="M2009.3057,1442.1372c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2010.522,1445.4453,2009.3057,1444.0952,2009.3057,1442.1372z M2010.4126,1441.4688
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2011.3003,1439.7661,2010.6069,1440.3862,2010.4126,1441.4688z"
          />
          <path d="M1956.7305,1457.3115h-1.0947v-8.7319h1.0947V1457.3115z" />
          <path
            d="M1957.5698,1455.5967c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1958.5063,1457.4209,1957.5698,1456.7397,1957.5698,1455.5967z M1961.522,1454.0522l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V1454.0522z"
          />
          <path
            d="M1963.9805,1450.9512h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V1450.9512z"
          />
          <path
            d="M1969.9165,1454.1372c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1970.9263,1457.4453,1969.9165,1455.9375,1969.9165,1454.1372z
				 M1972.8838,1451.7905c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1974.6841,1452.4351,1973.7842,1451.7905,1972.8838,1451.7905z"
          />
          <path
            d="M1978.1763,1456.3872v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C1979.2583,1457.4453,1978.5532,1457.0195,1978.1763,1456.3872z M1978.1519,1454.3687c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V1454.3687z"
          />
          <path
            d="M1986.3379,1457.4453c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1989.293,1456.1074,1988.0767,1457.4453,1986.3379,1457.4453z M1986.3379,1456.5088
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1984.4893,1455.5845,1985.2188,1456.5088,1986.3379,1456.5088z"
          />
          <path
            d="M1995.2417,1457.3115h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093
				c-1.3618,0-2.1284-0.9365-2.1284-2.2861v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083
				c0.7905,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V1457.3115z"
          />
          <path
            d="M2000.521,1450.9512l1.3135,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H2000.521z"
          />
        </g>
      </g>
      <g onClick={handleClick} id="2">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M2080.1411,330.906c0,25.2661-20.4812,45.75-45.7501,45.75c-25.266,0-45.75-20.4839-45.75-45.75
			c0-25.2688,20.484-45.75,45.75-45.75C2059.6599,285.156,2080.1411,305.6372,2080.1411,330.906"
        />
        <g>
          <path
            d="M2003.1997,298.0322c1.1313,0,1.9458-0.6567,2.2622-1.7632l1.1064,0.3647c-0.5107,1.5078-1.7026,2.4321-3.3687,2.4321
				c-2.2617,0-3.9277-1.8364-3.9277-4.4995c0-2.6636,1.666-4.5,3.9277-4.5c1.666,0,2.8579,0.9243,3.3687,2.4326l-1.1064,0.3647
				c-0.3164-1.1069-1.1309-1.7637-2.2622-1.7637c-1.6172,0-2.7603,1.3989-2.7603,3.4663S2001.5825,298.0322,2003.1997,298.0322z"
          />
          <path
            d="M2010.2681,299.0659c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C2013.2231,297.728,2012.0068,299.0659,2010.2681,299.0659z M2010.2681,298.1294
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2008.4194,297.2051,2009.1489,298.1294,2010.2681,298.1294z"
          />
          <path
            d="M2014.6113,292.5718h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V292.5718z"
          />
          <path
            d="M2024.0381,298.105c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3037,1.0581-1.228,1.8608-2.5537,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C2022.1289,297.2051,2022.9072,298.105,2024.0381,298.105z"
          />
          <path
            d="M2032.8447,298.9321h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V298.9321z"
          />
          <path
            d="M2037.9546,293.6543c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V293.6543z"
          />
          <path
            d="M2042.4556,293.6543c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V293.6543z"
          />
          <path
            d="M2043.2227,295.7578c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2044.439,299.0659,2043.2227,297.7158,2043.2227,295.7578z M2044.3296,295.0894
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2045.2173,293.3867,2044.5239,294.0068,2044.3296,295.0894z"
          />
          <path
            d="M2050.2295,292.5718h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V292.5718z"
          />
          <path
            d="M2056.6523,295.7578c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2057.6621,299.0659,2056.6523,297.5581,2056.6523,295.7578z
				 M2059.6196,293.4111c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2061.4199,294.0557,2060.52,293.4111,2059.6196,293.4111z"
          />
          <path
            d="M2063.8657,295.7578c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2065.082,299.0659,2063.8657,297.7158,2063.8657,295.7578z M2064.9727,295.0894
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2065.8604,293.3867,2065.167,294.0068,2064.9727,295.0894z"
          />
          <path
            d="M2006.8008,304.5718l1.3135,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H2006.8008z"
          />
          <path
            d="M2011.1689,307.7578c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2012.3853,311.0659,2011.1689,309.7158,2011.1689,307.7578z M2012.2759,307.0894
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2013.1636,305.3867,2012.4702,306.0068,2012.2759,307.0894z"
          />
          <path
            d="M2021.4717,305.6543c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V305.6543z"
          />
          <path
            d="M2022.2388,307.7578c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2023.4551,311.0659,2022.2388,309.7158,2022.2388,307.7578z M2023.3457,307.0894
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2024.2334,305.3867,2023.54,306.0068,2023.3457,307.0894z"
          />
          <path d="M2030.3403,310.9321h-1.0947v-8.7319h1.0947V310.9321z" />
          <path
            d="M2031.7271,307.7578c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2032.7368,311.0659,2031.7271,309.5581,2031.7271,307.7578z
				 M2034.6943,305.4111c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2036.4946,306.0557,2035.5947,305.4111,2034.6943,305.4111z"
          />
          <path
            d="M2043.3311,304.5718l1.313,4.7427l1.3257-4.7427h1.0947l-1.9336,6.3604h-0.9609l-1.3257-4.7188l-1.3257,4.7188h-0.9604
				l-1.9336-6.3604h1.1309l1.3379,4.731l1.3135-4.731H2043.3311z"
          />
          <path d="M2048.0879,302.2002h1.1919v1.2651h-1.1919V302.2002z M2049.2314,310.9321h-1.0947v-6.3604h1.0947V310.9321z" />
          <path
            d="M2051.0562,304.5718h1.0947v6.8833c0,1.1309-0.7056,1.7026-1.7881,1.7026c-0.2554,0-0.4136-0.0122-0.6079-0.0366v-0.9487
				c0.1338,0.0244,0.2432,0.0366,0.4258,0.0366c0.6323,0,0.8755-0.3042,0.8755-0.9487V304.5718z M2051.0073,302.2002h1.1919v1.2651
				h-1.1919V302.2002z"
          />
          <path
            d="M2053.5376,307.7578c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2054.5474,311.0659,2053.5376,309.5581,2053.5376,307.7578z
				 M2056.5049,305.4111c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2058.3052,306.0557,2057.4053,305.4111,2056.5049,305.4111z"
          />
          <path
            d="M2060.751,307.7578c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2061.9673,311.0659,2060.751,309.7158,2060.751,307.7578z M2061.8579,307.0894
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2062.7456,305.3867,2062.0522,306.0068,2061.8579,307.0894z"
          />
          <path
            d="M1997.8003,321.2173c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1998.7368,323.0415,1997.8003,322.3604,1997.8003,321.2173z M2001.7524,319.6729l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V319.6729z"
          />
          <path
            d="M2004.1987,321.2173c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C2005.1353,323.0415,2004.1987,322.3604,2004.1987,321.2173z M2008.1509,319.6729l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V319.6729z"
          />
          <path
            d="M2011.0957,316.5718h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V316.5718z"
          />
          <path
            d="M2019.0513,322.0078v0.9243h-1.0947v-8.7319h1.0947v3.2959c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C2020.1333,323.0659,2019.4282,322.6401,2019.0513,322.0078z M2019.0269,319.9893c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8608-0.9849,1.8608-2.3472c0-1.374-0.6689-2.3467-1.8608-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V319.9893z"
          />
          <path d="M2025.1333,314.2002h1.1919v1.2651h-1.1919V314.2002z M2026.2769,322.9321h-1.0947v-6.3604h1.0947V322.9321z" />
          <path
            d="M2027.6636,319.7578c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2028.8799,323.0659,2027.6636,321.7158,2027.6636,319.7578z M2028.7705,319.0894
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2029.6582,317.3867,2028.9648,318.0068,2028.7705,319.0894z"
          />
          <path
            d="M2034.2324,319.7578c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2035.2422,323.0659,2034.2324,321.5581,2034.2324,319.7578z
				 M2037.1997,317.4111c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2039,318.0557,2038.1001,317.4111,2037.1997,317.4111z"
          />
          <path d="M2041.835,314.2002h1.1919v1.2651h-1.1919V314.2002z M2042.9785,322.9321h-1.0947v-6.3604h1.0947V322.9321z" />
          <path
            d="M2044.8032,316.5718h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3496,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V316.5718z"
          />
          <path
            d="M2054.0962,325.2549c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3408,0.6201-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C2057.0513,324.2212,2055.8232,325.2549,2054.0962,325.2549z M2054.2056,317.4111
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C2055.9692,318.0918,2055.1177,317.4111,2054.2056,317.4111z"
          />
          <path
            d="M2058.4395,319.7578c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2059.6558,323.0659,2058.4395,321.7158,2058.4395,319.7578z M2059.5464,319.0894
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2060.4341,317.3867,2059.7407,318.0068,2059.5464,319.0894z"
          />
          <path
            d="M2065.4463,316.5718h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V316.5718z"
          />
          <path d="M2006.6064,334.9321l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H2006.6064z" />
          <path
            d="M2010.646,333.2173c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C2011.5825,335.0415,2010.646,334.3604,2010.646,333.2173z M2014.5981,331.6729l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V331.6729z"
          />
          <path
            d="M2017.543,328.5718h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V328.5718z"
          />
          <path
            d="M2028.0654,334.9321h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V334.9321z"
          />
          <path d="M2037.5171,326.2002h1.1919v1.2651h-1.1919V326.2002z M2038.6606,334.9321h-1.0947v-6.3604h1.0947V334.9321z" />
          <path
            d="M2040.4854,328.5718h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V328.5718z"
          />
          <path
            d="M2046.9082,331.7578c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2047.918,335.0659,2046.9082,333.5581,2046.9082,331.7578z
				 M2049.8755,329.4111c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2051.6758,330.0557,2050.7759,329.4111,2049.8755,329.4111z"
          />
          <path
            d="M2054.1216,331.7578c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2055.3379,335.0659,2054.1216,333.7158,2054.1216,331.7578z M2055.2285,331.0894
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2056.1162,329.3867,2055.4229,330.0068,2055.2285,331.0894z"
          />
          <path
            d="M2064.4243,329.6543c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V329.6543z"
          />
          <path
            d="M2007.4404,343.7578c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2008.4502,347.0659,2007.4404,345.5581,2007.4404,343.7578z
				 M2010.4077,341.4111c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2012.208,342.0557,2011.3081,341.4111,2010.4077,341.4111z"
          />
          <path
            d="M2020.1387,346.9321h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V346.9321z"
          />
          <path
            d="M2026.9995,346.9321h-1.0942v-0.8755c-0.3652,0.5352-0.9854,1.0093-1.8853,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5933-0.5962,1.5933-1.6538v-3.8311h1.0942V346.9321z"
          />
          <path
            d="M2032.1094,341.6543c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V341.6543z"
          />
          <path d="M2037.9116,346.9321h-5.0107v-0.8271l3.6729-4.5967h-3.624v-0.9365h4.9253v0.8394l-3.5996,4.5845h3.6362V346.9321z" />
          <path
            d="M2038.8374,345.2173c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C2039.7739,347.0415,2038.8374,346.3604,2038.8374,345.2173z M2042.7896,343.6729l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V343.6729z"
          />
          <path
            d="M2045.2358,345.2173c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C2046.1724,347.0415,2045.2358,346.3604,2045.2358,345.2173z M2049.188,343.6729l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4624-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V343.6729z"
          />
          <path
            d="M2053.2275,346.9321h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066H2056v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V346.9321z"
          />
          <path
            d="M1998.9243,361.2549c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3408,0.6201-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C2001.8794,360.2212,2000.6514,361.2549,1998.9243,361.2549z M1999.0337,353.4111
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C2000.7974,354.0918,1999.9458,353.4111,1999.0337,353.4111z"
          />
          <path
            d="M2003.2676,355.7578c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2004.4839,359.0659,2003.2676,357.7158,2003.2676,355.7578z M2004.3745,355.0894
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2005.2622,353.3867,2004.5688,354.0068,2004.3745,355.0894z"
          />
          <path
            d="M2011.3691,358.0078v3.1133h-1.0947v-8.5493h1.0947v0.9243c0.377-0.6201,1.082-1.0459,1.9697-1.0459
				c1.7637,0,2.7729,1.4956,2.7729,3.3076c0,1.8003-1.0093,3.3081-2.7729,3.3081
				C2012.4512,359.0659,2011.7461,358.6401,2011.3691,358.0078z M2011.3447,355.9893c0,1.459,0.8999,2.1157,1.7998,2.1157
				c1.1919,0,1.8604-0.9849,1.8604-2.3472c0-1.374-0.6685-2.3467-1.8604-2.3467c-0.8999,0-1.7998,0.6445-1.7998,2.1279V355.9893z"
          />
          <path
            d="M2020.7959,353.6543c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478H2017.5
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V353.6543z"
          />
          <path
            d="M2024.5186,359.0659c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C2027.4736,357.728,2026.2573,359.0659,2024.5186,359.0659z M2024.5186,358.1294
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2022.6699,357.2051,2023.3994,358.1294,2024.5186,358.1294z"
          />
          <path
            d="M2028.4238,355.7578c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2029.4336,359.0659,2028.4238,357.5581,2028.4238,355.7578z
				 M2031.3911,353.4111c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2033.1914,354.0557,2032.2915,353.4111,2031.3911,353.4111z"
          />
          <path
            d="M2041.1221,358.9321h-1.0947v-0.8755c-0.3647,0.5352-0.9849,1.0093-1.8848,1.0093c-1.3618,0-2.1284-0.9365-2.1284-2.2861
				v-4.208h1.0947v3.9766c0,0.8999,0.3892,1.5083,1.3257,1.5083c0.7905,0,1.5928-0.5962,1.5928-1.6538v-3.8311h1.0947V358.9321z"
          />
          <path
            d="M2045.502,358.105c0.8755,0,1.3984-0.5591,1.5811-1.3496l0.9727,0.4497c-0.3037,1.0581-1.228,1.8608-2.5537,1.8608
				c-1.7754,0-3.0039-1.3501-3.0039-3.3081c0-1.9697,1.2285-3.3076,3.0039-3.3076c1.3257,0,2.2256,0.7661,2.5298,1.8242
				l-0.9487,0.4741c-0.1826-0.7783-0.7056-1.3496-1.5811-1.3496c-1.1309,0-1.9092,0.8999-1.9092,2.3589
				C2043.5928,357.2051,2044.3711,358.105,2045.502,358.105z"
          />
          <path
            d="M2048.8237,355.7578c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2050.04,359.0659,2048.8237,357.7158,2048.8237,355.7578z M2049.9307,355.0894
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2050.8184,353.3867,2050.125,354.0068,2049.9307,355.0894z"
          />
          <path
            d="M2055.3926,355.7578c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2056.6089,359.0659,2055.3926,357.7158,2055.3926,355.7578z M2056.4995,355.0894
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2057.3872,353.3867,2056.6938,354.0068,2056.4995,355.0894z"
          />
          <path
            d="M2065.6953,353.6543c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V353.6543z"
          />
          <path
            d="M2066.4624,355.7578c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2067.4722,359.0659,2066.4624,357.5581,2066.4624,355.7578z
				 M2069.4297,353.4111c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2071.23,354.0557,2070.3301,353.4111,2069.4297,353.4111z"
          />
          <path d="M2015.8271,370.9321l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H2015.8271z" />
          <path
            d="M2022.8828,371.0659c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C2025.8379,369.728,2024.6216,371.0659,2022.8828,371.0659z M2022.8828,370.1294
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C2021.0342,369.2051,2021.7637,370.1294,2022.8828,370.1294z"
          />
          <path
            d="M2026.7881,367.7578c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2028.0044,371.0659,2026.7881,369.7158,2026.7881,367.7578z M2027.895,367.0894
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2028.7827,365.3867,2028.0894,366.0068,2027.895,367.0894z"
          />
          <path
            d="M2033.3569,367.7578c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C2034.3667,371.0659,2033.3569,369.5581,2033.3569,367.7578z
				 M2036.3242,365.4111c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C2038.1245,366.0557,2037.2246,365.4111,2036.3242,365.4111z"
          />
          <path
            d="M2041.1543,368.9619c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9243-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L2041.1543,368.9619z"
          />
          <path
            d="M2046.4824,367.7578c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C2047.6987,371.0659,2046.4824,369.7158,2046.4824,367.7578z M2047.5894,367.0894
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C2048.4771,365.3867,2047.7837,366.0068,2047.5894,367.0894z"
          />
          <path d="M2054.584,370.9321h-1.0947v-8.7319h1.0947V370.9321z" />
        </g>
      </g>
      <g onClick={handleClick} id="1">
        <path
          style={{ fill: '#E5ECED' }}
          className="node"
          d="M1435.777,507.551c0,25.2651-20.48,45.75-45.75,45.75c-25.265,0-45.75-20.4849-45.75-45.75
			c0-25.27,20.485-45.75,45.75-45.75C1415.297,461.801,1435.777,482.281,1435.777,507.551"
        />
        <g>
          <path d="M1354.1045,496.98h-1.1675v-8.7319h1.1675V496.98z" />
          <path
            d="M1355.978,490.6196h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V490.6196z"
          />
          <path
            d="M1363.0093,491.5562h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V491.5562z"
          />
          <path
            d="M1366.5366,493.8057c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1367.7529,497.1138,1366.5366,495.7637,1366.5366,493.8057z M1367.6436,493.1372
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1368.5312,491.4346,1367.8379,492.0547,1367.6436,493.1372z"
          />
          <path
            d="M1373.5435,490.6196h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V490.6196z"
          />
          <path
            d="M1380.5503,495.0098c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9243-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1380.5503,495.0098z"
          />
          <path d="M1386.2676,488.248h1.1919v1.2651h-1.1919V488.248z M1387.4111,496.98h-1.0947v-6.3604h1.0947V496.98z" />
          <path
            d="M1389.4062,491.5562h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V491.5562z"
          />
          <path
            d="M1392.9336,493.8057c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1394.1499,497.1138,1392.9336,495.7637,1392.9336,493.8057z M1394.0405,493.1372
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1394.9282,491.4346,1394.2349,492.0547,1394.0405,493.1372z"
          />
          <path d="M1399.8921,488.248h1.1919v1.2651h-1.1919V488.248z M1401.0356,496.98h-1.0947v-6.3604h1.0947V496.98z" />
          <path
            d="M1403.0303,491.5562h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2554,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V491.5562z"
          />
          <path d="M1411.1924,496.98l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7632-5.0225h1.1436l-2.3838,6.3604H1411.1924z" />
          <path
            d="M1415.2314,495.2651c0-1.2646,0.9849-1.7876,2.4077-2.0796l1.5444-0.3159v-0.2676c0-0.73-0.4014-1.1431-1.2524-1.1431
				c-0.8027,0-1.2769,0.377-1.4717,1.082l-1.0337-0.2676c0.3042-1.0337,1.2407-1.7754,2.542-1.7754
				c1.4229,0,2.2983,0.6934,2.2983,2.0552v3.1011c0,0.4136,0.2554,0.5474,0.6812,0.4502v0.8755
				c-0.9731,0.1216-1.5322-0.1094-1.666-0.7173c-0.3892,0.4985-1.0947,0.8267-1.958,0.8267
				C1416.168,497.0894,1415.2314,496.4082,1415.2314,495.2651z M1419.1836,493.7207l-1.2402,0.2676
				c-0.9731,0.1948-1.6299,0.4619-1.6299,1.2285c0,0.6201,0.4502,0.9849,1.1436,0.9849c0.936,0,1.7266-0.5718,1.7266-1.3862
				V493.7207z"
          />
          <path
            d="M1422.1284,490.6196h1.0947v0.8633c0.3647-0.5229,0.9971-0.9849,1.897-0.9849c1.3501,0,2.1162,0.9365,2.1162,2.2861
				v4.1958h-1.0947v-3.9038c0-0.8877-0.377-1.5566-1.3257-1.5566c-0.7905,0-1.5928,0.6079-1.5928,1.666v3.7944h-1.0947V490.6196z"
          />
          <path
            d="M1353.1611,513.9004c-1.3984,0-2.4199-0.6445-2.7725-1.812l1.0215-0.3892c0.2065,0.8149,0.8633,1.2891,1.751,1.2891
				c1.1191,0,1.8608-0.5713,1.8608-1.897v-0.7178c-0.3408,0.6206-1.0581,1.0581-1.9458,1.0581
				c-1.7393,0-2.7607-1.4351-2.7607-3.1738c0-1.7271,1.0215-3.1621,2.7607-3.1621c0.8755,0,1.605,0.438,1.9458,1.0581v-0.9365
				h1.0942v5.7886C1356.1162,512.8667,1354.8882,513.9004,1353.1611,513.9004z M1353.2705,506.0566
				c-1.1553,0-1.8604,0.8633-1.8604,2.2012c0,1.3374,0.7051,2.2134,1.8604,2.2134c0.9121,0,1.7637-0.6812,1.7637-2.0796v-0.2314
				C1355.0342,506.7373,1354.1826,506.0566,1353.2705,506.0566z"
          />
          <path
            d="M1357.5044,508.4033c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1358.7207,511.7114,1357.5044,510.3613,1357.5044,508.4033z M1358.6113,507.7349
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1359.499,506.0322,1358.8057,506.6523,1358.6113,507.7349z"
          />
          <path
            d="M1365.606,511.5776h-1.0947v-6.3604h1.0947v0.8633c0.3403-0.5229,0.9482-0.9849,1.8359-0.9849
				c0.8516,0,1.4473,0.4258,1.7637,1.0825c0.3525-0.5229,1.0337-1.0825,2.0308-1.0825c1.3135,0,2.104,0.9365,2.104,2.2861v4.1958
				h-1.0947v-3.9038c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538v3.8066h-1.0947v-3.9038
				c0-0.8877-0.3647-1.5566-1.2646-1.5566c-0.7295,0-1.5078,0.6201-1.5078,1.6538V511.5776z"
          />
          <path
            d="M1377.6245,511.7114c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1380.5796,510.3735,1379.3633,511.7114,1377.6245,511.7114z M1377.6245,510.7749
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1375.7759,509.8506,1376.5054,510.7749,1377.6245,510.7749z"
          />
          <path
            d="M1382.1382,506.1538h-0.9854v-0.9365h0.9854v-1.8608h1.082v1.8608h1.4473v0.9365h-1.4473v3.7334
				c0,0.6689,0.3286,0.7905,0.8877,0.7905c0.2559,0,0.438-0.0122,0.6934-0.0732v0.9365c-0.2798,0.061-0.5957,0.0977-0.9604,0.0977
				c-1.0825,0-1.7026-0.3652-1.7026-1.5811V506.1538z"
          />
          <path
            d="M1388.6211,511.7114c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1391.5762,510.3735,1390.3599,511.7114,1388.6211,511.7114z M1388.6211,510.7749
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1386.7725,509.8506,1387.502,510.7749,1388.6211,510.7749z"
          />
          <path
            d="M1396.2603,506.2998c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V506.2998z"
          />
          <path d="M1397.4165,502.8457h1.1919v1.2651h-1.1919V502.8457z M1398.5601,511.5776h-1.0947v-6.3604h1.0947V511.5776z" />
          <path
            d="M1400.5308,509.6074c0.3403,0.7417,1.1187,1.1919,1.9458,1.1919c0.7417,0,1.3984-0.3164,1.3984-1.0093
				c0-0.6934-0.6079-0.7905-1.6538-1.0093c-1.0459-0.2314-2.1406-0.4624-2.1406-1.7637c0-1.1309,0.9854-1.9214,2.335-1.9214
				c1.1436,0,2.0796,0.5596,2.4565,1.3135l-0.7783,0.6201c-0.3281-0.6323-0.9243-1.0337-1.7266-1.0337
				c-0.73,0-1.2163,0.3647-1.2163,0.9365c0,0.5957,0.5596,0.7051,1.4473,0.8999c1.0942,0.2554,2.3472,0.4741,2.3472,1.8608
				c0,1.2158-1.1069,2.0186-2.4688,2.0186c-1.1553,0-2.3105-0.4985-2.7974-1.4839L1400.5308,509.6074z"
          />
          <path
            d="M1405.8584,508.4033c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1407.0747,511.7114,1405.8584,510.3613,1405.8584,508.4033z M1406.9653,507.7349
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1407.853,506.0322,1407.1597,506.6523,1406.9653,507.7349z"
          />
          <path
            d="M1412.4272,508.4033c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1413.6436,511.7114,1412.4272,510.3613,1412.4272,508.4033z M1413.5342,507.7349
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1414.4219,506.0322,1413.7285,506.6523,1413.5342,507.7349z"
          />
          <path
            d="M1422.73,506.2998c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V506.2998z"
          />
          <path
            d="M1423.4971,508.4033c0-1.812,1.0098-3.3076,2.7729-3.3076c0.8877,0,1.5933,0.4258,1.9702,1.0459v-3.2959h1.0942v8.7319
				h-1.0942v-0.9243c-0.377,0.6323-1.0825,1.0581-1.9702,1.0581C1424.5068,511.7114,1423.4971,510.2036,1423.4971,508.4033z
				 M1426.4644,506.0566c-1.1914,0-1.8604,0.9727-1.8604,2.3467c0,1.3623,0.6689,2.3472,1.8604,2.3472
				c0.9004,0,1.8003-0.6567,1.8003-2.1157v-0.4502C1428.2646,506.7012,1427.3647,506.0566,1426.4644,506.0566z"
          />
          <path d="M1371.9009,526.1753l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1371.9009z" />
          <path
            d="M1376.001,523.001c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1377.2173,526.3091,1376.001,524.959,1376.001,523.001z M1377.1079,522.3325h3.4292
				c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1377.9956,520.6299,1377.3022,521.25,1377.1079,522.3325z"
          />
          <path
            d="M1386.3037,520.8975c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V520.8975z"
          />
          <path d="M1389.1382,526.1753l-2.3833-6.3604h1.1553l1.7876,5.0225l1.7637-5.0225h1.1431l-2.3838,6.3604H1389.1382z" />
          <path
            d="M1396.1934,526.3091c-1.7393,0-2.9556-1.3379-2.9556-3.3081c0-1.9697,1.2163-3.3076,2.9556-3.3076
				c1.7388,0,2.9551,1.3379,2.9551,3.3076C1399.1484,524.9712,1397.9321,526.3091,1396.1934,526.3091z M1396.1934,525.3726
				c1.1187,0,1.8481-0.9243,1.8481-2.3716c0-1.459-0.7295-2.3833-1.8481-2.3833c-1.1191,0-1.8486,0.9243-1.8486,2.3833
				C1394.3447,524.4482,1395.0742,525.3726,1396.1934,525.3726z"
          />
          <path
            d="M1400.0986,523.001c0-1.897,1.2041-3.3076,2.9312-3.3076c1.7754,0,2.7119,1.3501,2.7119,3.1133v0.3647h-4.5972
				c0.061,1.3379,0.8271,2.189,1.9336,2.189c0.8516,0,1.4595-0.4619,1.6543-1.2158l0.9604,0.3403
				c-0.3892,1.1309-1.3496,1.8242-2.6147,1.8242C1401.3149,526.3091,1400.0986,524.959,1400.0986,523.001z M1401.2056,522.3325
				h3.4292c-0.0239-0.8999-0.5107-1.7026-1.6172-1.7026C1402.0933,520.6299,1401.3999,521.25,1401.2056,522.3325z"
          />
          <path
            d="M1410.4014,520.8975c-0.1826-0.0366-0.3286-0.0488-0.5352-0.0488c-0.9243,0-1.666,0.7295-1.666,1.8486v3.478h-1.0947
				v-6.3604h1.0947v1.0825c0.2793-0.6445,0.8999-1.1313,1.7632-1.1313c0.1704,0,0.3281,0.0122,0.438,0.0244V520.8975z"
          />
        </g>
      </g>
    </g>
  );
};

export default SvgNodesNl;
