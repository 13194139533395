import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import Markdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { getTranslation } from '../dictionary';
import { useLocaleStore, useStore } from '../store';
import { getFormattedTruthyMultipleSelect } from '../utils';
import { useQuery } from '@tanstack/react-query';
import { initiativesQueryOptions } from '../services/InitiativeService';
import { expertsQueryOptions } from '../services/ExpertService';
import { stakeholdersQueryOptions } from '../services/StakeholderService';
import { indicatorsQueryOptions } from '../services/IndicatorService';

const highlightedMdString = (search, string) => {
  const re = new RegExp('(' + search + ')', 'ig');
  return string.replace(re, '*$1*');
};

const getSuggestionValue = (suggestion) => suggestion.name;

const renderSuggestion = (suggestion, searchInput, language) => {
  // const description = suggestion.description;
  const match_type = suggestion.match_type; // name, description, interests

  let line1;
  if (match_type === 'name') {
    line1 = (
      <Markdown className="suggestion-with-highlights">{highlightedMdString(searchInput, suggestion.name)}</Markdown>
    );
  } else {
    line1 = <span>{suggestion.name}</span>;
  }

  const extractAndHighlight = (search, commaSeparatedString) => {
    const re = new RegExp(search, 'i');
    const values = commaSeparatedString.split(',');
    const passedValues = values.filter((v) => re.test(v));
    const passedString = passedValues.map((v) => '**' + v + '**').join(', ');
    return highlightedMdString(search, passedString);
  };

  const getExcept = (words, index, surroundByN = 3) => {
    let stringStart = '… ';
    let stringEnd = ' …';
    let indexFrom = index - surroundByN;
    let indexTo = index + surroundByN + 1;

    if (indexFrom <= 0) {
      indexFrom = 0;
      stringStart = '';
    }
    if (indexTo >= words.length - 1) {
      indexTo = words.length - 1;
      stringEnd = '';
    }

    return stringStart + words.slice(indexFrom, indexTo).join(' ') + stringEnd;
  };

  const getHighlightedText = (searchInput, text) => {
    const re = new RegExp(searchInput, 'i');
    const words = text.split(' ');
    const index = words.findIndex((w) => re.test(w));
    return index !== -1 ? getExcept(words, index) : text;
  };

  const addHighlight = (key, searchInput, text) => {
    return (
      ` — ${getTranslation({ key, language })} ${getTranslation({
        key: 'include',
        language,
      })} ` + extractAndHighlight(searchInput, text)
    );
  };

  let line2Md = getTranslation({ key: suggestion.type, language });

  switch (match_type) {
    case 'description':
      const except = getHighlightedText(searchInput, suggestion.description);
      line2Md += ' — ' + highlightedMdString(searchInput, except);
      break;
    case 'interests':
      line2Md += addHighlight('interests', searchInput, suggestion.interests);
      break;
    case 'activities':
      line2Md += addHighlight('activities', searchInput, suggestion.activities);
      break;
    case 'products':
      line2Md += addHighlight('products', searchInput, suggestion.products);
      break;
    case 'category':
      line2Md +=
        ` — ${getTranslation({ key: 'categories', language })}: ` +
        extractAndHighlight(searchInput, suggestion.category);
      break;
    default:
      break;
  }

  return (
    <div className="suggestion">
      <div className={'line-1 font-sans-m'}>{line1}</div>
      <div className={'line-2 font-sans-s type-' + suggestion.type}>
        <Markdown className="suggestion-with-highlights">{line2Md}</Markdown>
      </div>
    </div>
  );
};

function Searchbar() {
  const language = useLocaleStore((state) => state.language);
  const setSearchOpen = (searchOpen) => {
    useStore.setState({ searchOpen: searchOpen });
  };

  const { data: initiatives } = useQuery(initiativesQueryOptions);
  const { data: experts } = useQuery(expertsQueryOptions);
  const { data: stakeholders } = useQuery(stakeholdersQueryOptions);
  const { data: indicators } = useQuery(indicatorsQueryOptions);

  const [value, setValue] = useState('');
  const [results, setResults] = useState([]);

  const source = useMemo(() => {
    const thisItems = [];

    experts?.forEach((d, i) => {
      thisItems.push({
        type: 'experts',
        name: d.name,
        description: language === 'en' ? d.description_en : d.description,
        id: d.id,
        key: 'expert' + d.id,
        activities: '',
        products: '',
        category: '',
        interests:
          d.interests &&
          getFormattedTruthyMultipleSelect(d, 'interests')
            .map((v) =>
              getTranslation({
                key: v,
                categoryKey: 'interestslist',
                language,
              }),
            )
            .join(','),
        match_type: null,
      });
    });
    stakeholders?.forEach((d, i) => {
      thisItems.push({
        type: 'stakeholders',
        name: d.name,
        description: language === 'en' ? d.description_en : d.description,
        id: d.id,
        key: 'stakeholder' + d.id,
        tags: '',
        category: '',
        interests:
          d.interests &&
          getFormattedTruthyMultipleSelect(d, 'interests')
            .map((v) =>
              getTranslation({
                key: v,
                categoryKey: 'interestslist',
                language,
              }),
            )
            .join(','),
        activities:
          d.activities &&
          getFormattedTruthyMultipleSelect(d, 'activities')
            .map((v) => getTranslation({ key: v, language }))
            .join(','),
        products:
          d.products &&
          getFormattedTruthyMultipleSelect(d, 'products')
            .map((v) => getTranslation({ key: v, language }))
            .join(','),
        match_type: null,
      });
    });
    initiatives?.forEach((d, i) => {
      thisItems.push({
        type: 'initiatives',
        name: d.name,
        description: language === 'en' ? d.description_en : d.description,
        id: d.id,
        key: 'initiative' + d.id,
        activities: '',
        interests:
          d.interests &&
          getFormattedTruthyMultipleSelect(d, 'interests')
            .map((v) =>
              getTranslation({
                key: v,
                categoryKey: 'interestslist',
                language,
              }),
            )
            .join(','),
        category:
          d.category &&
          getFormattedTruthyMultipleSelect(d, 'category')
            .map((v) => getTranslation({ key: v, language }))
            .join(','),
        products: '',
        match_type: null,
      });
    });

    indicators?.forEach((d, i) => {
      thisItems.push({
        type: 'indicators',
        name: language === 'en' ? d.indicator_en : d.indicator_nl,
        description: '',
        id: d.id,
        key: 'indicator' + d.id,
        activities: '',
        interests: '',
        category: '',
        products: '',
        match_type: null,
      });
    });
    setResults(thisItems);
    return thisItems;
  }, [experts, indicators, initiatives, language, stakeholders]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const re = new RegExp(_.escapeRegExp(value), 'i');
    const isMatch = (result) => {
      let matchType = null;

      const matchName = re.test(result.name);
      const matchDescripition = re.test(result.description);
      const matchActivity = re.test(result.activities);
      const matchProducts = re.test(result.products);
      const matchInterests = re.test(result.interests);
      const matchCategory = re.test(result.category);

      if (matchName) {
        matchType = 'name';
      } else if (matchDescripition) {
        matchType = 'description';
      } else if (matchActivity) {
        matchType = 'activities';
      } else if (matchProducts) {
        matchType = 'products';
      } else if (matchInterests) {
        matchType = 'interests';
      } else if (matchCategory) {
        matchType = 'category';
      }

      result.match_type = matchType;

      return matchName || matchDescripition || matchActivity || matchProducts || matchInterests || matchCategory;
    };

    return inputLength === 0 ? [] : source.filter(isMatch);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setResults(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setResults([]);
  };

  const inputProps = {
    placeholder: getTranslation({ key: 'search', language }),
    value,
    onChange: (event, { newValue }) => {
      setValue(newValue);
    },
  };

  const navigate = useNavigate();

  return (
    <div className="bg-grey-lll">
      <Autosuggest
        suggestions={results}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={(suggestion) => {
          return renderSuggestion(suggestion, value, language);
        }}
        shouldRenderSuggestions={(value) => value.trim().length >= 3}
        inputProps={inputProps}
        onSuggestionSelected={(e, val) => {
          setSearchOpen(false);
          navigate(`/${val.suggestion.type}/${val.suggestion.id}`);
        }}
      />
    </div>
  );
}

export default Searchbar;
