import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import MenuCover from '../components/MenuCover';
import MenuMobile from '../components/MenuMobile';

function Root() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//cdn.cookie-script.com/s/3c64021218375da126fe81054b2486cd.js';

    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="Home">
        <SnackbarProvider />
        <Header />
        <main>
          <Outlet />
        </main>
        <Footer />
        <MenuMobile />
        <MenuCover />
      </div>
    </>
  );
}

export default Root;
