import { isMobile, isTablet } from 'react-device-detect';
import axios from './axios';

export const cleanData = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => cleanData(item));
  } else if (typeof data === 'object' && data !== null) {
    const cleanedData = {};
    for (const key in data) {
      if (typeof data[key] === 'string' && data[key].trim() === '') {
        cleanedData[key] = null;
      } else {
        cleanedData[key] = cleanData(data[key]);
      }
    }
    return cleanedData;
  } else {
    return data;
  }
};

export const compareStates = (initialState, interestState, interestList) => {
  if (!initialState || !interestState || !interestList) return null;
  let result = {
    connect: [],
    disconnect: [],
  };

  function getIdByKey(key) {
    let interest = interestList.find((interest) => interest.name === key);
    return interest ? interest.id : null;
  }

  for (let key in initialState) {
    if (interestState[key] !== undefined && interestState[key] !== initialState[key]) {
      if (initialState[key] === true && interestState[key] === false) {
        result.disconnect.push(getIdByKey(key));
      } else if (initialState[key] === false && interestState[key] === true) {
        result.connect.push(getIdByKey(key));
      }
    }
  }

  return result;
};

export const transformIndicatorData = (jsond) => {
  const formatted = [];
  const j = jsond.data;

  for (const i of j) {
    const ja = i.attributes;

    const newj = {
      id: i.id,
      indicator_en: ja.name_en,
      indicator_nl: ja.name_nl,
      overarching_objectives: ja.overarching_objective,
      description_en: ja.description_en,
      description_nl: ja.description,
      source: ja.source,
      main_responsible: ja.responsible_entity,
      geographic_area: ja.geographic_area,
      visualisation_function: ja.visualization_function,
      group_id: ja.GroupID,
      group_description: ja.group_description,
      indicator_category: ja.indicator_category,
      indicator_category_nl: ja.indicator_category_nl,
      indicator_category_en: ja.indicator_category_en,
      title: ja.title,
      legend: ja.legend,
      overarching_objectives_nl: ja.overarching_objective,
      impact_areas_id: ja.impact_areas_id,
      impact_areas_en: ja.impact_areas_en,
      impact_areas_nl: ja.impact_areas_nl,
      dataset: [],
      linkeddataset: null,
    };

    for (const dataset of ja.dataset.data.sort((prev, after) => prev.attributes.year - after.attributes.year)) {
      const data = {
        id: dataset.id,
        unit: dataset.attributes.name,
        data: dataset.attributes.amount,
        year: dataset.attributes.year,
      };

      newj.dataset.push(data);
    }

    formatted.push(newj);
  }

  return formatted;
};

export const transformSingleIndicator = (oldIndicator) => {
  const i = oldIndicator.data;
  const iAttr = i.attributes;

  const newIndicator = {
    id: i.id,
    indicator_en: iAttr.name_en,
    indicator_nl: iAttr.name_nl,
    overarching_objectives: iAttr.overarching_objective,
    description_en: iAttr.description_en,
    description_nl: iAttr.description,
    source: iAttr.source,
    main_responsible: iAttr.responsible_entity,
    geographic_area: iAttr.geographic_area,
    visualisation_function: iAttr.visualization_function,
    group_id: iAttr.GroupID,
    group_description: iAttr.group_description,
    indicator_category: iAttr.indicator_category,
    indicator_category_nl: iAttr.indicator_category_nl,
    title: iAttr.title,
    legend: iAttr.legend,
    overarching_objectives_nl: iAttr.overarching_objective,
    impact_areas_id: iAttr.impact_areas_id,
    impact_areas_en: iAttr.impact_areas_en,
    impact_areas_nl: iAttr.impact_areas_nl,
    indicator_category_en: iAttr.indicator_category,
    dataset: [],
    linkeddataset: null,
  };

  for (const dataset of iAttr.dataset.data) {
    const data = {
      id: dataset.id,
      unit: dataset.attributes.name,
      data: dataset.attributes.amount,
      year: dataset.attributes.year,
    };

    newIndicator.dataset.push(data);
  }
  return newIndicator;
};

/**
 * Fetches coordinates for a given address using the OpenCage Geocoding API.
 * @param {string} address - The address to geocode.
 * @returns {Promise<string|null>} - The ID of the added location or null if an error occurred.
 */
export const getCoords = async (address) => {
  if (!address) {
    console.log('No address provided, ignore geocoding');
    return null;
  }

  const apiKey = '18d39806f1dc4eebb5c4b6f1c3efa367';
  const apiUrl = 'https://api.opencagedata.com/geocode/v1/json';

  try {
    const params = {
      q: address,
      key: apiKey,
      no_annotations: '1',
      language: 'en',
    };

    const response = await axios.get(apiUrl, { params, headers: { Authorization: null } });

    if (response.status === 200 && response.data.results.length > 0) {
      const { lat, lng } = response.data.results[0].geometry;
      const locationData = { data: { lat, lng } };

      const locationResponse = await addLocation(locationData);
      if (locationResponse) {
        console.log('Location added successfully');
        return locationResponse;
      } else {
        console.error('Failed to add location');
        return null;
      }
    } else {
      console.error('No results found or bad request', response.data);
      return null;
    }
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};

/**
 * Adds a location by sending a POST request to the /locations endpoint.
 * @param {object} locationData - The location data to be added.
 * @returns {Promise<string|null>} - The ID of the added location or null if an error occurred.
 */
const addLocation = async (locationData) => {
  try {
    const response = await axios.post('/locations', locationData, {
      headers: { 'Content-Type': 'application/json' },
    });

    if (response.status === 200) {
      return response.data.data.id;
    } else {
      console.error(`Failed to add location. Status code: ${response.status}`, response.data);
      return null;
    }
  } catch (error) {
    console.error('An error occurred while adding location:', error);
    return null;
  }
};

export const get2Indicators = async () => {
  const randomIndicatorSeeds = [
    'restaurants met vegetarische opties',
    'plantaardig aanbod',
    'verpakkingsvrije winkels',
    'buurtmoestuinen',
    'aantal biologische landbouwbedrijven',
    'Aantal bezoekers in klasverband aan landbouwbedrijven die meedoen aan Boeren met Klasse',
    'wekelijkse markten',
  ];

  const [indicator1, indicator2] = getRandomElements(randomIndicatorSeeds);

  const [randomSentenceIndicator1, randomSentenceIndicator2] = await Promise.all([
    axios.get(`/statistics?filters[name_nl][$containsi]=${indicator1}&populate=*`),
    axios.get(`/statistics?filters[name_nl][$containsi]=${indicator2}&populate=*`),
  ]);

  return [randomSentenceIndicator1.data, randomSentenceIndicator2.data];

  function getRandomElements(arr) {
    if (arr.length < 2) {
      return null;
    }

    let index1, index2;

    do {
      index1 = Math.floor(Math.random() * arr.length);
      index2 = Math.floor(Math.random() * arr.length);
    } while (index1 === index2);

    const element1 = arr[index1];
    const element2 = arr[index2];

    return [element1, element2];
  }
};

export const deleteImage = async (imageId) => {
  try {
    const response = await axios.delete(`/upload/files/${imageId}`);
    return response;
  } catch (error) {
    console.error('Error deleting image:', error);
  }
};

export const uploadImage = async (imageFile) => {
  if (!imageFile) {
    console.log('No image file provided, ignore upload');
    return null;
  }
  const formData = new FormData();
  formData.append('files', imageFile);

  try {
    const response = await axios.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data[0].id;
  } catch (error) {
    console.error('Error uploading image:', error);
  }
};

export const getFormattedTruthyMultipleSelect = (d, selectKey) => {
  if (d && d[selectKey]) {
    const values = Object.values(d[selectKey]);
    const keys = Object.keys(d[selectKey]);
    return keys.filter((k, i) => k !== 'id' && values[i] === true);
  } else {
    return [];
  }
};
export const transformIndicator = (data) => {
  let new_json = {
    id: 35,
    published_at: '2020-11-18T17:11:11.586Z',
    created_at: '2023-02-27T07:35:58.968Z',
    updated_at: '2023-02-27T07:35:58.968Z',
    statistic: [],
  };

  for (const item of data.data) {
    const i = item.attributes.statistic;
    const new_stat = {
      id: i.id,
      name: i.name,
      active: true,
      value: i.value,
    };
    new_json.statistic.push(new_stat);
  }
  return [new_json];
};

export const updateOpeningHours = (hours) => {
  return Object.fromEntries(
    Object.entries(hours).map(([day, times]) => {
      const formattedTime = times.open && times.close ? `${times.open} - ${times.close}` : '?';
      return [day, times.open === 'Gesloten' ? 'Gesloten' : formattedTime];
    }),
  );
};

export const parseOpeningHours = (hours) => {
  if (!hours) return null;
  const weekOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  const parsedHours = Object.fromEntries(
    Object.entries(hours).map(([day, timeString]) => {
      switch (timeString) {
        case 'Gesloten':
          return [day, { open: 'Gesloten', close: 'Gesloten' }];
        case '?':
          return [day, { open: '', close: '' }];
        default:
          const [open, close] = timeString.split(' - ');
          return [day, { open, close }];
      }
    }),
  );

  return Object.fromEntries(weekOrder.map((day) => [day, parsedHours[day]]));
};

export const flattenAttributes = (jsonData) => {
  const flattenedData = jsonData?.data?.map((item) => {
    return {
      ...item.attributes,
      id: item.id,
    };
  });

  return flattenedData;
};

export const transformEntity = (entity) => {
  entity.attributes.id = entity.id;
  entity = entity.attributes;

  const transformNestedData = (data, transformFunc) => (data ? data.map(transformFunc) : data);

  if (entity.interest) {
    entity.interest = transformNestedData(entity.interest.data, ({ id, attributes }) => {
      return { name: attributes.name, id };
    });
  }

  if (entity.photo && entity.photo.data) {
    entity.photo = {...entity.photo.data.attributes, id: entity.photo.data.id};
  }

  if (entity.target) {
    entity.target = transformNestedData(entity.target.data, ({ id, attributes }) => {
      return { name: attributes.name, type: attributes.type, id };
    });
  }

  if (entity.expert) {
    entity.expert = transformNestedData(entity.expert.data, ({ id, attributes }) => {
      return { ...attributes, id };
    });
  }

  if (entity.sector) {
    entity.sector = transformNestedData(entity.sector.data, ({ id, attributes }) => {
      return { name: attributes.name, id };
    });
  }

  if (entity.location && entity.location.data) {
    entity.location = entity.location.data.attributes;
  }

  if (entity.stakeholder) {
    entity.stakeholder = transformNestedData(entity.stakeholder.data, ({ id, attributes }) => {
      return { ...attributes, id };
    });
  }

  if (entity.initiative) {
    entity.initiative = transformNestedData(entity.initiative.data, ({ id, attributes }) => {
      return { ...attributes, id };
    });
  }

  if (entity.category) {
    entity.category = transformNestedData(entity.category.data, ({ id, attributes }) => {
      return { name: attributes.name, id };
    });
  }

  if (entity.dataset) {
    entity.dataset = transformNestedData(entity.dataset.data, ({ id, attributes }) => {
      return { ...attributes, id };
    });
  }

  return entity;
};

export const getPhotoIfPresent = (d) => {
  if (d.photo && d.photo.formats && d.photo.formats.thumbnail && d.photo.formats.thumbnail.url) {
    return d.photo.formats.thumbnail.url;
  } else {
    return false;
  }
};

export const getPhotoIdIfPresent = (d) => {
  if (d.photo && d.photo.formats && d.photo.formats.thumbnail && d.photo.formats.thumbnail.url) {
    return d.photo.id;
  } else {
    return false;
  }
};

export const getLogoIfPresent = (d) => {
  if (
    d.logo &&
    d.logo.data &&
    d.logo.data.attributes &&
    d.logo.data.attributes.formats &&
    d.logo.data.attributes.formats.thumbnail &&
    d.logo.data.attributes.formats.thumbnail.url
  ) {
    return d.logo.data.attributes.formats.thumbnail.url;
  } else {
    return false;
  }
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

export const openMenuMobile = () => {
  const b = document.body;
  if (!b.classList.contains('menu-open')) {
    b.classList.add('menu-open');
  }
};

export const closeMenuMobile = () => {
  const b = document.body;
  if (b.classList.contains('menu-open')) {
    b.classList.remove('menu-open');
  }
};

export const toggleSidebar = () => {
  const overlay = document.getElementById('overlay');
  if (!overlay.classList.contains('show')) {
    overlay.classList.add('show');
  } else {
    overlay.classList.remove('show');
  }
};

export const deSluggify = (str) => {
  return str.replaceAll(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
};

export const addhttp = (url) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = 'http://' + url;
  }
  return url;
};

export const cleanUrlText = (url) => {
  const urlParts = url.replace('http://', '').replace('https://', '').split(/[/?#]/);
  return urlParts[0];
};

export const formatNumberWithCommas = (x) =>
  ['number', 'string'].includes(typeof x)
    ? x
        .toString()
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    : x;

export const isMobileWithTablet = isMobile
  ? isTablet
    ? window.innerWidth < window.innerHeight
      ? true
      : false
    : true
  : false;

export const validURL = (str) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

/** AP Web Utility
 *  Query current breakpoint in bootstrap style.
 *  Examples:
 *
 *  console.log(breakpointIs("xs", "only"));
 *  console.log(breakpointIs("md", "down"));
 *  console.log(breakpointIs("lg", "up"));
 */
export const breakpointIs = (breakpointName, compare) => {
  var breakpoints = [
    { name: 'xs', index: 1, minWidth: 0 },
    { name: 'sm', index: 2, minWidth: 576 },
    { name: 'md', index: 3, minWidth: 768 },
    { name: 'lg', index: 4, minWidth: 992 },
    { name: 'xl', index: 5, minWidth: 1200 },
  ];
  var w = window.innerWidth;
  var current = breakpoints[0];
  breakpoints.forEach(function (b, i) {
    if (w > b.minWidth) {
      current = b;
    }
  });
  var breakpoint = breakpoints.find(function (e) {
    return e.name === breakpointName;
  });
  if (!breakpoint) {
    throw new Error(`(30298140) Unknown breakpointName ${breakpointName}`);
  }
  var breakpointIndex = breakpoint.index;
  if (compare === 'only') {
    return current.index === breakpointIndex;
  } else if (compare === 'down') {
    return current.index <= breakpointIndex;
  } else if (compare === 'up') {
    return current.index >= breakpointIndex;
  }
};
export const getDataSourceMap = (items) => {
  return {
    type: 'FeatureCollection',
    features: items.map((d) => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [d.location.lng, d.location.lat],
        },
        properties: {
          type: d.type,
          name: d.name,
          interests: d.interests,
          id: d.id,
        },
      };
    }),
  };
};
