import { FormControlLabel, FormGroup, FormLabel, Switch } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { getTranslation } from '../dictionary';
import { useLocaleStore } from '../store';

const RenderSwitches = ({ state, label, handleChange, control, categoryKey }) => {
  const language = useLocaleStore((state) => state.language);

  return (
    <>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup>
        {Object.keys(state).map((key) => (
          <Controller
            key={key}
            name={key}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={state[key]} onChange={handleChange} />}
                label={getTranslation({ key: key, categoryKey: categoryKey, language })}
              />
            )}
          />
        ))}
      </FormGroup>
    </>
  );
};

export default RenderSwitches;
