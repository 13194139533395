import React from 'react';
import IndicatorCard from './IndicatorCard';
import { useQuery } from '@tanstack/react-query';
import { indicatorsQueryOptions } from '../services/IndicatorService';
import { Skeleton } from '@mui/material';

const IndicatorsWrapper = () => {
  const { isLoading, data: indicators } = useQuery(indicatorsQueryOptions);
  const numberOfSkeletons = 6;
  const filteredIndicators = indicators
    ?.filter((d) => d.dataset[0] && d.dataset[0].data)
    ?.filter((g) => g.geographic_area === 'municipality_leuven' || g.geographic_area === 'arrondissement_leuven')
    .slice(0, numberOfSkeletons);

  return (
    <div className="container-fluid">
      <div className="row indicator-cards-container">
        {!isLoading
          ? filteredIndicators.map((d, i) => <IndicatorCard d={d} key={i} />)
          : Array.from({ length: numberOfSkeletons }).map((_, index) => (
              <div key={index} className="col-md-6 col-xl-4 card-stakeholder-col">
                <div className="rounded-3">
                  <Skeleton variant="rounded" height={260} className="mb-4" />
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default IndicatorsWrapper;
