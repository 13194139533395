import React from 'react';
import loginsvg from '../assets/images/login.svg';
import { useLocaleStore } from '../store';
import { getTranslation } from '../dictionary';
/*
Login configures and renders the StytchLogin component which is a prebuilt UI component for auth powered by Stytch
This component accepts style, config, and callbacks props. To learn more about possible options review the documentation at
https://stytch.com/docs/sdks/javascript-sdk#ui-configs
*/
const Loggedin = () => {
  const language = useLocaleStore((state) => state.language);

  return (
    <div style={{ width: '100vw' }} className="Home">
      <section className="indicators-intro pad-small">
        <div className="container-fluid">
          <div className="row pb-5">
            <div className="col-12">
              <div className="logged-in-header">
                <h2>
                  {getTranslation({ key: 'engagement', language })}
                </h2>
              </div>
              <p className="font-serif-l">
                {getTranslation({ key: 'loggedin_succesfully', language })}
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="container h-100">
        <div className=" text-black rounder-7">
          <div className="card-body p-md-5">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6">
                <div className="m-8"></div>
              </div>
              <div className="col-xl-6">
                <img className="logged-in-image" src={loginsvg} alt="loginsvg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loggedin;
