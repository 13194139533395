import React, { useEffect, useState } from 'react';
import { getTranslation } from '../dictionary';
import { useLocaleStore } from '../store';
import { get2Indicators } from '../utils';
import LinkScrollTop from './LinkScrollTop';
import { Skeleton } from '@mui/material';

const RandomSentence = () => {
  const [randomSentenceIndicators, setRandomSentenceIndicators] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const indicators = await get2Indicators();
        setRandomSentenceIndicators(indicators);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error: You might want to set an error state here
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const language = useLocaleStore((state) => state.language);

  return (
    <div>
      {!isLoading ? (
        randomSentenceIndicators.map((item, index) => {
          const nameNl = item.data[0].attributes[`name_${language}`].replace(/^(aantal|number of)\s+/i, '');
          const prefix = [
            `${getTranslation({ key: 'leuven_counts', language })}`,
            getTranslation({ key: 'and', language }),
          ];
          const number = item.data[0].attributes.dataset.data[0].attributes.amount;

          return (
            <div key={index}>
              <div>
                <span className="color-purple">
                  <span style={{ color: 'black' }}>{prefix[index]}</span>{' '}
                  <span style={{ color: '#290080' }}>{number} </span>
                  <LinkScrollTop href="#this" to={`/indicators/${item.data[0].id}`}>
                    {nameNl}
                  </LinkScrollTop>
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <Skeleton variant="rounded" height={200} className="mb-2" />
      )}
    </div>
  );
};

export default RandomSentence;
